import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import APIServices from '../../service/APIService';
import { API } from '../../constants/api_url';
import { TabMenu } from 'primereact/tabmenu';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import useForceUpdate from 'use-force-update';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { ReadMoreComponent } from '../../components/Forms/ReadMoreComponent';
import { fetchUserList } from '../../RTK/Background/userProfileList';
const UserListing = () => {

    const [list1, setList1] = useState([])
    const [list2, setList2] = useState([])
    const [list3, setList3] = useState([])
    const [data, setData] = useState([])
    const dispatch = useDispatch()
    const toast = useRef(null);
    const admin_data = useSelector((state) => state.user.admindetail)
    const login_data = useSelector((state) => state.user.userdetail)
    const [search1, setSearch1] = useState('')
    const [search2, setSearch2] = useState('')
    const [search3, setSearch3] = useState('')
    const [roledialog, setRoleDialog] = useState(false)
    const [selectedlist, setSelectedList] = useState({ country: [], city: [], site: [] })

    const [activeuser, setActiveUser] = useState({ submitter: false, viewer: false, approver: false })
    const [active, setActive] = useState(0)
    const [selectedObj, setSelectedObj] = useState({ userProfileId: admin_data.id, user_id: null, tier1_id: 0, tier2_id: 0, tier3_id: 0, roles: [] })
    const [selectedUser, setSelectedUser] = useState({})
    const [userRole, setUserRole] = useState([])
    const [allLocationRoles, setAllLocationRoles] = useState([])
    const [applicationlist, setApplicationList] = useState([])
    const [rawsitelist, setRawSiteList] = useState([])
    const forceUpdate = useForceUpdate()

    useEffect(() => {

        renderData()
        renderUserData()
    }, [])
    const renderUserData = () => {
        let datas = []
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile)
                .then((res) => {


                    res.data.forEach((item, index) => {
                        if (item.role === 'clientuser' && item.clientId === admin_data.id) {

                            datas.push({ id: item.id, empname: item.information.empname, empdesgination: item.information.empdesgination, empid: item.information.empid, empmailid: item.email, config: item.information.config, information: item, emplocation: item.information.emplocation === undefined ? '' : item.information.emplocation })

                        }
                        if (index === res.data.length - 1) {
                            resolve(datas)
                        }
                    })
                })
        })
        promise.then((d) => {
            console.log(d); setData(d);
            if (search1 && search1.trim().length) {
                APIServices.get(API.TVS_Ext_users + '/?search=' + search1).then((res) => {
                    setList1(res.data.data)

                })
            }


            forceUpdate()
        })
    }
    const renderData = () => {

        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let app = {
            "include": [{ "relation": "applicationRoles" }]

        }
        APIServices.get(API.UserRole).then((res) => {

            setUserRole(res.data.filter(i => i.userProfileId === admin_data.id))
        })
        APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`).then((res) => {
            const shapedSite = res.data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            console.log(shapedSite)
            shapedSite.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    city.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        site.push({ name: k.name, id: k.id })
                    })
                })
            })
            setRawSiteList(shapedSite)
            setSelectedList({ country, city, site })
        })
        APIServices.get(API.Application + `?filter=${encodeURIComponent(JSON.stringify(app))}`).then((res) => {
            setApplicationList(res.data)

        })

        APIServices.get(API.TVS_Ext_users).then((res) => {
            setList1(res.data.data)

        })

    }
    const countryList = [
        { label: 'Users' }

    ];
    const saveAssignment = () => {

        if (selectedObj.id) {
            let newObj = { user_id: selectedObj.user_id, modified_by: login_data.id, userProfileId: admin_data.id, modified_on: DateTime.utc(), roles: selectedObj.roles, locations: { tier1_id: selectedObj.tier1_id, tier2_id: selectedObj.tier2_id, tier3_id: selectedObj.tier3_id } }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                console.log(res)
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Saved', life: 3000 });
                setUserRole(newUserRole)
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                forceUpdate()

            })


        } else {
            let newObj = { created_by: login_data.id, created_on: DateTime.utc(), user_id: selectedObj.user_id, userProfileId: admin_data.id, locations: { tier1_id: selectedObj.tier1_id, tier2_id: selectedObj.tier2_id, tier3_id: selectedObj.tier3_id }, roles: selectedObj.roles }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                setSelectedObj((prev) => ({ ...prev, id: res.data.object[0].id }))
                setUserRole(newUserRole)
                forceUpdate()
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Updated', life: 3000 });
            })
        }
    };

    const resetAssignment = () => {
        console.log(selectedObj)
        if (selectedUser.id) {
            let newObj = { modified_by: login_data.id, modified_on: DateTime.utc(), roles: [] }
            Swal.fire({
                title: `Are you sure want to reset all role assignment for ${selectedUser.empname} ?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIServices.delete(API.ResetRolesToUser(selectedUser.id)).then((res) => {
                        setUserRole(userRole.filter(i => i.user_id !== selectedUser.id))
                        setSelectedObj((prev) => ({ ...prev, userProfileId: admin_data.id, tier1_id: 0, tier2_id: 0, tier3_id: 0, roles: [] }))
                        setAllLocationRoles([])
                        forceUpdate()
                        toast.current.show({ severity: 'danger', summary: 'Success', detail: 'Assignment Reseted', life: 3000 });
                    })
                }
            })
        }



    };
    const handleDropdownChange = (obj, val) => {
        console.log("handleDropdownChange:", val);
        let loc = JSON.parse(JSON.stringify(selectedObj))
        if (obj === 'country') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {

                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.tier1_id = val
            loc.tier2_id = null
            loc.tier3_id = null

            setSelectedList({ country, city, site })
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === val && i.tier2_id === 0 && i.tier3_id === 0)



            console.log(country, city, site, loc)
        } else if (obj === 'city') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {

                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })
            loc.tier2_id = val
            loc.tier3_id = null
            console.log(country, city, site, loc)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === loc.tier1_id && i.tier2_id === val && i.tier3_id === 0)

            setSelectedList({ country, city, site })
        } else if (obj === 'site') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.tier2_id || loc.tier2_id === 0) {
                            j.locationThrees.forEach((k) => {


                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            loc.tier3_id = val
            console.log(country, city, site, loc)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === loc.tier1_id && i.tier2_id === loc.tier2_id && i.tier3_id === val)

            setSelectedList({ country, city, site })
        }
        console.log(selectedUser)
        if ((loc.tier1_id === 0 && loc.tier2_id === null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id === 0 && loc.tier3_id === null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id !== null && loc.tier2_id !== 0 && loc.tier3_id !== null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id !== null && loc.tier2_id !== 0 && loc.tier3_id !== 0)) {

            APIServices.post(API.Roles_Info, { locations: { tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id }, user_id: selectedUser.id, userProfileId: admin_data.id }).then((res) => {
                console.log(res.data)
                if (res.data.length && res.data[0].id) {

                    setSelectedObj((prev) => ({ ...loc, ...res.data[0] }))
                } else {
                    setSelectedObj({ tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id, user_id: selectedUser.id, roles: [], disabledRoles: res.data[0].disabledRoles })
                }

            })
        } else {
            setSelectedObj({ tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id, user_id: selectedUser.id, roles: [], disabledRoles: [] })
        }

        forceUpdate()
    };
    const onCheckBoxChecked = (role) => {
        let loc = JSON.parse(JSON.stringify(selectedObj))
        if (loc.roles.includes(role)) {
            let index = loc.roles.findIndex(i => i === role)
            loc.roles.splice(index, 1)
        } else {
            loc.roles.push(role)
        }
        setSelectedObj(loc)
    }
    const getRoleNameById = (roleId) => {
        let roleName = 'Unknown Role'; // Default if not found
        Object.values(applicationlist).forEach(category => {
            if (category.applicationRoles) {
                const role = category.applicationRoles.find(role => role.id === roleId);
                console.log(category.applicationRoles)
                if (role) {
                    roleName = role.title;
                    return;
                }
            }

        });
        return roleName;
    }
    const handleSearch1 = (e) => {
        setSearch1(e)
        APIServices.get(API.TVS_Ext_users + '/?search=' + e).then((res) => {
            setList1(res.data.data)

        })
    }
    const onCategoryChange = (obj, val) => {
        let da = activeuser
        da[obj] = val
        setActiveUser(da)
        console.log(da)
        forceUpdate()
    }







    const handleUserCreate = (rowData) => {
        if (admin_data.id === 289) {
            let newObj = { information: { role: {} } }
            newObj["company"] = "TVS Motors"
            newObj["email"] = rowData.mail;
            newObj.information['empname'] = rowData.displayName;
            newObj.information['emplocation'] = '';
            newObj.information['empdesgination'] = '';
            newObj.information['username'] = rowData.userPrincipalName;

            newObj.information.role['reporter'] = false;
            newObj.information.role['viewer'] = false;
            newObj.information.role['reviewer'] = false;
            newObj.information.role['approver'] = false;

            newObj.information['companyname'] = admin_data.information.companyname
            newObj.information['companyid'] = admin_data.userId
            newObj.information['blocked'] = false;
            newObj.information['cid'] = admin_data.id
            newObj['role'] = 'clientuser'
            newObj['clientId'] = admin_data.id
            if (newObj['clientId'] !== null) {
                APIServices.post(API.NewUserProfile, newObj)
                    .then((res) => {
                        dispatch(fetchUserList(admin_data.id))
                        renderUserData()
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `User Added successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })


                    }).catch((e) => {
                      
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Email Id exist / check internet connection `,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: `Something went wrong kindly logout & try again.`,
                    showConfirmButton: false,
                    timer: 2500
                })
            }

        }
    }


    const handleUserRole = (config) => {
        let found = data.find(i => i.empmailid === config.mail)
        console.log(data, config)
        if (found) {
            setSelectedUser(found)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === found.id && i.tier1_id === 0 && i.tier2_id === 0 && i.tier3_id === 0)

            setSelectedObj((prev) => ({ ...prev, tier1_id: null, tier2_id: null, tier3_id: null, user_id: found.id, roles: [] }))
            setAllLocationRoles(userRole.filter(i => i.user_id === found.id))
            setRoleDialog(true)
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Something went wrong, kindly please inform EiSqr Team.',
                showConfirmButton: false,
                timer: 1500
            })
        }



    }

    return (
        <div className="grid p-fluid bg-smoke font-lato" style={{ minHeight: '100vh' }}>
            {(login_data.clientId === 289 || login_data.clientId === 28 || login_data.clientId === 17)  ? <div className="col-12 md:col-12">
                <Toast ref={toast} />
                <div className='p-3 card'>
                    <div className='flex fw-7 fs-20 clr-navy justify-content-center'>
                        User Management
                    </div>
                    <div className='readmore'>
                    As an Admin, you can add employees of your enterprise as users in Navigos and assign their roles. To do this, first navigate to the Employee List, where you can view details such as the employee's name and email address. Next, search for and locate the employee you wish to add as a user. Once found, click the button “<i className='pi pi-plus-circle' />“ next to their name, which will change to a tick mark (<i className='pi pi-check' />), indicating that the user has been successfully added to Navigos. Finally, after selecting the employee, assign the appropriate role by clicking “<i className='pi pi-list' />“
                        </div>
                    <div className='flex justify-content-end'>
                        <div className='m-1`' style={{ width: 300 }}>
                            <span className="p-input-icon-left" >
                                <i className="pi pi-search" />
                                <InputText placeholder="Search name" value={search1} onChange={(e) => { handleSearch1(e.target.value) }} />
                            </span>
                        </div>
                    </div>
                    <div >
                        <div>

                            <DataTable value={list1}
                                scrollable
                                dataKey="id"
                                className='fullheight'
                                emptyMessage="No records found." responsiveLayout="scroll">
                                <Column header='Name' field='displayName' />
                                <Column header='Email' field='mail' />
                                <Column header='Action' body={(rowData) => { return rowData.status === 'active' ? <div className='flex justify-content-start'><i className='pi pi-check'></i></div> : <div className='flex justify-content-start' onClick={() => { handleUserCreate(rowData) }}><i className='pi pi-plus-circle'></i></div> }} />
                                <Column header='Role Assignment' body={(rowData) => { return rowData.status === 'active' ? <div className='flex justify-content-start'><i className='pi pi-list' onClick={() => { handleUserRole(rowData) }}></i></div> : <></> }} />

                            </DataTable>
                        </div>




                    </div>
                </div>
                <Dialog style={{ width: '85%' }} header={`Assignment permission to ` + selectedUser.empname} visible={roledialog} modal onHide={() => { setRoleDialog(false) }}>
                    {userRole.filter(i => (i.user_id === selectedUser.id && i.roles.length)).length !== 0 && login_data.clientId === 289 &&  <div className='flex justify-content-end'>
                        <Button
                            label="Reset Assignment"
                            severity={"danger"}

                            onClick={resetAssignment}
                        />
                    </div>}
                    <ReadMoreComponent percent={100} content={`<div>Use this tab which appears on a click of  <i class="pi pi-list" ></i>  to assign a specific role to the user added for managing access and administrative functions for managing the data at various levels. Follow the steps below to configure permissions.
<b>Select Location:</b> Use the dropdown menu to choose a location for which the user would be assigned to manage the data. Locations have been structured in three-tier i.e., Country, Region/State, Site. Based on the former tier selected, the next tier options will open. Please choose "All Countries“  to assign the user a role for managing the data globally.
<b>User Roles:</b> Select one or more roles to define the user's level of access:
<b>Reporter:</b> Collect and submit required quantitative or qualitative data (or amendments) for the assigned entity together with necessary evidence and comments, if any. To also re-submit the data with the necessary changes done as per the comments provided by the Reviewer, if the data has been sent back by the Reviewer. 
<b>Reviewer:</b> Review the submitted data for correctness and submission quality, including relevant attachments and data trends. Can request amendments by sending it back to the Reporter. 
<b>Approver:</b> Reviews the consolidated data for accuracy and relevancy, provides final approval, and, if necessary, requests further clarification from Reviewers or Reporters. The Approver also oversees the generation of final reports for compliance with regulatory or business requirements.
<b>Viewer:</b> Has read-only access to view data.
<b>Sub Admin Role:</b> Check the Admin box if the user requires the Admin privileges. This role provides higher-level access to manage functions within the platform.
<b>Section Admin Permissions:</b> Grant specific section-based permissions to allow the user to oversee and manage these areas.
Available sections include <b>Sustainability, Health, Safety Central, Safety Operational, Supply Chain, Dealership Sustainability, Environmental, Social, Governance</b>
Once you've selected the roles and permissions, click Save Assignment to apply the changes.
This setup helps control user access levels, ensuring they have the appropriate permissions for their Roles.</div>`} />
                    <div className='grid m-0 col-12'>

                        <div className='col-6'>
                            <div class="grid p-4">
                                <div class="col-4">
                                    <Dropdown
                                        id="country"
                                        optionLabel="name"
                                        optionValue="id"
                                        value={selectedObj.tier1_id}
                                        options={selectedlist.country}
                                        onChange={(e) =>
                                            handleDropdownChange('country', e.value)
                                        }
                                        placeholder='Choose country'
                                        className="w-full"
                                    />
                                </div>
                                {(selectedObj.tier1_id !== null && selectedObj.tier1_id !== 0) && <div class="col-4">
                                    <Dropdown
                                        id="city"
                                        optionLabel="name"
                                        optionValue="id"
                                        value={selectedObj.tier2_id}
                                        options={selectedlist.city}
                                        onChange={(e) =>
                                            handleDropdownChange('city', e.value)
                                        }
                                        placeholder='Choose city'
                                        className="w-full"
                                    />
                                </div>}
                                {selectedObj.tier1_id !== null && selectedObj.tier1_id !== 0 && selectedObj.tier2_id !== null && selectedObj.tier2_id !== 0 && <div class="col-4">
                                    <Dropdown
                                        id="unit"
                                        optionLabel="name"
                                        optionValue="id"
                                        value={selectedObj.tier3_id}
                                        options={selectedlist.site}
                                        onChange={(e) =>
                                            handleDropdownChange('site', e.value)
                                        }
                                        placeholder='Choose business unit'
                                        className="w-full"
                                    />
                                </div>}
                            </div>
                            {(selectedObj.tier1_id !== null ? selectedObj.tier2_id !== null ? selectedObj.tier3_id !== null ? true : selectedObj.tier2_id === 0 : selectedObj.tier1_id === 0 : false) &&
                                <div>

                                    {applicationlist.map((app, index) => {
                                        let displayedCategories = new Set();
                                        if (index === 0) {
                                            return (
                                                <>
                                                    {app.applicationRoles && app.applicationRoles.length > 0 && <div>

                                                        <div className="grid p-2">
                                                            {app.applicationRoles && app.applicationRoles.sort((a, b) => {
                                                                return a.categoryOrder - b.categoryOrder
                                                            }).map((role, index) => {

                                                                if (role.status && (!role.client_ids || (role.client_ids && !role.client_ids.length) || (role.client_ids && role.client_ids.length && role.client_ids.includes(admin_data.id)))) {
                                                                    if (role.id === 6 ? (selectedObj.tier1_id > 0 && selectedObj.tier2_id === 0 && selectedObj.tier3_id === null && login_data.role === 'clientadmin') : [7, 8, 9, 10, 11, 12, 13, 14, 15,16].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null && login_data.role === 'clientadmin')  : [17,18,19].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null ) :  true) {
                                                                        const shouldShowCategory = !displayedCategories.has(role.category);


                                                                        displayedCategories.add(role.category);
                                                                        return (
                                                                            <React.Fragment key={index}>

                                                                                {shouldShowCategory && role.category && (
                                                                                    <div className="col-12">
                                                                                        <h4>{role.category}</h4>
                                                                                    </div>
                                                                                )}

                                                                                <div className='m-1 p-2'>
                                                                                    <Checkbox inputId={"role" + index} disabled={selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)} name="Reporter" value="Reporter" onChange={() => { onCheckBoxChecked(role.id) }} checked={(selectedObj.roles.includes(role.id) || (selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)))} />
                                                                                    <label htmlFor={"role" + index} className="ml-2">{role.title}</label>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                }

                                                            })

                                                            }
                                                        </div>
                                                    </div>}
                                                </>

                                            )
                                        }
                                    })

                                    }

                                    <div className="flex justify-content-evenly p-4">

                                       {login_data.clientId === 289 &&  <Button
                                            label="Save Assignment"

                                            onClick={saveAssignment}
                                        />}
                                    </div>

                                </div>}


                        </div>
                        <div className='col-6'>
                            <label className='fw-7 fs-18 clr-navy mb-2'> List of Role Assigned by Location </label>
                            <div style={{ height: 400, overflow: 'auto' }} >
                                {allLocationRoles.map((locationRole, index) => {
                                    // Initialize an array to hold the names for each level
                                    let locationNames = [];
                                    const roleNames = locationRole.roles.map(roleId => getRoleNameById(roleId));
                                    console.log(locationRole)
                                    // Function to get name by ID or handle special 'all' cases
                                    const getLocationName = (id, locationArray, allText) => {

                                        if (id === null) return ''; // Return empty if ID is not set
                                        if (id === 0) return allText; // Handle 'all' cases
                                        const location = locationArray.find(location => location.id === id);
                                        return location ? location.name : 'Unknown';
                                    };

                                    // Get names for each level
                                    locationNames.push(getLocationName(locationRole.tier1_id, rawsitelist, 'All Country'));
                                    locationNames.push(getLocationName(locationRole.tier2_id, rawsitelist.flatMap(i => i.locationTwos), 'All Region'));
                                    locationNames.push(getLocationName(locationRole.tier3_id, rawsitelist.flatMap(i => i.locationTwos.flatMap(i => i.locationThrees)), 'All Business Unit'));

                                    // Filter out empty or unknown locations before joining
                                    locationNames = locationNames.filter(name => name && name !== 'Unknown');
                                    if (roleNames.length) {
                                        return (
                                            <React.Fragment key={index}>
                                                <div>
                                                    {locationNames.join(' > ')} {/* Join names with ' > ' for breadcrumb style display */}
                                                    <ul>
                                                        {roleNames.map((name, roleIndex) => (
                                                            <li key={roleIndex}>{name}</li> // Render each role name in a bullet point
                                                        ))}
                                                    </ul>
                                                </div>
                                                <hr />
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>

                </Dialog>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )

}
export default UserListing

