import html2canvas from "html2canvas";

const exportAsImage = async (el) => {
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
  
    return image;
};


export default exportAsImage;