import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomLegend } from "./Dashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomMixLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 1 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

const data = {
  2019: [
    { month: "Jan", Renewable: 30, NonRenewable: 70 },
    { month: "Feb", Renewable: 40, NonRenewable: 60 },
    { month: "Mar", Renewable: 40, NonRenewable: 60 },
    { month: "Apr", Renewable: 80, NonRenewable: 20 },
    { month: "May", Renewable: 100, NonRenewable: 0 },
    { month: "Jun", Renewable: 60, NonRenewable: 40 },
    { month: "Jul", Renewable: 50, NonRenewable: 50 },
    { month: "Aug", Renewable: 40, NonRenewable: 60 },
    { month: "Sep", Renewable: 10, NonRenewable: 90 },
    { month: "Oct", Renewable: 30, NonRenewable: 70 },
    { month: "Nov", Renewable: 90, NonRenewable: 10 },
    { month: "Dec", Renewable: 0, NonRenewable: 100 },
  ],
  2020: [
    { month: "Jan", Renewable: 75, NonRenewable: 25 },
    { month: "Feb", Renewable: 32, NonRenewable: 68 },
    { month: "Mar", Renewable: 45, NonRenewable: 55 },
    { month: "Apr", Renewable: 85, NonRenewable: 15 },
    { month: "May", Renewable: 10, NonRenewable: 90 },
    { month: "Jun", Renewable: 45, NonRenewable: 55 },
    { month: "Jul", Renewable: 50, NonRenewable: 50 },
    { month: "Aug", Renewable: 65, NonRenewable: 35 },
    { month: "Sep", Renewable: 15, NonRenewable: 85 },
    { month: "Oct", Renewable: 70, NonRenewable: 30 },
    { month: "Nov", Renewable: 35, NonRenewable: 65 },
    { month: "Dec", Renewable: 25, NonRenewable: 75 },
  ],
  2021: [
    { month: "Jan", Renewable: 40, NonRenewable: 60 },
    { month: "Feb", Renewable: 38, NonRenewable: 62 },
    { month: "Mar", Renewable: 48, NonRenewable: 52 },
    { month: "Apr", Renewable: 90, NonRenewable: 10 },
    { month: "May", Renewable: 15, NonRenewable: 85 },
    { month: "Jun", Renewable: 50, NonRenewable: 50 },
    { month: "Jul", Renewable: 55, NonRenewable: 45 },
    { month: "Aug", Renewable: 70, NonRenewable: 30 },
    { month: "Sep", Renewable: 55, NonRenewable: 45 },
    { month: "Oct", Renewable: 75, NonRenewable: 25 },
    { month: "Nov", Renewable: 40, NonRenewable: 60 },
    { month: "Dec", Renewable: 28, NonRenewable: 72 },
  ],
  2022: [
    { month: "Jan", Renewable: 45, NonRenewable: 55 },
    { month: "Feb", Renewable: 42, NonRenewable: 58 },
    { month: "Mar", Renewable: 50, NonRenewable: 50 },
    { month: "Apr", Renewable: 95, NonRenewable: 5 },
    { month: "May", Renewable: 20, NonRenewable: 80 },
    { month: "Jun", Renewable: 25, NonRenewable: 75 },
    { month: "Jul", Renewable: 60, NonRenewable: 40 },
    { month: "Aug", Renewable: 75, NonRenewable: 25 },
    { month: "Sep", Renewable: 60, NonRenewable: 40 },
    { month: "Oct", Renewable: 20, NonRenewable: 80 },
    { month: "Nov", Renewable: 45, NonRenewable: 55 },
    { month: "Dec", Renewable: 45, NonRenewable: 55 },
  ],
  2023: [
    { month: "Jan", Renewable: 50, NonRenewable: 50 },
    { month: "Feb", Renewable: 48, NonRenewable: 52 },
    { month: "Mar", Renewable: 55, NonRenewable: 45 },
    { month: "Apr", Renewable: 100, NonRenewable: 0 },
    { month: "May", Renewable: 15, NonRenewable: 85 },
    { month: "Jun", Renewable: 60, NonRenewable: 40 },
    { month: "Jul", Renewable: 65, NonRenewable: 35 },
    { month: "Aug", Renewable: 80, NonRenewable: 20 },
    { month: "Sep", Renewable: 65, NonRenewable: 35 },
    { month: "Oct", Renewable: 85, NonRenewable: 15 },
    { month: "Nov", Renewable: 50, NonRenewable: 50 },
    { month: "Dec", Renewable: 73, NonRenewable: 27 },
  ],
  2024: [
    { month: "Jan", Renewable: 55, NonRenewable: 45 },
    { month: "Feb", Renewable: 52, NonRenewable: 48 },
    { month: "Mar", Renewable: 60, NonRenewable: 40 },
    { month: "Apr", Renewable: 80, NonRenewable: 20 },
    { month: "May", Renewable: 30, NonRenewable: 70 },
    { month: "Jun", Renewable: 65, NonRenewable: 35 },
    { month: "Jul", Renewable: 70, NonRenewable: 30 },
    { month: "Aug", Renewable: 85, NonRenewable: 15 },
    { month: "Sep", Renewable: 70, NonRenewable: 30 },
    { month: "Oct", Renewable: 90, NonRenewable: 10 },
    { month: "Nov", Renewable: 55, NonRenewable: 45 },
    { month: "Dec", Renewable: 85, NonRenewable: 15 },
  ],
};

export const EnergyConsumptionChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = data[year1].map((item, index) => ({
    month: item.month,
    [`${year1} Renewable`]: item.Renewable,
    [`${year1} NonRenewable`]: item.NonRenewable,
    [`${year2} Renewable`]: data[year2][index].Renewable,
    [`${year2} NonRenewable`]: data[year2][index].NonRenewable,
  }));
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Energy Breakup
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Energy Consumption Mix
          </div>
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(data)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(data)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={combinedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: "Percentage % ",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />
            <Tooltip />
            <Legend content={CustomMixLegend} />
            <Bar
              dataKey={`${year1} Renewable`}
              stackId="a"
              fill="#4C8BF5"
              barSize={30}
              name="Renewable"
            >
              {" "}
            </Bar>
            <Bar
              dataKey={`${year1} NonRenewable`}
              stackId="a"
              fill="#AFC8F9"
              barSize={30}
              name="Non-Renewable"
            ></Bar>
            <Bar
              dataKey={`${year2} Renewable`}
              stackId="b"
              fill="#4C8BF5"
              barSize={30}
            >
              {" "}
              <LabelList
                dataKey="Renewable"
                position="insideBottom"
                fill="white"
              />
            </Bar>
            <Bar
              dataKey={`${year2} NonRenewable`}
              stackId="b"
              fill="#AFC8F9"
              barSize={30}
            >
              <LabelList dataKey="Renewable" position="top" fill="black" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={data[year1].map((item, index) => ({
              ...item,
              [`renewable_${year2}`]: data[year2][index].Renewable,
              [`nonRenewable_${year2}`]: data[year2][index].NonRenewable,
            }))}
            tableClassName="font-lato"
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Renewable ${year1}} (%)`}
              style={{ minWidth: "8%" }}
              field="Renewable"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Non-Renewable ${year1}} (%)`}
              style={{ minWidth: "8%" }}
              field="NonRenewable"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Renewable ${year2}} (%)`}
              style={{ minWidth: "8%" }}
              field={`renewable_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Renewable ${year2}} (%)`}
              style={{ minWidth: "8%" }}
              field={`nonRenewable_${year2}`}
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};
