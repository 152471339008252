import React from 'react';
import '../Styles/header.scss'


export default function Header({ name, email }) {
  return (
    <div className='container'>
      <div className='flex-box' style={{ marginBottom: '2%' }}>
        <h2 className='remove-bottom-margin fw-6 fs-24'>Welcome {name}!</h2>
        <p style={{ marginLeft: '2%' }} className='flex-align'>&lt;{email}&gt;</p>
      </div>
      <div>
        <div className='flex-box' style={{ marginBottom: '1%' }}>
          <h4 className='remove-bottom-margin fw-7 fs-20'>Luminous Materiality Assessment 2024</h4>
          <div className='status-box-active fw-7 fs-14' style={{ marginLeft: '1%' }}>
            <div className="status-circle-active"></div>
            Active
          </div>
          <div className='status-box-inactive' style={{ marginLeft: '1%' }}>
            <div className="status-circle-inactive"></div>
            Inactive
          </div>
        </div>
        <div className='flex-box flex-align link' style={{ marginBottom: '2%' }}>
          <div className='link-box'>https://materiality-client.web.app/questionnaire/MTAw</div>
          <div className='copy-icon'>copy</div>
          <p style={{ marginLeft: '1%', fontSize: '14px' }}>Copy Link</p>
        </div>
      </div>
    </div>
  );
}
