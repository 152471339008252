// import React, { Component, useEffect, useState } from 'react'
// import { API_URL, NESTED_SUBTOPIC_URL, SUBTOPICS_URL, TOPICS_URL } from '../constants'
// import PageTitle from '../shared/PageTitle'
// import Breadcrumbs from '../shared/Breadcrumbs'
// import AddList from '../shared/AddList'
// import SubTopicList from '../shared/SubTopicList'
// import { Alert } from 'react-bootstrap'
// import SubTopicEdit from '../shared/SubTopicEdit'
// import List from '../shared/List'
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ManageTopic=()=>{

//         const [topics, setTopics] = useState([])
//         const [selectedTopic, setSelectedTopic] = useState('')
//         const [subTopics, setSubTopics] = useState([])
//         const [selectedSubTopic, setSelectedSubTopic] = useState('')
//         const [subTopicData, setSubTopicData] = useState({ description: '' })

//         useEffect(() => {
//             getTopics();
//         }, [])

//         const getTopics = async () => {
//             const response = await fetch(TOPICS_URL);
//             if (response.ok) {
//                 const data = await response.json();
//                 setTopics(data)
//             }
//         }

//         const handleAddTopicTitle = async (topicTitle) => {

//             const response = await fetch(TOPICS_URL, {
//                 method: 'POST',
//                 body: JSON.stringify({
//                     name: topicTitle.current.value,

//                 }),
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });

//             const data = await response.json()

//             setTopics((prevState) => [...prevState, { id: data.id, name: data.name }])

//         }

//         const handleAddSubTopicTitle = async (subTopicTitle) => {
//             const url = TOPICS_URL + '/' + selectedTopic + NESTED_SUBTOPIC_URL;
//             const response = await fetch(url, {
//                 method: 'POST',
//                 body: JSON.stringify({
//                     name: subTopicTitle.current.value,

//                 }),
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });

//             const data = await response.json()

//             setSubTopics((prevState) => [...prevState, { id: data.id, name: data.name }])

//         }

//         const handleTopicSelect = async (e, id) => {
//             e.preventDefault();
//             console.log(id)
//             setSelectedTopic(id)
//             setSelectedSubTopic('')
//             setSubTopicData({ description: '' })
//             const url = TOPICS_URL + '/' + id + NESTED_SUBTOPIC_URL;
//             const response = await fetch(url);
//             if (response.ok) {
//                 const data = await response.json()
//                 setSubTopics(data)
//             }


//         }


//         const handleSubTopicSelect = async (e, id) => {
//             e.preventDefault();
//             setSelectedSubTopic(id);
//             const url = SUBTOPICS_URL + '/' + id;
//             const response = await fetch(url);
//             if (response.ok) {
//                 const data = await response.json()
//                 setSubTopicData(data)
//             }



//         }

//         const handleTopicChange = async (value, id) => {


//             const url = TOPICS_URL + '/' + id;
//             const response = await fetch(url, {
//                 method: 'PATCH',
//                 body: JSON.stringify({
//                     name: value,

//                 }),
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });


//             setTopics((prevState) => prevState.map((i) => {
//                 if (i.id === id) {
//                     i.name = value
//                 }
//                 return i
//             }))

//         }

//         const handleSubTopicChange = async (value, id) => {


//             const url = SUBTOPICS_URL + '/' + id;
//             const response = await fetch(url, {
//                 method: 'PATCH',
//                 body: JSON.stringify({
//                     name: value,

//                 }),
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });


//             setSubTopics((prevState) => prevState.map((i) => {
//                 if (i.id === id) {
//                     i.name = value
//                 }
//                 return i
//             }))

//         }

//         const handleSubTopicSubmit = async (value) => {

//             const url = SUBTOPICS_URL + '/' + selectedSubTopic;
//             const response = await fetch(url, {
//                 method: 'PATCH',
//                 body: JSON.stringify({
//                     description: value,

//                 }),
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });


//             setSubTopicData((prevState) => { return { ...prevState, description: value } })
//         }

//         const handleTopicDelete = async (id) => {


//             const url = TOPICS_URL + '/' + id;
//             const response = await fetch(url, {
//                 method: 'DELETE',

//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });


//             setTopics((prevState) => prevState.filter(i => i.id !== id))

//             setSubTopicData({})
//             setSelectedTopic('')
//             setSelectedSubTopic('')


//         }


//         const handleSubTopicDelete = async (id) => {


//             const url = SUBTOPICS_URL + '/' + id;
//             const response = await fetch(url, {
//                 method: 'DELETE',

//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//             });


//             setSubTopics((prevState) => prevState.filter(i => i.id !== id))

//             setSubTopicData({})
//             setSelectedSubTopic('')

//         }


//         return (
//             <div>
//                 <div className="page-header">

//                     <PageTitle title="Topic Management" />
//                     <Breadcrumbs pages={['Manage Topics']} />

//                 </div>

//                 <div className="">
//                     <div className="row">
//                         <div className="col-3 pt-4 pb-4 pr-4 pl-4 border-right bg-white">


//                             <AddList onClick={handleAddTopicTitle} title='Topic' />

//                             {topics.length > 0 && <List topics={topics} onDelete={handleTopicDelete} listId={selectedTopic} onListChange={handleTopicChange} onClick={handleTopicSelect} />}

//                             {topics.length === 0 && <p className='text-center'>No Topics Found</p>}



//                         </div>

//                         <div className="col-4 pt-4 pb-4 pr-4 pl-4 border-right bg-white">
//                             {selectedTopic && <AddList onClick={handleAddSubTopicTitle} title='Sub Topic' />}
//                             {!selectedTopic && <p className='text-center'>Please select any topic to continue</p>}

//                             {(selectedTopic && subTopics.length > 0) && <List onDelete={handleSubTopicDelete} listId={selectedSubTopic} onListChange={handleSubTopicChange} onClick={handleSubTopicSelect} topics={subTopics} />}
//                             {(selectedTopic && subTopics.length === 0) &&

//                                 (<Alert variant='warning'>
//                                     No Sub Topics Found.
//                                 </Alert>)}
//                         </div>
//                         <div className="d-none d-md-block col-5 bg-white">
//                             {selectedSubTopic && <SubTopicEdit onSubmit={handleSubTopicSubmit} id={subTopicData.id} description={subTopicData.description} />}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )

// }

// export default ManageTopic

import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert, Form } from 'react-bootstrap';
import { API_URL, NESTED_SUBTOPIC_URL, STANDARDS_URL, SUBTOPICS_URL, TOPICS_URL } from '../constants';
import PageTitle from '../shared/PageTitle';
import Breadcrumbs from '../shared/Breadcrumbs';
import AddList from '../shared/AddList';
import List from '../shared/List';
import SubTopicEdit from '../shared/SubTopicEdit';
import 'bootstrap/dist/css/bootstrap.min.css';
import APIServices from '../../service/APIService';
import { API } from '../../constants/api_url';
import ClientSelect from '../shared/ClientSelect';
import { Dialog } from 'primereact/dialog';
import { MdMargin } from 'react-icons/md';
import CreatableSelect from 'react-select/creatable';


const ManageTopic = (props) => {
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');
    const [subTopics, setSubTopics] = useState([]);
    const [selectedSubTopic, setSelectedSubTopic] = useState('');
    const [subTopicData, setSubTopicData] = useState({ description: '' });
    const [showModal, setShowModal] = useState(false);
    const [newSubTopic, setNewSubTopic] = useState({ name: '', clients: [] });
    const [clientList, setClientList] = useState([]);

    const [client, setClients] = useState([])
    const [values, setValues] = useState([])
    const [clientIdList, setClientIdList] = useState([])

    useEffect(() => {
        getTopics();
        fetchClientList();
        getClientIdOptions();
    }, []);

    useEffect(() => {
        const fetchClientList = async () => {
            try {
                const res = await APIServices.get(API.UserProfile)
                const datas = res.data.filter(item => item.role === "clientadmin").map(item => ({
                    id: item.id,
                    contactpersonmail: item.information.contactpersonmail,
                    contactpersonno: item.information.contactpersonno,
                    companyname: item.information.companyname,
                    contactperson: item.information.contactperson,
                    email: item.email,
                    currency: item.information.currency ?? null,
                    config: item.information.config,
                    companylogo: item.information.companylogo
                }))
                setClientList(datas)
            } catch (error) {
                console.error('Failed to fetch clients:', error)
            }
        }

        if (props.id) {
            fetchClientList()
            getValues()
        }
    }, [props.id])

    const getValues = async () => {
        try {
            const response = await fetch(`${SUBTOPICS_URL}/${props.id}`)
            if (response.ok) {
                const data = await response.json()
                setValues(JSON.parse(data.standards))
            }
        } catch (error) {
            console.error('Failed to fetch values:', error)
        }
    }
    const handleOnChangeClientValues = (newValues) => {
        setNewSubTopic((prev)=> ({...prev, clients:newValues}))
    }

    const  getClientIdOptions = async () => {
        const response = await fetch(API.UserProfile);
        if(response.ok) {
          const data = await response.json();
          setClientIdList(data.filter(i=> i.role === "clientadmin" ).map((i) => {
              return {label: i.information.enterpriseid, value: i.id}

          }).filter(i => i.label && i.label.length !== 0 ) )
          //   setTopics(data)
        }
      }




    const fetchOptions = (method, body = null) => ({
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : null,
    });

    const getTopics = async () => {
        try {
            const response = await fetch(TOPICS_URL);
            if (response.ok) {
                const data = await response.json();
                setTopics(data);
            }
        } catch (error) {
            console.error('Failed to fetch topics:', error);
        }
    };

    const fetchClientList = async () => {
        try {
            const response = await fetch(API_URL + '/clients'); // Adjust the endpoint to your API's clients endpoint
            if (response.ok) {
                const data = await response.json();
                setClientList(data);
            }
        } catch (error) {
            console.error('Failed to fetch clients:', error);
        }
    };

    const handleAddTopicTitle = async (topicTitle) => {
        try {
            const response = await fetch(TOPICS_URL, fetchOptions('POST', { name: topicTitle.current.value }));
            const data = await response.json();
            setTopics(prevTopics => [...prevTopics, { id: data.id, name: data.name }]);
        } catch (error) {
            console.error('Failed to add topic:', error);
        }
    };

    const handleAddSubTopicTitle = async () => {
        const url = `${TOPICS_URL}/${selectedTopic}${NESTED_SUBTOPIC_URL}`;
        let newObj = {name:newSubTopic.name, clients:newSubTopic.clients.map(i=>i.value)}

        try {
            APIServices.post(url, newObj).then((res) => {
                
                let loctopics = JSON.parse(JSON.stringify(subTopics))
                
                    
                    loctopics = [...loctopics, res.data]

                    setSubTopics(loctopics)
                    handleCloseModal()
                   

            })
        } catch (error) {
            console.error('Failed to add sub-topic:', error);
        }
    };

    const handleTopicSelect = async (e, id) => {
        e.preventDefault();
        setSelectedTopic(id);
        setSelectedSubTopic('');
        setSubTopicData({ description: '' });
        try {
            const url = `${TOPICS_URL}/${id}${NESTED_SUBTOPIC_URL}`;
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                setSubTopics(data);
            }
        } catch (error) {
            console.error('Failed to fetch sub-topics:', error);
        }
    };

    const handleSubTopicSelect = async (e, id) => {
        e.preventDefault();
        setSelectedSubTopic(id);
        try {
            const url = `${SUBTOPICS_URL}/${id}`;
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                setSubTopicData(data);
            }
        } catch (error) {
            console.error('Failed to fetch sub-topic data:', error);
        }
    };

    const handleTopicChange = async (value, id) => {
        try {
            const url = `${TOPICS_URL}/${id}`;
            await fetch(url, fetchOptions('PATCH', { name: value }));
            setTopics(prevTopics => prevTopics.map(topic => (topic.id === id ? { ...topic, name: value } : topic)));
        } catch (error) {
            console.error('Failed to update topic:', error);
        }
    };

    const handleSubTopicChange = async (value, id) => {
        try {
            const url = `${SUBTOPICS_URL}/${id}`;
            await fetch(url, fetchOptions('PATCH', { name: value }));
            setSubTopics(prevSubTopics => prevSubTopics.map(subTopic => (subTopic.id === id ? { ...subTopic, name: value } : subTopic)));
        } catch (error) {
            console.error('Failed to update sub-topic:', error);
        }
    };

    const handleSubTopicSubmit = async (value) => {
        try {
            const url = `${SUBTOPICS_URL}/${selectedSubTopic}`;
            await fetch(url, fetchOptions('PATCH', { description: value }));
            setSubTopicData(prevData => ({ ...prevData, description: value }));
        } catch (error) {
            console.error('Failed to update sub-topic description:', error);
        }
    };

    const handleTopicDelete = async (id) => {
        try {
            const url = `${TOPICS_URL}/${id}`;
            await fetch(url, fetchOptions('DELETE'));
            setTopics(prevTopics => prevTopics.filter(topic => topic.id !== id));
            setSubTopicData({});
            setSelectedTopic('');
            setSelectedSubTopic('');
        } catch (error) {
            console.error('Failed to delete topic:', error);
        }
    };

    const handleSubTopicDelete = async (id) => {
        try {
            const url = `${SUBTOPICS_URL}/${id}`;
            await fetch(url, fetchOptions('DELETE'));
            setSubTopics(prevSubTopics => prevSubTopics.filter(subTopic => subTopic.id !== id));
            setSubTopicData({});
            setSelectedSubTopic('');
        } catch (error) {
            console.error('Failed to delete sub-topic:', error);
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setNewSubTopic(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <div>
            <div className="page-header">
                <PageTitle title="Topic Management" />
                <Breadcrumbs pages={['Manage Topics']} />
            </div>

            <div className="row">
                <div className="col-3 pt-4 pb-4 pr-4 pl-4 border-right bg-white">
                    <AddList onClick={handleAddTopicTitle} title='Topic' />
                    {topics.length > 0
                        ? <List topics={topics} onDelete={handleTopicDelete} listId={selectedTopic} onListChange={handleTopicChange} onClick={handleTopicSelect} />
                        : <p className='text-center'>No Topics Found</p>
                    }
                </div>

                <div className="col-4 pt-4 pb-4 pr-4 pl-4 border-right bg-white">
                    {selectedTopic
                        ? <div>
                            <Button onClick={handleShowModal}>Add Sub Topic</Button>
                            <Dialog
                                visible={showModal}
                                style={{ width: "450px" }}
                                header="Add Sub Topic"
                                modal
                                className="p-fluid"

                                onHide={handleCloseModal}
                            >
                                <Form>
                                    <Form.Group>
                                        {/* <Form.Label>Sub Topic Name</Form.Label> */}
                                        <Form.Label><h6 className="mt-5">Sub Topic Name </h6></Form.Label>

                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Sub Topic Name"
                                            value={newSubTopic.name}
                                            onChange={handleModalInputChange}
                                        />
                                    </Form.Group>
                                    {/* <Form.Group>
                                            <Form.Label>Company Shortform</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="companyShortform"
                                                value={newSubTopic.companyShortform}
                                                onChange={handleModalInputChange}
                                            >
                                                <option value="">Select Company</option>
                                                {clientList.map(client => (
                                                    <option key={client.id} value={client.companyname}>
                                                        {client.companyname}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group> */}
                                    <Form.Group>
                                        <Form.Label><h6 className="mt-5">Choosen Clients </h6></Form.Label>
                                        
                                        <CreatableSelect
                                            isMulti
                                            onChange={handleOnChangeClientValues}
                                            // onCreateOption={handleAddOptions}
                                            value={newSubTopic.clients}
                                            options={clientIdList}
                                        />
                                    </Form.Group>
                                </Form>
                                <div> <Button variant="secondary" onClick={handleCloseModal}>
                                    Close
                                </Button>
                                    <Button variant="primary" onClick={handleAddSubTopicTitle}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                        : <p className='text-center'>Please select any topic to continue</p>
                    }
                    {selectedTopic && subTopics.length > 0
                        ? <List onDelete={handleSubTopicDelete} listId={selectedSubTopic} onListChange={handleSubTopicChange} onClick={handleSubTopicSelect} topics={subTopics} isSubTopic={true}/>
                        : selectedTopic && (
                            <Alert variant='warning'>
                                No Sub Topics Found.
                            </Alert>
                        )
                    }
                </div>

                <div className="d-none d-md-block col-5 bg-white">
                    {selectedSubTopic && <SubTopicEdit onSubmit={handleSubTopicSubmit} id={subTopicData.id} description={subTopicData.description} />}
                </div>
            </div>
        </div>
    );
};

export default ManageTopic;

