
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";

const initialState = {

    rf: [],
    dcf: []

}


export const fetchRFLibrary = createAsyncThunk('api/library', async (id) => {
    return APIServices.get(API.RF).then((res) => res.data)
})


const Library = createSlice({
    name: 'library',
    initialState,

    extraReducers: (builder) => {

        builder.addCase(fetchRFLibrary.fulfilled, (state, action) => { return { ...state, rf: action.payload } })

    }



})

export default Library.reducer


