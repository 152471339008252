import React from 'react'
import '../Styles/timeeline.scss'

export default function TimelineCard(props) {
    const { subHeader, header, content } = props;
    return (
        <div className='card'>
            <div className='sub-header fs-10 fw-5'>
                {subHeader}
            </div>
            <div className='header fs-16 fw-7'>{header}</div>
            <div className='timeline-content fw-4 fs-12'>{content}</div>
        </div>
    )
}
