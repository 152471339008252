import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const locations = [
    { label: 'India', value: 'India' },
    { label: 'Singapore', value: 'Singapore' },
    // Add more locations as needed
];

const cities = [
    { label: 'Mumbai', value: 'Mumbai' },
    { label: 'Pune', value: 'Pune' },
    // Add more cities as needed
];

const sites = [
    { label: 'Site 1', value: 'Site 1' },
    { label: 'Site 2', value: 'Site 2' },
    // Add more sites as needed
];

const actions = [
    {
        action: 'In Tamil Nadu operations, increase the contribution of renewable energy in the energy...',
        targetDate: '23-Dec-2023',
        status: 'Ahead of time',
        responsibility: 'Singapore',
        statusColor: '#315975'
    },
    {
        action: 'Replacing furnace oil boilers with biomass boilers in Indore unit',
        targetDate: '23-Dec-2023',
        status: 'Under progress',
        responsibility: 'Pune',
        statusColor: '#F5C37B'
    },
    {
        action: 'Improving the efficiency of all boilers in all the plants by modifying the starter',
        targetDate: '23-Dec-2023',
        status: 'Under progress',
        responsibility: 'Mumbai',
        statusColor: '#F5C37B'
    },
    {
        action: 'Et dolorum et distinctio voluptatem est inventore quibusdam nulla.',
        targetDate: '23-Dec-2023',
        status: 'Completed',
        responsibility: 'Chandigarh',
        statusColor: '#5B8FF7'
    },
    {
        action: 'Tenetur minima facilis odio reiciendis nesciunt repellat cupiditate.',
        targetDate: '23-Dec-2023',
        status: 'Achieved',
        responsibility: 'Mumbai',
        statusColor: '#29C76F'
    }
];

const ActionTracker = ({locationData}) => {
    
    const [globalFilter, setGlobalFilter] = useState('');
    const [filter, setFilter] = useState({ year: null, category: 0, country: 0, city: null, location: null })
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    useEffect(()=>{
        setLocList((prev) => ({ ...prev, country: [{ name: 'All Countries', id: 0 }, ...locationData.map(i => ({ name: i.name, id: i.id }))] }))

    },[locationData])


    const statusBodyTemplate = (rowData) => {
        return (
            <span>
                <i className="pi pi-circle-on" style={{ color: rowData.statusColor, marginRight: '5px' }}></i>
                {rowData.status}
            </span>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <span>
                {rowData.action}
            </span>
        );
    };
    const updateLocationFilter = (obj, val) => {
        let item = { ...filter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All Countries', id: 0 }]
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        locationData.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        if (obj === 'country') {
            item.city = val === 0 ? null : 0
            item.location = null
        }
        else if (obj === 'city') {

            item.location = val === 0 ? null : 0
        }

        setFilter(item)

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
    
    }
    return (
        <div className="card">
            <h3>Action Tracker</h3>
            <div className="d-flex justify-content-between align-items-center p-grid p-align-center p-justify-start p-mb-3">
                <div className="p-col-12 col-3 p-md-3 p-lg-2">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search"
                            style={{ width: '100%' }}
                        />
                    </span>
                </div>
                <div className='col-1'>Location :</div>
                <div className='row col-8 m-0 p-0'>
        
                <div className='grid m-0 col-12' >
            
                            <div className='col-4' >

                                <Dropdown value={filter.country} options={locList.country} onChange={(e) => { updateLocationFilter('country', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Country" style={{width:'100%'}} />
                            </div>
                            {(filter.country !== 0 && (filter.city !== null || filter.city === 0)) && <div className='col-4' >
                                <Dropdown value={filter.city} options={locList.city} onChange={(e) => { updateLocationFilter('city', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Region" disabled={!locList.city.length}  style={{width:'100%'}}   />
                            </div>}
                            {(filter.city !== 0 && filter.location !== null) && <div className='col-4' >
                                <Dropdown value={filter.location} options={locList.location} onChange={(e) => { updateLocationFilter('location', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Business Unit" disabled={!locList.location.length} style={{width:'100%'}} />
                            </div>}
                        </div>
                </div>

            </div>
            <DataTable value={actions} globalFilter={globalFilter}>
                <Column field="action" header={`Identified Action (${actions.length})`} body={actionBodyTemplate} />
                <Column field="targetDate" header="Target Date" />
                <Column field="status" header="Status" body={statusBodyTemplate} />
                <Column field="responsibility" header="Responsibility" />
            </DataTable>
        </div>
    );
};

export default ActionTracker;
