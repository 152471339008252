import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from 'luxon';
const arrows = ['↓','↑',]
function generateHeaders(currentYear, fymonth,initialHeader) {
    const headers = [initialHeader];
    let startMonth, startYear, endMonth, endYear;
if(!currentYear){
    return []
}
    // If fymonth is 1 (calendar year case)
    if (fymonth === 1) {
        startMonth = 1; // January
        startYear = currentYear;
        endMonth = 12; // December
        endYear = currentYear;
    } else {
        // Fiscal year case, starting from `fymonth` in the previous year
        startMonth = fymonth; // Start from the fiscal month
        startYear = currentYear - 1; // Start in the previous year
        endMonth = fymonth - 1; // End in the month before the fiscal month (March)
        endYear = currentYear;
    }

    // Generate the month headers
    for (let i = 0; i < 12; i++) {
        const month = DateTime.fromObject({
            year: startYear,
            month: startMonth
        }).plus({ months: i });
        
        const formattedMonth = month.toFormat('MMM-yyyy');
        headers.push({ "header": month.toFormat('MMM'), "field": formattedMonth });
    }

    // Add the fiscal year column
    const fiscalYearHeader = `FY${startYear.toString().slice(-2)} - FY${endYear.toString().slice(-2)}`;
    headers.push({ "header": fiscalYearHeader, "field": fiscalYearHeader });

    return headers;
}

// Function to generate random values for each month
function generateRandomValues(headers, category) {
    const rowData = { category };
    
    headers.forEach(header => {
        // Skip the category field and generate random data for others
        if (header.field !== 'category' && !header.field.includes('FY')) {
            rowData[header.field] = Math.floor(Math.random() * 234) + ""+arrows[ Math.floor(Math.random() * 2)] // Random values between 0 and 100
        }else  if (header.field !== 'category' && header.field.includes('FY')) {
            rowData[header.field] = Math.floor(Math.random() * 75) + '%'; // Random values between 0 and 100
        }
    });

    return rowData;
}

// Function to create the final data structure
function generateWaterData(currentYear, fymonth) {
    console.log(currentYear, fymonth);
    const  headers = generateHeaders(currentYear, fymonth,{ "header": "Water (million m3)", "field": "category" });
    
    const categories = [
        "Water withdrawal excluding non-contact cooling water",
        "Water withdrawal including non-contact cooling water",
        "Third party water",
        "Surface water withdrawal",
        "Groundwater withdrawal",
        "Brackish/ seawater withdrawal",
        "Water discharged",
        "Water consumed"
    ];

    const data = categories.map(category => generateRandomValues(headers, category));
console.log(headers)
    return {
        columns: headers,
        data: data
    };
}
function generateWasteData(currentYear, fymonth) {
    console.log(currentYear, fymonth);
    const  headers = generateHeaders(currentYear, fymonth,{ "header": "Waste", "field": "category" });
    
    const categories = [
        'Hazardous waste generated (million kg)',
        'Hazardous waste diverted from disposal (million kg)',
        'Hazardous waste incinerated with energy recovery (million kg)',
        'Hazardous waste incinerated without energy recovery (million kg)',
        'Hazardous waste landfilled (million kg)',
        'Hazardous waste other treatment (million kg)',
        'Hazardous waste diverted from disposal (%)',
        'Non-hazardous waste generated (million kg)',
        'Non-hazardous waste diverted from disposal (million kg)',
        'Non-hazardous waste incinerated with energy recovery (million kg)',
        'Non-hazardous waste incinerated without energy recovery (million kg)',
        'Non-hazardous waste landfilled (million kg)',
        'Non-hazardous waste other treatment (million kg)',
        'Hazardous waste diverted from disposal (%)'
      ]

    const data = categories.map(category => generateRandomValues(headers, category));

    return {
        columns: headers,
        data: data
    };
}
function generateEmissionData(currentYear, fymonth) {
    console.log(currentYear, fymonth);
    const  headers = generateHeaders(currentYear, fymonth,{ "header": "Air Emissions", "field": "category" });
    
    const categories = [
        'Refrigerant losses (metric ton (mt)) CO2e',
        'Volatile organics (thousand kg)'

      ]

    const data = categories.map(category => generateRandomValues(headers, category));

    return {
        columns: headers,
        data: data
    };
}
function generateLabourData(currentYear, fymonth) {
    console.log(currentYear, fymonth);
    const  headers = generateHeaders(currentYear, fymonth,{ "header": "Labor", "field": "category" });
    
    const categories =[
        'Freedom Of Association',
        'Freely Chosen Employment',
        'Humane Treatment',
        'Nondiscrimination',
        'Sub Contractor And Next-Tier Supplier Responsibility',
        'Wages And Benefits',
        'Worker Grievance/ Complaint Mechanism',
        'Working Hours',
        'Young Workers'
      ]

    const data = categories.map(category => generateRandomValues(headers, category));

    return {
        columns: headers,
        data: data
    };
}
function generateEthicalData(currentYear, fymonth) {
    console.log(currentYear, fymonth);
    const  headers = generateHeaders(currentYear, fymonth,{ "header": "Ethical Behaviour", "field": "category" });
    
    const categories =[
        'Emergency Preparedness And Response',
        'Industrial Hygiene',
        'Machine Safeguarding',
        'Sanitation, Dormitory, And Canteen'
      ]
      

    const data = categories.map(category => generateRandomValues(headers, category));

    return {
        columns: headers,
        data: data
    };
}
function generateHealthData(currentYear, fymonth) {
    console.log(currentYear, fymonth);
    const  headers = generateHeaders(currentYear, fymonth,{ "header": "Health And Safety", "field": "category" });
    
    const categories =[
       "Business Integrity",
       "Transparency"
      ]

    const data = categories.map(category => generateRandomValues(headers, category));

    return {
        columns: headers,
        data: data
    };
}
const wasteData = {
    "columns": [
        { "header": "Waste", "field": "category" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" },
        { "header": "2020", "field": "2020" },
        { "header": "2020", "field": "2020_2" },
        { "header": "2020", "field": "2020_3" },
        { "header": "2020", "field": "2020_4" },
        { "header": "2020", "field": "2020_5" },
        { "header": "2021-2022", "field": "2021_2022" },
        { "header": "2019-2022", "field": "2019_2022" },
        { "header": "GRI Indicator", "field": "gri_indicator" }
    ],
    "data": [
        { "category": "Hazardous waste generated (million kg)", "2021": 86.4, "2022": 83.1, "2020": 75.1, "2020_2": 76.7, "2020_3": 76.7, "2020_4": 76.7, "2020_5": 76.7, "2021_2022": "↑ 2%", "2019_2022": "↓ 11%", "gri_indicator": "306-3" },
        { "category": "Hazardous waste diverted from disposal (million kg)", "2021": 16.8, "2022": 11.6, "2020": 11.9, "2020_2": 7.5, "2020_3": 7.5, "2020_4": 7.5, "2020_5": 7.5, "2021_2022": "↑ 37%", "2019_2022": "↓ 55%", "gri_indicator": "306-4" },
        { "category": "Hazardous waste incinerated with energy recovery (million kg)", "2021": 39.8, "2022": 43.6, "2020": 41.6, "2020_2": 47.6, "2020_3": 47.6, "2020_4": 47.6, "2020_5": 47.6, "2021_2022": "↑ 14%", "2019_2022": "↓ 20.8%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste incinerated without energy recovery (million kg)", "2021": 22.2, "2022": 23.1, "2020": 17.7, "2020_2": 17.1, "2020_3": 17.1, "2020_4": 17.1, "2020_5": 17.1, "2021_2022": "↑ 4%", "2019_2022": "↓ 23%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste landfilled (million kg)", "2021": 0.2, "2022": 0.1, "2020": 0.1, "2020_2": 0, "2020_3": 0, "2020_4": 0, "2020_5": 0, "2021_2022": "↑ 28%", "2019_2022": "↓ 76%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste other treatment (million kg)", "2021": 7.4, "2022": 4.7, "2020": 3.9, "2020_2": 4.5, "2020_3": 4.5, "2020_4": 4.5, "2020_5": 4.5, "2021_2022": "↑ 18%", "2019_2022": "↓ 40%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste diverted from disposal (%)", "2021": 19, "2022": 14, "2020": 16, "2020_2": 10, "2020_3": 10, "2020_4": 10, "2020_5": 10, "2021_2022": "-", "2019_2022": "-", "gri_indicator": "306-4" },
        { "category": "Non-hazardous waste generated (million kg)", "2021": 37.7, "2022": 36.9, "2020": 38.5, "2020_2": 35, "2020_3": 35, "2020_4": 35, "2020_5": 35, "2021_2022": "↑ 9%", "2019_2022": "↓ 7%", "gri_indicator": "306-3" },
        { "category": "Non-hazardous waste diverted from disposal (million kg)", "2021": 17.2, "2022": 17.4, "2020": 22.5, "2020_2": 19, "2020_3": 19, "2020_4": 19, "2020_5": 19, "2021_2022": "↑ 16%", "2019_2022": "↓ 11%", "gri_indicator": "306-4" },
        { "category": "Non-hazardous waste incinerated with energy recovery (million kg)", "2021": 4.4, "2022": 4.4, "2020": 4.8, "2020_2": 5, "2020_3": 5, "2020_4": 5, "2020_5": 5, "2021_2022": "↑ 5%", "2019_2022": "↓ 15%", "gri_indicator": "306-5" },
        { "category": "Non-hazardous waste incinerated without energy recovery (million kg)", "2021": 0.9, "2022": 1.3, "2020": 0.6, "2020_2": 0.6, "2020_3": 0.6, "2020_4": 0.6, "2020_5": 0.6, "2021_2022": "↑ 10%", "2019_2022": "↓ 32%", "gri_indicator": "306-5" },
        { "category": "Non-hazardous waste landfilled (million kg)", "2021": 15.1, "2022": 13.7, "2020": 10.2, "2020_2": 10.1, "2020_3": 10.1, "2020_4": 10.1, "2020_5": 10.1, "2021_2022": "↑ 2%", "2019_2022": "↓ 33%", "gri_indicator": "306-5" },
        { "category": "Non-hazardous waste other treatment (million kg)", "2021": 0.1, "2022": 0.2, "2020": 0.2, "2020_2": 0.2, "2020_3": 0.2, "2020_4": 0.2, "2020_5": 0.2, "2021_2022": "↑ 3%", "2019_2022": "↑ 78%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste diverted from disposal (%)", "2021": 46, "2022": 47, "2020": 59, "2020_2": 54, "2020_3": 54, "2020_4": 54, "2020_5": 54, "2021_2022": "-", "2019_2022": "-", "gri_indicator": "306-4" }
    ]
}

const airEmissionData = {
    "columns": [
        { "header": "Air Emissions", "field": "category" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" },
        { "header": "2020", "field": "2020" },
        { "header": "2020", "field": "2020_2" },
        { "header": "2020", "field": "2020_3" },
        { "header": "2020", "field": "2020_4" },
        { "header": "2020", "field": "2020_5" },
        { "header": "2021-2022", "field": "2021_2022" },
        { "header": "2019-2022", "field": "2019_2022" },
        { "header": "GRI Indicator", "field": "gri_indicator" }
    ],
    "data": [
        { "category": "Refrigerant losses (metric ton (mt)) CO2e", "2021": "-", "2022": "-", "2020": "-20,175", "2020_2": "20,175", "2020_3": "20,175", "2020_4": "20,175", "2020_5": "20,175", "2021_2022": "-", "2019_2022": "-", "gri_indicator": "305-6" },
        { "category": "Volatile organics (thousand kg)", "2021": 371.6, "2022": 379.6, "2020": 295.8, "2020_2": 308.3, "2020_3": 308.3, "2020_4": 308.3, "2020_5": 308.3, "2021_2022": "↑ 4%", "2019_2022": "↓ 17%", "gri_indicator": "305-7" }
    ]
}


const laborData = {
    "columns": [
        { "header": "Labor", "field": "category" },
        { "header": "2019", "field": "2019" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" }
    ],
    "data": [
        { "category": "Freedom Of Association", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Freely Chosen Employment", "2019": 2021, "2020": 2021, "2021": 2021, "2022": 2022, "2021_2022": "YOY%" },
        { "category": "Humane Treatment", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Nondiscrimination", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Sub Contractor And Next-Tier Supplier Responsibility", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Wages And Benefits", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Worker Grievance/ Complaint Mechanism", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Working Hours", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Young Workers", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" }
    ]
}

const ethicalData = {
    "columns": [
        { "header": "Ethical Behavior", "field": "category" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" }
    ],
    "data": [
        { "category": "Business Integrity", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Transparency", "2020": 2021, "2021": 2021, "2022": 2022, "2021_2022": "YOY%" }
    ]
}

const healthData = {
    "columns": [
        { "header": "Health And Safety", "field": "category" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" }
    ],
    "data": [
        { "category": "Emergency Preparedness And Response", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Industrial Hygiene", "2020": 2021, "2021": 2021, "2022": 2022, "2021_2022": "YOY%" },
        { "category": "Machine Safeguarding", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Sanitation, Dormitory, And Canteen", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" }
    ]
}


const arrowTemplate = (rowData, column) => {
    let value = rowData[column.field];
    let color;
    if (value.includes('↑')) {
        color = 'green';
    } else if (value.includes('↓')) {
        color = 'red';
    } else if (value.includes('•')) {
        color = 'palegoldenrod';
    }

    return (
        <div className='fs-14 flex'>
              {value.slice(0, -1).trim()} <span style={{color}}> {value.slice(-1).trim()}</span>
        </div>
    );
};

const EnvironmentMetrics = (props) => {
    console.log(props)
    const Legend = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <i className="pi pi-arrow-up" style={{ color: 'green', marginRight: '5px' }}></i> Increase
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <i className="pi pi-arrow-down" style={{ color: 'red', marginRight: '5px' }}></i> Decrease
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="pi pi-circle-on" style={{ color: 'palegoldenrod', marginRight: '5px' }}></i> No Change
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <Legend />
            <DataTable    scrollable showGridlines value={generateWaterData(props.year, props.fymonth).data}>
                {generateWaterData(props.year, props.fymonth).columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                     body={ arrowTemplate }
                    />
                ))}
            </DataTable>
            <br />
            <DataTable  scrollable showGridlines value={generateWasteData(props.year, props.fymonth).data}>
                {generateWasteData(props.year, props.fymonth).columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                     body={ arrowTemplate }
                    />
                ))}
            </DataTable>

            <br />
            <DataTable  scrollable showGridlines value={generateEmissionData(props.year, props.fymonth).data}>
                {generateEmissionData(props.year, props.fymonth).columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                     body={ arrowTemplate }
                    />
                ))}
            </DataTable>
            
            <br />
            <DataTable  scrollable showGridlines value={generateLabourData(props.year, props.fymonth).data}>
                {generateLabourData(props.year, props.fymonth).columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                     body={ arrowTemplate }
                    />
                ))}
            </DataTable>

            <br />
            <DataTable  scrollable showGridlines value={generateEthicalData(props.year, props.fymonth).data}>
                {generateEthicalData(props.year, props.fymonth).columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                     body={ arrowTemplate }
                    />
                ))}
            </DataTable>

            <br />
            <DataTable  scrollable showGridlines value={generateHealthData(props.year, props.fymonth).data}>
                {generateHealthData(props.year, props.fymonth).columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                     body={ arrowTemplate }
                    />
                ))}
            </DataTable>
        </div>
    );
};

export default EnvironmentMetrics;
