import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import { Buffer } from 'buffer';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useForceUpdate from 'use-force-update';
window.Buffer = Buffer;
const Sidebar = (props) => {
    console.log(props)
    const [omenu, setOMenu] = useState({})
    const [menuitems, setMenuItems] = useState(props.menuitems)
    const loc = useLocation()
    const forceUpdate = useForceUpdate()
    useEffect(() => {

        onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });
    }, [])
    const adjustSubMenuPosition = (element) => {

        const rect = element.getBoundingClientRect();
        console.log(rect)
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      
        const isFullyVisible =
          rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth;
      
        const isPartiallyVisible =
          (rect.top >= 0 && rect.top <= windowHeight) || (rect.bottom >= 0 && rect.bottom <= windowHeight);
      console.log(isPartiallyVisible,isFullyVisible)
  if(isPartiallyVisible && !isFullyVisible){
    element.style.top = +6-rect.height +'px'
  }
      };

    const isPathActive = (path) => {

        return loc.pathname.startsWith(path)
    }
    const toggleMenuState = (menuState) => {

        let loc = JSON.parse(JSON.stringify(omenu))

        Object.keys(loc).forEach((i) => {
            console.log(i)
            if (i === menuState) {
                console.log(loc[i])
                loc[i] = !loc[i]
            } else {
                loc[i] = false
            }
        })

        console.log(loc)
        setOMenu(loc)
        forceUpdate()
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.location !== prevProps.location) {
    //         this.onRouteChanged();
    //     }
    // }

    const onRouteChanged = () => {
        document.querySelector('#sidebar').classList.remove('active');
        let loc = {}
        menuitems.forEach((i) => {
            if (i.label !== undefined) {
                loc[i.label] = false
            }
        })
        console.log(loc)
        setOMenu(loc)


    }
    const encodeUserEmail = (userEmail) => {
        const base64Encoded = Buffer.from(userEmail).toString('base64');
        const replacedChars = base64Encoded
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
        const trimmed = replacedChars.replace(/=+$/, '');

        return trimmed;
    };
const onHoverLink= (e)=>{

    if(document.getElementsByClassName('nav-item hover-open') !== null && document.body.className !== ''){
       adjustSubMenuPosition(document.getElementsByClassName('nav-item hover-open')[0].childNodes[1])
    }

  
}
const logout = ()=>{
    props.logout()
}
    return (
        <nav className="sidebar sidebar-offcanvas font-lato fs-16 fw-7" id="sidebar" >
            {/* <div className='sidebar-action' style={{  left: document.body.className === '' ? 285 : 52.25 }}  >
                <div
                    onClick={(e) => { document.body.classList.toggle('sidebar-icon-only'); props.onToggleMenuClick(e) }}
                    style={{
                        cursor: 'pointer',

                        width: '30px',
                        height: '30px',
                        background: 'white',
                        alignItems: 'center',
                        borderRadius: '15px',
                        justifyContent: 'center',
                        display: 'flex'
                    }}>
                    <i className='material-icons' style={{ fontSize: 18, rotate: document.body.className === '' ? '180deg' : '360deg' }}>arrow_forward_ios</i>
                </div>
            </div> */}
            <ul className="nav">

                {menuitems.map((menu) => {
                    if (menu !== false && menu.label !== undefined && menu.items !== undefined) {

                        return (

                            <li onMouseMove={(e)=>{onHoverLink(e)}}  className={isPathActive(menu.to) ? 'nav-item active' : 'nav-item'}>
                                <div  className={omenu[menu.label] ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState(menu.label)} data-toggle="collapse">
                                {(menu.icon === 'material-icons' || menu.icon === 'material-symbols-outlined') ?
                                        <i className={menu.icon + ' menu-icon'}>{menu.gicon}</i> :
                                            <i className={menu.icon + ' menu-icon'}></i>
                                        }
                                    <span className="menu-title">{menu.label}</span>

                                    <i className="material-icons menu-arrow">navigate_next</i>
                                </div>
                                <Collapse in={omenu[menu.label] === true}>
                                    <ul  className="nav flex-column sub-menu">
                                        {menu.items.map((submenu) => {
                                            return (
                                                <li className="nav-item "> <Link className={isPathActive(submenu.to) ? 'flex nav-link active' : 'flex nav-link'} target={submenu.command !== undefined && '_blank'} to={submenu.to}>
                                                   {(submenu.icon === 'material-icons' || submenu.icon === 'material-symbols-outlined') ?
                                        <i className={submenu.icon + ' menu-icon'}>{submenu.gicon}</i> :
                                            <i className={submenu.icon + ' menu-icon'}></i>
                                        }
                                                    
                                                    {submenu.label}</Link></li>
                                            )
                                        })

                                        }
                                    </ul>
                                </Collapse>
                            </li>
                        )
                    } else if (menu !== false && menu.label !== undefined && menu.items === undefined) {

                        if(menu.exit){
                            return (
                                <li className={(isPathActive(menu.to) && menu.hover === undefined) ? 'nav-item active' : 'nav-item'}>
                                    <div className="nav-link" onClick={logout} >
                                    {(menu.icon === 'material-icons' || menu.icon === 'material-symbols-outlined') ?
                                        <i className={menu.icon + ' menu-icon'}>{menu.gicon}</i> :
                                            <i className={menu.icon + ' menu-icon'}></i>
                                        }
                                        <span className="menu-title">{menu.label}</span>
                                    </div>
                                </li>
                            )
                        }else{
                            return (
                                <li className={(isPathActive(menu.to) && menu.hover === undefined) ? 'nav-item active' : 'nav-item'}>
                                    <Link className="nav-link" to={menu.to} target={menu.command !== undefined && '_blank'} >
                                    {(menu.icon === 'material-icons' || menu.icon === 'material-symbols-outlined') ?
                                        <i className={menu.icon + ' menu-icon'}>{menu.gicon}</i> :
                                            <i className={menu.icon + ' menu-icon'}></i>
                                        }
                                        <span className="menu-title">{menu.label}</span>
                                    </Link>
                                </li>
                            )
                        }
                    } else if(menu !== false ) {
                        return menu.items.map((menu_) => {
                            return (
                                <li className={isPathActive(menu_.to) ? 'nav-item active' : 'nav-item'}>
                                    <Link className="nav-link" to={menu_.to} target={menu_.command !== undefined && '_blank'}>
                                    {(menu_.icon === 'material-icons' || menu_.icon === 'material-symbols-outlined') ?
                                        <i className={menu_.icon + ' menu-icon'}>{menu_.gicon}</i> :
                                            <i className={menu_.icon + ' menu-icon'}></i>
                                        }
                                        <span className="menu-title ">{menu_.label}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }

                })}
               




            </ul>
        </nav>
    );






}



export default Sidebar;