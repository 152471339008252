import $ from "jquery";
import React, { useRef, useEffect } from "react";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;

// @ts-ignore
require("jquery-ui-sortable");
// @ts-ignore
require("formBuilder");
const stripPreviewSuffix = (name) => name.replace('-preview', '');

const FormBuilderTwo = (props) => {
  const history = useHistory();
  const fb = useRef();

  useEffect(() => {
    // Default value handling for props.values (if empty or undefined)
    const initialFormValues = JSON.parse(props.values) || [];

    const fields = [{
      label: 'Media Picker',
      attrs: {
        type: 'multimedia'
      },
      icon: '<i class="mdi mdi-filmstrip"></i>',
    }, {
      label: 'Signature',
      attrs: {
        type: 'sign'
      },
      icon: '<i class="mdi mdi-pen"></i>',
    }, {
      label: 'Checkpoint', // Existing custom field
      attrs: {
        type: 'checkpoint' // Custom type for checkpoint
      },
      icon: '<i class="mdi mdi-checkbox-marked-circle-outline"></i>',
    }, {
      label: 'Checklist Group', // Renamed custom field
      attrs: {
        type: 'checklist-group' // Custom type for checklist group
      },
      icon: '<i class="mdi mdi-checkbox-multiple-marked-outline"></i>',
    }];

    const templates = {
      multimedia: function (fieldData) {
        return {
          field: '<span id="' + fieldData.name + '">',
          onRender: function () {
            $(document.getElementById(fieldData.name));
          }
        };
      },
      sign: function (fieldData) {
        return {
          field: '<span id="' + fieldData.name + '">',
          onRender: function () {
            $(document.getElementById(fieldData.name));
          }
        };
      },
      checkpoint: function (fieldData) { // Custom template for checkpoint
        return {
          field: `
          <div id="${fieldData.name}">
            <label>${fieldData.label || 'Checkpoint'}</label><br/>
            <input type="radio" name="${fieldData.name}" value="Yes"> Yes<br>
            <input type="radio" name="${fieldData.name}" value="No"> No<br>
            <input type="radio" name="${fieldData.name}" value="N/A"> Not Applicable<br>
          </div>
        `,
          onRender: function () {
            const $checkpoint = $(document.getElementById(fieldData.name));
            // Additional logic if necessary
          },
          options: [
            { label: "Yes", value: "Yes", selected: false },
            { label: "No", value: "No", selected: false },
            { label: "Not Applicable", value: "N/A", selected: false }
          ]
        };
      },
      'checklist-group': function (fieldData) { // Custom template for checklist group

        const savedQuestions = initialFormValues.find(field => field.name === (stripPreviewSuffix(fieldData.name)))?.questions || [];

        // console.log('fieldData.questions (saved)', fieldData, initialFormValues, savedQuestions); // Debugging

        // Generates HTML for multiple questions dynamically
        const questionFields = savedQuestions.map((q, idx) => `
          <div class="checklist-group-question">
            <label class="editable-label" data-index="${idx}">${q.label}</label>
            <input type="text" class="edit-input" style="display: none;" value="${q.label}">
            <br/>
            <input type="radio" name="${(q.name)}" value="Yes"> Yes<br>
            <input type="radio" name="${(q.name)}" value="No"> No<br>
            <input type="radio" name="${(q.name)}" value="N/A"> Not Applicable<br>
          </div>
        `).join('');

        // const questions = fieldData.questions || [{ label: 'Question 1', name: `${fieldData.name}_q1` }];
        // console.log(fieldData)
        // // Generates HTML for multiple questions dynamically
        // const questionFields = questions.map((q, idx) => `
        //   <div class="checklist-group-question">
        //     <label class="editable-label" data-index="${idx}">${q.label}</label>
        //     <input type="text" class="edit-input" style="display: none;" value="${q.label}">
        //     <br/>
        //     <input type="radio" name="${q.name}" value="Yes"> Yes<br>
        //     <input type="radio" name="${q.name}" value="No"> No<br>
        //     <input type="radio" name="${q.name}" value="N/A"> Not Applicable<br>
        //   </div>
        // `).join('');

        return {
          field: `
            <div id="${fieldData.name}">
              <label>${fieldData.label || 'Checklist Group'}</label>
              ${questionFields}
              <button type="button" class="add-question-btn">Add Question</button>
            </div>
          `,
          onRender: function () {

            const $checklistGroup = $(document.getElementById(fieldData.name));
            // console.log($checklistGroup)
            // Adding the functionality to dynamically add questions
            $checklistGroup.on('click', '.add-question-btn', function () {
              const newIndex = $checklistGroup.find('.checklist-group-question').length + 1;
              const newQuestionHtml = `
                <div class="checklist-group-question">
                  <label class="editable-label">Question ${newIndex}</label>
                  <input type="text" class="edit-input" style="display: none;" value="Question ${newIndex}">
                  <br/>
                  <input type="radio" name="${fieldData.name}_q${newIndex}" value="Yes"> Yes<br>
                  <input type="radio" name="${fieldData.name}_q${newIndex}" value="No"> No<br>
                  <input type="radio" name="${fieldData.name}_q${newIndex}" value="N/A"> Not Applicable<br>
                </div>
              `;
              $(newQuestionHtml).insertBefore($checklistGroup.find('.add-question-btn'));
            });

            // Enable inline editing of the question labels
            $checklistGroup.on('click', '.editable-label', function () {
              const $label = $(this);
              const $input = $label.siblings('.edit-input');
              $label.hide();
              $input.show().focus();
            });

            $checklistGroup.on('blur', '.edit-input', function () {
              const $input = $(this);
              const $label = $input.siblings('.editable-label');
              $label.text($input.val()); // Update label text with input value
              $input.hide();
              $label.show();
            });
          },
          questions: fieldData.questions || [{ label: 'Question 1', name: `${fieldData.name}_q1` }]
        };
      }
    };

    const options = {
      fields,
      templates,
      stickyControls: {
        enable: true
      },
      controlPosition: 'left',
      disabledActionButtons: ['data'],
      actionButtons: [{
        id: 'go-back',
        className: 'btn btn-light',
        label: 'Back',
        type: 'button',
        events: {
          click: function () {
            history.goBack()
          }
        }
      }],
      disabledAttrs: [
        'access',
        'className',
        'inline',
        'name',
        'other',
        'rows',
        'step',
        'style',
        'subtype',
        'toggle'
      ],
      disableFields: ['autocomplete', 'button', 'hidden', 'number', 'select', 'starRating', 'text', 'file'],
      onSave: function (evt, formData) {
        // Convert formData to JSON and ensure it's correctly formatted
        let parsedFormData = JSON.parse(formData);

        // Add options to checkpoint and checklist-group fields if not already present and ensure value is empty by default
        parsedFormData = parsedFormData.map(field => {
          if (field.type === 'checkpoint') {
            field.options = [
              { label: "Yes", value: "Yes", selected: false },
              { label: "No", value: "No", selected: false },
              { label: "Not Applicable", value: "N/A", selected: false }
            ];
            field.value = ""; // Ensure default value is empty
          }
          if (field.type === 'checklist-group') {
            // Capture the updated question labels and values
            console.log(field.name)
            const $checklistGroup = $(fb.current).find(`[id^="${field.name}"]`);

            field.questions = [];
            $checklistGroup.find('.checklist-group-question').each(function () {
              const $label = $(this).find('.editable-label');
              const name = $(this).find('input[type="radio"]').attr('name');
              field.questions.push({ label: $label.text(), name });
            });
          }
          return field;
        });

        console.log(parsedFormData);

        props.onSubmit(parsedFormData)
      },
    };

    // Initialize formBuilder plugin even when props.values is empty (new form)
    let formBuilderPlugin = $(fb.current).formBuilder(options);
    
    // Load saved values if they exist
    if (props.values) {

      setTimeout(() => formBuilderPlugin.actions.setData(initialFormValues), 1000);
    }

  }, [props.values]);

  return (
    <>
      <div id="fb-editor" ref={fb} />
    </>
  );
};

export default FormBuilderTwo;
