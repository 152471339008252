import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditText } from "react-edit-text";
import EdiText from 'react-editext'
import { AutoComplete } from "primereact/autocomplete";
import Axios from "axios";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from 'primereact/multiselect';
import Swal from "sweetalert2";
import { Chips } from 'primereact/chips';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from "primereact/dropdown";
import { API } from "../../constants/api_url";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import APIServices from "../../service/APIService";
const StandardsConfig = () => {
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: "", tier5: ""
    });
    const tier4ref = useRef([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const fileRef = useRef(null)
    const [edittopicdialog, setEditTopicDialog] = useState(false)
    const [selectedtopic, setSelectedTopic] = useState(null)

    const [datasourcelist, setDataSourceList] = useState([])
    const [datasourcelist_, setDataSourceList_] = useState([])
    const [selectedRow, setSelectedRow] = useState([]);
    const [editDialog, setEditDialog] = useState(false)
    const [datapointconfigdialog, setDataPointConfigDialog] = useState(false)
    const [metricconfigdialog, setMetricConfigDialog] = useState(false)
    const [dcfitems, setDCFItems] = useState([])
    const [selected, setSelected] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });
    const [submitted, setSubmitted] = useState(false);
    const [config, setConfig] = useState({ unit: '', desc: '', tags: [], suffix: 'DP', datasource: null, type: { name: 'Quantitative' } })
    const [metricconfig, setMetricConfig] = useState({ tags: [], desc: '' })

    const selector = useSelector((state) => state.user.userdetail);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [tier4, setTier4] = useState([]);
    const [tier5, setTier5] = useState([]);
    const [tier5dd, setTier5DD] = useState(null)
    const [addtopicdialog, setAddTopicDialog] = useState(false)

    const [selectedDataPoint, setSelectedDataPoint] = useState('')
    const [metric, setSelectedMetric] = useState('')

    const [tier0, setTier0] = useState([])
    const forceUpdate = useForceUpdate();
    useEffect(() => {

        APIServices.get(API.STD_Country,
        ).then((res) => {
            setTier1(res.data);
        });

    }, []);
    const deleteTier4 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier4.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.STD_Scope_Edit(item.id),
                ).then((res) => {
                    let loc = tier4
                    loc.splice(index, 1);

                    setTier4(loc);
                    forceUpdate();

                });
            }
        })

    }
    const setEditModeTier4 = (cyindex) => {
        tier4.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier4 = (cyindex) => {
        tier4.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier3 = (cyindex) => {
        tier3.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier3 = (cyindex) => {
        tier3.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const saveTopic = () => {
        setSubmitted(true)
        if (selectedtopic.title.trim().length !== 0) {
            if (selectedtopic.data1 === null) {
                selectedtopic.data1 = []
            }
            APIServices.patch(API.STD_Topic_Edit(selectedtopic.id), selectedtopic).then((res) => {
                let tier_5 = tier5
                let index = tier_5.findIndex((i) => { return i.id === selectedtopic.id })

                tier_5[index] = selectedtopic
                setTier5(tier_5)
                setEditTopicDialog(false)
                forceUpdate()
            })
        }
    }
    const setEditModeTier2 = (cyindex) => {
        tier2.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier2 = (cyindex) => {
        tier2.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier1 = (cyindex) => {
        tier1.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier1 = (cyindex) => {
        tier1.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const deleteTier3 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier3.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.STD_Name_Edit(item.id),
                ).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''

                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier3
                    loc.splice(index, 1);

                    setTier3(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier2 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier2.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.STD_Year_Edit(item.id),
                ).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier2
                    loc.splice(index, 1);

                    setTier2(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier1 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier1.findIndex((i) => { return i.id === item.id })
                APIServices.delete(API.STD_Country_Edit(item.id),
                ).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier1 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);

                    let loc = tier1
                    loc.splice(index, 1);

                    setTier1(loc);
                    forceUpdate();

                });
            }
        })

    }

    
  
  
    const openConfig = (items) => {
        // setDataSource()
        let item = JSON.parse(items)
        setSubmitted(false)
        console.log(item)
        setSelectedDataPoint(item);
        if (item.data1 === null) {
            setConfig({ unit: '', desc: '', suffix: 'DP', tags: [], datasource: null, type: { name: 'Quantitative' } });
            setDataPointConfigDialog(true);
        } else {
            if (item.data1[0]['suffix'] === undefined) { item.data1[0]['suffix'] = 'DP' }

            if ((item.data1[0].datasource !== null && item.data1[0].datasource !== undefined) || !Array.isArray(item.data1[0].datasource)) {

                item.data1[0].datasource = datasourcelist[datasourcelist.findIndex((k) => { return k.id === item.data1[0].datasource })]
            }

            setConfig(item.data1[0]);
            setDataPointConfigDialog(true);
        }
    }
    const openMetricConfig = (items) => {
        let item = JSON.parse(items)
        setSubmitted(false)
        setSelectedMetric(item);
        if (item.data1 === null) {
            setMetricConfig({ tags: [], desc: '' });
            setMetricConfigDialog(true);
        } else {

            setMetricConfig(item.data1[0]);
            setMetricConfigDialog(true);
        }
    }
    const updateConfig = (obj, val) => {
        let loc = config;

        if (obj === 'type') {
            loc.unit = ''
        }
        loc[obj] = val;


        setConfig(loc);
        forceUpdate()

    }
    const updateMetricConfig = (obj, val) => {
        let loc = metricconfig;


        loc[obj] = val;


        setMetricConfig(loc);
        forceUpdate()

    }
   
    const addNewTier1 = () => {

        let index = tier1.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier1.trim().length !== 0) {
            APIServices.post(API.STD_Country,
                {
                    title: module.tier1.trim()
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier1;
                        tier.push(res.data);
                        setTier1(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier0: "",
                            tier5: "",
                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier2 = () => {

        let index = tier2.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier2.trim().length !== 0) {
            APIServices.post(API.STD_Year_Country(selected.tier1),
                {
                    title: module.tier2.trim()
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        let tier = tier2;
                        tier.push(res.data);
                        setTier2(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier5: "",
                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier3 = () => {

        let index = tier3.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier3.trim().length !== 0) {
            APIServices.post(API.STD_Name_Year(selected.tier2),
                {
                    title: module.tier3.trim()
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier3;

                        tier.push(res.data);
                        setTier3(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier5: "",
                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier4 = () => {

        let index = tier4.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })
        setTier5DD(null)
        if (index === -1 && module.tier4.trim().length !== 0) {
            APIServices.post(API.STD_Scope_Name(selected.tier3),
                {
                    title: module.tier4.trim()
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier4;

                        tier.push(res.data);
                        setTier4(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier4: '',
                            tier5: ""
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier5 = () => {
        setSubmitted(true)
        let index = tier5.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier5.trim().toLowerCase() })

        if (index === -1 && module.tier5.trim().length !== 0) {
            APIServices.post(API.STD_Topic_Scope(selected.tier4),
                {
                    title: module.tier5.trim()
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier5;

                        tier.push(res.data);
                        setTier5(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier4: '',
                            tier5: ""
                        });
                        console.log(tier5)
                    }
                    setSubmitted(false)
                    setAddTopicDialog(false)
                })
                .catch((e) => {
                    setAddTopicDialog(false)
                    setSubmitted(false)

                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const updateTier1 = (e, id) => {
        console.log(e)
        let index = tier1.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.STD_Country_Edit(tier1[index].id),
                {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = tier1
                loc[index].title = e.trim();
                setTier1(loc);
                forceUpdate()
            });
        }
    };


    const getTier2Data = (id) => {
        let sel = selected;
        sel.tier1 = id;
        sel.tier2 = "";
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);

        APIServices.get(API.STD_Year_Country(id),
        ).then((res) => {
            setTier2(res.data);
        });
    };
    const getTier3Data = (id) => {
        let sel = selected;
        sel.tier2 = id;
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);
        forceUpdate();
        APIServices.get(API.STD_Name_Year(selected.tier2),
        ).then((res) => {
            setTier3(res.data);
        });
    };

    const getTier4Data = (id) => {
        let sel = selected;
        sel.tier3 = id;

        sel.tier4 = ''
        setSelected(sel);
        forceUpdate();
        APIServices.get(API.STD_Scope_Name(selected.tier3),
        ).then((res) => {
            setTier4(res.data);
        });
    };
    const getTier5Data = (id) => {
        setTier5DD(null)
        let sel = selected;
        sel.tier4 = id;
        setSelected(sel);
        APIServices.get(API.STD_Topic_Scope(selected.tier4),
        ).then((res) => {
            setTier5(res.data);
        });
        forceUpdate();
    };
    const updateTier2 = (e, id) => {
        let index = tier2.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch(API.STD_Year_Edit(tier2[index].id),
            {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier2
            loc[index].title = e.trim();
            setTier2(loc);
            forceUpdate()

        });
    };
    const updateTier3 = (e, id) => {
        let index = tier3.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch(API.STD_Name_Edit(tier3[index].id),
            {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier3
            loc[index].title = e.trim();
            setTier3(loc);
            forceUpdate()

        });
    };

    const showTier4CM = (e, ind) => {
        console.log(tier4ref.current)
        tier4ref.current.forEach((i, j) => {
            if (j === ind) {
                tier4ref.current[ind].show(e)
            } else {
                if (tier4ref.current[j] !== null) {
                    tier4ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
    }
    const showTier3CM = (e, ind) => {

        tier3ref.current.forEach((i, j) => {
            if (j === ind) {
                tier3ref.current[ind].show(e)
            } else {
                if (tier3ref.current[j] !== null) {
                    tier3ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })

    }
    const updateTier4 = (e, id) => {
        let index = tier4.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch(API.STD_Scope_Edit(tier4[index].id),
            {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier4
            loc[index].title = e.trim();
            setTier4(loc);
            forceUpdate()

        });
        console.log(tier4)
    };
    const updateTier5DD = (e) => {
        setTier5DD(e)
        forceUpdate()

    }
    const updateTierValue = (obj, e) => {

        let mod = module;
        mod[obj] = e.target.value;
        if (obj === 'tier4') {

        }
        setModule(mod);
        forceUpdate();
    };
    const search = (event) => {

        let _items = datasourcelist.filter((k) => { return k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setDCFItems(_items);
        forceUpdate()
    }
    const items_ = [
        {
            label: 'Indicator Listing',

            command: () => {
                renderData(0)

            }
        },
        {
            label: 'Indicator > DP',

            command: () => {
                renderData(1)
            }
        },
        {
            label: 'DP > DCF ',

            command: () => {
                renderData(2)
            }
        }
    ];
    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    const importExcel_ = (es) => {

        const file = es.files[0];
        let selectedTopic = tier5dd
        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = []
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false }).length !== 0) {
                        json.push({ [sheet]: XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false }) });
                    }

                }
                let local = []

                json.forEach((a, i) => {

                    if (true) {


                        const cols = a[Object.keys(a)[0]][0];
                        a[Object.keys(a)[0]].shift();

                        let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                        let _importedData = a[Object.keys(a)[0]].map(d => {


                            return cols.reduce((obj, c, i) => {

                                obj[c] = typeof d[i] === 'string' ? d[i].trim() : d[i];
                                return obj;


                            }, {});
                        });


                        local.push({ importedCells: _importedCols, importedData: _importedData })


                    }
                })
                selectedTopic.data1 = local
                setTier5DD(selectedTopic)
                console.log(local)
                es.options.clear()
                forceUpdate()

            };

            reader.readAsArrayBuffer(file);
        });
    }
    const exportExcel_ = () => {

        // const ws = XLSX.utils.json_to_sheet(csvData);
        // const wb = {
        //     Sheets: {
        //         [sheetname]: ws,
        //     },
        //     SheetNames: [sheetname],
        // };
        // const excelBuffer = XLSX.write(wb, {
        //     bookType: "xlsx",
        //     type: "array",
        // });
        // const data = new Blob([excelBuffer], {
        //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        // });
        // FileSaver.saveAs(data, fileName + ".xlsx");

    }
    const renderData = (type) => {

        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }


        let site_url = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        APIServices.get(site_url).then((res) => {
            let metrics = [], dps = []
            res.data.forEach((item) => {
                if (item.newTopics !== undefined) {
                    item.newTopics.forEach((item2) => {
                        if (item2.newMetrics !== undefined) {

                            metrics.push(...item2.newMetrics)
                            item2.newMetrics.forEach((item3) => {
                                if (item3.newDataPoints !== undefined) {
                                    dps.push(...item3.newDataPoints)
                                }
                            })
                        }
                    })
                }
            })
            export2Excel(type, metrics, dps)
        })

    }
    const groupArrayObject = (array) => {
        return array.reduce((group, arr) => {

            const { DCF } = arr;

            group[DCF] = group[DCF] ?? [];

            group[DCF].push(arr);

            return group;

        },

            {})
    }
    const export2Excel = () => {
        console.log(tier5dd)


        const ws = XLSX.utils.json_to_sheet(tier5dd.data1[0]['importedData'])
        let sheet_name = tier5dd.title
        const wb = {
            Sheets: { [sheet_name]: ws },
            SheetNames: [sheet_name],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, sheet_name + ".xlsx");
    }
    const onImportSelectionChange = (e) => {

        setEditDialog(true)
        setSelectedRow(JSON.parse(JSON.stringify(e.value)))

    }


    const hideEditDialog = () => {
        setEditDialog(false)
    }
    const updateEditedCells = (e) => {

        let local = tier5dd
        local.data1.forEach((item) => {

            let index = item.importedData.findIndex((i) => { return i.id === selectedRow[0].id })
            if (index !== -1) {
                item.importedData[index] = selectedRow[0]
            }


        })
        setEditDialog(false)
        setTier5DD(local)
    }
    const editDialogFooter = (
        <>

            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideEditDialog} />

            <Button label="Save & Exit" icon="pi pi-check" className="p-button-text" onClick={(e) => { updateEditedCells(e) }} />
        </>
    );
    const editValueUpdate = (e, obj, row) => {

        row[obj] = e.target.value
        setSelectedRow([row])


    }
    const renderList = (data) => {

        return (<>
            <div className="card">




                <DataTable value={data.importedData} emptyMessage="No data" selection={selectedRow} onSelectionChange={(e) => { onImportSelectionChange(e) }} paginator rows={20}
                    rowsPerPageOptions={[10, 20, 50, 100]} alwaysShowPaginator={false} responsiveLayout="scroll"
                    selectionMode="multiple"   >
                    {
                        data.importedCells.map((col, index) => {
                            if (col.field !== 'scope') {
                                return <Column key={index} field={col.field} header={col.header} />
                            }
                        })
                    }
                </DataTable>
                <Dialog visible={editDialog} style={{ width: '450px' }} header={`Update selected values`} modal footer={editDialogFooter} onHide={hideEditDialog}>
                    <div className="flex align-items-center justify-content-center">
                        {selectedRow.map((row) => {

                            return (<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                    Object.keys(row).map((item) => {
                                        if (item !== 'id' && item !== 'scope') {
                                            return (

                                                <div className='col-12' style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <h5 style={{ width: '50%' }}>{item}</h5>
                                                    <input style={{ width: '50%', borderRadius: 5 }} disabled={row[item] === undefined ? true : false} value={row[item]} onChange={(e) => { editValueUpdate(e, item, row) }} />

                                                </div>
                                            )
                                        }
                                    })
                                } </div>)
                        })


                        }
                    </div>
                </Dialog>
            </div>
        </>)



    }
    const uploadEmissionFactor = (e) => {
        APIServices.patch(API.STD_Topic_Edit(tier5dd.id), tier5dd)
    }
    const deleteTier5 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.STD_Topic_Edit(item.id)).then((res) => {
                    let tier_5 = tier5
                    let index = tier_5.findIndex((i) => { return i.id === item.id })
                    tier_5.splice(index, 1)
                    setTier5(tier_5)
                    console.log(selectedRow)
           
                        setTier5DD(null)

                    

                    forceUpdate()
                })
            }
        })
    }
    const editTopic = (item) => {
        setSelectedTopic(JSON.parse(JSON.stringify(item)))
        setEditTopicDialog(true)
    }
    const topicOptionTemplate = (option) => {

        return (
            <div className="col-12 grid">
                <div className="col-10">{option.title}</div>
                <i className="material-icons col-1" style={{ zIndex: 9 }} onClick={(e) => { e.stopPropagation(); editTopic(option) }}>edit</i>

                <i className="material-icons col-1" style={{ zIndex: 9 }} onClick={(e) => { e.stopPropagation(); deleteTier5(option) }}>delete</i>
            </div>
        );
    };

    return (
        <div className="grid">

            {selector.role === "eisqradmin" ? (
                <div className="col-12">
                    <div className="card">
                        <h3> Standards Configuration </h3>
                        <div>

                            <div className="field">
                                <div>
                                    <div className="col-12">
                                        <div className="grid">

                                            <div className="col-3">
                                                <div className="grid">
                                                    <div className="col-10">
                                                        <InputText
                                                            type={"text"}
                                                            value={module.tier1}
                                                            onChange={(e) => {
                                                                updateTierValue("tier1", e);
                                                            }}
                                                            placeholder="Enter Country name"
                                                        />
                                                    </div>
                                                    <div className="col-2" style={{ marginLeft: -10 }}>
                                                        <Button
                                                            icon="pi pi-plus"

                                                            style={{ cursor: module.tier1.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier1.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier1.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                            className="p-button-success mr-2"
                                                            onClick={() => {
                                                                addNewTier1();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12"
                                                    style={{
                                                        border: "1px solid grey",
                                                        height: "46vh",
                                                        overflow: "auto",
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    {tier1.length === 0 ? (
                                                        <text
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >

                                                            {"Add Country"}
                                                        </text>
                                                    ) : (
                                                        tier1.map((item, cyindex) => {
                                                            return (
                                                                <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                                    <div className="col-11"
                                                                        onClick={() => {
                                                                            getTier2Data(item.id);
                                                                        }}
                                                                        style={{
                                                                            color: selected.tier1 === item.id && "white",
                                                                            background: selected.tier1 === item.id && "grey",
                                                                            borderBottom: "0.5px ridge grey", padding: 0
                                                                        }}
                                                                    >
                                                                        <EdiText

                                                                            viewContainerClassName={selected.tier1 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                            type='text'
                                                                            value={item.title}
                                                                            onSave={(e) => { updateTier1(e, item.id); setEditModeOffTier1() }}
                                                                            editing={item.edit}
                                                                            onCancel={() => { setEditModeOffTier1() }}
                                                                            hideIcons={true}
                                                                            validationMessage="Title should not exist / not empty "
                                                                            validation={val => { console.log(val); return val.trim().length !== 0 && tier1.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                            saveButtonContent="✔"
                                                                            cancelOnUnfocus
                                                                            cancelButtonContent={<strong>X</strong>}

                                                                            editButtonClassName="custom-edit-button"
                                                                        />
                                                                        {/* <EditText
                                                                            onSave={(e) => {
                                                                                if (e.value.trim().length !== 0) {
                                                                                    updateTier1(e);
                                                                                }
                                                                            }}
                                                                            name="textbox3"
                                                                            // value={item.country}
                                                                            defaultValue={item.title}
                                                                            editButtonProps={{
                                                                                style: {
                                                                                    marginLeft: "5px",
                                                                                    width: 16,
                                                                                },
                                                                            }}
                                                                            showEditButton
                                                                        /> */}
                                                                    </div>
                                                                    <ContextMenu model={[
                                                                        {
                                                                            label: 'Edit',
                                                                            icon: 'pi pi-fw pi-pencil',
                                                                            command: () => { setEditModeTier1(cyindex) }
                                                                        },
                                                                        {
                                                                            label: 'Delete',
                                                                            icon: 'pi pi-fw pi-trash', command: () => { deleteTier1(item) }
                                                                        }]} ref={(r) => { tier1ref.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                                    <div className="col-1" onContextMenu={(e) => { showTier1CM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                        <i className="material-icons" >settings</i>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            {selected.tier1 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier2}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier2", e);
                                                                }}
                                                                placeholder="Enter Year"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier2.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier2.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier2.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier2();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier2.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Year"}
                                                            </text>
                                                        ) : (
                                                            tier2.map((item, cyindex) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                            <Tooltip target={".topic" + item.id} position={'top'} autoHide={false}> {item.title} </Tooltip>
                                                                            <div className={"col-11 topic" + item.id}
                                                                                onClick={() => {
                                                                                    getTier3Data(item.id);
                                                                                }}
                                                                                style={{
                                                                                    color: selected.tier2 === item.id && "white",
                                                                                    background: selected.tier2 === item.id && "grey",
                                                                                    borderBottom: "0.5px ridge grey", padding: 0
                                                                                }}
                                                                            >
                                                                                <EdiText

                                                                                    viewContainerClassName={selected.tier2 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                    type='text'
                                                                                    value={item.title}
                                                                                    onSave={(e) => { updateTier2(e, item.id); setEditModeOffTier2() }}
                                                                                    editing={item.edit}
                                                                                    onCancel={() => { setEditModeOffTier2() }}
                                                                                    hideIcons={true}
                                                                                    validationMessage="Title should not exist / not empty "
                                                                                    validation={val => { console.log(val); return val.trim().length !== 0 && tier2.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                    saveButtonContent="✔"
                                                                                    cancelOnUnfocus
                                                                                    cancelButtonContent={<strong>X</strong>}

                                                                                    editButtonClassName="custom-edit-button"
                                                                                />
                                                                                {/* <EditText
                                                                                    onSave={(e) => {
                                                                                        if (e.value.trim().length !== 0) {
                                                                                            updateTier2(e);
                                                                                        }
                                                                                    }}
                                                                                    name="textbox3"
                                                                                    // value={item.country}
                                                                                    defaultValue={item.title}
                                                                                    editButtonProps={{
                                                                                        style: {
                                                                                            marginLeft: "5px",
                                                                                            width: 16,
                                                                                        },
                                                                                    }}
                                                                                    showEditButton
                                                                                /> */}
                                                                            </div>
                                                                            <ContextMenu model={[
                                                                                {
                                                                                    label: 'Edit',
                                                                                    icon: 'pi pi-fw pi-pencil',
                                                                                    command: () => { setEditModeTier2(cyindex) }
                                                                                },
                                                                                {
                                                                                    label: 'Delete',
                                                                                    icon: 'pi pi-fw pi-trash', command: () => { deleteTier2(item) }
                                                                                }]} ref={(r) => { tier2ref.current[parseInt(`2${item.id}`)] = r }}></ContextMenu>
                                                                            <div className="col-1" onContextMenu={(e) => { showTier2CM(e, parseInt(`2${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                                <i className="material-icons" >settings</i>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {selected.tier2 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier3}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier3", e);
                                                                }}
                                                                placeholder="Enter Standard name"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier3.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier3.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier3.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier3();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier3.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Standard"}
                                                            </text>
                                                        ) : (
                                                            tier3.map((item, cyindex) => {
                                                                return (
                                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        <Tooltip target={".metric" + item.id} position={'top'} autoHide={false}>{item.title} </Tooltip>
                                                                        <div className={"col-11 " + " metric" + item.id}

                                                                            onClick={() => {
                                                                                getTier4Data(item.id);
                                                                            }}
                                                                            style={{
                                                                                color: selected.tier3 === item.id && "white",
                                                                                background: selected.tier3 === item.id && "grey",
                                                                                borderBottom: "0.5px ridge grey", padding: 0,
                                                                            }}
                                                                        >
                                                                            <EdiText

                                                                                viewContainerClassName={selected.tier3 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                type='text'
                                                                                value={item.title}
                                                                                onSave={(e) => { updateTier3(e, item.id); setEditModeOffTier3() }}
                                                                                editing={item.edit}
                                                                                onCancel={() => { setEditModeOffTier3() }}
                                                                                hideIcons={true}
                                                                                validationMessage="Title should not exist / not empty "
                                                                                validation={val => { console.log(val); return val.trim().length !== 0 && tier3.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                saveButtonContent="✔"
                                                                                cancelOnUnfocus
                                                                                cancelButtonContent={<strong>X</strong>}

                                                                                editButtonClassName="custom-edit-button"
                                                                            />
                                                                            {/* <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        updateTier3(e);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"
                                                                                // value={item.country}
                                                                                defaultValue={item.title}
                                                                                editButtonProps={{
                                                                                    style: {
                                                                                        marginLeft: "5px",
                                                                                        width: 16,
                                                                                    },
                                                                                }}
                                                                                showEditButton
                                                                            /> */}
                                                                        </div>
                                                                        <ContextMenu model={[
                                                                            {
                                                                                label: 'Edit',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { setEditModeTier3(cyindex) }
                                                                            },
                                                                            {
                                                                                label: 'Delete',
                                                                                icon: 'pi pi-fw pi-trash', command: () => { deleteTier3(item) }
                                                                            }]} ref={(r) => { tier3ref.current[parseInt(`3${item.id}`)] = r }}></ContextMenu>
                                                                        <div className="col-1" onContextMenu={(e) => { showTier3CM(e, parseInt(`3${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }}>

                                                                            <i className="material-icons" >settings</i>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {selected.tier3 !== "" && (
                                                <div className="col-3">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                value={module.tier4}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier4", e);
                                                                }}
                                                                placeholder="Enter Scope name"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier4.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier4.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier4.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier4();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier4.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Scope"}
                                                            </text>
                                                        ) : (
                                                            tier4.map((item, cyindex) => {
                                                                return (
                                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        <Tooltip target={".dp" + item.id} position={'top'} autoHide={false}> {item.title} </Tooltip>

                                                                        <div className={"col-11 " + " dp" + item.id}
                                                                            onClick={() => {

                                                                                getTier5Data(item.id);

                                                                            }}
                                                                            style={{
                                                                                color: selected.tier4 === item.id && "white",
                                                                                background: selected.tier4 === item.id && "grey",
                                                                                borderBottom: "0.5px ridge grey", padding: 0
                                                                            }}

                                                                        >

                                                                            <EdiText


                                                                                viewContainerClassName={selected.tier4 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                                type='text'
                                                                                value={item.title}
                                                                                onSave={(e) => { updateTier4(e, item.id); setEditModeOffTier4() }}
                                                                                editing={item.edit}
                                                                                onCancel={() => { setEditModeOffTier4() }}
                                                                                hideIcons={true}
                                                                                validationMessage="Title should not exist / not empty "
                                                                                validation={val => { return val.trim().length !== 0 && tier4.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                saveButtonContent="✔"
                                                                                cancelOnUnfocus
                                                                                cancelButtonContent={<strong>X</strong>}

                                                                                editButtonClassName="custom-edit-button"
                                                                            />
                                                                            {/* <EditText
                                                                                onSave={(e) => {
                                                                                    if (e.value.trim().length !== 0) {
                                                                                        updateTier4(e);
                                                                                    }
                                                                                }}
                                                                                name="textbox3"

                                                                                // value={item.country}
                                                                                defaultValue={item.title}
                                                                                editButtonProps={{
                                                                                    style: {
                                                                                        marginLeft: "5px",
                                                                                        width: 16,
                                                                                    },
                                                                                }}
                                                                                showEditButton

                                                                            /> */}

                                                                        </div>
                                                                        <ContextMenu model={[
                                                                            {
                                                                                label: 'Edit',
                                                                                icon: 'pi pi-fw pi-pencil',
                                                                                command: () => { setEditModeTier4(cyindex) }
                                                                            },
                                                                            {
                                                                                label: 'Delete',
                                                                                icon: 'pi pi-fw pi-trash', command: () => { deleteTier4(item) }
                                                                            },
                                                                        ]} ref={(r) => { tier4ref.current[parseInt(`4${item.id}`)] = r }}></ContextMenu>


                                                                        <div className="col-1" onContextMenu={(e) => { showTier4CM(e, parseInt(`4${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                            <i className="material-icons" style={{ color: '#495057' }} >settings</i>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {selected.tier4 !== '' &&

                                            <div className="col-12">
                                                <div className="col-6">
                                                    Select Topic
                                                </div>
                                                <div className="col-6">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <Dropdown style={{ width: '100%' }} itemTemplate={topicOptionTemplate} options={tier5} value={tier5dd} optionLabel={'title'} onChange={(e) => { e.stopPropagation(); updateTier5DD(e.value) }} />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"

                                                                style={{ border: module.tier5.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                className="p-button-success mr-2"
                                                                onClick={() => {
                                                                    setAddTopicDialog(true)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        }
                                        {tier5dd !== null &&
                                            <>
                                                <div className="col-12 lg:col-12 grid" style={{ justifyContent: 'space-between' }}>
                                                    <div >
                                                        <FileUpload ref={fileRef} chooseOptions={{ label: 'Import Emission Factor', icon: 'pi pi-file-excel', className: 'p-button-success' }} mode="basic" name="demo[]" auto customUpload
                                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="mr-2" uploadHandler={importExcel_} />
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (e.target.tagName === "SPAN") {
                                                                export2Excel();
                                                            }
                                                        }}
                                                    >
                                                        <FileUpload
                                                            chooseOptions={{
                                                                label: "Export as xls",
                                                                icon: "pi pi-download",
                                                                className: "p-button-success",
                                                            }}
                                                            mode="basic"
                                                            name="demo[]"
                                                            auto
                                                            customUpload
                                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                            className="mr-2"
                                                        />
                                                    </div>
                                                </div>
                                                <>
                                                    {tier5dd.data1 !== undefined && tier5dd.data1 !== null && tier5dd.data1.length !== 0 &&

                                                        <div className="col-12 lg:col-12">
                                                            <>
                                                                {tier5dd.data1.map((a) => {
                                                                    return renderList(a)
                                                                })}
                                                            </>
                                                            <Button label="Upload" icon="pi pi-check" className="p-button" onClick={(e) => { uploadEmissionFactor(e) }} />

                                                        </div>
                                                    }
                                                </>
                                            </>

                                        }
                                        <Dialog
                                            visible={addtopicdialog}
                                            style={{
                                                width: "50%", height: '50%'
                                            }}
                                            header={"Add Topic"}
                                            modal
                                            className="p-fluid"

                                            onHide={() => { setAddTopicDialog(false); setSubmitted(false) }}
                                        >
                                            <div style={{ margin: 10 }}>
                                                <text> Enter Topic Names </text>

                                                <InputText value={module.tier5} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateTierValue("tier5", e); }} placeholder="Topic title" />

                                                {submitted && module.tier5.trim().length === 0 && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Topic title required
                                                    </small>
                                                )}
                                            </div>




                                            <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addNewTier5() }} />
                                        </Dialog>
                                        <Dialog
                                            visible={edittopicdialog}
                                            style={{
                                                width: "50%", height: '50%'
                                            }}
                                            header={"Edit Topic "}
                                            modal
                                            className="p-fluid"

                                            onHide={() => { setEditTopicDialog(false) }}
                                        >
                                            <div style={{ margin: 10 }}>
                                                <text>Topic Name</text>

                                                <InputText value={selectedtopic !== null && selectedtopic.title} style={{ padding: 10, margin: 10 }} onChange={(e) => { let loc = selectedtopic; loc.title = e.target.value; setSelectedTopic(loc); forceUpdate() }} placeholder="Topic title" />

                                                {submitted && selectedtopic !== null && selectedtopic.title.trim().length === 0 && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Topic title required
                                                    </small>
                                                )}
                                            </div>




                                            <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { saveTopic() }} />
                                        </Dialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-12 card">You have no rights to access this page </div>
            )}
      
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(StandardsConfig, comparisonFn);
