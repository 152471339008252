import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomLegend } from "./Dashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomScopeLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 0 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const scopeData = {
  2019: [
    { month: "Jan", consumptionEmission: 30, refrigerantEmission: 50 },
    { month: "Feb", consumptionEmission: 140, refrigerantEmission: 60 },
    { month: "Mar", consumptionEmission: 50, refrigerantEmission: 30 },
    { month: "Apr", consumptionEmission: 80, refrigerantEmission: 20 },
    { month: "May", consumptionEmission: 100, refrigerantEmission: 30 },
    { month: "Jun", consumptionEmission: 30, refrigerantEmission: 90 },
    { month: "Jul", consumptionEmission: 30, refrigerantEmission: 50 },
    { month: "Aug", consumptionEmission: 50, refrigerantEmission: 60 },
    { month: "Sep", consumptionEmission: 20, refrigerantEmission: 70 },
    { month: "Oct", consumptionEmission: 40, refrigerantEmission: 40 },
    { month: "Nov", consumptionEmission: 10, refrigerantEmission: 20 },
    { month: "Dec", consumptionEmission: 10, refrigerantEmission: 100 },
  ],
  2020: [
    { month: "Jan", consumptionEmission: 75, refrigerantEmission: 43 },
    { month: "Feb", consumptionEmission: 32, refrigerantEmission: 34 },
    { month: "Mar", consumptionEmission: 45, refrigerantEmission: 64 },
    { month: "Apr", consumptionEmission: 23, refrigerantEmission: 54 },
    { month: "May", consumptionEmission: 10, refrigerantEmission: 34 },
    { month: "Jun", consumptionEmission: 42, refrigerantEmission: 75 },
    { month: "Jul", consumptionEmission: 34, refrigerantEmission: 54 },
    { month: "Aug", consumptionEmission: 23, refrigerantEmission: 42 },
    { month: "Sep", consumptionEmission: 15, refrigerantEmission: 42 },
    { month: "Oct", consumptionEmission: 65, refrigerantEmission: 30 },
    { month: "Nov", consumptionEmission: 23, refrigerantEmission: 43 },
    { month: "Dec", consumptionEmission: 77, refrigerantEmission: 32 },
  ],
  2021: [
    { month: "Jan", consumptionEmission: 30, refrigerantEmission: 80 },
    { month: "Feb", consumptionEmission: 28, refrigerantEmission: 62 },
    { month: "Mar", consumptionEmission: 38, refrigerantEmission: 52 },
    { month: "Apr", consumptionEmission: 60, refrigerantEmission: 10 },
    { month: "May", consumptionEmission: 45, refrigerantEmission: 85 },
    { month: "Jun", consumptionEmission: 30, refrigerantEmission: 50 },
    { month: "Jul", consumptionEmission: 45, refrigerantEmission: 45 },
    { month: "Aug", consumptionEmission: 60, refrigerantEmission: 30 },
    { month: "Sep", consumptionEmission: 35, refrigerantEmission: 45 },
    { month: "Oct", consumptionEmission: 25, refrigerantEmission: 35 },
    { month: "Nov", consumptionEmission: 40, refrigerantEmission: 40 },
    { month: "Dec", consumptionEmission: 28, refrigerantEmission: 72 },
  ],
  2022: [
    { month: "Jan", consumptionEmission: 42, refrigerantEmission: 23 },
    { month: "Feb", consumptionEmission: 42, refrigerantEmission: 12 },
    { month: "Mar", consumptionEmission: 42, refrigerantEmission: 33 },
    { month: "Apr", consumptionEmission: 66, refrigerantEmission: 12 },
    { month: "May", consumptionEmission: 20, refrigerantEmission: 64 },
    { month: "Jun", consumptionEmission: 25, refrigerantEmission: 22 },
    { month: "Jul", consumptionEmission: 60, refrigerantEmission: 32 },
    { month: "Aug", consumptionEmission: 33, refrigerantEmission: 25 },
    { month: "Sep", consumptionEmission: 55, refrigerantEmission: 40 },
    { month: "Oct", consumptionEmission: 20, refrigerantEmission: 67 },
    { month: "Nov", consumptionEmission: 45, refrigerantEmission: 33 },
    { month: "Dec", consumptionEmission: 25, refrigerantEmission: 55 },
  ],
  2023: [
    { month: "Jan", consumptionEmission: 23, refrigerantEmission: 50 },
    { month: "Feb", consumptionEmission: 42, refrigerantEmission: 52 },
    { month: "Mar", consumptionEmission: 53, refrigerantEmission: 45 },
    { month: "Apr", consumptionEmission: 76, refrigerantEmission: 0 },
    { month: "May", consumptionEmission: 32, refrigerantEmission: 85 },
    { month: "Jun", consumptionEmission: 62, refrigerantEmission: 40 },
    { month: "Jul", consumptionEmission: 54, refrigerantEmission: 35 },
    { month: "Aug", consumptionEmission: 65, refrigerantEmission: 20 },
    { month: "Sep", consumptionEmission: 21, refrigerantEmission: 35 },
    { month: "Oct", consumptionEmission: 64, refrigerantEmission: 15 },
    { month: "Nov", consumptionEmission: 23, refrigerantEmission: 50 },
    { month: "Dec", consumptionEmission: 53, refrigerantEmission: 27 },
  ],
  2024: [
    { month: "Jan", consumptionEmission: 43, refrigerantEmission: 45 },
    { month: "Feb", consumptionEmission: 75, refrigerantEmission: 48 },
    { month: "Mar", consumptionEmission: 42, refrigerantEmission: 40 },
    { month: "Apr", consumptionEmission: 75, refrigerantEmission: 20 },
    { month: "May", consumptionEmission: 34, refrigerantEmission: 70 },
    { month: "Jun", consumptionEmission: 65, refrigerantEmission: 35 },
    { month: "Jul", consumptionEmission: 23, refrigerantEmission: 30 },
    { month: "Aug", consumptionEmission: 54, refrigerantEmission: 15 },
    { month: "Sep", consumptionEmission: 22, refrigerantEmission: 30 },
    { month: "Oct", consumptionEmission: 75, refrigerantEmission: 10 },
    { month: "Nov", consumptionEmission: 12, refrigerantEmission: 45 },
    { month: "Dec", consumptionEmission: 65, refrigerantEmission: 15 },
  ],
};

export const ScopeEmissionChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = scopeData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} ConsumptionEmission`]: item.consumptionEmission,
    [`${year1} RefrigerantEmission`]: item.refrigerantEmission,
    [`${year2} ConsumptionEmission`]:
      scopeData[year2][index].consumptionEmission,
    [`${year2} RefrigerantEmission`]:
      scopeData[year2][index].refrigerantEmission,
  }));
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Scope 1+2 Emissions */}
          Energy Consumption (Millions of GJ)
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Energy consumed from different sources
          </div>
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(scopeData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(scopeData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={combinedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: "Millions of GJ",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />
            <Tooltip />
            <Legend content={CustomScopeLegend} />
            <Bar
              dataKey={`${year1} ConsumptionEmission`}
              fill="#73C3B3"
              barSize={30}
              name="Energy consumption across the organization "
            ></Bar>

            <Bar
              dataKey={`${year2} ConsumptionEmission`}
              fill="#73C3B3"
              barSize={30}
            >
              {" "}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            tableClassName="font-lato"
            value={scopeData[year1].map((item, index) => ({
              ...item,
              [`consumptionEmission_${year2}`]:
                scopeData[year2][index].consumptionEmission,
            }))}
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Energy Consumption - ${year1} (GJ)`}
              style={{ minWidth: "8%" }}
              field="consumptionEmission"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Energy Consumption - ${year2} (GJ)`}
              style={{ minWidth: "8%" }}
              field={`consumptionEmission_${year2}`}
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};
