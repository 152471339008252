const IATACodes = [

    { value: `Aarhus/Denmark/AAR`, label: `Aarhus/Denmark/AAR` },
    { value: `Abadan/Iran/ABD`, label: `Abadan/Iran/ABD` },
    { value: `Abeche/Chad/AEH`, label: `Abeche/Chad/AEH` },
    { value: `Aberdeen/United Kingdom/ABZ`, label: `Aberdeen/United Kingdom/ABZ` },
    { value: `Aberdeen (SD)/USA/ABR`, label: `Aberdeen (SD)/USA/ABR` },
    { value: `Abidjan/Cote d'Ivoire/ABJ`, label: `Abidjan/Cote d'Ivoire/ABJ` },
    { value: `Abilene (TX)/USA/ABI`, label: `Abilene (TX)/USA/ABI` },
    { value: `Abu Dhabi - Abu Dhabi International/United Arab Emirates/AUH`, label: `Abu Dhabi - Abu Dhabi International/United Arab Emirates/AUH` },
    { value: `Abuja - Nnamdi Azikiwe International Airport/Nigeria/ABV`, label: `Abuja - Nnamdi Azikiwe International Airport/Nigeria/ABV` },
    { value: `Abu Rudeis/Egypt/AUE`, label: `Abu Rudeis/Egypt/AUE` },
    { value: `Abu Simbel/Egypt/ABS`, label: `Abu Simbel/Egypt/ABS` },
    { value: `Acapulco/Mexico/ACA`, label: `Acapulco/Mexico/ACA` },
    { value: `Accra - Kotoka International Airport/Ghana/ACC`, label: `Accra - Kotoka International Airport/Ghana/ACC` },
    { value: `Adana/Turkey/ADA`, label: `Adana/Turkey/ADA` },
    { value: `Addis Ababa - Bole International Airport/Ethiopia/ADD`, label: `Addis Ababa - Bole International Airport/Ethiopia/ADD` },
    { value: `Adelaide/Australia/ADL`, label: `Adelaide/Australia/ADL` },
    { value: `Aden - Aden International Airport/Yemen/ADE`, label: `Aden - Aden International Airport/Yemen/ADE` },
    { value: `Adiyaman/Turkey/ADF`, label: `Adiyaman/Turkey/ADF` },
    { value: `Adler/Sochi/Russia/AER`, label: `Adler/Sochi/Russia/AER` },
    { value: `Agades/Niger/AJY`, label: `Agades/Niger/AJY` },
    { value: `Agadir/Morocco/AGA`, label: `Agadir/Morocco/AGA` },
    { value: `Agana (Hagåtña)/Guam/SUM`, label: `Agana (Hagåtña)/Guam/SUM` },
    { value: `Aggeneys/South Africa/AGZ`, label: `Aggeneys/South Africa/AGZ` },
    { value: `Aguadilla/Puerto Rico/BQN`, label: `Aguadilla/Puerto Rico/BQN` },
    { value: `Aguascaliente/Mexico/AGU`, label: `Aguascaliente/Mexico/AGU` },
    { value: `Ahmedabad/India/AMD`, label: `Ahmedabad/India/AMD` },
    { value: `Aiyura/Papua New Guinea/AYU`, label: `Aiyura/Papua New Guinea/AYU` },
    { value: `Ajaccio/France/AJA`, label: `Ajaccio/France/AJA` },
    { value: `Akita/Japan/AXT`, label: `Akita/Japan/AXT` },
    { value: `Akron (OH)/USA/CAK`, label: `Akron (OH)/USA/CAK` },
    { value: `Akrotiri - RAF/Cyprus/AKT`, label: `Akrotiri - RAF/Cyprus/AKT` },
    { value: `Al Ain/United Arab Emirates/AAN`, label: `Al Ain/United Arab Emirates/AAN` },
    { value: `Al Arish/Egypt/AAC`, label: `Al Arish/Egypt/AAC` },
    { value: `Albany/Australia/ALH`, label: `Albany/Australia/ALH` },
    { value: `Albany (GA)/USA/ABY`, label: `Albany (GA)/USA/ABY` },
    { value: `Albany (NY) - Albany International Airport/USA/ALB`, label: `Albany (NY) - Albany International Airport/USA/ALB` },
    { value: `Albi/France/LBI`, label: `Albi/France/LBI` },
    { value: `Alborg/Denmark/AAL`, label: `Alborg/Denmark/AAL` },
    { value: `Albuquerque (NM)/USA/ABQ`, label: `Albuquerque (NM)/USA/ABQ` },
    { value: `Albury/Australia/ABX`, label: `Albury/Australia/ABX` },
    { value: `Alderney/Channel Islands/ACI`, label: `Alderney/Channel Islands/ACI` },
    { value: `Aleppo/Syria/ALP`, label: `Aleppo/Syria/ALP` },
    { value: `Alesund/Norway/AES`, label: `Alesund/Norway/AES` },
    { value: `Alexander Bay - Kortdoorn/South Africa/ALJ`, label: `Alexander Bay - Kortdoorn/South Africa/ALJ` },
    { value: `Alexandria - Borg el Arab Airport/Egypt/HBH`, label: `Alexandria - Borg el Arab Airport/Egypt/HBH` },
    { value: `Alexandria - El Nhouza Airport/Egypt/ALY`, label: `Alexandria - El Nhouza Airport/Egypt/ALY` },
    { value: `Alexandria - Esler Field/USA (LA)/ESF`, label: `Alexandria - Esler Field/USA (LA)/ESF` },
    { value: `Alfujairah (Fujairah)/United Arab Emirates/FJR`, label: `Alfujairah (Fujairah)/United Arab Emirates/FJR` },
    { value: `Alghero Sassari/Italy/AHO`, label: `Alghero Sassari/Italy/AHO` },
    { value: `Algiers, Houari Boumediene Airport/Algeria/ALG`, label: `Algiers, Houari Boumediene Airport/Algeria/ALG` },
    { value: `Al Hoceima/Morocco/AHU`, label: `Al Hoceima/Morocco/AHU` },
    { value: `Alicante/Spain/ALC`, label: `Alicante/Spain/ALC` },
    { value: `Alice Springs/Australia/ASP`, label: `Alice Springs/Australia/ASP` },
    { value: `Alldays/South Africa/ADY`, label: `Alldays/South Africa/ADY` },
    { value: `Allentown (PA)/USA/ABE`, label: `Allentown (PA)/USA/ABE` },
    { value: `Almaty (Alma Ata) - Almaty International Airport/Kazakhstan/ALA`, label: `Almaty (Alma Ata) - Almaty International Airport/Kazakhstan/ALA` },
    { value: `Almeria/Spain/LEI`, label: `Almeria/Spain/LEI` },
    { value: `Alta/Norway/ALF`, label: `Alta/Norway/ALF` },
    { value: `Altay/PR China/AAT`, label: `Altay/PR China/AAT` },
    { value: `Altenrhein/Switzerland/ACH`, label: `Altenrhein/Switzerland/ACH` },
    { value: `Altoona (PA)/USA/AOO`, label: `Altoona (PA)/USA/AOO` },
    { value: `Altus/USA/AXS`, label: `Altus/USA/AXS` },
    { value: `Amami/Japan/ASJ`, label: `Amami/Japan/ASJ` },
    { value: `Amarillo (TX)/USA/AMA`, label: `Amarillo (TX)/USA/AMA` },
    { value: `Amazon Bay/Papua New Guinea/AZB`, label: `Amazon Bay/Papua New Guinea/AZB` },
    { value: `Amman - Queen Alia International Airport/Jordan/AMM`, label: `Amman - Queen Alia International Airport/Jordan/AMM` },
    { value: `Amman - Amman-Marka International Airport/Jordan/ADJ`, label: `Amman - Amman-Marka International Airport/Jordan/ADJ` },
    { value: `Amritsar/India/ATQ`, label: `Amritsar/India/ATQ` },
    { value: `Amsterdam - Amsterdam Airport Schiphol/Netherlands/AMS`, label: `Amsterdam - Amsterdam Airport Schiphol/Netherlands/AMS` },
    { value: `Anand/India/QNB`, label: `Anand/India/QNB` },
    { value: `Anchorage (AK) - Ted Stevens Anchorage International/USA/ANC`, label: `Anchorage (AK) - Ted Stevens Anchorage International/USA/ANC` },
    { value: `Ancona - Ancona Falconara Airport/Italy/AOI`, label: `Ancona - Ancona Falconara Airport/Italy/AOI` },
    { value: `Andorra La Vella - Heliport/Andorra/ALV`, label: `Andorra La Vella - Heliport/Andorra/ALV` },
    { value: `Anguilla/Anguilla/AXA`, label: `Anguilla/Anguilla/AXA` },
    { value: `Anjouan - Anjouan Airport/Comoros (Comores)/AJN`, label: `Anjouan - Anjouan Airport/Comoros (Comores)/AJN` },
    { value: `Ankara/Turkey/ANK`, label: `Ankara/Turkey/ANK` },
    { value: `Ankara - Esenboğa International Airport/Turkey/ESB`, label: `Ankara - Esenboğa International Airport/Turkey/ESB` },
    { value: `Annaba/Algeria/AAE`, label: `Annaba/Algeria/AAE` },
    { value: `Ann Arbor (MI)/USA/ARB`, label: `Ann Arbor (MI)/USA/ARB` },
    { value: `Annecy/France/NCY`, label: `Annecy/France/NCY` },
    { value: `Anniston (AL)/USA/ANB`, label: `Anniston (AL)/USA/ANB` },
    { value: `Antalya/Turkey/AYT`, label: `Antalya/Turkey/AYT` },
    { value: `Antananarivo (Tanannarive) - Ivato International Airport/Madagascar/TNR`, label: `Antananarivo (Tanannarive) - Ivato International Airport/Madagascar/TNR` },
    { value: `Antigua, V.C. Bird International/Antigua and Barbuda/ANU`, label: `Antigua, V.C. Bird International/Antigua and Barbuda/ANU` },
    { value: `Antwerp/Belgium/ANR`, label: `Antwerp/Belgium/ANR` },
    { value: `Aomori/Japan/AOJ`, label: `Aomori/Japan/AOJ` },
    { value: `Apia - Faleolo International Airport/Samoa/APW`, label: `Apia - Faleolo International Airport/Samoa/APW` },
    { value: `Appelton/Neenah/Menasha (WI)/USA/ATW`, label: `Appelton/Neenah/Menasha (WI)/USA/ATW` },
    { value: `Aqaba/Jordan/AQJ`, label: `Aqaba/Jordan/AQJ` },
    { value: `Aracaju/Brazil/AJU`, label: `Aracaju/Brazil/AJU` },
    { value: `Arkhangelsk/Russia/ARH`, label: `Arkhangelsk/Russia/ARH` },
    { value: `Arusha/Tanzania/ARK`, label: `Arusha/Tanzania/ARK` },
    { value: `Araxos/Greece/GPA`, label: `Araxos/Greece/GPA` },
    { value: `Arlit/Niger/RLT`, label: `Arlit/Niger/RLT` },
    { value: `Arrecife/Lanzarote/Spain/ACE`, label: `Arrecife/Lanzarote/Spain/ACE` },
    { value: `Aruba - Reina Beatrix International, Oranjestad/Aruba/AUA`, label: `Aruba - Reina Beatrix International, Oranjestad/Aruba/AUA` },
    { value: `Asheville (NC)/USA/AVL`, label: `Asheville (NC)/USA/AVL` },
    { value: `Ashgabat - Saparmurat Turkmenbashy Int. Airport/Turkmenistan/ASB`, label: `Ashgabat - Saparmurat Turkmenbashy Int. Airport/Turkmenistan/ASB` },
    { value: `Asmara - Asmara International/Eritrea/ASM`, label: `Asmara - Asmara International/Eritrea/ASM` },
    { value: `Aspen, (CO) - Aspen-Pitkin County Airport/USA/ASE`, label: `Aspen, (CO) - Aspen-Pitkin County Airport/USA/ASE` },
    { value: `Assiut/Egypt/ATZ`, label: `Assiut/Egypt/ATZ` },
    { value: `Astana - Astana International Airport/Kazakhstan/TSE`, label: `Astana - Astana International Airport/Kazakhstan/TSE` },
    { value: `Asuncion - Asunción International Airport/Paraguay/ASU`, label: `Asuncion - Asunción International Airport/Paraguay/ASU` },
    { value: `Aswan - Daraw Airport/Egypt/ASW`, label: `Aswan - Daraw Airport/Egypt/ASW` },
    { value: `Athens - Elefthérios Venizélos International Airport/Greece/ATH`, label: `Athens - Elefthérios Venizélos International Airport/Greece/ATH` },
    { value: `Athens, Hellinikon Airport/Greece/HEW`, label: `Athens, Hellinikon Airport/Greece/HEW` },
    { value: `Athens (GA)/USA/AHN`, label: `Athens (GA)/USA/AHN` },
    { value: `Athens (OH)/USA/ATO`, label: `Athens (OH)/USA/ATO` },
    { value: `Atlanta (GA) - Hartsfield Atlanta International Airport/USA/ATL`, label: `Atlanta (GA) - Hartsfield Atlanta International Airport/USA/ATL` },
    { value: `Atlantic City (NJ) - Atlantic City International/USA/ACY`, label: `Atlantic City (NJ) - Atlantic City International/USA/ACY` },
    { value: `Attawapiskat, NT/Canada/YAT`, label: `Attawapiskat, NT/Canada/YAT` },
    { value: `Auckland - Auckland International Airport/New Zealand/AKL`, label: `Auckland - Auckland International Airport/New Zealand/AKL` },
    { value: `Augsburg - Augsbur gAirport/Germany/AGB`, label: `Augsburg - Augsbur gAirport/Germany/AGB` },
    { value: `Augusta (GA)/USA/AGS`, label: `Augusta (GA)/USA/AGS` },
    { value: `Augusta (ME) - Augusta State Airport/USA/AUG`, label: `Augusta (ME) - Augusta State Airport/USA/AUG` },
    { value: `Aurillac/France/AUR`, label: `Aurillac/France/AUR` },
    { value: `Austin (TX) - Austin-Bergstrom Airport/USA/AUS`, label: `Austin (TX) - Austin-Bergstrom Airport/USA/AUS` },
    { value: `Ayawasi/Indonesia/AYW`, label: `Ayawasi/Indonesia/AYW` },
    { value: `Ayers Rock - Connellan/Australia/AYQ`, label: `Ayers Rock - Connellan/Australia/AYQ` },
    { value: `Ayr/Australia/AYR`, label: `Ayr/Australia/AYR` },
    { value: `Badajoz/Spain/BJZ`, label: `Badajoz/Spain/BJZ` },
    { value: `Bagdad - Baghdad International Airport/Iraq/BGW`, label: `Bagdad - Baghdad International Airport/Iraq/BGW` },
    { value: `Bagdogra/India/IXB`, label: `Bagdogra/India/IXB` },
    { value: `Bahamas - Lynden Pindling International Airport/The Bahamas/NAS`, label: `Bahamas - Lynden Pindling International Airport/The Bahamas/NAS` },
    { value: `Bahawalpur/Pakistan/BHV`, label: `Bahawalpur/Pakistan/BHV` },
    { value: `Bahrain - Bahrain International Airport/Bahrain/BAH`, label: `Bahrain - Bahrain International Airport/Bahrain/BAH` },
    { value: `Bakersfield (CA)/USA/BFL`, label: `Bakersfield (CA)/USA/BFL` },
    { value: `Baku - Heydar Aliyev International Airport/Azerbaijan/BAK`, label: `Baku - Heydar Aliyev International Airport/Azerbaijan/BAK` },
    { value: `Ballina/Australia/BNK`, label: `Ballina/Australia/BNK` },
    { value: `Baltimore (MD) - Washington International Airport/USA/BWI`, label: `Baltimore (MD) - Washington International Airport/USA/BWI` },
    { value: `Bamaga/Australia/ABM`, label: `Bamaga/Australia/ABM` },
    { value: `Bamako - Bamako-Sénou International Airport/Mali/BKO`, label: `Bamako - Bamako-Sénou International Airport/Mali/BKO` },
    { value: `Bambari/Central African Republic/BBY`, label: `Bambari/Central African Republic/BBY` },
    { value: `Bandar Seri Begawan - Brunei International Airport/Brunei/BWN`, label: `Bandar Seri Begawan - Brunei International Airport/Brunei/BWN` },
    { value: `Bandung - Husein Sastranegara International Airport/Indonesia/BDO`, label: `Bandung - Husein Sastranegara International Airport/Indonesia/BDO` },
    { value: `Bangalore/India/BLR`, label: `Bangalore/India/BLR` },
    { value: `Bangassou/Central African Republic/BGU`, label: `Bangassou/Central African Republic/BGU` },
    { value: `Bangkok, Don Muang/Thailand/DMK`, label: `Bangkok, Don Muang/Thailand/DMK` },
    { value: `Bangkok, Suvarnabhumi International/Thailand/BKK`, label: `Bangkok, Suvarnabhumi International/Thailand/BKK` },
    { value: `Bangor (ME)/USA/BGR`, label: `Bangor (ME)/USA/BGR` },
    { value: `Bangui - M'Poko International Airport/Central African Republic/BGF`, label: `Bangui - M'Poko International Airport/Central African Republic/BGF` },
    { value: `Banjul - Banjul International Airport (Yundum International)/Gambia/BJL`, label: `Banjul - Banjul International Airport (Yundum International)/Gambia/BJL` },
    { value: `Bannu/Pakistan/BNP`, label: `Bannu/Pakistan/BNP` },
    { value: `Barcelona/Spain/BCN`, label: `Barcelona/Spain/BCN` },
    { value: `Barcelona/Venezuela/BLA`, label: `Barcelona/Venezuela/BLA` },
    { value: `Bardufoss/Norway/BDU`, label: `Bardufoss/Norway/BDU` },
    { value: `Bari/Italy/BRI`, label: `Bari/Italy/BRI` },
    { value: `Barisal/Bangladesh/BZL`, label: `Barisal/Bangladesh/BZL` },
    { value: `Baroda/India/BDQ`, label: `Baroda/India/BDQ` },
    { value: `Barra (the famous tidal beach landing)/United Kingdom/BRR`, label: `Barra (the famous tidal beach landing)/United Kingdom/BRR` },
    { value: `Barranquilla/Colombia/BAQ`, label: `Barranquilla/Colombia/BAQ` },
    { value: `Basel/Switzerland/BSL`, label: `Basel/Switzerland/BSL` },
    { value: `Basel/Mulhouse/"Switzerland/France"/EAP`, label: `Basel/Mulhouse/"Switzerland/France"/EAP` },
    { value: `Basra, Basrah/Iraq/BSR`, label: `Basra, Basrah/Iraq/BSR` },
    { value: `Basse-Terre - Pointe-à-Pitre International Airport/Guadeloupe/PTP`, label: `Basse-Terre - Pointe-à-Pitre International Airport/Guadeloupe/PTP` },
    { value: `Basseterre - Robert L. Bradshaw International Airport/Saint Kitts and Nevis/SKB`, label: `Basseterre - Robert L. Bradshaw International Airport/Saint Kitts and Nevis/SKB` },
    { value: `Bastia/France/BIA`, label: `Bastia/France/BIA` },
    { value: `Baton Rouge (LA) - Baton Rouge Metropolitan Airport/USA/BTR`, label: `Baton Rouge (LA) - Baton Rouge Metropolitan Airport/USA/BTR` },
    { value: `Bayreuth - Bindlacher-Berg/Germany/BYU`, label: `Bayreuth - Bindlacher-Berg/Germany/BYU` },
    { value: `Beaumont/Pt. Arthur (TX)/USA/BPT`, label: `Beaumont/Pt. Arthur (TX)/USA/BPT` },
    { value: `Beckley (WV)/USA/BKW`, label: `Beckley (WV)/USA/BKW` },
    { value: `Beef Island - Terrance B. Lettsome/Virgin Islands (British)/EIS`, label: `Beef Island - Terrance B. Lettsome/Virgin Islands (British)/EIS` },
    { value: `Beijing/China/PEK`, label: `Beijing/China/PEK` },
    { value: `Beijing - Nanyuan Airport/China/NAY`, label: `Beijing - Nanyuan Airport/China/NAY` },
    { value: `Beira/Mozambique/BEW`, label: `Beira/Mozambique/BEW` },
    { value: `Beirut - Beirut Rafic Hariri International Airport/Lebanon/BEY`, label: `Beirut - Beirut Rafic Hariri International Airport/Lebanon/BEY` },
    { value: `Belem - Val de Cans International Airport/Brazil/BEL`, label: `Belem - Val de Cans International Airport/Brazil/BEL` },
    { value: `Belfast - George Best Belfast City Airport/United Kingdom/BHD`, label: `Belfast - George Best Belfast City Airport/United Kingdom/BHD` },
    { value: `Belfast - Belfast International Airport/United Kingdom/BFS`, label: `Belfast - Belfast International Airport/United Kingdom/BFS` },
    { value: `Belgaum/India/IXG`, label: `Belgaum/India/IXG` },
    { value: `Belgrad (Beograd) - Belgrade Nikola Tesla International/Serbia/BEG`, label: `Belgrad (Beograd) - Belgrade Nikola Tesla International/Serbia/BEG` },
    { value: `Belize City - Philip S.W.Goldson International/Belize/BZE`, label: `Belize City - Philip S.W.Goldson International/Belize/BZE` },
    { value: `Bellingham (WA)/USA/BLI`, label: `Bellingham (WA)/USA/BLI` },
    { value: `Belo Horizonte - Tancredo Neves International Airport/Brazil/CNF`, label: `Belo Horizonte - Tancredo Neves International Airport/Brazil/CNF` },
    { value: `Bemidji (MN)/USA/BJI`, label: `Bemidji (MN)/USA/BJI` },
    { value: `Benbecula/United Kingdom/BEB`, label: `Benbecula/United Kingdom/BEB` },
    { value: `Benghazi (Bengasi)/Libya/BEN`, label: `Benghazi (Bengasi)/Libya/BEN` },
    { value: `Benguela/Angola/BUG`, label: `Benguela/Angola/BUG` },
    { value: `Benton Harbour (MI)/USA/BEH`, label: `Benton Harbour (MI)/USA/BEH` },
    { value: `Berberati/Central African Republic/BBT`, label: `Berberati/Central African Republic/BBT` },
    { value: `Bergamo/Milan - Orio Al Serio/Italy/BGY`, label: `Bergamo/Milan - Orio Al Serio/Italy/BGY` },
    { value: `Bergen/Norway/BGO`, label: `Bergen/Norway/BGO` },
    { value: `Bergerac - Roumanieres/France/EGC`, label: `Bergerac - Roumanieres/France/EGC` },
    { value: `Berlin/Germany/BER`, label: `Berlin/Germany/BER` },
    { value: `Berlin, Schoenefeld/Germany/SXF`, label: `Berlin, Schoenefeld/Germany/SXF` },
    { value: `Berlin, Tegel/Germany/TXL`, label: `Berlin, Tegel/Germany/TXL` },
    { value: `Berlin, Tempelhof (ceased operating in 2008)/Germany/THF`, label: `Berlin, Tempelhof (ceased operating in 2008)/Germany/THF` },
    { value: `Bermuda - L.F. Wade International Airport/Bermuda/BDA`, label: `Bermuda - L.F. Wade International Airport/Bermuda/BDA` },
    { value: `Berne, Bern-Belp/Switzerland/BRN`, label: `Berne, Bern-Belp/Switzerland/BRN` },
    { value: `Berne, Railway Service/Switzerland/ZDJ`, label: `Berne, Railway Service/Switzerland/ZDJ` },
    { value: `Bethel (AK)/USA/BET`, label: `Bethel (AK)/USA/BET` },
    { value: `Bhopal/India/BHO`, label: `Bhopal/India/BHO` },
    { value: `Bhubaneswar/India/BBI`, label: `Bhubaneswar/India/BBI` },
    { value: `Biarritz/France/BIQ`, label: `Biarritz/France/BIQ` },
    { value: `Bilbao/Spain/BIO`, label: `Bilbao/Spain/BIO` },
    { value: `Billings (MT)/USA/BIL`, label: `Billings (MT)/USA/BIL` },
    { value: `Billund/Denmark/BLL`, label: `Billund/Denmark/BLL` },
    { value: `Bintulu/Malaysia/BTU`, label: `Bintulu/Malaysia/BTU` },
    { value: `Biraro/Central African Republic/IRO`, label: `Biraro/Central African Republic/IRO` },
    { value: `Birmingham - Birmingham International Airport/United Kingdom/BHX`, label: `Birmingham - Birmingham International Airport/United Kingdom/BHX` },
    { value: `Birmingham (AL)/USA/BHM`, label: `Birmingham (AL)/USA/BHM` },
    { value: `Bishkek - Manas International Airport/Kyrgyzstan/FRU`, label: `Bishkek - Manas International Airport/Kyrgyzstan/FRU` },
    { value: `Bismarck (ND) - Bismarck Municipal Airport/USA/BIS`, label: `Bismarck (ND) - Bismarck Municipal Airport/USA/BIS` },
    { value: `Bissau - Osvaldo Vieiro International Airport/Guinea-Bissau/BXO`, label: `Bissau - Osvaldo Vieiro International Airport/Guinea-Bissau/BXO` },
    { value: `Blackpool/United Kingdom/BLK`, label: `Blackpool/United Kingdom/BLK` },
    { value: `Blackwater/Australia/BLT`, label: `Blackwater/Australia/BLT` },
    { value: `Blantyre (Mandala) - Chileka International Airport/Malawi/BLZ`, label: `Blantyre (Mandala) - Chileka International Airport/Malawi/BLZ` },
    { value: `Blenheim/New Zealand/BHE`, label: `Blenheim/New Zealand/BHE` },
    { value: `Bloemfontein - Bloemfontein Airport/South Africa/BFN`, label: `Bloemfontein - Bloemfontein Airport/South Africa/BFN` },
    { value: `Bloomington (IL)/USA/BMI`, label: `Bloomington (IL)/USA/BMI` },
    { value: `Bloomington (IN)/USA/BMG`, label: `Bloomington (IN)/USA/BMG` },
    { value: `Bluefield (WV)/USA/BLF`, label: `Bluefield (WV)/USA/BLF` },
    { value: `Boa Vista/Brazil/BVB`, label: `Boa Vista/Brazil/BVB` },
    { value: `Bobo/Dioulasso/Burkina Faso/BOY`, label: `Bobo/Dioulasso/Burkina Faso/BOY` },
    { value: `Bodo/Norway/BOO`, label: `Bodo/Norway/BOO` },
    { value: `Bodrum - Milas Airport/Turkey/BJV`, label: `Bodrum - Milas Airport/Turkey/BJV` },
    { value: `Bogota - El Nuevo Dorado International Airport/Colombia/BOG`, label: `Bogota - El Nuevo Dorado International Airport/Colombia/BOG` },
    { value: `Boise (ID) - Boise Air Terminal/USA/BOI`, label: `Boise (ID) - Boise Air Terminal/USA/BOI` },
    { value: `Bologna/Italy/BLQ`, label: `Bologna/Italy/BLQ` },
    { value: `Bombay (Mumbai) - Chhatrapati Shivaji International/India/BOM`, label: `Bombay (Mumbai) - Chhatrapati Shivaji International/India/BOM` },
    { value: `Bonaire/Netherlands Antilles/BON`, label: `Bonaire/Netherlands Antilles/BON` },
    { value: `Bonaventure, PQ/Canada/YVB`, label: `Bonaventure, PQ/Canada/YVB` },
    { value: `Bora Bora/French Polynesia/BOB`, label: `Bora Bora/French Polynesia/BOB` },
    { value: `Bordeaux - Bordeaux Airport/France/BOD`, label: `Bordeaux - Bordeaux Airport/France/BOD` },
    { value: `Borrego Springs (CA)/USA/BXS`, label: `Borrego Springs (CA)/USA/BXS` },
    { value: `Boston (MA) - General Edward Lawrence Logan/USA/BOS`, label: `Boston (MA) - General Edward Lawrence Logan/USA/BOS` },
    { value: `Bouake/Cote d'Ivoire/BYK`, label: `Bouake/Cote d'Ivoire/BYK` },
    { value: `Bourgas/Burgas/Bulgaria/BOJ`, label: `Bourgas/Burgas/Bulgaria/BOJ` },
    { value: `Bournemouth/United Kingdom/BOH`, label: `Bournemouth/United Kingdom/BOH` },
    { value: `Bowen/Australia/ZBO`, label: `Bowen/Australia/ZBO` },
    { value: `Bozeman (MT)/USA/BZN`, label: `Bozeman (MT)/USA/BZN` },
    { value: `Bradford/Warren (PA) /Olean (NY)/USA/BFD`, label: `Bradford/Warren (PA) /Olean (NY)/USA/BFD` },
    { value: `Brainerd (MN)/USA/BRD`, label: `Brainerd (MN)/USA/BRD` },
    { value: `Brampton Island/Australia/BMP`, label: `Brampton Island/Australia/BMP` },
    { value: `Brasilia - President Juscelino Kubitschek International/Brazil/BSB`, label: `Brasilia - President Juscelino Kubitschek International/Brazil/BSB` },
    { value: `Bratislava - M. R. Štefánik Airport/Slovakia/BTS`, label: `Bratislava - M. R. Štefánik Airport/Slovakia/BTS` },
    { value: `Brazzaville - Maya-Maya Airport/Congo (ROC)/BZV`, label: `Brazzaville - Maya-Maya Airport/Congo (ROC)/BZV` },
    { value: `Bremen - Bremen Airport (Flughafen Bremen)/Germany/BRE`, label: `Bremen - Bremen Airport (Flughafen Bremen)/Germany/BRE` },
    { value: `Brescia, Montichiari/Italy/VBS`, label: `Brescia, Montichiari/Italy/VBS` },
    { value: `Brest/France/BES`, label: `Brest/France/BES` },
    { value: `Bria/Central African Republic/BIV`, label: `Bria/Central African Republic/BIV` },
    { value: `Bridgeport (CT)/USA/BDR`, label: `Bridgeport (CT)/USA/BDR` },
    { value: `Bridgetown - Grantley Adams International/Barbados/BGI`, label: `Bridgetown - Grantley Adams International/Barbados/BGI` },
    { value: `Brindisi/Italy/BDS`, label: `Brindisi/Italy/BDS` },
    { value: `Brisbane/Australia/BNE`, label: `Brisbane/Australia/BNE` },
    { value: `Bristol/United Kingdom/BRS`, label: `Bristol/United Kingdom/BRS` },
    { value: `Broennoeysund/Norway/BNN`, label: `Broennoeysund/Norway/BNN` },
    { value: `Broken Hill/Australia/BHQ`, label: `Broken Hill/Australia/BHQ` },
    { value: `Brookings (SD)/USA/BKX`, label: `Brookings (SD)/USA/BKX` },
    { value: `Broome/Australia/BME`, label: `Broome/Australia/BME` },
    { value: `Brunswick (GA)/USA/BQK`, label: `Brunswick (GA)/USA/BQK` },
    { value: `Brussels - Brussels Airport/Belgium/BRU`, label: `Brussels - Brussels Airport/Belgium/BRU` },
    { value: `Bucaramanga/Colombia/BGA`, label: `Bucaramanga/Colombia/BGA` },
    { value: `Bucharest/Romania/BUH`, label: `Bucharest/Romania/BUH` },
    { value: `Bucharest - Henri Coandă International Airport/Romania/OTP`, label: `Bucharest - Henri Coandă International Airport/Romania/OTP` },
    { value: `Budapest - Budapest Ferihegy International Airport/Hungary/BUD`, label: `Budapest - Budapest Ferihegy International Airport/Hungary/BUD` },
    { value: `Buenos Aires/Argentina/BUE`, label: `Buenos Aires/Argentina/BUE` },
    { value: `Buenos Aires, Ezeiza International Airport/Argentina/EZE`, label: `Buenos Aires, Ezeiza International Airport/Argentina/EZE` },
    { value: `Buenos Aires, Jorge Newbery/Argentina/AEP`, label: `Buenos Aires, Jorge Newbery/Argentina/AEP` },
    { value: `Buffalo Range/Zimbabwe/BFO`, label: `Buffalo Range/Zimbabwe/BFO` },
    { value: `Buffalo/Niagara Falls (NY)/USA/BUF`, label: `Buffalo/Niagara Falls (NY)/USA/BUF` },
    { value: `Bujumbura - Bujumbura International Airport/Burundi/BJM`, label: `Bujumbura - Bujumbura International Airport/Burundi/BJM` },
    { value: `Bulawayo/Zimbabwe/BUQ`, label: `Bulawayo/Zimbabwe/BUQ` },
    { value: `Bullhead City (NV)/USA/BHC`, label: `Bullhead City (NV)/USA/BHC` },
    { value: `Bundaberg/Australia/BDB`, label: `Bundaberg/Australia/BDB` },
    { value: `Burbank (CA)/USA/BUR`, label: `Burbank (CA)/USA/BUR` },
    { value: `Burlington IA/USA/BRL`, label: `Burlington IA/USA/BRL` },
    { value: `Burlington (VT)/USA/BTV`, label: `Burlington (VT)/USA/BTV` },
    { value: `Burnie (Wynyard)/Australia/BWT`, label: `Burnie (Wynyard)/Australia/BWT` },
    { value: `Butte (MT)/USA/BTM`, label: `Butte (MT)/USA/BTM` },
    { value: `Cabinda/Angola/CAB`, label: `Cabinda/Angola/CAB` },
    { value: `Cagliari/Italy/CAG`, label: `Cagliari/Italy/CAG` },
    { value: `Cairns/Australia/CNS`, label: `Cairns/Australia/CNS` },
    { value: `Cairo - Cairo International Airport/Egypt/CAI`, label: `Cairo - Cairo International Airport/Egypt/CAI` },
    { value: `Calama - El Loa/Chile/CJC`, label: `Calama - El Loa/Chile/CJC` },
    { value: `Calcutta (Kolkata) - Netaji Subhas Chandra/India/CCU`, label: `Calcutta (Kolkata) - Netaji Subhas Chandra/India/CCU` },
    { value: `Calgary - Calgary International Airport/Canada/YYC`, label: `Calgary - Calgary International Airport/Canada/YYC` },
    { value: `Cali/Colombia/CLO`, label: `Cali/Colombia/CLO` },
    { value: `Calicut/India/CCJ`, label: `Calicut/India/CCJ` },
    { value: `Calvi/France/CLY`, label: `Calvi/France/CLY` },
    { value: `Cambridge Bay/Canada/YCB`, label: `Cambridge Bay/Canada/YCB` },
    { value: `Cambrigde/United Kingdom/CBG`, label: `Cambrigde/United Kingdom/CBG` },
    { value: `Campbeltown/United Kingdom/CAL`, label: `Campbeltown/United Kingdom/CAL` },
    { value: `Campo Grande/Brazil/CGR`, label: `Campo Grande/Brazil/CGR` },
    { value: `Canberra - Canberra Airport/Australia/CBR`, label: `Canberra - Canberra Airport/Australia/CBR` },
    { value: `Cancun/Mexico/CUN`, label: `Cancun/Mexico/CUN` },
    { value: `Cannes – Mandelieu Airport/France/CEQ`, label: `Cannes – Mandelieu Airport/France/CEQ` },
    { value: `Canouan (island) - Canouan Airport/Saint Vincent & the Grenadines/CIW`, label: `Canouan (island) - Canouan Airport/Saint Vincent & the Grenadines/CIW` },
    { value: `Cape Town - Cape Town International Airport/South Africa/CPT`, label: `Cape Town - Cape Town International Airport/South Africa/CPT` },
    { value: `Caracas - Simón Bolívar International Airport/Venezuela/CCS`, label: `Caracas - Simón Bolívar International Airport/Venezuela/CCS` },
    { value: `Cardiff - Cardiff Airport/United Kingdom/CWL`, label: `Cardiff - Cardiff Airport/United Kingdom/CWL` },
    { value: `Carlsbad (CA)/USA/CLD`, label: `Carlsbad (CA)/USA/CLD` },
    { value: `Carnarvon/Australia/CVQ`, label: `Carnarvon/Australia/CVQ` },
    { value: `Carnot/Central African Republic/CRF`, label: `Carnot/Central African Republic/CRF` },
    { value: `Carson City (NV)/USA/CSN`, label: `Carson City (NV)/USA/CSN` },
    { value: `Casablanca/Morocco/CAS`, label: `Casablanca/Morocco/CAS` },
    { value: `Casablanca, Mohamed V/Morocco/CMN`, label: `Casablanca, Mohamed V/Morocco/CMN` },
    { value: `Casa de Campo - La Romana International Airport/Dominican Republic/LRM`, label: `Casa de Campo - La Romana International Airport/Dominican Republic/LRM` },
    { value: `Casino/Australia/CSI`, label: `Casino/Australia/CSI` },
    { value: `Casper (WY)/USA/CPR`, label: `Casper (WY)/USA/CPR` },
    { value: `Castaway/Fiji/CST`, label: `Castaway/Fiji/CST` },
    { value: `Cartagena - Rafael Núñez International Airport/Colombia/CTG`, label: `Cartagena - Rafael Núñez International Airport/Colombia/CTG` },
    { value: `Castries - George F. L. Charles Airport/Saint Lucia/SLU`, label: `Castries - George F. L. Charles Airport/Saint Lucia/SLU` },
    { value: `Catania/Italy/CTA`, label: `Catania/Italy/CTA` },
    { value: `Cayenne - Cayenne-Rochambeau Airport/French Guiana/CAY`, label: `Cayenne - Cayenne-Rochambeau Airport/French Guiana/CAY` },
    { value: `Cottbus - Cottbus-Drewitz Airport/Germany/CBU`, label: `Cottbus - Cottbus-Drewitz Airport/Germany/CBU` },
    { value: `Cebu City - Mactan-Cebu International/Philippines/CEB`, label: `Cebu City - Mactan-Cebu International/Philippines/CEB` },
    { value: `Cedar City (UT)/USA/CDC`, label: `Cedar City (UT)/USA/CDC` },
    { value: `Cedar Rapids IA/USA/CID`, label: `Cedar Rapids IA/USA/CID` },
    { value: `Ceduna/Australia/CED`, label: `Ceduna/Australia/CED` },
    { value: `Cessnock/Australia/CES`, label: `Cessnock/Australia/CES` },
    { value: `Chabarovsk (Khabarovsk)/Russia/KHV`, label: `Chabarovsk (Khabarovsk)/Russia/KHV` },
    { value: `Chambery/France/CMF`, label: `Chambery/France/CMF` },
    { value: `Champaign (IL)/USA/CMI`, label: `Champaign (IL)/USA/CMI` },
    { value: `Chandigarh - Chandigarh International Airport/India/IXC`, label: `Chandigarh - Chandigarh International Airport/India/IXC` },
    { value: `Changchun/Jilin, PR China/CGQ`, label: `Changchun/Jilin, PR China/CGQ` },
    { value: `Chania/Greece/CHQ`, label: `Chania/Greece/CHQ` },
    { value: `Chaoyang, Beijing - Chaoyang Airport/PR China/CHG`, label: `Chaoyang, Beijing - Chaoyang Airport/PR China/CHG` },
    { value: `Charleston (SC)/USA/CHS`, label: `Charleston (SC)/USA/CHS` },
    { value: `Charleston (WV) - Yeager Airport/USA/CRW`, label: `Charleston (WV) - Yeager Airport/USA/CRW` },
    { value: `Charlotte (NC)/USA/CLT`, label: `Charlotte (NC)/USA/CLT` },
    { value: `Charlottesville (VA)/USA/CHO`, label: `Charlottesville (VA)/USA/CHO` },
    { value: `Charters Towers/Australia/CXT`, label: `Charters Towers/Australia/CXT` },
    { value: `Chattanooga (TN)/USA/CHA`, label: `Chattanooga (TN)/USA/CHA` },
    { value: `Chengdu - Shuangliu/Sichuan, PR China/CTU`, label: `Chengdu - Shuangliu/Sichuan, PR China/CTU` },
    { value: `Chennai (Madras)/India/MAA`, label: `Chennai (Madras)/India/MAA` },
    { value: `Cheyenne (WY) - Cheyenne Regional Airport/USA/CYS`, label: `Cheyenne (WY) - Cheyenne Regional Airport/USA/CYS` },
    { value: `Chiang Mai - Chiang Mai International Airport/Thailand/CNX`, label: `Chiang Mai - Chiang Mai International Airport/Thailand/CNX` },
    { value: `Chiba City/Japan/QCB`, label: `Chiba City/Japan/QCB` },
    { value: `Chicago (IL), Midway/USA/MDW`, label: `Chicago (IL), Midway/USA/MDW` },
    { value: `Chicago (IL), O'Hare International Airport/USA/ORD`, label: `Chicago (IL), O'Hare International Airport/USA/ORD` },
    { value: `Chicago (IL)/USA/CHI`, label: `Chicago (IL)/USA/CHI` },
    { value: `Chichen Itza/Mexico/CZA`, label: `Chichen Itza/Mexico/CZA` },
    { value: `Chico (CA)/USA/CIC`, label: `Chico (CA)/USA/CIC` },
    { value: `Chihuahua - Gen Fierro Villalobos/Mexico/CUU`, label: `Chihuahua - Gen Fierro Villalobos/Mexico/CUU` },
    { value: `Chios/Greece/JKH`, label: `Chios/Greece/JKH` },
    { value: `Chipata/Zambia/CIP`, label: `Chipata/Zambia/CIP` },
    { value: `Chisinau - Chişinău International Airport/Moldova/KIV`, label: `Chisinau - Chişinău International Airport/Moldova/KIV` },
    { value: `Chita (Tschita)/Russia/HTA`, label: `Chita (Tschita)/Russia/HTA` },
    { value: `Sapporo - New Chitose Airport/Japan/CTS`, label: `Sapporo - New Chitose Airport/Japan/CTS` },
    { value: `Chitral/Pakistan/CJL`, label: `Chitral/Pakistan/CJL` },
    { value: `Chittagong/Bangladesh/CGP`, label: `Chittagong/Bangladesh/CGP` },
    { value: `Chongqing - Jiangbei International Airport/Sichuan, PR China/CKG`, label: `Chongqing - Jiangbei International Airport/Sichuan, PR China/CKG` },
    { value: `Christchurch/New Zealand/CHC`, label: `Christchurch/New Zealand/CHC` },
    { value: `Chub Cay/Bahamas/CCZ`, label: `Chub Cay/Bahamas/CCZ` },
    { value: `Churchill/Canada/YYQ`, label: `Churchill/Canada/YYQ` },
    { value: `Cienfuegos - Jaime González Airport/Cuba/CFG`, label: `Cienfuegos - Jaime González Airport/Cuba/CFG` },
    { value: `Cincinnati (OH) - Cincinnati/Northern Kentucky Int'l/USA/CVG`, label: `Cincinnati (OH) - Cincinnati/Northern Kentucky Int'l/USA/CVG` },
    { value: `Ciudad Del Carmen/Mexico/CME`, label: `Ciudad Del Carmen/Mexico/CME` },
    { value: `Ciudad Guayana/Venezuela/CGU`, label: `Ciudad Guayana/Venezuela/CGU` },
    { value: `Ciudad Juarez/Mexico/CJS`, label: `Ciudad Juarez/Mexico/CJS` },
    { value: `Ciudad Obregon/Mexico/CEN`, label: `Ciudad Obregon/Mexico/CEN` },
    { value: `Ciudad Victoria/Mexico/CVM`, label: `Ciudad Victoria/Mexico/CVM` },
    { value: `Clarksburg (WV)/USA/CKB`, label: `Clarksburg (WV)/USA/CKB` },
    { value: `Clermont/Australia/CMQ`, label: `Clermont/Australia/CMQ` },
    { value: `Clermont Ferrand/France/CFE`, label: `Clermont Ferrand/France/CFE` },
    { value: `Cleveland (OH) , Burke Lakefront/USA/BKL`, label: `Cleveland (OH) , Burke Lakefront/USA/BKL` },
    { value: `Cleveland (OH) - Cleveland Hopkins International/USA/CLE`, label: `Cleveland (OH) - Cleveland Hopkins International/USA/CLE` },
    { value: `Cochabamba/Bolivia/CBB`, label: `Cochabamba/Bolivia/CBB` },
    { value: `Cochin/India/COK`, label: `Cochin/India/COK` },
    { value: `Cody/Powell/Yellowstone (WY)/USA/COD`, label: `Cody/Powell/Yellowstone (WY)/USA/COD` },
    { value: `Coffmann Cove (AK)/USA/KCC`, label: `Coffmann Cove (AK)/USA/KCC` },
    { value: `Coffs Harbour/Australia/CFS`, label: `Coffs Harbour/Australia/CFS` },
    { value: `Coimbatore/India/CJB`, label: `Coimbatore/India/CJB` },
    { value: `Colima/Mexico/CLQ`, label: `Colima/Mexico/CLQ` },
    { value: `College Station/Bryan (TX)/USA/CLL`, label: `College Station/Bryan (TX)/USA/CLL` },
    { value: `Collinsville/Australia/KCE`, label: `Collinsville/Australia/KCE` },
    { value: `Cologne - Cologne Airport (Flughafen Köln/Bonn)/Germany/CGN`, label: `Cologne - Cologne Airport (Flughafen Köln/Bonn)/Germany/CGN` },
    { value: `Colombo - Bandaranaike International Airport/Sri Lanka/CMB`, label: `Colombo - Bandaranaike International Airport/Sri Lanka/CMB` },
    { value: `Colorado Springs (CO)/USA/COS`, label: `Colorado Springs (CO)/USA/COS` },
    { value: `Columbia (SC) - Columbia Metropolitan Airport/USA/CAE`, label: `Columbia (SC) - Columbia Metropolitan Airport/USA/CAE` },
    { value: `Columbus (GA)/USA/CSG`, label: `Columbus (GA)/USA/CSG` },
    { value: `Columbus (OH) - Port Columbus International/USA/CMH`, label: `Columbus (OH) - Port Columbus International/USA/CMH` },
    { value: `Conakry - Conakry International Airport/Guinea/CKY`, label: `Conakry - Conakry International Airport/Guinea/CKY` },
    { value: `Concord (CA) - Buchanan Field/USA/CCR`, label: `Concord (CA) - Buchanan Field/USA/CCR` },
    { value: `Concord (NH) - Concord Municipal Airport/USA/CON`, label: `Concord (NH) - Concord Municipal Airport/USA/CON` },
    { value: `Constantine/Algeria/CZL`, label: `Constantine/Algeria/CZL` },
    { value: `Constanta (Constanța) - Constanta Int'l Airport/Romania/CND`, label: `Constanta (Constanța) - Constanta Int'l Airport/Romania/CND` },
    { value: `Coober Pedy/Australia/CPD`, label: `Coober Pedy/Australia/CPD` },
    { value: `Cooktown/Australia/CTN`, label: `Cooktown/Australia/CTN` },
    { value: `Cooma/Australia/OOM`, label: `Cooma/Australia/OOM` },
    { value: `Copenhagen - Copenhagen Airport/Denmark/CPH`, label: `Copenhagen - Copenhagen Airport/Denmark/CPH` },
    { value: `Cordoba/Argentina/COR`, label: `Cordoba/Argentina/COR` },
    { value: `Cordoba/Spain/ODB`, label: `Cordoba/Spain/ODB` },
    { value: `Cordova (AK)/USA/CDV`, label: `Cordova (AK)/USA/CDV` },
    { value: `Corfu/Greece/CFU`, label: `Corfu/Greece/CFU` },
    { value: `Cork/Ireland/ORK`, label: `Cork/Ireland/ORK` },
    { value: `Corpus Christi (TX)/USA/CRP`, label: `Corpus Christi (TX)/USA/CRP` },
    { value: `Cotonou - Cotonou Cadjehoun Airport/Benin/COO`, label: `Cotonou - Cotonou Cadjehoun Airport/Benin/COO` },
    { value: `Coventry - Baginton/United Kingdom/CVT`, label: `Coventry - Baginton/United Kingdom/CVT` },
    { value: `Cozmel/Mexico/CZM`, label: `Cozmel/Mexico/CZM` },
    { value: `Craig (AK)/USA/CGA`, label: `Craig (AK)/USA/CGA` },
    { value: `Crescent City (CA)/USA/CEC`, label: `Crescent City (CA)/USA/CEC` },
    { value: `Cuiaba - Marechal Rondon International Airport/Brazil/CGB`, label: `Cuiaba - Marechal Rondon International Airport/Brazil/CGB` },
    { value: `Culiacan/Mexico/CUL`, label: `Culiacan/Mexico/CUL` },
    { value: `Curacao - Curaçao International Airport/Netherlands Antilles/CUR`, label: `Curacao - Curaçao International Airport/Netherlands Antilles/CUR` },
    { value: `Curitiba - Afonso Pena International Airport/Brazil/CWB`, label: `Curitiba - Afonso Pena International Airport/Brazil/CWB` },
    { value: `Cuyo/Philippines/CYU`, label: `Cuyo/Philippines/CYU` },
    { value: `Dakar - Léopold Sédar Senghor International Airport/Senegal/DKR`, label: `Dakar - Léopold Sédar Senghor International Airport/Senegal/DKR` },
    { value: `Dalaman/Turkey/DLM`, label: `Dalaman/Turkey/DLM` },
    { value: `Dalby/Australia/DBY`, label: `Dalby/Australia/DBY` },
    { value: `Dalian - Zhoushuizi International Airport/Liaoning, PR China/DLC`, label: `Dalian - Zhoushuizi International Airport/Liaoning, PR China/DLC` },
    { value: `Dallas (TX) , Love Field/USA/DAL`, label: `Dallas (TX) , Love Field/USA/DAL` },
    { value: `Dallas/Ft. Worth (TX) - Dallas/Fort Worth International/USA/DFW`, label: `Dallas/Ft. Worth (TX) - Dallas/Fort Worth International/USA/DFW` },
    { value: `Daloa/Cote d'Ivoire/DJO`, label: `Daloa/Cote d'Ivoire/DJO` },
    { value: `Damascus, International/Syria/DAM`, label: `Damascus, International/Syria/DAM` },
    { value: `Dammam, King Fahad International/Saudi Arabien/DMM`, label: `Dammam, King Fahad International/Saudi Arabien/DMM` },
    { value: `Danville (VA)/USA/DAN`, label: `Danville (VA)/USA/DAN` },
    { value: `Dar es Salam (Daressalam) - Julius Nyerere Int'l/Tanzania/DAR`, label: `Dar es Salam (Daressalam) - Julius Nyerere Int'l/Tanzania/DAR` },
    { value: `Darwin/Australia/DRW`, label: `Darwin/Australia/DRW` },
    { value: `Daydream Island/Australia/DDI`, label: `Daydream Island/Australia/DDI` },
    { value: `Dayton (OH)/USA/DAY`, label: `Dayton (OH)/USA/DAY` },
    { value: `Daytona Beach (FL)/USA/DAB`, label: `Daytona Beach (FL)/USA/DAB` },
    { value: `Decatur (IL)/USA/DEC`, label: `Decatur (IL)/USA/DEC` },
    { value: `Deer Lake/Corner Brook/Canada/YDF`, label: `Deer Lake/Corner Brook/Canada/YDF` },
    { value: `Delhi - Indira Gandhi International Airport/India/DEL`, label: `Delhi - Indira Gandhi International Airport/India/DEL` },
    { value: `Den Haag (The Hague)/Netherlands/HAG`, label: `Den Haag (The Hague)/Netherlands/HAG` },
    { value: `Denizli/Turkey/DNZ`, label: `Denizli/Turkey/DNZ` },
    { value: `Denpasar/Bali/Indonesia/DPS`, label: `Denpasar/Bali/Indonesia/DPS` },
    { value: `Denver (CO) - Denver International Airport/USA/DEN`, label: `Denver (CO) - Denver International Airport/USA/DEN` },
    { value: `Dera Ismail Khan - Dera Ismail Khan Airport/Pakistan/DSK`, label: `Dera Ismail Khan - Dera Ismail Khan Airport/Pakistan/DSK` },
    { value: `Derby/Australia/DRB`, label: `Derby/Australia/DRB` },
    { value: `Derry (Londonderry)/United Kingdom/LDY`, label: `Derry (Londonderry)/United Kingdom/LDY` },
    { value: `Des Moines (IA) - Des Moines International Airport/USA/DSM`, label: `Des Moines (IA) - Des Moines International Airport/USA/DSM` },
    { value: `Detroit (MI) , Coleman A. Young Municipal/USA/DET`, label: `Detroit (MI) , Coleman A. Young Municipal/USA/DET` },
    { value: `Detroit (MI) , Wayne County Airport/USA/DTW`, label: `Detroit (MI) , Wayne County Airport/USA/DTW` },
    { value: `Detroit (MI) , Metropolitan Area/USA/DTT`, label: `Detroit (MI) , Metropolitan Area/USA/DTT` },
    { value: `Devils Lake (ND)/USA/DVL`, label: `Devils Lake (ND)/USA/DVL` },
    { value: `Devonport/Australia/DPO`, label: `Devonport/Australia/DPO` },
    { value: `Dhahran/Saudi Arabia/DHA`, label: `Dhahran/Saudi Arabia/DHA` },
    { value: `Dhaka - Zia International Airport/Bangladesh/DAC`, label: `Dhaka - Zia International Airport/Bangladesh/DAC` },
    { value: `Dili - Nicolau Lobato International Airport/Timor Leste (East Timor)/DIL`, label: `Dili - Nicolau Lobato International Airport/Timor Leste (East Timor)/DIL` },
    { value: `Dillingham (AK)/USA/DLG`, label: `Dillingham (AK)/USA/DLG` },
    { value: `Dinard/France/DNR`, label: `Dinard/France/DNR` },
    { value: `Disneyland Paris/France/DLP`, label: `Disneyland Paris/France/DLP` },
    { value: `Djerba/Tunisia/DJE`, label: `Djerba/Tunisia/DJE` },
    { value: `Djibouti (city) - Djibouti-Ambouli International Airport/Djibouti/JIB`, label: `Djibouti (city) - Djibouti-Ambouli International Airport/Djibouti/JIB` },
    { value: `Dodoma - Dodoma Airport/Tanzania/DOD`, label: `Dodoma - Dodoma Airport/Tanzania/DOD` },
    { value: `Doha - Doha International Airport/Qatar/DOH`, label: `Doha - Doha International Airport/Qatar/DOH` },
    { value: `Doncaster/Sheffield, Robin Hood International Airport/United Kingdom/DSA`, label: `Doncaster/Sheffield, Robin Hood International Airport/United Kingdom/DSA` },
    { value: `Donegal (Carrickfin)/Ireland/CFN`, label: `Donegal (Carrickfin)/Ireland/CFN` },
    { value: `Dortmund/Germany/DTM`, label: `Dortmund/Germany/DTM` },
    { value: `Dothan (AL)/USA/DHN`, label: `Dothan (AL)/USA/DHN` },
    { value: `Douala/Cameroon/DLA`, label: `Douala/Cameroon/DLA` },
    { value: `Dresden - Dresden Airport/Germany/DRS`, label: `Dresden - Dresden Airport/Germany/DRS` },
    { value: `Dubai - Dubai International Airport/United Arab Emirates/DXB`, label: `Dubai - Dubai International Airport/United Arab Emirates/DXB` },
    { value: `Dubbo/Australia/DBO`, label: `Dubbo/Australia/DBO` },
    { value: `Dublin - Dublin International Airport/Ireland/DUB`, label: `Dublin - Dublin International Airport/Ireland/DUB` },
    { value: `Dubois (PA)/USA/DUJ`, label: `Dubois (PA)/USA/DUJ` },
    { value: `Dubrovnik/Croatia (Hrvatska)/DBV`, label: `Dubrovnik/Croatia (Hrvatska)/DBV` },
    { value: `Dubuque IA/USA/DBQ`, label: `Dubuque IA/USA/DBQ` },
    { value: `Duesseldorf - Düsseldorf International Airport/Germany/DUS`, label: `Duesseldorf - Düsseldorf International Airport/Germany/DUS` },
    { value: `Duluth (MN) /Superior (WI)/USA/DLH`, label: `Duluth (MN) /Superior (WI)/USA/DLH` },
    { value: `Dundee/United Kingdom/DND`, label: `Dundee/United Kingdom/DND` },
    { value: `Dunedin/New Zealand/DUD`, label: `Dunedin/New Zealand/DUD` },
    { value: `Dunk Island/Australia/DKI`, label: `Dunk Island/Australia/DKI` },
    { value: `Durango (CO)/USA/DRO`, label: `Durango (CO)/USA/DRO` },
    { value: `Durban/South Africa/DUR`, label: `Durban/South Africa/DUR` },
    { value: `Dushanbe (Duschanbe) - Dushanbe Airport/Tajikistan/DYU`, label: `Dushanbe (Duschanbe) - Dushanbe Airport/Tajikistan/DYU` },
    { value: `Dutch Harbor (AK)/USA/DUT`, label: `Dutch Harbor (AK)/USA/DUT` },
    { value: `Dysart/Australia/DYA`, label: `Dysart/Australia/DYA` },
    { value: `Dzaoudzi/Mayotte/DZA`, label: `Dzaoudzi/Mayotte/DZA` },
    { value: `East London/South Africa/ELS`, label: `East London/South Africa/ELS` },
    { value: `Easter Island/Chile/IPC`, label: `Easter Island/Chile/IPC` },
    { value: `Eau Clarie (WI)/USA/EAU`, label: `Eau Clarie (WI)/USA/EAU` },
    { value: `Edinburgh - Edinburgh Airport/Scotland, UK/EDI`, label: `Edinburgh - Edinburgh Airport/Scotland, UK/EDI` },
    { value: `Edmonton/Canada/YEA`, label: `Edmonton/Canada/YEA` },
    { value: `Edmonton, International/Canada/YEG`, label: `Edmonton, International/Canada/YEG` },
    { value: `Edmonton, Municipal/Canada/YXD`, label: `Edmonton, Municipal/Canada/YXD` },
    { value: `Egilsstadir/Iceland/EGS`, label: `Egilsstadir/Iceland/EGS` },
    { value: `Eindhoven/Netherlands/EIN`, label: `Eindhoven/Netherlands/EIN` },
    { value: `Samana - Samaná El Catey International Airport/Dominican Republic/AZS`, label: `Samana - Samaná El Catey International Airport/Dominican Republic/AZS` },
    { value: `Elba Island, Marina Di Campo/Italy/EBA`, label: `Elba Island, Marina Di Campo/Italy/EBA` },
    { value: `Elat/Israel/ETH`, label: `Elat/Israel/ETH` },
    { value: `Elat, Ovula/Israel/VDA`, label: `Elat, Ovula/Israel/VDA` },
    { value: `Elkhart (IN)/USA/EKI`, label: `Elkhart (IN)/USA/EKI` },
    { value: `Elko (NV)/USA/EKO`, label: `Elko (NV)/USA/EKO` },
    { value: `Ellisras/South Africa/ELL`, label: `Ellisras/South Africa/ELL` },
    { value: `El Minya/Egypt/EMY`, label: `El Minya/Egypt/EMY` },
    { value: `Elmira (NY)/USA/ELM`, label: `Elmira (NY)/USA/ELM` },
    { value: `El Paso (TX) - El Paso International Airport/USA/ELP`, label: `El Paso (TX) - El Paso International Airport/USA/ELP` },
    { value: `Ely (NV)/USA/ELY`, label: `Ely (NV)/USA/ELY` },
    { value: `Emerald/Australia/EDR`, label: `Emerald/Australia/EDR` },
    { value: `Emerald/Australia/EMD`, label: `Emerald/Australia/EMD` },
    { value: `Enontekioe/Finland/ENF`, label: `Enontekioe/Finland/ENF` },
    { value: `Entebbe - Entebbe International Airport/Uganda/EBB`, label: `Entebbe - Entebbe International Airport/Uganda/EBB` },
    { value: `Erfurt - Erfurt Airport (Flughafen Erfurt)/Germany/ERF`, label: `Erfurt - Erfurt Airport (Flughafen Erfurt)/Germany/ERF` },
    { value: `Erie (PA)/USA/ERI`, label: `Erie (PA)/USA/ERI` },
    { value: `Eriwan (Yerevan, Jerevan)/Armenia/EVN`, label: `Eriwan (Yerevan, Jerevan)/Armenia/EVN` },
    { value: `Erzincan/Turkey/ERC`, label: `Erzincan/Turkey/ERC` },
    { value: `Erzurum/Turkey/ERZ`, label: `Erzurum/Turkey/ERZ` },
    { value: `Esbjerg/Denmark/EBJ`, label: `Esbjerg/Denmark/EBJ` },
    { value: `Escanaba (MI)/USA/ESC`, label: `Escanaba (MI)/USA/ESC` },
    { value: `Esperance/Australia/EPR`, label: `Esperance/Australia/EPR` },
    { value: `Eugene (OR)/USA/EUG`, label: `Eugene (OR)/USA/EUG` },
    { value: `Eureka (CA)/USA/ACV`, label: `Eureka (CA)/USA/ACV` },
    { value: `Evansville (IN)/USA/EVV`, label: `Evansville (IN)/USA/EVV` },
    { value: `Evenes/Norway/EVE`, label: `Evenes/Norway/EVE` },
    { value: `Exeter/United Kingdom/EXT`, label: `Exeter/United Kingdom/EXT` },
    { value: `Fairbanks (AK)/USA/FAI`, label: `Fairbanks (AK)/USA/FAI` },
    { value: `Fair Isle (Shetland)/United Kingdom/FIE`, label: `Fair Isle (Shetland)/United Kingdom/FIE` },
    { value: `Faisalabad/Pakistan/LYP`, label: `Faisalabad/Pakistan/LYP` },
    { value: `Fargo (ND) (MN)/USA/FAR`, label: `Fargo (ND) (MN)/USA/FAR` },
    { value: `Farmington (NM)/USA/FMN`, label: `Farmington (NM)/USA/FMN` },
    { value: `Faro/Portugal/FAO`, label: `Faro/Portugal/FAO` },
    { value: `Faroer - Vágar Airport/Denmark/FAE`, label: `Faroer - Vágar Airport/Denmark/FAE` },
    { value: `Fayetteville (AR)/USA/FYV`, label: `Fayetteville (AR)/USA/FYV` },
    { value: `Fayetteville/Ft. Bragg (NC)/USA/FAY`, label: `Fayetteville/Ft. Bragg (NC)/USA/FAY` },
    { value: `Fes/Morocco/FEZ`, label: `Fes/Morocco/FEZ` },
    { value: `Figari/France/FSC`, label: `Figari/France/FSC` },
    { value: `Flagstaff (AZ)/USA/FLG`, label: `Flagstaff (AZ)/USA/FLG` },
    { value: `Flin Flon/Canada/YFO`, label: `Flin Flon/Canada/YFO` },
    { value: `Flint (MI)/USA/FNT`, label: `Flint (MI)/USA/FNT` },
    { value: `Florence (Firenze) - Peretola Airport/Italy/FLR`, label: `Florence (Firenze) - Peretola Airport/Italy/FLR` },
    { value: `Florence (SC)/USA/FLO`, label: `Florence (SC)/USA/FLO` },
    { value: `Florianopolis/Brazil/FLN`, label: `Florianopolis/Brazil/FLN` },
    { value: `Floro/Norway/FRO`, label: `Floro/Norway/FRO` },
    { value: `Fort Albert/Canada/YFA`, label: `Fort Albert/Canada/YFA` },
    { value: `Fortaleza - Pinto Martins Airport/Brazil/FOR`, label: `Fortaleza - Pinto Martins Airport/Brazil/FOR` },
    { value: `Fort de France - Martinique Aimé Césaire International/Martinique/FDF`, label: `Fort de France - Martinique Aimé Césaire International/Martinique/FDF` },
    { value: `Fort Dodge IA/USA/FOD`, label: `Fort Dodge IA/USA/FOD` },
    { value: `Fort Huachuca/Sierra Vista (AZ)/USA/FHU`, label: `Fort Huachuca/Sierra Vista (AZ)/USA/FHU` },
    { value: `Fort Lauderdale/Hollywood (FL)/USA/FLL`, label: `Fort Lauderdale/Hollywood (FL)/USA/FLL` },
    { value: `Fort McMurray/Canada/YMM`, label: `Fort McMurray/Canada/YMM` },
    { value: `Fort Myers, Metropolitan Area (FL)/USA/FMY`, label: `Fort Myers, Metropolitan Area (FL)/USA/FMY` },
    { value: `Fort Myers, Southwest Florida Reg (FL)/USA/RSW`, label: `Fort Myers, Southwest Florida Reg (FL)/USA/RSW` },
    { value: `Fort Riley (KS) - Marshall AAF/USA/FRI`, label: `Fort Riley (KS) - Marshall AAF/USA/FRI` },
    { value: `Fort Smith/Canada/YSM`, label: `Fort Smith/Canada/YSM` },
    { value: `Fort Smith (AR)/USA/FSM`, label: `Fort Smith (AR)/USA/FSM` },
    { value: `Fort St. John/Canada/YXJ`, label: `Fort St. John/Canada/YXJ` },
    { value: `Fort Walton Beach (FL)/USA/VPS`, label: `Fort Walton Beach (FL)/USA/VPS` },
    { value: `Fort Wayne (IN)/USA/FWA`, label: `Fort Wayne (IN)/USA/FWA` },
    { value: `Fort Worth (TX) - Dallas/Fort Worth International Airport/USA/DFW`, label: `Fort Worth (TX) - Dallas/Fort Worth International Airport/USA/DFW` },
    { value: `Foula (Shetland)/United Kingdom/FOU`, label: `Foula (Shetland)/United Kingdom/FOU` },
    { value: `Francistown/Botswana/FRW`, label: `Francistown/Botswana/FRW` },
    { value: `Frankfurt/Main - Frankfurt Airport (Rhein-Main-Flughafen)/Germany/FRA`, label: `Frankfurt/Main - Frankfurt Airport (Rhein-Main-Flughafen)/Germany/FRA` },
    { value: `Frankfurt/Hahn/Germany/HHN`, label: `Frankfurt/Hahn/Germany/HHN` },
    { value: `Franklin/Oil City (PA)/USA/FKL`, label: `Franklin/Oil City (PA)/USA/FKL` },
    { value: `Fredericton/Canada/YFC`, label: `Fredericton/Canada/YFC` },
    { value: `Freeport - Grand Bahama International Airport/Bahamas/FPO`, label: `Freeport - Grand Bahama International Airport/Bahamas/FPO` },
    { value: `Freetown - Freetown-Lungi International Airport/Sierra Leone/FNA`, label: `Freetown - Freetown-Lungi International Airport/Sierra Leone/FNA` },
    { value: `Frejus/France/FRJ`, label: `Frejus/France/FRJ` },
    { value: `Fresno (CA)/USA/FAT`, label: `Fresno (CA)/USA/FAT` },
    { value: `Friedrichshafen - Bodensee-Airport Friedrichshafen/Germany/FDH`, label: `Friedrichshafen - Bodensee-Airport Friedrichshafen/Germany/FDH` },
    { value: `Fuerteventura/Spain/FUE`, label: `Fuerteventura/Spain/FUE` },
    { value: `Fujairah, International Airport/United Arab Emirates/FJR`, label: `Fujairah, International Airport/United Arab Emirates/FJR` },
    { value: `Fukuoka/Japan/FUK`, label: `Fukuoka/Japan/FUK` },
    { value: `Fukushima - Fukushima Airport/Japan/FKS`, label: `Fukushima - Fukushima Airport/Japan/FKS` },
    { value: `Funchal/Portugal/FNC`, label: `Funchal/Portugal/FNC` },
    { value: `Futuna/Wallis and Futuna Islands/FUT`, label: `Futuna/Wallis and Futuna Islands/FUT` },
    { value: `Gaborone - Sir Seretse Khama International Airport/Botswana/GBE`, label: `Gaborone - Sir Seretse Khama International Airport/Botswana/GBE` },
    { value: `Gadsden (AL)/USA/GAD`, label: `Gadsden (AL)/USA/GAD` },
    { value: `Gainesville (FL)/USA/GNV`, label: `Gainesville (FL)/USA/GNV` },
    { value: `Galway/Ireland/GWY`, label: `Galway/Ireland/GWY` },
    { value: `Gander/Canada/YQX`, label: `Gander/Canada/YQX` },
    { value: `Garoua/Cameroon/GOU`, label: `Garoua/Cameroon/GOU` },
    { value: `Gaza City - Gaza International Airport/Palestinian Territory/GZA`, label: `Gaza City - Gaza International Airport/Palestinian Territory/GZA` },
    { value: `Gaziantep/Turkey/GZT`, label: `Gaziantep/Turkey/GZT` },
    { value: `Gdansk/Poland/GDN`, label: `Gdansk/Poland/GDN` },
    { value: `Geelong/Australia/GEX`, label: `Geelong/Australia/GEX` },
    { value: `Geneva - Geneva-Cointrin International Airport/Switzerland/GVA`, label: `Geneva - Geneva-Cointrin International Airport/Switzerland/GVA` },
    { value: `Genoa/Italy/GOA`, label: `Genoa/Italy/GOA` },
    { value: `George/South Africa/GRJ`, label: `George/South Africa/GRJ` },
    { value: `Georgetown - Cheddi Jagan International Airport/Guyana/GEO`, label: `Georgetown - Cheddi Jagan International Airport/Guyana/GEO` },
    { value: `Geraldton/Australia/GET`, label: `Geraldton/Australia/GET` },
    { value: `Gerona/Spain/GRO`, label: `Gerona/Spain/GRO` },
    { value: `Ghent (Gent)/Belgium/GNE`, label: `Ghent (Gent)/Belgium/GNE` },
    { value: `Gibraltar/Gibraltar/GIB`, label: `Gibraltar/Gibraltar/GIB` },
    { value: `Gilette (WY)/USA/GCC`, label: `Gilette (WY)/USA/GCC` },
    { value: `Gilgit/Pakistan/GIL`, label: `Gilgit/Pakistan/GIL` },
    { value: `Gillam/Canada/YGX`, label: `Gillam/Canada/YGX` },
    { value: `Gladstone/Australia/GLT`, label: `Gladstone/Australia/GLT` },
    { value: `Glasgow, Prestwick/United Kingdom/PIK`, label: `Glasgow, Prestwick/United Kingdom/PIK` },
    { value: `Glasgow/United Kingdom/GLA`, label: `Glasgow/United Kingdom/GLA` },
    { value: `Glasgow (MT)/USA/GGW`, label: `Glasgow (MT)/USA/GGW` },
    { value: `Glendive (MT)/USA/GDV`, label: `Glendive (MT)/USA/GDV` },
    { value: `Goa/India/GOI`, label: `Goa/India/GOI` },
    { value: `Goiania, Santa Genoveva Airport/Brazil/GYN`, label: `Goiania, Santa Genoveva Airport/Brazil/GYN` },
    { value: `Gold Coast/Australia/OOL`, label: `Gold Coast/Australia/OOL` },
    { value: `Goondiwindi/Australia/GOO`, label: `Goondiwindi/Australia/GOO` },
    { value: `Goose Bay/Canada/YYR`, label: `Goose Bay/Canada/YYR` },
    { value: `Gorna/Bulgaria/GOZ`, label: `Gorna/Bulgaria/GOZ` },
    { value: `Gothenburg (Göteborg) - Landvetter/Sweden/GOT`, label: `Gothenburg (Göteborg) - Landvetter/Sweden/GOT` },
    { value: `Gove (Nhulunbuy)/Australia/GOV`, label: `Gove (Nhulunbuy)/Australia/GOV` },
    { value: `Govenors Harbour/Bahamas/GHB`, label: `Govenors Harbour/Bahamas/GHB` },
    { value: `Granada/Spain/GRX`, label: `Granada/Spain/GRX` },
    { value: `Grand Bahama International/Bahamas/FPO`, label: `Grand Bahama International/Bahamas/FPO` },
    { value: `Grand Canyon (AZ)/USA/GCN`, label: `Grand Canyon (AZ)/USA/GCN` },
    { value: `Grand Cayman - Owen Roberts International/Cayman Islands/GCM`, label: `Grand Cayman - Owen Roberts International/Cayman Islands/GCM` },
    { value: `Grand Forks (ND)/USA/GFK`, label: `Grand Forks (ND)/USA/GFK` },
    { value: `Grand Junction (CO)/USA/GJT`, label: `Grand Junction (CO)/USA/GJT` },
    { value: `Grand Rapids (MI)/USA/GRR`, label: `Grand Rapids (MI)/USA/GRR` },
    { value: `Grand Rapids (MN)/USA/GPZ`, label: `Grand Rapids (MN)/USA/GPZ` },
    { value: `Graz/Austria/GRZ`, label: `Graz/Austria/GRZ` },
    { value: `Great Falls (MT)/USA/GTF`, label: `Great Falls (MT)/USA/GTF` },
    { value: `Great Keppel Island/Australia/GKL`, label: `Great Keppel Island/Australia/GKL` },
    { value: `Green Bay (WI)/USA/GRB`, label: `Green Bay (WI)/USA/GRB` },
    { value: `Greenbrier/Lewisburg (WV)/USA/LWB`, label: `Greenbrier/Lewisburg (WV)/USA/LWB` },
    { value: `Greensboro/Winston Salem (NC)/USA/GSO`, label: `Greensboro/Winston Salem (NC)/USA/GSO` },
    { value: `Greenville (MS)/USA/GLH`, label: `Greenville (MS)/USA/GLH` },
    { value: `Greenville (NC)/USA/PGV`, label: `Greenville (NC)/USA/PGV` },
    { value: `Greenville/Spartanburg (SC)/USA/GSP`, label: `Greenville/Spartanburg (SC)/USA/GSP` },
    { value: `Grenada - Point Salines Airport also Maurice Bishop/Grenada/GND`, label: `Grenada - Point Salines Airport also Maurice Bishop/Grenada/GND` },
    { value: `Grenoble/France/GNB`, label: `Grenoble/France/GNB` },
    { value: `Griffith/Australia/GFF`, label: `Griffith/Australia/GFF` },
    { value: `Groningen - Eelde/Netherlands/GRQ`, label: `Groningen - Eelde/Netherlands/GRQ` },
    { value: `Groote Eylandt - Alyangula/Australia/GTE`, label: `Groote Eylandt - Alyangula/Australia/GTE` },
    { value: `Groton/New London (CT)/USA/GON`, label: `Groton/New London (CT)/USA/GON` },
    { value: `Guadalajara/Mexico/GDL`, label: `Guadalajara/Mexico/GDL` },
    { value: `Guadalcanal/Solomon Islands/GSI`, label: `Guadalcanal/Solomon Islands/GSI` },
    { value: `Guam/Guam/GUM`, label: `Guam/Guam/GUM` },
    { value: `Guangzhou (Canton) - Baiyun International Airport/Guangdong, PR China/CAN`, label: `Guangzhou (Canton) - Baiyun International Airport/Guangdong, PR China/CAN` },
    { value: `Sao Paulo - Guarulhos International/Brazil/GRU`, label: `Sao Paulo - Guarulhos International/Brazil/GRU` },
    { value: `Guatemala City - La Aurora International Airport/Guatemala/GUA`, label: `Guatemala City - La Aurora International Airport/Guatemala/GUA` },
    { value: `Guayaquil - Simon Bolivar/Ecuador/GYE`, label: `Guayaquil - Simon Bolivar/Ecuador/GYE` },
    { value: `Guernsey/Channel Islands/GCI`, label: `Guernsey/Channel Islands/GCI` },
    { value: `Guettin/Germany/GTI`, label: `Guettin/Germany/GTI` },
    { value: `Gulfport/Biloxi (MS)/USA/GPT`, label: `Gulfport/Biloxi (MS)/USA/GPT` },
    { value: `Guilin - Liangjiang/Guangxi, PR China/KWL`, label: `Guilin - Liangjiang/Guangxi, PR China/KWL` },
    { value: `Gulu/Uganda/ULU`, label: `Gulu/Uganda/ULU` },
    { value: `Gunnison/Crested Butte (CO)/USA/GUC`, label: `Gunnison/Crested Butte (CO)/USA/GUC` },
    { value: `Guwahati/India/GAU`, label: `Guwahati/India/GAU` },
    { value: `Gwadar/Pakistan/GWD`, label: `Gwadar/Pakistan/GWD` },
    { value: `Gweru/Zimbabwe/GWE`, label: `Gweru/Zimbabwe/GWE` },
    { value: `Gympie/Australia/GYP`, label: `Gympie/Australia/GYP` },
    { value: `Hachijo Jima/Japan/HAC`, label: `Hachijo Jima/Japan/HAC` },
    { value: `Hagåtña - Guam International Airport/Guam/GUM`, label: `Hagåtña - Guam International Airport/Guam/GUM` },
    { value: `Haifa/Israel/HFA`, label: `Haifa/Israel/HFA` },
    { value: `Haines (AK)/USA/HNS`, label: `Haines (AK)/USA/HNS` },
    { value: `Hakodate/Japan/HKD`, label: `Hakodate/Japan/HKD` },
    { value: `Halifax International/Canada/YHZ`, label: `Halifax International/Canada/YHZ` },
    { value: `Hall Beach/Canada/YUX`, label: `Hall Beach/Canada/YUX` },
    { value: `Hamburg - Fuhlsbuettel/Germany/HAM`, label: `Hamburg - Fuhlsbuettel/Germany/HAM` },
    { value: `Hamilton/Australia/HLT`, label: `Hamilton/Australia/HLT` },
    { value: `Hamilton/Canada/YHM`, label: `Hamilton/Canada/YHM` },
    { value: `Hamilton/New Zealand/HLZ`, label: `Hamilton/New Zealand/HLZ` },
    { value: `Hamilton Island/Australia/HTI`, label: `Hamilton Island/Australia/HTI` },
    { value: `Hammerfest/Norway/HFT`, label: `Hammerfest/Norway/HFT` },
    { value: `Hancock (MI)/USA/CMX`, label: `Hancock (MI)/USA/CMX` },
    { value: `Hangchow (Hangzhou)/Zhejiang, PR China/HGH`, label: `Hangchow (Hangzhou)/Zhejiang, PR China/HGH` },
    { value: `Hannover/Germany/HAJ`, label: `Hannover/Germany/HAJ` },
    { value: `Hanoi - Noi Bai International Airport/Vietnam/HAN`, label: `Hanoi - Noi Bai International Airport/Vietnam/HAN` },
    { value: `Harare - Harare International Airport/Zimbabwe/HRE`, label: `Harare - Harare International Airport/Zimbabwe/HRE` },
    { value: `Harbin (Haerbin)/Heilongjiang, PR China/HRB`, label: `Harbin (Haerbin)/Heilongjiang, PR China/HRB` },
    { value: `Harlingen/South Padre Island (TX)/USA/HRL`, label: `Harlingen/South Padre Island (TX)/USA/HRL` },
    { value: `Harrington Harbour, PQ/Canada/YHR`, label: `Harrington Harbour, PQ/Canada/YHR` },
    { value: `Harrisburg (PA) - Harrisburg Skyport/USA/HAR`, label: `Harrisburg (PA) - Harrisburg Skyport/USA/HAR` },
    { value: `Harrisburg (PA) - Harrisburg International/USA/MDT`, label: `Harrisburg (PA) - Harrisburg International/USA/MDT` },
    { value: `Hartford (CT) /Springfield (MA)/USA/BDL`, label: `Hartford (CT) /Springfield (MA)/USA/BDL` },
    { value: `Hatyai (Hat Yai)/Thailand/HDY`, label: `Hatyai (Hat Yai)/Thailand/HDY` },
    { value: `Haugesund/Norway/HAU`, label: `Haugesund/Norway/HAU` },
    { value: `Havana - José Martí International/Cuba/HAV`, label: `Havana - José Martí International/Cuba/HAV` },
    { value: `Havre (MT)/USA/HVR`, label: `Havre (MT)/USA/HVR` },
    { value: `Hayman Island/Australia/HIS`, label: `Hayman Island/Australia/HIS` },
    { value: `Helena (MT)/USA/HLN`, label: `Helena (MT)/USA/HLN` },
    { value: `Helsingborg/Sweden/JHE`, label: `Helsingborg/Sweden/JHE` },
    { value: `Helsinki - Vantaa/Finland/HEL`, label: `Helsinki - Vantaa/Finland/HEL` },
    { value: `Heraklion/Greece/HER`, label: `Heraklion/Greece/HER` },
    { value: `Hermosillo - Gen. Pesqueira Garcia/Mexico/HMO`, label: `Hermosillo - Gen. Pesqueira Garcia/Mexico/HMO` },
    { value: `Hervey Bay/Australia/HVB`, label: `Hervey Bay/Australia/HVB` },
    { value: `Hibbing (MN)/USA/HIB`, label: `Hibbing (MN)/USA/HIB` },
    { value: `Hickory (NC)/USA/HKY`, label: `Hickory (NC)/USA/HKY` },
    { value: `Hilo (HI)/USA/ITO`, label: `Hilo (HI)/USA/ITO` },
    { value: `Hilton Head Island (SC)/USA/HHH`, label: `Hilton Head Island (SC)/USA/HHH` },
    { value: `Hinchinbrook Island/Australia/HNK`, label: `Hinchinbrook Island/Australia/HNK` },
    { value: `Hiroshima International/Japan/HIJ`, label: `Hiroshima International/Japan/HIJ` },
    { value: `Ho Chi Minh City (Saigon) - Tan Son Nhat International/Viet Nam/SGN`, label: `Ho Chi Minh City (Saigon) - Tan Son Nhat International/Viet Nam/SGN` },
    { value: `Hobart/Australia/HBA`, label: `Hobart/Australia/HBA` },
    { value: `Hof/Germany/HOQ`, label: `Hof/Germany/HOQ` },
    { value: `Holguin/Cuba/HOG`, label: `Holguin/Cuba/HOG` },
    { value: `Home Hill/Australia/HMH`, label: `Home Hill/Australia/HMH` },
    { value: `Homer (AK)/USA/HOM`, label: `Homer (AK)/USA/HOM` },
    { value: `Hong Kong - International Airport (HKIA)/Hong Kong/HKG`, label: `Hong Kong - International Airport (HKIA)/Hong Kong/HKG` },
    { value: `Hong Kong - Chek Lap Kok/Hong Kong/ZJK`, label: `Hong Kong - Chek Lap Kok/Hong Kong/ZJK` },
    { value: `Honiara Henderson International/Solomon Islands/HIR`, label: `Honiara Henderson International/Solomon Islands/HIR` },
    { value: `Honolulu (HI) - Honolulu International Airport/USA/HNL`, label: `Honolulu (HI) - Honolulu International Airport/USA/HNL` },
    { value: `Hoonah (AK)/USA/HNH`, label: `Hoonah (AK)/USA/HNH` },
    { value: `Horta/Portugal/HOR`, label: `Horta/Portugal/HOR` },
    { value: `Houston (TX) , Hobby/USA/HOU`, label: `Houston (TX) , Hobby/USA/HOU` },
    { value: `Houston, TX - George Bush Intercontinental Airport/USA/IAH`, label: `Houston, TX - George Bush Intercontinental Airport/USA/IAH` },
    { value: `Huahine/French Polynesia/HUH`, label: `Huahine/French Polynesia/HUH` },
    { value: `Huatulco/Mexico/HUX`, label: `Huatulco/Mexico/HUX` },
    { value: `Hue - Phu Bai/Viet Nam/HUI`, label: `Hue - Phu Bai/Viet Nam/HUI` },
    { value: `Humberside/United Kingdom/HUY`, label: `Humberside/United Kingdom/HUY` },
    { value: `Huntington (WV)/USA/HTS`, label: `Huntington (WV)/USA/HTS` },
    { value: `Huntsville (AL)/USA/HSV`, label: `Huntsville (AL)/USA/HSV` },
    { value: `Hurghada International/Egypt/HRG`, label: `Hurghada International/Egypt/HRG` },
    { value: `Huron (SD)/USA/HON`, label: `Huron (SD)/USA/HON` },
    { value: `Hwange National Park/Zimbabwe/HWN`, label: `Hwange National Park/Zimbabwe/HWN` },
    { value: `Hyannis (MA)/USA/HYA`, label: `Hyannis (MA)/USA/HYA` },
    { value: `Hydaburg (AK)/USA/HYG`, label: `Hydaburg (AK)/USA/HYG` },
    { value: `Hyderabad - Rajiv Gandhi International Airport/India/HYD`, label: `Hyderabad - Rajiv Gandhi International Airport/India/HYD` },
    { value: `Hyderabad/Pakistan/HDD`, label: `Hyderabad/Pakistan/HDD` },
    { value: `Ibiza/"Ibiza/Spain"/IBZ`, label: `Ibiza/"Ibiza/Spain"/IBZ` },
    { value: `Idaho Falls (ID)/USA/IDA`, label: `Idaho Falls (ID)/USA/IDA` },
    { value: `Iguazu, Cataratas/Argentina/IGR`, label: `Iguazu, Cataratas/Argentina/IGR` },
    { value: `Ile des Pins/New Caledonia/ILP`, label: `Ile des Pins/New Caledonia/ILP` },
    { value: `Ile Ouen/New Caledonia/IOU`, label: `Ile Ouen/New Caledonia/IOU` },
    { value: `Iliamna (AK)/USA/ILI`, label: `Iliamna (AK)/USA/ILI` },
    { value: `Imperial (CA)/USA/IPL`, label: `Imperial (CA)/USA/IPL` },
    { value: `Incercargill/New Zealand/IVC`, label: `Incercargill/New Zealand/IVC` },
    { value: `Incheon, Incheon International Airport/Korea South/ICN`, label: `Incheon, Incheon International Airport/Korea South/ICN` },
    { value: `Indianapolis (IN) International/USA/IND`, label: `Indianapolis (IN) International/USA/IND` },
    { value: `Ingham/Australia/IGH`, label: `Ingham/Australia/IGH` },
    { value: `Innisfail/Australia/IFL`, label: `Innisfail/Australia/IFL` },
    { value: `Innsbruck - Kranebitten/Austria/INN`, label: `Innsbruck - Kranebitten/Austria/INN` },
    { value: `International Falls (MN)/USA/INL`, label: `International Falls (MN)/USA/INL` },
    { value: `Inuvik/Canada/YEV`, label: `Inuvik/Canada/YEV` },
    { value: `Invercargill/New Zealand/IVC`, label: `Invercargill/New Zealand/IVC` },
    { value: `Inverness/United Kingdom/INV`, label: `Inverness/United Kingdom/INV` },
    { value: `Inykern (CA)/USA/IYK`, label: `Inykern (CA)/USA/IYK` },
    { value: `Iqaluit (Frobisher Bay)/Canada/YFB`, label: `Iqaluit (Frobisher Bay)/Canada/YFB` },
    { value: `Iquitos/Peru/IQT`, label: `Iquitos/Peru/IQT` },
    { value: `Irkutsk/Russia/IKT`, label: `Irkutsk/Russia/IKT` },
    { value: `Ishigaki - New Ishigaki Airport/Japan/ISG`, label: `Ishigaki - New Ishigaki Airport/Japan/ISG` },
    { value: `Islamabad - Benazir Bhutto International Airport/Pakistan/ISB`, label: `Islamabad - Benazir Bhutto International Airport/Pakistan/ISB` },
    { value: `Islay/United Kingdom/ILY`, label: `Islay/United Kingdom/ILY` },
    { value: `Isle of Man//IOM`, label: `Isle of Man//IOM` },
    { value: `Istanbul - Istanbul Atatürk Airport/Turkey/IST`, label: `Istanbul - Istanbul Atatürk Airport/Turkey/IST` },
    { value: `Istanbul - Sabiha Gokcen/Turkey/SAW`, label: `Istanbul - Sabiha Gokcen/Turkey/SAW` },
    { value: `Ithaca/Cortland (NY)/USA/ITH`, label: `Ithaca/Cortland (NY)/USA/ITH` },
    { value: `Ivalo/Finland/IVL`, label: `Ivalo/Finland/IVL` },
    { value: `Ixtapa/Zihuatenejo/Mexico/ZIH`, label: `Ixtapa/Zihuatenejo/Mexico/ZIH` },
    { value: `Izmir/Turkey/IZM`, label: `Izmir/Turkey/IZM` },
    { value: `Izmir - Adnan Menderes Airport/Turkey/ADB`, label: `Izmir - Adnan Menderes Airport/Turkey/ADB` },
    { value: `Jackson Hole (WY)/USA/JAC`, label: `Jackson Hole (WY)/USA/JAC` },
    { value: `Jackson (MI) - Reynolds Municipal/USA/JXN`, label: `Jackson (MI) - Reynolds Municipal/USA/JXN` },
    { value: `Jackson, MN/USA/MJQ`, label: `Jackson, MN/USA/MJQ` },
    { value: `Jackson (MS) - Jackson Internationall/USA/JAN`, label: `Jackson (MS) - Jackson Internationall/USA/JAN` },
    { value: `Jackson (MS) - Hawkins Field/USA/HKS`, label: `Jackson (MS) - Hawkins Field/USA/HKS` },
    { value: `Jackson (TN) - Mckellar/USA/MKL`, label: `Jackson (TN) - Mckellar/USA/MKL` },
    { value: `Jackson Hole (WY)/USA/JAC`, label: `Jackson Hole (WY)/USA/JAC` },
    { value: `Jacksonville (AR) Little Rock AFB/USA/LRF`, label: `Jacksonville (AR) Little Rock AFB/USA/LRF` },
    { value: `Jacksonville (FL) - Cecil Field NAS/USA/NZC`, label: `Jacksonville (FL) - Cecil Field NAS/USA/NZC` },
    { value: `Jacksonville (FL) Jacksonville NAS/USA/NIP`, label: `Jacksonville (FL) Jacksonville NAS/USA/NIP` },
    { value: `Jacksonville (FL) - International/USA/JAX`, label: `Jacksonville (FL) - International/USA/JAX` },
    { value: `Jacksonville (FL) - Craig Municipal/USA/CRG`, label: `Jacksonville (FL) - Craig Municipal/USA/CRG` },
    { value: `Jacksonville (IL) - Municipal Airport/USA/IJX`, label: `Jacksonville (IL) - Municipal Airport/USA/IJX` },
    { value: `Jacksonville (NC)/USA/OAJ`, label: `Jacksonville (NC)/USA/OAJ` },
    { value: `Jacksonville (TX)/USA/JKV`, label: `Jacksonville (TX)/USA/JKV` },
    { value: `Jacmel/Haiti/JAK`, label: `Jacmel/Haiti/JAK` },
    { value: `Jacobabad/Pakistan/JAG`, label: `Jacobabad/Pakistan/JAG` },
    { value: `Jacobina/Brazil/JCM`, label: `Jacobina/Brazil/JCM` },
    { value: `Jacquinot Bay/Papua New Guinea/JAQ`, label: `Jacquinot Bay/Papua New Guinea/JAQ` },
    { value: `Jaffna - Kankesanturai/Sri Lanka/JAF`, label: `Jaffna - Kankesanturai/Sri Lanka/JAF` },
    { value: `Jagdalpur/India/JGB`, label: `Jagdalpur/India/JGB` },
    { value: `Jaipur - Sanganeer/India/JAI`, label: `Jaipur - Sanganeer/India/JAI` },
    { value: `Jaisalmer/India/JSA`, label: `Jaisalmer/India/JSA` },
    { value: `Jakarta - Halim Perdana Kusuma/Indonesia/HLP`, label: `Jakarta - Halim Perdana Kusuma/Indonesia/HLP` },
    { value: `Jakarta - Metropolitan Area/Indonesia/JKT`, label: `Jakarta - Metropolitan Area/Indonesia/JKT` },
    { value: `Jakarta - Soekarno-Hatta International/Indonesia/CGK`, label: `Jakarta - Soekarno-Hatta International/Indonesia/CGK` },
    { value: `Jalalabad/Afghanistan/JAA`, label: `Jalalabad/Afghanistan/JAA` },
    { value: `Jalandhar/India/JLR`, label: `Jalandhar/India/JLR` },
    { value: `Jalapa/Mexico/JAL`, label: `Jalapa/Mexico/JAL` },
    { value: `Jales/Brazil/JLS`, label: `Jales/Brazil/JLS` },
    { value: `Jaluit Island/Marshall Islands/UIT`, label: `Jaluit Island/Marshall Islands/UIT` },
    { value: `Jamba/Angola/JMB`, label: `Jamba/Angola/JMB` },
    { value: `Jambi - Sultan Taha Syarifudn/Indonesia/DJB`, label: `Jambi - Sultan Taha Syarifudn/Indonesia/DJB` },
    { value: `Jambol/Bulgaria/JAM`, label: `Jambol/Bulgaria/JAM` },
    { value: `Jamestown (ND)/USA/JMS`, label: `Jamestown (ND)/USA/JMS` },
    { value: `Jamestown (NY)/USA/JHW`, label: `Jamestown (NY)/USA/JHW` },
    { value: `Jammu - Satwari/India/IXJ`, label: `Jammu - Satwari/India/IXJ` },
    { value: `Jamnagar - Govardhanpur/India/JGA`, label: `Jamnagar - Govardhanpur/India/JGA` },
    { value: `Jamshedpur - Sonari Airport/India/IXW`, label: `Jamshedpur - Sonari Airport/India/IXW` },
    { value: `Janakpur/Nepal/JKR`, label: `Janakpur/Nepal/JKR` },
    { value: `Jandakot/Australia/JAD`, label: `Jandakot/Australia/JAD` },
    { value: `Janesville (WI) - Rock County/USA/JVL`, label: `Janesville (WI) - Rock County/USA/JVL` },
    { value: `Januaria/Brazil/JNA`, label: `Januaria/Brazil/JNA` },
    { value: `Jaque/Panama/JQE`, label: `Jaque/Panama/JQE` },
    { value: `Jatai/Brazil/JTI`, label: `Jatai/Brazil/JTI` },
    { value: `Jauja/Peru/JAU`, label: `Jauja/Peru/JAU` },
    { value: `Jayapura - Sentani/Indonesia/DJJ`, label: `Jayapura - Sentani/Indonesia/DJJ` },
    { value: `Jeddah - King Abdulaziz International/Saudi Arabia/JED`, label: `Jeddah - King Abdulaziz International/Saudi Arabia/JED` },
    { value: `Jefferson City (MO) - Jefferson Memorial/USA/JEF`, label: `Jefferson City (MO) - Jefferson Memorial/USA/JEF` },
    { value: `Jeremie - Jeremie Airport/Haiti/JEE`, label: `Jeremie - Jeremie Airport/Haiti/JEE` },
    { value: `Jerez de la Frontera/Cadiz - La Parra/Spain/XRY`, label: `Jerez de la Frontera/Cadiz - La Parra/Spain/XRY` },
    { value: `Jersey/Channel Islands/JER`, label: `Jersey/Channel Islands/JER` },
    { value: `Jerusalem - Atarot Airport (closed)/Israel/JRS`, label: `Jerusalem - Atarot Airport (closed)/Israel/JRS` },
    { value: `Jessore - Jessore Airport/Bangladesh/JSR`, label: `Jessore - Jessore Airport/Bangladesh/JSR` },
    { value: `Jeypore - Jeypore Airport/India/PYB`, label: `Jeypore - Jeypore Airport/India/PYB` },
    { value: `Ji'an/Jiangxi, China/JGS`, label: `Ji'an/Jiangxi, China/JGS` },
    { value: `Jiamusi - Jiamusi Airport/PR China/JMU`, label: `Jiamusi - Jiamusi Airport/PR China/JMU` },
    { value: `Jiayuguan - Jiayuguan Airport/PR China/JGN`, label: `Jiayuguan - Jiayuguan Airport/PR China/JGN` },
    { value: `Jijel/Algeria/GJL`, label: `Jijel/Algeria/GJL` },
    { value: `Jijiga/Ethiopia/JIJ`, label: `Jijiga/Ethiopia/JIJ` },
    { value: `Jilin/PR China/JIL`, label: `Jilin/PR China/JIL` },
    { value: `Jimma/Ethiopia/JIM`, label: `Jimma/Ethiopia/JIM` },
    { value: `Jinan/Shandong, PR China/TNA`, label: `Jinan/Shandong, PR China/TNA` },
    { value: `Jingdezhen/PR China/JDZ`, label: `Jingdezhen/PR China/JDZ` },
    { value: `Jinghong - Gasa Airport/PR China/JHG`, label: `Jinghong - Gasa Airport/PR China/JHG` },
    { value: `Jining/PR China/JNG`, label: `Jining/PR China/JNG` },
    { value: `Jinja/Uganda/JIN`, label: `Jinja/Uganda/JIN` },
    { value: `Jinjiang/PR China/JJN`, label: `Jinjiang/PR China/JJN` },
    { value: `Jinka - Baco/Jinka Airport/Ethiopia/BCO`, label: `Jinka - Baco/Jinka Airport/Ethiopia/BCO` },
    { value: `Jinzhou - Jinzhou Airport/PR China/JNZ`, label: `Jinzhou - Jinzhou Airport/PR China/JNZ` },
    { value: `Jipijapa/Ecuador/JIP`, label: `Jipijapa/Ecuador/JIP` },
    { value: `Jiri/Nepal/JIR`, label: `Jiri/Nepal/JIR` },
    { value: `Jiujiang - Jiujiang Lushan Airport/PR China/JIU`, label: `Jiujiang - Jiujiang Lushan Airport/PR China/JIU` },
    { value: `Jiwani/Pakistan/JIW`, label: `Jiwani/Pakistan/JIW` },
    { value: `Joacaba/Brazil/JCB`, label: `Joacaba/Brazil/JCB` },
    { value: `Joao Pessoa - Castro Pinto Airport/Brazil/JPA`, label: `Joao Pessoa - Castro Pinto Airport/Brazil/JPA` },
    { value: `Jodhpur/India/JDH`, label: `Jodhpur/India/JDH` },
    { value: `Jönköping (Jonkoping) - Axamo Airport/Sweden/JKG`, label: `Jönköping (Jonkoping) - Axamo Airport/Sweden/JKG` },
    { value: `Joensuu/Finland/JOE`, label: `Joensuu/Finland/JOE` },
    { value: `Johannesburg - OR Tambo International Airport/South Africa/JNB`, label: `Johannesburg - OR Tambo International Airport/South Africa/JNB` },
    { value: `Johnson City (NY) - Binghamton/Endicott/Johnson/USA/BGM`, label: `Johnson City (NY) - Binghamton/Endicott/Johnson/USA/BGM` },
    { value: `Johnston Island/USA/JON`, label: `Johnston Island/USA/JON` },
    { value: `Johnstown (PA)/USA/JST`, label: `Johnstown (PA)/USA/JST` },
    { value: `Johor Bahru - Sultan Ismail International/Malaysia/JHB`, label: `Johor Bahru - Sultan Ismail International/Malaysia/JHB` },
    { value: `Joinville - Cubatao Airport/Brazil/JOI`, label: `Joinville - Cubatao Airport/Brazil/JOI` },
    { value: `Jolo/Philippines/JOL`, label: `Jolo/Philippines/JOL` },
    { value: `Jomsom/Nepal/JMO`, label: `Jomsom/Nepal/JMO` },
    { value: `Jonesboro (AR) Jonesboro Airport/USA/JBR`, label: `Jonesboro (AR) Jonesboro Airport/USA/JBR` },
    { value: `Joplin (MO)/USA/JLN`, label: `Joplin (MO)/USA/JLN` },
    { value: `Jorhat - Rowriah Airport/India/JRH`, label: `Jorhat - Rowriah Airport/India/JRH` },
    { value: `Jos/Nigeria/JOS`, label: `Jos/Nigeria/JOS` },
    { value: `Jose De San Martin/Argentina/JSM`, label: `Jose De San Martin/Argentina/JSM` },
    { value: `Jouf/Saudi Arabia/AJF`, label: `Jouf/Saudi Arabia/AJF` },
    { value: `Juanjui/Peru/JJI`, label: `Juanjui/Peru/JJI` },
    { value: `Juba/South Sudan/JUB`, label: `Juba/South Sudan/JUB` },
    { value: `Juist (island)/Germany/JUI`, label: `Juist (island)/Germany/JUI` },
    { value: `Juiz De Fora - Francisco De Assis Airport/Brazil/JDF`, label: `Juiz De Fora - Francisco De Assis Airport/Brazil/JDF` },
    { value: `Jujuy - El Cadillal Airport/Argentina/JUJ`, label: `Jujuy - El Cadillal Airport/Argentina/JUJ` },
    { value: `Julia Creek/Australia/JCK`, label: `Julia Creek/Australia/JCK` },
    { value: `Juliaca/Peru/JUL`, label: `Juliaca/Peru/JUL` },
    { value: `Jumla/Nepal/JUM`, label: `Jumla/Nepal/JUM` },
    { value: `Jundah/Australia/JUN`, label: `Jundah/Australia/JUN` },
    { value: `Juneau (AK) - Juneau International Airport/USA/JNU`, label: `Juneau (AK) - Juneau International Airport/USA/JNU` },
    { value: `Junin/Argentina/JNI`, label: `Junin/Argentina/JNI` },
    { value: `Juticalpa/Honduras/JUT`, label: `Juticalpa/Honduras/JUT` },
    { value: `Jwaneng/Botswana/JWA`, label: `Jwaneng/Botswana/JWA` },
    { value: `Jyväskylä (Jyvaskyla)/Finland/JYV`, label: `Jyväskylä (Jyvaskyla)/Finland/JYV` },
    { value: `Kabul - Khwaja Rawash Airport/Afghanistan/KBL`, label: `Kabul - Khwaja Rawash Airport/Afghanistan/KBL` },
    { value: `Kagoshima/Japan/KOJ`, label: `Kagoshima/Japan/KOJ` },
    { value: `Kahramanmaras/Turkey/KCM`, label: `Kahramanmaras/Turkey/KCM` },
    { value: `Kahului (HI)/USA/OGG`, label: `Kahului (HI)/USA/OGG` },
    { value: `Kajaani/Finland/KAJ`, label: `Kajaani/Finland/KAJ` },
    { value: `Kalamata/Greece/KLX`, label: `Kalamata/Greece/KLX` },
    { value: `Kalamazoo/Battle Creek (MI)/USA/AZO`, label: `Kalamazoo/Battle Creek (MI)/USA/AZO` },
    { value: `Kalgoorlie/Australia/KGI`, label: `Kalgoorlie/Australia/KGI` },
    { value: `Kaliningrad/Russia/KGD`, label: `Kaliningrad/Russia/KGD` },
    { value: `Kalispell (MT)/USA/FCA`, label: `Kalispell (MT)/USA/FCA` },
    { value: `Kalmar/Sweden/KLR`, label: `Kalmar/Sweden/KLR` },
    { value: `Kamloops, BC/Canada/YKA`, label: `Kamloops, BC/Canada/YKA` },
    { value: `Kamuela (HI)/USA/MUE`, label: `Kamuela (HI)/USA/MUE` },
    { value: `Kano/Nigeria/KAN`, label: `Kano/Nigeria/KAN` },
    { value: `Kanpur/India/KNU`, label: `Kanpur/India/KNU` },
    { value: `Kansas City (MO) - Kansas City International Airport/USA/MCI`, label: `Kansas City (MO) - Kansas City International Airport/USA/MCI` },
    { value: `Kaohsiung International/Taiwan/KHH`, label: `Kaohsiung International/Taiwan/KHH` },
    { value: `Kapalua West (HI)/USA/JHM`, label: `Kapalua West (HI)/USA/JHM` },
    { value: `Karachi - Jinnah International Airport/Pakistan/KHI`, label: `Karachi - Jinnah International Airport/Pakistan/KHI` },
    { value: `Karlsruhe-Baden - Soellingen/Germany/FKB`, label: `Karlsruhe-Baden - Soellingen/Germany/FKB` },
    { value: `Karlstad/Sweden/KSD`, label: `Karlstad/Sweden/KSD` },
    { value: `Karpathos/Greece/AOK`, label: `Karpathos/Greece/AOK` },
    { value: `Karratha/Australia/KTA`, label: `Karratha/Australia/KTA` },
    { value: `Kars/Turkey/KYS`, label: `Kars/Turkey/KYS` },
    { value: `Karumba/Australia/KRB`, label: `Karumba/Australia/KRB` },
    { value: `Karup/Denmark/KRP`, label: `Karup/Denmark/KRP` },
    { value: `Kaschechawan, PQ/Canada/ZKE`, label: `Kaschechawan, PQ/Canada/ZKE` },
    { value: `Kassala/Sudan/KSL`, label: `Kassala/Sudan/KSL` },
    { value: `Katherine/Australia/KTR`, label: `Katherine/Australia/KTR` },
    { value: `Kathmandu - Tribhuvan International Airport/Nepal/KTM`, label: `Kathmandu - Tribhuvan International Airport/Nepal/KTM` },
    { value: `Katima Mulilo/Mpacha/Namibia/MPA`, label: `Katima Mulilo/Mpacha/Namibia/MPA` },
    { value: `Kauhajoki/Finland/KHJ`, label: `Kauhajoki/Finland/KHJ` },
    { value: `Kaunakakai (HI)/USA/MKK`, label: `Kaunakakai (HI)/USA/MKK` },
    { value: `Kavalla/Greece/KVA`, label: `Kavalla/Greece/KVA` },
    { value: `Kayseri/Turkey/ASR`, label: `Kayseri/Turkey/ASR` },
    { value: `Kazan - Kazan International Airport/Russia/KZN`, label: `Kazan - Kazan International Airport/Russia/KZN` },
    { value: `Keetmanshoop/Namibia/KMP`, label: `Keetmanshoop/Namibia/KMP` },
    { value: `Kelowna, BC/Canada/YLW`, label: `Kelowna, BC/Canada/YLW` },
    { value: `Kemi/Tornio/Finland/KEM`, label: `Kemi/Tornio/Finland/KEM` },
    { value: `Kenai (AK)/USA/ENA`, label: `Kenai (AK)/USA/ENA` },
    { value: `Kent (Manston) Kent International/United Kingdom/MSE`, label: `Kent (Manston) Kent International/United Kingdom/MSE` },
    { value: `Kerry County/Ireland/KIR`, label: `Kerry County/Ireland/KIR` },
    { value: `Ketchikan (AK)/USA/KTN`, label: `Ketchikan (AK)/USA/KTN` },
    { value: `Key West (FL)/USA/EYW`, label: `Key West (FL)/USA/EYW` },
    { value: `Khamis Mushayat/Saudi Arabia/AHB`, label: `Khamis Mushayat/Saudi Arabia/AHB` },
    { value: `Kharga - New Valley/Egypt/UVL`, label: `Kharga - New Valley/Egypt/UVL` },
    { value: `Kharkov/Ukraine/HRK`, label: `Kharkov/Ukraine/HRK` },
    { value: `Khartoum - Khartoum International Airport/Sudan/KRT`, label: `Khartoum - Khartoum International Airport/Sudan/KRT` },
    { value: `Khuzdar/Pakistan/KDD`, label: `Khuzdar/Pakistan/KDD` },
    { value: `Kiel - Holtenau/Germany/KEL`, label: `Kiel - Holtenau/Germany/KEL` },
    { value: `Kiev - Borispol/Ukraine/KBP`, label: `Kiev - Borispol/Ukraine/KBP` },
    { value: `Kiev - Zhulhany/Ukraine/IEV`, label: `Kiev - Zhulhany/Ukraine/IEV` },
    { value: `Kigali - Gregoire Kayibanda/Rwanda/KGL`, label: `Kigali - Gregoire Kayibanda/Rwanda/KGL` },
    { value: `Kilimadjaro/Tanzania/JRO`, label: `Kilimadjaro/Tanzania/JRO` },
    { value: `Killeem (TX)/USA/ILE`, label: `Killeem (TX)/USA/ILE` },
    { value: `Kimberley/South Africa/KIM`, label: `Kimberley/South Africa/KIM` },
    { value: `King Island/King Island (Australia)/KNS`, label: `King Island/King Island (Australia)/KNS` },
    { value: `King Salomon (AK)/USA/AKN`, label: `King Salomon (AK)/USA/AKN` },
    { value: `Kingscote/Australia/KGC`, label: `Kingscote/Australia/KGC` },
    { value: `Kingston - Norman Manley/Jamaica/KIN`, label: `Kingston - Norman Manley/Jamaica/KIN` },
    { value: `Kingston (NC)/USA/ISO`, label: `Kingston (NC)/USA/ISO` },
    { value: `Kingstown - E. T. Joshua Airport/Saint Vincent and the Grenadines/SVD`, label: `Kingstown - E. T. Joshua Airport/Saint Vincent and the Grenadines/SVD` },
    { value: `Kinshasa - N'Djili/Congo (DRC)/FIH`, label: `Kinshasa - N'Djili/Congo (DRC)/FIH` },
    { value: `Kiritimati (island) - Cassidy International Airport/Kiribati/CXI`, label: `Kiritimati (island) - Cassidy International Airport/Kiribati/CXI` },
    { value: `Kirkenes/Norway/KKN`, label: `Kirkenes/Norway/KKN` },
    { value: `Kirkuk/Iraq/KIK`, label: `Kirkuk/Iraq/KIK` },
    { value: `Kirkwall (Orkney)/United Kingdom/KOI`, label: `Kirkwall (Orkney)/United Kingdom/KOI` },
    { value: `Kiruna/Sweden/KRN`, label: `Kiruna/Sweden/KRN` },
    { value: `Kisangani/Congo (DRC)/FKI`, label: `Kisangani/Congo (DRC)/FKI` },
    { value: `Kittilä/Finland/KTT`, label: `Kittilä/Finland/KTT` },
    { value: `Kitwe/Zambia/KIW`, label: `Kitwe/Zambia/KIW` },
    { value: `Klagenfurt/Austria/KLU`, label: `Klagenfurt/Austria/KLU` },
    { value: `Klamath Fall (OR)/USA/LMT`, label: `Klamath Fall (OR)/USA/LMT` },
    { value: `Klawock (AK)/USA/KLW`, label: `Klawock (AK)/USA/KLW` },
    { value: `Kleinsee/South Africa/KLZ`, label: `Kleinsee/South Africa/KLZ` },
    { value: `Knock/Ireland/NOC`, label: `Knock/Ireland/NOC` },
    { value: `Knoxville (TN)/USA/TYS`, label: `Knoxville (TN)/USA/TYS` },
    { value: `Kobe/Japan/UKB`, label: `Kobe/Japan/UKB` },
    { value: `Kochi/Japan/KCZ`, label: `Kochi/Japan/KCZ` },
    { value: `Köln, Köln/Bonn/Germany/CGN`, label: `Köln, Köln/Bonn/Germany/CGN` },
    { value: `Kodiak (AK)/USA/ADQ`, label: `Kodiak (AK)/USA/ADQ` },
    { value: `Kohat/Pakistan/OHT`, label: `Kohat/Pakistan/OHT` },
    { value: `Kokkola/Pietarsaari/Finland/KOK`, label: `Kokkola/Pietarsaari/Finland/KOK` },
    { value: `Kolkata (Calcutta) - Netaji Subhas Chandra/India/CCU`, label: `Kolkata (Calcutta) - Netaji Subhas Chandra/India/CCU` },
    { value: `Komatsu/Japan/KMQ`, label: `Komatsu/Japan/KMQ` },
    { value: `Kona (HI)/USA/KOA`, label: `Kona (HI)/USA/KOA` },
    { value: `Konya/Turkey/KYA`, label: `Konya/Turkey/KYA` },
    { value: `Korhogo/Cote d'Ivoire/HGO`, label: `Korhogo/Cote d'Ivoire/HGO` },
    { value: `Kos/Greece/KGS`, label: `Kos/Greece/KGS` },
    { value: `Kota Kinabalu/Malaysia/BKI`, label: `Kota Kinabalu/Malaysia/BKI` },
    { value: `Kotzbue (AK)/USA/OTZ`, label: `Kotzbue (AK)/USA/OTZ` },
    { value: `Kowanyama/Australia/KWM`, label: `Kowanyama/Australia/KWM` },
    { value: `Krakow (Cracow) - John Paul II International Airport/Poland/KRK`, label: `Krakow (Cracow) - John Paul II International Airport/Poland/KRK` },
    { value: `Kristiansand/Norway/KRS`, label: `Kristiansand/Norway/KRS` },
    { value: `Kristianstad/Sweden/KID`, label: `Kristianstad/Sweden/KID` },
    { value: `Kristiansund/Norway/KSU`, label: `Kristiansund/Norway/KSU` },
    { value: `Kuala Lumpur - International Airport/Malaysia/KUL`, label: `Kuala Lumpur - International Airport/Malaysia/KUL` },
    { value: `Kuala Lumpur - Sultan Abdul Aziz Shah/Malaysia/SZB`, label: `Kuala Lumpur - Sultan Abdul Aziz Shah/Malaysia/SZB` },
    { value: `Kuantan/Malaysia/KUA`, label: `Kuantan/Malaysia/KUA` },
    { value: `Kuching/Malaysia/KCH`, label: `Kuching/Malaysia/KCH` },
    { value: `Kumamoto/Japan/KMJ`, label: `Kumamoto/Japan/KMJ` },
    { value: `Kununurra/Australia/KNX`, label: `Kununurra/Australia/KNX` },
    { value: `Kuopio/Finland/KUO`, label: `Kuopio/Finland/KUO` },
    { value: `Kushiro/Japan/KUH`, label: `Kushiro/Japan/KUH` },
    { value: `Kuujjuaq (FortChimo)/Canada/YVP`, label: `Kuujjuaq (FortChimo)/Canada/YVP` },
    { value: `Kuujjuarapik/Canada/YGW`, label: `Kuujjuarapik/Canada/YGW` },
    { value: `Kuusamo/Finland/KAO`, label: `Kuusamo/Finland/KAO` },
    { value: `Kuwait - Kuwait International/Kuwait/KWI`, label: `Kuwait - Kuwait International/Kuwait/KWI` },
    { value: `Kyoto/Japan/UKY`, label: `Kyoto/Japan/UKY` },
    { value: `Labe/Guinea/LEK`, label: `Labe/Guinea/LEK` },
    { value: `Labouchere Bay (AK)/USA/WLB`, label: `Labouchere Bay (AK)/USA/WLB` },
    { value: `Labuan/Malaysia/LBU`, label: `Labuan/Malaysia/LBU` },
    { value: `Lac Brochet, MB/Canada/XLB`, label: `Lac Brochet, MB/Canada/XLB` },
    { value: `La Coruna/Spain/LCG`, label: `La Coruna/Spain/LCG` },
    { value: `La Crosse (WI)/USA/LSE`, label: `La Crosse (WI)/USA/LSE` },
    { value: `Lae - Lae Nadzab Airport/Papua New Guinea/LAE`, label: `Lae - Lae Nadzab Airport/Papua New Guinea/LAE` },
    { value: `La Rochelle/France/LRH`, label: `La Rochelle/France/LRH` },
    { value: `Lafayette (IN)/USA/LAF`, label: `Lafayette (IN)/USA/LAF` },
    { value: `Lafayette, La/USA/LFT`, label: `Lafayette, La/USA/LFT` },
    { value: `Lagos - Murtala Muhammed Airport/Nigeria/LOS`, label: `Lagos - Murtala Muhammed Airport/Nigeria/LOS` },
    { value: `La Grande/Canada/YGL`, label: `La Grande/Canada/YGL` },
    { value: `Lahore/Pakistan/LHE`, label: `Lahore/Pakistan/LHE` },
    { value: `Lake Charles (LA)/USA/LCH`, label: `Lake Charles (LA)/USA/LCH` },
    { value: `Lake Havasu City (AZ)/USA/HII`, label: `Lake Havasu City (AZ)/USA/HII` },
    { value: `Lake Tahoe (CA)/USA/TVL`, label: `Lake Tahoe (CA)/USA/TVL` },
    { value: `Lakselv/Norway/LKL`, label: `Lakselv/Norway/LKL` },
    { value: `Lambarene/Gabon/LBQ`, label: `Lambarene/Gabon/LBQ` },
    { value: `Lamezia Terme/Italy/SUF`, label: `Lamezia Terme/Italy/SUF` },
    { value: `Lampedusa/Italy/LMP`, label: `Lampedusa/Italy/LMP` },
    { value: `Lanai City (HI)/USA/LNY`, label: `Lanai City (HI)/USA/LNY` },
    { value: `Lancaster (PA)/USA/LNS`, label: `Lancaster (PA)/USA/LNS` },
    { value: `Land's End/United Kingdom/LEQ`, label: `Land's End/United Kingdom/LEQ` },
    { value: `Langkawi (islands)/Malaysia/LGK`, label: `Langkawi (islands)/Malaysia/LGK` },
    { value: `Lannion/France/LAI`, label: `Lannion/France/LAI` },
    { value: `Lanseria/South Africa/HLA`, label: `Lanseria/South Africa/HLA` },
    { value: `Lansing (MI)/USA/LAN`, label: `Lansing (MI)/USA/LAN` },
    { value: `La Paz - El Alto/Bolivia/LPB`, label: `La Paz - El Alto/Bolivia/LPB` },
    { value: `La Paz - Leon/Mexico/LAP`, label: `La Paz - Leon/Mexico/LAP` },
    { value: `Lappeenranta/Finland/LPP`, label: `Lappeenranta/Finland/LPP` },
    { value: `Laramie (WY)/USA/LAR`, label: `Laramie (WY)/USA/LAR` },
    { value: `Laredo (TX)/USA/LRD`, label: `Laredo (TX)/USA/LRD` },
    { value: `Larnaca/Cyprus/LCA`, label: `Larnaca/Cyprus/LCA` },
    { value: `Las Palmas/Spain/LPA`, label: `Las Palmas/Spain/LPA` },
    { value: `Las Vegas (NV)/USA/LAS`, label: `Las Vegas (NV)/USA/LAS` },
    { value: `Latrobe (PA)/USA/LBE`, label: `Latrobe (PA)/USA/LBE` },
    { value: `Launceston/Australia/LST`, label: `Launceston/Australia/LST` },
    { value: `Laurel/Hattisburg (MS)/USA/PIB`, label: `Laurel/Hattisburg (MS)/USA/PIB` },
    { value: `Laverton/Australia/LVO`, label: `Laverton/Australia/LVO` },
    { value: `Lawton (OK)/USA/LAW`, label: `Lawton (OK)/USA/LAW` },
    { value: `Lazaro Cardenas/Mexico/LZC`, label: `Lazaro Cardenas/Mexico/LZC` },
    { value: `Leaf Rapids/Canada/YLR`, label: `Leaf Rapids/Canada/YLR` },
    { value: `Learmouth (Exmouth)/Australia/LEA`, label: `Learmouth (Exmouth)/Australia/LEA` },
    { value: `Lebanon (NH)/USA/LEB`, label: `Lebanon (NH)/USA/LEB` },
    { value: `Leeds/Bradford/United Kingdom/LBA`, label: `Leeds/Bradford/United Kingdom/LBA` },
    { value: `Leinster/Australia/LER`, label: `Leinster/Australia/LER` },
    { value: `Leipzig/Germany/LEJ`, label: `Leipzig/Germany/LEJ` },
    { value: `Lelystad/Netherlands/LEY`, label: `Lelystad/Netherlands/LEY` },
    { value: `Leon/Mexico/BJX`, label: `Leon/Mexico/BJX` },
    { value: `Leonora/Australia/LNO`, label: `Leonora/Australia/LNO` },
    { value: `Lerwick/Tingwall (Shetland Islands)/United Kingdom/LWK`, label: `Lerwick/Tingwall (Shetland Islands)/United Kingdom/LWK` },
    { value: `Lewiston (ID)/USA/LWS`, label: `Lewiston (ID)/USA/LWS` },
    { value: `Lewistown (MT)/USA/LWT`, label: `Lewistown (MT)/USA/LWT` },
    { value: `Lexington (KY)/USA/LEX`, label: `Lexington (KY)/USA/LEX` },
    { value: `Libreville/Gabon/LBV`, label: `Libreville/Gabon/LBV` },
    { value: `Lidkoeping/Sweden/LDK`, label: `Lidkoeping/Sweden/LDK` },
    { value: `Liege/Belgium/LGG`, label: `Liege/Belgium/LGG` },
    { value: `Lifou/Loyaute, Pazifik/LIF`, label: `Lifou/Loyaute, Pazifik/LIF` },
    { value: `Lihue (HI)/USA/LIH`, label: `Lihue (HI)/USA/LIH` },
    { value: `Lille - Lille Airport/France/LIL`, label: `Lille - Lille Airport/France/LIL` },
    { value: `Lilongwe - Lilongwe International/Malawi/LLW`, label: `Lilongwe - Lilongwe International/Malawi/LLW` },
    { value: `Lima - J Chavez International/Peru/LIM`, label: `Lima - J Chavez International/Peru/LIM` },
    { value: `Limassol/Cyprus/QLI`, label: `Limassol/Cyprus/QLI` },
    { value: `Limoges/France/LIG`, label: `Limoges/France/LIG` },
    { value: `Lincoln (NE)/USA/LNK`, label: `Lincoln (NE)/USA/LNK` },
    { value: `Lindeman Island/Australia/LDC`, label: `Lindeman Island/Australia/LDC` },
    { value: `Linz - Hoersching/Austria/LNZ`, label: `Linz - Hoersching/Austria/LNZ` },
    { value: `Lisala/Congo (DRC)/LIQ`, label: `Lisala/Congo (DRC)/LIQ` },
    { value: `Lisbon - Lisboa/Portugal/LIS`, label: `Lisbon - Lisboa/Portugal/LIS` },
    { value: `Lismore/Australia/LSY`, label: `Lismore/Australia/LSY` },
    { value: `Little Rock (AR)/USA/LIT`, label: `Little Rock (AR)/USA/LIT` },
    { value: `Liverpool/United Kingdom/LPL`, label: `Liverpool/United Kingdom/LPL` },
    { value: `Lizard Island/Australia/LZR`, label: `Lizard Island/Australia/LZR` },
    { value: `Ljubljana - Brnik/Slovenia/LJU`, label: `Ljubljana - Brnik/Slovenia/LJU` },
    { value: `Lockhart River/Australia/IRG`, label: `Lockhart River/Australia/IRG` },
    { value: `Lome/Togo/LFW`, label: `Lome/Togo/LFW` },
    { value: `London/Canada/YXU`, label: `London/Canada/YXU` },
    { value: `London Metropolitan Area/United Kingdom/LON`, label: `London Metropolitan Area/United Kingdom/LON` },
    { value: `London - City Airport/United Kingdom/LCY`, label: `London - City Airport/United Kingdom/LCY` },
    { value: `London - Gatwick/United Kingdom/LGW`, label: `London - Gatwick/United Kingdom/LGW` },
    { value: `London - Heathrow/United Kingdom/LHR`, label: `London - Heathrow/United Kingdom/LHR` },
    { value: `London - Luton/United Kingdom/LTN`, label: `London - Luton/United Kingdom/LTN` },
    { value: `London - Stansted/United Kingdom/STN`, label: `London - Stansted/United Kingdom/STN` },
    { value: `Londonderry - Eglinton/United Kingdom/LDY`, label: `Londonderry - Eglinton/United Kingdom/LDY` },
    { value: `Long Beach (CA)/USA/LGB`, label: `Long Beach (CA)/USA/LGB` },
    { value: `Long Island (AK)/USA/LIJ`, label: `Long Island (AK)/USA/LIJ` },
    { value: `Long Island, Islip (NY) - Mac Arthur/USA/ISP`, label: `Long Island, Islip (NY) - Mac Arthur/USA/ISP` },
    { value: `Longreach/Australia/LRE`, label: `Longreach/Australia/LRE` },
    { value: `Longview/Kilgore (TX)/USA/GGG`, label: `Longview/Kilgore (TX)/USA/GGG` },
    { value: `Longyearbyen - Svalbard/"Svalbard/Norway"/LYR`, label: `Longyearbyen - Svalbard/"Svalbard/Norway"/LYR` },
    { value: `Loreto/Mexico/LTO`, label: `Loreto/Mexico/LTO` },
    { value: `Lorient/France/LRT`, label: `Lorient/France/LRT` },
    { value: `Los Angeles (CA) - International/USA/LAX`, label: `Los Angeles (CA) - International/USA/LAX` },
    { value: `Los Cabos/Mexico/SJD`, label: `Los Cabos/Mexico/SJD` },
    { value: `Los Mochis/Mexico/LMM`, label: `Los Mochis/Mexico/LMM` },
    { value: `Los Rodeos/"Teneriffa/Spain"/TFN`, label: `Los Rodeos/"Teneriffa/Spain"/TFN` },
    { value: `Losinj - Losinj Arpt/Croatia (Hrvatska)/LSZ`, label: `Losinj - Losinj Arpt/Croatia (Hrvatska)/LSZ` },
    { value: `Lourdes/Tarbes/France/LDE`, label: `Lourdes/Tarbes/France/LDE` },
    { value: `Louisville (KY)/USA/SDF`, label: `Louisville (KY)/USA/SDF` },
    { value: `Luanda - 4 de Fevereiro/Angola/LAD`, label: `Luanda - 4 de Fevereiro/Angola/LAD` },
    { value: `Lubbock (TX)/USA/LBB`, label: `Lubbock (TX)/USA/LBB` },
    { value: `Lucknow/India/LKO`, label: `Lucknow/India/LKO` },
    { value: `Luederitz/Namibia/LUD`, label: `Luederitz/Namibia/LUD` },
    { value: `Luga/Malta/MLA`, label: `Luga/Malta/MLA` },
    { value: `Lugano/Switzerland/LUG`, label: `Lugano/Switzerland/LUG` },
    { value: `Lulea/Sweden/LLA`, label: `Lulea/Sweden/LLA` },
    { value: `Lumbumbashi/Congo (DRC)/FBM`, label: `Lumbumbashi/Congo (DRC)/FBM` },
    { value: `Lusaka - Lusaka International Airport/Zambia/LUN`, label: `Lusaka - Lusaka International Airport/Zambia/LUN` },
    { value: `Lusisiki/South Africa/LUJ`, label: `Lusisiki/South Africa/LUJ` },
    { value: `Luxembourg/Luxembourg/LUX`, label: `Luxembourg/Luxembourg/LUX` },
    { value: `Luxi - Mangshi/Yunnan, PR China/LUM`, label: `Luxi - Mangshi/Yunnan, PR China/LUM` },
    { value: `Luxor/Egypt/LXR`, label: `Luxor/Egypt/LXR` },
    { value: `Lvov (Lwow, Lemberg)/Ukraine/LWO`, label: `Lvov (Lwow, Lemberg)/Ukraine/LWO` },
    { value: `Lydd - Lydd International Airport/United Kingdom/LYX`, label: `Lydd - Lydd International Airport/United Kingdom/LYX` },
    { value: `Lynchburg (VA)/USA/LYH`, label: `Lynchburg (VA)/USA/LYH` },
    { value: `Lyon - Lyon-Saint Exupéry Airport/France/LYS`, label: `Lyon - Lyon-Saint Exupéry Airport/France/LYS` },
    { value: `Lyons (KS) - Rice County Municipal/USA/LYO`, label: `Lyons (KS) - Rice County Municipal/USA/LYO` },
    { value: `Maastricht/Aachen/Netherlands/MST`, label: `Maastricht/Aachen/Netherlands/MST` },
    { value: `Macapa - Alberto Alcolumbre International Airport/Brazil/MCP`, label: `Macapa - Alberto Alcolumbre International Airport/Brazil/MCP` },
    { value: `Macau - Macau International Airport/Macau, China SAR/MFM`, label: `Macau - Macau International Airport/Macau, China SAR/MFM` },
    { value: `Maceio - Zumbi dos Palmares International Airport/Brazil/MCZ`, label: `Maceio - Zumbi dos Palmares International Airport/Brazil/MCZ` },
    { value: `Mackay/Australia/MKY`, label: `Mackay/Australia/MKY` },
    { value: `Macon (GA)/USA/MCN`, label: `Macon (GA)/USA/MCN` },
    { value: `Mactan Island - Nab/Philippines/NOP`, label: `Mactan Island - Nab/Philippines/NOP` },
    { value: `Madinah (Medina) - Mohammad Bin Abdulaziz/Saudi Arabia/MED`, label: `Madinah (Medina) - Mohammad Bin Abdulaziz/Saudi Arabia/MED` },
    { value: `Madison (WI) - Dane County Regional Airport/USA/MSN`, label: `Madison (WI) - Dane County Regional Airport/USA/MSN` },
    { value: `Madras (Chennai) - Chennai International Airport/India/MAA`, label: `Madras (Chennai) - Chennai International Airport/India/MAA` },
    { value: `Madrid - Barajas Airport/Spain/MAD`, label: `Madrid - Barajas Airport/Spain/MAD` },
    { value: `Mahe - Seychelles International/Seychelles/SEZ`, label: `Mahe - Seychelles International/Seychelles/SEZ` },
    { value: `Mahon/Spain/MAH`, label: `Mahon/Spain/MAH` },
    { value: `Maitland/Australia/MTL`, label: `Maitland/Australia/MTL` },
    { value: `Majunga/Madagascar/MJN`, label: `Majunga/Madagascar/MJN` },
    { value: `Makung/Taiwan/MZG`, label: `Makung/Taiwan/MZG` },
    { value: `Malabo - Malabo International Airport/Equatorial Guinea/SSG`, label: `Malabo - Malabo International Airport/Equatorial Guinea/SSG` },
    { value: `Malaga/Spain/AGP`, label: `Malaga/Spain/AGP` },
    { value: `Malatya/Turkey/MLX`, label: `Malatya/Turkey/MLX` },
    { value: `Male - Velana International Airport/Maledives/MLE`, label: `Male - Velana International Airport/Maledives/MLE` },
    { value: `Malindi/Kenya/MYD`, label: `Malindi/Kenya/MYD` },
    { value: `Malmo (Malmoe)/Sweden/MMA`, label: `Malmo (Malmoe)/Sweden/MMA` },
    { value: `Malmo (Malmö) - Malmö Airport/Sweden/MMX`, label: `Malmo (Malmö) - Malmö Airport/Sweden/MMX` },
    { value: `Man/Cote d'Ivoire/MJC`, label: `Man/Cote d'Ivoire/MJC` },
    { value: `Managua - Augusto C Sandino/Nicaragua/MGA`, label: `Managua - Augusto C Sandino/Nicaragua/MGA` },
    { value: `Manaus - Eduardo Gomes International Airport/Brazil/MAO`, label: `Manaus - Eduardo Gomes International Airport/Brazil/MAO` },
    { value: `Manchester/United Kingdom/MAN`, label: `Manchester/United Kingdom/MAN` },
    { value: `Manchester (NH)/USA/MHT`, label: `Manchester (NH)/USA/MHT` },
    { value: `Mandalay - Mandalay-Annisaton Airport/Myanmar/MDL`, label: `Mandalay - Mandalay-Annisaton Airport/Myanmar/MDL` },
    { value: `Manguna/Papua New Guinea/MFO`, label: `Manguna/Papua New Guinea/MFO` },
    { value: `Manihi/French Polynesia/XMH`, label: `Manihi/French Polynesia/XMH` },
    { value: `Manila - Ninoy Aquino International/Philippines/MNL`, label: `Manila - Ninoy Aquino International/Philippines/MNL` },
    { value: `Manzanillo/Mexico/ZLO`, label: `Manzanillo/Mexico/ZLO` },
    { value: `Manzini - Matsapha International/Swaziland/MTS`, label: `Manzini - Matsapha International/Swaziland/MTS` },
    { value: `Maputo - Maputo International/Mozambique/MPM`, label: `Maputo - Maputo International/Mozambique/MPM` },
    { value: `Mar del Plata/Argentina/MDQ`, label: `Mar del Plata/Argentina/MDQ` },
    { value: `Maracaibo - La Chinita International/Venezuela/MAR`, label: `Maracaibo - La Chinita International/Venezuela/MAR` },
    { value: `Maradi/Niger/MFQ`, label: `Maradi/Niger/MFQ` },
    { value: `Maras/Turkey/KCM`, label: `Maras/Turkey/KCM` },
    { value: `Marathon (FL)/USA/MTH`, label: `Marathon (FL)/USA/MTH` },
    { value: `Mardin/Turkey/MQM`, label: `Mardin/Turkey/MQM` },
    { value: `Mare/New Caledonia/MEE`, label: `Mare/New Caledonia/MEE` },
    { value: `Margate/South Africa/MGH`, label: `Margate/South Africa/MGH` },
    { value: `Margerita/Venezuela/PMV`, label: `Margerita/Venezuela/PMV` },
    { value: `Maribor/Slovenia/MBX`, label: `Maribor/Slovenia/MBX` },
    { value: `Mariehamn (Maarianhamina)/Finland/MHQ`, label: `Mariehamn (Maarianhamina)/Finland/MHQ` },
    { value: `Maroua/Cameroon/MVR`, label: `Maroua/Cameroon/MVR` },
    { value: `Marquette (MI)/USA/MQT`, label: `Marquette (MI)/USA/MQT` },
    { value: `Marrakesh - Menara Airport/Morocco/RAK`, label: `Marrakesh - Menara Airport/Morocco/RAK` },
    { value: `Marsa Alam/Egypt/RMF`, label: `Marsa Alam/Egypt/RMF` },
    { value: `Marsa Matrah (Marsa Matruh)/Egypt/MUH`, label: `Marsa Matrah (Marsa Matruh)/Egypt/MUH` },
    { value: `Marseille - Marseille Provence Airport/France/MRS`, label: `Marseille - Marseille Provence Airport/France/MRS` },
    { value: `Marsh Harbour/Bahamas/MHH`, label: `Marsh Harbour/Bahamas/MHH` },
    { value: `Martha's Vineyard (MA)/USA/MVY`, label: `Martha's Vineyard (MA)/USA/MVY` },
    { value: `Martinsburg (WV)/USA/MRB`, label: `Martinsburg (WV)/USA/MRB` },
    { value: `Maryborough/Australia/MBH`, label: `Maryborough/Australia/MBH` },
    { value: `Maseru - Moshoeshoe International/Lesotho/MSU`, label: `Maseru - Moshoeshoe International/Lesotho/MSU` },
    { value: `Mason City IA/USA/MCW`, label: `Mason City IA/USA/MCW` },
    { value: `Masvingo/Zimbabwe/MVZ`, label: `Masvingo/Zimbabwe/MVZ` },
    { value: `Matsumoto, Nagano/Japan/MMJ`, label: `Matsumoto, Nagano/Japan/MMJ` },
    { value: `Matsuyama/Japan/MYJ`, label: `Matsuyama/Japan/MYJ` },
    { value: `Mattoon (IL)/USA/MTO`, label: `Mattoon (IL)/USA/MTO` },
    { value: `Maun/Botswana/MUB`, label: `Maun/Botswana/MUB` },
    { value: `Maupiti/French Polynesia/MAU`, label: `Maupiti/French Polynesia/MAU` },
    { value: `Mauritius - S.Seewoosagur Ram International/Mauritius/MRU`, label: `Mauritius - S.Seewoosagur Ram International/Mauritius/MRU` },
    { value: `Mayaguez/Puerto Rico/MAZ`, label: `Mayaguez/Puerto Rico/MAZ` },
    { value: `Mazatlan/Mexico/MZT`, label: `Mazatlan/Mexico/MZT` },
    { value: `McAllen (TX)/USA/MFE`, label: `McAllen (TX)/USA/MFE` },
    { value: `Medan - Polania Int'l (now Soewondo AFB)/Indonesia/MES`, label: `Medan - Polania Int'l (now Soewondo AFB)/Indonesia/MES` },
    { value: `Medan - Kualanamu International/Indonesia/KNO`, label: `Medan - Kualanamu International/Indonesia/KNO` },
    { value: `Medellin - José María Córdova International/Colombia/MDE`, label: `Medellin - José María Córdova International/Colombia/MDE` },
    { value: `Medford (OR)/USA/MFR`, label: `Medford (OR)/USA/MFR` },
    { value: `Medina/Saudi Arabia/MED`, label: `Medina/Saudi Arabia/MED` },
    { value: `Meekatharra/Australia/MKR`, label: `Meekatharra/Australia/MKR` },
    { value: `Melbourne - Melbourne Airport (Tullamarine)/Australia/MEL`, label: `Melbourne - Melbourne Airport (Tullamarine)/Australia/MEL` },
    { value: `Melbourne (FL)/USA/MLB`, label: `Melbourne (FL)/USA/MLB` },
    { value: `Melville Hall/Dominica/DOM`, label: `Melville Hall/Dominica/DOM` },
    { value: `Memphis (TN)/USA/MEM`, label: `Memphis (TN)/USA/MEM` },
    { value: `Mendoza/Argentina/MDZ`, label: `Mendoza/Argentina/MDZ` },
    { value: `Manado/Indonesia/MDC`, label: `Manado/Indonesia/MDC` },
    { value: `Merced (CA)/USA/MCE`, label: `Merced (CA)/USA/MCE` },
    { value: `Merida/Mexico/MID`, label: `Merida/Mexico/MID` },
    { value: `Meridian (MS)/USA/MEI`, label: `Meridian (MS)/USA/MEI` },
    { value: `Merimbula/Australia/MIM`, label: `Merimbula/Australia/MIM` },
    { value: `Messina/South Africa/MEZ`, label: `Messina/South Africa/MEZ` },
    { value: `Metlakatla (AK)/USA/MTM`, label: `Metlakatla (AK)/USA/MTM` },
    { value: `Metz - Frescaty/France/MZM`, label: `Metz - Frescaty/France/MZM` },
    { value: `Metz/Nancy Metz-Nancy-Lorraine/France/ETZ`, label: `Metz/Nancy Metz-Nancy-Lorraine/France/ETZ` },
    { value: `Mexicali/Mexico/MXL`, label: `Mexicali/Mexico/MXL` },
    { value: `Mexico City - Mexico City International Airport/Mexico/MEX`, label: `Mexico City - Mexico City International Airport/Mexico/MEX` },
    { value: `Mexico City - Atizapan/Mexico/AZP`, label: `Mexico City - Atizapan/Mexico/AZP` },
    { value: `Mexico City - Juarez International/Mexico/MEX`, label: `Mexico City - Juarez International/Mexico/MEX` },
    { value: `Mexico City - Santa Lucia/Mexico/NLU`, label: `Mexico City - Santa Lucia/Mexico/NLU` },
    { value: `Mfuwe/Zambia/MFU`, label: `Mfuwe/Zambia/MFU` },
    { value: `Miami (FL)/USA/MIA`, label: `Miami (FL)/USA/MIA` },
    { value: `Mianwali/Pakistan/MWD`, label: `Mianwali/Pakistan/MWD` },
    { value: `Middlemount/Australia/MMM`, label: `Middlemount/Australia/MMM` },
    { value: `Midland/Odessa (TX)/USA/MAF`, label: `Midland/Odessa (TX)/USA/MAF` },
    { value: `Midway Island - Sand Island Field/US Minor Outlying Islands/MDY`, label: `Midway Island - Sand Island Field/US Minor Outlying Islands/MDY` },
    { value: `Mikkeli/Finland/MIK`, label: `Mikkeli/Finland/MIK` },
    { value: `Milan/Italy/MIL`, label: `Milan/Italy/MIL` },
    { value: `Milan - Linate/Italy/LIN`, label: `Milan - Linate/Italy/LIN` },
    { value: `Milan - Malpensa/Italy/MXP`, label: `Milan - Malpensa/Italy/MXP` },
    { value: `Milan - Orio Al Serio/Italy/BGY`, label: `Milan - Orio Al Serio/Italy/BGY` },
    { value: `Mildura/Australia/MQL`, label: `Mildura/Australia/MQL` },
    { value: `Miles City (MT)/USA/MLS`, label: `Miles City (MT)/USA/MLS` },
    { value: `Milford Sound/New Zealand/MFN`, label: `Milford Sound/New Zealand/MFN` },
    { value: `Milwaukee (WI)/USA/MKE`, label: `Milwaukee (WI)/USA/MKE` },
    { value: `Minatitlan/Mexico/MTT`, label: `Minatitlan/Mexico/MTT` },
    { value: `Mineralnye Vody/Russia/MRV`, label: `Mineralnye Vody/Russia/MRV` },
    { value: `Minneapolis - St. Paul International Airport (MN)/USA/MSP`, label: `Minneapolis - St. Paul International Airport (MN)/USA/MSP` },
    { value: `Minot (ND)/USA/MOT`, label: `Minot (ND)/USA/MOT` },
    { value: `Minsk, International/Belarus/MSQ`, label: `Minsk, International/Belarus/MSQ` },
    { value: `Miri/Malaysia/MYY`, label: `Miri/Malaysia/MYY` },
    { value: `Mirpur/Pakistan/QML`, label: `Mirpur/Pakistan/QML` },
    { value: `Missula (MT)/USA/MSO`, label: `Missula (MT)/USA/MSO` },
    { value: `Mitchell (SD)/USA/MHE`, label: `Mitchell (SD)/USA/MHE` },
    { value: `Miyako Jima (Ryuku Islands) - Hirara/Japan/MMY`, label: `Miyako Jima (Ryuku Islands) - Hirara/Japan/MMY` },
    { value: `Miyazaki/Japan/KMI`, label: `Miyazaki/Japan/KMI` },
    { value: `Mkambati/South Africa/MBM`, label: `Mkambati/South Africa/MBM` },
    { value: `Moanda/Gabon/MFF`, label: `Moanda/Gabon/MFF` },
    { value: `Mobile (AL) - Pascagoula (MS)/USA/MOB`, label: `Mobile (AL) - Pascagoula (MS)/USA/MOB` },
    { value: `Modesto (CA)/USA/MOD`, label: `Modesto (CA)/USA/MOD` },
    { value: `Moenjodaro/Pakistan/MJD`, label: `Moenjodaro/Pakistan/MJD` },
    { value: `Mogadishu/Somalia/MGQ`, label: `Mogadishu/Somalia/MGQ` },
    { value: `Mokuti/Namibia/OKU`, label: `Mokuti/Namibia/OKU` },
    { value: `Moline/Quad Cities (IL)/USA/MLI`, label: `Moline/Quad Cities (IL)/USA/MLI` },
    { value: `Mombasa - Moi International/Kenya/MBA`, label: `Mombasa - Moi International/Kenya/MBA` },
    { value: `Monastir/Tunisia/MIR`, label: `Monastir/Tunisia/MIR` },
    { value: `Moncton/Canada/YQM`, label: `Moncton/Canada/YQM` },
    { value: `Monroe (LA)/USA/MLU`, label: `Monroe (LA)/USA/MLU` },
    { value: `Monrovia - Metropolitan Area/Liberia/MLW`, label: `Monrovia - Metropolitan Area/Liberia/MLW` },
    { value: `Monrovia - Roberts International/Liberia/ROB`, label: `Monrovia - Roberts International/Liberia/ROB` },
    { value: `Montego Bay - Sangster International/Jamaica/MBJ`, label: `Montego Bay - Sangster International/Jamaica/MBJ` },
    { value: `Montenegro/Brazil/QGF`, label: `Montenegro/Brazil/QGF` },
    { value: `Monterey (CA)/USA/MRY`, label: `Monterey (CA)/USA/MRY` },
    { value: `Monterrey - Gen. Mariano Escobedo/Mexico/MTY`, label: `Monterrey - Gen. Mariano Escobedo/Mexico/MTY` },
    { value: `Monterrey - Aeropuerto Del Norte/Mexico/NTR`, label: `Monterrey - Aeropuerto Del Norte/Mexico/NTR` },
    { value: `Montevideo - Carrasco International Airport/Uruguay/MVD`, label: `Montevideo - Carrasco International Airport/Uruguay/MVD` },
    { value: `Montgomery (AL) - Montgomery Regional Airport/USA/MGM`, label: `Montgomery (AL) - Montgomery Regional Airport/USA/MGM` },
    { value: `Montpellier - Montpellier–Méditerranée Airport/France/MPL`, label: `Montpellier - Montpellier–Méditerranée Airport/France/MPL` },
    { value: `Montreal/Canada/YMQ`, label: `Montreal/Canada/YMQ` },
    { value: `Montreal - Dorval (Montréal-Trudeau)/Canada/YUL`, label: `Montreal - Dorval (Montréal-Trudeau)/Canada/YUL` },
    { value: `Montreal - Mirabel/Canada/YMX`, label: `Montreal - Mirabel/Canada/YMX` },
    { value: `Montrose/Tellruide (CO)/USA/MTJ`, label: `Montrose/Tellruide (CO)/USA/MTJ` },
    { value: `Moorea/French Polynesia/MOZ`, label: `Moorea/French Polynesia/MOZ` },
    { value: `Moranbah/Australia/MOV`, label: `Moranbah/Australia/MOV` },
    { value: `Moree/Australia/MRZ`, label: `Moree/Australia/MRZ` },
    { value: `Morelia/Mexico/MLM`, label: `Morelia/Mexico/MLM` },
    { value: `Morgantown (WV)/USA/MGW`, label: `Morgantown (WV)/USA/MGW` },
    { value: `Morioka, Hanamaki/Japan/HNA`, label: `Morioka, Hanamaki/Japan/HNA` },
    { value: `Moroni - Prince Said Ibrahim/Comoros (Comores)/HAH`, label: `Moroni - Prince Said Ibrahim/Comoros (Comores)/HAH` },
    { value: `Moruya/Australia/MYA`, label: `Moruya/Australia/MYA` },
    { value: `Moscow - Metropolitan Area/Russia/MOW`, label: `Moscow - Metropolitan Area/Russia/MOW` },
    { value: `Moscow - Domodedovo/Russia/DME`, label: `Moscow - Domodedovo/Russia/DME` },
    { value: `Moscow - Sheremetyevo/Russia/SVO`, label: `Moscow - Sheremetyevo/Russia/SVO` },
    { value: `Moscow - Vnukovo/Russia/VKO`, label: `Moscow - Vnukovo/Russia/VKO` },
    { value: `Moses Lake (WA)/USA/MWH`, label: `Moses Lake (WA)/USA/MWH` },
    { value: `Mossel Bay/South Africa/MZY`, label: `Mossel Bay/South Africa/MZY` },
    { value: `Mostar/Bosnia and Herzegovina/OMO`, label: `Mostar/Bosnia and Herzegovina/OMO` },
    { value: `Mosul/Iraq/OSM`, label: `Mosul/Iraq/OSM` },
    { value: `Mouila/Gabon/MJL`, label: `Mouila/Gabon/MJL` },
    { value: `Moundou/Chad/MQQ`, label: `Moundou/Chad/MQQ` },
    { value: `Mount Cook/New Zealand/GTN`, label: `Mount Cook/New Zealand/GTN` },
    { value: `Mount Gambier/Australia/MGB`, label: `Mount Gambier/Australia/MGB` },
    { value: `Mount Magnet/Australia/MMG`, label: `Mount Magnet/Australia/MMG` },
    { value: `Mt. Isa/Australia/ISA`, label: `Mt. Isa/Australia/ISA` },
    { value: `Mt. McKinley (AK)/USA/MCL`, label: `Mt. McKinley (AK)/USA/MCL` },
    { value: `Muenchen (Munich) - Franz Josef Strauss/Germany/MUC`, label: `Muenchen (Munich) - Franz Josef Strauss/Germany/MUC` },
    { value: `Muenster/Osnabrueck/Germany/FMO`, label: `Muenster/Osnabrueck/Germany/FMO` },
    { value: `Mulhouse/France/MLH`, label: `Mulhouse/France/MLH` },
    { value: `Multan/Pakistan/MUX`, label: `Multan/Pakistan/MUX` },
    { value: `Murcia/Spain/MJV`, label: `Murcia/Spain/MJV` },
    { value: `Murmansk/Russia/MMK`, label: `Murmansk/Russia/MMK` },
    { value: `Mus/Turkey/MSR`, label: `Mus/Turkey/MSR` },
    { value: `Muscat - Seeb/Oman/MCT`, label: `Muscat - Seeb/Oman/MCT` },
    { value: `Muscle Shoals (AL)/USA/MSL`, label: `Muscle Shoals (AL)/USA/MSL` },
    { value: `Muskegon (MI)/USA/MKG`, label: `Muskegon (MI)/USA/MKG` },
    { value: `Muzaffarabad/Pakistan/MFG`, label: `Muzaffarabad/Pakistan/MFG` },
    { value: `Mvengue/Gabon/MVB`, label: `Mvengue/Gabon/MVB` },
    { value: `Mykonos/Greece/JMK`, label: `Mykonos/Greece/JMK` },
    { value: `Myrtle Beach (SC) - Myrtle Beach AFB/USA/MYR`, label: `Myrtle Beach (SC) - Myrtle Beach AFB/USA/MYR` },
    { value: `Myrtle Beach (SC) - Grand Strand Airport/USA/CRE`, label: `Myrtle Beach (SC) - Grand Strand Airport/USA/CRE` },
    { value: `Mysore/India/MYQ`, label: `Mysore/India/MYQ` },
    { value: `Mytilene (Lesbos)/Greece/MJT`, label: `Mytilene (Lesbos)/Greece/MJT` },
    { value: `Mzamba/South Africa/MZF`, label: `Mzamba/South Africa/MZF` },
    { value: `Nadi/Fiji/NAN`, label: `Nadi/Fiji/NAN` },
    { value: `Nagasaki/Japan/NGS`, label: `Nagasaki/Japan/NGS` },
    { value: `Nagoya - Komaki AFB/Japan/NGO`, label: `Nagoya - Komaki AFB/Japan/NGO` },
    { value: `Nagpur/India/NAG`, label: `Nagpur/India/NAG` },
    { value: `Nairobi/Kenya/NBO`, label: `Nairobi/Kenya/NBO` },
    { value: `Nakhichevan/Azerbaijan/NAJ`, label: `Nakhichevan/Azerbaijan/NAJ` },
    { value: `Nakhon Si Thammarat/Thailand/NST`, label: `Nakhon Si Thammarat/Thailand/NST` },
    { value: `Nancy/France/ENC`, label: `Nancy/France/ENC` },
    { value: `Nanisivik/Canada/YSR`, label: `Nanisivik/Canada/YSR` },
    { value: `Nanning/Guangxi, PR China/NNG`, label: `Nanning/Guangxi, PR China/NNG` },
    { value: `Nantes - Nantes Atlantique Airport/France/NTE`, label: `Nantes - Nantes Atlantique Airport/France/NTE` },
    { value: `Nantucket (MA)/USA/ACK`, label: `Nantucket (MA)/USA/ACK` },
    { value: `Naples - Naples Capodichino Airport/Italy/NAP`, label: `Naples - Naples Capodichino Airport/Italy/NAP` },
    { value: `Naples (FL)/USA/APF`, label: `Naples (FL)/USA/APF` },
    { value: `Narrabri/Australia/NAA`, label: `Narrabri/Australia/NAA` },
    { value: `Narrandera/Australia/NRA`, label: `Narrandera/Australia/NRA` },
    { value: `Narsarsuaq/Greenland/UAK`, label: `Narsarsuaq/Greenland/UAK` },
    { value: `Nashville (TN)/USA/BNA`, label: `Nashville (TN)/USA/BNA` },
    { value: `Nassau/Bahamas/NAS`, label: `Nassau/Bahamas/NAS` },
    { value: `Natal - Augusto Severo International Airport/Brazil/NAT`, label: `Natal - Augusto Severo International Airport/Brazil/NAT` },
    { value: `Nausori/"Fiji/Suva"/SUV`, label: `Nausori/"Fiji/Suva"/SUV` },
    { value: `Nawab Shah/Pakistan/WNS`, label: `Nawab Shah/Pakistan/WNS` },
    { value: `Naxos - Naxos Airport/Greece/JNX`, label: `Naxos - Naxos Airport/Greece/JNX` },
    { value: `N'Djamena/Chad/NDJ`, label: `N'Djamena/Chad/NDJ` },
    { value: `N'Dola/Zambia/NLA`, label: `N'Dola/Zambia/NLA` },
    { value: 'Nal Airport/India/BKB', label: 'Nal Airport/India/BKB' },
    { value: `Nelson/New Zealand/NSN`, label: `Nelson/New Zealand/NSN` },
    { value: `Nelspruit/South Africa/NLP`, label: `Nelspruit/South Africa/NLP` },
    { value: `Nelspruit - Kruger Mpumalanga International Airport/South Africa/MQP`, label: `Nelspruit - Kruger Mpumalanga International Airport/South Africa/MQP` },
    { value: `Nevis/St. Kitts and Nevis/NEV`, label: `Nevis/St. Kitts and Nevis/NEV` },
    { value: `New Bern (NC)/USA/EWN`, label: `New Bern (NC)/USA/EWN` },
    { value: `New Haven (CT)/USA/HVN`, label: `New Haven (CT)/USA/HVN` },
    { value: `New Orleans, La/USA/MSY`, label: `New Orleans, La/USA/MSY` },
    { value: `Newquay/United Kingdom/NQY`, label: `Newquay/United Kingdom/NQY` },
    { value: `New Valley - Kharga/Egypt/UVL`, label: `New Valley - Kharga/Egypt/UVL` },
    { value: `New York - John F. Kennedy (NY)/USA/JFK`, label: `New York - John F. Kennedy (NY)/USA/JFK` },
    { value: `New York - LaGuardia (NY)/USA/LGA`, label: `New York - LaGuardia (NY)/USA/LGA` },
    { value: `New York - Newark (NJ)/USA/EWR`, label: `New York - Newark (NJ)/USA/EWR` },
    { value: `New York (NY)/USA/NYC`, label: `New York (NY)/USA/NYC` },
    { value: `Newburgh (NY)/USA/SWF`, label: `Newburgh (NY)/USA/SWF` },
    { value: `Newcastle - Belmont/Australia/BEO`, label: `Newcastle - Belmont/Australia/BEO` },
    { value: `Newcastle - Williamtown/Australia/NTL`, label: `Newcastle - Williamtown/Australia/NTL` },
    { value: `Newcastle/United Kingdom/NCL`, label: `Newcastle/United Kingdom/NCL` },
    { value: `Newcastle/South Africa/NCS`, label: `Newcastle/South Africa/NCS` },
    { value: `Newman/Australia/ZNE`, label: `Newman/Australia/ZNE` },
    { value: `Newport News/Williamsburg (VA)/USA/PHF`, label: `Newport News/Williamsburg (VA)/USA/PHF` },
    { value: `N'Gaoundere/Cameroon/NGE`, label: `N'Gaoundere/Cameroon/NGE` },
    { value: `Niagara Falls International/USA/IAG`, label: `Niagara Falls International/USA/IAG` },
    { value: `Niamey/Niger/NIM`, label: `Niamey/Niger/NIM` },
    { value: `Nice - Cote D'Azur Airport/France/NCE`, label: `Nice - Cote D'Azur Airport/France/NCE` },
    { value: `Nicosia/Cyprus/NIC`, label: `Nicosia/Cyprus/NIC` },
    { value: `Nikolaev/Ukraine/NLV`, label: `Nikolaev/Ukraine/NLV` },
    { value: `Niigata/Japan/KIJ`, label: `Niigata/Japan/KIJ` },
    { value: `Nimes/France/FNI`, label: `Nimes/France/FNI` },
    { value: `Nis/Serbia/INI`, label: `Nis/Serbia/INI` },
    { value: `Nizhny Novgorod - Strigino International Airport/Russia/GOJ`, label: `Nizhny Novgorod - Strigino International Airport/Russia/GOJ` },
    { value: `Nome (AK)/USA/OME`, label: `Nome (AK)/USA/OME` },
    { value: `Noosa/Australia/NSA`, label: `Noosa/Australia/NSA` },
    { value: `Norfolk Island/Australia/NLK`, label: `Norfolk Island/Australia/NLK` },
    { value: `Norfolk/Virginia Beach (VA)/USA/ORF`, label: `Norfolk/Virginia Beach (VA)/USA/ORF` },
    { value: `Norman Wells/Canada/YVQ`, label: `Norman Wells/Canada/YVQ` },
    { value: `Norrkoeping/Sweden/NRK`, label: `Norrkoeping/Sweden/NRK` },
    { value: `North Bend (OR)/USA/OTH`, label: `North Bend (OR)/USA/OTH` },
    { value: `North Eleuthera/Bahamas/ELH`, label: `North Eleuthera/Bahamas/ELH` },
    { value: `Norwich/United Kingdom/NWI`, label: `Norwich/United Kingdom/NWI` },
    { value: `Nottingham - East Midlands/United Kingdom/EMA`, label: `Nottingham - East Midlands/United Kingdom/EMA` },
    { value: `Nouadhibou/Mauritania/NDB`, label: `Nouadhibou/Mauritania/NDB` },
    { value: `Nouakchott/Mauritania/NKC`, label: `Nouakchott/Mauritania/NKC` },
    { value: `Noumea/New Caledonia/NOU`, label: `Noumea/New Caledonia/NOU` },
    { value: `Novi Sad/Serbia/QND`, label: `Novi Sad/Serbia/QND` },
    { value: `Novosibirsk - Tolmachevo Airport/Russia/OVB`, label: `Novosibirsk - Tolmachevo Airport/Russia/OVB` },
    { value: `Nürnberg (Nuremberg)/Germany/NUE`, label: `Nürnberg (Nuremberg)/Germany/NUE` },
    { value: `Nuevo Laredo/Mexico/NLD`, label: `Nuevo Laredo/Mexico/NLD` },
    { value: `Nuku'alofa - Fua'Amotu International/Tonga/TBU`, label: `Nuku'alofa - Fua'Amotu International/Tonga/TBU` },
    { value: `Oakland (CA)/USA/OAK`, label: `Oakland (CA)/USA/OAK` },
    { value: `Oaxaca - Xoxocotlan/Mexico/OAX`, label: `Oaxaca - Xoxocotlan/Mexico/OAX` },
    { value: `Odense/Denmark/ODE`, label: `Odense/Denmark/ODE` },
    { value: `Odessa/Ukraine/ODS`, label: `Odessa/Ukraine/ODS` },
    { value: `Oerebro/Sweden/ORB`, label: `Oerebro/Sweden/ORB` },
    { value: `Ohrid/Macedonia/OHD`, label: `Ohrid/Macedonia/OHD` },
    { value: `Oita/Japan/OIT`, label: `Oita/Japan/OIT` },
    { value: `Okayama/Japan/OKJ`, label: `Okayama/Japan/OKJ` },
    { value: `Okinawa, Ryukyo Island - Naha/Japan/OKA`, label: `Okinawa, Ryukyo Island - Naha/Japan/OKA` },
    { value: `Oklahoma City (OK) - Will Rogers World/USA/OKC`, label: `Oklahoma City (OK) - Will Rogers World/USA/OKC` },
    { value: `Olbia/Italy/OLB`, label: `Olbia/Italy/OLB` },
    { value: `Olympic Dam/Australia/OLP`, label: `Olympic Dam/Australia/OLP` },
    { value: `Omaha (NE)/USA/OMA`, label: `Omaha (NE)/USA/OMA` },
    { value: `Ondangwa/Namibia/OND`, label: `Ondangwa/Namibia/OND` },
    { value: `Ontario (CA)/USA/ONT`, label: `Ontario (CA)/USA/ONT` },
    { value: `Oran (Ouahran)/Algeria/ORN`, label: `Oran (Ouahran)/Algeria/ORN` },
    { value: `Orange/Australia/OAG`, label: `Orange/Australia/OAG` },
    { value: `Orange County (Santa Ana) (CA)/USA/SNA`, label: `Orange County (Santa Ana) (CA)/USA/SNA` },
    { value: `Oranjemund/Namibia/OMD`, label: `Oranjemund/Namibia/OMD` },
    { value: `Oranjestad - Reina Beatrix International/Aruba/AUA`, label: `Oranjestad - Reina Beatrix International/Aruba/AUA` },
    { value: `Orkney - Kirkwall/United Kingdom/KOI`, label: `Orkney - Kirkwall/United Kingdom/KOI` },
    { value: `Orlando Metropolitan Area (FL)/USA/ORL`, label: `Orlando Metropolitan Area (FL)/USA/ORL` },
    { value: `Orlando - International Airport (FL)/USA/MCO`, label: `Orlando - International Airport (FL)/USA/MCO` },
    { value: `Orpheus Island/Australia/ORS`, label: `Orpheus Island/Australia/ORS` },
    { value: `Osaka, Metropolitan Area/Japan/OSA`, label: `Osaka, Metropolitan Area/Japan/OSA` },
    { value: `Osaka - Itami/Japan/ITM`, label: `Osaka - Itami/Japan/ITM` },
    { value: `Osaka - Kansai International Airport/Japan/KIX`, label: `Osaka - Kansai International Airport/Japan/KIX` },
    { value: `Oshkosh (WI)/USA/OSH`, label: `Oshkosh (WI)/USA/OSH` },
    { value: `Osijek/Croatia (Hrvatska)/OSI`, label: `Osijek/Croatia (Hrvatska)/OSI` },
    { value: `Oslo - Oslo Airport, Gardermoen/Norway/OSL`, label: `Oslo - Oslo Airport, Gardermoen/Norway/OSL` },
    { value: `Oslo - Fornebu/Norway/FBU`, label: `Oslo - Fornebu/Norway/FBU` },
    { value: `Oslo - Sandefjord/Norway/TRF`, label: `Oslo - Sandefjord/Norway/TRF` },
    { value: `Ottawa - Hull/Canada/YOW`, label: `Ottawa - Hull/Canada/YOW` },
    { value: `Ouadda/Central African Republic/ODA`, label: `Ouadda/Central African Republic/ODA` },
    { value: `Ouarzazate/Morocco/OZZ`, label: `Ouarzazate/Morocco/OZZ` },
    { value: `Oudtshoorn/South Africa/OUH`, label: `Oudtshoorn/South Africa/OUH` },
    { value: `Ouagadougou/Burkina Faso/OUA`, label: `Ouagadougou/Burkina Faso/OUA` },
    { value: `Oujda/Morocco/OUD`, label: `Oujda/Morocco/OUD` },
    { value: `Oulu/Finland/OUL`, label: `Oulu/Finland/OUL` },
    { value: `Out Skerries (Shetland)/United Kingdom/OUK`, label: `Out Skerries (Shetland)/United Kingdom/OUK` },
    { value: `Oviedo/Spain/OVD`, label: `Oviedo/Spain/OVD` },
    { value: `Owensboro (KY)/USA/OWB`, label: `Owensboro (KY)/USA/OWB` },
    { value: `Oxnard (CA)/USA/OXR`, label: `Oxnard (CA)/USA/OXR` },
    { value: `Oyem/"Gabon/Loyautte"/UVE`, label: `Oyem/"Gabon/Loyautte"/UVE` },
    { value: `Paderborn/Lippstadt/Germany/PAD`, label: `Paderborn/Lippstadt/Germany/PAD` },
    { value: `Paducah (KY)/USA/PAH`, label: `Paducah (KY)/USA/PAH` },
    { value: `Page/Lake Powell (AZ)/USA/PGA`, label: `Page/Lake Powell (AZ)/USA/PGA` },
    { value: `Pago Pago/American Samoa/PPG`, label: `Pago Pago/American Samoa/PPG` },
    { value: `Pakersburg (WV) /Marietta (OH)/USA/PKB`, label: `Pakersburg (WV) /Marietta (OH)/USA/PKB` },
    { value: `Palermo - Punta Raisi/Italy/PMO`, label: `Palermo - Punta Raisi/Italy/PMO` },
    { value: `Palma de Mallorca/Spain/PMI`, label: `Palma de Mallorca/Spain/PMI` },
    { value: `Palmas/Brazil/PMW`, label: `Palmas/Brazil/PMW` },
    { value: `Palmdale/Lancaster (CA)/USA/PMD`, label: `Palmdale/Lancaster (CA)/USA/PMD` },
    { value: `Palmerston North/New Zealand/PMR`, label: `Palmerston North/New Zealand/PMR` },
    { value: `Palm Springs (CA)/USA/PSP`, label: `Palm Springs (CA)/USA/PSP` },
    { value: `Panama City - Tocumen International/Panama/PTY`, label: `Panama City - Tocumen International/Panama/PTY` },
    { value: `Panama City (FL)/USA/PFN`, label: `Panama City (FL)/USA/PFN` },
    { value: `Panjgur/Pakistan/PJG`, label: `Panjgur/Pakistan/PJG` },
    { value: `Pantelleria/Italy/PNL`, label: `Pantelleria/Italy/PNL` },
    { value: `Papeete - Faaa/French Polynesia (Tahiti)/PPT`, label: `Papeete - Faaa/French Polynesia (Tahiti)/PPT` },
    { value: `Paphos/Cyprus/PFO`, label: `Paphos/Cyprus/PFO` },
    { value: `Paraburdoo/Australia/PBO`, label: `Paraburdoo/Australia/PBO` },
    { value: `Paramaribo - Zanderij International/Suriname/PBM`, label: `Paramaribo - Zanderij International/Suriname/PBM` },
    { value: `Paris/France/PAR`, label: `Paris/France/PAR` },
    { value: `Paris - Charles de Gaulle/France/CDG`, label: `Paris - Charles de Gaulle/France/CDG` },
    { value: `Paris - Le Bourget/France/LBG`, label: `Paris - Le Bourget/France/LBG` },
    { value: `Paris - Orly/France/ORY`, label: `Paris - Orly/France/ORY` },
    { value: `Paro/Bhutan/PBH`, label: `Paro/Bhutan/PBH` },
    { value: `Pasco (WA)/USA/PSC`, label: `Pasco (WA)/USA/PSC` },
    { value: `Pasni/Pakistan/PSI`, label: `Pasni/Pakistan/PSI` },
    { value: `Patna/India/PAT`, label: `Patna/India/PAT` },
    { value: `Pattaya/Thailand/PYX`, label: `Pattaya/Thailand/PYX` },
    { value: `Pau/France/PUF`, label: `Pau/France/PUF` },
    { value: `Pellston (MI)/USA/PLN`, label: `Pellston (MI)/USA/PLN` },
    { value: `Penang International/Malaysia/PEN`, label: `Penang International/Malaysia/PEN` },
    { value: `Pendelton (OR)/USA/PDT`, label: `Pendelton (OR)/USA/PDT` },
    { value: `Pensacola (FL)/USA/PNS`, label: `Pensacola (FL)/USA/PNS` },
    { value: `Peoria/Bloomington (IL)/USA/PIA`, label: `Peoria/Bloomington (IL)/USA/PIA` },
    { value: `Pereira/Colombia/PEI`, label: `Pereira/Colombia/PEI` },
    { value: `Perpignan/France/PGF`, label: `Perpignan/France/PGF` },
    { value: `Perth International/Australia/PER`, label: `Perth International/Australia/PER` },
    { value: `Perugia/Italy/PEG`, label: `Perugia/Italy/PEG` },
    { value: `Pescara/Italy/PSR`, label: `Pescara/Italy/PSR` },
    { value: `Peshawar/Pakistan/PEW`, label: `Peshawar/Pakistan/PEW` },
    { value: `Petersburg (AK)/USA/PSG`, label: `Petersburg (AK)/USA/PSG` },
    { value: `Phalaborwa/South Africa/PHW`, label: `Phalaborwa/South Africa/PHW` },
    { value: `Philadelphia (PA) - International/USA/PHL`, label: `Philadelphia (PA) - International/USA/PHL` },
    { value: `Phnom Penh - Pochentong/Cambodia/PNH`, label: `Phnom Penh - Pochentong/Cambodia/PNH` },
    { value: `Phoenix (AZ) - Sky Harbor International/USA/PHX`, label: `Phoenix (AZ) - Sky Harbor International/USA/PHX` },
    { value: `Phuket/Thailand/HKT`, label: `Phuket/Thailand/HKT` },
    { value: `Pierre (SD)/USA/PIR`, label: `Pierre (SD)/USA/PIR` },
    { value: `Pietermaritzburg/South Africa/PZB`, label: `Pietermaritzburg/South Africa/PZB` },
    { value: `Pietersburg/South Africa/PTG`, label: `Pietersburg/South Africa/PTG` },
    { value: `Pilanesberg/Sun City/South Africa/NTY`, label: `Pilanesberg/Sun City/South Africa/NTY` },
    { value: `Pisa - Galileo Galilei/Italy/PSA`, label: `Pisa - Galileo Galilei/Italy/PSA` },
    { value: `Pittsburgh International Airport (PA)/USA/PIT`, label: `Pittsburgh International Airport (PA)/USA/PIT` },
    { value: `Plattsburgh (NY)/USA/PLB`, label: `Plattsburgh (NY)/USA/PLB` },
    { value: `Plettenberg Bay/South Africa/PBZ`, label: `Plettenberg Bay/South Africa/PBZ` },
    { value: `Pocatello (ID)/USA/PIH`, label: `Pocatello (ID)/USA/PIH` },
    { value: `Podgorica/Montenegro/TGD`, label: `Podgorica/Montenegro/TGD` },
    { value: `Pohnpei/Micronesia/PNI`, label: `Pohnpei/Micronesia/PNI` },
    { value: `Pointe a Pitre/Guadeloupe/PTP`, label: `Pointe a Pitre/Guadeloupe/PTP` },
    { value: `Pointe Noire/Congo (ROC)/PNR`, label: `Pointe Noire/Congo (ROC)/PNR` },
    { value: `Poitiers - Biard/France/PIS`, label: `Poitiers - Biard/France/PIS` },
    { value: `Ponce/Puerto Rico/PSE`, label: `Ponce/Puerto Rico/PSE` },
    { value: `Ponta Delgada/Portugal/PDL`, label: `Ponta Delgada/Portugal/PDL` },
    { value: `Pori/Finland/POR`, label: `Pori/Finland/POR` },
    { value: `Port Angeles (WA)/USA/CLM`, label: `Port Angeles (WA)/USA/CLM` },
    { value: `Port au Prince/Haiti/PAP`, label: `Port au Prince/Haiti/PAP` },
    { value: `Port Augusta/Australia/PUG`, label: `Port Augusta/Australia/PUG` },
    { value: `Port Elizabeth/South Africa/PLZ`, label: `Port Elizabeth/South Africa/PLZ` },
    { value: `Port Gentil/Gabon/POG`, label: `Port Gentil/Gabon/POG` },
    { value: `Port Harcourt/Nigeria/PHC`, label: `Port Harcourt/Nigeria/PHC` },
    { value: `Port Hedland/Australia/PHE`, label: `Port Hedland/Australia/PHE` },
    { value: `Portland/Australia/PTJ`, label: `Portland/Australia/PTJ` },
    { value: `Portland (ME)/USA/PWM`, label: `Portland (ME)/USA/PWM` },
    { value: `Portland International (OR)/USA/PDX`, label: `Portland International (OR)/USA/PDX` },
    { value: `Port Lincoln/Australia/PLO`, label: `Port Lincoln/Australia/PLO` },
    { value: `Port Macquarie/Australia/PQQ`, label: `Port Macquarie/Australia/PQQ` },
    { value: `Port Menier, PQ/Canada/YPN`, label: `Port Menier, PQ/Canada/YPN` },
    { value: `Port Moresby - Jackson Field/Papua New Guinea/POM`, label: `Port Moresby - Jackson Field/Papua New Guinea/POM` },
    { value: `Porto/Portugal/OPO`, label: `Porto/Portugal/OPO` },
    { value: `Porto Alegre - Salgado Filho International Airport/Brazil/POA`, label: `Porto Alegre - Salgado Filho International Airport/Brazil/POA` },
    { value: `Port of Spain - Piarco International/Trinidad and Tobago/POS`, label: `Port of Spain - Piarco International/Trinidad and Tobago/POS` },
    { value: `Port Said/Egypt/PSD`, label: `Port Said/Egypt/PSD` },
    { value: `Porto Santo/Portugal/PXO`, label: `Porto Santo/Portugal/PXO` },
    { value: `Porto Velho/Brazil/PVH`, label: `Porto Velho/Brazil/PVH` },
    { value: `Port Vila/Vanuatu/VLI`, label: `Port Vila/Vanuatu/VLI` },
    { value: `Poughkeepsie (NY)/USA/POU`, label: `Poughkeepsie (NY)/USA/POU` },
    { value: `Poznan, Lawica/Poland/POZ`, label: `Poznan, Lawica/Poland/POZ` },
    { value: `Prague - Václav Havel Airport (formerly Ruzyne)/Czech Republic/PRG`, label: `Prague - Václav Havel Airport (formerly Ruzyne)/Czech Republic/PRG` },
    { value: `Praia - Nelson Mandela International Airport/Cape Verde/RAI`, label: `Praia - Nelson Mandela International Airport/Cape Verde/RAI` },
    { value: `Presque Island (ME)/USA/PQI`, label: `Presque Island (ME)/USA/PQI` },
    { value: `Pretoria - Wonderboom Apt./South Africa/PRY`, label: `Pretoria - Wonderboom Apt./South Africa/PRY` },
    { value: `Preveza/Lefkas/Greece/PVK`, label: `Preveza/Lefkas/Greece/PVK` },
    { value: `Prince George/Canada/YXS`, label: `Prince George/Canada/YXS` },
    { value: `Prince Rupert/Digby Island/Canada/YPR`, label: `Prince Rupert/Digby Island/Canada/YPR` },
    { value: `Pristina/Serbia/PRN`, label: `Pristina/Serbia/PRN` },
    { value: `Prosperpine/Australia/PPP`, label: `Prosperpine/Australia/PPP` },
    { value: `Providence (RI)/USA/PVD`, label: `Providence (RI)/USA/PVD` },
    { value: `Prudhoe Bay (AK)/USA/SCC`, label: `Prudhoe Bay (AK)/USA/SCC` },
    { value: `Puebla/Mexico/PBC`, label: `Puebla/Mexico/PBC` },
    { value: `Pueblo (CO)/USA/PUB`, label: `Pueblo (CO)/USA/PUB` },
    { value: `Puerto Escondido/Mexico/PXM`, label: `Puerto Escondido/Mexico/PXM` },
    { value: `Puerto Ordaz/Venezuela/PZO`, label: `Puerto Ordaz/Venezuela/PZO` },
    { value: `Puerto Plata/Dominican Republic/POP`, label: `Puerto Plata/Dominican Republic/POP` },
    { value: `Puerto Vallarta/Mexico/PVR`, label: `Puerto Vallarta/Mexico/PVR` },
    { value: `Pukatawagan/Canada/XPK`, label: `Pukatawagan/Canada/XPK` },
    { value: `Pula/Croatia (Hrvatska)/PUY`, label: `Pula/Croatia (Hrvatska)/PUY` },
    { value: `Pullman (WA)/USA/PUW`, label: `Pullman (WA)/USA/PUW` },
    { value: `Pune/India, Maharashtra/PNQ`, label: `Pune/India, Maharashtra/PNQ` },
    { value: `Punta Arenas - Presidente Carlos Ibáñez del Campo/Chile/PUQ`, label: `Punta Arenas - Presidente Carlos Ibáñez del Campo/Chile/PUQ` },
    { value: `Punta Cana - Punta Cana International/Dominican Republic/PUJ`, label: `Punta Cana - Punta Cana International/Dominican Republic/PUJ` },
    { value: `Pu San (Busan) - Gimhae International Airport/South Korea/PUS`, label: `Pu San (Busan) - Gimhae International Airport/South Korea/PUS` },
    { value: `Pyongyang - Sunan International Airport/North Korea/FNJ`, label: `Pyongyang - Sunan International Airport/North Korea/FNJ` },
    { value: `Quebec - Quebec International/Canada/YQB`, label: `Quebec - Quebec International/Canada/YQB` },
    { value: `Queenstown/Australia/UEE`, label: `Queenstown/Australia/UEE` },
    { value: `Queenstown/New Zealand/ZQN`, label: `Queenstown/New Zealand/ZQN` },
    { value: `Quetta/Pakistan/UET`, label: `Quetta/Pakistan/UET` },
    { value: `Qingdao/Shandong, PR China/TAO`, label: `Qingdao/Shandong, PR China/TAO` },
    { value: `Quimper/France/UIP`, label: `Quimper/France/UIP` },
    { value: `Quincy (IL)/USA/UIN`, label: `Quincy (IL)/USA/UIN` },
    { value: `Quito - Mariscal Sucre International Airport/Ecuador/UIO`, label: `Quito - Mariscal Sucre International Airport/Ecuador/UIO` },
    { value: `Rabat - Rabat-Salé Airport/Morocco/RBA`, label: `Rabat - Rabat-Salé Airport/Morocco/RBA` },
    { value: `Rahim Yar Khan - Shaikh Zayed International Airport/Pakistan/RYK`, label: `Rahim Yar Khan - Shaikh Zayed International Airport/Pakistan/RYK` },
    { value: `Raiatea/French Polynesia/RFP`, label: `Raiatea/French Polynesia/RFP` },
    { value: `Rainbow Lake, AB/Canada/YOP`, label: `Rainbow Lake, AB/Canada/YOP` },
    { value: `Rajkot/India/RAJ`, label: `Rajkot/India/RAJ` },
    { value: `Raleigh/Durham (NC)/USA/RDU`, label: `Raleigh/Durham (NC)/USA/RDU` },
    { value: `Ranchi/India/IXR`, label: `Ranchi/India/IXR` },
    { value: `Rangiroa/French Polynesia/RGI`, label: `Rangiroa/French Polynesia/RGI` },
    { value: `Rangoon (Yangon) - Mingaladon/Myanmar/RGN`, label: `Rangoon (Yangon) - Mingaladon/Myanmar/RGN` },
    { value: `Rapid City (SD)/USA/RAP`, label: `Rapid City (SD)/USA/RAP` },
    { value: `Rarotonga/Cook Island/RAR`, label: `Rarotonga/Cook Island/RAR` },
    { value: `Ras al Khaymah (Ras al Khaimah)/United Arab Emirates/RKT`, label: `Ras al Khaymah (Ras al Khaimah)/United Arab Emirates/RKT` },
    { value: `Rawala Kot/Pakistan/RAZ`, label: `Rawala Kot/Pakistan/RAZ` },
    { value: `Rawalpindi/Pakistan/RWP`, label: `Rawalpindi/Pakistan/RWP` },
    { value: `Reading (PA)/USA/RDG`, label: `Reading (PA)/USA/RDG` },
    { value: `Recife - Guararapes-Gilberto Freyre International/Brazil/REC`, label: `Recife - Guararapes-Gilberto Freyre International/Brazil/REC` },
    { value: `Redding (CA)/USA/RDD`, label: `Redding (CA)/USA/RDD` },
    { value: `Redmond (OR)/USA/RDM`, label: `Redmond (OR)/USA/RDM` },
    { value: `Reggio Calabria/Italy/REG`, label: `Reggio Calabria/Italy/REG` },
    { value: `Regina/Canada/YQR`, label: `Regina/Canada/YQR` },
    { value: `Reina Sofia/"Teneriffa/Spain"/TFS`, label: `Reina Sofia/"Teneriffa/Spain"/TFS` },
    { value: `Rennes/France/RNS`, label: `Rennes/France/RNS` },
    { value: `Reno (NV)/USA/RNO`, label: `Reno (NV)/USA/RNO` },
    { value: `Resolute Bay/Canada/YRB`, label: `Resolute Bay/Canada/YRB` },
    { value: `Reus/Spain/REU`, label: `Reus/Spain/REU` },
    { value: `Reykjavik - Metropolitan Area/Iceland/REK`, label: `Reykjavik - Metropolitan Area/Iceland/REK` },
    { value: `Reykjavik - Keflavik International/Iceland/KEF`, label: `Reykjavik - Keflavik International/Iceland/KEF` },
    { value: `Rhinelander (WI)/USA/RHI`, label: `Rhinelander (WI)/USA/RHI` },
    { value: `Rhodos/Greece/RHO`, label: `Rhodos/Greece/RHO` },
    { value: `Richards Bay/South Africa/RCB`, label: `Richards Bay/South Africa/RCB` },
    { value: `Richmond (VA)/USA/RIC`, label: `Richmond (VA)/USA/RIC` },
    { value: `Riga/Latvia/RIX`, label: `Riga/Latvia/RIX` },
    { value: `Rijeka/Croatia (Hrvatska)/RJK`, label: `Rijeka/Croatia (Hrvatska)/RJK` },
    { value: `Rimini/Italy/RMI`, label: `Rimini/Italy/RMI` },
    { value: `Rio Branco - Plácido de Castro International Airport/Brazil/RBR`, label: `Rio Branco - Plácido de Castro International Airport/Brazil/RBR` },
    { value: `Rio de Janeiro - Galeao/Brazil/GIG`, label: `Rio de Janeiro - Galeao/Brazil/GIG` },
    { value: `Rio de Janeiro - Santos Dumont/Brazil/SDU`, label: `Rio de Janeiro - Santos Dumont/Brazil/SDU` },
    { value: `Rio de Janeiro/Brazil/RIO`, label: `Rio de Janeiro/Brazil/RIO` },
    { value: `Riyadh - King Khaled International/Saudi Arabia/RUH`, label: `Riyadh - King Khaled International/Saudi Arabia/RUH` },
    { value: `Roanne/France/RNE`, label: `Roanne/France/RNE` },
    { value: `Roanoke (VA)/USA/ROA`, label: `Roanoke (VA)/USA/ROA` },
    { value: `Roatan/Honduras/RTB`, label: `Roatan/Honduras/RTB` },
    { value: `Rochester (MN)/USA/RST`, label: `Rochester (MN)/USA/RST` },
    { value: `Rochester (NY)/USA/ROC`, label: `Rochester (NY)/USA/ROC` },
    { value: `Rock Sound/Bahamas/RSD`, label: `Rock Sound/Bahamas/RSD` },
    { value: `Rock Springs (WY)/USA/RKS`, label: `Rock Springs (WY)/USA/RKS` },
    { value: `Rockford (IL)/USA/RFD`, label: `Rockford (IL)/USA/RFD` },
    { value: `Rockhampton/Australia/ROK`, label: `Rockhampton/Australia/ROK` },
    { value: `Rockland (ME)/USA/RKD`, label: `Rockland (ME)/USA/RKD` },
    { value: `Rocky Mount - Wilson (NC)/USA/RWI`, label: `Rocky Mount - Wilson (NC)/USA/RWI` },
    { value: `Rodez/France/RDZ`, label: `Rodez/France/RDZ` },
    { value: `Rodrigues Island/Mauritius/RRG`, label: `Rodrigues Island/Mauritius/RRG` },
    { value: `Roenne/Denmark/RNN`, label: `Roenne/Denmark/RNN` },
    { value: `Rome/Italy/ROM`, label: `Rome/Italy/ROM` },
    { value: `Rome - Ciampino/Italy/CIA`, label: `Rome - Ciampino/Italy/CIA` },
    { value: `Rome - Fuimicino/Italy/FCO`, label: `Rome - Fuimicino/Italy/FCO` },
    { value: `Ronneby/Sweden/RNB`, label: `Ronneby/Sweden/RNB` },
    { value: `Rosario/Argentina/ROS`, label: `Rosario/Argentina/ROS` },
    { value: `Rostov-on-Don - Rostov-on-Don Airport/Russia/RVI`, label: `Rostov-on-Don - Rostov-on-Don Airport/Russia/RVI` },
    { value: `Rostov-on-Don - Platov International Airport/Russia/ROV`, label: `Rostov-on-Don - Platov International Airport/Russia/ROV` },
    { value: `Rotorua/New Zealand/ROT`, label: `Rotorua/New Zealand/ROT` },
    { value: `Rotterdam/Netherlands/RTM`, label: `Rotterdam/Netherlands/RTM` },
    { value: `Rovaniemi/Finland/RVN`, label: `Rovaniemi/Finland/RVN` },
    { value: `Rundu/Namibia/NDU`, label: `Rundu/Namibia/NDU` },
    { value: `Ruse/Bulgaria/ROU`, label: `Ruse/Bulgaria/ROU` },
    { value: `Saarbruecken/Germany/SCN`, label: `Saarbruecken/Germany/SCN` },
    { value: `Sacramento (CA)/USA/SMF`, label: `Sacramento (CA)/USA/SMF` },
    { value: `Sado Shima/Japan/SDS`, label: `Sado Shima/Japan/SDS` },
    { value: `Saginaw/Bay City/Midland (MI)/USA/MBS`, label: `Saginaw/Bay City/Midland (MI)/USA/MBS` },
    { value: `Saidu Sharif/Pakistan/SDT`, label: `Saidu Sharif/Pakistan/SDT` },
    { value: `Saigon (Ho Chi Minh City) - Tan Son Nhat International/Viet Nam/SGN`, label: `Saigon (Ho Chi Minh City) - Tan Son Nhat International/Viet Nam/SGN` },
    { value: `Saint Brieuc/France/SBK`, label: `Saint Brieuc/France/SBK` },
    { value: `Saint Denis - Roland Garros Airport/Reunion/RUN`, label: `Saint Denis - Roland Garros Airport/Reunion/RUN` },
    { value: `Saint John/Canada/YSJ`, label: `Saint John/Canada/YSJ` },
    { value: `Saipan/Northern Mariana Islands/SPN`, label: `Saipan/Northern Mariana Islands/SPN` },
    { value: `Sal/Cape Verde/SID`, label: `Sal/Cape Verde/SID` },
    { value: `Salalah/Oman/SLL`, label: `Salalah/Oman/SLL` },
    { value: `Salem (OR)/USA/SLE`, label: `Salem (OR)/USA/SLE` },
    { value: `Salinas (CA)/USA/SNS`, label: `Salinas (CA)/USA/SNS` },
    { value: `Salinas/Ecuador/SNC`, label: `Salinas/Ecuador/SNC` },
    { value: `Salisbury/Zimbabwe/SAY`, label: `Salisbury/Zimbabwe/SAY` },
    { value: `Salisbury (MD)/USA/SBY`, label: `Salisbury (MD)/USA/SBY` },
    { value: `Saloniki/Greece/SKG`, label: `Saloniki/Greece/SKG` },
    { value: `Salta, Gen Belgrano/Argentina/SLA`, label: `Salta, Gen Belgrano/Argentina/SLA` },
    { value: `Salt Lake City (UT)/USA/SLC`, label: `Salt Lake City (UT)/USA/SLC` },
    { value: `Salvador - Salvador International Airport/Brazil/SSA`, label: `Salvador - Salvador International Airport/Brazil/SSA` },
    { value: `Salzburg - W.A. Mozart/Austria/SZG`, label: `Salzburg - W.A. Mozart/Austria/SZG` },
    { value: `Samara - Kurumoch International Airport/Russia/KUF`, label: `Samara - Kurumoch International Airport/Russia/KUF` },
    { value: `Samarkand - Samarkand International Airport/Uzbekistan/SKD`, label: `Samarkand - Samarkand International Airport/Uzbekistan/SKD` },
    { value: `Samos/Greece/SMI`, label: `Samos/Greece/SMI` },
    { value: `Samsun/Turkey/SZF`, label: `Samsun/Turkey/SZF` },
    { value: `San Andres/Colombia/ADZ`, label: `San Andres/Colombia/ADZ` },
    { value: `San Angelo (TX)/USA/SJT`, label: `San Angelo (TX)/USA/SJT` },
    { value: `San Antonio (TX)/USA/SAT`, label: `San Antonio (TX)/USA/SAT` },
    { value: `San Carlos de Bariloche/Argentina/BRC`, label: `San Carlos de Bariloche/Argentina/BRC` },
    { value: `San Diego - Lindberg Field International (CA)/USA/SAN`, label: `San Diego - Lindberg Field International (CA)/USA/SAN` },
    { value: `San Francisco - International Airport, SA/USA/SFO`, label: `San Francisco - International Airport, SA/USA/SFO` },
    { value: `San Jose Cabo/Mexico/SJD`, label: `San Jose Cabo/Mexico/SJD` },
    { value: `San Jose/Costa Rica/SJO`, label: `San Jose/Costa Rica/SJO` },
    { value: `San Jose (CA)/USA/SJC`, label: `San Jose (CA)/USA/SJC` },
    { value: `San Juan - Luis Munoz Marin International Airport/Puerto Rico/SJU`, label: `San Juan - Luis Munoz Marin International Airport/Puerto Rico/SJU` },
    { value: `San Luis Obisco (CA)/USA/SBP`, label: `San Luis Obisco (CA)/USA/SBP` },
    { value: `San Luis Potosi/Mexico/SLP`, label: `San Luis Potosi/Mexico/SLP` },
    { value: `San Pedro/Cote d'Ivoire/SPY`, label: `San Pedro/Cote d'Ivoire/SPY` },
    { value: `San Pedro Sula/Honduras/SAP`, label: `San Pedro Sula/Honduras/SAP` },
    { value: `San Salvador/Bahamas/ZSA`, label: `San Salvador/Bahamas/ZSA` },
    { value: `San Salvador/El Salvador/SAL`, label: `San Salvador/El Salvador/SAL` },
    { value: `San Sebastian/Spain/EAS`, label: `San Sebastian/Spain/EAS` },
    { value: `Sanaa (Sana'a) - Sana'a International/Yemen/SAH`, label: `Sanaa (Sana'a) - Sana'a International/Yemen/SAH` },
    { value: `Sandspit/Canada/YZP`, label: `Sandspit/Canada/YZP` },
    { value: `Santa Ana - John Wayne Airport (CA)/USA/SNA`, label: `Santa Ana - John Wayne Airport (CA)/USA/SNA` },
    { value: `Santa Barbara (CA)/USA/SBA`, label: `Santa Barbara (CA)/USA/SBA` },
    { value: `Santa Cruz de la Palma/Spain/SPC`, label: `Santa Cruz de la Palma/Spain/SPC` },
    { value: `Santa Cruz de la Sierra/Bolivia/SRZ`, label: `Santa Cruz de la Sierra/Bolivia/SRZ` },
    { value: `Santa Katarina - Mount Sinai/Egypt/SKV`, label: `Santa Katarina - Mount Sinai/Egypt/SKV` },
    { value: `Santa Maria/Portugal/SMA`, label: `Santa Maria/Portugal/SMA` },
    { value: `Santa Maria (CA)/USA/SMX`, label: `Santa Maria (CA)/USA/SMX` },
    { value: `Santander/Spain/SDR`, label: `Santander/Spain/SDR` },
    { value: `Santa Rosa (CA)/USA/STS`, label: `Santa Rosa (CA)/USA/STS` },
    { value: `Santa Rosa/Bolivia/SRB`, label: `Santa Rosa/Bolivia/SRB` },
    { value: `Santa Rosa/Brazil/SRA`, label: `Santa Rosa/Brazil/SRA` },
    { value: `Santa Rosa/Argentina/RSA`, label: `Santa Rosa/Argentina/RSA` },
    { value: `Santa Rosa, Copan/Honduras/SDH`, label: `Santa Rosa, Copan/Honduras/SDH` },
    { value: `Santa Rosalia/Colombia/SSL`, label: `Santa Rosalia/Colombia/SSL` },
    { value: `Santa Rosalia/Mexico/SRL`, label: `Santa Rosalia/Mexico/SRL` },
    { value: `Santiago - Antonio Maceo Airport/Cuba/SCU`, label: `Santiago - Antonio Maceo Airport/Cuba/SCU` },
    { value: `Santiago de Chile - Arturo Merino Benitez/Chile/SCL`, label: `Santiago de Chile - Arturo Merino Benitez/Chile/SCL` },
    { value: `Santiago de Compostela/Spain/SCQ`, label: `Santiago de Compostela/Spain/SCQ` },
    { value: `Santo/Vanuatu/SON`, label: `Santo/Vanuatu/SON` },
    { value: `Santo Domingo/Dominican Republic/SDQ`, label: `Santo Domingo/Dominican Republic/SDQ` },
    { value: `Sao Luis - Marechal Cunha Machado International/Brazil/SLZ`, label: `Sao Luis - Marechal Cunha Machado International/Brazil/SLZ` },
    { value: `Sao Paulo/Brazil/SAO`, label: `Sao Paulo/Brazil/SAO` },
    { value: `Sao Paulo - Congonhas/Brazil/CGH`, label: `Sao Paulo - Congonhas/Brazil/CGH` },
    { value: `Sao Paulo - Guarulhos/Brazil/GRU`, label: `Sao Paulo - Guarulhos/Brazil/GRU` },
    { value: `Sao Paulo - Viracopos/Brazil/VCP`, label: `Sao Paulo - Viracopos/Brazil/VCP` },
    { value: `Sao Tome/Sao Tome & Principe/TMS`, label: `Sao Tome/Sao Tome & Principe/TMS` },
    { value: `Sapporo/Hokkaido, Japan/SPK`, label: `Sapporo/Hokkaido, Japan/SPK` },
    { value: `Sapporo - Okadama/Hokkaido, Japan/OKD`, label: `Sapporo - Okadama/Hokkaido, Japan/OKD` },
    { value: `Sapporo - New Chitose Airport/Japan/CTS`, label: `Sapporo - New Chitose Airport/Japan/CTS` },
    { value: `Sarajevo/Bosnia and Herzegovina/SJJ`, label: `Sarajevo/Bosnia and Herzegovina/SJJ` },
    { value: `Saransk - Saransk Airport/Russia/SKX`, label: `Saransk - Saransk Airport/Russia/SKX` },
    { value: `Sarasota/Bradenton (FL)/USA/SRQ`, label: `Sarasota/Bradenton (FL)/USA/SRQ` },
    { value: `Saskatoon/Canada/YXE`, label: `Saskatoon/Canada/YXE` },
    { value: `Sassandra/Cote d'Ivoire/ZSS`, label: `Sassandra/Cote d'Ivoire/ZSS` },
    { value: `Savannah (GA)/USA/SAV`, label: `Savannah (GA)/USA/SAV` },
    { value: `Savonlinna/Finland/SVL`, label: `Savonlinna/Finland/SVL` },
    { value: `Scarborough - Crown Point International/Trinidad and Tobago/TAB`, label: `Scarborough - Crown Point International/Trinidad and Tobago/TAB` },
    { value: `Scone/Australia/NSO`, label: `Scone/Australia/NSO` },
    { value: `Scottsdale (AZ)/USA/SCF`, label: `Scottsdale (AZ)/USA/SCF` },
    { value: `Seattle/Tacoma (WA)/USA/SEA`, label: `Seattle/Tacoma (WA)/USA/SEA` },
    { value: `Sehba/Libya/SEB`, label: `Sehba/Libya/SEB` },
    { value: `Seinaejoki/Finland/SJY`, label: `Seinaejoki/Finland/SJY` },
    { value: `Selibi Phikwe/Botswana/PKW`, label: `Selibi Phikwe/Botswana/PKW` },
    { value: `Sendai/Japan/SDJ`, label: `Sendai/Japan/SDJ` },
    { value: `Seoul - Incheon International Airport/South Korea/ICN`, label: `Seoul - Incheon International Airport/South Korea/ICN` },
    { value: `Seoul - Kimpo/South Korea/SEL`, label: `Seoul - Kimpo/South Korea/SEL` },
    { value: `Sevilla/Spain/SVQ`, label: `Sevilla/Spain/SVQ` },
    { value: `Sfax/Tunisia/SFA`, label: `Sfax/Tunisia/SFA` },
    { value: `Shamattawa, MB/Canada/ZTM`, label: `Shamattawa, MB/Canada/ZTM` },
    { value: `Shanghai - Hongqiao/China/SHA`, label: `Shanghai - Hongqiao/China/SHA` },
    { value: `Shanghai - Pu Dong/China/PVG`, label: `Shanghai - Pu Dong/China/PVG` },
    { value: `Shannon (Limerick)/Ireland/SNN`, label: `Shannon (Limerick)/Ireland/SNN` },
    { value: `Sharjah/United Arab Emirates/SHJ`, label: `Sharjah/United Arab Emirates/SHJ` },
    { value: `Sharm El Sheikh/Egypt/SSH`, label: `Sharm El Sheikh/Egypt/SSH` },
    { value: `Sheffield, City Airport/United Kingdom/SZD`, label: `Sheffield, City Airport/United Kingdom/SZD` },
    { value: `Sheffield, Doncaster, Robin Hood International Airport/United Kingdom/DSA`, label: `Sheffield, Doncaster, Robin Hood International Airport/United Kingdom/DSA` },
    { value: `Shenandoah Valley/Stauton (VA)/USA/SHD`, label: `Shenandoah Valley/Stauton (VA)/USA/SHD` },
    { value: `Shenyang/Liaoning, PR China/SHE`, label: `Shenyang/Liaoning, PR China/SHE` },
    { value: `Shenzhen - Shenzhen Bao'an International/Guangdong, PR China/SZX`, label: `Shenzhen - Shenzhen Bao'an International/Guangdong, PR China/SZX` },
    { value: `Sheridan (WY)/USA/SHR`, label: `Sheridan (WY)/USA/SHR` },
    { value: `Shreveport, La/USA/SHV`, label: `Shreveport, La/USA/SHV` },
    { value: `Shute Harbour/Australia/JHQ`, label: `Shute Harbour/Australia/JHQ` },
    { value: `Sibu/Malaysia/SBW`, label: `Sibu/Malaysia/SBW` },
    { value: `Sidney (MT)/USA/SDY`, label: `Sidney (MT)/USA/SDY` },
    { value: `Silistra/Bulgaria/SLS`, label: `Silistra/Bulgaria/SLS` },
    { value: `Simferopol/Ukraine/SIP`, label: `Simferopol/Ukraine/SIP` },
    { value: `Sindhri/Pakistan/MPD`, label: `Sindhri/Pakistan/MPD` },
    { value: `Singapore - Changi/Singapore/SIN`, label: `Singapore - Changi/Singapore/SIN` },
    { value: `Singapore - Paya Lebar/Singapore/QPG`, label: `Singapore - Paya Lebar/Singapore/QPG` },
    { value: `Singapore - Seletar/Singapore/XSP`, label: `Singapore - Seletar/Singapore/XSP` },
    { value: `Singleton/Australia/SIX`, label: `Singleton/Australia/SIX` },
    { value: `Sioux City IA/USA/SUX`, label: `Sioux City IA/USA/SUX` },
    { value: `Sioux Falls (SD)/USA/FSD`, label: `Sioux Falls (SD)/USA/FSD` },
    { value: `Sishen/South Africa/SIS`, label: `Sishen/South Africa/SIS` },
    { value: `Sitka (AK)/USA/SIT`, label: `Sitka (AK)/USA/SIT` },
    { value: `Sivas/Turkey/VAS`, label: `Sivas/Turkey/VAS` },
    { value: `Siwa/Egypt/SEW`, label: `Siwa/Egypt/SEW` },
    { value: `Skagway (AK)/USA/SGY`, label: `Skagway (AK)/USA/SGY` },
    { value: `Skardu/Pakistan/KDU`, label: `Skardu/Pakistan/KDU` },
    { value: `Skiathos/Greece/JSI`, label: `Skiathos/Greece/JSI` },
    { value: `Skopje/Macedonia/SKP`, label: `Skopje/Macedonia/SKP` },
    { value: `Skrydstrup/Denmark/SKS`, label: `Skrydstrup/Denmark/SKS` },
    { value: `Skukuza/South Africa/SZK`, label: `Skukuza/South Africa/SZK` },
    { value: `Sligo/Ireland/SXL`, label: `Sligo/Ireland/SXL` },
    { value: `Smithers/Canada/YYD`, label: `Smithers/Canada/YYD` },
    { value: `Sodankylae/Finland/SOT`, label: `Sodankylae/Finland/SOT` },
    { value: `Soenderborg/Denmark/SGD`, label: `Soenderborg/Denmark/SGD` },
    { value: `Soendre Stroemfjord/Greenland/SFJ`, label: `Soendre Stroemfjord/Greenland/SFJ` },
    { value: `Sofia - Vrazhdebna/Bulgaria/SOF`, label: `Sofia - Vrazhdebna/Bulgaria/SOF` },
    { value: `Sogndal/Norway/SOG`, label: `Sogndal/Norway/SOG` },
    { value: `Southampton - Eastleigh/United Kingdom/SOU`, label: `Southampton - Eastleigh/United Kingdom/SOU` },
    { value: `South Bend (IN)/USA/SBN`, label: `South Bend (IN)/USA/SBN` },
    { value: `South Indian Lake, MB/Canada/XSI`, label: `South Indian Lake, MB/Canada/XSI` },
    { value: `South Molle Island/Australia/SOI`, label: `South Molle Island/Australia/SOI` },
    { value: `Southend (London)/United Kingdom/SEN`, label: `Southend (London)/United Kingdom/SEN` },
    { value: `Split/Croatia (Hrvatska)/SPU`, label: `Split/Croatia (Hrvatska)/SPU` },
    { value: `Spokane (WA)/USA/GEG`, label: `Spokane (WA)/USA/GEG` },
    { value: `Springbok/South Africa/SBU`, label: `Springbok/South Africa/SBU` },
    { value: `Springfield (IL)/USA/SPI`, label: `Springfield (IL)/USA/SPI` },
    { value: `Springfield (MO)/USA/SGF`, label: `Springfield (MO)/USA/SGF` },
    { value: `Srinagar/India/SXR`, label: `Srinagar/India/SXR` },
    { value: `St. Augustin, PQ/Canada/YIF`, label: `St. Augustin, PQ/Canada/YIF` },
    { value: `St. Croix/Virgin Islands (U.S.)/STX`, label: `St. Croix/Virgin Islands (U.S.)/STX` },
    { value: `St. Etienne/France/EBU`, label: `St. Etienne/France/EBU` },
    { value: `St. George (UT)/USA/SGU`, label: `St. George (UT)/USA/SGU` },
    { value: `St. John's/Canada/YYT`, label: `St. John's/Canada/YYT` },
    { value: `St. Kitts/St. Kitts and Nevis/SKB`, label: `St. Kitts/St. Kitts and Nevis/SKB` },
    { value: `St. Louis (MO) Lambert–St. Louis International Airport/USA/STL`, label: `St. Louis (MO) Lambert–St. Louis International Airport/USA/STL` },
    { value: `St. Lucia Hewanorra/Saint Lucia/UVF`, label: `St. Lucia Hewanorra/Saint Lucia/UVF` },
    { value: `St. Lucia Vigle/Saint Lucia/SLU`, label: `St. Lucia Vigle/Saint Lucia/SLU` },
    { value: `St. Marteen/Netherlands Antilles/SXM`, label: `St. Marteen/Netherlands Antilles/SXM` },
    { value: `St. Martin/St. Martin (Guadeloupe)/SFG`, label: `St. Martin/St. Martin (Guadeloupe)/SFG` },
    { value: `St. Petersburg (Leningrad) - Pulkovo/Russia/LED`, label: `St. Petersburg (Leningrad) - Pulkovo/Russia/LED` },
    { value: `St. Pierre, NF/Canada/FSP`, label: `St. Pierre, NF/Canada/FSP` },
    { value: `St. Thomas/Virgin Islands (U.S.)/STT`, label: `St. Thomas/Virgin Islands (U.S.)/STT` },
    { value: `St. Vincent/Saint Vincent and the Grenadines/SVD`, label: `St. Vincent/Saint Vincent and the Grenadines/SVD` },
    { value: `Stansted (London)/United Kingdom/STN`, label: `Stansted (London)/United Kingdom/STN` },
    { value: `State College/Belefonte (PA)/USA/SCE`, label: `State College/Belefonte (PA)/USA/SCE` },
    { value: `Stavanger/Norway/SVG`, label: `Stavanger/Norway/SVG` },
    { value: `Steamboat Springs (CO)/USA/HDN`, label: `Steamboat Springs (CO)/USA/HDN` },
    { value: `Stettin/Poland/SZZ`, label: `Stettin/Poland/SZZ` },
    { value: `Stockholm Metropolitan Area/Sweden/STO`, label: `Stockholm Metropolitan Area/Sweden/STO` },
    { value: `Stockholm - Arlanda/Sweden/ARN`, label: `Stockholm - Arlanda/Sweden/ARN` },
    { value: `Stockholm - Bromma/Sweden/BMA`, label: `Stockholm - Bromma/Sweden/BMA` },
    { value: `Stockton (CA)/USA/SCK`, label: `Stockton (CA)/USA/SCK` },
    { value: `Stornway/United Kingdom/SYY`, label: `Stornway/United Kingdom/SYY` },
    { value: `Strasbourg - Strasbourg Airport/France/SXB`, label: `Strasbourg - Strasbourg Airport/France/SXB` },
    { value: `Streaky Bay/Australia/KBY`, label: `Streaky Bay/Australia/KBY` },
    { value: `Stuttgart - Echterdingen/Germany/STR`, label: `Stuttgart - Echterdingen/Germany/STR` },
    { value: `Sui/Pakistan/SUL`, label: `Sui/Pakistan/SUL` },
    { value: `Sukkur/Pakistan/SKZ`, label: `Sukkur/Pakistan/SKZ` },
    { value: `Sumburgh (Shetland)/United Kingdom/LSI`, label: `Sumburgh (Shetland)/United Kingdom/LSI` },
    { value: `Sun Valley (ID)/USA/SUN`, label: `Sun Valley (ID)/USA/SUN` },
    { value: `Sundsvall/Sweden/SDL`, label: `Sundsvall/Sweden/SDL` },
    { value: `Sunshine Coast/Australia/MCY`, label: `Sunshine Coast/Australia/MCY` },
    { value: `Surabaya - Juanda/Indonesia/SUB`, label: `Surabaya - Juanda/Indonesia/SUB` },
    { value: `Surat/India/STV`, label: `Surat/India/STV` },
    { value: `Suva - Nausori Airport (Luvuluvu)/Fiji/SUV`, label: `Suva - Nausori Airport (Luvuluvu)/Fiji/SUV` },
    { value: `Swakopmund/Namibia/SWP`, label: `Swakopmund/Namibia/SWP` },
    { value: `Sydney - Sydney Airport/Australia/SYD`, label: `Sydney - Sydney Airport/Australia/SYD` },
    { value: `Sylhet/Bangladesh/ZYL`, label: `Sylhet/Bangladesh/ZYL` },
    { value: `Syracuse (NY)/USA/SYR`, label: `Syracuse (NY)/USA/SYR` },
    { value: `Tabuk/Saudi Arabia/TUU`, label: `Tabuk/Saudi Arabia/TUU` },
    { value: `Taif/Saudi Arabia/TIF`, label: `Taif/Saudi Arabia/TIF` },
    { value: `Taipei - Chiang Kai Shek/Taiwan/TPE`, label: `Taipei - Chiang Kai Shek/Taiwan/TPE` },
    { value: `Taipei - Sung Shan/Taiwan/TAY`, label: `Taipei - Sung Shan/Taiwan/TAY` },
    { value: `Taiyuan/Shanxi, PR China/TYN`, label: `Taiyuan/Shanxi, PR China/TYN` },
    { value: `Takamatsu/Japan/TAK`, label: `Takamatsu/Japan/TAK` },
    { value: `Talkeetna (AK)/USA/TKA`, label: `Talkeetna (AK)/USA/TKA` },
    { value: `Tallahassee (FL)/USA/TLH`, label: `Tallahassee (FL)/USA/TLH` },
    { value: `Tallinn - Pirita Harbour/Estonia/QUF`, label: `Tallinn - Pirita Harbour/Estonia/QUF` },
    { value: `Tallinn - Ulemiste/Estonia/TLL`, label: `Tallinn - Ulemiste/Estonia/TLL` },
    { value: `Tampa - International (FL)/USA/TPA`, label: `Tampa - International (FL)/USA/TPA` },
    { value: `Tampere/Finland/TMP`, label: `Tampere/Finland/TMP` },
    { value: `Tampico - Gen. F. Javier Mina/Mexico/TAM`, label: `Tampico - Gen. F. Javier Mina/Mexico/TAM` },
    { value: `Tamworth/Australia/TMW`, label: `Tamworth/Australia/TMW` },
    { value: `Tangier - Boukhalef/Morocco/TNG`, label: `Tangier - Boukhalef/Morocco/TNG` },
    { value: `Taree/Australia/TRO`, label: `Taree/Australia/TRO` },
    { value: `Targovishte/Bulgaria/TGV`, label: `Targovishte/Bulgaria/TGV` },
    { value: `Tashkent - International/Uzbekistan/TAS`, label: `Tashkent - International/Uzbekistan/TAS` },
    { value: `Tawau/Malaysia/TWU`, label: `Tawau/Malaysia/TWU` },
    { value: `Vijayawada/India/VGA`, label: `/India/VGA` },
    { value: `Madurai/India/IXM`, label: `Madurai/India/IXM` },
    { value: `Vishakhapatnam/India/VTZ`, label: `Vishakhapatnam/India/VTZ` },

    { value: `Tbilisi - Novo Alexeyevka/Georgia/TBS`, label: `Tbilisi - Novo Alexeyevka/Georgia/TBS` },
    { value: `Te Anau/New Zealand/TEU`, label: `Te Anau/New Zealand/TEU` },
    { value: `Teesside, Durham Tees Valley/United Kingdom/MME`, label: `Teesside, Durham Tees Valley/United Kingdom/MME` },
    { value: `Tegucigalpa/Honduras/TGU`, label: `Tegucigalpa/Honduras/TGU` },
    { value: `Tehran (Teheran) - Mehrabad/Iran/THR`, label: `Tehran (Teheran) - Mehrabad/Iran/THR` },
    { value: `Tekirdag - Corlu/Turkey/TEQ`, label: `Tekirdag - Corlu/Turkey/TEQ` },
    { value: `Tel Aviv - Ben Gurion International/Israel/TLV`, label: `Tel Aviv - Ben Gurion International/Israel/TLV` },
    { value: `Telluride (CO)/USA/TEX`, label: `Telluride (CO)/USA/TEX` },
    { value: `Temora/Australia/TEM`, label: `Temora/Australia/TEM` },
    { value: `Tenerife/Spain/TCI`, label: `Tenerife/Spain/TCI` },
    { value: `Tenerife - Sur Reina Sofia/Spain/TFS`, label: `Tenerife - Sur Reina Sofia/Spain/TFS` },
    { value: `Tenerife - Norte Los Rodeos/Spain/TFN`, label: `Tenerife - Norte Los Rodeos/Spain/TFN` },
    { value: `Tennant Creek/Australia/TCA`, label: `Tennant Creek/Australia/TCA` },
    { value: `Terceira/Portugal/TER`, label: `Terceira/Portugal/TER` },
    { value: `Teresina/Brazil/THE`, label: `Teresina/Brazil/THE` },
    { value: `Termez (Termes)/Uzbekistan/TMZ`, label: `Termez (Termes)/Uzbekistan/TMZ` },
    { value: `Terrace/Canada/YXT`, label: `Terrace/Canada/YXT` },
    { value: `Terre Haute (IN)/USA/HUF`, label: `Terre Haute (IN)/USA/HUF` },
    { value: `Texarkana (AR)/USA/TXK`, label: `Texarkana (AR)/USA/TXK` },
    { value: `Thaba'Nchu/South Africa/TCU`, label: `Thaba'Nchu/South Africa/TCU` },
    { value: `The Pas/Canada/YQD`, label: `The Pas/Canada/YQD` },
    { value: `Thessaloniki - Makedonia Apt./Greece/SKG`, label: `Thessaloniki - Makedonia Apt./Greece/SKG` },
    { value: `Thief River Falls (MN)/USA/TVF`, label: `Thief River Falls (MN)/USA/TVF` },
    { value: `Thira/Greece/JTR`, label: `Thira/Greece/JTR` },
    { value: `Thiruvananthapuram/India/TRV`, label: `Thiruvananthapuram/India/TRV` },
    { value: `Thisted/Denmark/TED`, label: `Thisted/Denmark/TED` },
    { value: `Thompson/Canada/YTH`, label: `Thompson/Canada/YTH` },
    { value: `Thorne Bay (AK)/USA/KTB`, label: `Thorne Bay (AK)/USA/KTB` },
    { value: `Thunder Bay/Canada/YQT`, label: `Thunder Bay/Canada/YQT` },
    { value: `Thursday Island/Australia/TIS`, label: `Thursday Island/Australia/TIS` },
    { value: `Tianjin/China/TSN`, label: `Tianjin/China/TSN` },
    { value: `Tijuana - Rodriguez/Mexico/TIJ`, label: `Tijuana - Rodriguez/Mexico/TIJ` },
    { value: `Tioman/Indonesia/TOD`, label: `Tioman/Indonesia/TOD` },
    { value: `Tirana - Rinas/Albania/TIA`, label: `Tirana - Rinas/Albania/TIA` },
    { value: `Tiruchirapally/India/TRZ`, label: `Tiruchirapally/India/TRZ` },
    { value: `Tivat/Montenegro/TIV`, label: `Tivat/Montenegro/TIV` },
    { value: `Tobago/Trinidad and Tobago/TAB`, label: `Tobago/Trinidad and Tobago/TAB` },
    { value: `Tokushima/Japan/TKS`, label: `Tokushima/Japan/TKS` },
    { value: `Tokyo/Japan/TYO`, label: `Tokyo/Japan/TYO` },
    { value: `Tokyo - Haneda/Japan/HND`, label: `Tokyo - Haneda/Japan/HND` },
    { value: `Tokyo - Narita/Japan/NRT`, label: `Tokyo - Narita/Japan/NRT` },
    { value: `Toledo (OH)/USA/TOL`, label: `Toledo (OH)/USA/TOL` },
    { value: `Tom Price/Australia/TPR`, label: `Tom Price/Australia/TPR` },
    { value: `Toowoomba/Australia/TWB`, label: `Toowoomba/Australia/TWB` },
    { value: `Toronto - Billy Bishop Toronto City Airport/Canada/YTZ`, label: `Toronto - Billy Bishop Toronto City Airport/Canada/YTZ` },
    { value: `Toronto - Toronto Pearson International Airport/Canada/YYZ`, label: `Toronto - Toronto Pearson International Airport/Canada/YYZ` },
    { value: `Toronto/Canada/YTO`, label: `Toronto/Canada/YTO` },
    { value: `Tortola/British Virgin Islands/TOV`, label: `Tortola/British Virgin Islands/TOV` },
    { value: `Touho/New Caledonia/TOU`, label: `Touho/New Caledonia/TOU` },
    { value: `Toulouse - Blagnac Airport/France/TLS`, label: `Toulouse - Blagnac Airport/France/TLS` },
    { value: `Townsville/Australia/TSV`, label: `Townsville/Australia/TSV` },
    { value: `Toyama/Japan/TOY`, label: `Toyama/Japan/TOY` },
    { value: `Trabzon/Turkey/TZX`, label: `Trabzon/Turkey/TZX` },
    { value: `Trapani/Italy/TPS`, label: `Trapani/Italy/TPS` },
    { value: `Traverse City (MI)/USA/TVC`, label: `Traverse City (MI)/USA/TVC` },
    { value: `Treasure Cay/Bahamas/TCB`, label: `Treasure Cay/Bahamas/TCB` },
    { value: `Trenton/Princeton (NJ)/USA/TTN`, label: `Trenton/Princeton (NJ)/USA/TTN` },
    { value: `Treviso/Italy/TSF`, label: `Treviso/Italy/TSF` },
    { value: `Tri-Cities Regional (TN) /VA/USA/TRI`, label: `Tri-Cities Regional (TN) /VA/USA/TRI` },
    { value: `Trieste/Italy/TRS`, label: `Trieste/Italy/TRS` },
    { value: `Tripoli - Tripoli International/Libya/TIP`, label: `Tripoli - Tripoli International/Libya/TIP` },
    { value: `Tromsoe/Norway/TOS`, label: `Tromsoe/Norway/TOS` },
    { value: `Trondheim/Norway/TRD`, label: `Trondheim/Norway/TRD` },
    { value: `Tsumeb/Namibia/TSB`, label: `Tsumeb/Namibia/TSB` },
    { value: `Tucson (AZ)/USA/TUS`, label: `Tucson (AZ)/USA/TUS` },
    { value: `Tulepo (MS)/USA/TUP`, label: `Tulepo (MS)/USA/TUP` },
    { value: `Tulsa (OK)/USA/TUL`, label: `Tulsa (OK)/USA/TUL` },
    { value: `Tunis - Carthage/Tunisia/TUN`, label: `Tunis - Carthage/Tunisia/TUN` },
    { value: `Turbat/Pakistan/TUK`, label: `Turbat/Pakistan/TUK` },
    { value: `Turin/Italy/TRN`, label: `Turin/Italy/TRN` },
    { value: `Turku/Finland/TKU`, label: `Turku/Finland/TKU` },
    { value: `Tuscaloosa (AL)/USA/TCL`, label: `Tuscaloosa (AL)/USA/TCL` },
    { value: `Tuxtla Gutierrez/Mexico/TGZ`, label: `Tuxtla Gutierrez/Mexico/TGZ` },
    { value: `Twin Falls (ID)/USA/TWF`, label: `Twin Falls (ID)/USA/TWF` },
    { value: `Tyler (TX)/USA/TYR`, label: `Tyler (TX)/USA/TYR` },
    { value: `Ua Huka/French Polynesia/UAH`, label: `Ua Huka/French Polynesia/UAH` },
    { value: `Ua Pou/French Polynesia/UAP`, label: `Ua Pou/French Polynesia/UAP` },
    { value: `Ube/Japan/UBJ`, label: `Ube/Japan/UBJ` },
    { value: `Uberaba/Brazil/UBA`, label: `Uberaba/Brazil/UBA` },
    { value: `Uberlandia - Eduardo Gomes Airport/Brazil/UDI`, label: `Uberlandia - Eduardo Gomes Airport/Brazil/UDI` },
    { value: `Ubon Ratchathani - Muang Ubon Airport/Thailand/UBP`, label: `Ubon Ratchathani - Muang Ubon Airport/Thailand/UBP` },
    { value: `Udaipur - Dabok Airport/India/UDR`, label: `Udaipur - Dabok Airport/India/UDR` },
    { value: `Uden - Volkel Airport/Netherlands/UDE`, label: `Uden - Volkel Airport/Netherlands/UDE` },
    { value: `Udon Thani/Thailand/UTH`, label: `Udon Thani/Thailand/UTH` },
    { value: `Ufa/Russia/UFA`, label: `Ufa/Russia/UFA` },
    { value: `Uherske Hradiste/Czech Republic/UHE`, label: `Uherske Hradiste/Czech Republic/UHE` },
    { value: `Uige/Angola/UGO`, label: `Uige/Angola/UGO` },
    { value: `Ujung Pandang - Hasanudin Airport/Indonesia/UPG`, label: `Ujung Pandang - Hasanudin Airport/Indonesia/UPG` },
    { value: `Ukhta/Russia/UCT`, label: `Ukhta/Russia/UCT` },
    { value: `Ukiah (CA)/USA/UKI`, label: `Ukiah (CA)/USA/UKI` },
    { value: `Ulaanbaatar - Buyant Uhaa Airport/Mongolia/ULN`, label: `Ulaanbaatar - Buyant Uhaa Airport/Mongolia/ULN` },
    { value: `Ulan-Ude/Russia/UUD`, label: `Ulan-Ude/Russia/UUD` },
    { value: `Ulanhot/PR China/HLH`, label: `Ulanhot/PR China/HLH` },
    { value: `Ulei/Vanuatu/ULB`, label: `Ulei/Vanuatu/ULB` },
    { value: `Ulsan/South Korea/USN`, label: `Ulsan/South Korea/USN` },
    { value: `Ulundi/South Africa/ULD`, label: `Ulundi/South Africa/ULD` },
    { value: `Umea/Sweden/UME`, label: `Umea/Sweden/UME` },
    { value: `Umiujaq/Canada/YUD`, label: `Umiujaq/Canada/YUD` },
    { value: `Umtata/South Africa/UTT`, label: `Umtata/South Africa/UTT` },
    { value: `Unalakleet (AK)/USA/UNK`, label: `Unalakleet (AK)/USA/UNK` },
    { value: `Union Island/Saint Vincent and the Grenadines/UNI`, label: `Union Island/Saint Vincent and the Grenadines/UNI` },
    { value: `Unst (Shetland Island) - Baltasound Airport/United Kingdom/UNT`, label: `Unst (Shetland Island) - Baltasound Airport/United Kingdom/UNT` },
    { value: `Upala/Costa Rica/UPL`, label: `Upala/Costa Rica/UPL` },
    { value: `Upernavik - Upernavik Heliport/Greenland/JUV`, label: `Upernavik - Upernavik Heliport/Greenland/JUV` },
    { value: `Upington/South Africa/UTN`, label: `Upington/South Africa/UTN` },
    { value: `Upolu Point (HI)/USA/UPP`, label: `Upolu Point (HI)/USA/UPP` },
    { value: `Uranium City/Canada/YBE`, label: `Uranium City/Canada/YBE` },
    { value: `Urgench/Uzbekistan/UGC`, label: `Urgench/Uzbekistan/UGC` },
    { value: `Uriman/Venezuela/URM`, label: `Uriman/Venezuela/URM` },
    { value: `Urmiehm (Orumieh)/Iran/OMH`, label: `Urmiehm (Orumieh)/Iran/OMH` },
    { value: `Uruapan/Mexico/UPN`, label: `Uruapan/Mexico/UPN` },
    { value: `Urubupunga - Ernesto Pochler Airport/Brazil/URB`, label: `Urubupunga - Ernesto Pochler Airport/Brazil/URB` },
    { value: `Uruguaiana - Ruben Berta Airport/Brazil/URG`, label: `Uruguaiana - Ruben Berta Airport/Brazil/URG` },
    { value: `Urumqi/Xinjiang, PR China/URC`, label: `Urumqi/Xinjiang, PR China/URC` },
    { value: `Uruzgan/Afghanistan/URZ`, label: `Uruzgan/Afghanistan/URZ` },
    { value: `Ushuaia - Islas Malvinas Airport/Argentina/USH`, label: `Ushuaia - Islas Malvinas Airport/Argentina/USH` },
    { value: `Utapao (Pattaya)/Thailand/UTP`, label: `Utapao (Pattaya)/Thailand/UTP` },
    { value: `Utica (NY) - Oneida County Airport/USA/UCA`, label: `Utica (NY) - Oneida County Airport/USA/UCA` },
    { value: `Utila/Honduras/UII`, label: `Utila/Honduras/UII` },
    { value: `Uummannaq/Greenland/UMD`, label: `Uummannaq/Greenland/UMD` },
    { value: `Uzhgorod/Ukraine/UDJ`, label: `Uzhgorod/Ukraine/UDJ` },
    { value: `Vaasa/Finland/VAA`, label: `Vaasa/Finland/VAA` },
    { value: `Vaexjoe/Sweden/VXO`, label: `Vaexjoe/Sweden/VXO` },
    { value: `Vail (CO)/USA/EGE`, label: `Vail (CO)/USA/EGE` },
    { value: `Val d'Or/Canada/YVO`, label: `Val d'Or/Canada/YVO` },
    { value: `Valdez (AK)/USA/VDZ`, label: `Valdez (AK)/USA/VDZ` },
    { value: `Valdosta (GA)/USA/VLD`, label: `Valdosta (GA)/USA/VLD` },
    { value: `Valencia/Spain/VLC`, label: `Valencia/Spain/VLC` },
    { value: `Valencia/Venezuela/VLN`, label: `Valencia/Venezuela/VLN` },
    { value: `Valladolid/Spain/VLL`, label: `Valladolid/Spain/VLL` },
    { value: `Valparaiso/Chile/VAP`, label: `Valparaiso/Chile/VAP` },
    { value: `Valverde/Spain/VDE`, label: `Valverde/Spain/VDE` },
    { value: `Van - Ferit Melen/Turkey/VAN`, label: `Van - Ferit Melen/Turkey/VAN` },
    { value: `Vancouver - Vancouver International/Canada/YVR`, label: `Vancouver - Vancouver International/Canada/YVR` },
    { value: `Varadero/Cuba/VRA`, label: `Varadero/Cuba/VRA` },
    { value: `Varanasi/India/VNS`, label: `Varanasi/India/VNS` },
    { value: `Varkaus/Finland/VRK`, label: `Varkaus/Finland/VRK` },
    { value: `Varna/Bulgaria/VAR`, label: `Varna/Bulgaria/VAR` },
    { value: `Vasteras/Sweden/VST`, label: `Vasteras/Sweden/VST` },
    { value: `Velikiye Luki (Welikije Luki)/Russia/VLU`, label: `Velikiye Luki (Welikije Luki)/Russia/VLU` },
    { value: `Venice - Marco Polo/Italy/VCE`, label: `Venice - Marco Polo/Italy/VCE` },
    { value: `Veracruz/Mexico/VER`, label: `Veracruz/Mexico/VER` },
    { value: `Vernal (UT)/USA/VEL`, label: `Vernal (UT)/USA/VEL` },
    { value: `Vero Beach/Ft. Pierce (FL)/USA/VRB`, label: `Vero Beach/Ft. Pierce (FL)/USA/VRB` },
    { value: `Verona (Brescia) Montichiari/Italy/VBS`, label: `Verona (Brescia) Montichiari/Italy/VBS` },
    { value: `Verona/Italy/VRN`, label: `Verona/Italy/VRN` },
    { value: `Victoria/Canada/YYJ`, label: `Victoria/Canada/YYJ` },
    { value: `Victoria Falls/Zimbabwe/VFA`, label: `Victoria Falls/Zimbabwe/VFA` },
    { value: `Vidin/Bulgaria/VID`, label: `Vidin/Bulgaria/VID` },
    { value: `Vientiane - Wattay/Lao PDR/VTE`, label: `Vientiane - Wattay/Lao PDR/VTE` },
    { value: `Vigo/Spain/VGO`, label: `Vigo/Spain/VGO` },
    { value: `Villahermosa/Mexico/VSA`, label: `Villahermosa/Mexico/VSA` },
    { value: `Vilnius/Lithuania/VNO`, label: `Vilnius/Lithuania/VNO` },
    { value: `Virgin Gorda/Virgin Islands (British)/VIJ`, label: `Virgin Gorda/Virgin Islands (British)/VIJ` },
    { value: `Visalia (CA)/USA/VIS`, label: `Visalia (CA)/USA/VIS` },
    { value: `Visby/Sweden/VBY`, label: `Visby/Sweden/VBY` },
    { value: `Vitoria/Spain/VIT`, label: `Vitoria/Spain/VIT` },
    { value: `Vitoria - Eurico de Aguiar Salles Airport/Brazil/VIX`, label: `Vitoria - Eurico de Aguiar Salles Airport/Brazil/VIX` },
    { value: `Vryheid/South Africa/VYD`, label: `Vryheid/South Africa/VYD` },
    { value: `Wabush/Canada/YWK`, label: `Wabush/Canada/YWK` },
    { value: `Waco (TX)/USA/ACT`, label: `Waco (TX)/USA/ACT` },
    { value: `Wagga/Australia/WGA`, label: `Wagga/Australia/WGA` },
    { value: `Walla Walla (WA)/USA/ALW`, label: `Walla Walla (WA)/USA/ALW` },
    { value: `Wallis/Wallis and Futuna Islands/WLS`, label: `Wallis/Wallis and Futuna Islands/WLS` },
    { value: `Walvis Bay/South Africa/WVB`, label: `Walvis Bay/South Africa/WVB` },
    { value: `Warrnambool/Australia/WMB`, label: `Warrnambool/Australia/WMB` },
    { value: `Warsaw - Frédéric Chopin/Poland/WAW`, label: `Warsaw - Frédéric Chopin/Poland/WAW` },
    { value: `Washington DC - Baltimore Washington International/USA/BWI`, label: `Washington DC - Baltimore Washington International/USA/BWI` },
    { value: `Washington DC - Dulles International/USA/IAD`, label: `Washington DC - Dulles International/USA/IAD` },
    { value: `Washington DC - Ronald Reagan National/USA/DCA`, label: `Washington DC - Ronald Reagan National/USA/DCA` },
    { value: `Washington DC/USA/WAS`, label: `Washington DC/USA/WAS` },
    { value: `Waterloo IA/USA/ALO`, label: `Waterloo IA/USA/ALO` },
    { value: `Watertown (SD)/USA/ATY`, label: `Watertown (SD)/USA/ATY` },
    { value: `Wausau/Stevens Point (WI)/USA/CWA`, label: `Wausau/Stevens Point (WI)/USA/CWA` },
    { value: `Weipa/Australia/WEI`, label: `Weipa/Australia/WEI` },
    { value: `Welkom/South Africa/WEL`, label: `Welkom/South Africa/WEL` },
    { value: `Wellington/New Zealand/WLG`, label: `Wellington/New Zealand/WLG` },
    { value: `Wenatchee (WA)/USA/EAT`, label: `Wenatchee (WA)/USA/EAT` },
    { value: `West Palm Beach (FL)/USA/PBI`, label: `West Palm Beach (FL)/USA/PBI` },
    { value: `West Yellowstone (MT)/USA/WYS`, label: `West Yellowstone (MT)/USA/WYS` },
    { value: `Westerland, Sylt Airport/Germany/GWT`, label: `Westerland, Sylt Airport/Germany/GWT` },
    { value: `Whakatane/New Zealand/WHK`, label: `Whakatane/New Zealand/WHK` },
    { value: `Whale Cove, NT/Canada/YXN`, label: `Whale Cove, NT/Canada/YXN` },
    { value: `Whangarei/New Zealand/WRE`, label: `Whangarei/New Zealand/WRE` },
    { value: `White Plains (NY)/USA/HPN`, label: `White Plains (NY)/USA/HPN` },
    { value: `Whitehorse/Canada/YXY`, label: `Whitehorse/Canada/YXY` },
    { value: `Whitsunday Resort/Australia/HAP`, label: `Whitsunday Resort/Australia/HAP` },
    { value: `Whyalla/Australia/WYA`, label: `Whyalla/Australia/WYA` },
    { value: `Wichita Falls (TX)/USA/SPS`, label: `Wichita Falls (TX)/USA/SPS` },
    { value: `Wichita (KS)/USA/ICT`, label: `Wichita (KS)/USA/ICT` },
    { value: `Wick/United Kingdom/WIC`, label: `Wick/United Kingdom/WIC` },
    { value: `Wickham/Australia/WHM`, label: `Wickham/Australia/WHM` },
    { value: `Wien (Vienna) - Vienna International/Austria/VIE`, label: `Wien (Vienna) - Vienna International/Austria/VIE` },
    { value: `Wiesbaden, Air Base/Germany/WIE`, label: `Wiesbaden, Air Base/Germany/WIE` },
    { value: `Wilkes Barre/Scranton (PA)/USA/AVP`, label: `Wilkes Barre/Scranton (PA)/USA/AVP` },
    { value: `Williamsport (PA)/USA/IPT`, label: `Williamsport (PA)/USA/IPT` },
    { value: `Williston (ND)/USA/ISL`, label: `Williston (ND)/USA/ISL` },
    { value: `Wilmington (NC)/USA/ILM`, label: `Wilmington (NC)/USA/ILM` },
    { value: `Wilna (Vilnius)/Lithuania/VNO`, label: `Wilna (Vilnius)/Lithuania/VNO` },
    { value: `Wiluna/Australia/WUN`, label: `Wiluna/Australia/WUN` },
    { value: `Windhoek - Eros/Namibia/ERS`, label: `Windhoek - Eros/Namibia/ERS` },
    { value: `Windhoek - Hosea Kutako International/Namibia/WDH`, label: `Windhoek - Hosea Kutako International/Namibia/WDH` },
    { value: `Windsor Ontario/Canada/YQG`, label: `Windsor Ontario/Canada/YQG` },
    { value: `Winnipeg International/Canada/YWG`, label: `Winnipeg International/Canada/YWG` },
    { value: `Wolf Point (MT)/USA/OLF`, label: `Wolf Point (MT)/USA/OLF` },
    { value: `Wollongong/Australia/WOL`, label: `Wollongong/Australia/WOL` },
    { value: `Woomera/Australia/UMR`, label: `Woomera/Australia/UMR` },
    { value: `Worcester (MA)/USA/ORH`, label: `Worcester (MA)/USA/ORH` },
    { value: `Worland (WY)/USA/WRL`, label: `Worland (WY)/USA/WRL` },
    { value: `Wrangell (AK)/USA/WRG`, label: `Wrangell (AK)/USA/WRG` },
    { value: `Wuhan/Hubei, PR China/WUH`, label: `Wuhan/Hubei, PR China/WUH` },
    { value: `Wyndham/Australia/WYN`, label: `Wyndham/Australia/WYN` },
    { value: `Xiamen/Fujian, PR China/XMN`, label: `Xiamen/Fujian, PR China/XMN` },
    { value: `Xi'an - Xianyang/Shaanxi, PR China/XIY`, label: `Xi'an - Xianyang/Shaanxi, PR China/XIY` },
    { value: `Yakima (WA)/USA/YKM`, label: `Yakima (WA)/USA/YKM` },
    { value: `Yakutat (AK)/USA/YAK`, label: `Yakutat (AK)/USA/YAK` },
    { value: `Yakutsk/Russia/YKS`, label: `Yakutsk/Russia/YKS` },
    { value: `Yamagata, Junmachi/Japan/GAJ`, label: `Yamagata, Junmachi/Japan/GAJ` },
    { value: `Yamoussoukro/Côte d'Ivoire/ASK`, label: `Yamoussoukro/Côte d'Ivoire/ASK` },
    { value: `Yanbu/Saudi Arabia/YNB`, label: `Yanbu/Saudi Arabia/YNB` },
    { value: `Yangon (Rangoon) - Mingaladon/Myanmar/RGN`, label: `Yangon (Rangoon) - Mingaladon/Myanmar/RGN` },
    { value: `Yaounde/Cameroon/YAO`, label: `Yaounde/Cameroon/YAO` },
    { value: `Yellowknife/Canada/YZF`, label: `Yellowknife/Canada/YZF` },
    { value: `Yekaterinburg - Koltsovo Airport/Russia/SVX`, label: `Yekaterinburg - Koltsovo Airport/Russia/SVX` },
    { value: `Yichang/Hubei, PR China/YIH`, label: `Yichang/Hubei, PR China/YIH` },
    { value: `Yokohama/Japan/YOK`, label: `Yokohama/Japan/YOK` },
    { value: `Yuma (AZ)/USA/YUM`, label: `Yuma (AZ)/USA/YUM` },
    { value: `Zacatecas/Mexico/ZCL`, label: `Zacatecas/Mexico/ZCL` },
    { value: `Zadar/Croatia (Hrvatska)/ZAD`, label: `Zadar/Croatia (Hrvatska)/ZAD` },
    { value: `Zagreb - Zagreb Airport Pleso/Croatia (Hrvatska)/ZAG`, label: `Zagreb - Zagreb Airport Pleso/Croatia (Hrvatska)/ZAG` },
    { value: `Zakynthos/Greece/ZTH`, label: `Zakynthos/Greece/ZTH` },
    { value: `Zaragoza/Spain/ZAZ`, label: `Zaragoza/Spain/ZAZ` },
    { value: `Zhob/Pakistan/PZH`, label: `Zhob/Pakistan/PZH` },
    { value: `Zinder/Niger/ZND`, label: `Zinder/Niger/ZND` },
    { value: `Zouerate/Mauritania/OUZ`, label: `Zouerate/Mauritania/OUZ` },
    { value: `Zurich (Zürich) - Kloten/Switzerland/ZRH`, label: `Zurich (Zürich) - Kloten/Switzerland/ZRH` }

]

export default IATACodes