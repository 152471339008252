import $ from "jquery";
import React, { Component, createRef, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import { InputText } from "primereact/inputtext";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const FormBuilder = (props) => {

  const fb = useRef();

  useEffect(() => {

    const options = {

      editOnAdd: true,
      typeUserAttrs: {
        number: {
          fraction: {
            label: 'Fraction',
            value: 0
          },
          directionsign: {
            label: '0-higher,1-lower',
            value: 0
          },
          
        },
        paragraph: {
          ptype: {
            label: 'Is this is Header ?',
            value: false,
            type: 'checkbox',
          },
          alignment: {
            label: 'Content Alignment', 
            multiple: false, 
            options: {
               'center': 'Center',
              'start': 'Left',
              'end': 'Right',
             
            },
           
          }
          
        }

      },
      disabledActionButtons: ['data', 'save', 'clear'],
      onAddField: (optionTemplate, optionIndex) => {
        return optionTemplate
      },
      disabledAttrs: [
        'access',
        'className',
        'inline',
        'value', 'min', 'max', 'maxlength',
        'other',
        'rows',
        'step',
        'style',
        'subtype',
        'toggle'
      ],
      disableFields: ['autocomplete', 'hidden', 'header', 'button'
      ],
      stickyControls: {
        enable: true,
        offset: {
          top: 70,
          right: 20,
          left: 'auto'
        }
      },
      onSave: function (evt, formData) {
        console.log(formData)
        props.onSubmit(formData)
        const customSwal2 = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',

          },
          buttonsStyling: false
        })
        customSwal2.fire(
          'Saved!',
          '',
          'success')

      },
      fields: [{
        label: 'Section',
        attrs: {
          type: 'section'  // New 'section' type
        },
        icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-560h360v-120H240v120Zm-40 440q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>'  // Custom icon for section
      }],
      templates: {
        section: function(fieldData) {
          return {
            field: `<div id="${fieldData.name}" class="custom-section">
                      <h3>${fieldData.label}</h3>
                    </div>`,  // HTML structure for the section field
            onRender: function() {
              // Custom render logic if necessary
              console.log(`Section rendered: ${fieldData.name}`);
            }
          };
        }
      }
    };


    let formBuilderPlugin = $(fb.current).formBuilder(options);

    props.fbref(formBuilderPlugin)
    setTimeout(() => { formBuilderPlugin.actions.setData(props.values); }, 500)

  }, [])

  return (
    <>

      <div id="fb-editor" ref={fb} />
    </>


  );
}

// class FormBuilder extends Component {
//     fb = createRef();
//     componentDidMount() {
//       $(this.fb.current).formBuilder();
//     }

//     render() {
//       return <div id="fb-editor" ref={this.fb} />;
//     }
//   }

export default FormBuilder