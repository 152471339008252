import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { canvasPreview } from '../../components/canvasPreview';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import axios from 'axios';
import IATACodes from '../../assets/dummy/IATACodes'
import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'

import { CascadeSelect } from 'primereact/cascadeselect';

import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';
import 'react-image-crop/dist/ReactCrop.css'
import exportAsImage from '../../components/exportAsImage';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"

import cx from "classnames";

import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from '../../constants/api_url';
import APIServices from '../../service/APIService';
import { DateTime } from 'luxon';
import { ReadMoreComponent } from '../../components/Forms/ReadMoreComponent';
import { fetchUserList } from '../../RTK/Background/userProfileList';
const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const AddClientUser = () => {

    const [data, setData] = useState([])
    const [apidata, setAPiData] = useState([])
    const [allLocationRoles, setAllLocationRoles] = useState([])
    const [selectedroles, setSelectedRoles] = useState({ country: [], navigos: [], materiality: [] })
const dispatch = useDispatch()
    const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
    const [adddialog, setAddDialog] = useState(false)
    const [cropdialog, setCropDialog] = useState(false)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [selectedUser, setSelectedUser] = useState({})
    const [selectedconfig, setSelectedConfig] = useState({ name: 'Assigned Locations' })
    const [configdialog, setConfigDialog] = useState(false)
    const imgRef = useRef(null)
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)

    const previewCanvasRef = useRef(null)
    const [employee, setEmployee] = useState({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false, reviewer: false, approver: false } })
    const [submitted, setSubmitted] = useState(false)
    const [applicationlist, setApplicationList] = useState([])
    const [filter, setFilter] = useState('')
    const forceUpdate = useForceUpdate()
    const dt = useRef(null);
    const [roledialog, setRoleDialog] = useState(false)
    const [rawsitelist, setRawSiteList] = useState([])
    const [userRole, setUserRole] = useState([])
    const [selectedObj, setSelectedObj] = useState({ userProfileId: admin_data.id, user_id: null, tier1_id: 0, tier2_id: 0, tier3_id: 0, roles: [] })
    const [selectedlist, setSelectedList] = useState({ country: [], city: [], site: [] })
    const toast = useRef(null);

    let configmenu = [{ name: 'Assigned Locations' }, { name: 'Admin' }]
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    const locationtree = { "name": "", "children": [{ "name": "India", "children": [{ "name": "TamilNadu", parent: 100, "children": [{ "name": "Vellore" }, { "name": "Sholinghur" }] }, { "name": "AP", "children": [{ "name": "Chittoor" }, { "name": "Puttur" }] }] }, { "name": "Japan", "children": [{ "name": "Yuhan", "children": [{ "name": "Site1" }, { "name": "Site2" }] }, { "name": "Beijing", "children": [{ "name": "Site3" }, { "name": "Site4" }] }] }] }


    useEffect(() => {

        renderData()

    }, [])

    const renderData = () => {
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let app = {
            "include": [{ "relation": "applicationRoles" }]

        }
        APIServices.get(API.UserRole).then((res) => {

            setUserRole(res.data.filter(i => i.userProfileId === admin_data.id))
        })
        APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`).then((res) => {
            const shapedSite = res.data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            console.log(shapedSite)
            shapedSite.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    city.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        site.push({ name: k.name, id: k.id })
                    })
                })
            })
            setRawSiteList(shapedSite)
            setSelectedList({ country, city, site })
        })
        APIServices.get(API.Application + `?filter=${encodeURIComponent(JSON.stringify(app))}`).then((res) => {
            setApplicationList(res.data)

        })
        let datas = []
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile)
                .then((res) => {
                    setAPiData(res.data)

                    res.data.forEach((item, index) => {
                        if (item.role === 'clientuser' && item.information.cid === admin_data.id) {

                            datas.push({ id: item.id, empname: item.information.empname, empdesgination: item.information.empdesgination, empid: item.information.empid, empmailid: item.email, config: item.information.config, information: item, emplocation: item.information.emplocation === undefined ? '' : item.information.emplocation })

                        }
                        if (index === res.data.length - 1) {
                            resolve(datas)
                        }
                    })
                })
        })
        promise.then((d) => { console.log(d); setData(d); forceUpdate() })


    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="col-12 flex justify-content-end" >
                    <Button label="New User" icon="pi pi-plus" color='white' className="clr-navy mr-2" style={{ width: 150, color: 'white' }} onClick={() => { setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false, reviewer: false, approver: false } }); setAddDialog(true) }} />

                </div>
            </React.Fragment>
            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '40%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );


    const checkValidMailID = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const addNewEmployee = () => {
        if (employee.id === undefined) {
            let loc = data
            setSubmitted(true)
            if (checkValidMailID(employee.empmailid.trim()) && employee.empname.trim().length !== 0 && checkValidMailID(employee.empdesgination.trim()) ) {

                let obj = employee
                let newObj = { information: {} }
                Object.keys(obj).forEach((i) => {
                    if (i === 'empmailid') {
                        newObj['email'] = obj[i]
                    } else {

                        if (i === 'emplocation') {

                            newObj.information['emplocation'] = obj[i].trim()
                        } else {
                            newObj.information[i] = obj[i]
                        }


                    }
                })
                newObj.information['companyname'] = admin_data.information.companyname
                newObj.information['companyid'] = admin_data.userId
                newObj.information['blocked'] = false;
                newObj.information['cid'] = admin_data.id
                newObj['role'] = 'clientuser'
                newObj['clientId'] = admin_data.id
                if (newObj['clientId'] !== null) {
                    APIServices.post(API.NewUserProfile, newObj)
                        .then((res) => {
                            dispatch(fetchUserList(admin_data.id))
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: `User Added successfully`,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            setData(loc)
                            renderData()
                        }).catch((e) => {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: `Email Id exist / check internet connection `,
                                showConfirmButton: false,
                                timer: 1500
                            })

                        })
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong kindly logout & try again.`,
                        showConfirmButton: false,
                        timer: 2500
                    })
                }
                setAddDialog(false)
                setSelectedConfig({ name: 'Assigned Locations' })
                setSubmitted(false)
                setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false, reviewer: false, approver: false } })

                forceUpdate()
            }
        } else {
            setSubmitted(true)

            if (checkValidMailID(employee.empmailid.trim())  && checkValidMailID(employee.empdesgination.trim()) && employee.empname.trim().length !== 0) {
                let obj = employee
                let newObj = { information: {} }
                Object.keys(obj).forEach((i) => {
                    if (i === 'empmailid') {

                    } else {

                        if (i === 'emplocation') {
                            console.log(obj[i])
                            newObj.information['emplocation'] = obj[i].trim()
                        }
                        if (i !== 'id' && i !== 'information' && i !== 'emplocation') {

                            newObj.information[i] = obj[i]
                        }

                    }
                })


                newObj.information['companyname'] = admin_data.information.companyname
                newObj.information['companyid'] = admin_data.userId
                newObj.information['cid'] = admin_data.id
                newObj['clientId'] = admin_data.id
                newObj.information['blocked'] = employee.information.blocked
                newObj['company'] = admin_data.information.companyname
                newObj['role'] = employee.information.role
                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(employee.id), newObj)
                    .then((res) => {
                        setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false, approver: false, reviewer: false } })
                        dispatch(fetchUserList(admin_data.id))
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `Updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {
                        setEmployee({ empname: '', empmailid: '', empdesgination: '', emplocation: '', role: { reporter: true, viewer: false, approver: false, reviewer: false } })

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
                setAddDialog(false)
                setSelectedConfig({ name: 'Assigned Locations' })
                setSubmitted(false)

            }
        }
    }
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewEmployee() }} />

        </div>)
    }
    const updateDataConfig = (data2) => {
        let id = data[selectedConfigIndex].id
        let apidatalocal = apidata
        let apiIndex = apidata.findIndex((i) => { return i.id === id })

        let newObj = apidatalocal[apiIndex]
        delete newObj.username;
        delete newObj.email;

        newObj.information.config = data2
        newObj.company = admin_data.information.companyname

        let promise = new Promise((resolve, error) => {
            APIServices.patch(API.UserProfile_Edit(id), newObj).then((res) => {
                if (res.status === 200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
        promise.then((status) => {
            if (status) {
                renderData()
            }
        })
    }


    const cropandsave = async () => {

        let canvas = previewCanvasRef.current
        let URLs = canvas.toDataURL('image/png')
        let img = document.createElement('img')
        img.src = URLs
        img.style.height = canvas.height
        img.style.width = canvas.width

        setTimeout(() => {


            // atob to base64_decode the data-URI
            var image_data = atob(URLs.split(',')[1]);
            // Use typed arrays to convert the binary data to a Blob
            var arraybuffer = new ArrayBuffer(image_data.length);
            var view = new Uint8Array(arraybuffer);
            for (var i = 0; i < image_data.length; i++) {
                view[i] = image_data.charCodeAt(i) & 0xff;
            }
            try {
                // This is the recommended method:
                var blob = new Blob([arraybuffer], { type: 'application/octet-stream' });
            } catch (e) {
                // The BlobBuilder API has been deprecated in favour of Blob, but older
                // browsers don't know about the Blob constructor
                // IE10 also supports BlobBuilder, but since the `Blob` constructor
                //  also works, there's no need to add `MSBlobBuilder`.
                var bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder);
                bb.append(arraybuffer);
                var blob = bb.getBlob('application/octet-stream'); // <-- Here's the Blob
            }

            // Use the URL object to create a temporary URL
            var url = (window.webkitURL || window.URL).createObjectURL(blob);
            let loc = employee
            loc.logo = url
            setEmployee(employee)
            setCropDialog(false)
        }, 1000)

    }
    useEffect(() => {
        console.log('ass')
        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {

            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                1,
                0,
            )
        }
    }, [completedCrop, crop])
    const editUser = (user) => {
        console.log(user.information.information.role)
        setAddDialog(true)
        setEmployee({ id: user.id, information: user.information, empname: user.empname, emplocation: user.information.information.emplocation, empdesgination: user.empdesgination, empmailid: user.information.email, emplocation: user.emplocation, role: user.information.information.role })

    }
    const deleteUser = (user) => {
        alert('Delete feature is disabled by tech team')

        // Swal.fire({
        //     title: 'Delete user ?' + user.empname,
        //     text: "You won't be able to reuse mail id " + user.empmailid,
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, delete User!'
        // }).then(async (result) => {
        //     if (result.isConfirmed) {
        //         const { value: password } = await Swal.fire({
        //             title: 'Enter Master password',
        //             input: 'password',
        //             inputLabel: 'Password',
        //             inputPlaceholder: 'Enter Master password',
        //             inputAttributes: {
        //                 maxlength: 10,
        //                 autocapitalize: 'off',
        //                 autocorrect: 'off'
        //             }
        //         })

        //         if (password) {
        //             if (password === 'failletter') {
        //  APIservices.delete(API.UserProfile_Edit(user.id)  )
        //                     .then((res) => {


        //                         Swal.fire({
        //                             position: 'center',
        //                             icon: 'success',
        //                             title: `User deleted successfully`,
        //                             showConfirmButton: false,
        //                             timer: 1500
        //                         })

        //                         renderData()
        //                     }).catch((e) => {

        //                         Swal.fire({
        //                             position: 'center',
        //                             icon: 'error',
        //                             title: `Something went wrong`,
        //                             showConfirmButton: false,
        //                             timer: 1500
        //                         })

        //                     })
        //             }
        //         }

        //     }
        // })
    }
    const blockUser = (user) => {
        let title = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? 'Block user ' + user.empname + ' ?' : 'Unblock user ' + user.empname + ' ?'
        let text = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? "Once Blocked user cannot access the platform " : "Once user unblocked, they can access the platform "
        let cnftxt = (user.information.information['blocked'] === 'undefined' || !user.information.information['blocked']) ? 'Yes, Block User!' : 'Yes, Unblock User!'

        Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: cnftxt,
        }).then((result) => {
            if (result.isConfirmed) {
                let newObj = user.information;
                delete newObj.email
                delete newObj.username
                newObj.information['blocked'] = user.information.information['blocked'] === undefined ? true : !user.information.information['blocked'];
                console.log(newObj)
                APIServices.patch(API.UserProfile_Edit(user.id), newObj)
                    .then((res) => {


                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: `User details updated successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                        renderData()
                    }).catch((e) => {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: `Something went wrong`,
                            showConfirmButton: false,
                            timer: 1500
                        })

                    })
            }
        })
    }
    const nameTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empname}
            </>
        );
    }
    const logoTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <img src={rowData.logo} width='50' />
            </>
        );
    }
    const configTemplate = (rowData) => {

        return (
            <>

                <i onClick={() => { openConfig(rowData) }} className='pi pi-list'></i>
            </>
        );
    }
    const roleTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                <span>{rowData.config[0].admin.approver ? 'Approver' : 'User'}</span>
            </>
        );
    }
    const editTemplate = (rowData) => {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'green' }} onClick={() => { editUser(rowData) }} className='material-icons'>edit</i>
                <i onMouseOut={(e) => { e.currentTarget.style.color = '#495057' }} style={{ cursor: 'pointer' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { deleteUser(rowData) }} className='material-icons'>delete</i>
                <i onMouseOut={(e) => { e.currentTarget.style.color = rowData.information.information.blocked ? 'red' : '#495057' }} style={{ cursor: 'pointer', color: rowData.information.information.blocked ? 'red' : '#495057' }} onMouseEnter={(e) => { e.currentTarget.style.color = 'red' }} onClick={() => { blockUser(rowData) }} className='material-icons'>block</i>

            </div>
        );
    }



    const ArrowIcon = ({ isOpen, className }) => {
        const baseClass = "arrow";
        const classes = cx(
            baseClass,
            { [`${baseClass}--closed`]: !isOpen },
            { [`${baseClass}--open`]: isOpen },
            className
        );
        return <IoMdArrowDropright className={classes} />;
    };

    const CheckBoxIcon = ({ variant, ...rest }) => {
        switch (variant) {
            case "all":
                return <TbCheckbox style={{ fontSize: 19 }} {...rest} />;
            case "none":
                return <MdCheckBoxOutlineBlank style={{ fontSize: 19 }} {...rest} />;
            case "some":
                return <MdOutlineIndeterminateCheckBox style={{ fontSize: 19 }} {...rest} />;
            default:
                return null;
        }
    };
    const openConfig = (config) => {
        setSelectedUser(config)
        let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === config.id && i.tier1_id === 0 && i.tier2_id === 0 && i.tier3_id === 0)

        setSelectedObj((prev) => ({ ...prev, tier1_id: null, tier2_id: null, tier3_id: null, user_id: config.id, roles: [] }))
        setAllLocationRoles(userRole.filter(i => i.user_id === config.id))
        setRoleDialog(true)

    }
    const contactTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empid}
            </>
        );
    }
    const destTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empdesgination}
            </>
        );
    }
    const emailTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empmailid}
            </>
        );
    }
    const userIDTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.emplocation}
            </>
        );
    }
    const onRoleChange = (e, obj) => {
        let loc = employee
        loc.role[obj] = !e.value
        setEmployee(loc)
        console.log(loc)
        forceUpdate()
    }
    const updateEmployee = (obj, val) => {
        let loc = employee
        loc[obj] = val
        setEmployee(loc)
        forceUpdate()
    }
    const centerAspectCrop = (
        mediaWidth,
        mediaHeight,
        aspect
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }
    const onImageLoad = (e) => {

        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 1))

    }

    const handleDropdownChange = (obj, val) => {
        console.log("handleDropdownChange:", val);
        let loc = JSON.parse(JSON.stringify(selectedObj))
        if (obj === 'country') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === val || val === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        j.locationThrees.forEach((k) => {

                            site.push({ name: k.name, id: k.id })
                        })
                    })
                }
            })
            loc.tier1_id = val
            loc.tier2_id = null
            loc.tier3_id = null

            setSelectedList({ country, city, site })
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === val && i.tier2_id === 0 && i.tier3_id === 0)



            console.log(country, city, site, loc)
        } else if (obj === 'city') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === val || val === 0) {
                            j.locationThrees.forEach((k) => {

                                site.push({ name: k.name, id: k.id })
                            })
                        }
                    })
                }
            })
            loc.tier2_id = val
            loc.tier3_id = null
            console.log(country, city, site, loc)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === loc.tier1_id && i.tier2_id === val && i.tier3_id === 0)

            setSelectedList({ country, city, site })
        } else if (obj === 'site') {
            let country = [{ name: 'All Countries', id: 0 }], city = [{ name: 'All Regions', id: 0 }], site = [{ name: 'All Sites', id: 0 }]
            rawsitelist.forEach((i) => {
                country.push({ name: i.name, id: i.id })
                if (i.id === loc.tier1_id || loc.tier1_id === 0) {
                    i.locationTwos.forEach((j) => {
                        city.push({ name: j.name, id: j.id })
                        if (j.id === loc.tier2_id || loc.tier2_id === 0) {
                            j.locationThrees.forEach((k) => {


                                site.push({ name: k.name, id: k.id })


                            })
                        }
                    })
                }
            })
            loc.tier3_id = val
            console.log(country, city, site, loc)
            let roleIndex = userRole.findIndex(i => i.userProfileId === admin_data.id && i.user_id === selectedUser.id && i.tier1_id === loc.tier1_id && i.tier2_id === loc.tier2_id && i.tier3_id === val)

            setSelectedList({ country, city, site })
        }
        console.log(selectedUser)
        if ((loc.tier1_id === 0 && loc.tier2_id === null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id === 0 && loc.tier3_id === null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id !== null && loc.tier2_id !== 0 && loc.tier3_id !== null) || (loc.tier1_id !== null && loc.tier1_id !== 0 && loc.tier2_id !== null && loc.tier2_id !== 0 && loc.tier3_id !== 0)) {

            APIServices.post(API.Roles_Info, { locations: { tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id }, user_id: selectedUser.id, userProfileId: admin_data.id }).then((res) => {
                console.log(res.data)
                if (res.data.length && res.data[0].id) {

                    setSelectedObj((prev) => ({ ...loc, ...res.data[0] }))
                } else {
                    setSelectedObj({ tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id, user_id: selectedUser.id, roles: [], disabledRoles: res.data[0].disabledRoles })
                }

            })
        } else {
            setSelectedObj({ tier1_id: loc.tier1_id, tier2_id: loc.tier2_id, tier3_id: loc.tier3_id, user_id: selectedUser.id, roles: [], disabledRoles: [] })
        }

        forceUpdate()
    };
    const onCheckBoxChecked = (role) => {
        let loc = JSON.parse(JSON.stringify(selectedObj))
        if (loc.roles.includes(role)) {
            let index = loc.roles.findIndex(i => i === role)
            loc.roles.splice(index, 1)
        } else {
            loc.roles.push(role)
        }
        setSelectedObj(loc)
    }

    const saveAssignment = () => {

        if (selectedObj.id) {
            let newObj = { user_id: selectedObj.user_id, modified_by: login_data.id, userProfileId: admin_data.id, modified_on: DateTime.utc(), roles: selectedObj.roles, locations: { tier1_id: selectedObj.tier1_id, tier2_id: selectedObj.tier2_id, tier3_id: selectedObj.tier3_id } }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                console.log(res)
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Saved', life: 3000 });
                setUserRole(newUserRole)
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                forceUpdate()

            })


        } else {
            let newObj = { created_by: login_data.id, created_on: DateTime.utc(), user_id: selectedObj.user_id, userProfileId: admin_data.id, locations: { tier1_id: selectedObj.tier1_id, tier2_id: selectedObj.tier2_id, tier3_id: selectedObj.tier3_id }, roles: selectedObj.roles }
            APIServices.post(API.AssingRolesToUser, newObj).then((res) => {
                let loc = userRole.filter(i => i.user_id !== newObj.user_id)
                let newUserRole = [...loc, ...res.data.data]
                setSelectedObj((prev) => ({ ...prev, id: res.data.object[0].id }))
                setUserRole(newUserRole)
                forceUpdate()
                setAllLocationRoles(newUserRole.filter(i => i.user_id === newObj.user_id))
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Assignment Updated', life: 3000 });
            })
        }
    };

    const resetAssignment = () => {
        console.log(selectedObj)
        if (selectedUser.id) {
            let newObj = { modified_by: login_data.id, modified_on: DateTime.utc(), roles: [] }
            Swal.fire({
                title: `Are you sure want to reset all role assignment for ${selectedUser.empname} ?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    APIServices.delete(API.ResetRolesToUser(selectedUser.id)).then((res) => {
                        setUserRole(userRole.filter(i => i.user_id !== selectedUser.id))
                        setSelectedObj((prev) => ({ ...prev, userProfileId: admin_data.id, tier1_id: 0, tier2_id: 0, tier3_id: 0, roles: [] }))
                        setAllLocationRoles([])
                        forceUpdate()
                        toast.current.show({ severity: 'danger', summary: 'Success', detail: 'Assignment Reseted', life: 3000 });
                    })
                }
            })
        }



    };
    const getAssignmentStatus = () => {
        let index = userRole.findIndex(i => i.id === selectedObj.id)
        if (index !== -1) {
            console.log(userRole[index], selectedObj)
            if (userRole[index].roles.length && selectedObj.roles.length === 0) {
                return false
            } else if (selectedObj.roles.length !== 0) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }

    }
    const getRoleNameById = (roleId) => {
        let roleName = 'Unknown Role'; // Default if not found
        Object.values(applicationlist).forEach(category => {
            if (category.applicationRoles) {
                const role = category.applicationRoles.find(role => role.id === roleId);
                console.log(category.applicationRoles)
                if (role) {
                    roleName = role.title;
                    return;
                }
            }

        });
        return roleName;
    }
    return (
        <div className="grid p-fluid">
            {(login_data.role === 'clientadmin' || login_data.role === 'clientuser') ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{
                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Users (${data.length})`} </h5>
                    </div>
                    <Toast ref={toast} />
                    <div>
                        <DataTable ref={dt} value={data}
                            scrollable
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            globalFilterFields={['empname']} globalFilter={filter} emptyMessage="No records found." header={header} responsiveLayout="scroll">
                            <Column field="empname" header="User Name" body={nameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="User Mail ID" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Location" body={userIDTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="config" header="Role Configuration" sortable body={configTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>
                            {/* <Column field="role" header="role" sortable body={roleTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                            <Column header="Action" body={editTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column>

                        </DataTable>
                        <Dialog visible={adddialog} style={{ width: '450px' }} header="Add User" modal className="p-fluid" footer={addDialogFooter} onHide={() => { setSubmitted(false); setAddDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">User Name <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={employee.empname} onChange={(e) => { updateEmployee('empname', e.target.value) }} placeholder="Name" />
                                {submitted && employee.empname.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Name is mandatory</small>}

                            </div>
                            {/* <div className="field">
                                    <label htmlFor="refdate">User ID</label>
                                    <InputText type={'text'} disabled={employee.id !== undefined} value={employee.empid} onChange={(e) => { updateEmployee('empid', e.target.value) }} placeholder="Unique ID for login" />
                                    {submitted && employee.empid.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>User Id is mandatory</small>}

                                </div> */}
                            <div className="field">
                                <label htmlFor="refdate">User E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                                <InputText disabled={employee.id !== undefined} type={'email'} value={employee.empmailid} onChange={(e) => { updateEmployee('empmailid', e.target.value) }} placeholder="Mail ID" />
                                {submitted && !checkValidMailID(employee.empmailid.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Mail ID is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Location ( Physical location of user if neccessary )</label>
                                <InputText type={'text'} value={employee.emplocation} onChange={(e) => { updateEmployee('emplocation', e.target.value) }} placeholder="location name" />


                            </div>

                            <div className="field">
                                <label htmlFor="refdate">Supervisor <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'email'} value={employee.empdesgination} onChange={(e) => { updateEmployee('empdesgination', e.target.value) }} placeholder="Supervisor mail id" />
                                {submitted && !checkValidMailID(employee.empdesgination.trim()) && <small className="p-invalid" style={{ color: 'red' }}>Supervisor Mail ID is mandatory</small>}


                            </div>
                            {/* <div >
                                <label htmlFor="refdate">Choose Role <span style={{ color: 'red' }}>*</span></label>
                                <div style={{ display: 'flex', marginTop: 5, marginLeft: 10 }}>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="rep" name="pizza" value={employee.role.reporter} onChange={(e) => { onRoleChange(e, 'reporter') }} checked={employee.role.reporter === true} />
                                        <label htmlFor="rep" className="ml-2">Reporter</label>
                                    </div>
                                    <div className="flex align-items-center ml-2">
                                        <Checkbox inputId="rev" name="pizza" value={employee.role.reviewer} onChange={(e) => { onRoleChange(e, 'reviewer') }} checked={employee.role.reviewer === true} />
                                        <label htmlFor="rev" className="ml-2">Reviewer</label>
                                    </div>
                                    <div style={{ marginLeft: 10 }} className="flex align-items-center ml-2">
                                        <Checkbox inputId="app" name="pizza" value={employee.role.approver} onChange={(e) => { onRoleChange(e, 'approver') }} checked={employee.role.approver === true} />
                                        <label htmlFor="app" className="ml-2">Approver</label>
                                    </div>
                                </div>
                                {submitted && Object.values(employee.role).filter(i => i === true).length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Select role</small>}

                            </div> */}



                        </Dialog>
                        <Dialog style={{ width: '85%' }} header={`Assignment permission to ` + selectedUser.empname} visible={roledialog} modal onHide={() => { setRoleDialog(false) }}>
                        <ReadMoreComponent percent={100} content={`<div>Use this tab which appears on a click of  <i class="pi pi-list" ></i>  to assign a specific role to the user added for managing access and administrative functions for managing the data at various levels. Follow the steps below to configure permissions.
<b>Select Location:</b> Use the dropdown menu to choose a location for which the user would be assigned to manage the data. Locations have been structured in three-tier i.e., Country, Region/State, Site. Based on the former tier selected, the next tier options will open. Please choose "All Countries“  to assign the user a role for managing the data globally.
<b>User Roles:</b> Select one or more roles to define the user's level of access:
<b>Reporter:</b> Collect and submit required quantitative or qualitative data (or amendments) for the assigned entity together with necessary evidence and comments, if any. To also re-submit the data with the necessary changes done as per the comments provided by the Reviewer, if the data has been sent back by the Reviewer. 
<b>Reviewer:</b> Review the submitted data for correctness and submission quality, including relevant attachments and data trends. Can request amendments by sending it back to the Reporter. 
<b>Approver:</b> Reviews the consolidated data for accuracy and relevancy, provides final approval, and, if necessary, requests further clarification from Reviewers or Reporters. The Approver also oversees the generation of final reports for compliance with regulatory or business requirements.
<b>Viewer:</b> Has read-only access to view data.
<b>Sub Admin Role:</b> Check the Admin box if the user requires the Admin privileges. This role provides higher-level access to manage functions within the platform.
<b>Section Admin Permissions:</b> Grant specific section-based permissions to allow the user to oversee and manage these areas.
Available sections include <b>Sustainability, Health, Safety Central, Safety Operational, Supply Chain, Dealership Sustainability, Environmental, Social, Governance</b>
Once you've selected the roles and permissions, click Save Assignment to apply the changes.
This setup helps control user access levels, ensuring they have the appropriate permissions for their Roles.</div>`} />
                            {userRole.filter(i => (i.user_id === selectedUser.id && i.roles.length)).length !== 0 && <div className='flex justify-content-end'>
                                <Button
                                    label="Reset Assignment"
                                    severity={"danger"}

                                    onClick={resetAssignment}
                                />
                            </div>}
                            <div className='grid m-0 col-12'>

                                <div className='col-6'>
                                    <div class="grid p-4">
                                        <div class="col-4">
                                            <Dropdown
                                                id="country"
                                                optionLabel="name"
                                                optionValue="id"
                                                value={selectedObj.tier1_id}
                                                options={selectedlist.country}
                                                onChange={(e) =>
                                                    handleDropdownChange('country', e.value)
                                                }
                                                placeholder='Choose country'
                                                className="w-full"
                                            />
                                        </div>
                                        {(selectedObj.tier1_id !== null && selectedObj.tier1_id !== 0) && <div class="col-4">
                                            <Dropdown
                                                id="city"
                                                optionLabel="name"
                                                optionValue="id"
                                                value={selectedObj.tier2_id}
                                                options={selectedlist.city}
                                                onChange={(e) =>
                                                    handleDropdownChange('city', e.value)
                                                }
                                                placeholder='Choose city'
                                                className="w-full"
                                            />
                                        </div>}
                                        {selectedObj.tier1_id !== null && selectedObj.tier1_id !== 0 && selectedObj.tier2_id !== null && selectedObj.tier2_id !== 0 && <div class="col-4">
                                            <Dropdown
                                                id="unit"
                                                optionLabel="name"
                                                optionValue="id"
                                                value={selectedObj.tier3_id}
                                                options={selectedlist.site}
                                                onChange={(e) =>
                                                    handleDropdownChange('site', e.value)
                                                }
                                                placeholder='Choose business unit'
                                                className="w-full"
                                            />
                                        </div>}
                                    </div>
                                    {(selectedObj.tier1_id !== null ? selectedObj.tier2_id !== null ? selectedObj.tier3_id !== null ? true : selectedObj.tier2_id === 0 : selectedObj.tier1_id === 0 : false) &&
                                        <div>

                                            {applicationlist.map((app, index) => {
                                                let displayedCategories = new Set();
                                                if (index === 0) {
                                                    return (
                                                        <>
                                                            {app.applicationRoles && app.applicationRoles.length > 0 && <div>

                                                                <div className="grid p-2">
                                                                    {app.applicationRoles && app.applicationRoles.sort((a, b) => {
                                                                    
                                                                        return a.categoryOrder - b.categoryOrder
                                                                    }).map((role, index) => {

                                                                        if (role.status && (!role.client_ids || (role.client_ids && !role.client_ids.length) || (role.client_ids && role.client_ids.length && role.client_ids.includes(admin_data.id)))) {
                                                                            if (role.id === 6 ? (selectedObj.tier1_id > 0 && selectedObj.tier2_id === 0 && selectedObj.tier3_id === null && login_data.role === 'clientadmin') : [7, 8, 9, 10, 11, 12, 13, 14, 15,16].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null && login_data.role === 'clientadmin')  : [17,18,19].includes(role.id) ? (selectedObj.tier1_id === 0 && selectedObj.tier2_id === null && selectedObj.tier3_id === null ) :  true) {
                                                                                const shouldShowCategory = !displayedCategories.has(role.category);

                                                                                
                                                                                displayedCategories.add(role.category);
                                                                                return (
                                                                                    <React.Fragment key={index}>

                                                                                        {shouldShowCategory && role.category && (
                                                                                            <div className="col-12">
                                                                                                <h4>{role.category}</h4>
                                                                                            </div>
                                                                                        )}
                                                                                        
                                                                                        <div className='m-1 p-2'>
                                                                                            <Checkbox inputId={"role" + index} disabled={selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)} name="Reporter" value="Reporter" onChange={() => { onCheckBoxChecked(role.id) }} checked={(selectedObj.roles.includes(role.id) || (selectedObj.disabledRoles && selectedObj.disabledRoles.includes(role.id)))} />
                                                                                            <label htmlFor={"role" + index} className="ml-2">{role.title}</label>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        }

                                                                    })

                                                                    }
                                                                </div>
                                                            </div>}
                                                        </>

                                                    )
                                                }
                                            })

                                            }

                                            <div className="flex justify-content-evenly p-4">

                                                <Button
                                                    label="Save Assignment"

                                                    onClick={saveAssignment}
                                                />
                                            </div>

                                        </div>}


                                </div>
                                <div className='col-6'>
                                    <label className='fw-7 fs-18 clr-navy mb-2'> List of Role Assigned by Location </label>
                                    <div style={{ height: 400, overflow: 'auto' }} >
                                        {allLocationRoles.map((locationRole, index) => {
                                            // Initialize an array to hold the names for each level
                                            let locationNames = [];
                                            const roleNames = locationRole.roles.map(roleId => getRoleNameById(roleId));
                                            console.log(locationRole)
                                            // Function to get name by ID or handle special 'all' cases
                                            const getLocationName = (id, locationArray, allText) => {

                                                if (id === null) return ''; // Return empty if ID is not set
                                                if (id === 0) return allText; // Handle 'all' cases
                                                const location = locationArray.find(location => location.id === id);
                                                return location ? location.name : 'Unknown';
                                            };

                                            // Get names for each level
                                            locationNames.push(getLocationName(locationRole.tier1_id, rawsitelist, 'All Country'));
                                            locationNames.push(getLocationName(locationRole.tier2_id, rawsitelist.flatMap(i => i.locationTwos), 'All Region'));
                                            locationNames.push(getLocationName(locationRole.tier3_id, rawsitelist.flatMap(i => i.locationTwos.flatMap(i => i.locationThrees)), 'All Business Unit'));

                                            // Filter out empty or unknown locations before joining
                                            locationNames = locationNames.filter(name => name && name !== 'Unknown');
                                            if (roleNames.length) {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            {locationNames.join(' > ')} {/* Join names with ' > ' for breadcrumb style display */}
                                                            <ul>
                                                                {roleNames.map((name, roleIndex) => (
                                                                    <li key={roleIndex}>{name}</li> // Render each role name in a bullet point
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <hr />
                                                    </React.Fragment>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>

                        </Dialog>

                    </div>

                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )
}

export default AddClientUser

