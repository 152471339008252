import React, { useRef, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "react-bootstrap";

// Sample data for the chart
const data = [
  {
    year: "2019",
    GeneralTraining: 85,
    HoursTrained: 70,
    CodeOfConduct: 90,
  },
  {
    year: "2020",
    GeneralTraining: 80,
    hoursTrained: 75,
    CodeOfConduct: 85,
  },
  {
    year: "2021",
    GeneralTraining: 82,
    hoursTrained: 68,
    CodeOfConduct: 88,
  },
  {
    year: "2022",
    GeneralTraining: 84,
    hoursTrained: 72,
    CodeOfConduct: 87,
  },
  {
    year: "2023",
    GeneralTraining: 83,
    hoursTrained: 73,
    CodeOfConduct: 89,
  },
];

const GovernanceChart2 = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Self trained and attendance
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              barSize={15}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="GeneralTraining"
                fill="#003f5c"
                name="Self trained (General training which includes safety)"
              />
              <Bar
                dataKey="hoursTrained"
                fill="#ffa600"
                name="number of hours of safety training"
              />

              <Bar
                dataKey="CodeOfConduct"
                fill="#ef5675"
                name="Number of Employees trained on-line in the code of ethics and conduct"
              />

              {/* Different color for total */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="General Training"
              style={{ minWidth: "8%" }}
              field="GeneralTraining"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Hours of safety training"
              field="hoursTrained"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Employees trained of code of conduct"
              field="CodeOfConduct"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default GovernanceChart2;
