import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber"
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { AutoComplete } from "primereact/autocomplete";
import { Tag } from "primereact/tag";
import { SplitButton } from "primereact/splitbutton";
import Axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { ref } from "yup";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { AutoComplete as AC } from "primereact/autocomplete";

import IATACodes from "../../assets/dummy/IATACodes";
import { BgEvent } from "@fullcalendar/core";
const { DateTime } = require('luxon')

const google = window.google;
const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const getID = () => {
    return parseInt(Date.now() * Math.random()) + moment().unix() + randomIntFromInterval(1, 1000)

}
const BGSQ14 = ({ data, getData, setData, edit,rp=false }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QL14DP1: '',
        QL14DP2: '',
        QL14DP3: 0,

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QL14DP1: '',
            QL14DP2: '',
            QL14DP3: 0,
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QL14DP1.trim().length !== 0 && dataVal.QL14DP2.trim().length !== 0 && dataVal.QL14DP3 > 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QL14DP1: '',
                QL14DP2: '',
                QL14DP3: 0,
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}

                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QL14DP1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Description of Main Activity"
                    field="QL14DP1"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QL14DP2"
                    header="Description of Business Activity"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QL14DP3"
                    header="% Turnover"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   
                {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr"> Description of Main Activity</label>
                    <InputTextarea
                        value={dataVal.QL14DP1}
                        onChange={(e) => {
                            updateDataVal("QL14DP1", e.target.value);
                        }}
                        placeholder="description"
                    />
                    {submitted && dataVal.QL14DP1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter description for main activity
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr"> Description of business activity</label>
                    <InputTextarea
                        value={dataVal.QL14DP2}
                        onChange={(e) => {
                            updateDataVal("QL14DP2", e.target.value);
                        }}
                        placeholder="description"
                    />
                    {submitted && dataVal.QL14DP2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter description for business activity
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Turnover in % </label>
                    <InputNumber
                        value={dataVal.QL14DP3}
                        maxFractionDigits={4} useGrouping={false}
                        onChange={(e) => {
                            updateDataVal("QL14DP3", e.value);
                        }}
                        placeholder="Turnover in %"
                    />
                    {submitted && dataVal.QL14DP3 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Turnover is required
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BGSQ15 = ({ data, getData, setData, edit,rp=false  }) => {
    const QL15DP1AOptions = [{ name: 'Product', id: 1 }, { name: 'Service', id: 2 }]
    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QL15DP1A: null,
        QL15DP1B: '',
        QL15DP2: '',
        QL15DP3: 0,

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(local)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QL15DP1A: null,
            QL15DP1B: '',
            QL15DP2: '',
            QL15DP3: 0,
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QL15DP1A !== null && dataVal.QL15DP1B.trim().length !== 0 && dataVal.QL15DP2.trim().length !== 0 && dataVal.QL15DP3 > 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QL15DP1A: null,
                QL15DP1B: '',
                QL15DP2: '',
                QL15DP3: 0,
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QL15DP1B"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Product / Service"

                    body={(rowData) => { console.log(rowData.QL15DP1A); return <>{rowData.QL15DP1A === 1 ? 'Product' : 'Service'} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Name"
                    field="QL15DP1B"
                    body={(rowData) => { return <>{rowData.QL15DP1B} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QL15DP2"
                    header="NIC code"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QL15DP3"
                    header="% Turnover"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}  
                 {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Product / Service</label>
                    <Dropdown
                        value={dataVal.QL15DP1A}
                        onChange={(e) => {
                            updateDataVal("QL15DP1A", e.value);
                        }}
                        options={QL15DP1AOptions}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QL15DP1A === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select Product / Service
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Name</label>
                    <InputText
                        value={dataVal.QL15DP1B}
                        onChange={(e) => {
                            updateDataVal("QL15DP1B", e.target.value);
                        }}
                        placeholder="name"
                    />
                    {submitted && dataVal.QL15DP1B.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">NIC code</label>
                    <InputTextarea
                        value={dataVal.QL15DP2}
                        onChange={(e) => {
                            updateDataVal("QL15DP2", e.target.value);
                        }}
                        placeholder="description"
                    />
                    {submitted && dataVal.QL15DP2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter NIC
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> % Turnover contributed</label>
                    <InputNumber
                        value={dataVal.QL15DP3}
                        maxFractionDigits={4} useGrouping={false}
                        onChange={(e) => {
                            updateDataVal("QL15DP3", e.value);
                        }}
                        placeholder="Turnover in %"
                    />
                    {submitted && dataVal.QL15DP3 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Turnover is required
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BGSQ21 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);
    const QL21DP1AOptions = [{ name: 'Holding', id: 1 }, { name: 'Subsidiary', id: 2 }, { name: 'Associate Companies', id: 3 }, { name: 'Joint Ventures', id: 4 }]
    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QL21DP1A: null,
        QL21DP1B: '',
        QL21DP2: 0,
        QL21DP3: null,

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QL21DP1A: null,
            QL21DP1B: '',
            QL21DP2: 0,
            QL21DP3: null,
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QL21DP1A !== null && dataVal.QL21DP1B.trim().length !== 0 && dataVal.QL21DP2 > 0 && dataVal.QL21DP3 !== null) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QL21DP1A: null,
                QL21DP1B: '',
                QL21DP2: 0,
                QL21DP3: null,
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QL21DP1B"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Type of company"

                    body={(rowData) => { console.log(rowData.QL21DP1A); return <>{[{ name: 'Holding', id: 1 }, { name: 'Subsidiary', id: 2 }, { name: 'Associate Companies', id: 3 }, { name: 'joint ventures', id: 4 }].find((i) => { return i.id === rowData.QL21DP1A }).name} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Name of Company"
                    field="QL21DP1B"
                    body={(rowData) => { return <>{rowData.QL21DP1B} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QL21DP2"
                    header="% of shares"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QL21DP3"
                    header="Partcipated"
                    body={(rowData) => { return <>{rowData.QL21DP3 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />} 
                  {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Type of Company</label>
                    <Dropdown
                        value={dataVal.QL21DP1A}
                        onChange={(e) => {
                            updateDataVal("QL21DP1A", e.value);
                        }}
                        options={QL21DP1AOptions}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QL21DP1A === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select company type
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Name of company</label>
                    <InputText
                        value={dataVal.QL21DP1B}
                        onChange={(e) => {
                            updateDataVal("QL21DP1B", e.target.value);
                        }}
                        placeholder="name"
                    />
                    {submitted && dataVal.QL21DP1B.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">% of shares
                        held by
                        listed
                        entity</label>
                    <InputNumber

                        maxFractionDigits={4} useGrouping={false}
                        value={dataVal.QL21DP2}
                        onChange={(e) => {
                            updateDataVal("QL21DP2", e.value);
                        }}
                        placeholder="% of shares"
                    />
                    {submitted && dataVal.QL21DP2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter % of shares
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno">Does it partcipates in Business responsibility of listed entity</label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QL21DP3Y"} name={'Yes'} value={dataVal.QL21DP3} onChange={(e) => { updateDataVal('QL21DP3', true) }} checked={dataVal.QL21DP3} />
                        <label htmlFor={"QL21DP3Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QL21DP3N"} name={'No'} value={dataVal.QL21DP3} onChange={(e) => { updateDataVal('QL21DP3', false) }} checked={dataVal.QL21DP3 !== null && !dataVal.QL21DP3} />
                        <label htmlFor={"QL21DP3N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QL21DP3 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP1EQ2 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);
    const QLBP1E2D1Options = [{ name: 'Monetary', id: 1 }, { name: 'Non- Monetary', id: 2 }]
    const QLBP1E2D2Options = [{ name: 'Penalty or Fine', id: 1 }, { name: 'Settlement', id: 2 }, { name: 'Compounding fee', id: 3 }]
    const QLBP1E2D8Options = [{ name: 'Imprisonment', id: 1 }, { name: 'Punishment', id: 2 }]
    const QLBP1E2D4Options = [{ name: 'Regulatory', id: 1 }, { name: 'Enforcement agencies', id: 2 }, { name: 'Judicial institutions', id: 3 }, { name: 'NA', id: 4 }]
    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP1E2D1: null,
        QLBP1E2D2: null,
        QLBP1E2D8: null,
        QLBP1E2D3: '',
        QLBP1E2D4: null,
        QLBP1E2D5: '',
        QLBP1E2D6: 0,
        QLBP1E2D7: '',
        QLBP1E2D9: null,
        QLBP1E3D1: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP1E2D2') {
            local['QLBP1E2D8'] = null
        } else if (obj === 'QLBP1E2D8') {
            local['QLBP1E2D2'] = null
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP1E2D1: null,
            QLBP1E2D2: null,
            QLBP1E2D8: null,
            QLBP1E2D3: '',
            QLBP1E2D4: null,
            QLBP1E2D5: '',
            QLBP1E2D6: 0,
            QLBP1E2D7: '',
            QLBP1E2D9: null,
            QLBP1E3D1: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP1E2D1 !== null && ((dataVal.QLBP1E2D1 === 1 && dataVal.QLBP1E2D2 !== null) || (dataVal.QLBP1E2D1 === 2 && dataVal.QLBP1E2D8 !== null)) && dataVal.QLBP1E2D3.trim().length !== 0 && dataVal.QLBP1E2D5.trim().length !== 0 && dataVal.QLBP1E2D4 !== null && dataVal.QLBP1E2D6 >= 0 && dataVal.QLBP1E2D7.trim().length !== 0 && dataVal.QLBP1E2D9 !== null && ((dataVal.QLBP1E2D9 === true && dataVal.QLBP1E3D1.trim().length !== 0) || !dataVal.QLBP1E2D9)) {
            if (!dataVal.QLBP1E2D9) {
                local.QLBP1E3D1 = ''
            }
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP1E2D1: null,
                QLBP1E2D2: null,
                QLBP1E2D8: null,
                QLBP1E2D3: '',
                QLBP1E2D4: null,
                QLBP1E2D5: '',
                QLBP1E2D6: 0,
                QLBP1E2D7: '',
                QLBP1E2D9: null,
                QLBP1E3D1: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            {/* <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["vehno", "vehtype.name"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Types of proceedings "

                    body={(rowData) => { return <>{QLBP1E2D1Options.find((i) => { return i.id === rowData.QLBP1E2D1 }).name} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Types of Monetary  proceedings "
                    field="QLBP1E2D2"
                    body={(rowData) => { return <>{rowData.QLBP1E2D2 === null ? 'NA' : QLBP1E2D2Options.find((i) => { return i.id === rowData.QLBP1E2D2 }).name} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Types of Non- Monetary  proceedings"
                    field="QL21DP1B"
                    body={(rowData) => { return <>{rowData.QLBP1E2D8 === null ? 'NA' : QLBP1E2D8Options.find((i) => { return i.id === rowData.QLBP1E2D8 }).name} </> }}

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP1E2D3"
                    header="NGRBC Principle"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP1E2D4"
                    header="Govt bodies involved"
                    body={(rowData) => { return <>{rowData.QLBP1E2D4 === null ? 'NA' : QLBP1E2D4Options.find((i) => { return i.id === rowData.QLBP1E2D4 }).name} </> }}

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP1E2D5"
                    header="Name of Govt Body Involved"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP1E2D6"
                    header="Amount (in INR)"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP1E2D7"
                    header="Brief of Case"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP1E2D9"
                    header="Has appeal been preferrecd"
                    body={(rowData) => { return <>{rowData.QLBP1E2D9 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />} 
                  {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Types of proceedings </label>
                    <Dropdown
                        value={dataVal.QLBP1E2D1}
                        onChange={(e) => {
                            updateDataVal("QLBP1E2D1", e.value);
                        }}
                        options={QLBP1E2D1Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP1E2D1 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select proceedings type
                        </small>
                    )}
                </div>
                {dataVal.QLBP1E2D1 !== null && dataVal.QLBP1E2D1 === 1 ?

                    <div className="field">
                        <label htmlFor="ecpsngr">Types of Monetary proceedings</label>
                        <Dropdown
                            value={dataVal.QLBP1E2D2}
                            onChange={(e) => {
                                updateDataVal("QLBP1E2D2", e.value);
                            }}
                            options={QLBP1E2D2Options}
                            optionLabel="name"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && dataVal.QLBP1E2D2 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Monetary proceedings type
                            </small>
                        )}
                    </div>
                    : dataVal.QLBP1E2D1 !== null &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Types of Non-Monetary  proceedings </label>
                        <Dropdown
                            value={dataVal.QLBP1E2D8}
                            onChange={(e) => {
                                updateDataVal("QLBP1E2D8", e.value);
                            }}
                            options={QLBP1E2D8Options}
                            optionLabel="name"
                            optionValue="id"
                            placeholder="Select"
                        />
                        {submitted && dataVal.QLBP1E2D8 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Non-Monetary proceedings type
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="ecpsngr">Mention NGRBC Principle</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP1E2D3}
                        onChange={(e) => {
                            updateDataVal("QLBP1E2D3", e.target.value);
                        }}
                        placeholder="NGRBC Principle"
                    />
                    {submitted && dataVal.QLBP1E2D3.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter NGRBC Principle
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Type of Govt bodies involved </label>
                    <Dropdown
                        value={dataVal.QLBP1E2D4}
                        onChange={(e) => {
                            updateDataVal("QLBP1E2D4", e.value);
                        }}
                        options={QLBP1E2D4Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP1E2D4 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select Type of Govt bodies involved
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Name of Regulatory/Enforcement agencies/ Judicial institutions</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP1E2D5}
                        onChange={(e) => {
                            updateDataVal("QLBP1E2D5", e.target.value);
                        }}
                        placeholder="Name"
                    />
                    {submitted && dataVal.QLBP1E2D5.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Amount (In INR)</label>

                    <InputNumber value={dataVal.QLBP1E2D6} onChange={(e) => { updateDataVal("QLBP1E2D6", e.value); }} locale="en-IN" minFractionDigits={2} />

                    {submitted && dataVal.QLBP1E2D6 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Amount
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Brief of Case</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP1E2D7}
                        onChange={(e) => {
                            updateDataVal("QLBP1E2D7", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP1E2D7.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Brief of Case
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno">Has appeal been preferred</label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP1E2D9Y"} name={'Yes'} value={dataVal.QLBP1E2D9} onChange={(e) => { updateDataVal('QLBP1E2D9', true) }} checked={dataVal.QLBP1E2D9} />
                        <label htmlFor={"QLBP1E2D9Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP1E2D9N"} name={'No'} value={dataVal.QLBP1E2D9} onChange={(e) => { updateDataVal('QLBP1E2D9', false) }} checked={dataVal.QLBP1E2D9 !== null && !dataVal.QLBP1E2D9} />
                        <label htmlFor={"QLBP1E2D9N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP1E2D9 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>
                {dataVal.QLBP1E2D9 !== null && dataVal.QLBP1E2D9 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Case Details</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP1E3D1}
                            onChange={(e) => {
                                updateDataVal("QLBP1E3D1", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP1E3D1.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Case Details
                            </small>
                        )}
                    </div>

                }
            </Dialog>

        </div>
    )
}
const BGSQ24 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);
    const QLBG24D2Options = [{ name: 'Risk', id: 1 }, { name: 'Opportunity', id: 2 }]
    const QLBG24D5Options = [{ name: 'Positive', id: 1 }, { name: 'Negative', id: 2 }]

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBG24D1: '',
        QLBG24D2: null,
        QLBG24D3: '',
        QLBG24D4: '',
        QLBG24D5: null

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBG24D2') {
            local['QLBG24D4'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBG24D1: '',
            QLBG24D2: null,
            QLBG24D3: '',
            QLBG24D4: '',
            QLBG24D5: null

        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBG24D2 !== null && dataVal.QLBG24D1.trim().length !== 0 && dataVal.QLBG24D3.trim().length !== 0 && (dataVal.QLBG24D2 === 1 ? dataVal.QLBG24D4.trim().length !== 0 : true) && dataVal.QLBG24D5 !== null) {
            if (!dataVal.QLBG24D2 === 2) {
                local.QLBG24D4 = ''
            }
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBG24D1: '',
                QLBG24D2: null,
                QLBG24D3: '',
                QLBG24D4: '',
                QLBG24D5: null

            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBG24D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Material issue identified"
                    field="QLBG24D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Risk/Opportunity "
                    field="QLBP1E2D2"
                    body={(rowData) => { return <>{rowData.QLBG24D2 === null ? 'NA' : QLBG24D2Options.find((i) => { return i.id === rowData.QLBG24D2 }).name} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Rationale for identifying  risk / opportunity"
                    field="QLBG24D3"


                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBG24D4"
                    header="Approach to adapt or mitigate"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Financial implications"
                    field="QLBP1E2D2"
                    body={(rowData) => { return <>{rowData.QLBG24D5 === null ? 'NA' : QLBG24D5Options.find((i) => { return i.id === rowData.QLBG24D5 }).name} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />} 
                  {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Material issue identified </label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBG24D1}
                        onChange={(e) => {
                            updateDataVal("QLBG24D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBG24D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Material issue identified is required
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="ecpsngr">Indicate whether risk or opportunity </label>
                    <Dropdown
                        value={dataVal.QLBG24D2}
                        onChange={(e) => {
                            updateDataVal("QLBG24D2", e.value);
                        }}
                        options={QLBG24D2Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBG24D2 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select option
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Rationale for identifying  risk / opportunity</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBG24D3}
                        onChange={(e) => {
                            updateDataVal("QLBG24D3", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBG24D3.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Rationale for identifying
                        </small>
                    )}
                </div>
                {dataVal.QLBG24D2 !== null && dataVal.QLBG24D2 === 1 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Approach to adapt or mitigate</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBG24D4}
                            onChange={(e) => {
                                updateDataVal("QLBG24D4", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBG24D4.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Approach to adapt or mitigate
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="ecpsngr">Financial implications of the risk or opportunity (Indicate positive /  negative implications)     </label>
                    <Dropdown
                        value={dataVal.QLBG24D5}
                        onChange={(e) => {
                            updateDataVal("QLBG24D5", e.value);
                        }}
                        options={QLBG24D5Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBG24D5 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Financial implications of the risk or opportunity
                        </small>
                    )}
                </div>
            </Dialog>

        </div>
    )
}
const BP4EQ2 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);
    const QLBP4E2D3Options = [{ name: 'Email', id: 1 }, { name: 'SMS', id: 2 }, { name: 'Newspaper', id: 3 }, { name: 'Pamphlets', id: 4 }, { name: 'Advertisement', id: 5 }, { name: 'Community Meetings', id: 6 }, { name: 'Notice Board', id: 7 }, { name: 'Website', id: 8 }, { name: 'Other', id: 9 }]
    const QLBP4E2D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP4E2D1: '',
        QLBP4E2D2: null,
        QLBP4E2D3: null,
        QLBP4E2D4B: '',
        QLBP4E2D4: null,
        QLBP4E2D5: ''


    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP4E2D4') {
            local['QLBP4E2D4B'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP4E2D1: '',
            QLBP4E2D2: null,
            QLBP4E2D3: null,
            QLBP4E2D4B: '',
            QLBP4E2D4: null,
            QLBP4E2D5: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP4E2D1.trim().length !== 0 && dataVal.QLBP4E2D2 !== null && dataVal.QLBP4E2D3 !== null && dataVal.QLBP4E2D4 !== null && (dataVal.QLBP4E2D4 === 4 ? dataVal.QLBP4E2D4B.trim().length !== 0 : true) && dataVal.QLBP4E2D5.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP4E2D1: '',
                QLBP4E2D2: null,
                QLBP4E2D3: null,
                QLBP4E2D4B: '',
                QLBP4E2D4: null,
                QLBP4E2D5: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP4E2D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Stakeholder Group Name"

                    field="QLBP4E2D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Identified as Vulnerable & Marginalized Group"
                    field="QLBP1E2D2"
                    body={(rowData) => { return <>{rowData.QLBP4E2D2 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Channels of communication "
                    field="QL21DP1B"
                    body={(rowData) => { return <>{rowData.QLBP4E2D3 === null ? 'NA' : QLBP4E2D3Options.find((i) => { return i.id === rowData.QLBP4E2D3 }).name} </> }}

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Frequency of engagement"
                    field="QLBP4E2D4"
                    body={(rowData) => { return <>{rowData.QLBP4E2D4 === null ? 'NA' : rowData.QLBP4E2D4 !== 4 ? QLBP4E2D4Options.find((i) => { return i.id === rowData.QLBP4E2D4 }).name : rowData.QLBP4E2D4B} </> }}

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP4E2D5"
                    header="Purpose & Scope"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />



                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />} 
                  {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Name of Stakeholder Group</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP4E2D1}
                        onChange={(e) => {
                            updateDataVal("QLBP4E2D1", e.target.value);
                        }}
                        placeholder="Name"
                    />
                    {submitted && dataVal.QLBP4E2D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Stakeholder Group
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="vehno">Identified as Vulnerable & Marginalized Group</label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP4E2D2Y"} name={'Yes'} value={dataVal.QLBP4E2D2} onChange={(e) => { updateDataVal('QLBP4E2D2', true) }} checked={dataVal.QLBP4E2D2} />
                        <label htmlFor={"QLBP4E2D2Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP4E2D2N"} name={'No'} value={dataVal.QLBP4E2D2} onChange={(e) => { updateDataVal('QLBP4E2D2', false) }} checked={dataVal.QLBP4E2D2 !== null && !dataVal.QLBP4E2D2} />
                        <label htmlFor={"QLBP4E2D2N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP4E2D2 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>
                {dataVal.QLBP1E2D9 !== null && dataVal.QLBP1E2D9 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Case Details</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP1E3D1}
                            onChange={(e) => {
                                updateDataVal("QLBP1E3D1", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP1E3D1.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Case Details
                            </small>
                        )}
                    </div>

                }

                <div className="field">
                    <label htmlFor="ecpsngr">Channels of communication </label>
                    <Dropdown
                        value={dataVal.QLBP4E2D3}
                        onChange={(e) => {
                            updateDataVal("QLBP4E2D3", e.value);
                        }}
                        options={QLBP4E2D3Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP4E2D3 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select Channels of communication
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Frequency of engagement </label>
                    <Dropdown
                        value={dataVal.QLBP4E2D4}
                        onChange={(e) => {
                            updateDataVal("QLBP4E2D4", e.value);
                        }}
                        options={QLBP4E2D4Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP4E2D4 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select Frequency of engagement
                        </small>
                    )}
                </div>
                {dataVal.QLBP4E2D4 && dataVal.QLBP4E2D4 === 4 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Please specify</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP4E2D4B}
                            onChange={(e) => {
                                updateDataVal("QLBP4E2D4B", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP4E2D4B.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter others
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="ecpsngr">Purpose and scope of engagement include key topics and concerns raised during such engagement</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP4E2D5}
                        onChange={(e) => {
                            updateDataVal("QLBP4E2D5", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP4E2D5.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Purpose and scope
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}

const BP7EQ2 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP7E2D1: '',
        QLBP7E2D2: '',
        QLBP7E2D3: '',

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP7E2D1: '',
            QLBP7E2D2: '',
            QLBP7E2D3: '',
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP7E2D1.trim().length !== 0 && dataVal.QLBP7E2D2.trim().length !== 0 && dataVal.QLBP7E2D3.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP7E2D1: '',
                QLBP7E2D2: '',
                QLBP7E2D3: '',
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP7E2D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Name of authority"
                    field="QLBP7E2D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP7E2D2"
                    header="Brief of the case"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP7E2D3"
                    header="Corrective action taken"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}  
                 {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Name of authority</label>
                    <InputText
                        value={dataVal.QLBP7E2D1}
                        onChange={(e) => {
                            updateDataVal("QLBP7E2D1", e.target.value);
                        }}
                        placeholder="Name"
                    />
                    {submitted && dataVal.QLBP7E2D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter description for main activity
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Brief of the case corrective action taken for underway on any issues related to anti-competitive
                        conduct by the entity, based on adverse orders from regulatory authorities</label>
                    <InputTextarea
                        value={dataVal.QLBP7E2D2}
                        onChange={(e) => {
                            updateDataVal("QLBP7E2D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP7E2D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Brief of the case corrective action
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Corrective action taken for underway on any issues related to anti competitive
                        conduct by the entity, based on adverse orders from regulatory authorities</label>
                    <InputTextarea
                        value={dataVal.QLBP7E2D3}

                        onChange={(e) => {
                            updateDataVal("QLBP7E2D3", e.target.value);
                        }}
                        placeholder="Corrective action taken"
                    />
                    {submitted && dataVal.QLBP7E2D3.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Corrective action taken
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP7LQ1 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP7L1D1: '',
        QLBP7L1D2: '',
        QLBP7L1D3: null,
        QLBP7L1D4B: '',
        QLBP7L1D4: null,
        QLBP7L1D5: ''


    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP7L1D4') {
            local['QLBP7L1D4B'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP7L1D1: '',
            QLBP7L1D2: '',
            QLBP7L1D3: null,
            QLBP7L1D4B: '',
            QLBP7L1D4: null,
            QLBP7L1D5: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP7L1D1.trim().length !== 0 && dataVal.QLBP7L1D2.trim().length !== 0 && dataVal.QLBP7L1D3 !== null && dataVal.QLBP7L1D4 !== null && (dataVal.QLBP7L1D4 === 4 ? dataVal.QLBP7L1D4B.trim().length !== 0 : true)) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP7L1D1: '',
                QLBP7L1D2: '',
                QLBP7L1D3: null,
                QLBP7L1D4B: '',
                QLBP7L1D4: null,
                QLBP7L1D5: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP7L1D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Public policy advocated"

                    field="QLBP7L1D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Method resorted"
                    field="QLBP7L1D2"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Available in public domain"

                    body={(rowData) => { return <>{rowData.QLBP7L1D3 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column

                    header="Frequency of review"
                    field="QLBP4E2D4"
                    body={(rowData) => { return <>{rowData.QLBP7L1D4 === null ? 'NA' : rowData.QLBP7L1D4 !== 4 ? QLBP7L1D4Options.find((i) => { return i.id === rowData.QLBP7L1D4 }).name : rowData.QLBP7L1D4B} </> }}

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP7L1D5"
                    header="Web Link"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />



                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Public policy advocated</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP7L1D1}
                        onChange={(e) => {
                            updateDataVal("QLBP7L1D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP7L1D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Public policy advocated
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Method resorted for such advocacy</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP7L1D2}
                        onChange={(e) => {
                            updateDataVal("QLBP7L1D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP7L1D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter method resorted
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno">Whether information available in public domain </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP7L1D3Y"} name={'Yes'} value={dataVal.QLBP7L1D3} onChange={(e) => { updateDataVal('QLBP7L1D3', true) }} checked={dataVal.QLBP7L1D3} />
                        <label htmlFor={"QLBP7L1D3Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP7L1D3N"} name={'No'} value={dataVal.QLBP7L1D3} onChange={(e) => { updateDataVal('QLBP7L1D3', false) }} checked={dataVal.QLBP7L1D3 !== null && !dataVal.QLBP7L1D3} />
                        <label htmlFor={"QLBP7L1D3N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP7L1D3 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>



                <div className="field">
                    <label htmlFor="ecpsngr">Frequency of Review by Board </label>
                    <Dropdown
                        value={dataVal.QLBP7L1D4}
                        onChange={(e) => {
                            updateDataVal("QLBP7L1D4", e.value);
                        }}
                        options={QLBP7L1D4Options}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP7L1D4 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select Frequency of review
                        </small>
                    )}
                </div>
                {dataVal.QLBP7L1D4 && dataVal.QLBP7L1D4 === 4 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Please specify</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP7L1D4B}
                            onChange={(e) => {
                                updateDataVal("QLBP7L1D4B", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP7L1D4B.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter others
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="ecpsngr">Web Link, if available</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP7L1D5}
                        onChange={(e) => {
                            updateDataVal("QLBP7L1D5", e.target.value);
                        }}

                    />

                </div>

            </Dialog>

        </div>
    )
}
const BP8LQ1 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP8L1D1: '',
        QLBP8L1D2: ''
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP8L1D1: '',
            QLBP8L1D2: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP8L1D2.trim().length !== 0 && dataVal.QLBP8L1D1.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP8L1D1: '',
                QLBP8L1D2: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP8L1D1", "QLBP8L1D2"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Details of negative social impact identified"
                    field="QLBP8L1D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L1D2"
                    header="Corrective action taken"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />


                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Details of negative social impact identified in the Social Impact Assessments</label>
                    <InputTextarea
                        value={dataVal.QLBP8L1D1}
                        onChange={(e) => {
                            updateDataVal("QLBP8L1D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L1D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter negative social impact
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Corrective action taken</label>
                    <InputTextarea
                        value={dataVal.QLBP8L1D2}
                        onChange={(e) => {
                            updateDataVal("QLBP8L1D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L1D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter  corrective action
                        </small>
                    )}
                </div>



            </Dialog>

        </div>
    )
}
const BP8LQ2 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP8L2D1: '',
        QLBP8L2D2: '',
        QLBP8L2D3: 0
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP8L2D1: '',
            QLBP8L2D2: '',
            QLBP8L2D3: 0
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP8L2D1.trim().length !== 0 && dataVal.QLBP8L2D2.trim().length !== 0 && dataVal.QLBP8L2D3 >= 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP8L2D1: '',
                QLBP8L2D2: '',
                QLBP8L2D3: 0
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP8L2D1", "QLBP8L2D2"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="State on CSR projects"
                    field="QLBP8L2D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L2D2"
                    header="Aspirational District  on CSR projects"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L2D3"
                    header="Amount spent (In INR)"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />


                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">State on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies</label>
                    <InputTextarea
                        value={dataVal.QLBP8L2D1}
                        onChange={(e) => {
                            updateDataVal("QLBP8L2D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L2D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Aspirational District   on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies</label>
                    <InputTextarea
                        value={dataVal.QLBP8L2D2}
                        onChange={(e) => {
                            updateDataVal("QLBP8L2D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L2D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Amount spent (In INR) on the Project</label>
                    <InputNumber value={dataVal.QLBP8L2D3} onChange={(e) => { updateDataVal("QLBP8L2D3", e.value); }} locale="en-IN" minFractionDigits={2} />

                    {submitted && dataVal.QLBP8L2D3 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP8LQ4 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP8L4D1: '',
        QLBP8L4D2: null,
        QLBP8L4D3: null,
        QLBP8L4D4: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP8L4D1: '',
            QLBP8L4D2: null,
            QLBP8L4D3: null,
            QLBP8L4D4: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP8L4D1.trim().length !== 0 && dataVal.QLBP8L4D2 !== null && dataVal.QLBP8L4D3 !== null && dataVal.QLBP8L4D4.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP8L4D1: '',
                QLBP8L4D2: null,
                QLBP8L4D3: null,
                QLBP8L4D4: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP8L4D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Intellectual Property based on traditional knowledge"

                    field="QLBP8L4D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column

                    header="Owned/ Acquired "

                    body={(rowData) => { return <>{rowData.QLBP8L4D2 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Benefit shared"

                    body={(rowData) => { return <>{rowData.QLBP8L4D3 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="QLBP8L4D4"
                    header="Basis of calculating benefit share"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />



                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Intellectual Property based on traditional knowledge</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP8L4D1}
                        onChange={(e) => {
                            updateDataVal("QLBP8L4D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L4D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Intellectual Property
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="vehno">Owned/ Acquired  </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8L4D2Y"} name={'Yes'} value={dataVal.QLBP8L4D2} onChange={(e) => { updateDataVal('QLBP8L4D2', true) }} checked={dataVal.QLBP8L4D2} />
                        <label htmlFor={"QLBP8L4D2Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8L4D2N"} name={'No'} value={dataVal.QLBP8L4D2} onChange={(e) => { updateDataVal('QLBP8L4D2', false) }} checked={dataVal.QLBP8L4D2 !== null && !dataVal.QLBP8L4D2} />
                        <label htmlFor={"QLBP8L4D2N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP8L4D2 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno">Benefit shared</label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8L4D3Y"} name={'Yes'} value={dataVal.QLBP8L4D3} onChange={(e) => { updateDataVal('QLBP8L4D3', true) }} checked={dataVal.QLBP8L4D3} />
                        <label htmlFor={"QLBP8L4D3Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8L4D3N"} name={'No'} value={dataVal.QLBP8L4D3} onChange={(e) => { updateDataVal('QLBP8L4D3', false) }} checked={dataVal.QLBP8L4D3 !== null && !dataVal.QLBP8L4D3} />
                        <label htmlFor={"QLBP8L4D3N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP8L4D3 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="ecpsngr">Basis of calculating benefit share</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP8L4D4}
                        onChange={(e) => {
                            updateDataVal("QLBP8L4D4", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L4D4.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter calculating benefit share
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP8LQ5 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP8L5D1: '',
        QLBP8L5D2: '',
        QLBP8L5D3: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP8L5D1: '',
            QLBP8L5D2: '',
            QLBP8L5D3: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP8L5D1.trim().length !== 0 && dataVal.QLBP8L5D2.trim().length !== 0 && dataVal.QLBP8L5D3.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP8L5D1: '',
                QLBP8L5D2: '',
                QLBP8L5D3: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP8L5D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Name of authority"
                    field="QLBP8L5D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L5D2"
                    header="Brief of the case"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L5D3"
                    header="Corrective action taken"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Name of authority</label>
                    <InputText
                        value={dataVal.QLBP8L5D1}
                        onChange={(e) => {
                            updateDataVal("QLBP8L5D1", e.target.value);
                        }}
                        placeholder="Name"
                    />
                    {submitted && dataVal.QLBP8L5D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter name of authority
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Brief of the case of corrective actions taken or underway, based on any adverse order in
                        intellectual property related disputes wherein usage of traditional knowledge is involved</label>
                    <InputTextarea
                        value={dataVal.QLBP8L5D2}
                        onChange={(e) => {
                            updateDataVal("QLBP8L5D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L5D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Brief of the case corrective action
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Corrective taken or underway, based on any adverse order in
                        intellectual property related disputes wherein usage of traditional knowledge is involved</label>
                    <InputTextarea
                        value={dataVal.QLBP8L5D3}

                        onChange={(e) => {
                            updateDataVal("QLBP8L5D3", e.target.value);
                        }}
                        placeholder="Corrective action taken"
                    />
                    {submitted && dataVal.QLBP8L5D3.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Corrective action taken
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP8LQ6 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP8L6D1: '',
        QLBP8L6D2: 0,
        QLBP8L6D3: 0

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP8L6D1: '',
            QLBP8L6D2: 0,
            QLBP8L6D3: 0
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP8L6D1.trim().length !== 0 && dataVal.QLBP8L6D2 > 0 && dataVal.QLBP8L6D3 > 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP8L6D1: '',
                QLBP8L6D2: 0,
                QLBP8L6D3: 0
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP8L6D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Name of CSR Project"
                    field="QLBP8L6D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L6D2"
                    header="No. of persons benefitted from CSR Projects"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP8L6D3"
                    header="% of beneficiaries"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Name of CSR Project</label>
                    <InputText
                        value={dataVal.QLBP8L6D1}
                        onChange={(e) => {
                            updateDataVal("QLBP8L6D1", e.target.value);
                        }}
                        placeholder="Name"
                    />
                    {submitted && dataVal.QLBP8L6D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter name of CSR project
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">No. of persons benefitted from CSR Projects</label>
                    <InputNumber
                        maxFractionDigits={4} useGrouping={false}
                        value={dataVal.QLBP8L6D2}
                        onChange={(e) => {
                            updateDataVal("QLBP8L6D2", e.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8L6D2 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter no of persons
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> % of beneficiaries from vulnerable and marginalized groupsd</label>
                    <InputNumber

                        value={dataVal.QLBP8L6D3}
                        maxFractionDigits={4} useGrouping={false}
                        onChange={(e) => {
                            updateDataVal("QLBP8L6D3", e.value);
                        }}
                        placeholder="% of beneficiaries"
                    />
                    {submitted && dataVal.QLBP8L6D3 <= 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter % of beneficiaries
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP7EQ1B = ({ data, getData, setData, edit,rp=false  }) => {
    const QLBP7E1D1AOptions = [{ name: 'Chambers', id: 1 }, { name: 'Associations', id: 2 }]
    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP7E1D1A: null,
        QLBP7E1D1B: '',
        QLBP7E1D2: ''
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        local[obj] = val;
        console.log(local)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP7E1D1A: null,
            QLBP7E1D1B: '',
            QLBP7E1D2: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP7E1D1A !== null && dataVal.QLBP7E1D1B.trim().length !== 0 && dataVal.QLBP7E1D2.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP7E1D1A: null,
                QLBP7E1D1B: '',
                QLBP7E1D2: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {badata.length < 10 && <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            }
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QL15DP1B"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Type the trade and industry"

                    body={(rowData) => { return <>{rowData.QLBP7E1D1A === 1 ? 'Chambers' : 'Associations'} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Name"
                    field="QLBP7E1D1A"
                    body={(rowData) => { return <>{rowData.QLBP7E1D1B} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP7E1D2"
                    header="Reach of trade & industry"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />


                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Select type of trade and industry</label>
                    <Dropdown
                        value={dataVal.QLBP7E1D1A}
                        onChange={(e) => {
                            updateDataVal("QLBP7E1D1A", e.value);
                        }}
                        options={QLBP7E1D1AOptions}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP7E1D1A === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select type of trade and industry
                        </small>
                    )}
                </div>
                {dataVal.QLBP7E1D1A !== null &&
                    <>
                        <div className="field">
                            <label htmlFor="ecpsngr">{`Name of trade and industry  ${dataVal.QLBP7E1D1A === 1 ? 'Chambers' : 'Associations'}`}</label>
                            <InputText
                                value={dataVal.QLBP7E1D1B}
                                onChange={(e) => {
                                    updateDataVal("QLBP7E1D1B", e.target.value);
                                }}
                                placeholder="name"
                            />
                            {submitted && dataVal.QLBP7E1D1B.trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Enter Name
                                </small>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="ecpsngr"> {`Reach of trade and industry  ${dataVal.QLBP7E1D1A === 1 ? 'Chambers' : 'Associations'} (State/National)`} </label>
                            <InputTextarea
                                value={dataVal.QLBP7E1D2}
                                onChange={(e) => {
                                    updateDataVal("QLBP7E1D2", e.target.value);
                                }}

                            />
                            {submitted && dataVal.QLBP7E1D2.trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    required
                                </small>
                            )}
                        </div>

                    </>}

            </Dialog>

        </div>
    )
}
const BP8EQ1 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);




    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP8E1D1A: '',
        QLBP8E1D1B: '',
        QLBP8E1D1C: null,
        QLBP8E1D1D: null,
        QLBP8E1D1E: null,
        QLBP8E1D1F: ''
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP8E1D1E') {
            local['QLBP8E1D1F'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP8E1D1A: '',
            QLBP8E1D1B: '',
            QLBP8E1D1C: null,
            QLBP8E1D1D: null,
            QLBP8E1D1E: null,
            QLBP8E1D1F: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP8E1D1A.trim().length !== 0 && dataVal.QLBP8E1D1B.trim().length !== 0 && dataVal.QLBP8E1D1C !== null && dataVal.QLBP8E1D1D !== null && dataVal.QLBP8E1D1E !== null && (dataVal.QLBP8E1D1E ? dataVal.QLBP8E1D1F.trim().length !== 0 : true)) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP8E1D1A: '',
                QLBP8E1D1B: '',
                QLBP8E1D1C: null,
                QLBP8E1D1D: null,
                QLBP8E1D1E: null,
                QLBP8E1D1F: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))

        items.QLBP8E1D1C = DateTime.fromISO(items.QLBP8E1D1C, { zone: 'utc' }).toJSDate()

        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP8E1D1A"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Name & brief details of project"

                    field="QLBP8E1D1A"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column

                    header="SIA Notification No."

                    body={(rowData) => { return <>{rowData.QLBP8L4D2 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Date of notification"
                    body={(rowData) => { return <>{formatDate(rowData.QLBP8E1D1C, 'dd-MM-yyyy')} </> }}

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Conducted by independent external agency"

                    body={(rowData) => { return <>{rowData.QLBP8E1D1D === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Results communicated in public domain"

                    body={(rowData) => { return <>{rowData.QLBP8E1D1E === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Relevant Web link"

                    field="QLBP8E1D1F"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />




                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Name & brief details of project</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP8E1D1A}
                        onChange={(e) => {
                            updateDataVal("QLBP8E1D1A", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8E1D1A.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Name & brief details of project
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">SIA Notification No</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP8E1D1B}
                        onChange={(e) => {
                            updateDataVal("QLBP8E1D1B", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP8E1D1B.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter SIA Notification No
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Date of notification</label>
                    <Calendar value={dataVal.QLBP8E1D1C} onChange={(e) => { updateDataVal('QLBP8E1D1C', e.value) }} showIcon view="date" dateFormat="dd-mm-yy" />

                    {submitted && dataVal.QLBP8E1D1C === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select date
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno">Whether conducted by independent external agency </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8E1D1DY"} name={'Yes'} value={dataVal.QLBP8E1D1D} onChange={(e) => { updateDataVal('QLBP8E1D1D', true) }} checked={dataVal.QLBP8E1D1D} />
                        <label htmlFor={"QLBP8E1D1DY"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8E1D1DN"} name={'No'} value={dataVal.QLBP8E1D1D} onChange={(e) => { updateDataVal('QLBP8E1D1D', false) }} checked={dataVal.QLBP8E1D1D !== null && !dataVal.QLBP8E1D1D} />
                        <label htmlFor={"QLBP8E1D1DN"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP8E1D1D === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno">Results communicated in public domain </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8E1D1EY"} name={'Yes'} value={dataVal.QLBP8E1D1E} onChange={(e) => { updateDataVal('QLBP8E1D1E', true) }} checked={dataVal.QLBP8E1D1E} />
                        <label htmlFor={"QLBP8E1D1EY"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP8E1D1EN"} name={'No'} value={dataVal.QLBP8E1D1E} onChange={(e) => { updateDataVal('QLBP8E1D1E', false) }} checked={dataVal.QLBP8E1D1E !== null && !dataVal.QLBP8E1D1E} />
                        <label htmlFor={"QLBP8E1D1EN"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP8E1D1E === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>

                {dataVal.QLBP8E1D1E !== null && dataVal.QLBP8E1D1E &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Relevant Web link</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP8E1D1F}
                            onChange={(e) => {
                                updateDataVal("QLBP8E1D1F", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP8E1D1F.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter relevant link
                            </small>
                        )}
                    </div>
                }
            </Dialog>

        </div>
    )
}
const BP9LQ1 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);




    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP9L1D1: '',
        QLBP9L1D2: null,
        QLBP9L1D3: ''
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP9L1D2') {
            local['QLBP9L1D3'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP9L1D1: '',
            QLBP9L1D2: null,
            QLBP9L1D3: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP9L1D1.trim().length !== 0 && dataVal.QLBP9L1D2 !== null && (dataVal.QLBP9L1D2 ? dataVal.QLBP9L1D3.trim().length !== 0 : true)) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP9L1D1: '',
                QLBP9L1D2: null,
                QLBP9L1D3: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))



        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP9L1D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Information on products and services of the entity"

                    field="QLBP9L1D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />


                <Column

                    header="Availability in public"

                    body={(rowData) => { return <>{rowData.QLBP9L1D2 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column

                    header="Relevant Web link"

                    field="QLBP9L1D3"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />




                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr"> Channels / platforms where information on products and services of the entity can be
                        accessed </label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP9L1D1}
                        onChange={(e) => {
                            updateDataVal("QLBP9L1D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP9L1D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="vehno"> Does information on products and services of the entity is avavilable publically? </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP9L1D2Y"} name={'Yes'} value={dataVal.QLBP9L1D2} onChange={(e) => { updateDataVal('QLBP9L1D2', true) }} checked={dataVal.QLBP9L1D2} />
                        <label htmlFor={"QLBP9L1D2Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP9L1D2N"} name={'No'} value={dataVal.QLBP9L1D2} onChange={(e) => { updateDataVal('QLBP9L1D2', false) }} checked={dataVal.QLBP9L1D2 !== null && !dataVal.QLBP9L1D2} />
                        <label htmlFor={"QLBP9L1D2N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP9L1D2 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>



                {dataVal.QLBP9L1D2 !== null && dataVal.QLBP9L1D2 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Provide web link </label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP9L1D3}
                            onChange={(e) => {
                                updateDataVal("QLBP9L1D3", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP9L1D3.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter web link
                            </small>
                        )}
                    </div>
                }
            </Dialog>

        </div>
    )
}
const BP2LQ1 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const QLBP2LQ1D3AOptions = [{ name: 'Product', id: 1 }, { name: 'Service', id: 2 }]



    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP2LQ1D1: null,
        QLBP2LQ1D2: '',
        QLBP2LQ1D3A: null,
        QLBP2LQ1D3B: '',
        QLBP2LQ1D4: 0,
        QLBP2LQ1D5: null,
        QLBP2LQ1D6A: null,
        QLBP2LQ1D6B: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP2LQ1D6A') {
            local['QLBP2LQ1D6B'] = ''
        }
        if (obj === 'QLBP2LQ1D1') {
            local['QLBP2LQ1D6B'] = ''
            local['QLBP2LQ1D6A'] = null
            local['QLBP2LQ1D3A'] = null
            local['QLBP2LQ1D3B'] = ''
            local['QLBP2LQ1D5'] = null

        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP2LQ1D1: null,
            QLBP2LQ1D2: '',
            QLBP2LQ1D3A: null,
            QLBP2LQ1D3B: '',
            QLBP2LQ1D4: 0,
            QLBP2LQ1D5: null,
            QLBP2LQ1D6A: null,
            QLBP2LQ1D6B: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP2LQ1D1 !== null && !dataVal.QLBP2LQ1D1 || (dataVal.QLBP2LQ1D1 && dataVal.QLBP2LQ1D2.trim().length !== 0 && dataVal.QLBP2LQ1D3A !== null && dataVal.QLBP2LQ1D3B.trim().length !== 0 && dataVal.QLBP2LQ1D4 >= 0 && dataVal.QLBP2LQ1D5 !== null && dataVal.QLBP2LQ1D6A !== null && (dataVal.QLBP2LQ1D6A ? dataVal.QLBP2LQ1D6B.trim().length !== 0 : true))) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP2LQ1D1: null,
                QLBP2LQ1D2: '',
                QLBP2LQ1D3A: null,
                QLBP2LQ1D3B: '',
                QLBP2LQ1D4: 0,
                QLBP2LQ1D5: null,
                QLBP2LQ1D6A: null,
                QLBP2LQ1D6B: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP2LQ1D2", "QLBP2LQ1D3B"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Has the entity conducted Life Cycle Perspective / Assessments (LCA)"

                    body={(rowData) => { return <>{rowData.QLBP2LQ1D1 === true ? 'Yes' : 'No'} </> }}

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP2LQ1D2"
                    header="NIC Code"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP2LQ1D3B"
                    header="Name of product/service"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP2LQ1D4"
                    header="% of total Turnover contributed"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Whether the LCA conducted is by independent external agency"

                    body={(rowData) => { return <>{rowData.QLBP2LQ1D5 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Available in public domain"

                    body={(rowData) => { return <>{rowData.QLBP2LQ1D6A === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="QLBP2LQ1D6B"
                    header="Weblink"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />



                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)</label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP2LQ1D1Y"} name={'Yes'} value={dataVal.QLBP2LQ1D1} onChange={(e) => { updateDataVal('QLBP2LQ1D1', true) }} checked={dataVal.QLBP2LQ1D1} />
                        <label htmlFor={"QLBP2LQ1D1Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP2LQ1D1N"} name={'No'} value={dataVal.QLBP2LQ1D1} onChange={(e) => { updateDataVal('QLBP2LQ1D1', false) }} checked={dataVal.QLBP2LQ1D1 !== null && !dataVal.QLBP2LQ1D1} />
                        <label htmlFor={"QLBP2LQ1D1N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP2LQ1D1 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>
                {dataVal.QLBP2LQ1D1 !== null && dataVal.QLBP2LQ1D1 && <>
                    <div className="field">
                        <label htmlFor="ecpsngr">NIC code</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP2LQ1D2}
                            onChange={(e) => {
                                updateDataVal("QLBP2LQ1D2", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP2LQ1D2.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter NIC Code
                            </small>
                        )}
                    </div>
                    <div className="field">
                        <label htmlFor="ecpsngr">Select type</label>
                        <Dropdown
                            options={QLBP2LQ1D3AOptions}
                            optionValue="id"
                            optionLabel="name"
                            value={dataVal.QLBP2LQ1D3A}
                            onChange={(e) => {
                                updateDataVal("QLBP2LQ1D3A", e.value);
                            }}

                        />
                        {submitted && dataVal.QLBP2LQ1D3A === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select type
                            </small>
                        )}
                    </div>
                    {dataVal.QLBP2LQ1D3A !== null && <div className="field">
                        <label htmlFor="ecpsngr">Enter name of {dataVal.QLBP2LQ1D3A === 1 ? 'Product' : 'Service'}</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP2LQ1D3B}
                            onChange={(e) => {
                                updateDataVal("QLBP2LQ1D3B", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP2LQ1D3B.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter name
                            </small>
                        )}
                    </div>}
                    <div className="field">
                        <label htmlFor="ecpsngr">% of total Turnover contributed</label>
                        <InputNumber
                            maxFractionDigits={4} useGrouping={false}
                            value={dataVal.QLBP2LQ1D4}
                            onChange={(e) => {
                                updateDataVal("QLBP2LQ1D4", e.value);
                            }}

                        />
                        {submitted && dataVal.QLBP2LQ1D4 <= 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter % of total Turnover contributed
                            </small>
                        )}
                    </div>
                    <div className="field">
                        <label htmlFor="vehno">Whether the LCA conducted is by independent external agency</label>
                        <div className="flex align-items-center" style={{ margin: 10 }}>
                            <Checkbox inputId={"QLBP2LQ1D5Y"} name={'Yes'} value={dataVal.QLBP2LQ1D5} onChange={(e) => { updateDataVal('QLBP2LQ1D5', true) }} checked={dataVal.QLBP2LQ1D5} />
                            <label htmlFor={"QLBP2LQ1D5Y"} className="ml-2">Yes</label>
                        </div>
                        <div className="flex align-items-center" style={{ margin: 10 }}>
                            <Checkbox inputId={"QLBP2LQ1D5N"} name={'No'} value={dataVal.QLBP2LQ1D5} onChange={(e) => { updateDataVal('QLBP2LQ1D5', false) }} checked={dataVal.QLBP2LQ1D5 !== null && !dataVal.QLBP2LQ1D5} />
                            <label htmlFor={"QLBP2LQ1D5N"} className="ml-2">No</label>
                        </div>
                        {submitted && dataVal.QLBP2LQ1D5 === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                select yes or no
                            </small>
                        )}
                    </div>

                    <div className="field">
                        <label htmlFor="vehno">Are the results of LCA communicated in the public domain</label>
                        <div className="flex align-items-center" style={{ margin: 10 }}>
                            <Checkbox inputId={"QLBP2LQ1D6AY"} name={'Yes'} value={dataVal.QLBP2LQ1D6A} onChange={(e) => { updateDataVal('QLBP2LQ1D6A', true) }} checked={dataVal.QLBP2LQ1D6A} />
                            <label htmlFor={"QLBP2LQ1D6AY"} className="ml-2">Yes</label>
                        </div>
                        <div className="flex align-items-center" style={{ margin: 10 }}>
                            <Checkbox inputId={"QLBP2LQ1D6AN"} name={'No'} value={dataVal.QLBP2LQ1D6A} onChange={(e) => { updateDataVal('QLBP2LQ1D6A', false) }} checked={dataVal.QLBP2LQ1D6A !== null && !dataVal.QLBP2LQ1D6A} />
                            <label htmlFor={"QLBP2LQ1D6AN"} className="ml-2">No</label>
                        </div>
                        {submitted && dataVal.QLBP2LQ1D6A === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                select yes or no
                            </small>
                        )}
                    </div>


                    {dataVal.QLBP2LQ1D6A && <div className="field">
                        <label htmlFor="ecpsngr">Weblink</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP2LQ1D6B}
                            onChange={(e) => {
                                updateDataVal("QLBP2LQ1D6B", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP2LQ1D6B.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter weblink
                            </small>
                        )}
                    </div>
                    }
                </>}
            </Dialog>

        </div>
    )
}
const BP2LQ2 = ({ data, getData, setData, edit,rp=false  }) => {
    const QLBP2LQ2D1AOptions = [{ name: 'Product', id: 1 }, { name: 'Service', id: 2 }]
    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP2LQ2D1A: null,
        QLBP2LQ2D1B: '',
        QLBP2LQ2D2: '',
        QLBP2LQ2D3: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(local)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP2LQ2D1A: null,
            QLBP2LQ2D1B: '',
            QLBP2LQ2D2: '',
            QLBP2LQ2D3: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP2LQ2D1A !== null && dataVal.QLBP2LQ2D1B.trim().length !== 0 && dataVal.QLBP2LQ2D2.trim().length !== 0 && dataVal.QLBP2LQ2D3.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP2LQ2D1A: null,
                QLBP2LQ2D1B: '',
                QLBP2LQ2D2: '',
                QLBP2LQ2D3: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP2LQ2D1B"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Product / Service"

                    body={(rowData) => { return <>{rowData.QLBP2LQ2D1A === 1 ? 'Product' : 'Service'} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Name"
                    field="QLBP2LQ2D1B"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP2LQ2D2"
                    header="Description of the risk / concern"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP2LQ2D3"
                    header="Action Taken"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Product / Service</label>
                    <Dropdown
                        value={dataVal.QLBP2LQ2D1A}
                        onChange={(e) => {
                            updateDataVal("QLBP2LQ2D1A", e.value);
                        }}
                        options={QLBP2LQ2D1AOptions}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                    />
                    {submitted && dataVal.QLBP2LQ2D1A === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select Product / Service
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Name</label>
                    <InputText
                        value={dataVal.QLBP2LQ2D1B}
                        onChange={(e) => {
                            updateDataVal("QLBP2LQ2D1B", e.target.value);
                        }}
                        placeholder="name"
                    />
                    {submitted && dataVal.QLBP2LQ2D1B.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Name
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Description of the risk / concern</label>
                    <InputTextarea
                        value={dataVal.QLBP2LQ2D2}
                        onChange={(e) => {
                            updateDataVal("QLBP2LQ2D2", e.target.value);
                        }}
                        placeholder="description"
                    />
                    {submitted && dataVal.QLBP2LQ2D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter description
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Action Taken</label>
                    <InputTextarea
                        value={dataVal.QLBP2LQ2D3}
                        onChange={(e) => {
                            updateDataVal("QLBP2LQ2D3", e.target.value);
                        }}
                        placeholder="description"
                    />
                    {submitted && dataVal.QLBP2LQ2D3.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Action taken
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP6EQ10 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const QLBP7L1D4Options = [{ name: 'Annually', id: 1 }, { name: 'Half yearly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Others', id: 4 }]


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP6EQ10D1: '',
        QLBP6EQ10D2: '',
        QLBP6EQ10D3A: null,
        QLBP6EQ10D3B: '',
        QLBP6EQ10D4: ''


    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP7L1D4') {
            local['QLBP7L1D4B'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP6EQ10D1: '',
            QLBP6EQ10D2: '',
            QLBP6EQ10D3A: null,
            QLBP6EQ10D3B: '',
            QLBP6EQ10D4: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP6EQ10D1.trim().length !== 0 && dataVal.QLBP6EQ10D2.trim().length !== 0 && dataVal.QLBP6EQ10D4.trim().length !== 0 && dataVal.QLBP6EQ10D3A !== null && (dataVal.QLBP6EQ10D3A === false ? dataVal.QLBP6EQ10D3B.trim().length !== 0 : true)) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP6EQ10D1: '',
                QLBP6EQ10D2: '',
                QLBP6EQ10D3A: null,
                QLBP6EQ10D3B: '',
                QLBP6EQ10D4: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP6EQ10D1", "QLBP6EQ10D2"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Location of operations/ offices"

                    field="QLBP6EQ10D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Type of operations"
                    field="QLBP6EQ10D2"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Whether the conditions of environmental approval / clearance are complied "

                    body={(rowData) => { return <>{rowData.QLBP6EQ10D3A === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column

                    header="Details of significant direct & indirect impact"
                    field="QLBP6EQ10D4"


                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />




                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Location of operations/ offices</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP6EQ10D1}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ10D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ10D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter location
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="ecpsngr">Type of operations</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP6EQ10D2}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ10D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ10D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter operation type
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno">Whether the conditions of environmental approval / clearance are complied  </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP6EQ10D3AY"} name={'Yes'} value={dataVal.QLBP6EQ10D3A} onChange={(e) => { updateDataVal('QLBP6EQ10D3A', true) }} checked={dataVal.QLBP6EQ10D3A} />
                        <label htmlFor={"QLBP6EQ10D3AY"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP6EQ10D3AN"} name={'No'} value={dataVal.QLBP6EQ10D3A} onChange={(e) => { updateDataVal('QLBP6EQ10D3A', false) }} checked={dataVal.QLBP6EQ10D3A !== null && !dataVal.QLBP6EQ10D3A} />
                        <label htmlFor={"QLBP6EQ10D3AN"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP6EQ10D3A === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>




                {dataVal.QLBP6EQ10D3A !== null && dataVal.QLBP6EQ10D3A === false &&
                    <div className="field">
                        <label htmlFor="ecpsngr">If no, the reasons thereof and corrective action taken</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP6EQ10D3B}
                            onChange={(e) => {
                                updateDataVal("QLBP6EQ10D3B", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP6EQ10D3B.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter reason for no
                            </small>
                        )}
                    </div>
                }
                <div className="field">
                    <label htmlFor="ecpsngr">provide details of significant direct & indirect impact of the entity on
                        biodiversity in such areas along-with prevention and remediation activities</label>
                    <InputTextarea
                        type={'text'}
                        value={dataVal.QLBP6EQ10D4}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ10D4", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ10D4.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            enter details
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const BP6EQ11 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);




    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP6EQ11D1: '',
        QLBP6EQ11D2: '',
        QLBP6EQ11D3: null,
        QLBP6EQ11D4: null,
        QLBP6EQ11D5: null,
        QLBP6EQ11D6: ''
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLBP6EQ11D5') {
            local['QLBP6EQ11D6'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP6EQ11D1: '',
            QLBP6EQ11D2: '',
            QLBP6EQ11D3: null,
            QLBP6EQ11D4: null,
            QLBP6EQ11D5: null,
            QLBP6EQ11D6: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLBP6EQ11D1.trim().length !== 0 && dataVal.QLBP6EQ11D2.trim().length !== 0 && dataVal.QLBP6EQ11D3 !== null && dataVal.QLBP6EQ11D4 !== null && dataVal.QLBP6EQ11D5 !== null && (dataVal.QLBP6EQ11D5 ? dataVal.QLBP6EQ11D6.trim().length !== 0 : true)) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP6EQ11D1: '',
                QLBP6EQ11D2: '',
                QLBP6EQ11D3: null,
                QLBP6EQ11D4: null,
                QLBP6EQ11D5: null,
                QLBP6EQ11D6: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))

        items.QLBP6EQ11D3 = DateTime.fromISO(items.QLBP6EQ11D3, { zone: 'utc' }).toJSDate()

        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP6EQ11D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Name & brief details of project"

                    field="QLBP6EQ11D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                <Column

                    header="EIA Notification No."
                    field="QLBP6EQ11D2"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Date of notification"
                    body={(rowData) => { return <>{formatDate(rowData.QLBP6EQ11D3, 'dd-MM-yyyy')} </> }}

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Whether conducted by independent external agency"

                    body={(rowData) => { return <>{rowData.QLBP6EQ11D4 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Results communicated in public domain"

                    body={(rowData) => { return <>{rowData.QLBP6EQ11D5 === true ? 'Yes' : 'No'} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Relevant Web link"

                    field="QLBP6EQ11D6"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />




                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Name & brief details of project</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP6EQ11D1}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ11D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ11D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Name & brief details of project
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">EIA Notification No</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLBP6EQ11D2}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ11D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ11D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter EIA Notification No
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Date of notification</label>
                    <Calendar value={dataVal.QLBP6EQ11D3} onChange={(e) => { updateDataVal('QLBP6EQ11D3', e.value) }} showIcon view="date" dateFormat="dd-mm-yy" />

                    {submitted && dataVal.QLBP6EQ11D3 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select date
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno">Whether conducted by independent external agency (Yes / No)</label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP6EQ11D4Y"} name={'Yes'} value={dataVal.QLBP6EQ11D4} onChange={(e) => { updateDataVal('QLBP6EQ11D4', true) }} checked={dataVal.QLBP6EQ11D4} />
                        <label htmlFor={"QLBP6EQ11D4Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP6EQ11D4N"} name={'No'} value={dataVal.QLBP6EQ11D4} onChange={(e) => { updateDataVal('QLBP6EQ11D4', false) }} checked={dataVal.QLBP6EQ11D4 !== null && !dataVal.QLBP6EQ11D4} />
                        <label htmlFor={"QLBP6EQ11D4N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP6EQ11D4 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno">Results communicated in public domain </label>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP6EQ11D5Y"} name={'Yes'} value={dataVal.QLBP6EQ11D5} onChange={(e) => { updateDataVal('QLBP6EQ11D5', true) }} checked={dataVal.QLBP6EQ11D5} />
                        <label htmlFor={"QLBP6EQ11D5Y"} className="ml-2">Yes</label>
                    </div>
                    <div className="flex align-items-center" style={{ margin: 10 }}>
                        <Checkbox inputId={"QLBP6EQ11D5N"} name={'No'} value={dataVal.QLBP6EQ11D5} onChange={(e) => { updateDataVal('QLBP6EQ11D5', false) }} checked={dataVal.QLBP6EQ11D5 !== null && !dataVal.QLBP6EQ11D5} />
                        <label htmlFor={"QLBP6EQ11D5N"} className="ml-2">No</label>
                    </div>
                    {submitted && dataVal.QLBP6EQ11D5 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            select yes or no
                        </small>
                    )}
                </div>

                {dataVal.QLBP6EQ11D5 !== null && dataVal.QLBP6EQ11D5 &&
                    <div className="field">
                        <label htmlFor="ecpsngr">Relevant Web link</label>
                        <InputText
                            type={'text'}
                            value={dataVal.QLBP6EQ11D6}
                            onChange={(e) => {
                                updateDataVal("QLBP6EQ11D6", e.target.value);
                            }}

                        />
                        {submitted && dataVal.QLBP6EQ11D6.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter relevant link
                            </small>
                        )}
                    </div>
                }
            </Dialog>

        </div>
    )
}
const BP6EQ12 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP6EQ12D1: '',
        QLBP6EQ12D2: '',
        QLBP6EQ12D3: '',
        QLBP6EQ12D4: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP6EQ12D1: '',
            QLBP6EQ12D2: '',
            QLBP6EQ12D3: '',
            QLBP6EQ12D4: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP6EQ12D1.trim().length !== 0 && dataVal.QLBP6EQ12D2.trim().length !== 0 && dataVal.QLBP6EQ12D3.trim().length !== 0 && dataVal.QLBP6EQ12D4.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP6EQ12D1: '',
                QLBP6EQ12D2: '',
                QLBP6EQ12D3: '',
                QLBP6EQ12D4: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP6EQ12D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Specify the law / regulation / guidelines was not complied with"
                    field="QLBP6EQ12D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP6EQ12D2"
                    header="Provide details of the non-compliance"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP6EQ12D3"
                    header="Any fines / penalties / action taken"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP6EQ12D4"
                    header="Corrective action taken , if any"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Specify the law / regulation / guidelines was not complied with</label>
                    <InputTextarea
                        value={dataVal.QLBP6EQ12D1}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ12D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ12D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Provide details of the non-compliance</label>
                    <InputTextarea
                        value={dataVal.QLBP6EQ12D2}
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ12D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ12D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Provide details of the non-compliance
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Any fines / penalties / action taken by regulatory agencies such as pollution control boards / by courts</label>
                    <InputTextarea
                        value={dataVal.QLBP6EQ12D3}
                        type="text"
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ12D3", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ12D3.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="vehno">Corrective action taken , if any</label>
                    <InputTextarea
                        value={dataVal.QLBP6EQ12D4}
                        type="text"
                        onChange={(e) => {
                            updateDataVal("QLBP6EQ12D4", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6EQ12D4.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>
            </Dialog>

        </div>
    )
}
const BP6LQ6 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);

    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLBP6LQ6D1: '',
        QLBP6LQ6D2: '',
        QLBP6LQ6D3: ''

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLBP6LQ6D1: '',
            QLBP6LQ6D2: '',
            QLBP6LQ6D3: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;
        if (dataVal.QLBP6LQ6D1.trim().length !== 0 && dataVal.QLBP6LQ6D2.trim().length !== 0 && dataVal.QLBP6LQ6D3.trim().length !== 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLBP6LQ6D1: '',
                QLBP6LQ6D2: '',
                QLBP6LQ6D3: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(local2)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLBP6LQ6D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Initiative undertaken"
                    field="QLBP6LQ6D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP6LQ6D2"
                    header="Details of the initiative (Web-link, may be provided along-with summary)"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="QLBP6LQ6D3"
                    header="Outcome of the initiative"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />

                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add"
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Initiative undertaken</label>
                    <InputTextarea
                        value={dataVal.QLBP6LQ6D1}
                        onChange={(e) => {
                            updateDataVal("QLBP6LQ6D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6LQ6D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Details of the initiative (Web-link, may be provided along-with summary)</label>
                    <InputTextarea
                        value={dataVal.QLBP6LQ6D2}
                        onChange={(e) => {
                            updateDataVal("QLBP6LQ6D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6LQ6D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>

                <div className="field">
                    <label htmlFor="vehno"> Outcome of the initiative</label>
                    <InputTextarea
                        value={dataVal.QLBP6LQ6D3}
                        type="text"
                        onChange={(e) => {
                            updateDataVal("QLBP6LQ6D3", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLBP6LQ6D3.trim() === "" && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            required
                        </small>
                    )}
                </div>

            </Dialog>

        </div>
    )
}
const SGXGSQ4 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLSG1G3D1: '',
        QLSG1G3D2: '',
        QLSG1G3D3: 0

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLSG1G3D1: '',
            QLSG1G3D2: '',
            QLSG1G3D3: 0
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLSG1G3D1.trim().length !== 0 && dataVal.QLSG1G3D2.trim().length !== 0 && dataVal.QLSG1G3D3 >= 0) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLSG1G3D1: '',
                QLSG1G3D2: '',
                QLSG1G3D3: 0

            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))


        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLSG1G3D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Disclosure Standard"
                    field="QLSG1G3D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Description of Standard"
                    field="QLSG1G3D2"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Number of Standard"
                    field="QLSG1G3D3"


                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />


                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Disclosure Standard</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLSG1G3D1}
                        onChange={(e) => {
                            updateDataVal("QLSG1G3D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G3D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Standard is required
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="ecpsngr">Description of Standard</label>
                    <InputTextarea

                        value={dataVal.QLSG1G3D2}
                        onChange={(e) => {
                            updateDataVal("QLSG1G3D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G3D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Description is required
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Number of Standard</label>
                    <InputNumber
                        maxFractionDigits={4} useGrouping={false}
                        value={dataVal.QLSG1G3D3}
                        onChange={(e) => {
                            updateDataVal("QLSG1G3D3", e.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G3D3 < 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Number of Standard is required
                        </small>
                    )}
                </div>
            </Dialog>

        </div>
    )
}
const SGXGSQ6 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLSG1G1D1: '',
        QLSG1G1D2: null

    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;

        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLSG1G1D1: '',
            QLSG1G1D2: null
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLSG1G1D1.trim().length !== 0 && dataVal.QLSG1G1D2 !== null) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLSG1G1D1: '',
                QLSG1G1D2: null

            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        items.QLSG1G1D2 = DateTime.fromISO(items.QLSG1G1D2, { zone: 'utc' }).toJSDate()

        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLSG1G1D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Name"
                    field="QLSG1G1D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Validity"

                    body={(rowData) => { return <>{formatDate(rowData.QLSG1G1D2, 'dd-MM-yyyy')} </> }}
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />



                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >




                <div className="field">
                    <label htmlFor="ecpsngr">Name of Certificate</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLSG1G1D1}
                        onChange={(e) => {
                            updateDataVal("QLSG1G1D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G1D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            name is required
                        </small>
                    )}
                </div>


                <div className="field">
                    <label htmlFor="ecpsngr">Validity</label>
                    <Calendar value={dataVal.QLSG1G1D2} onChange={(e) => { updateDataVal('QLSG1G1D2', e.value) }} showIcon view="date" dateFormat="dd-mm-yy" />

                    {submitted && dataVal.QLSG1G1D2 === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select validity
                        </small>
                    )}
                </div>
            </Dialog>

        </div>
    )
}
const SGXGSQ7 = ({ data, getData, setData, edit,rp=false  }) => {

    const [badata, setBAData] = useState(data)
    const [baDialog, setBADialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [bafilter, setBAFilter] = useState(null);


    const QLSG1G2D3Options = [{ name: 'GRI', id: 1 }, { name: 'TFCD', id: 2 }, { name: 'SASB', id: 3 }, { name: 'SGDs', id: 4 }, { name: 'Others', id: 0 }]


    const forceUpdate = useForceUpdate()

    const [dataVal, setDataVal] = useState({
        QLSG1G2D1: '',
        QLSG1G2D2: '',
        QLSG1G2D3A: null,
        QLSG1G2D3B: '',
        QLSG1G2D4: ''
    });


    const updateDataVal = (obj, val) => {
        let local = dataVal;
        if (obj === 'QLSG1G2D3A') {
            local['QLSG1G2D3B'] = ''
        }
        local[obj] = val;
        console.log(obj, val)
        setDataVal(local)
        forceUpdate()
    }

    const openNewMc = () => {
        setDataVal({
            QLSG1G2D1: '',
            QLSG1G2D2: '',
            QLSG1G2D3A: null,
            QLSG1G2D3B: '',
            QLSG1G2D4: ''
        });
        setBADialog(true)
        setSubmitted(false)
    }
    const saveData = () => {
        setSubmitted(true);
        let local = dataVal;
        let local2 = badata;

        if (dataVal.QLSG1G2D1.trim().length !== 0 && dataVal.QLSG1G2D2.trim().length !== 0 && dataVal.QLSG1G2D4.trim().length !== 0 && dataVal.QLSG1G2D3A !== null && (dataVal.QLSG1G2D3A === 0 ? dataVal.QLSG1G2D3B.trim().length !== 0 : true)) {

            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getID();
                local2.push(local);
            }
            setDataVal({
                QLSG1G2D1: '',
                QLSG1G2D2: '',
                QLSG1G2D3A: null,
                QLSG1G2D3B: '',
                QLSG1G2D4: ''
            });
            setBAData(local2)
            forceUpdate()
            setBADialog(false)


        }
        console.log(dataVal)
    }
    const mcheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="navy-button mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewMc}
                    />

                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBAFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const DialogFooter = (
        <>

            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData()
                }}
            />
        </>
    );
    const editMcVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))

        items.QLBP8E1D1C = DateTime.fromISO(items.QLBP8E1D1C, { zone: 'utc' }).toJSDate()

        setDataVal(items)

        setBADialog(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        let loc = badata
        let index = loc.findIndex((k) => { return k.id === item.id })
        loc.splice(index, 1)
        setBAData(loc)
        forceUpdate()
    }
    const actionBodyTemplateMc = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editMcVal(rowData)
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    return (
        <div>

            <DataTable
                value={badata}
                dataKey="id"
                paginator
                scrollable
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilterFields={["QLSG1G2D1"]}
                globalFilter={bafilter}
                emptyMessage="No records found."
                header={edit === 1 && mcheader}
                responsiveLayout="scroll"
            >
                <Column

                    header="Topic"

                    field="QLSG1G2D1"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Metric"

                    field="QLSG1G2D2"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Alignment with frameworks and disclosure practices"

                    body={(rowData) => { return <>{QLSG1G2D3Options.find((i) => { return i.id === rowData.QLSG1G2D3A }).name} </> }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column

                    header="Description"

                    field="QLSG1G2D4"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />


                {rp && <Column
                    field="rp"
                    header="Reporting Period"

                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />}   {edit === 1 && <Column body={actionBodyTemplateMc}> </Column>}

            </DataTable>
            <Dialog
                visible={baDialog}
                style={{
                    width: "450px",
                }}
                header="Add "
                modal
                className="p-fluid"
                footer={DialogFooter}
                onHide={() => { setBADialog(false) }}
            >


                <div className="field">
                    <label htmlFor="ecpsngr">Topic</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLSG1G2D1}
                        onChange={(e) => {
                            updateDataVal("QLSG1G2D1", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G2D1.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Topic
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Metric</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLSG1G2D2}
                        onChange={(e) => {
                            updateDataVal("QLSG1G2D2", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G2D2.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Metric
                        </small>
                    )}
                </div>
                <div className="field">
                    <label htmlFor="ecpsngr">Select Alignment with frameworks and disclosure practices</label>
                    <Dropdown
                        options={QLSG1G2D3Options}
                        optionValue="id"
                        optionLabel="name"
                        value={dataVal.QLSG1G2D3A}
                        onChange={(e) => {
                            updateDataVal("QLSG1G2D3A", e.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G2D3A === null && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Select
                        </small>
                    )}
                </div>
                {dataVal.QLSG1G2D3A !== null && dataVal.QLSG1G2D3A === 0 && <div className="field">
                    <label htmlFor="ecpsngr">Specify Others</label>
                    <InputText
                        type={'text'}
                        value={dataVal.QLSG1G2D3B}
                        onChange={(e) => {
                            updateDataVal("QLSG1G2D3B", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G2D3B.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter others
                        </small>
                    )}
                </div>}
                <div className="field">
                    <label htmlFor="ecpsngr">Description of the extent of application of the framework</label>
                    <InputTextarea
                        type={'text'}
                        value={dataVal.QLSG1G2D4}
                        onChange={(e) => {
                            updateDataVal("QLSG1G2D4", e.target.value);
                        }}

                    />
                    {submitted && dataVal.QLSG1G2D4.trim().length === 0 && (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red",
                            }}
                        >

                            Enter Description
                        </small>
                    )}
                </div>
            </Dialog>

        </div>
    )
}
export { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ2, BP7LQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP7EQ1B, BP8EQ1, BP9LQ1, BP2LQ1, BP2LQ2, BP6EQ10, BP6EQ11, BP6EQ12, BP6LQ6, SGXGSQ4, SGXGSQ6, SGXGSQ7 }