import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const data = [
    { 
        water: 'Water withdrawal excluding non-contact cooling water',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Water withdrawal including non-contact cooling water',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Third party water',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Surface water withdrawal',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Groundwater withdrawal',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Brackish/ seawater withdrawal',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Water discharged',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        water: 'Water consumed',
        '2019': 100,
        '2021': 200,
        '2022': 150,
        '2023': 180,
        '2021-2022': 300,
        '2019-2022': 450,
        'GRI Indicator': 'Some Value'
    },
    // Add more data objects as needed
];

const WaterDataTable = () => {
    const tableStyle = { 
        borderRadius: '10px',
        overflow: 'hidden',
        border: '1px solid #EBEDEF', // Set border color to grey
        fontSize: '16px',
        fontWeight: '400'
    };

    const customWaterCell = (rowData, column) => {
        let padding = 'initial';
        if(rowData.water === 'Third party water' || rowData.water ==='Surface water withdrawal' || rowData.water ==='Groundwater withdrawal') {
            padding = '20px';
        }
        return <td style={{ paddingLeft: padding }}>{rowData.water}</td>;
    };

    return (
        <DataTable value={data} style={tableStyle}>
            <Column field="water" header="Water (million m3)" body={customWaterCell}/>
            <Column field="2019" header="2019" />
            <Column field="2021" header="2021" />
            <Column field="2022" header="2022" />
            <Column field="2023" header="2023" />
            <Column field="2021-2022" header="2021-2022" />
            <Column field="2019-2022" header="2019-2022" />
            <Column field="GRI Indicator" header="GRI Indicator" />
        </DataTable>
    );
}

export default WaterDataTable;
