import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import { motion } from "framer-motion";
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useForceUpdate from 'use-force-update';
import { custompath } from './constants/pathManagement';
export const AppTopbar = (props) => {
    const admin_data = useSelector(state => state.user.admindetail)
    const [rotation, setRotation] = useState(true)
    const SECRET_KEY = "e!sq6kee4token";
    const allRoles = useSelector((state) => state.user.allRoles);
    const history = useHistory()
    const dispatch = useDispatch()
    const crypto = require("crypto-js");
    const location = useLocation()
    const navigate = useHistory()
    const forceUpdate = useForceUpdate()
    const login_data = useSelector((state) => state.user.userdetail)
    const tvsSubAdmin = useSelector((state) => state.user.tvsSubAdmin);

    const [pathname, setPathname] = useState(null)
    useEffect(() => {
        setPathname(location.pathname)

    }, [location.pathname])
    function encryptNumber(number, secret) {
        return crypto.AES.encrypt(number, SECRET_KEY).toString();  // Return IV and encrypted text
    }


    return (
        <div className="layout-topbar justify-content-between">
            <div className='col-8 flex align-items-center '>

                {/* <div className='flex justify-content-center align-items-center' style={{flexDirection:'column'}}>
                    {Object.keys(admin_data).length !== 0 && (admin_data.role === 'clientadmin' || admin_data.role === 'eisqradmin') ?
                        <img id='clientlogo' src={admin_data.information.companylogo} style={{
                            width: '100%', maxWidth: 70, padding: 2

                        }} /> :
                        <img id='clientlogo' src={client_info.information.companylogo} style={{
                            width: '100%', maxWidth: 70, padding: 2

                        }} />


                    }

                    <label className='clr-navy text-bold'> {Object.keys(admin_data).length !== 0 && (admin_data.role === 'clientadmin' || admin_data.role === 'eisqradmin') ? 'ADMINISTRATOR' : 'USER'}</label>
                </div> */}
             
                <div >
                <div className='flex justify-content-end align-items-center mr-3' style={{ flexDirection: 'column' }}>
                    {Object.keys(admin_data).length !== 0 &&
                        <img id='clientlogo' src={admin_data.information.companylogo} style={{
                            width: '100%', maxWidth: 100, padding: 2

                        }} /> 


                    }

                    {/* <label className='clr-navy text-bold'> {Object.keys(login_data).length !== 0 && (login_data.role === 'clientadmin' || login_data.role === 'eisqradmin') ? 'ENTERPRISE ADMIN' : login_data.role === 'consultantadmin' ?  'CONSULTANT' : 'ADMIN'}</label> */}

                </div>

                <>

                    {/* {Object.keys(admin_data).length !== 0 &&
                        <>
                          
                            <button style={{ paddingRight: 40 }} onClick={() => { dispatch(resetLoggedUserDetail()); dispatch(resetOverallPendingData([])); localStorage.clear(); sessionStorage.clear(); history.push('/') }} className='p-link'><i className='pi pi-power-off clr-delete' style={{ fontSize: 20 }} ></i> </button>

                        </>

                    } */}
                </>

            </div>


                {admin_data.role === 'clientadmin' && !tvsSubAdmin && (login_data.role === 'clientadmin' || allRoles.includes(2)) && ((admin_data.access === undefined || admin_data.access === null) || admin_data.access.navigos === true) &&
                    <div className='ml-2 grid m-0 p-0'>
                        <div className='m-2'> <label onClick={() => { navigate.push({ pathname: '/client_home' }) }} onMouseLeave={(e) => { e.target.style.textDecoration = (pathname !== '/client_home' && 'none'); }} onMouseOver={(e) => { e.target.style.textDecoration = 'underline' }} className={(pathname === '/client_home' ? 'clr-navy text-bold  text-link ' : 'bg-white text-none ') + 'fs-16  br-5 p-3 cur-pointer '} >Home</label> </div>
                        <div className='m-2'><label onClick={() => { navigate.push({ pathname: '/report_list' }) }} onMouseLeave={(e) => { e.target.style.textDecoration = (pathname !== '/report_list' && 'none'); }} onMouseOver={(e) => { e.target.style.textDecoration = 'underline' }} className={(pathname === '/report_list' ? 'clr-navy text-bold  text-link ' : 'bg-white text-none ') + 'fs-16  br-5 p-3 cur-pointer'}>Reports & Disclosure</label> </div>
                        <div className='m-2'> <label className='fs-16 br-5 bg-white text-none  p-3 cur-pointer'  onClick={()=>{ 
                            let userLoginPath =  custompath.login === 1 ? 'https://rotaryeng.eisqr.com' : custompath.login === 2 ? 'https://tvsmotor.uat.eisqr.com' :  custompath.login === 3  ? "https://tvsmotor.eisqr.com" : 'https://user.navigos.eisqr.com'  ;
                            console.log(userLoginPath+'redirect', ); 
                           window.open(  userLoginPath+'/redirect?'+encryptNumber(localStorage.getItem('token')))
                            
                        
                                   }}>User Portal</label> <i className='pi pi-external-link' />  </div>
                    </div>
                }
            </div>
            {/* <div className="col-4 flex align-items-center justify-content-center">




            </div> */}

            {/* <div className="col-6 flex align-items-center justify-content-end">
                <Link to={admin_data.role === 'clientadmin' ? "/client/indicators" : '/add_new_client'} style={{ width: 180 }} className="justify-content-center layout-topbar-logo mr-5">
                    <img id='logo' width={'100%'} height={'auto'} src={props.layoutColorMode === 'light' ? require('../src/assets/images/eisqr_logo_final_v1.png').default : require('../src/assets/images/eisqr_logo.png').default} alt="logo" />
           
                </Link>

                {Object.keys(admin_data).length !== 0 &&
                    <>
                       
                        <button style={{paddingRight:40}} onClick={() => { dispatch(resetLoggedUserDetail()); dispatch(resetOverallPendingData([])); localStorage.clear(); sessionStorage.clear(); history.push('/') }} className='p-link'><i className='pi pi-power-off clr-delete' style={{ fontSize: 20 }} ></i> </button>

                    </>

                }
            </div> */}
             <Link to={pathname} style={{ width: 150 }} className="justify-content-center layout-topbar-logo mr-2">
                    <img id='logo' width={'100%'} height={'auto'} src={props.layoutColorMode === 'light' ? require('../src/assets/images/eisqr_logo_final_v1.png').default : require('../src/assets/images/eisqr_logo.png').default} alt="logo" />

                </Link>

            {/* layout-topbar-menu lg:flex origin-top */}

            {/* layout-topbar-menu lg:flex origin-top layout-topbar-menu-mobile-active */}
            <ul style={{ listStyle: 'none' }} className={props.mobileTopbarMenuActive ? 'custom-layout-topbar-menu lg:flex origin-top layout-topbar-menu-mobile-active' : 'layout-topbar-menu lg:flex origin-top d-none'}>
                <li >
                    <button style={{ width: '100%' }} className="p-link layout-topbar-button justify-content-start " >
                        <i className="pi pi-key " style={{ fontSize: 14 }} />
                        <span className='ml-3'>Change Password</span>
                    </button>
                </li>
                <li >

                    <button style={{ width: '100%' }} onClick={() => { dispatch(resetLoggedUserDetail()); dispatch(resetOverallPendingData([])); localStorage.removeItem('token'); sessionStorage.clear(); history.push('/') }} className="p-link layout-topbar-button justify-content-start">
                        <i className="pi pi-sign-out clr-delete" style={{ fontSize: 14 }} />
                        <span className='ml-3'>Logout</span>
                    </button>
                </li>

            </ul>
        </div>
    );
}
