import React, { useState, useEffect } from "react";
import "../reports/toc_style.css";
import { useHeadsObserver } from "./hooks";
import * as XLSX from "xlsx";
import useForceUpdate from "use-force-update";
import $ from "jquery";
import Axios from "axios";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "../../assets/images/eisqr_logo.png";
import { Dropdown } from "primereact/dropdown";
import APIServices from "../../service/APIService";


pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = {
//     Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//       }
// }
const { DateTime } = require("luxon");

window.jQuery = $;
window.$ = $;
const dcf_id = [11, 10, 15, 72, 16, 36];
const CDPReport = () => {
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();
    const [tableData, setTableData] = useState([]);
    const [workbook, setWorkbook] = useState(null);
    const [firstSheet, setFirstSheet] = useState(null);
    const [year, setYear] = useState(2022);
    const [rfData, setRFData] = useState({});
    const admin_data = useSelector((state) => state.user.admindetail);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor);
    const locationList = useSelector((state) => state.sitelist.locationList);
    const siteList = useSelector((state) => state.sitelist.siteList);
    const rflibrary = useSelector((state) => state.library.rf)
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [dcfass, setDCFAss] = useState([]);
    const [dcflist, setDcfList] = useState([]);
    const [response, setResponse] = useState([]);
    const [report, setReport] = useState([]);
    const [reportEF, setReportEF] = useState([]);
    const [dpreport, setDpReport] = useState([]);
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const forceUpdate = useForceUpdate();
    function formatSubscript__(inputString, findArray, replaceArray) {
        let result = [];
        let currentIndex = 0;

        for (let i = 0; i < findArray.length; i++) {
            const findText = findArray[i];
            const replaceValue = replaceArray[i];
            const index = inputString.toLowerCase().indexOf(findText, currentIndex);

            if (index === -1) {
                // If the findText is not found, add the remaining text and break
                result.push(inputString.substring(currentIndex));
                break;
            }

            // Add the text before the found substring
            result.push(inputString.substring(currentIndex, index));

            // Add the subscripted replaceValue as an object
            result.push(...getResult(findText, replaceValue));

            // Update the currentIndex to continue searching
            currentIndex = index + findText.length;
        }

        // Add any remaining text after the last replacement
        if (currentIndex < inputString.length) {
            result.push(inputString.substring(currentIndex));
        }

        // Filter out empty strings
        result = result.filter((item) => item !== "");

        return result;
    }
    const handleNaNAndInfinity = (value) => {
        if (typeof value === 'number' && isFinite(value)) {
            return value.toFixed(0); // Return the original value if it's a valid number
        } else {
            return 0; // Return 0 for NaN or Infinity
        }
    }
    const getDataByDPArray = (dparr, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport))
        let d = 0
        dparr.forEach((dpid) => {
            dpreport_.filter((i) => { return i.dp === dpid }).forEach((k) => {


                if (checkYear(k.rp, yr) !== 0) {
                    d = d + k.value
                }

            })
        })

        return isNaN(d) ? 0 : d.toFixed(0)
    }
    function formatSubscript(inputString, findArray, replaceArray) {
        return inputString;
    }
    function getResult(str, str2) {
        let arr = str.split(str2.toString());
        arr.splice(1, 0, { text: str2, fontSize: 7, baseline: -5 });
        return arr;
    }
    function ulOrOlToPdfMake(element) {
        const result = [];
        const listItems = Array.from(element.querySelectorAll("li"));

        listItems.forEach((li) => {
            const text = li.textContent.trim();
            if (text !== "") {
                result.push({ text });
            }
        });

        return result;
    }
    async function exportTable2Excel(type) {
        let initialData = [
            {
                alignment: "center", // Center the text horizontally
                margin: [0, 250], // Adjust the top margin to vertically center the text
                text: [
                    { text: "CDP REPORT" + "\n", fontSize: 40, color: "#315874", bold: true },
                    { text: "FY " + year + " - " + (year + 1).toString().substr(2, 3) + "\n", fontSize: 20, color: "#315874" },
                    { text: DateTime.local().toFormat("MMMM dd, yyyy"), fontSize: 20, color: "blue" }, // Customize the font size and color
                ],
                pageBreak: "after",
            },
            {
                toc: {
                    id: "sectionHeader",
                    title: { text: "Table of Content", style: "tdHead" },
                },
                pageBreak: "after",
            },
        ],
            data = [];
        const div = document.getElementById("main");
        for (let i = 0; i < div.children.length; i++) {
            if (div.childNodes[i].tagName.toLowerCase() === "sectionheader") {
                data.push({
                    table: {
                        widths: ["*"],
                        body: [[{ tocItem: "sectionHeader", text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]), style: "secHead", border: [false, false, false, false] }]],
                    },
                });
                data.push({
                    text: "", // Empty text

                    margin: [10, 10], // Adjust the margin for horizontal space
                });
            } else if (div.childNodes[i].tagName.toLowerCase() === "sectionheader1") {
                data.push({ text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]), style: "text-under" });
                data.push({
                    text: "", // Empty text

                    margin: [5, 5], // Adjust the margin for horizontal space
                });
            } else {
                if (div.childNodes[i].children.length !== 0) {
                    for (let child = 0; child < div.childNodes[i].children.length; child++) {
                        let tag = div.childNodes[i].childNodes[child].tagName;

                        if (tag) {
                            if (tag === "OL") {
                                data.push({ ol: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "UL") {
                                data.push({ ul: ulOrOlToPdfMake(div.childNodes[i].childNodes[child]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "TABLE") {
                                let content = generatePdfMakeContentFromTable(div.childNodes[i].childNodes[child]);

                                data.push({
                                    table: {
                                        headerRows: 1,
                                        widths: JSON.parse(JSON.stringify(content))[0].map((a, b) => {
                                            return b == 0 ? "*" : "auto";
                                        }),
                                        body: content,
                                        style: "tableStyle",
                                    },
                                });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 10], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "IMG") {
                                data.push({ image: div.childNodes[i].childNodes[child].src });

                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else if (tag === "BR") {
                                let txt = `Definition: Operational Boundaries requires choosing the scope of emissions that will be reported. There are three scopes of emissions that can be reported:
                       \n Scope 1: Direct GHG Emissions from company owned or controlled sources
                       \n Scope 2: Indirect GHG Emissions from purchased electricity or steam.
                       \n According the GHG Protocol Corporate Reporting Standard, Scope 1 and Scope 2 emissions must be reported. Scope 3 emissions are voluntary`;
                                data.push({ text: txt });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            } else {
                                data.push({ text: formatSubscript(div.childNodes[i].childNodes[child].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]) });
                                data.push({
                                    text: "", // Empty text

                                    margin: [0, 5], // Adjust the margin for horizontal space
                                });
                            }
                        }
                    }
                } else {
                    data.push({ text: formatSubscript(div.childNodes[i].textContent, ["tco2e", "n2o", "ch4", "co2"], ["2", "2", "4", "2"]) });
                    data.push({
                        text: "", // Empty text

                        margin: [5, 5], // Adjust the margin for horizontal space
                    });
                }
            }
        }

        data.forEach((k) => {
            if (k.table) {
                if (!haveSameSubarrayLengths(k.table.body)) {
                    console.log(k.table.body);
                }
            }
        });

        let images = {
            clientlogo: document.getElementById("clientlogo").src,
        };

        const header = (currentPage, pageCount, pageSize) => {
            if (currentPage !== 1) {
                return {
                    columns: [
                        {
                            text: `CDP Report FY` + year.toString().substr(2, 3),
                            style: "headerText",
                            margin: [10, 20],
                            fit: [40, 40],
                            alignment: "left",
                        },
                        {
                            image: "clientlogo",
                            fit: [40, 40],
                            margin: [0, 5, 5, 0],
                            alignment: "right",
                        },
                    ],
                    // Add margins to the header
                };
            }
        };
        const documentDefinition = {
            info: {
                title: "CDP Report - " + DateTime.local().toFormat("MMMM dd, yyyy"),
                author: "Navigos",
                subject: "CDP Report",
                keywords: "Dont share unless people within same organization",
                producer: "EiSqr",
            },
            // userPassword: 'Report@',
            // ownerPassword: '123456',
            permissions: {
                printing: "highResolution", //'lowResolution'
                modifying: false,
                copying: false,
                annotating: true,
                fillingForms: true,
                contentAccessibility: true,
                documentAssembly: true,
            },
            pageSize: "A4",
            pageMargins: [30, 70, 30, 30], // [left, top, right, bottom] margins
            header,
            footer: function (currentPage, pageCount) {
                return {
                    text: "Page " + currentPage + " of " + pageCount,
                    alignment: "center",
                    fontSize: 8,
                };
            },
            content: [...initialData, ...data],
            // defaultStyle: {
            //     font: 'Roboto'
            //   },

            images,
            styles: {
                tdHead: {
                    bold: true,
                    alignment: "center",
                    valign: "middle",
                    fillColor: "#315874",
                    color: "white",
                },

                secHead: {
                    bold: true,
                    fillColor: "#315874",
                    alignment: "center",
                    padding: [10, 10],
                    color: "white",
                },
                headerText: {
                    fontSize: 14,
                    bold: true,
                    color: "para", // Text color
                },
                "text-under": {
                    decoration: "underline",
                    color: "#315874",
                    bold: true,
                },
                boldBlue: {
                    color: "#315874",
                    bold: true,
                },
            },
        };
        console.log([...initialData, ...data]);
      
        if (type === 0) {
            const pdf = pdfMake.createPdf(documentDefinition);

            pdf.download('CDPReport.pdf');

        } else {
            pdfMake.createPdf(documentDefinition).open({}, window.open('', '_blank'));

        }
    }
    function haveSameSubarrayLengths(data) {
        if (data.length < 2) {
            // If there are fewer than 2 subarrays, they are considered to have the same length.
            return true;
        }

        const firstSubarrayLength = data[0].length;

        for (let i = 1; i < data.length; i++) {
            if (data[i].length !== firstSubarrayLength) {
                return false;
            }
        }

        return true;
    }
    function generatePdfMakeContentFromTable(table) {
        if (!table) {
            console.error(`Table  not found.`);
            return [];
        }

        let contentArray = [],
            maxCol = 0;
        for (let i = 0; i < table.rows.length; i++) {
            if (maxCol <= table.rows[i].cells.length) {
                maxCol = table.rows[i].cells.length;
            }
        }

        for (let i = 0; i < table.rows.length; i++) {
            const rowArray = [];

            for (let j = 0; j < table.rows[i].cells.length; j++) {
                const cell = table.rows[i].cells[j];
                const colSpan = cell.getAttribute("colspan");
                const rowSpan = cell.getAttribute("rowspan");
                const cellText = cell.textContent.trim();

                const cellObject = { text: cellText };
                cellObject.colSpan = parseInt(colSpan);
                cellObject.rowSpan = parseInt(rowSpan);
                cellObject.style = cell.getAttribute("class");
                rowArray.push(cellObject);
                if (parseInt(colSpan) > 1) {
                    for (let j = 0; j < parseInt(colSpan) - 1; j++) {
                        rowArray.push({});
                    }
                }
            }

            contentArray.push(rowArray);
        }
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                if (contentArray[index - 1]) {
                    contentArray[index - 1].forEach((k, ind) => {
                        if (k.rowSpan) {
                            if (k.rowSpan > 1) {
                                if (k.colSpan === 1) {
                                    i.splice(ind, 0, { text: "", colSpan: k.colSpan, rowSpan: k.rowSpan - 1 });
                                } else {
                                    let newind = ind;
                                    for (let j = 0; j < parseInt(k.colSpan); j++) {
                                        i.splice(newind, 0, {});
                                        newind++;
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < maxCol - Object.keys(i).length; j++) {
                                // i.push({id:1});
                            }
                        }
                    });
                }
            }
        });
        contentArray.forEach((i, index) => {
            if (i.length !== maxCol) {
                let len = Object.keys(i).length;
                for (let j = 0; j < maxCol - len; j++) {
                    i.push({});
                }
            }
        });
        return contentArray;
    }

    const isMergedCell = (merge, rowIndex, colIndex) => {
        return merge.some((range) => rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c);
    };
    const getSum = (subset) => {
        let i = 0;
        subset.forEach((item) => {
            i = i + item[1];
        });
        return i;
    };
    const checkReportingPeriod = (rp, filter) => {
        let count = 0,
            rps = [];
        filter.forEach((item) => {
            if (rp.includes(item)) {
                count = count + 1;
                rps.push(item);
            }
        });
        return { result: count === rp.length, count: count, rps };
    };
    function getObjectsWithEmptyString(arr) {
        return arr
            .map((obj) => {
                const updatedObj = { ...obj };
                Object.keys(updatedObj).forEach((key) => {
                    if (typeof updatedObj[key] === "string" && updatedObj[key].trim() === "") {
                        delete updatedObj[key];
                    }
                });
                return updatedObj;
            })
            .filter((obj) => Object.values(obj).some((value) => value !== ""));
    }
    const getCellColSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.c - range.s.c + 1;
            }
        }
        return 1;
    };

    const getCellRowSpan = (rowIndex, colIndex) => {
        const merge = workbook.Sheets[firstSheet]["!merges"] || [];
        for (const range of merge) {
            if (rowIndex >= range.s.r && rowIndex <= range.e.r && colIndex >= range.s.c && colIndex <= range.e.c) {
                return range.e.r - range.s.r + 1;
            }
        }
        return 1;
    };
    function findValueByKey(object, key) {
        if (key in object) {
            return object[key];
        } else {
            return null; // or any other default value you want to return if the key is not found
        }
    }
    const getLastResponseByRFID = (key, uid) => {
        let locData = JSON.parse(JSON.stringify(rfData));
        let result = findValueByKey(locData, key);
        console.log(key, result)
        if (result) {
            if (result[0].type === 0) {
                if (typeof uid === "string") {
                    let index = result[0].response.findIndex((k) => {
                        return k.name === uid;
                    });
                    if (index !== -1) {
                        if (result[0].response[index].type === 2) {
                            let value_2 = result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
                            return value_2 === null ? '' : result[0].response[index].value.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')

                        } else if (result[0].response[index].type === 6 || result[0].response[index].type === 4) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return rflibrary[rflib].data1[field].values[result[0].response[index].value[0]].label
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value[0] })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        } else if (result[0].response[index].type === 3) {
                            let rflib = rflibrary.findIndex((m) => { return m.id === key })
                            console.log(rflib, key, uid)
                            if (rflib !== -1) {
                                let field = rflibrary[rflib].data1.findIndex((s) => { return s.name === uid })
                                if (field !== -1) {
                                    if (typeof result[0].response[index].value[0] === 'number') {
                                        return null
                                    } else if (typeof result[0].response[index].value[0] === 'string') {

                                        let rgloc = rflibrary[rflib].data1[field].values.findIndex((l) => { return l.value === result[0].response[index].value })
                                        if (rgloc !== -1) {
                                            return rflibrary[rflib].data1[field].values[rgloc].label
                                        }
                                    } else {
                                        return null
                                    }


                                } else {
                                    return 'Field Not Found'
                                }

                            } else {
                                return 'RF Not Found'
                            }
                        }
                        else if (result[0].response[index].type === 9) {
                            return DateTime.fromISO(result[0].response[index].value, { zone: 'utc' }).toFormat('dd-MM-yyyy')
                        } else {
                            return result[0].response[index].value;
                        }

                    } else {
                        return 'NA';
                    }
                } else {
                    let str = "";
                    uid.forEach((id) => {
                        let index = result[0].response.findIndex((k) => {
                            return k.name === id;
                        });
                        if (index !== -1) {

                            str = str + " " + result[0].response[index].value;
                        }
                    });
                    if (str.trim().length !== 0) {
                        return str;
                    } else {
                        return 'NA';
                    }
                }
            } else {
                return result[0].data2.replace(/(<([^>]+)>)/gi, "").replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
            }
        }
        return "";
    };
    const updateDataByYear = (val) => {
        setYear(val);
        forceUpdate();
    };

    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("sectionheader,sectionheader1,sectionheader2")).map((elem) => ({
            id: elem.id,
            text: elem.childNodes[0].textContent.trim(),
            level: Number(elem.nodeName.charAt(13)),
        }));

        let as = [],
            indx = 0;
        elements.forEach((item, ind) => {
            if (item.level === 0) {
                as[indx] = item;
                indx = indx + 1;
            } else if (elements[ind - 1].level === 0) {
                as[indx] = { item: [item], level: 1 };
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            } else {
                as[indx].item.push(item);
                if (elements[ind + 1] !== undefined && elements[ind + 1].level === 0) {
                    indx = indx + 1;
                }
            }
        });

        setHeadings(as);
    }, []);
    const groupArrayObject = (array, obj) => {
        return array.reduce(
            (group, arr) => {
                let key = arr[obj];

                group[key] = group[key] ?? [];

                group[key].push(arr);

                return group;
            },

            {}
        );
    };
    useEffect(() => {
        let gtaString = {
            include: ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"],
        };
        let dcf_list = [],
            dcf_submitted = [],
            locloc = [];
        let category_string = {
            include: [{ relation: "newTopics", scope: { include: [{ relation: "newMetrics", scope: { include: [{ relation: "newDataPoints" }] } }] } }],
        };

        const promise1 = APIServices.get(API.DCF);
        const promise2 = APIServices.get(API.DCF_Submit_UP(admin_data.id));
        const promise3 = APIServices.get(API.QNDP_Report_UP(admin_data.id))
        const promise4 = APIServices.get(API.RF_Submit_UP(admin_data.id));
        const promise5 = APIServices.get(API.AssignDCFUser_UP(admin_data.id));
        Promise.all([promise1, promise2, promise3, promise4, promise5]).then(function (values) {
            setDCFAss(
                values[4].data
                    .filter((k) => {
                        return dcf_id.includes(k.dcfId) && k.type === 0;
                    })
                    .map((k) => {
                        return { dcfId: k.dcfId, site: k.site[0] };
                    })
            );
            dcf_list = values[0].data;
            setDcfList(values[0].data);
            dcf_submitted = values[1].data;
            setDpReport(values[2].data);
            let val = [],
                filterarr = groupArrayObject(values[2].data, "submitId");
            Object.keys(filterarr).forEach((item) => {
                val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], "form_id")) });
            });

            setResponse(val);
            let rfid_group = groupArrayObject(values[3].data, "rfid");
            Object.keys(rfid_group).forEach((key) => {
                rfid_group[key].sort((a, b) => {
                    return b.id - a.id;
                });
            });

            setRFData(rfid_group);
            forceUpdate();
        });
    }, []);
    useEffect(() => {
        if (response.length !== 0) {
            let report_ = renderData(
                { location: { a: { name: "All", id: 0 }, b: { name: "All", id: 0 }, c: { name: "All", id: 0 } }, year: { name: "All", id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year), to: getDateObjectByMonth_Year(3, year + 1) },
                1
            );
            let reportEF_ = renderDataEF(
                { location: { a: { name: "All", id: 0 }, b: { name: "All", id: 0 }, c: { name: "All", id: 0 } }, year: { name: "All", id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year), to: getDateObjectByMonth_Year(3, year + 1) },
                1
            );

            // report_[`${year - 1}`] = renderData({ location: { a: { name: 'All', id: 0 }, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: { name: 'All', id: 0 }, month: null, indicator: { id: 0 }, timeline: [], from: getDateObjectByMonth_Year(4, year - 1), to: getDateObjectByMonth_Year(3, year) }, 1)
            setReport(report_);
            setReportEF(reportEF_);
            forceUpdate();
        }
    }, [response, year]);

    const getScopeData = (year, area) => {
        if (report[year]) {
            return report[year]
                .filter((k) => {
                    return k.scope === area;
                })
                .map((j) => {
                    return j.ghg;
                })
                .reduce((a, b) => {
                    return a + b;
                }, 0)
                .toFixed(2);
        }
        return 0;
    };
    const checkYear = (rp, yr) => {
        let betweenMonths = [];

        let endDate = moment.utc(getDateObjectByMonth_Year(2, yr + 1)).local();
        let startDate = moment.utc(getDateObjectByMonth_Year(2, yr)).local();
        while (startDate.startOf("month") <= endDate.startOf("month")) {
            betweenMonths.push(startDate.format("MM-YYYY"));
            startDate.add(1, "month");
        }
        return betweenMonths.filter((i) => {
            return rp.includes(i);
        }).length === rp.length
            ? 1
            : betweenMonths.filter((i) => {
                return rp.includes(i);
            }).length;
    };
    const getDataByDP = (dpid, yr) => {
        let dpreport_ = JSON.parse(JSON.stringify(dpreport));
        let d = 0;

        dpreport_
            .filter((i) => {
                return i.dp === dpid;
            })
            .forEach((k) => {
                if (checkYear(k.rp, yr) !== 0) {
                    d = d + k.value;
                }
            });

        return d;
    };
    const checkScope = (arr) => {
        let index = dcfass.findIndex((l) => {
            return arr.includes(l.dcfId);
        });
        return index !== -1 ? true : false;
    };
    function concatenateArrayWithAnd(array) {
        if (array.length === 1) {
            return array[0];
        } else if (array.length > 1) {
            const lastElement = array.pop(); // Remove the last element
            return `${array.join(", ")} and ${lastElement}`;
        } else {
            return "NA"; // Return an empty string if the array is empty
        }
    }
    const getMCFuelUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 15;
            })
            .forEach((i, j) => {
                !result.includes(i.mode) && result.push(i.mode);
            });

        return concatenateArrayWithAnd(result);
    };
    const getSCFuelUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 11;
            })
            .forEach((i, j) => {
                !result.includes(i.fuel_type) && result.push(i.fuel_type);
            });
        return concatenateArrayWithAnd(result);
    };
    const getFEGasUsed = () => {
        let result = [];

        report
            .filter((l) => {
                return l.dcfId === 10;
            })
            .forEach((i, j) => {
                result.push(i.gastype);
            });
        return concatenateArrayWithAnd(result);
    };
    function removeDuplicatesByProperties(arr, keys) {
        const seen = new Set();
        return arr.filter((item) => {
            const key = JSON.stringify(keys.map((key) => item[key]));
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }
    const renderFEGas = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 10;
            })
            .forEach((i, j) => {
                result.push({ type: i.gastype, ghg: i.co2e_.toFixed(2) + " kg CO2e/kg" });
            });

        return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
    };
    const renderSCFuel = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 11;
            })
            .forEach((i, j) => {
                result.push({ type: i.fuel_type + "-" + i.unit, co2_: i.co2_.toFixed(2) + " kg CO2e/kg", n2o_: i.n2o_.toFixed(2) + " kg CO2e/kg", ch4_: i.ch4_.toFixed(2) + " kg CO2e/kg" });
            });
        return result.length === 0 ? [{ type: "Not Found", co2_: 0, ch4_: 0, n2o_: 0 }] : removeDuplicatesByProperties(result, ["co2_", "n2o_", "ch4", "unit", "fuel_type"]);
    };

    const renderMCFuel = () => {
        let result = [];

        reportEF
            .filter((l) => {
                return l.dcfId === 15;
            })
            .forEach((i, j) => {
                console.log("i", i);
                result.push({ type: i.mode + " - " + i.fuel_cat, ghg: i.co2e_.toFixed(2) + " kg CO2e /litre" });
            });

        return result.length === 0 ? [{ type: "Not Found", ghg: 0 }] : result;
    };
    const getScopeDataByDCF = (id) => {
        console.log(
            JSON.parse(JSON.stringify(report)).filter((i) => {
                return id.includes(i.dcfId);
            }),
            "report",
            id
        );
        let report_ = JSON.parse(JSON.stringify(report))
            .filter((i) => {
                return id.includes(i.dcfId);
            })
            .map((i) => {
                return i.ghg;
            })
            .reduce((a, b) => {
                return a + b;
            }, 0);
        return report_.toFixed(2);
    };
    const renderEmissionScope1 = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([11])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([15])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([10])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        <tr>
                            <td colspan="1" rowspan="1">
                                Subtotal Scope 1 Emissions
                            </td>
                            <td colspan="1" rowspan="1"></td>
                            <td colSpan="1" className="text-center">
                                {getScopeDataByDCF([10, 11, 15])}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3 = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([36])}
                                </td>
                                <td colSpan="1" className="text-center"></td>
                            </tr>
                        )}
                        <tr>
                            <td colspan="1" rowspan="1">
                                Subtotal Scope 3 Emissions
                            </td>
                            <td colspan="1" rowspan="1"></td>
                            <td colSpan="1" className="text-center">
                                {getScopeDataByDCF([36, 16])}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope1_ = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([11])}
                                </td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([15])}
                                </td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([10])}
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3_ = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16])}
                                </td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([36])}
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope1_1 = (text) => {
        return (
            <>
                {checkScope([11, 10, 15]) && (
                    <>
                        {checkScope([11]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Stationary Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([15]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Mobile Combustion Emission
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([10]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Fugitive Emissions
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionScope3_1 = (text) => {
        return (
            <>
                {checkScope([16, 36]) && (
                    <>
                        {checkScope([16]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 1 - Purchased goods and services
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                        {checkScope([36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Category 6 - Business travel
                                </td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                                <td colspan="1" rowspan="1"></td>
                            </tr>
                        )}
                    </>
                )}
            </>
        );
    };
    const renderEmissionByScopeTable = (text) => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">
                                Scope of Emission
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">
                                tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) && (
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1">
                                        Scope 1- Direct Emissions
                                    </td>
                                    <td colspan="1" rowspan="1">
                                        {getScopeDataByDCF([11, 10, 15])}
                                    </td>
                                </tr>
                            </>
                        )}
                        {checkScope([72]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Scope 2- Indirect Emission
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([72])}
                                </td>
                            </tr>
                        )}
                        {checkScope([16, 36]) && (
                            <tr>
                                <td colspan="1" rowspan="1">
                                    Scope 3- Indirect emissions
                                </td>
                                <td colspan="1" rowspan="1">
                                    {getScopeDataByDCF([16, 36])}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };
    const renderEmissionByCategoryTable = () => {
        return (
            <div class="gridlines-container">
                <table class="gridlines">
                    <tbody>
                        <tr>
                            <td colspan="1" rowspan="1" className="tdHead">
                                Emission Profile
                            </td>
                            <td colspan="1" rowspan="1" className="tdHead">
                                tCO2e
                            </td>
                        </tr>
                        {checkScope([11, 10, 15]) && (
                            <>
                                {checkScope([11]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Stationary Combustion (Fuel Used)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([11])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([15]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Mobile Combustion (Owned Vehicles)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([15])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([10]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Fugitive Emissions (Refrigerants)
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([10])}
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {checkScope([72]) && (
                            <>
                                <tr>
                                    <td colspan="1" rowspan="1">
                                        Emissions from purchased energy (Grid Electricity)
                                    </td>
                                    <td colspan="1" rowspan="1">
                                        {getScopeDataByDCF([72])}
                                    </td>
                                </tr>
                            </>
                        )}
                        {checkScope([16, 36]) && (
                            <>
                                {checkScope([16]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Purchased Goods and Services
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([16])}
                                        </td>
                                    </tr>
                                )}
                                {checkScope([36]) && (
                                    <tr>
                                        <td colspan="1" rowspan="1">
                                            Business Travel
                                        </td>
                                        <td colspan="1" rowspan="1">
                                            {getScopeDataByDCF([36])}
                                        </td>
                                    </tr>
                                )}
                                {/* <tr>
                            <td colspan="1" rowspan="1" >Employee Commute
                            </td>
                            <td colspan="1" rowspan="1" >
                            </td>
                        </tr> */}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };
    const getDataByDCFDPID = (dcfid, dpid, yr) => {
        let response_ = JSON.parse(JSON.stringify(response));
        let d = 0;

        response_.forEach((k) => {
            if (k.dcf === dcfid) {
                if (checkYear(k.rp, yr) !== 0) {
                    let result = k.response.filter((k) => {
                        return k.name === dpid;
                    });
                    if (result.length !== 0) {
                        d = d + parseInt(result[0].value.match(/\d+/)[0]);
                    }
                }
            }
        });

        return d;
    };

    function getDateObjectByMonth_Year(month, year) {
        if (isNaN(month) || isNaN(year)) {
            throw new Error("Invalid month or year");
        }

        const normalizedMonth = Math.max(1, Math.min(12, month));

        const date = DateTime.fromObject({ year, month: normalizedMonth, day: 1 });

        return date.toJSDate();
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {
            return months_[parseInt(rp[0].split("-")[0]) - 1] + "-" + rp[0].split("-")[1].slice(-2);
        } else {
            return months_[parseInt(rp[0].split("-")[0]) - 1] + "-" + rp[0].split("-")[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split("-")[0]) - 1] + "-" + rp[rp.length - 1].split("-")[1].slice(-2);
        }
    };
    const getClassName = (level) => {
        switch (level) {
            case 1:
                return "head1";
            case 2:
                return "head2";
            case 3:
                return "head3";
            default:
                return null;
        }
    };

    const checkSite = (id, filter) => {
        let idlist = [];

        siteList.forEach((country) => {
            if (filter.a.id === 0 || filter.a.id === country.id) {
                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id);
                            }
                        });
                    }
                });
            }
        });

        return idlist.includes(id);
    };
    const groupArrayObject_3_Keys = (array, obj1, obj2, obj3) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];
            let key3 = arr[obj3];

            const key = `${key1}-${key2}-${key3}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {});
    };
    const renderData = (search) => {
        let betweenMonths = [],
            betweenYears = [];
        let monthly_fg = [];

        let endDate = moment.utc(search.to).local();
        let startDate = moment.utc(search.from).local();
        let year = moment(moment.utc()).format("YYYY");

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf("month") <= endDate.startOf("month")) {
                betweenMonths.push(startDate.format("MM-YYYY"));
                !betweenYears.includes(startDate.format("YYYY")) && betweenYears.push(startDate.format("YYYY"));

                startDate.add(1, "month");
            }

            let res = JSON.parse(JSON.stringify(response));

            res.forEach((report, rind) => {
                let sc_total = 0,
                    fg_total = 0;

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => {
                        return loc.id === report.site;
                    })[0].name;
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0285";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let item_class = fg.filter((i) => {
                                    return i.dp === "DPA0287";
                                })[0].value.name;
                                let item_qty = fg.filter((i) => {
                                    return i.dp === "DPA0288";
                                })[0].value;
                                let price_per_item = fg.filter((i) => {
                                    return i.dp === "DPA0289";
                                })[0].value;

                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.item_classification === item_class;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    item_qty *
                                    price_per_item;

                                fg_total = total + fg_total;

                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0290";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let total = 0;

                                let travel_mode = fg.filter((i) => {
                                    return i.dp === "DPA0291";
                                })[0].value.name;
                                let passenger = fg.filter((i) => {
                                    return i.dp === "DPA0292";
                                })[0].value;

                                if (travel_mode.toLowerCase() === "air") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_co2e_mt";
                                        })[0].value * passenger;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "road") {
                                    let veh_cat = fg.filter((i) => {
                                        return i.dp === "DPA0295";
                                    })[0].value.name;
                                    let veh_type = fg.filter((i) => {
                                        return i.dp === "DPA0337";
                                    })[0].value;
                                    let fuel = fg.filter((i) => {
                                        return i.dp === "DPA0338";
                                    })[0].value;
                                    let km = fg.filter((i) => {
                                        return i.dp === "DP_KM";
                                    })[0].value;
                                    if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                                        if (veh_cat.includes("Cars")) {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        } else {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        }
                                    } else {
                                        total =
                                            (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                return k.vehicle_category === veh_cat && k.unit === "passenger.km";
                                            })[0]["co2e_in_kg"] /
                                                1000) *
                                            km *
                                            passenger;
                                    }

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "rail") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_KM";
                                        })[0].value *
                                        passenger *
                                        0.00116;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                }
                            }
                        });
                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            sc_data = [];
                        report.response.forEach((fg, ind) => {
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0130";
                            })[0].value.name;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0131";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0132";
                            })[0].value.name;
                            let consumed = fg.filter((i) => {
                                return i.dp === "DPA0336";
                            })[0].value;
                            let fuel_cat_ind = sc_data.findIndex((k) => {
                                return k.fuel_type === fuel_type;
                            });

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => {
                                    return i.dp === "DPA0134";
                                })[0].value;
                                let cv = fg.filter((i) => {
                                    return i.dp === "DPA0133";
                                })[0].value;
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv;
                                    total = (gj * 0.000004184 * consumed) / 1000;
                                    fg["value"] = ["Emission Factor-", 3.664, " ,EnergyProduced-", 0.000004184];
                                    co2 = 0;
                                    ch4 = 0;
                                    n2o = 0;
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                } else {
                                    total =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2e_in_kg"] /
                                            1000) *
                                        consumed;
                                    co2 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2_in_kg"] /
                                            1000) *
                                        consumed;
                                    ch4 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["ch4_in_kg"] /
                                            1000) *
                                        consumed;
                                    n2o =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["n2o_in_kg"] /
                                            1000) *
                                        consumed;
                                    fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                }
                            } else {
                                total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    consumed;
                                co2 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"] /
                                        1000) *
                                    consumed;
                                ch4 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"] /
                                        1000) *
                                    consumed;
                                n2o =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"] /
                                        1000) *
                                    consumed;
                                fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4 });
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({
                                        scope: 1,
                                        year: moment(date).format("YYYY"),
                                        month: moment(date).format("MMM-YYYY"),
                                        ghg: item.ghg / addedMonth.count,
                                        dcf: report.dcf,
                                        site: report.site,
                                        fuel_type: item.fuel_type,
                                        co2: (item.co2 * 1000) / addedMonth.count,
                                        n2o: (item.n20 * 1000) / addedMonth.count,
                                        ch4: (item.ch4 * 1000) / addedMonth.count,
                                    });
                                } else {
                                    monthly_fg[updateind].co2 = ((item.co2 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].co2;
                                    monthly_fg[updateind].n2o = ((item.n2o * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].n2o;
                                    monthly_fg[updateind].ch4 = ((item.ch4 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].ch4;
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = [];

                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0137";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let gastype = fg.filter((i) => {
                                    return i.dp === "DPA0136";
                                })[0].value.name;
                                let gasrefilled = fg.filter((i) => {
                                    return i.dp === "DPA0138";
                                })[0].value;
                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                        return k.gas_type === gastype;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    gasrefilled;

                                fg_total = total + fg_total;

                                fg["gas"] = gastype;
                                fg["gasfilled"] = gasrefilled;
                                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY");
                                });
                                let monthind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY");
                                });
                                let dcfind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf;
                                });
                                let siteind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                                });

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                                let renewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0156";
                                    })[0].value / report.rp.length;
                                let nonrenewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0157";
                                    })[0].value / report.rp.length;

                                if (yearind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (monthind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (dcfind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (siteind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                }
                            }
                        });
                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData;
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData;

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            mc_data = [];

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => {
                                return i.dp === "DP_MODE";
                            })[0].value
                                ? "by distance"
                                : "by fuel";
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0140";
                            })[0].value;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0139";
                            })[0].value;
                            let fuel = fg.filter((i) => {
                                return i.dp === "DPA0141";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0339";
                            })[0].value;
                            let km = fg.filter((i) => {
                                return i.dp === "DPA0144";
                            })[0].value;
                            let fuel_filled = fg.filter((i) => {
                                return i.dp === "DPA0143";
                            })[0].value;
                            let fuel_cat_ind = mc_data.findIndex((k) => {
                                return k.fuel === fuel;
                            });

                            if (mode === "by distance") {
                                total = ef_by_distance.filter((k) => {
                                    return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name;
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * km, fuel_cat: fuel_cat.name });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            } else {
                                total = ef_by_fuel.filter((k) => {
                                    return k.fuel.trim() === fuel.trim();
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                });

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                    });
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, ghg: item.ghg / addedMonth.count, mode: item.mode, fuel_cat: item.fuel_cat });
                                    }
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                }
            });

            let scope12_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1 || i.scope === 2;
                }),
                "month"
            );
            let scope3_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 3;
                }),
                "month"
            );
            let scope1_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1;
                }),
                "month"
            );
            let scope2_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 2;
                }),
                "month"
            );
            let pie = [];
            monthly_fg
                .filter((i) => {
                    return i.scope === 1;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 2;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 3;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });

            let index1 = pie.findIndex((j) => {
                return j.name === "Scope 1";
            });
            let index2 = pie.findIndex((j) => {
                return j.name === "Scope 2";
            });
            let index3 = pie.findIndex((j) => {
                return j.name === "Scope 3";
            });

            if (index1 === -1) {
                pie.push({ name: "Scope 1", y: 0 });
            }
            if (index2 === -1) {
                pie.push({ name: "Scope 2", y: 0 });
            }
            if (index3 === -1) {
                pie.push({ name: "Scope 3", y: 0 });
            }

            if (scope1_array.length !== 0) {
                let scope1_tier2 = [],
                    final = [];
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });

                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [],
                    final = [];
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [],
                    final = [];
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
        }

        return monthly_fg;
    };
    const renderDataEF = (search) => {
        let betweenMonths = [],
            betweenYears = [];
        let monthly_fg = [];

        let endDate = moment.utc(search.to).local();
        let startDate = moment.utc(search.from).local();
        let year = moment(moment.utc()).format("YYYY");

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf("month") <= endDate.startOf("month")) {
                betweenMonths.push(startDate.format("MM-YYYY"));
                !betweenYears.includes(startDate.format("YYYY")) && betweenYears.push(startDate.format("YYYY"));

                startDate.add(1, "month");
            }

            let res = JSON.parse(JSON.stringify(response));

            res.forEach((report, rind) => {
                let sc_total = 0,
                    fg_total = 0;

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => {
                        return loc.id === report.site;
                    })[0].name;
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0285";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let item_class = fg.filter((i) => {
                                    return i.dp === "DPA0287";
                                })[0].value.name;
                                let item_qty = fg.filter((i) => {
                                    return i.dp === "DPA0288";
                                })[0].value;
                                let price_per_item = fg.filter((i) => {
                                    return i.dp === "DPA0289";
                                })[0].value;

                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.item_classification === item_class;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    item_qty *
                                    price_per_item;

                                fg_total = total + fg_total;

                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {
                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0290";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let total = 0;

                                let travel_mode = fg.filter((i) => {
                                    return i.dp === "DPA0291";
                                })[0].value.name;
                                let passenger = fg.filter((i) => {
                                    return i.dp === "DPA0292";
                                })[0].value;

                                if (travel_mode.toLowerCase() === "air") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_co2e_mt";
                                        })[0].value * passenger;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "road") {
                                    let veh_cat = fg.filter((i) => {
                                        return i.dp === "DPA0295";
                                    })[0].value.name;
                                    let veh_type = fg.filter((i) => {
                                        return i.dp === "DPA0337";
                                    })[0].value;
                                    let fuel = fg.filter((i) => {
                                        return i.dp === "DPA0338";
                                    })[0].value;
                                    let km = fg.filter((i) => {
                                        return i.dp === "DP_KM";
                                    })[0].value;
                                    if (veh_cat.includes("Cars") || veh_cat.includes("Motor")) {
                                        if (veh_cat.includes("Cars")) {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        } else {
                                            total =
                                                (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                    return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === "km";
                                                })[0]["co2e_in_kg"] /
                                                    1000) *
                                                km *
                                                passenger;
                                        }
                                    } else {
                                        total =
                                            (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => {
                                                return k.vehicle_category === veh_cat && k.unit === "passenger.km";
                                            })[0]["co2e_in_kg"] /
                                                1000) *
                                            km *
                                            passenger;
                                    }

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                } else if (travel_mode.toLowerCase() === "rail") {
                                    total =
                                        fg.filter((i) => {
                                            return i.dp === "DP_KM";
                                        })[0].value *
                                        passenger *
                                        0.00116;

                                    let updateind = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode;
                                    });

                                    if (updateind === -1) {
                                        monthly_fg.push({ scope: 3, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode });
                                    } else {
                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                    }

                                    fg_total = total + fg_total;
                                }
                            }
                        });
                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            sc_data = [];
                        report.response.forEach((fg, ind) => {
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0130";
                            })[0].value.name;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0131";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0132";
                            })[0].value.name;
                            let consumed = fg.filter((i) => {
                                return i.dp === "DPA0336";
                            })[0].value;
                            let fuel_cat_ind = sc_data.findIndex((k) => {
                                return k.fuel_type === fuel_type && k.unit === unit;
                            });

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => {
                                    return i.dp === "DPA0134";
                                })[0].value;
                                let cv = fg.filter((i) => {
                                    return i.dp === "DPA0133";
                                })[0].value;
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv;
                                    total = (gj * 0.000004184 * consumed) / 1000;
                                    fg["value"] = ["Emission Factor-", 3.664, " ,EnergyProduced-", 0.000004184];
                                    co2 = 0;
                                    ch4 = 0;
                                    n2o = 0;
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_: 0, n2o_: 0, ch4_: 0 });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                } else {
                                    total =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2e_in_kg"] /
                                            1000) *
                                        consumed;
                                    co2 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["co2_in_kg"] /
                                            1000) *
                                        consumed;
                                    ch4 =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["ch4_in_kg"] /
                                            1000) *
                                        consumed;
                                    n2o =
                                        (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                            return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                        })[0]["n2o_in_kg"] /
                                            1000) *
                                        consumed;
                                    let co2_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"];
                                    let ch4_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"];
                                    let n2o_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"];
                                    fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_type: fuel_type, ghg: total, unit, co2, n2o, ch4, co2_, n2o_, ch4_ });
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                        sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                        sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                        sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                    }
                                }
                            } else {
                                total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    consumed;
                                co2 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["co2_in_kg"] /
                                        1000) *
                                    consumed;
                                ch4 =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["ch4_in_kg"] /
                                        1000) *
                                    consumed;
                                n2o =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                        return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                    })[0]["n2o_in_kg"] /
                                        1000) *
                                    consumed;
                                let co2_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["co2_in_kg"];
                                let ch4_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["ch4_in_kg"];
                                let n2o_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => {
                                    return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit;
                                })[0]["n2o_in_kg"];

                                fg["value"] = ["co2-", co2 / consumed, " ,ch4-", ch4 / consumed, " ,n2o-", n2o / consumed];
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_type: fuel_type, ghg: total, co2, n2o, ch4, unit, co2_, n2o_, ch4_ });
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total;
                                    sc_data[fuel_cat_ind].co2 = sc_data[fuel_cat_ind].co2 + co2;
                                    sc_data[fuel_cat_ind].ch4 = sc_data[fuel_cat_ind].ch4 + ch4;
                                    sc_data[fuel_cat_ind].n2o = sc_data[fuel_cat_ind].n2o + n2o;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.fuel_type === item.fuel_type && i.unit === item.unit;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({
                                        scope: 1,
                                        year: moment(date).format("YYYY"),
                                        month: moment(date).format("MMM-YYYY"),
                                        ghg: item.ghg / addedMonth.count,
                                        dcf: report.dcf,
                                        site: report.site,
                                        fuel_type: item.fuel_type,
                                        unit: item.unit,
                                        co2: (item.co2 * 1000) / addedMonth.count,
                                        n2o: (item.n20 * 1000) / addedMonth.count,
                                        ch4: (item.ch4 * 1000) / addedMonth.count,
                                        co2_: item.co2_,
                                        ch4_: item.ch4_,
                                        n2o_: item.n2o_,
                                    });
                                } else {
                                    monthly_fg[updateind].co2 = ((item.co2 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].co2;
                                    monthly_fg[updateind].n2o = ((item.n2o * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].n2o;
                                    monthly_fg[updateind].ch4 = ((item.ch4 * 1000) / addedMonth.count) * 1000 + monthly_fg[updateind].ch4;
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = [];

                        report.response.forEach((fg, ind) => {
                            let date = fg.filter((i) => {
                                return i.dp === "DPA0137";
                            })[0].value;
                            let yearind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY");
                            });
                            let monthind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY");
                            });
                            let dcfind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf;
                            });
                            let siteind = monthly_fg.findIndex((i) => {
                                return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                            });

                            if (checkReportingPeriod([moment(date).format("MM-YYYY")], betweenMonths).result) {
                                let gastype = fg.filter((i) => {
                                    return i.dp === "DPA0136";
                                })[0].value.name;
                                let gasrefilled = fg.filter((i) => {
                                    return i.dp === "DPA0138";
                                })[0].value;
                                let total =
                                    (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                        return k.gas_type === gastype;
                                    })[0]["co2e_in_kg"] /
                                        1000) *
                                    gasrefilled;
                                let co2e_ = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => {
                                    return k.gas_type === gastype;
                                })[0]["co2e_in_kg"];
                                fg_total = total + fg_total;

                                fg["gas"] = gastype;
                                fg["gasfilled"] = gasrefilled;
                                fg["value"] = ["EmissionFactor-", total / gasrefilled];
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype;
                                });

                                if (updateind === -1) {
                                    monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total, co2e_ });
                                } else {
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total;
                                }
                            }
                        });
                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {
                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY");
                                });
                                let monthind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY");
                                });
                                let dcfind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf;
                                });
                                let siteind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(i, "MM-YYYY").format("YYYY") && i.month === moment(i, "MM-YYYY").format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site;
                                });

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]["kwh_in_tco2e"];

                                let renewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0156";
                                    })[0].value / report.rp.length;
                                let nonrenewable =
                                    report.response[0].filter((i) => {
                                        return i.dp === "DPA0157";
                                    })[0].value / report.rp.length;

                                if (yearind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (monthind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (dcfind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, co2e_: ef, dcf: report.dcf, site: report.site, renewable, nonrenewable });
                                } else if (siteind === -1) {
                                    monthly_fg.push({ scope: 2, year: moment(i, "MM-YYYY").format("YYYY"), month: moment(i, "MM-YYYY").format("MMM-YYYY"), ghg: nonrenewable * ef, dcf: report.dcf, co2e_: ef, site: report.site, renewable, nonrenewable });
                                }
                            }
                        });
                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths);

                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData;
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData;

                        let total = 0,
                            co2 = 0,
                            ch4 = 0,
                            n2o = 0,
                            mc_data = [];

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => {
                                return i.dp === "DP_MODE";
                            })[0].value
                                ? "by distance"
                                : "by fuel";
                            let fuel_cat = fg.filter((i) => {
                                return i.dp === "DPA0140";
                            })[0].value;
                            let fuel_type = fg.filter((i) => {
                                return i.dp === "DPA0139";
                            })[0].value;
                            let fuel = fg.filter((i) => {
                                return i.dp === "DPA0141";
                            })[0].value.name;
                            let unit = fg.filter((i) => {
                                return i.dp === "DPA0339";
                            })[0].value;
                            let km = fg.filter((i) => {
                                return i.dp === "DPA0144";
                            })[0].value;
                            let fuel_filled = fg.filter((i) => {
                                return i.dp === "DPA0143";
                            })[0].value;
                            let fuel_cat_ind = mc_data.findIndex((k) => {
                                return k.fuel === fuel;
                            });

                            if (mode === "by distance") {
                                total = ef_by_distance.filter((k) => {
                                    return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name;
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * km, co2e_: total, fuel_cat: fuel_cat.name });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            } else {
                                total = ef_by_fuel.filter((k) => {
                                    return k.fuel.trim() === fuel.trim();
                                })[0]["co2e_in_kg"];

                                if (fuel_cat_ind === -1) {
                                    mc_data.push({ mode: fuel, ghg: total * fuel_filled, co2e_: total, fuel_cat: "" });
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total;
                                }
                            }
                        });
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, "MM-YYYY").toDate();

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => {
                                    return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                });

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => {
                                        return i.year === moment(date).format("YYYY") && i.month === moment(date).format("MMM-YYYY") && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode;
                                    });
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = item.ghg / addedMonth.count + monthly_fg[updateind2].ghg;
                                        // monthly_fg[updateind2]['fuel_cat'] = item.fuel_cat
                                    } else {
                                        console.log("EF", item);
                                        monthly_fg.push({ scope: 1, year: moment(date).format("YYYY"), month: moment(date).format("MMM-YYYY"), ghg: 0, dcf: report.dcf, site: report.site, ghg: item.ghg / addedMonth.count, mode: item.mode, fuel_cat: item.fuel_cat, co2e_: item.co2e_ });
                                    }
                                } else {
                                    // monthly_fg[updateind]['fuel_cat'] = item.fuel_cat
                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + item.ghg / addedMonth.count;
                                }
                            });
                        });
                    }
                }
            });

            let scope12_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1 || i.scope === 2;
                }),
                "month"
            );
            let scope3_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 3;
                }),
                "month"
            );
            let scope1_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 1;
                }),
                "month"
            );
            let scope2_array = groupArrayObject(
                monthly_fg.filter((i) => {
                    return i.scope === 2;
                }),
                "month"
            );
            let pie = [];
            monthly_fg
                .filter((i) => {
                    return i.scope === 1;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 2;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });
            monthly_fg
                .filter((i) => {
                    return i.scope === 3;
                })
                .forEach((item) => {
                    let index = pie.findIndex((j) => {
                        return j.name === "Scope " + item.scope;
                    });
                    if (index === -1) {
                        pie.push({ name: "Scope " + item.scope, y: item.ghg });
                    } else {
                        pie[index].y = pie[index].y + item.ghg;
                    }
                });

            let index1 = pie.findIndex((j) => {
                return j.name === "Scope 1";
            });
            let index2 = pie.findIndex((j) => {
                return j.name === "Scope 2";
            });
            let index3 = pie.findIndex((j) => {
                return j.name === "Scope 3";
            });

            if (index1 === -1) {
                pie.push({ name: "Scope 1", y: 0 });
            }
            if (index2 === -1) {
                pie.push({ name: "Scope 2", y: 0 });
            }
            if (index3 === -1) {
                pie.push({ name: "Scope 3", y: 0 });
            }

            if (scope1_array.length !== 0) {
                let scope1_tier2 = [],
                    final = [];
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });

                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [],
                    final = [];
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [],
                    final = [];
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf;
                        item.dcf =
                            dcflist.filter((l) => {
                                return l.id === item.dcf;
                            }).length === 0
                                ? item.dcf
                                : dcflist.filter((l) => {
                                    return l.id === item.dcf;
                                })[0].title;
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: "red",
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    });
                });
                betweenYears.forEach((j) => {
                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => {
                        i.subset.sort((a, b) => {
                            return moment(a[0], "MMM-YYYY").toDate() - moment(b[0], "MMM-YYYY").toDate();
                        });
                        i.subset = i.subset.filter((k) => {
                            return k[0].split("-")[1] === j;
                        });
                        i.y = i.subset.length === 0 ? 0 : getSum(i.subset);
                        i.incomplete = false;
                        return i;
                    });

                    final.push(dataset);
                });
            }
        }

        return monthly_fg;
    };
    const showSite = () => {
        let site_ids = [],
            site_names = [];
        dcfass.forEach((i) => {
            !site_ids.includes(i.site) && site_ids.push(i.site);
        });

        site_ids.forEach((i, j) => {
            site_names.push(
                locationList.find((k) => {
                    return k.id === i;
                }).title
            );
        });

        return site_names;
    };
    return (
        <div>
            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="col-3 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                    <nav>
                        {headings.map((heading, ind) => {
                            let indexes = [];
                            return (
                                <>
                                    {heading.level === 0 ? (
                                        <label key={heading.id} style={{ display: "flex", margin: 5, fontWeight: activeId === heading.id ? "bold" : "normal", textDecoration: heading.text.includes("SECTION") && "underline" }} className={getClassName(heading.level)}>
                                            <a
                                                href={`#${heading.id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.querySelector(`#${heading.id}`).scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "start",
                                                        inline: "nearest",
                                                    });
                                                }}
                                                style={{
                                                    fontWeight: activeId === heading.id ? "bold" : "normal",
                                                }}
                                            >
                                                {heading.text}
                                            </a>
                                        </label>
                                    ) : (
                                        <ul>
                                            {heading.item.map((item, ind2) => {
                                                return (
                                                    <li key={item.id} className={getClassName(item.level)}>
                                                        <a
                                                            href={`#${item.id}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                document.querySelector(`#${item.id}`).scrollIntoView({
                                                                    behavior: "smooth",
                                                                });
                                                            }}
                                                            style={{
                                                                fontWeight: activeId === item.id ? "bold" : "normal",
                                                            }}
                                                        >
                                                            {item.text}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </>
                            );
                        })}
                    </nav>
                </div>
                <div className="col-9 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll", color: "white" }}>
                <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end' }} >

<Button icon='pi pi-eye' style={{ marginRight: 10 }} rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(1) }}> </Button>

<Button icon='pi pi-cloud-download' rounded text raised aria-label="Filter" onClick={() => { exportTable2Excel(0) }}> </Button>



</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <img id='ass' src='https://mpower-s3.s3-ap-southeast-1.amazonaws.com/uploads/hazards/1690426362525Pushing%20and%20Pulling.png' width={'100%'} /> */}
                        <div>
                            <div className="col-12 grid" style={{ margin: 5, color: "white" }}>
                                <div>
                                    <label
                                        style={{
                                            color: "black",
                                            padding: 15,
                                            justifyContent: "flex-start",
                                            display: "flex",
                                        }}
                                    >
                                        Select Year :
                                    </label>
                                </div>

                                <div className="col-4">
                                    <Dropdown options={[{ name: 2022 }, { name: 2023 }]} value={year} optionLabel="name" optionValue="name" onChange={(e) => { setYear(e.value) }} />
                                </div>
                            </div>

                        </div>
                        <div id="main" className="cdp" style={{ flexDirection: "column", display: "flex" }}>
                            {/* C0 Introduction */}

                            <sectionheader id={"c0i"} className="secHead">
                                C0 Introduction
                            </sectionheader>

                            <sectionheader1 id={"introduction"}>Introduction</sectionheader1>
                            <div className="m-3 para">(C0.1) Give a general description and introduction to your organization.</div>
                            <div className="m-3 para">(C0.2) State the start and end date of the year for which you are reporting data and indicate whether you will be providing emissions data
                                for past reporting years.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Start date
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                End date
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Indicate if you are providing
                                                emissions data for past
                                                reporting years
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Select the number of past
                                                reporting years you will be
                                                providing Scope 1 emissions
                                                data for*
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Select the number of past
                                                reporting years you will be
                                                providing Scope 2 emissions
                                                data for*
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Select the number of past
                                                reporting years you will be
                                                providing Scope 3 emissions
                                                data for*
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C0.3) Select the countries/areas in which you operate.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Country/area
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C0.4) Select the currency used for all financial information disclosed throughout your response.
                                Response options
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Currency
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C0.5) Select the option that describes the reporting  boundary for which climate-related impacts on your business are being reported.  Note that this option should align with your chosen approach for consolidating your GHG inventory.
                            </div>

                            <div className="m-3 para">
                                (C0.8) Does your organization have an ISIN code or another unique identifier (e.g., Ticker, CUSIP, etc.)?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Indicate whether you are able to provide a unique identifier for your organization
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Provide your unique identifier*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            {/* C1 Governance */}

                            <sectionheader id={"c1g"} className="secHead">
                                C1 Governance
                            </sectionheader>

                            <sectionheader1 id={"board"}>Board oversight</sectionheader1>
                            <div className="m-3 para">(C1.1) Is there board-level oversight of climate-related issues within your organization?</div>
                            <div className="m-3 para"> (C1.1a) Identify the position(s) (do not include any names) of the individual(s) on the board with responsibility for climate-related issues.</div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Position of individual or committee
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Responsibilities for climate-related issues
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C1.1b) Provide further details on the board’s oversight of climate-related issues.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Frequency with which climate-related issues are a scheduled agenda item
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Governance mechanisms into which climate-related issues are integrated
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                [FINANCIAL SERVICES ONLY] Scope of board-level oversight
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C1.1c) Why is there no board-level oversight of climate-related issues and what are your plans to change this in the future?</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Primary reason
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Board-level oversight of climate-related issues will be introduced within the next two years.
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>


                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                            <div className="m-3 para">  (C1.1d) Does your organization have at least one board member with competence on climate-related issues?</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Board member(s) have competence on climate-related issues
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Criteria used to assess competence of board member(s) on climate-related issues*
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Primary reason for no board-level competence on climate-related issues
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Explain why your organization does not have at least one board member with competence on climate-related issues and any plans to address board-level competence in the future*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"management"}>Management responsibility</sectionheader1>

                            <div className="m-3 para"> (C1.2) Provide the highest management-level position(s) or committee(s) with responsibility for climate-related issues.</div>

                            <div className="m-3 para">Response options</div>
                            <div className="m-3 para">Please complete the following table. You are able to add rows by using the “Add Row” button at the bottom of the table.  </div>



                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Position or committee
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Climate-related responsibilities of this position
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                [FINANCIAL SERVICES ONLY] Coverage of responsibilities
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Reporting line
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Frequency of reporting to the board on climate-related issues via this reporting line
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"employeei"}>Employee incentives</sectionheader1>

                            <div className="m-3 para">(C1.3) Do you provide incentives for the management of climate-related issues, including the attainment of targets?</div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Provide incentives for the management of climate-related issues	Comment
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C1.3a) Provide further details on the incentives provided for the management of climate-related issues (do not include the names of individuals).</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Entitled to incentive
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Type of incentive
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Incentive(s)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Performance indicator(s)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Incentive plan(s) this incentive is linked to
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Further details of incentive(s)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Entitled to incentive	Type of incentive	Incentive(s)	Performance indicator(s)	Incentive plan(s) this incentive is linked to	Further details of incentive(s)	Explain how this incentive contributes to the implementation of your organization’s climate commitments and/or climate transition plan
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            {/* C2 Risks and opportunities */}

                            <sectionheader id={"c2r"} className="secHead">
                                C2 Risks and opportunities
                            </sectionheader>
                            <sectionheader1 id={"managementp"}>Management processes</sectionheader1>

                            <div className="m-3 para">(C2.1) Does your organization have a process for identifying, assessing, and responding to climate-related risks and opportunities?

                            </div>

                            <div className="m-3 para">(C2.1a) How does your organization define short-, medium- and long-term time horizons?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Time horizon
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                From (years)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                To (years)
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C2.1b) How does your organization define substantive financial or strategic impact on your business? </div>

                            <div className="m-3 para"> (C2.2) Describe your process (es) for identifying, assessing and responding to climate-related risks and opportunities.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Value chain stage(s) covered
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Risk management process
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Frequency of assessment
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Time horizon(s) covered
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of process
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C2.2a) Which risk types are considered in your organization's climate-related risk assessments?</div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Risk type
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Relevance & inclusion
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>


                                        </tr>
                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                            <div className="m-3 para">(C2.2g) Why does your organization not have a process in place for identifying, assessing, and responding to climate-related risks and opportunities, and do you plan to introduce such a process in the future?</div>
                            <div className="m-3 para">Question dependencies</div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Primary reason
                                            </td>
                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"riskd"}>Risk disclosure</sectionheader1>

                            <div className="m-3 para">(C2.3) Have you identified any inherent climate-related risks with the potential to have a substantive financial or strategic impact on your business?</div>
                            <div className="m-3 para">  (C2.3a) Provide details of risks identified with the potential to have a substantive financial or strategic impact on your business.</div>



                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5 ">2</td>
                                            <td colSpan="1" className="text-center fb5">3a</td>
                                            <td colSpan="1" className="text-center fb5">3b</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>

                                            <td colSpan="1" className="text-center fb5">7</td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Identifier
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Where in the value chain does the risk driver occur?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Risk type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary climate-related risk driver
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary potential financial impact
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                [Financial services only]  	Climate risk type mapped to traditional financial services industry risk classification
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Company- specific description
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Time horizon
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5 ">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Likelihood
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Magnitude of impact
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Are you able to provide a potential financial impact figure?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential financial impact figure (currency)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential financial impact figure - minimum (currency)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential financial impact figure - maximum (currency)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>


                                    </tbody>
                                </table>
                            </div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">14</td>
                                            <td colSpan="1" className="text-center fb5 ">15</td>
                                            <td colSpan="1" className="text-center fb5">16</td>
                                            <td colSpan="1" className="text-center fb5">17</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Explanation of financial impact figure
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Cost of response to risk
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of response and explanation of cost calculation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C2.3b) Why do you not consider your organization to be exposed to climate-related risks with the potential to have a substantive financial or strategic impact on your business?</div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Primary reason
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"opportunityd"}>Opportunity disclosure</sectionheader1>


                            <div className="m-3 para"> (C2.4) Have you identified any climate-related opportunities with the potential to have a substantive financial or strategic impact on your business?</div>
                            <div className="m-3 para">   (C2.4a) Provide details of opportunities identified with the potential to have a substantive financial or strategic impact on your business.</div>



                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Identifier
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Where in the value chain does the opportunity occur?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Opportunity type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary climate-related opportunity driver
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary potential financial impact
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Company-specific description
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Time horizon
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Likelihood
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Magnitude of impact
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Are you able to provide a potential financial impact figure?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential financial impact figure (currency)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential financial impact figure - minimum (currency)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential financial impact figure - maximum (currency)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">14</td>
                                            <td colSpan="1" className="text-center fb5 ">15</td>
                                            <td colSpan="1" className="text-center fb5">16</td>
                                            <td colSpan="1" className="text-center fb5">17</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Explanation of financial impact figure
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Cost to realize opportunity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Strategy to realize opportunity and explanation of cost calculation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">   (C2.4b) Why do you not consider your organization to have climate-related opportunities?</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Primary reason
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c3b"} className="secHead">
                                C3 Business strategy
                            </sectionheader>
                            <sectionheader1 id={"businesss"}>Business strategy</sectionheader1>

                            <div className="m-3 para"> (C3.1) Does your organization’s strategy include a climate transition plan that aligns with a 1.5°C world? Response options</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Climate transition plan
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Publicly available climate transition plan
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Mechanism by which feedback is collected from shareholders on your climate transition plan
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of feedback mechanism
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Frequency of feedback collection
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach any relevant documents which detail your climate transition plan (optional)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain why your organization does not have a climate transition plan that aligns with a 1.5°C world and any plans to develop one in the future
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain why climate-related risks and opportunities have not influenced your strategy
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C3.2) Does your organization use climate-related scenario analysis to inform its strategy?Response options</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Use of climate-related scenario analysis to inform strategy
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary reason why your organization does not use climate-related scenario analysis to inform its strategy
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain why your organization does not use climate-related scenario analysis to inform its strategy and any plans to use it in the future
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C3.2a) Provide details of your organization’s use of climate-related scenario analysis.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Climate-related scenario
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scenario analysis coverage

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Temperature alignment of scenario
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Parameters, assumptions, analytical choices
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C3.2b) Provide details of the focal questions your organization seeks to address by using climate-related scenario analysis, and summarize the results with respect to these questions.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Focal questions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Results of the climate-related scenario analysis with respect to the focal questions
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C3.3) Describe where and how climate-related risks and opportunities have influenced your strategy.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Business area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Have climate-related risks and opportunities influenced your strategy in this area?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of influence
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C3.4) Describe where and how climate-related risks and opportunities have influenced your financial planning.
                                Response options
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Financial planning elements that have been influenced
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of influence

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C3.4) Describe where and how climate-related risks and opportunities have influenced your financial planning.
                                Response options
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Financial planning elements that have been influenced
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of influence

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C3.5) In your organization’s financial accounting, do you identify spending/revenue that is aligned with your organization’s climate transition?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Identification of spending/revenue that is  aligned with your organization’s climate transition
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Indicate the level at which you identify the  alignment of your spending/revenue with a sustainable finance taxonomy*

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C3.5a) Quantify the percentage share of your spending/revenue that is aligned with your organization’s climate transition. </div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Financial metric
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of alignment being reported for this financial metric

                                            </td>

                                            <td colSpan="1" className="tdHead">

                                                Taxonomy under which information is being reported*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Objective under which alignment is being reported*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Amount of selected financial metric that is aligned in the reporting year (unit currency as selected in C0.4)

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Percentage share of selected financial metric aligned in the reporting year (%)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Percentage share of selected financial metric planned to align in 2025 (%)

                                            </td>

                                            <td colSpan="1" className="tdHead">

                                                Percentage share of selected financial metric planned to align in 2030 (%)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Describe the methodology used to identify spending/revenue that is aligned

                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C3.5b) Quantify  the percentage share of your spending/revenue that was associated with eligible  and aligned activities under the sustainable finance taxonomy in the reporting  year. </div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Economic activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy under which information is being reported
                                            </td>

                                            <td colSpan="1" className="tdHead">

                                                Taxonomy alignment
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Financial metric(s)

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned turnover from this  activity in the reporting year (unit currency as selected in C0.4)*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned turnover from this activity as % of total turnover in the reporting year*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned turnover from this activity that substantially contributed to climate change mitigation as a % of total turnover in the reporting year*
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>
                                            <td colSpan="1" className="text-center fb5">14</td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned turnover from this activity that substantially contributed to climate change adaptation as a % of total turnover in the reporting year*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-eligible but not aligned turnover from this activity in the reporting year (unit currency as selected in C0.4)*
                                            </td>

                                            <td colSpan="1" className="tdHead">

                                                Taxonomy-eligible but not aligned turnover from this activity as % of total turnover in the reporting year*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned CAPEX from this activity in the reporting year (unit currency as selected in C0.4)*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned CAPEX from this activity as % of total CAPEX in the reporting year*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned CAPEX from this activity that substantially contributed to climate change mitigation as a % of total CAPEX in the reporting year*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned CAPEX from this activity that substantially contributed to climate change adaptation as a % of total CAPEX in the reporting year*
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">15</td>
                                            <td colSpan="1" className="text-center fb5">16</td>
                                            <td colSpan="1" className="text-center fb5">17</td>
                                            <td colSpan="1" className="text-center fb5">18</td>
                                            <td colSpan="1" className="text-center fb5">19</td>
                                            <td colSpan="1" className="text-center fb5">20</td>
                                            <td colSpan="1" className="text-center fb5">21</td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-eligible but not aligned CAPEX associated with this activity in the reporting year (unit currency as selected in C0.4)*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-eligible but not aligned CAPEX associated with this activity as % of total CAPEX in the reporting year*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned OPEX from this activity in the reporting year (unit currency as selected in C0.4)*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned OPEX from this activity as % of total OPEX in the reporting year*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned OPEX from this activity that substantially contributed to climate change mitigation as a % of total OPEX in the reporting year*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-aligned OPEX from this activity that substantially contributed to climate change adaptation as a % of total OPEX in the reporting year*

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-eligible but not aligned OPEX associated with this activity in the reporting year (unit currency as selected in C0.4)*
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">22</td>
                                            <td colSpan="1" className="text-center fb5">23</td>
                                            <td colSpan="1" className="text-center fb5">24</td>
                                            <td colSpan="1" className="text-center fb5">25</td>
                                            <td colSpan="1" className="text-center fb5">26</td>
                                            <td colSpan="1" className="text-center fb5">27</td>
                                            <td colSpan="1" className="text-center fb5">28</td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Taxonomy-eligible but not aligned OPEX associated with this activity as % total OPEX in the reporting year*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type(s) of substantial contribution*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Calculation methodology and supporting information
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Technical screening criteria met

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of technical screening criteria analysis
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Do no significant harm requirements met

                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of do no significant harm analysis
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="text-center fb5">29</td>
                                            <td colSpan="1" className="text-center fb5">30</td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Minimum safeguards compliance requirements met
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of minimum safeguards compliance analysis
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C3.5c) Provide any additional contextual and/or verification/assurance information relevant to your organization’s taxonomy alignment. </div>

                            {/* C4 Targets and performance */}

                            <sectionheader id={"c4t"} className="secHead">
                                C4 Targets and performance
                            </sectionheader>

                            <sectionheader1 id={"emissionst"}>Emissions targets</sectionheader1>

                            <div className="m-3 para">(C4.1) Did you have an emissions target that was active in the reporting year? </div>
                            <div className="m-3 para">  (C4.1a) Provide details of your absolute emissions target(s) and progress made against those targets. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5 ">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target reference number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this a science-based target?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target ambition*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Year target was set
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target coverage
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope(s)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2 accounting method
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12-28</td>
                                            <td colSpan="1" className="text-center fb5">29</td>
                                            <td colSpan="1" className="text-center fb5">30</td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 3 category(ies)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 1 emissions covered by target (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 2 emissions covered by target (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 3, Category […] emissions covered by target (metric tons CO2e)* [One column for each Scope 3 category]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year total Scope 3 emissions covered by target (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total base year emissions covered by target in all selected Scopes (metric tons CO2e)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">31</td>
                                            <td colSpan="1" className="text-center fb5">32</td>
                                            <td colSpan="1" className="text-center fb5">33-49</td>
                                            <td colSpan="1" className="text-center fb5">50</td>
                                            <td colSpan="1" className="text-center fb5">51</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 1 emissions covered by target as % of total base year emissions in Scope 1
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 2 emissions covered by target as % of total base year emissions in Scope 2
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 3, Category […] emissions covered by target as   % of total base year emissions in Scope 3, Category […] (metric tons CO  		2e)* [One column for each Scope 3 category]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year Scope 3 emissions covered by target as % of total base year emissions in Scope 3 (in all Scope 3 categories)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year emissions covered by target in all selected Scopes as % of total base year emissions in all selected Scopes
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">52</td>
                                            <td colSpan="1" className="text-center fb5">53</td>
                                            <td colSpan="1" className="text-center fb5">54</td>
                                            <td colSpan="1" className="text-center fb5">55</td>
                                            <td colSpan="1" className="text-center fb5">56</td>
                                            <td colSpan="1" className="text-center fb5">57-73</td>
                                            <td colSpan="1" className="text-center fb5">74</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Targeted reduction from base year (%)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total emissions in target year covered by target in all selected Scopes (metric tons CO2e)  [auto-calculated]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions in reporting year covered by target (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2 emissions in reporting year covered by target (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3, Category […] emissions in reporting year covered by target (metric tons CO2e) [One column for each Scope 3 category]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total Scope 3 emissions in reporting year covered by target (metric tons CO2e)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">75</td>
                                            <td colSpan="1" className="text-center fb5">76</td>
                                            <td colSpan="1" className="text-center fb5">77</td>
                                            <td colSpan="1" className="text-center fb5">78</td>
                                            <td colSpan="1" className="text-center fb5">79</td>
                                            <td colSpan="1" className="text-center fb5">80</td>
                                            <td colSpan="1" className="text-center fb5">81</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Total emissions in reporting year covered by target in all selected scopes (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Does this target cover any land-related emissions?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of target achieved relative to base year [auto-calculated]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target status in reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain target coverage and identify any exclusions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Plan for achieving target, and progress made to the end of the reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                List the emissions reduction initiatives which contributed most to achieving this target
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C4.1b) Provide details of your emissions intensity target(s) and progress made against those target(s). </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>



                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target reference number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this a science-based target?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target ambition*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Year target was set
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target coverage
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope(s)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2 accounting method
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3 category(ies)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity metric
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5 ">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13-29</td>
                                            <td colSpan="1" className="text-center fb5">30</td>
                                            <td colSpan="1" className="text-center fb5">31</td>
                                            <td colSpan="1" className="text-center fb5">32</td>
                                            <td colSpan="1" className="text-center fb5">33</td>



                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Base year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in base year for Scope 1 (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in base year for Scope 2 (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in base year for Scope 3, Category […] (metric tons CO2e per unit of activity)* [One column for each Scope 3 category]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in base year for total Scope 3 (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in base year for all selected Scopes (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of total base year emissions in Scope 1 covered by this Scope 1 intensity figure
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of total base year emissions in Scope 2 covered by this Scope 2 intensity figure
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">34-50</td>
                                            <td colSpan="1" className="text-center fb5">51</td>
                                            <td colSpan="1" className="text-center fb5">52</td>
                                            <td colSpan="1" className="text-center fb5">53</td>
                                            <td colSpan="1" className="text-center fb5">54</td>
                                            <td colSpan="1" className="text-center fb5">55</td>
                                            <td colSpan="1" className="text-center fb5">56</td>
                                            <td colSpan="1" className="text-center fb5">57</td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                % of total base year emissions in Scope 3, Category […] covered by this Scope 3, Category […] intensity figure* [One column for each Scope 3 category]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of total base year emissions in Scope 3 (in all Scope 3 categories) covered by this total Scope 3 intensity figure
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of total base year emissions in all selected Scopes covered by this intensity figure
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Targeted reduction from base year (%)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in target year for all selected Scopes (metric tons CO2e per unit of activity) [auto-calculated]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % change anticipated in absolute Scope 1+2 emissions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % change anticipated in absolute Scope 3 emissions
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">58</td>
                                            <td colSpan="1" className="text-center fb5">59</td>
                                            <td colSpan="1" className="text-center fb5">60-76</td>
                                            <td colSpan="1" className="text-center fb5">77</td>
                                            <td colSpan="1" className="text-center fb5">78</td>
                                            <td colSpan="1" className="text-center fb5">79</td>
                                            <td colSpan="1" className="text-center fb5">80</td>
                                            <td colSpan="1" className="text-center fb5">81</td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in reporting year for Scope 1 (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in reporting year for Scope 2 (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in reporting year for Scope 3, Category […] (metric tons CO2e per unit of activity)* [One column for each Scope 3 category]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in reporting year for total Scope 3 (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Intensity figure in reporting year for all selected Scopes (metric tons CO2e per unit of activity)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Does this target cover any land-related emissions?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of target achieved relative to base year [auto-calculated]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target status in reporting year
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">82</td>
                                            <td colSpan="1" className="text-center fb5">83</td>
                                            <td colSpan="1" className="text-center fb5">84</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Please explain target coverage and identify any exclusions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Plan for achieving target, and progress made to the end of the reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                List the emissions reduction initiatives which contributed most to achieving this target
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C4.1c) Explain why you did not have an emissions target, and forecast how your emissions will change over the next five years. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>

                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Primary reason
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Five-year forecast
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ocrt"}>Other climate-related targets</sectionheader1>

                            <div className="m-3 para"> (C4.2) Did you have any other climate-related targets that were active in the reporting year? </div>

                            <div className="m-3 para">  (C4.2a) Provide details of your target(s) to increase low-carbon energy consumption or production. </div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target reference number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Year target was set
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target coverage
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target type: energy carrier
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target type: activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target type: energy source
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Base year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Consumption or production of selected energy carrier in base year (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % share of low-carbon or renewable energy in base year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % share of low-carbon or renewable energy in target year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % share of low-carbon or renewable energy in reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of target achieved relative to base year [auto-calculated]
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">14</td>
                                            <td colSpan="1" className="text-center fb5">15</td>
                                            <td colSpan="1" className="text-center fb5">16</td>
                                            <td colSpan="1" className="text-center fb5">17</td>
                                            <td colSpan="1" className="text-center fb5">18</td>
                                            <td colSpan="1" className="text-center fb5">19</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target status in reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this target part of an emissions target?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this target part of an overarching initiative?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain target coverage and identify any exclusions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Plan for achieving target, and progress made to the end of the reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                List the actions which contributed most to achieving this target
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C4.2b) Provide details of any other climate-related targets, including methane reduction targets.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5a</td>
                                            <td colSpan="1" className="text-center fb5">5b</td>
                                            <td colSpan="1" className="text-center fb5">6</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target reference number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Year target was set
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target coverage
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target type: absolute or intensity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target type: category
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Metric (target numerator if reporting an intensity target)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target denominator (intensity targets only)
                                            </td>




                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5"> 13</td>
                                            <td colSpan="1" className="text-center fb5">14</td>
                                            <td colSpan="1" className="text-center fb5">15</td>
                                            <td colSpan="1" className="text-center fb5">16</td>
                                            <td colSpan="1" className="text-center fb5">17</td>
                                            <td colSpan="1" className="text-center fb5">18</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target status in reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this target part of an emissions target?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this target part of an overarching initiative?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain target coverage and identify any exclusions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Plan for achieving target, and progress made to the end of the reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                List the actions which contributed most to achieving this target
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C4.2c) Provide details of your net-zero target(s).</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Target reference number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target coverage
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Absolute/intensity emission target(s) linked to this net-zero target
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Target year for achieving net zero
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this a science-based target?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain target coverage and identify any exclusions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Do you intend to neutralize any unabated emissions with permanent carbon removals at the target year?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Planned milestones and/or near-term investments for neutralization at target year
                                            </td>

                                            <td colSpan="1" className="tdHead">

                                                Planned actions to mitigate emissions beyond your value chain (optional)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"eri"}>Emissions reduction initiatives</sectionheader1>


                            <div className="m-3 para">  (C4.3) Did you have emissions reduction initiatives that were active within the reporting year? Note that this can include those in the planning and/or implementation phases.</div>


                            <div className="m-3 para">  (C4.3a) Identify the total number of initiatives at each stage of development, and for those in the implementation stages, the estimated CO2e savings. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Stage of development
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Number of initiatives
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total estimated annual CO2e savings in metric tons CO2e (only for rows marked *)
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C4.3b) Provide details on the initiatives implemented in the reporting year in the table below. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Initiative category
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Initiative type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Estimated annual CO2e savings (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope(s) or Scope 3 category(ies) where emissions savings occur
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Voluntary/ Mandatory
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Annual monetary savings (unit currency – as specified in C0.4)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Investment required (unit currency – as specified in C0.4)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Payback period
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Estimated lifetime of the initiative
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C4.3c) What methods do you use to drive investment in emissions reduction activities? </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Method
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C4.3d) Why did you not have any emissions reduction initiatives active during the reporting year? </div>

                            <sectionheader1 id={"lcp"}>Low-carbon products</sectionheader1>

                            <div className="m-3 para"> (C4.5) Do you classify any of your existing goods and/or services as low-carbon products? </div>

                            <div className="m-3 para"> (C4.5a) Provide details of your products and/or services that you classify as low-carbon products. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Level of aggregation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Taxonomy used to classify product(s) or service(s) as low-carbon
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of product(s) or service(s)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of product(s) or service(s)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Have you estimated the avoided emissions of this low-carbon product(s) or service(s)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Methodology used to calculate avoided emissions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Life cycle stage(s) covered for the low-carbon product(s) or services(s)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>
                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Functional unit used
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Reference product/service or baseline scenario used
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Life cycle stage(s) covered for the reference product/service or baseline scenario
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Estimated avoided emissions (metric tons CO2e per functional unit) compared to reference product/service or baseline scenario
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain your calculation of avoided emissions, including any assumptions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Revenue generated from low-carbon product(s) or service(s) as %  of total revenue in the reporting year
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c5e"} className="secHead">
                                C5 Emissions methodology
                            </sectionheader>

                            <sectionheader1 id={"citry"}> Changes in the reporting year</sectionheader1>

                            <div className="m-3 para"> (C5.1) Is this your first year of reporting emissions data to CDP?  </div>
<div className="rfres-one"> {getLastResponseByRFID(84,'radio-group-1697525714291-0')} </div>
                            <div className="m-3 para">  (C5.1a) Has your organization undergone any structural changes in the reporting year, or are any previous structural changes being accounted for in this disclosure of emissions data? </div>
                            <div className="rfres-one"> {getLastResponseByRFID(84,'radio-group-1697715425616')} </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Has there been a structural change?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Name of organization(s) acquired, divested from, or merged with*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of structural change(s), including completion dates*
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center">{getLastResponseByRFID(84,'radio-group-1697715425616')} </td>
                                            <td colSpan="1" className="text-center">{getLastResponseByRFID(84,'textarea-1697715490794-0')}</td>
                                            <td colSpan="1" className="text-center">{getLastResponseByRFID(84,'textarea-1697715523275-0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C5.1b) Has your emissions accounting methodology, boundary, and/or reporting year definition changed in the reporting year?  </div>
                            <div className="rfres-one"> {getLastResponseByRFID(84,'radio-group-1697715735091')} </div>
                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Change(s) in methodology, boundary, and/or reporting year definition?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of methodology, boundary, and/or reporting year definition change(s)*
                                            </td>
                                        </tr>

                                        <tr>
                                        <td colSpan="1" className="text-center">{getLastResponseByRFID(84,'textarea-1697715767875-0')} </td>
                                        <td colSpan="1" className="text-center">{getLastResponseByRFID(84,'textarea-1697715779795')} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C5.1c) Have your organization’s base year emissions and past years’ emissions been recalculated as a result of any changes or errors reported in C5.1a and/or C5.1b?  </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Base year recalculation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope(s) recalculated*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year emissions recalculation policy, including significance threshold
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Past years’ recalculation
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"bye"}>Base year emissions</sectionheader1>

                            <div className="m-3 para"> (C5.2) Provide your base year and base year emissions.  </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year start
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year end
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Base year emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"em"}> Emissions methodology </sectionheader1>

                            <div className="m-3 para"> (C5.3) Select the name of the standard, protocol, or methodology you have used to collect activity data and calculate emissions.  </div>

                            <sectionheader id={"c6e"} className="secHead">
                                C6 Emissions data
                            </sectionheader>

                            <sectionheader1 id={"s1ed"}>Scope 1 emissions data </sectionheader1>

                            <div className="m-3 para">(C6.1) What were your organization’s gross global Scope 1 emissions in metric tons CO2e?  </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Gross global Scope 1 emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Start date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                End date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"s2er"}> Scope 2 emissions reporting </sectionheader1>

                            <div className="m-3 para"> (C6.2) Describe your organization's approach to reporting Scope 2 emissions.  </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 2, location-based
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, market-based
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"s2ed"}> Scope 2 emissions data </sectionheader1>

                            <div className="m-3 para">(C6.3) What were your organization's gross global Scope 2 emissions in metric tons CO2e?  </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, location-based
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, market-based (if applicable)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Start date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                End date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"exclusions"}> Exclusions </sectionheader1>

                            <div className="m-3 para">(C6.3) (C6.4) Are there any sources (e.g. facilities, specific GHGs, activities, geographies, etc.) of Scope 1, Scope 2 or Scope 3 emissions that are within your selected reporting boundary which are not included in your disclosure? </div>

                            <div className="m-3 para">  (C6.4a) Provide details of the sources of Scope 1, Scope 2, or Scope 3 emissions that are within your selected reporting boundary which are not included in your disclosure.  </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Source of excluded emissions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope(s) or Scope 3 category(ies)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevance of Scope 1 emissions from this source
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevance of location-based Scope 2 emissions from this source
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevance of market-based Scope 2 emissions from this source
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevance of Scope 3 emissions from this source
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Date of completion of acquisition or merger
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Estimated percentage of total Scope 1+2 emissions this excluded source represents
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Estimated percentage of total Scope 3 emissions this excluded source represents
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain why this source is excluded
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain how you estimated the percentage of emissions this excluded source represents
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"s3ed"}> Scope 3 emissions data </sectionheader1>

                            <div className="m-3 para">  (C6.5) Account for your organization’s gross global Scope 3 emissions, disclosing and explaining any exclusions.Response options</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 3 category
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Evaluation status
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Emissions in reporting year (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Emissions calculation methodology
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Percentage of emissions calculated using data obtained from suppliers or value chain partners
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C6.5a) Disclose or restate your Scope 3 emissions data for previous years.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Start date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                End date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Purchased goods and services (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Capital goods (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Fuel and energy-related activities (not included in Scopes 1 or 2) (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Upstream transportation and distribution (metric tons CO2e)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>
                                            <td colSpan="1" className="text-center fb5">14</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Waste generated in operations (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Business travel (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Employee commuting (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Upstream leased assets (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Downstream transportation and distribution (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Processing of sold products (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Use of sold products (metric tons CO2e)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">15</td>
                                            <td colSpan="1" className="text-center fb5">16</td>
                                            <td colSpan="1" className="text-center fb5">17</td>
                                            <td colSpan="1" className="text-center fb5">18</td>
                                            <td colSpan="1" className="text-center fb5">19</td>
                                            <td colSpan="1" className="text-center fb5">20</td>
                                            <td colSpan="1" className="text-center fb5">21</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 3: End of life treatment of sold products (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Downstream leased assets (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Franchises (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Investments (metric tons CO2e) [column hidden for FS sector companies]
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Other (upstream) (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 3: Other (downstream) (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"bcd"}> Biogenic carbon data </sectionheader1>

                            <div className="m-3 para">(C6.7) Are carbon dioxide emissions from biogenic carbon relevant to your organization?</div>

                            <div className="m-3 para"> (C6.7a) Provide the emissions from biogenic carbon relevant to your organization in metric tons CO2.</div>

                            <div className="m-3 para"> Question dependencies</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                CO2 emissions from biogenic carbon (metric tons CO2)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c7e"} className="secHead">
                                C7 Emissions breakdown
                            </sectionheader>

                            <sectionheader1 id={"s1bghg"}>Scope 1 breakdown: GHGs </sectionheader1>

                            <div className="m-3 para">(C7.1) Does your organization break down its Scope 1 emissions by greenhouse gas type?</div>

                            <div className="m-3 para"> (C7.1a) Break down your total gross global Scope 1 emissions by greenhouse gas type and provide the source of each used global warming potential (GWP).</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Greenhouse gas
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions (metric tons in CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                GWP Reference
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"s1bcar"}>  Scope 1 breakdown: country/area/region</sectionheader1>

                            <div className="m-3 para"> (C7.2) Break down your total gross global Scope 1 emissions by country/area/region.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Country/area/region
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions (metric tons CO2e)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"s1bbd"}> Scope 1 breakdown: business breakdown  </sectionheader1>

                            <div className="m-3 para">(C7.3) Indicate which gross global Scope 1 emissions breakdowns you are able to provide.</div>

                            <div className="m-3 para">  (C7.3a) Break down your total gross global Scope 1 emissions by business division. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Business division
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions (metric tons CO2e)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C7.3b) Break down your total gross global Scope 1 emissions by business facility. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Facility
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Latitude
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Longitude
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C7.3c) Break down your total gross global Scope 1 emissions by business activity. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions (metric tons CO2e)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C7.6a) Break down your total gross global Scope 2 emissions by business division. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Business division
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, location-based (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, market-based (metric tons CO2e)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className="m-3 para"> (C7.6b) Break down your total gross global Scope 2 emissions by business facility. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Facility
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, location-based (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, market-based (metric tons CO2e)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C7.6c) Break down your total gross global Scope 2 emissions by business activity. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, location-based (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, market-based (metric tons CO2e)
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ebbs7"}> Emissions breakdown by subsidiary  </sectionheader1>

                            <div className="m-3 para">  (C7.7) Is your organization able to break down your emissions data for any of the subsidiaries included in your CDP response? </div>

                            <div className="m-3 para">  (C7.7a) Break down your gross Scope 1 and Scope 2 emissions by subsidiary. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Subsidiary name
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Select the unique identifier(s) you are able to provide for this subsidiary
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                ISIN code – bond*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                ISIN code – equity*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                CUSIP number*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Ticker symbol*
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>
                                            <td colSpan="1" className="text-center fb5">14</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                SEDOL code*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                LEI number*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Other unique identifier*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 1 emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, location-based emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope 2, market-based emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ep7"}>Emissions performance  </sectionheader1>

                            <div className="m-3 para"> (C7.9) How do your gross global emissions (Scope 1 and 2 combined) for the reporting year compare to those of the previous reporting year? </div>

                            <div className="m-3 para">  (C7.9a) Identify the reasons for any change in your gross global emissions (Scope 1 and 2 combined), and for each of them specify how your emissions compare to the previous year. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Reason
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Change in emissions (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Direction of change in emissions
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Emissions value (percentage)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain calculation
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C7.9b) Are your emissions performance calculations in C7.9 and C7.9a based on a location-based Scope 2 emissions figure or a market-based Scope 2 emissions figure? </div>

                            <sectionheader id={"c8e"} className="secHead">
                                C8 Energy
                            </sectionheader>

                            <sectionheader1 id={"spend8"}>Energy spend  </sectionheader1>

                            <div className="m-3 para">(C8.1) What percentage of your total operational spend in the reporting year was on energy? </div>

                            <div className="m-3 para">Energy-related activities </div>

                            <div className="m-3 para">(C8.2) Select which energy-related activities your organization has undertaken. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Indicate whether your organization undertook this energy-related activity in the reporting year
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C8.2a) Report your organization’s energy consumption totals (excluding feedstocks) in MWh. </div>


                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Heating value
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh from renewable sources
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh from non-renewable sources
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total (renewable + non-renewable) MWh
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C8.2b) Select the applications of your organization’s consumption of fuel. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Fuel application
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Indicate whether your organization undertakes this fuel application
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C8.2c) State how much fuel in MWh your  organization has consumed (excluding feedstocks) by fuel type. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Fuels (excluding feedstocks)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Heating value
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total fuel MWh consumed by the organization
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh fuel consumed for self-generation of electricity*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh fuel consumed for self-generation of heat*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh fuel consumed for self-generation of steam*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh fuel consumed for self-generation of cooling*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                MWh fuel consumed for self- cogeneration or self-trigeneration*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className="m-3 para">  (C8.2d) Provide details on the electricity, heat, steam, and cooling your organization has generated and consumed in the reporting year.Question Dependencies </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Energy Carrier
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total Gross generation (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Generation that is consumed by the organization (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Gross generation from renewable sources (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Generation from renewable sources that is consumed by the organization (MWh)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C8.2e) Provide details on the electricity, heat, steam, and/or cooling amounts that were accounted for at a zero or near-zero emission factor in the market-based Scope 2 figure reported in C6.3. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>

                                            <td colSpan="1" className="tdHead">
                                                Country/area of low-carbon energy consumption
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Sourcing method
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Energy carrier
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Low-carbon technology type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Low-carbon energy consumed via selected sourcing method in the reporting year (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Tracking instrument used
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Country/area of origin (generation) of the low-carbon energy or energy attribute
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Are you able to report the commissioning or re-powering year of the energy generation facility?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Commissioning year of the energy generation facility (e.g. date of first commercial operation or repowering)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C8.2g) Provide a breakdown by country/area of your non-fuel energy consumption in the reporting year. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Country/area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Consumption of purchased electricity (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Consumption of self-generated electricity (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is this electricity consumption excluded from your RE100 commitment?*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Consumption of purchased heat, steam, and cooling (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Consumption of self-generated heat, steam, and cooling (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total non-fuel energy consumption (MWh) [Auto-calculated]
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C8.2h) Provide details of your organization’s renewable electricity purchases in the reporting year by country/area. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Country/area of consumption of purchased renewable electricity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Sourcing method
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Renewable electricity technology type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Renewable electricity consumed via selected sourcing method in the reporting year (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Tracking instrument used
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Country/area of origin (generation) of purchased renewable electricity
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Are you able to report the commissioning or re-powering year of the energy generation facility?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Commissioning year of the energy generation facility (e.g. date of first commercial operation or repowering)*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Vintage of the renewable energy/attribute (i.e. year of generation)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Supply arrangement start year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Additional voluntary label associated with purchased renewable electricity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C8.2i) Provide details of your organization’s low-carbon heat, steam, and cooling purchases in the reporting year by country/area. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Sourcing method
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Country/area of consumption of low-carbon heat, steam or cooling*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Energy carrier*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Low-carbon technology type*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Low-carbon heat, steam, or cooling consumed (MWh)*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C8.2j) Provide details of your organization’s renewable electricity generation by country/area in the reporting year </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Country/area of generation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Renewable electricity technology type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Facility capacity (MW)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total renewable electricity generated by this facility in the reporting year (MWh)   </td>

                                            <td colSpan="1" className="tdHead">
                                                Renewable electricity consumed by your organization from this facility in the reporting year (MWh)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Energy attribute certificates issued for this generation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of energy attribute certificate*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C8.2k) Describe how your organization’s renewable electricity sourcing strategy directly or indirectly contributes to bringing new capacity into the grid in the countries/areas in which you operate. </div>

                            <div className="m-3 para"> (C8.2l) In the reporting year, has your organization faced any challenges to sourcing renewable electricity? </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Challenges to sourcing renewable electricity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Challenges faced by your organization which were not country/area-specific*
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para"> (C8.2m) Provide details of the country/area-specific challenges to sourcing renewable electricity faced by your organization in the reporting year. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Country/area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Reason(s) why it was challenging to source renewable electricity within selected country/area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Provide additional details of the barriers faced within this country/area
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"fcc8"}>Feedstock consumption: Chemicals  </sectionheader1>

                            <div className="m-3 para">(C-CH8.3) Does your organization consume fuels as feedstocks for chemical production activities? </div>

                            <div className="m-3 para">(C-CH8.3a) Disclose details on your organization’s consumption of fuels as feedstocks for chemical production activities. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Fuels used as feedstocks
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total consumption
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total consumption unit
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Inherent carbon dioxide emission factor of feedstock, metric tons CO2 per consumption unit
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Heating value of feedstock, MWh per consumption unit
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Heating value
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">(C-CH8.3b) State the percentage, by mass, of primary resource from which your chemical feedstocks derive. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Feedstock source
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Percentage of total chemical feedstock (%)
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"fcs8"}> Feedstock consumption: Steel</sectionheader1>

                            <div className="m-3 para">(C-ST8.3) Disclose details on your organization’s consumption of feedstocks for steel production activities.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Feedstocks
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total consumption
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total consumption unit
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Dry or wet basis?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Inherent carbon dioxide emission factor of feedstock, metric tons of CO2 per consumption unit
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Heating value of feedstock, MWh per consumption unit
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Heating value
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c9a"} className="secHead">
                                C9 Additional metrics
                            </sectionheader>

                            <sectionheader1 id={"ocrm9"}>Other climate-related metrics  </sectionheader1>

                            <div className="m-3 para"> (C9.1) Provide any additional climate-related metrics relevant to your business.</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Description
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Metric value
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Metric numerator
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Metric denominator (intensity metric only)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % change from previous year


                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Direction of change
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"ca0v"} className="secHead">
                                C10 Verification
                            </sectionheader>

                            <sectionheader1 id={"verification"}> Verification  </sectionheader1>

                            <div className="m-3 para">(C10.1) Indicate the verification/assurance status that applies to your reported emissions.Change from last year</div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Verification/assurance status
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">  (C10.1a) Provide further details of the verification/assurance undertaken for your Scope 1  emissions and attach the relevant statements. </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Verification or assurance cycle in place
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Status in the current reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of verification or assurance
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach the statement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Page/section reference
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevant standard
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Proportion of reported emissions verified (%)
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C10.1b) Provide further details of the verification/assurance undertaken for your Scope 2 emissions and attach the relevant statements.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 2 approach
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Verification or assurance cycle in place
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Status in the current reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of verification or assurance
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach the statement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Page/ section reference
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevant standard
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Proportion of reported emissions  verified (%)
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C10.1c) Provide further details of the verification/assurance undertaken for your Scope 3 emissions and attach the relevant statements.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Scope 3 category
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Verification or assurance cycle in place
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Status in the current reporting year
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of verification or assurance
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach the statement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Page/ section reference
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Relevant standard
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Proportion of reported emissions  verified (%)
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ovd10"}> Other verified data  </sectionheader1>

                            <div className="m-3 para">
                                (C10.2) Do you verify any climate-related information reported in your CDP disclosure other than the emissions figures reported in C6.1, C6.3, and C6.5?
                            </div>

                            <div className="m-3 para">
                                (C10.2a) Which data points within your CDP disclosure have been verified, and which verification standards were used?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Disclosure module verification relates to
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Data verified
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Verification standard
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c11c"} className="secHead">
                                C11 Carbon pricing
                            </sectionheader>

                            <sectionheader1 id={"cps11"}> Carbon pricing systems  </sectionheader1>

                            <div className="m-3 para">
                                (C11.1) Are any of your operations or activities regulated by a carbon pricing system (i.e. ETS, Cap & Trade or Carbon Tax)?
                            </div>

                            <div className="m-3 para">
                                (C11.1a) Select the carbon pricing regulation(s) which impacts your operations.
                            </div>

                            <div className="m-3 para">
                                (C11.1b) Complete the following table for each of the emissions trading schemes you are regulated by.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                System name
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of Scope 1 emissions covered by the ETS
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of Scope 2 emissions covered by the ETS
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Period start date   </td>

                                            <td colSpan="1" className="tdHead">
                                                Period end date
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Allowances allocated
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Allowances allocated
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Verified Scope 1 emissions in metric tons CO2e
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Verified Scope 2 emissions in metric tons CO2e  </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of ownership
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C11.1c) Complete the following table for each of the tax systems you are regulated by.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Tax system
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Period start date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Period end date
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of total Scope 1 emissions covered by tax
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Total cost of tax paid
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C11.1d) What is your strategy for complying with the systems you are regulated by or anticipate being regulated by?
                            </div>

                            <sectionheader1 id={"pbcc11"}> Project-based carbon credits  </sectionheader1>

                            <div className="m-3 para">
                                (C11.2) Has your organization canceled any project-based carbon credits within the reporting year?
                            </div>
                            <div className="m-3 para">
                                (C11.2a) Provide details of the project-based carbon credits canceled by your organization in the reporting year.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Project type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of mitigation activity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Project description
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Credits canceled by your organization from this project in the reporting year (metric tons CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Purpose of cancellation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Are you able to report the vintage of the credits at cancellation?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Vintage of credits at cancellation*
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>
                                            <td colSpan="1" className="text-center fb5">13</td>
                                            <td colSpan="1" className="text-center fb5">14</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Were these credits issued to or  purchased by your organization?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Credits issued by which carbon-crediting program
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Method(s) the program uses to assess additionality for this project*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Approach(es) by which the selected program requires this project to address reversal risk*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Potential sources of leakage the selected program requires this project to have assessed*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Provide details of other issues the selected program requires projects to address*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ipoc11"}> Internal price on carbon  </sectionheader1>

                            <div className="m-3 para">
                                (C11.3) Does your organization use an internal price on carbon?
                            </div>
                            <div className="m-3 para">
                                (C11.3a) Provide details of how your organization uses an internal price on carbon.
                                Question dependencies
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Type of internal carbon price
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                How the price is determined
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Objective(s) for implementing this internal carbon price
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope(s) covered     </td>

                                            <td colSpan="1" className="tdHead">
                                                Pricing approach used – spatial variance
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Pricing approach used – temporal variance
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Indicate how you expect the price to change over time*
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>
                                            <td colSpan="1" className="text-center fb5">11</td>
                                            <td colSpan="1" className="text-center fb5">12</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Actual price(s) used – minimum (currency as specified in C0.4 per metric ton CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Actual price(s) used – maximum (currency as specified in C0.4 per metric ton CO2e)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Business decision-making processes this internal carbon price is applied to
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Mandatory enforcement of this internal carbon price within these business decision-making processes   </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain how this internal carbon price has contributed to the implementation of your organization’s climate commitments and/or climate transition plan
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c12e"} className="secHead">
                                C12 Engagement
                            </sectionheader>

                            <sectionheader1 id={"vce12"}> Value chain engagement  </sectionheader1>

                            <div className="m-3 para">
                                (C12.1) Do you engage with your value chain on climate-related issues?
                            </div>

                            <div className="m-3 para">
                                (C12.1a) Provide details of your climate-related supplier engagement strategy.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Type of engagement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of engagement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of suppliers by number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % total procurement spend (direct and indirect)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of supplier-related Scope 3 emissions as reported in C6.5
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Rationale for the coverage of your engagement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Impact of engagement, including measures of success    Relevant standard
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C12.1b) Give details of your climate-related engagement strategy with your customers.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Type of engagement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Details of engagement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % of customers by number
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % customer-related Scope 3 emissions as reported in C6.5
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain the rationale for selecting this group of customers and scope of engagement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Impact of engagement, including measures of success
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C12.1d) Give details of your climate-related engagement strategy with other partners in the value chain.
                            </div>

                            <div className="m-3 para">
                                (C12.1e) Why do you not engage with any elements of your value chain on climate-related issues, and what are your plans to do so in the future?
                            </div>

                            <sectionheader1 id={"crr12"}> Climate-related requirements  </sectionheader1>

                            <div className="m-3 para">
                                (C12.2) Do your suppliers have to meet climate-related requirements as part of your organization’s purchasing process?
                            </div>

                            <div className="m-3 para">

                                (C12.2a) Provide details of the climate-related requirements that suppliers have to meet as part of your organization’s purchasing process and the compliance mechanisms in place.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Climate-related requirement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of this climate related requirement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % suppliers by procurement spend that have to comply with this climate-related requirement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                % suppliers by procurement spend in compliance with this climate-related requirement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Mechanisms for monitoring compliance with this climate-related requirement
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Response to supplier non-compliance with this climate-related requirement
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ppe12"}> Public policy engagement  </sectionheader1>

                            <div className="m-3 para">
                                (C12.3) Does your organization engage in activities that could either directly or indirectly influence policy, law, or regulation that may impact the climate?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                External engagement activities that could directly or indirectly influence policy, law, or regulation that may impact the climate
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Does your organization have a public commitment or position statement to conduct your engagement activities in line with the goals of the Paris Agreement?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach commitment or position statement(s)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Describe the process(es) your organization has in place to ensure that your external engagement activities are consistent with your climate commitments and/or climate transition plan
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Primary reason for not engaging in activities that could directly or indirectly influence policy, law, or regulation that may impact the climate
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain why your organization does not engage in activities that could directly or indirectly influence policy, law, or regulation that may impact the climate

                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C12.3a) On what policy, law, or regulation that may impact the climate has your organization been engaging directly with policy makers in the reporting year?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>
                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Specify the policy, law, or regulation on which your organization is engaging with policy makers
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Category of policy, law, or regulation that may impact the climate
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Focus area of policy, law, or regulation that may impact the climate
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Policy, law, or regulation geographic coverage     </td>

                                            <td colSpan="1" className="tdHead">
                                                Country/area/region the policy, law, or regulation applies to
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Your organization’s position on the policy, law, or regulation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of engagement with policy makers
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">8</td>
                                            <td colSpan="1" className="text-center fb5">9</td>
                                            <td colSpan="1" className="text-center fb5">10</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Details of exceptions (if applicable) and your organization’s proposed alternative approach to the policy, law or regulation
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Have you evaluated whether your organization’s engagement on this policy, law, or regulation is aligned with the goals of the Paris Agreement?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain whether this policy, law or regulation is central to the achievement of your climate transition plan and, if so, how?
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C12.3b) Provide details of the trade associations your organization is a member of, or engages with, which are likely to take a position on any policy, law or regulation that may impact the climate
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Trade association
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Is your organization's position on climate change policy consistent with theirs?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Has your organization attempted to influence their position in the reporting year?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Describe how your organization’s position is consistent with or differs from the trade association’s position, and any actions taken to influence their position
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Funding figure your organization provided to this trade association in the reporting year, (currency as selected in C0.4)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Describe the aim of your organization’s funding
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Have you evaluated whether your organization’s engagement with this trade association is aligned with the goals of the Paris Agreement?
                                            </td>



                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C12.3c) Provide details of the funding you provided to other organizations or individuals in the reporting year whose activities could influence policy, law, or regulation that may impact the climate.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Type of organization or individual
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                State the organization or individual to which you provided funding
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Funding figure your organization provided to this organization or individual in the reporting year (currency as selected in C0.4)
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Describe the aim of this funding and how it could influence policy, law or regulation that may impact the climate
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Have you evaluated whether this funding is aligned with the goals of the Paris Agreement?
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"comm12"}> Communications  </sectionheader1>

                            <div className="m-3 para">
                                (C12.4) Have you published information about your organization’s response to climate change and GHG emissions performance for this reporting year in places other than in your CDP response? If so, please attach the publication(s).
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Publication
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Status
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach the document
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Page/Section reference
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Content elements
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                comment
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader1 id={"ic12"}> Industry collaboration  </sectionheader1>

                            <div className="m-3 para">
                                (C12.5) Indicate the collaborative frameworks, initiatives and/or commitments related to environmental issues for which you are a signatory/member.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Environmental collaborative framework, initiative and/or commitment
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Describe your organization’s role within each framework, initiative and/or commitment*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c13o"} className="secHead">
                                C13 Other land management impacts
                            </sectionheader>

                            <sectionheader1 id={"bd13"}> Biodiversity  </sectionheader1>

                            <div className="m-3 para">
                                (C15.1) Is there board-level oversight and/or executive management-level responsibility for biodiversity-related matters within your organization?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Board-level oversight and/or executive management-level responsibility for biodiversity-related issues
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Description of oversight and objectives relating to biodiversity*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Scope of board-level oversight [FS Only]*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C15.2) Has your organization made a public commitment and/or endorsed any initiatives related to biodiversity?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Indicate whether your organization made a public commitment or endorsed any initiatives related to biodiversity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Biodiversity-related public commitments*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Initiatives endorsed*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C15.3) Does your organization assess the impacts and dependencies of its value chain on biodiversity?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">0</td>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Type of assessment
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Indicate whether your organization undertakes this type of assessment
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Value chain stage(s) covered*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Portfolio activity [FS only]*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Tools and methods to assess impacts and/or dependencies on biodiversity*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Please explain how the tools and methods are implemented and provide an indication of the associated outcome(s)*
                                            </td>


                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C15.4) Does your organization have activities located in or near to biodiversity- sensitive areas in the reporting year?
                            </div>

                            <div className="m-3 para">
                                (C15.4a) Provide details of your organization’s activities in the reporting year located in or near to biodiversity -sensitive areas.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>
                                            <td colSpan="1" className="text-center fb5">1</td>
                                            <td colSpan="1" className="text-center fb5">2</td>
                                            <td colSpan="1" className="text-center fb5">3</td>
                                            <td colSpan="1" className="text-center fb5">4</td>
                                            <td colSpan="1" className="text-center fb5">5</td>


                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Classification of biodiversity -sensitive area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Country/area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Name of the biodiversity-sensitive area
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Proximity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Briefly describe your organization’s activities in the reporting year located in or near to the selected area
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>


                                        <tr>

                                            <td colSpan="1" className="text-center fb5">6</td>
                                            <td colSpan="1" className="text-center fb5">7</td>
                                            <td colSpan="1" className="text-center fb5">8</td>

                                        </tr>


                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Indicate whether any of your organization’s activities located in or near to the selected area could negatively affect biodiversity
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Mitigation measures implemented within the selected area*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Explain how your organization’s activities located in or near to the selected area could negatively affect biodiversity, how this was assessed, and describe any mitigation measures implemented*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center "></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C15.5) What actions has your organization taken in the reporting year to progress your biodiversity-related commitments?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Have you taken any actions in the reporting period to progress your biodiversity-related commitments?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Type of action taken to progress biodiversity- related commitments*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C15.6) Does your organization use biodiversity indicators to monitor performance across its activities?
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Does your organization use indicators to monitor biodiversity performance?
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Indicators used to monitor biodiversity performance
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="m-3 para">
                                (C15.7) Have you published information about your organization’s response to biodiversity-related issues for this reporting year in places other than in your CDP response? If so, please attach the publication(s).
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Report type
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Content elements*
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Attach the document and indicate where in the document the relevant biodiversity information is located*
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <sectionheader id={"c16s"} className="secHead">
                                C16 Signoff
                            </sectionheader>

                            <sectionheader1 id={"fi16s"}> Further information  </sectionheader1>

                            <div className="m-3 para">
                                (C-FI) Use this field to provide any additional information or context that you feel is relevant to your organization's response. Please note that this field is optional and is not scored.
                            </div>

                            <sectionheader1 id={"so16s"}> Signoff  </sectionheader1>

                            <div className="m-3 para">
                                (C16.1) Provide details for the person that has signed off (approved) your CDP climate change response.
                            </div>

                            <div class="gridlines-container">
                                <table class="gridlines">
                                    <tbody>
                                        <tr>
                                            <td colSpan="1" className="tdHead">
                                                Job title
                                            </td>

                                            <td colSpan="1" className="tdHead">
                                                Corresponding job category
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="1" className="text-center"></td>
                                            <td colSpan="1" className="text-center"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

























                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CDPReport, comparisonFn);
