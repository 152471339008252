import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const data = [
  { year: "2019", ltifStaff: 5, ltifContractors: 3 },
  { year: "2020", ltifStaff: 3, ltifContractors: 2 },
  { year: "2021", ltifStaff: 4, ltifContractors: 1 },
  { year: "2022", ltifStaff: 6, ltifContractors: 2 },
  { year: "2023", ltifStaff: 7, ltifContractors: 3 },
];

export const FatalityChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Fatalities{" "}
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ overflow: "hidden" }}>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={data}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Number of Fatalities",
                  angle: -90,
                  position: "insideLeft",
                }}
                domain={[0, 30]}
              />
              <Tooltip />
              <Bar
                dataKey="ltifStaff"
                name="Employee"
                fill="#4d4d4d"
                barSize={40}
              />
              <Bar
                dataKey="ltifContractors"
                name="Contractors"
                fill="#ff4d4d"
                barSize={40}
              />
              {/* <Line
                type="linear"
                dataKey="ltifRate"
                name="LTIF Rate"
                stroke="#4CAF50"
                strokeWidth={2}
              /> */}
              <Legend align="center" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="LTIF Employee"
              style={{ minWidth: "8%" }}
              field="ltifStaff"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="LTIF Contractors"
              style={{ minWidth: "8%" }}
              field="ltifContractors"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="LTIF Rate"
              style={{ minWidth: "8%" }}
              field="ltifRate"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};
