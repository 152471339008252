import { DateTime } from "luxon";

export const getEFCalculatedDataByFY = (obj) => {
    const scope1 = [10, 11, 15]
    const scope2 = [257]
    const scope3 = [16, 36]
    let subcat = obj.subcat, apief = obj.apief
    function filterResponsesByPeriod(responses, startMonth, year) {
        const isFiscalYear = year.includes('-');
        let startYear, endYear;

        if (isFiscalYear) {
            const [start, end] = year.split('-').map(Number);
            startYear = start;
            endYear = start + 1;
        } else {
            startYear = parseInt(year, 10);
            endYear = startYear;
        }

        // Define the start and end dates for the period based on the fiscal or calendar year
        const startDate = DateTime.fromObject({ month: startMonth, year: startYear }).startOf('month');
        const endDate = startMonth === 1
            ? DateTime.fromObject({ month: 12, year: startYear }).endOf('month')
            : DateTime.fromObject({ month: startMonth - 1, year: endYear }).endOf('month');

        return responses.filter(response => {
            return response.reporting_period.some(period => {
                const [periodMonth, periodYear] = period.split('-').map(Number);
                const periodDate = DateTime.fromObject({ month: periodMonth, year: periodYear });

                return periodDate >= startDate && periodDate <= endDate;
            });
        });
    }
    const getEmissionFactorCalculation = (item, year) => {
        let betweenMonths = getBetweenMonths(year, obj.fymonth)
        console.log(betweenMonths)
        let month_data = []
        let scope = 0
        if (item.dcfId === 11) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {

                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                console.log('Matched_SC', standard_index)
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            console.log('Dates_SC', dates)

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0130 && i.subcategory2 === sc.DPA0131 && i.subcategory3 === sc.DPA0132 })
                                    console.log(sc_index, sc)
                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0336) * ef.co2e)) / 1000).toFixed(3))
                                        console.log(sc.DPA0336, ef)
                                        let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0131 })
                                        let unit_index = subcat.three.findIndex((i) => { return i.id === sc.DPA0132 })
                                        let fuel_cat_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0130 })
                                        let unit_type = 'NA', cat_type = 'NA'
                                        let fuel_type = 'Other', usage = 0, r = 0, nr = 0
                                        if (fuel_type_index !== -1) {
                                            fuel_type = subcat.two[fuel_type_index].title
                                        }
                                        if (unit_index !== -1) {
                                            unit_type = subcat.three[fuel_type_index].title
                                        }
                                        if (fuel_cat_index !== -1) {
                                            cat_type = subcat.one[fuel_cat_index].title
                                        }
                                        if (unit_index !== -1) {


                                            if (unit_type.includes('ton')) {
                                                if (fuel_cat_index !== -1) {
                                                    cat_type = subcat.one[fuel_cat_index].title

                                                    if (cat_type.trim().toLowerCase().includes('bio')) {
                                                        r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))
                                                    } else {

                                                        nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))

                                                    }
                                                }
                                                usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3))

                                            } else {
                                                if (fuel_cat_index !== -1) {


                                                    if (cat_type.trim().toLowerCase().includes('bio')) {
                                                        r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                    } else {

                                                        nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                    }
                                                }
                                                usage = parseFloat(sc.DPA0336)
                                            }
                                        }
                                        scope = 1
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, cat_type, unit_type, fuel_type, usage, r, nr }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.fuel_type === fuel_type && i.unit_type === unit_type && i.cat_type === cat_type })
                                        // if (month_index === -1) {
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, cat_type, unit_type, fuel_type, usage, r, nr })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].usage += usage
                                        //     month_data[month_index].r += r
                                        //     month_data[month_index].nr += nr
                                        // }

                                    }

                                }
                            }

                        }
                    })
                }
            }

        } else if (item.dcfId === 10) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 5 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0136 })
                                    console.log(sc_index, sc.DPA0136, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                    if (sc_index !== -1) {
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0138) * ef.co2e)) / 1000).toFixed(3))
                                        console.log(sc.DPA0138, ef)
                                        let gas_type_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0136 })
                                        let gas_type = 'Other'
                                        if (gas_type_index !== -1) {
                                            gas_type = subcat.one[gas_type_index].title
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.gas_type === gas_type })
                                        // if (month_index === -1) {
                                        scope = 1
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, gas_type, usage: parseFloat(sc.DPA0138) }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, gas_type, usage: parseFloat(sc.DPA0138) })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].usage += parseFloat(sc.DPA0138)
                                        // }

                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 16) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 11 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0287 })

                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0288) * parseFloat(sc.DPA0289) * ef.co2e)) / 1000).toFixed(3))

                                        let item_class_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0287 })
                                        let item_class = 'Other'
                                        if (item_class_index !== -1) {
                                            item_class = subcat.one[item_class_index].title
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.item_class === item_class })
                                        // if (month_index === -1) {
                                        scope = 1
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, item_class }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, item_class })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        // }

                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                if (!sc.DP_MODE) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPA0141 === 2) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 7 })
                                        } else if (sc.DPA0141 === 13) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                        } else if (sc.DPA0141 === 19) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })

                                        }
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e)) / 1000).toFixed(3))

                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0141 })
                                            let fuel_type = 'Other'
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.type === 'byFuel' && i.fuel_type === fuel_type })
                                            // if (month_index === -1) {
                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byFuel' }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byFuel' })
                                            // } else {
                                            //     month_data[month_index].ghg += ghg
                                            // }

                                        }

                                    }
                                } else {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e)) / 1000).toFixed(3))

                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0139 })
                                            let fuel_type = 'Other'
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 1 && i.fuel_type === fuel_type && i.type === 'byDistance' })
                                            // if (month_index === -1) {
                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byDistance' }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, fuel_type, type: 'byDistance' })
                                            // } else {
                                            //     month_data[month_index].ghg += ghg
                                            // }

                                        }

                                    }
                                }

                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 257) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {

                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(sc, item.id)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i, j) => { return j === 0 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN095 && i.subcategory2 === sc.DPAN096 && i.subcategory3 === sc.DPAN099 })
                                    console.log(sc_index)
                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = 0

                                        console.log(sc.DPA0138, ef)
                                        let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                        let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                        let type = 'Other', source_type = 'Other', r = 0, nr = 0
                                        if (type_index !== -1) {
                                            type = subcat.one[type_index].title
                                        }
                                        if (source_index !== -1) {

                                            source_type = subcat.two[source_index].title
                                            console.log(source_type, sc.DPAN096)
                                            if (source_type.trim().toLowerCase().includes('non')) {
                                                ghg = parseFloat((((parseFloat(sc.DPAN098) * ef.co2e)) / 1000).toFixed(3))
                                                nr = parseFloat(sc.DPAN098 / 1000)
                                            } else {
                                                r = parseFloat(sc.DPAN098 / 1000)
                                            }
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                        // if (month_index === -1) {
                                        scope = 2
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 2, ghg, type, source_type, r, nr }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 2, ghg, type, source_type, r, nr })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].r += r
                                        //     month_data[month_index].nr += nr
                                        // }

                                    } else {

                                        if (subcat.one.map(i => i.id).includes(sc.DPAN095) && subcat.two.map(i => i.id).includes(sc.DPAN096) && subcat.three.map(i => i.id).includes(sc.DPAN099)) {
                                            let source_type = '', r = 0, nr = 0, type = ''
                                            let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                            let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                            type = subcat.one[type_index].title
                                            source_type = subcat.two[source_index].title
                                            if (!source_type.trim().toLowerCase().includes('non')) {
                                                r = parseFloat(sc.DPAN098 / 1000)
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                            // if (month_index === -1) {
                                            scope = 2
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef: {}, scope: 2, ghg: 0, type, source_type, r, nr }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef: {}, scope: 2, ghg: 0, type, source_type, r, nr })
                                            // } else {
                                            //     month_data[month_index].ghg += 0
                                            //     month_data[month_index].r += r
                                            //     month_data[month_index].nr += nr
                                            // }
                                        }
                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                if (sc.DP_MODE === true) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })
                                        console.log(sc_index)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e)) / 1000).toFixed(3))

                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0144) }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0144) })

                                        }

                                    }
                                } else {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })

                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPA0141 === 2) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === 2 && i.subcategory3 === 7 })
                                        } else if (sc.DPA0141 === 13) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                        } else {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })
                                        }
                                        if (sc_index !== -1) {

                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e)) / 1000).toFixed(3))
                                            scope = 1
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0143) }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 1, ghg, usage: parseFloat(sc.DPA0143) })

                                        }


                                    }

                                }
                            }


                        }
                    })
                }
            }
        } else if (item.dcfId === 36) {
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (sc.DPA0291 === 'Road') {
                            if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                                let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                                console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 6 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0295  && i.subcategory2 === sc.DPA0337 && i.subcategory3 === sc.DPA0338 && i.subcategory4 === sc.DPA0338G })
                                        console.log(sc_index, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                        if (sc_index !== -1) {
                                            console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0292) * ef.co2e)) / 1000).toFixed(3))
                                        

                                            scope = 3
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: parseFloat(sc.DPA0292) }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: parseFloat(sc.DPA0292) })
                                        
    
                                        }
    
                                    }
                                }
    
    
                            }
                        } else if (sc.DPA0291 === 'Air') {
                            scope = 3
                            let ghg = sc.DP_co2e_mt
                            month_data.push({ year: item.reporting_period[0].split('-')[1], scope: 3, ghg })

                        } else if (sc.DPA0291 === 'Rail') {
                            scope = 3
                            let ghg = parseFloat(sc.DPA0292) * sc.DP_KM * 0.00116
                            month_data.push({ year: item.reporting_period[0].split('-')[1], scope: 3, ghg })

                        }
                        
                    })
                }
            }
        } else if (item.dcfId === 292) {
            console.log(item)
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 42 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        
                                        if (sc.DPAN1150 === 3) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1155 })
                                        } else {
                                            if (sc.DPAN1151 === 316 || sc.DPAN1151 === 317) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1154 && i.subcategory4 === sc.DPAN1155 })
                                            } else {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1151 && i.subcategory2 === sc.DPAN1152 && i.subcategory3 === sc.DPAN1153 && i.subcategory4 === sc.DPAN1155 })
                                            }
                                        }

                                        const mode_options = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
                                        if (sc_index !== -1) {
                                            scope =3
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPAN1156) * parseFloat(sc.DPAN1157) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 })

                                        }

                                    }


                                }

                            })
                        }
                    })
                }
            }

        } else if (item.dcfId === 293) {
            console.log(item)
            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            dates.forEach((rpm) => {
                                let date_index = findIndexByDate(apief[standard_index].newEfDates, rpm)

                                if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {

                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 42 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                      
                                        if (sc.DPAN1208 === 3) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1213 })
                                        } else {
                                            if (sc.DPAN1209 === 316 || sc.DPAN1209 === 317) {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1212 && i.subcategory4 === sc.DPAN1213 })
                                            } else {
                                                sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN1209 && i.subcategory2 === sc.DPAN1210 && i.subcategory3 === sc.DPAN1211 && i.subcategory4 === sc.DPAN1213 })
                                            }
                                        }

                                        const mode_options = [{ name: 'Road', id: 1 }, { name: 'Air', id: 2 }, { name: 'Rail', id: 3 }, { name: 'Sea', id: 4 }]
                                        if (sc_index !== -1) {
                                            scope =3
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPAN1214) * parseFloat(sc.DPAN1215) * ef.co2e) / dates.length) / 1000).toFixed(3))

                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 3, ghg, usage: 0 })


                                        }

                                    }


                                }

                            })
                        }
                    })
                }
            }

        }

        item.ghg = month_data.map(i => i.ghg).reduce((a, b) => a + b, 0)
        item.scope = scope

        // DPA0292

        return month_data
    }
 
    const isDateWithinPeriod = (rp, period) => {
        // Convert date strings to Luxon DateTime objects
        const rpDates = rp.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));
        const periodDates = period.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));

        // Find the matching dates in rp within the period
        const matchingDates = rpDates.filter(rpDate =>
            periodDates.some(periodDate => rpDate.toMillis() === periodDate.toMillis())
        );

        return matchingDates.length > 0 ? { match: true, dates: matchingDates.map(date => date.toFormat('MM-yyyy')) } : { match: false, dates: null };
    };
    const getBetweenMonths = (year, fymonth) => {
        const months = [];

        // Handle year input in the format "YYYY" or "YYYY-YY"
        let startYear;
        let endYear;
        if (year.includes('-')) {
            [startYear, endYear] = year.split('-').map(part => parseInt(part));
            startYear = parseInt(startYear);
            endYear = endYear.length === 2 ? parseInt(`20${endYear}`) : parseInt(endYear); // Handle two-digit year
        } else {
            startYear = parseInt(year);
            endYear = startYear;
        }

        // Case 1: FY starts in January
        if (fymonth === 1) {
            for (let i = 1; i <= 12; i++) {
                const month = DateTime.fromObject({ year: endYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
        } else {
            // Case 2: FY starts in a month other than January
            for (let i = fymonth; i <= 12; i++) {
                const month = DateTime.fromObject({ year: startYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
            for (let i = 1; i < fymonth; i++) {
                const month = DateTime.fromObject({ year: endYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
        }

        return months;
    }
    const findIndexByDate = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy');

        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        let foundIndex = -1;

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            const endDate = DateTime.fromISO(obj.end);

            return startDate <= targetDate && targetDate <= endDate;
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date lesser than passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        let lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start);
            const currStartDate = DateTime.fromISO(curr.start);
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };
    let filteredData = filterResponsesByPeriod(obj.response, obj.fymonth, getFiscalYearString(obj.year))
    filteredData.forEach(i => {
        console.log(getEmissionFactorCalculation(i, getFiscalYearString(obj.year), i.dcfId))

    })
    return filteredData
}

const getFiscalYearString = (year) => {
    // Ensure year is a number
    year = Number(year);

    // Calculate the previous year
    const prevYear = year - 1;

    // Format the fiscal year string
    const fiscalYearString = `${prevYear}-${String(year).slice(2)}`;

    return fiscalYearString;
};
