import React, { useRef } from "react";

function AddList(props) {
    const topicTitle = useRef();

    const handleAddTitle = () => {
        props.onClick(topicTitle);
    }

    return (
        <>
            <input className="form-control w-100 mb-2" ref={topicTitle} type="search" placeholder="Enter Title"/>
            <button onClick={handleAddTitle} className="btn btn-primary btn-block mb-2">Add {props.title}</button>
        </>
    );

}

export default AddList;