import React, { useEffect, useState } from "react";
import useForceUpdate from "use-force-update";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { API } from "../../../constants/api_url";
import { DateTime } from "luxon";
import moment from "moment";
import APIServices from "../../../service/APIService";
const AssuranceReport = () => {

    const forceUpdate = useForceUpdate()
    const admin_data = useSelector((state) => state.user.admindetail);
    const locationList = useSelector(state => state.sitelist.locationList)
    const siteList = useSelector((state) => state.sitelist.siteList)
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const [year, setYear] = useState([])
    const [data, setData] = useState([])
    const [apief, setApiEF] = useState([])
    const [subcat, setSubCat] = useState({ one: [], two: [], three: [], four: [] })
    const [response, setResponse] = useState([])
    const [dcfdata, setDCFData] = useState([])
    const [dcflisitingmodal, setDCFListingModal] = useState(false)
    const [individualResponseView, setIndividualResponseView] = useState(false)
    const [individualResponseData, setIndividualResponseData] = useState(null)
    const [efSourceData, setEFSourceData] = useState({ description: '', unit: '', methodology: '', quality: '', notes: '', co2e: 0, co2: 0, ch4: 0, n2o: 0 })
    const [efSourceModal, setEFSourceModal] = useState(false)
    const [rawsitelist, setRawSitelist] = useState([]);
    useEffect(() => {

        setYear(calculateYears(admin_data.information.startdate, fymonth))
        let metricString = {
            "include": [{ "relation": "newDataPoints" }]

        }
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let ef_complete = API.EF_Std + `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`
        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.QN_Submit_UP(admin_data.id))
        const promise3 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise4 = APIServices.get(API.AssignDCFUser_UP(admin_data.id))
        const promise5 = APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(metricString))}`)
        const promise6 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        const promise7 = APIServices.get(API.QNDP_Report_UP(admin_data.id))
        const promise8 = APIServices.get(ef_complete)
        const promise9 = APIServices.get(API.EF_SC1)
        const promise10 = APIServices.get(API.EF_SC2)
        const promise11 = APIServices.get(API.EF_SC3)
        const promise12 = APIServices.get(API.EF_SC4)
        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11, promise12]).then((values) => {
            let indicators = [], val = []
            let betweenYears = []
            const shapedSite = values[2].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);

            setRawSitelist(shapedSite);
            values[1].data.forEach((x) => {
                x.coverage = getCoverageTextById(x, shapedSite)
                x.rp = getRPTextFormat(x.reporting_period)
                x.dcf = values[0].data.find(i => i.id === x.dcfId).title
            })
            setResponse(values[1].data)
            setApiEF(values[7].data)
            let loc_subcat = subcat
            loc_subcat.one = values[8].data
            loc_subcat.two = values[9].data
            loc_subcat.three = values[10].data
            loc_subcat.four = values[11].data
            setSubCat(loc_subcat)
            let filtered_ind = []
            values[5].data[0].metric_ids.forEach((id) => {
                let dcf = []
                let index = values[4].data.findIndex((x) => x.id === id)
                if (index !== -1) {
                    filtered_ind.push(values[4].data[index])
                    let loc = JSON.parse(JSON.stringify(values[4].data[index]))
                    if (loc.data1.length !== 0 && loc.data1[0].type === 0 && loc.data1[0].source === 0) {
                        let dcf_ids = []
                        getStandaloneIds(loc, values[4].data).forEach((met_id) => {
                            let find = values[4].data.findIndex((l) => { return l.id === met_id })
                            if (find !== -1 && values[4].data[find].newDataPoints !== undefined) {

                                values[4].data[find].newDataPoints.forEach((dp) => {
                                    if (Array.isArray(dp.data1)) {
                                        !dcf_ids.includes(dp.data1[0].datasource) && dcf_ids.push(dp.data1[0].datasource)
                                    }
                                })
                            }
                        })
                        betweenYears.forEach((yr) => {
                            loc[yr] = getDataByIndicator(dcf_ids, yr, val)
                        })
                        loc.dcf = dcf_ids
                        delete loc.newDataPoints

                        indicators.push(loc)
                    } else if (loc.newDataPoints !== undefined && loc.data1.length !== 0 && loc.data1[0].type === 0 && loc.data1[0].source === 1) {
                        let dcf_ids = []
                        loc.newDataPoints.forEach((dp) => {
                            if (Array.isArray(dp.data1)) {
                                !dcf_ids.includes(dp.data1[0].datasource) && dcf_ids.push(dp.data1[0].datasource)
                            }
                        })
                        betweenYears.forEach((yr) => {
                            loc[yr] = getDataByIndicator(dcf_ids, yr, val)
                        })
                        loc.dcf = dcf_ids
                        delete loc.newDataPoints

                        indicators.push(loc)
                    }

                }
            })

            let ind_obj = []
            let indicator_list = values[4].data
            indicatorLevel1(filtered_ind, values[4].data, betweenYears, val).forEach((i) => {
                i.indicator = i.title
                i.data1[0].indicator.forEach((j) => {
                    let indexdcf = indicator_list.findIndex(a => a.id === j)
                    if (indexdcf !== -1) {
                        ind_obj.push({ ...i, ...indicator_list[indexdcf] })
                    }
                })
            })
            console.log(ind_obj, indicatorLevel1(filtered_ind, values[4].data, betweenYears, val))
            setData(ind_obj)


        })

    }, [])
    const getCoverageTextById = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.tier0_id !== null) {
            text = "Corporate";
        } else if (rowData.tier1_id !== null) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.tier1_id
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.tier2_id !== null) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.tier2_id;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.tier3_id !== null) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.tier3_id;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };
    const getStandaloneIds = (met, metric) => {
        let ids = []
        const collectStandaloneIds = (met, ids) => {

            for (const item of met) {
                console.log(item)
                if (item.data1[0].type === 0 && item.data1[0].source === 0) {
                    // Recur for the indicator array if available
                    if (Array.isArray(item.data1[0].indicator)) {
                        let ind = []
                        item.data1[0].indicator.forEach((id) => {
                            let index = metric.findIndex((k) => { return k.id === id })
                            if (index !== -1) {
                                ind.push(metric.find((k) => { return k.id === id }))
                            }
                        })
                        console.log(item)
                        collectStandaloneIds(ind, ids);
                    }
                } else if (item.data1[0].type === 0 && item.data1[0].source === 1) {

                    ids.push(item.id);
                }
            }
        };
        collectStandaloneIds([met], ids)
        return ids
    }
    const indicatorLevel1 = (data, metric_list, betweenYears, val) => {
        const standaloneItems = [];

        let derived = data.filter(i => Array.isArray(i.data1) && i.data1[0].source === 0)
        derived.forEach((i, index) => {
            let count = 0
            let dcf_ids = []

            if (Array.isArray(i.data1)) {

                i.data1[0].indicator.forEach((j) => {
                    count += 1
                    let index = data.findIndex(k => k.id === j)
                    let index2 = metric_list.findIndex(k => k.id === j)
                    if (index !== -1 && index2 !== -1 && Array.isArray(data[index].data1)) {
                        if (data[index].data1[0].source === 1) {

                            if (metric_list[index2].newDataPoints !== undefined) {
                                metric_list[index2].newDataPoints.forEach((m) => {
                                    if (Array.isArray(m.data1)) {
                                        !dcf_ids.includes(m.data1[0].datasource) && dcf_ids.push(m.data1[0].datasource)
                                    }
                                })
                            }
                            count -= 1
                        }
                    } else {
                        count -= 1
                    }

                })
                if (count === 0) {
                    betweenYears.forEach((yr) => {
                        i[yr] = getDataByIndicator(dcf_ids, yr, val)
                    })
                    i.dcf = dcf_ids
                    standaloneItems.push(i)
                }
            }


        })
        return standaloneItems;
    };
    const getDataByIndicator = (indi_id, year) => {

        return year
    }
    function calculateYears(startDateStr, monthNo) {
        const startDate = DateTime.fromISO(startDateStr, { zone: 'utc' }).plus({ month: 1 });
        const currentDate = DateTime.utc();
        const startYear = startDate.year;
        const currentYear = currentDate.year;

        let years = [];

        // Case 1: Month number is January (1)
        if (monthNo === 1) {
            for (let year = startYear; year <= currentYear; year++) {
                years.push(year.toString());
            }
        }
        // Case 2 and Case 3: Month number is not January
        else {
            let fiscalStartYear = startYear;
            // Adjust fiscal start year based on start date month
            if (startDate.month < monthNo) {
                fiscalStartYear--;
            }

            let fiscalEndYear = fiscalStartYear + 1;

            while (fiscalEndYear <= currentYear + 1) {
                const fiscalYearString = `${fiscalStartYear}-${(fiscalEndYear).toString().slice(-2)}`;
                years.push(fiscalYearString);

                fiscalStartYear++;
                fiscalEndYear++;
            }

            // Remove the last fiscal year if it exceeds the current date
            const lastFiscalYearEnd = DateTime.fromObject({ year: currentYear, month: monthNo, day: 1 }, { zone: 'utc' }).minus({ day: 1 });
            if (currentDate < lastFiscalYearEnd) {
                years.pop();
            }
        }

        return years;
    }

    const dataTemplate = (rowData, yr) => {

        let ghg = 0, submissiondata = []
        if (rowData.newDataPoints) {
            let ndp_dcfs = rowData.newDataPoints.map(i => Array.isArray(i.data1) ? i.data1[0].datasource : null).filter(i => i !== null)
            submissiondata = renderData({ year: yr, dcfId: ndp_dcfs })
            let calcval = submissiondata.reduce((a, b) => { return a + b.ghg }, 0)
            ghg = calcval ? (calcval).toFixed(3) : 0
            console.log('data_', submissiondata)
        }

        return (<>
            <a style={{ textDecoration: ghg ? 'underline' : 'none', cursor: ghg ? 'pointer' : 'default' }} onClick={() => {

                if (ghg) {
                    setDCFData(submissiondata)
                    setDCFListingModal(true)
                }
            }}> {ghg}</a>
        </>)
    }
  
    function filterResponsesByPeriod(responses, month, year) {
        const isFiscalYear = year.includes('-');
        let startYear, endYear;

        if (isFiscalYear) {
            const [start, end] = year.split('-').map(Number);
            startYear = start;
            endYear = start + 1;
        } else {
            startYear = endYear = parseInt(year, 10);
        }

        return responses.filter(response => {
            return response.reporting_period.some(period => {
                const [periodMonth, periodYear] = period.split('-').map(Number);
                if (isFiscalYear) {
                    // Check if the periodYear is within the fiscal year range
                    return (periodYear === startYear && periodMonth >= month) ||
                        (periodYear === endYear && periodMonth <= month);
                } else {
                    // Check if the periodMonth and periodYear match the provided month and year
                    return periodMonth === month && periodYear === startYear;
                }
            });
        });
    }
    const getBetweenMonths = (year, fymonth) => {
        const months = [];

        // Handle year input in the format "YYYY" or "YYYY-YY"
        let startYear;
        let endYear;
        if (year.includes('-')) {
            [startYear, endYear] = year.split('-').map(part => parseInt(part));
            startYear = parseInt(startYear);
            endYear = endYear.length === 2 ? parseInt(`20${endYear}`) : parseInt(endYear); // Handle two-digit year
        } else {
            startYear = parseInt(year);
            endYear = startYear;
        }

        // Case 1: FY starts in January
        if (fymonth === 1) {
            for (let i = 1; i <= 12; i++) {
                const month = DateTime.fromObject({ year: endYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
        } else {
            // Case 2: FY starts in a month other than January
            for (let i = fymonth; i <= 12; i++) {
                const month = DateTime.fromObject({ year: startYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
            for (let i = 1; i < fymonth; i++) {
                const month = DateTime.fromObject({ year: endYear, month: i });
                const formattedMonth = month.toFormat('MM-yyyy');
                months.push(formattedMonth);
            }
        }

        return months;
    }
    const isDateWithinPeriod = (rp, period) => {
        // Convert date strings to Luxon DateTime objects
        const rpDates = rp.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));
        const periodDates = period.map(dateString => DateTime.fromFormat(dateString, 'MM-yyyy'));

        // Find the matching dates in rp within the period
        const matchingDates = rpDates.filter(rpDate =>
            periodDates.some(periodDate => rpDate.toMillis() === periodDate.toMillis())
        );

        return matchingDates.length > 0 ? { match: true, dates: matchingDates.map(date => date.toFormat('MM-yyyy')) } : { match: false, dates: null };
    };
    const findIndexByDate = (array, dateToFind) => {
        const targetDate = DateTime.fromFormat(dateToFind, 'MM-yyyy');

        if (array.length === 1) {
            return 0; // If array length is 1, return index 0
        }

        let foundIndex = -1;

        // Check for an object with both start and end not null and matching the passed date
        const matchedObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            const endDate = DateTime.fromISO(obj.end);

            return startDate <= targetDate && targetDate <= endDate;
        });

        if (matchedObject) {
            return array.indexOf(matchedObject);
        }

        // Check for an object with end as null and start date lesser than passed date
        const endIsNullObject = array.find(obj => {
            const startDate = DateTime.fromISO(obj.start);
            return obj.end === null && startDate <= targetDate;
        });

        if (endIsNullObject) {
            return array.indexOf(endIsNullObject);
        }

        // If none of the above conditions match, find the object with the lesser start date
        let lesserStartDateObject = array.reduce((prev, curr) => {
            const prevStartDate = DateTime.fromISO(prev.start);
            const currStartDate = DateTime.fromISO(curr.start);
            return prevStartDate < currStartDate ? prev : curr;
        });

        return array.indexOf(lesserStartDateObject);
    };
    const getEmissionFactorCalculation = (item, year) => {
        let betweenMonths = getBetweenMonths(year, fymonth)
        console.log(betweenMonths)
        let month_data = []
        if (item.dcfId === 11) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {

                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                console.log('Matched_SC', standard_index)
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {
                            console.log('Dates_SC', dates)

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0130 && i.subcategory2 === sc.DPA0131 && i.subcategory3 === sc.DPA0132 })
                                    console.log(sc_index, sc)
                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0336) * ef.co2e)) / 1000).toFixed(3))
                                        console.log(sc.DPA0336, ef)
                                        let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0131 })
                                        let unit_index = subcat.three.findIndex((i) => { return i.id === sc.DPA0132 })
                                        let fuel_cat_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0130 })
                                        let unit_type = 'NA', cat_type = 'NA'
                                        let fuel_type = 'Other', usage = 0, r = 0, nr = 0
                                        if (fuel_type_index !== -1) {
                                            fuel_type = subcat.two[fuel_type_index].title
                                        }
                                        if (unit_index !== -1) {
                                            unit_type = subcat.three[fuel_type_index].title
                                        }
                                        if (fuel_cat_index !== -1) {
                                            cat_type = subcat.one[fuel_cat_index].title
                                        }
                                        if (unit_index !== -1) {


                                            if (unit_type.includes('ton')) {
                                                if (fuel_cat_index !== -1) {
                                                    cat_type = subcat.one[fuel_cat_index].title

                                                    if (cat_type.trim().toLowerCase().includes('bio')) {
                                                        r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))
                                                    } else {

                                                        nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10800 * 4.187) / 1000000000).toFixed(3))

                                                    }
                                                }
                                                usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3))

                                            } else {
                                                if (fuel_cat_index !== -1) {


                                                    if (cat_type.trim().toLowerCase().includes('bio')) {
                                                        r = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                    } else {

                                                        nr = parseFloat((((parseFloat(sc.DPA0336) * 0.82) * 10.8 * 4.187) / 1000000000).toFixed(3))
                                                    }
                                                }
                                                usage = parseFloat(sc.DPA0336)
                                            }
                                        }
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, cat_type, unit_type, fuel_type, usage, r, nr }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.fuel_type === fuel_type && i.unit_type === unit_type && i.cat_type === cat_type })
                                        // if (month_index === -1) {
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, cat_type, unit_type, fuel_type, usage, r, nr })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].usage += usage
                                        //     month_data[month_index].r += r
                                        //     month_data[month_index].nr += nr
                                        // }

                                    }

                                }
                            }

                        }
                    })
                }
            }

        } else if (item.dcfId === 10) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(date_index, apief[standard_index].newEfDates[date_index].newEfs)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 5 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0136 })
                                    console.log(sc_index, sc.DPA0136, apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems)
                                    if (sc_index !== -1) {
                                        console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index], sc)
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0138) * ef.co2e)) / 1000).toFixed(3))
                                        console.log(sc.DPA0138, ef)
                                        let gas_type_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0136 })
                                        let gas_type = 'Other'
                                        if (gas_type_index !== -1) {
                                            gas_type = subcat.one[gas_type_index].title
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.gas_type === gas_type })
                                        // if (month_index === -1) {
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, gas_type, usage: parseFloat(sc.DPA0138) }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, gas_type, usage: parseFloat(sc.DPA0138) })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].usage += parseFloat(sc.DPA0138)
                                        // }

                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 16) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 11 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0287 })

                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = parseFloat((((parseFloat(sc.DPA0288) * parseFloat(sc.DPA0289) * ef.co2e)) / 1000).toFixed(3))

                                        let item_class_index = subcat.one.findIndex((i) => { return i.id === sc.DPA0287 })
                                        let item_class = 'Other'
                                        if (item_class_index !== -1) {
                                            item_class = subcat.one[item_class_index].title
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.item_class === item_class })
                                        // if (month_index === -1) {
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, item_class }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, item_class })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        // }

                                    }

                                }
                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 15) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {
                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])

                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                if (!sc.DP_MODE) {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === 1 })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = -1
                                        if (sc.DPA0141 === 2) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 1 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 7 })
                                        } else if (sc.DPA0141 === 13) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 51 })
                                        } else if (sc.DPA0141 === 19) {
                                            sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === 4 && i.subcategory2 === sc.DPA0141 && i.subcategory3 === 75 })

                                        }
                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0143) * ef.co2e)) / 1000).toFixed(3))

                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0141 })
                                            let fuel_type = 'Other'
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.type === 'byFuel' && i.fuel_type === fuel_type })
                                            // if (month_index === -1) {
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, fuel_type, type: 'byFuel' }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, fuel_type, type: 'byFuel' })
                                            // } else {
                                            //     month_data[month_index].ghg += ghg
                                            // }

                                        }

                                    }
                                } else {
                                    let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i) => { return i.category === sc.DPGMode })
                                    console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                    if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                        let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPA0140 && i.subcategory2 === sc.DPA0139 && i.subcategory3 === sc.DPA0141 && i.subcategory4 === sc.DPA0339 })

                                        if (sc_index !== -1) {
                                            let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                            let ghg = parseFloat((((parseFloat(sc.DPA0144) * ef.co2e)) / 1000).toFixed(3))

                                            let fuel_type_index = subcat.two.findIndex((i) => { return i.id === sc.DPA0139 })
                                            let fuel_type = 'Other'
                                            if (fuel_type_index !== -1) {
                                                fuel_type = subcat.two[fuel_type_index].title
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 'scope1' && i.fuel_type === fuel_type && i.type === 'byDistance' })
                                            // if (month_index === -1) {
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, fuel_type, type: 'byDistance' }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 'scope1', ghg, fuel_type, type: 'byDistance' })
                                            // } else {
                                            //     month_data[month_index].ghg += ghg
                                            // }

                                        }

                                    }
                                }

                            }


                        }
                    })
                }
            }

        } else if (item.dcfId === 257) {

            const { match, dates } = isDateWithinPeriod(item.reporting_period, betweenMonths)
            if (match) {
                const standard_index = apief.findIndex((i) => { return i.id === item.standard })
                if (standard_index !== -1) {
                    item.response.forEach((sc) => {

                        if (standard_index !== -1 && apief[standard_index].newEfDates !== undefined && apief[standard_index].newEfDates.length !== 0) {

                            let date_index = findIndexByDate(apief[standard_index].newEfDates, item.reporting_period[0])
                            console.log(sc, item.id)
                            if (date_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs !== undefined && apief[standard_index].newEfDates[date_index].newEfs.length !== 0) {
                                let cat_index = apief[standard_index].newEfDates[date_index].newEfs.findIndex((i, j) => { return j === 0 })
                                console.log(apief[standard_index].newEfDates[date_index].newEfs[cat_index])
                                if (cat_index !== -1 && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems !== undefined && apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.length !== 0) {
                                    let sc_index = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems.findIndex(i => { return i.subcategory1 === sc.DPAN095 && i.subcategory2 === sc.DPAN096 && i.subcategory3 === sc.DPAN099 })
                                    console.log(sc_index)
                                    if (sc_index !== -1) {
                                        let ef = apief[standard_index].newEfDates[date_index].newEfs[cat_index].newEfItems[sc_index]
                                        let ghg = 0

                                        console.log(sc.DPA0138, ef)
                                        let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                        let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                        let type = 'Other', source_type = 'Other', r = 0, nr = 0
                                        if (type_index !== -1) {
                                            type = subcat.one[type_index].title
                                        }
                                        if (source_index !== -1) {

                                            source_type = subcat.two[source_index].title
                                            console.log(source_type, sc.DPAN096)
                                            if (source_type.trim().toLowerCase().includes('non')) {
                                                ghg = parseFloat((((parseFloat(sc.DPAN098) * ef.co2e)) / 1000).toFixed(3))
                                                nr = parseFloat(sc.DPAN098 / 1000)
                                            } else {
                                                r = parseFloat(sc.DPAN098 / 1000)
                                            }
                                        }
                                        let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                        // if (month_index === -1) {
                                        sc.efdata = { year: item.reporting_period[0].split('-')[1], ef, scope: 2, ghg, type, source_type, r, nr }
                                        month_data.push({ year: item.reporting_period[0].split('-')[1], ef, scope: 2, ghg, type, source_type, r, nr })
                                        // } else {
                                        //     month_data[month_index].ghg += ghg
                                        //     month_data[month_index].r += r
                                        //     month_data[month_index].nr += nr
                                        // }

                                    } else {

                                        if (subcat.one.map(i => i.id).includes(sc.DPAN095) && subcat.two.map(i => i.id).includes(sc.DPAN096) && subcat.three.map(i => i.id).includes(sc.DPAN099)) {
                                            let source_type = '', r = 0, nr = 0, type = ''
                                            let source_index = subcat.two.findIndex((i) => { return i.id === sc.DPAN096 })
                                            let type_index = subcat.one.findIndex((i) => { return i.id === sc.DPAN095 })
                                            type = subcat.one[type_index].title
                                            source_type = subcat.two[source_index].title
                                            if (!source_type.trim().toLowerCase().includes('non')) {
                                                r = parseFloat(sc.DPAN098 / 1000)
                                            }
                                            let month_index = month_data.findIndex((i) => { return i.scope === 2 && i.source_type === source_type && i.type === type })
                                            // if (month_index === -1) {
                                            sc.efdata = { year: item.reporting_period[0].split('-')[1], ef: {}, scope: 2, ghg: 0, type, source_type, r, nr }
                                            month_data.push({ year: item.reporting_period[0].split('-')[1], ef: {}, scope: 2, ghg: 0, type, source_type, r, nr })
                                            // } else {
                                            //     month_data[month_index].ghg += 0
                                            //     month_data[month_index].r += r
                                            //     month_data[month_index].nr += nr
                                            // }
                                        }
                                    }

                                }
                            }


                        }
                    })
                }
            }

        }
        item.ghg = month_data.map(i => i.ghg).reduce((a, b) => a + b, 0)
        if (month_data.length && (item.dcfId === 10 || item.dcfId === 11 || item.dcfId === 16)) {
            console.log(item.response, item.ghg, 'find')
        }


        return month_data
    }
    const renderData = (search) => {

        let filteredData = filterResponsesByPeriod(response, fymonth, search.year).filter(i => search.dcfId.includes(i.dcfId))
        filteredData.forEach(i => {
            console.log(getEmissionFactorCalculation(i, search.year), i.dcfId)

        })
        return filteredData
    }
    const handleEFSource = (rowData) => {

        setEFSourceData(rowData.efdata.ef)

        setEFSourceModal(true)
    }
    const subcategory1Template = (rowData, dpid, subcatlist) => {
        console.log(rowData)
        let text = 'Deleted'
        let index = subcatlist.findIndex((i) => { return i.id === rowData[dpid] })
        if (index !== -1) {
            text = subcatlist[index].title
        }
        return (<>{text}</>)
    }
    const renderResponseDataInModal = () => {
        if (individualResponseData !== null && individualResponseData.dcfId === 11) {

            return (
                <div>
                    <DataTable
                        value={individualResponseData.response}
                        scrollable
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"


                        emptyMessage="No records found."

                        responsiveLayout="scroll"
                    >
                        <Column
                            body={(rowData) => { return subcategory1Template(rowData, 'DPA0130', subcat.one) }}
                            header="Fuel Category"
                            sortable
                            headerStyle={{
                                width: "25%",
                                minWidth: "8rem",
                            }}
                        />
                        <Column
                            body={(rowData) => { return subcategory1Template(rowData, 'DPA0131', subcat.two) }}
                            header="Fuel Type"
                            sortable
                            headerStyle={{
                                width: "25%",
                                minWidth: "8rem",
                            }}
                        />
                        <Column
                            body={(rowData) => { return subcategory1Template(rowData, 'DPA0132', subcat.three) }}
                            header="Unit"
                            sortable
                            headerStyle={{
                                width: "12%",
                                minWidth: "8rem",
                            }}
                        />
                        <Column
                            field="DPA0336"
                            header="Quantity Used"
                            headerStyle={{
                                width: "10%",
                                minWidth: "8rem",
                            }}
                        />
                        <Column
                            field="efdata.ghg"
                            header="GHG (in tCo2e)"
                            headerStyle={{
                                width: "10%",
                                minWidth: "8rem",
                            }}
                        />

                        <Column
                            body={(rowData) => { return <> <i className="pi pi-eye" onClick={() => { handleEFSource(rowData) }}></i> </> }}
                            header="Source"
                            headerStyle={{
                                width: "10%",
                                minWidth: "8rem",
                            }}
                        />
                    </DataTable>
                </div>
            )
        } else if (individualResponseData !== null && individualResponseData.dcfId === 10) {
            return (<DataTable
                value={individualResponseData.response}
                scrollable
                dataKey="id"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"


                emptyMessage="No records found."

                responsiveLayout="scroll"
            >

                <Column
                    field="DPA0135"
                    header="AC Type"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return subcategory1Template(rowData, 'DPA0136', subcat.one) }}
                    header="Fuel Category"
                    sortable
                    headerStyle={{
                        width: "25%",
                        minWidth: "8rem",
                    }}
                />


                <Column
                    field="DPA0138"
                    header="Gas Refilled"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="efdata.ghg"
                    header="GHG (in tCo2e)"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return <> <i className="pi pi-eye" onClick={() => { handleEFSource(rowData) }}></i> </> }}
                    header="Source"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />



            </DataTable>)

        } else if (individualResponseData !== null && individualResponseData.dcfId === 16) {
            return (<DataTable
                value={individualResponseData.response}
                scrollable
                dataKey="id"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"


                emptyMessage="No records found."

                responsiveLayout="scroll"
            >

                <Column
                    field="DPA0286"
                    header="Item Name"
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0287.name"
                    header="Item Classification"
                    body={(rowData) => { return subcategory1Template(rowData, 'DPA0287', subcat.one) }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />

                <Column
                    field="DPA0288"
                    header="Quantity Purchased"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0289"
                    header="Cost per Item "
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="efdata.ghg"
                    header="GHG (in tCo2e)"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return <> <i className="pi pi-eye" onClick={() => { handleEFSource(rowData) }}></i> </> }}
                    header="Source"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />



            </DataTable>)

        } else if (individualResponseData !== null && individualResponseData.dcfId === 15) {
            return (<DataTable
                value={individualResponseData.response}
                scrollable
                dataKey="id"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"


                emptyMessage="No records found."

                responsiveLayout="scroll"
            >

                <Column
                    header="Data Captured by"
                    body={(rowData) => {
                        return <span>{rowData.DP_MODE ? "by Distance" : "by Fuel"}</span>;
                    }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0140.name"
                    header="Vehicle Category"
                    body={(rowData) => { return subcategory1Template(rowData, 'DPA0140', subcat.one) }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0139.name"
                    header="Vehicle Type"
                    body={(rowData) => { return subcategory1Template(rowData, 'DPA0139', subcat.two) }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPA0141.name"
                    header="Fuel type"
                    body={(rowData) => { return subcategory1Template(rowData, 'DPA0141', subcat.three) }}
                    headerStyle={{
                        width: "30%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="efdata.ghg"
                    header="GHG (in tCo2e)"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return <> {rowData.efdata && <i className="pi pi-eye" onClick={() => { handleEFSource(rowData) }}></i> }</> }}
                    header="Source"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />



            </DataTable>)

        } else if (individualResponseData !== null && individualResponseData.dcfId === 257) {
            return (<DataTable
                value={individualResponseData.response}
                scrollable
                dataKey="id"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                emptyMessage="No records found."
                responsiveLayout="scroll"
            >

                <Column
                    body={(rowData) => { return subcategory1Template(rowData, 'DPAN095', subcat.one) }}
                    header="Type of Energy used"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return subcategory1Template(rowData, 'DPAN096', subcat.two) }}
                    header="Source of Energy"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return subcategory1Template(rowData, 'DPAN099', subcat.three) }}
                    header="Unit"

                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN098"
                    header="Quantity Used"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="DPAN097"
                    header="Source from where the energy is procured"
                    headerStyle={{

                        minWidth: "8rem",
                    }}
                />
                <Column
                    field="efdata.ghg"
                    header="GHG (in tCo2e)"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />
                <Column
                    body={(rowData) => { return  <> {rowData.efdata && <i className="pi pi-eye" onClick={() => { handleEFSource(rowData) }}></i>} </> }}
                    header="Source"
                    headerStyle={{
                        width: "10%",
                        minWidth: "8rem",
                    }}
                />



            </DataTable>)

        }
    }
    const handleResponse = (rowData) => {

        setIndividualResponseData(rowData)
        setIndividualResponseView(true)



    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <>
                        {(admin_data.role === 'clientadmin' || admin_data.role === 'clientuser') ?
                            <div>
                                <DataTable rowGroupMode="rowspan" groupRowsBy="indicator" sortMode="single" sortField="indicator" sortOrder={1} scrollable value={data}>

                                    <Column field="indicator" header="Indicator" style={{ minWidth: '200px' }}></Column>
                                    <Column field='title' header='' />
                                    {year.map((yr) => {
                                        return <Column body={(rowData) => dataTemplate(rowData, yr)} header={yr} />
                                    })

                                    }
                                </DataTable>
                                <Dialog
                                    visible={dcflisitingmodal}
                                    style={{
                                        width: "65%",
                                    }}
                                    header="Associated DCF"
                                    modal
                                    className="p-fluid"

                                    onHide={() => { setDCFListingModal(false) }}
                                >
                                    <div>
                                        <DataTable scrollable value={dcfdata} >
                                            <Column header="DCF#" field='dcf' />
                                            <Column header="Coverage" field='coverage' />
                                            <Column header="Reporting Period" field='rp' />
                                            <Column header="Submission" body={(rowData) => { return <> <i className="pi pi-eye" onClick={() => { handleResponse(rowData) }}></i> </> }} />

                                        </DataTable>
                                    </div>
                                </Dialog>
                                <Dialog visible={individualResponseView}
                                    style={{
                                        width: "65%",
                                    }}
                                    header="Associated Submission(s)"
                                    modal
                                    className="p-fluid"

                                    onHide={() => { setIndividualResponseView(false) }} >
                                    <div>
                                        {renderResponseDataInModal()}
                                    </div>
                                </Dialog>
                                <Dialog visible={efSourceModal}
                                    style={{
                                        width: "65%",
                                    }}
                                    header="Source of Emission Factor"
                                    modal
                                    className="p-fluid"

                                    onHide={() => { setEFSourceModal(false) }} >
                                    <div>
                                        <div className="col-12 lg:col-12">

                                            <div className="col-12 grid ">

                                                <div className="col-6 grid align-items-center">
                                                    <label className="col-5">
                                                        Unit
                                                    </label>
                                                    <div className="col-7">
                                                        <InputText type='text' disabled value={efSourceData.unit} />
                                                    </div>
                                                </div>
                                                <div className="col-6 grid align-items-center">
                                                    <label className="col-5">
                                                        Description
                                                    </label>
                                                    <div className="col-7">
                                                        <InputTextarea disabled value={efSourceData.description} type='text' />
                                                    </div>
                                                </div>
                                            </div>

                                            <fieldset style={{ borderRadius: 10 }} >

                                                <legend>Emission Factor Values</legend>
                                                <div className="col-12 grid">


                                                    <div className="col-12 grid justify-content-around">
                                                        <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                                            <span className="p-inputgroup-addon">co2e<span style={{ color: 'red' }}>*</span></span>
                                                            <InputText value={efSourceData.co2e} type={'number'} disabled />
                                                            <span className="p-inputgroup-addon">kg</span>
                                                        </div>
                                                        <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                                            <span className="p-inputgroup-addon">co2</span>
                                                            <InputText value={efSourceData.co2} placeholder="co2" type={'number'} disabled />
                                                            <span className="p-inputgroup-addon">kg</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 grid">


                                                    <div className="col-12 grid justify-content-around">
                                                        <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                                            <span className="p-inputgroup-addon">ch4</span>
                                                            <InputText value={efSourceData.ch4} placeholder="ch4" type={'number'} disabled />
                                                            <span className="p-inputgroup-addon">kg</span>
                                                        </div>

                                                        <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                                            <span className="p-inputgroup-addon">n2o</span>
                                                            <InputText value={efSourceData.n2o} placeholder="n2o" type={'number'} disabled />
                                                            <span className="p-inputgroup-addon">kg</span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </fieldset>


                                            <div className="col-12 grid">

                                                <div className="col-12 grid align-items-center">
                                                    <label className="col-2">
                                                        Methodology
                                                    </label>
                                                    <div className="col-10">
                                                        <div dangerouslySetInnerHTML={{ __html: efSourceData.methodology }} style={{ maxHeight: 200, overflow: 'auto' }}></div>


                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-12 grid">
                                                <div className="col-6 grid align-items-center">
                                                    <label className="col-5">
                                                        Data Quality
                                                    </label>
                                                    <div className="col-7">
                                                        <InputText type='text' disabled value={efSourceData.quality} />
                                                    </div>

                                                </div>
                                                <div className="col-6 grid align-items-center">
                                                    <label className="col-5">
                                                        Notes
                                                    </label>
                                                    <div className="col-7">
                                                        <InputTextarea disabled value={efSourceData.notes} type='text' />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>




                                    </div>
                                </Dialog>
                            </div> :
                            <div>
                                You have no rights to access this page
                            </div>
                        }
                    </>
                </div>
            </div>

        </div >
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AssuranceReport, comparisonFn);