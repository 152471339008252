import React, {  useEffect, useState } from 'react'
import { QUESTIONS_URL } from '../constants'
import PageTitle from '../shared/PageTitle'
import Breadcrumbs from '../shared/Breadcrumbs'
import AddList from '../shared/AddList'

import FormBuilder from '../shared/FormBuilder'
import List from '../shared/List'


function ManageQuestionnaire () {
   

    const [questions, setQuestions] = useState([])
    const [selectedQuestion, setSelectedQuestion] = useState('')
   
    const [questionData, setQuestionData] = useState({value: []})

    useEffect(() => {
      getQuestions();
    }, [])

    const  getQuestions = async () => {
      const response = await fetch(QUESTIONS_URL);
      if(response.ok) {
        const data = await response.json();
        setQuestions(data)
      }
    }

    const handleAddQuestionTitle = async (questionTitle) => {
      
      const response = await fetch(QUESTIONS_URL, {
        method: 'POST',
        body: JSON.stringify({
          name: questionTitle.current.value,
         
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json()

      setQuestions((prevState) => [...prevState, {id: data.id, name: data.name }])
     
    }

   
    const handleQuestionSelect = async (e, id) => {
      e.preventDefault();
      setSelectedQuestion('')
      
   
      const url = QUESTIONS_URL + '/' + id;
      const response = await fetch(url);
      if(response.ok) {
        const data = await response.json()
        setQuestionData(data)
        setSelectedQuestion(id)
      }
      
     
  
    }


   
    const handleQuestionChange = async (value, id) => {
    
      
      const url = QUESTIONS_URL + '/' + id;
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({
          name: value,
         
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });
     
     
      setQuestions((prevState) => prevState.map((i) => {
        if(i.id === id) {
          i.name = value
        }
        return i
      }))
  
    }



    const handleQuestionSubmit = async (value) => {
      
      const url = QUESTIONS_URL + '/' + selectedQuestion;
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({
          value: value,
         
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });

      
      setQuestionData((prevState) => { return {...prevState, value: value}})
    }

    const handleQuestionDelete = async (id) => {
    
      
      const url = QUESTIONS_URL + '/' + id;
      const response = await fetch(url, {
        method: 'DELETE',
        
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });
     
     
      setQuestions((prevState) => prevState.filter(i => i.id !== id))

      setQuestionData({})
      setSelectedQuestion('')
    

  
    } 

    return (
      <div>
        <div className="page-header">
        
          <PageTitle title="Questions Management" />
          <Breadcrumbs pages={['Manage Questions']} />
          
        </div>
        <div className="email-wrapper wrapper">
          <div className="row align-items-stretch">
            <div className="mail-list-container col-xl-5 pt-4 pb-4 pr-4 pl-4 border-right bg-white">
             
                  
                <AddList onClick={handleAddQuestionTitle} title='Questions' />
                
                { questions.length > 0 && <List topics={questions} onDelete={handleQuestionDelete} listId={selectedQuestion} onListChange={handleQuestionChange} onClick={handleQuestionSelect} />}

                { questions.length === 0 && <p className='text-center'>No Question Found</p>}
               
                
              
            </div>

            <div className="mail-list-container col-xl-7 pt-4 pb-4 pr-4 pl-4 border-right bg-white">
           
            { !selectedQuestion && <p className='text-center'>Please select any question to continue</p>}
              
            { selectedQuestion && <FormBuilder onSubmit={handleQuestionSubmit} id={questionData.id} values={questionData.value} />}
            
            </div>
           
          </div>
        </div>
      </div>
    )
  
}

export default ManageQuestionnaire
