import React, { useState, useEffect } from "react";

import { Tab, Row, Col, Nav, Table, Form, Modal, Accordion, Card } from 'react-bootstrap';

//import {_utilities} from '../SupplierScreen';

import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import Swal from "sweetalert2";
import moment from 'moment'
import questionary from './assessmentform'
import { InputTextarea } from 'primereact/inputtextarea'
import { RadioButton } from "primereact/radiobutton";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";




const SupplierPanel = ({ readOnly, users, auditId, updateData }) => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);

    const [activeIndex, setActiveIndex] = useState(0);
    const forceUpdate = useForceUpdate()
    const [headerValues, setHeaderValues] = useState({});
    const [files, setFiles] = useState([]);
    const [activeQuestionId, setActiveQuestionId] = useState(null);
    const [commentText, setCommentText] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    //    const [audit, setAudit] = useState({
    //     auditName: 'Sample Audit',
    //     auditDate: moment().format('YYYY-MM-DD'),
    //     // Add any other default fields that your audit object might need
    // });

    const [text, setText] = useState('')
    const [findings, setFindings] = useState('')

    //const [gmsOne, setGmsOne] = useState([])
    const [allGmsTwo, setAllGmsTwo] = useState([])
    // const [gmsTwo, setGmsTwo] = useState([])
    const [allGmsThree, setAllGmsThree] = useState([])
    //const [gmsThree, setGmsThree] = useState([])

    //const [selectedGmsOne, setSelectedGmsOne] = useState('')
    //const [selectedGmsTwo, setSelectedGmsTwo] = useState('')
    const [selectedGmsThree, setSelectedGmsThree] = useState('')


    const [questionaries, setQuestionaries] = useState(auditId.supplierAssignmentSubmission ? JSON.parse(auditId?.supplierAssignmentSubmission?.response) : questionary)
    const [gmsOne, setGmsOne] = useState([
        { id: 1, name: 'Supplier Environmental Framework' },
        { id: 2, name: 'Supplier Sustainability Ambassadorship Framework' },
        { id: 3, name: 'Supplier Social Stewardship Framework' },
        { id: 4, name: 'Supplier Occupational Health & Safety Framework' },
        { id: 5, name: 'Legal Compliances' },
        { id: 6, name: 'Supplier Governance Framework' },
    ]);
    console.log(questionary)
    const getSupplier = (id) => {

        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = users.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = users[index].information.supplierName
        }
        return user_name

    }
    // Mock data for GMS Two for each tab in GMS One
    const gmsTwoOptions = [

        { id: 1, name: 'Water', sectionId: 1 },
        { id: 2, name: 'Waste', sectionId: 1 },
        { id: 3, name: 'Energy', sectionId: 1 },
        { id: 4, name: 'Product Stewardship', sectionId: 1 },
        { id: 5, name: 'Water', sectionId: 2 },
        { id: 6, name: 'Energy', sectionId: 2 },
        { id: 7, name: 'General', sectionId: 2 },
        { id: 8, name: 'Waste', sectionId: 2 },
        { id: 9, name: 'Road Safety', sectionId: 2 },
        { id: 10, name: 'BioDiversity', sectionId: 2 },

        { id: 11, name: 'Child Labour', sectionId: 3 },
        { id: 12, name: 'Disciplinary Practices', sectionId: 3 },
        { id: 13, name: 'Discrimination', sectionId: 3 },
        { id: 14, name: 'Freedom of Association', sectionId: 3 },
        { id: 15, name: 'Forced or Compulsary Labour', sectionId: 3 },
        { id: 16, name: 'Remuneration', sectionId: 3 },
        { id: 17, name: 'Working Hours', sectionId: 3 },
        { id: 18, name: 'Maching guarding & controls', sectionId: 4 },
        { id: 19, name: 'Contractor safety & Permit To Work', sectionId: 4 },
        { id: 20, name: 'Road safety', sectionId: 4 },
        { id: 21, name: 'Electricals, Fire safety & Emergency Preparedness', sectionId: 4 },
        { id: 22, name: 'General', sectionId: 5 },
        { id: 23, name: 'Fire Protection', sectionId: 5 },
        { id: 24, name: 'Electrical Installations', sectionId: 5 },
        { id: 25, name: 'General', sectionId: 6 },
        // Add more options for other tabs...
    ];

    const sectionTwo = [
        { id: 1, name: 'Monitoring Water Consumption', subTopic: 1 },
        { id: 2, name: 'ETP Operation & Maintainence', subTopic: 1 },
        { id: 3, name: 'STP Operation & Maintainence', subTopic: 1 },
        { id: 4, name: 'Water Stewardship Playbook', subTopic: 1 },
        { id: 5, name: 'Water Neutrality', subTopic: 1 },
        { id: 6, name: 'Zero Liquid Discharge Status', subTopic: 1 },
        { id: 7, name: 'Hazardous Waste', subTopic: 2 },
        { id: 8, name: 'E- Waste', subTopic: 2 },
        { id: 9, name: 'Bio-Medical Waste', subTopic: 2 },
        { id: 10, name: 'Plastic Waste', subTopic: 2 },
        { id: 11, name: 'Battery Waste', subTopic: 2 },
        { id: 12, name: 'Zero Waste to Landfill', subTopic: 2 },
        { id: 13, name: 'Waste Management Playbook', subTopic: 2 },
        { id: 14, name: 'Waste Intensity', subTopic: 2 },
        { id: 15, name: 'Energy Monitoring', subTopic: 3 },
        { id: 16, name: 'Renewable Energy', subTopic: 3 },
        { id: 17, name: 'Energy Excellence Playbook', subTopic: 3 },
        { id: 18, name: 'Carbon Foot print Scope 1 & Scope 2 Reduction Plan', subTopic: 3 },
        { id: 19, name: 'Scope 3: Mapping ', subTopic: 3 },
        { id: 20, name: 'Scope 3: Reduction Plan ', subTopic: 3 },
        { id: 21, name: 'Projects towards EP 100', subTopic: 3 },
        { id: 22, name: 'Lifecycle Assessments', subTopic: 4 },
        { id: 23, name: 'Product Stewardship Playbook', subTopic: 4 },
        { id: 24, name: 'Recyclability', subTopic: 4 },
        { id: 25, name: 'Recoverability', subTopic: 4 },
        { id: 26, name: 'Zero Hazardous Waste ', subTopic: 4 },
        { id: 27, name: 'Meet Line & Lot Rejection', subTopic: 4 },
        { id: 28, name: 'Use of Alternate Materials ', subTopic: 4 },
        { id: 29, name: 'Alternate to Plastic in Packaging', subTopic: 4 },
        { id: 30, name: 'Eco-Labelling', subTopic: 4 },
        { id: 31, name: 'Product Recalls', subTopic: 4 },

        { id: 32, name: 'Water', subTopic: 5 },
        { id: 33, name: 'Energy', subTopic: 6 },
        { id: 34, name: 'General', subTopic: 7 },
        { id: 35, name: 'Waste', subTopic: 8 },
        { id: 36, name: 'Road Safety', subTopic: 9 },
        { id: 37, name: 'BioDiversity', subTopic: 10 },

        { id: 38, name: 'Child Labour', subTopic: 11 },
        { id: 39, name: 'Disciplinary Practices', subTopic: 12 },
        { id: 40, name: 'Discrimination', subTopic: 13 },
        { id: 41, name: 'Freedom of Association', subTopic: 14 },
        { id: 42, name: 'Forced or Compulsary Labour', subTopic: 15 },
        { id: 43, name: 'Remuneration', subTopic: 16 },
        { id: 44, name: 'Working Hours', subTopic: 17 },
        { id: 45, name: 'Maching guarding & controls', subTopic: 18 },
        { id: 46, name: 'Contractor safety & Permit To Work', subTopic: 19 },
        { id: 47, name: 'Road safety', subTopic: 20 },
        { id: 48, name: 'Electricals, Fire safety & Emergency Preparedness', subTopic: 21 },
        { id: 49, name: 'General', subTopic: 22 },
        { id: 50, name: 'Fire Protection', subTopic: 23 },
        { id: 51, name: 'Electrical Installations', subTopic: 24 },
        { id: 52, name: 'General', subTopic: 25 }
    ]

    const [selectedGmsOne, setSelectedGmsOne] = useState('');
    const [selectedGmsTwo, setSelectedGmsTwo] = useState(null);
    const [expandedGmsTwo, setExpandedGmsTwo] = useState(null);
    const [activeId, setActiveId] = useState(1); // State to hold the active framework ID
    const [labelColor, setLabelColor] = useState("#007bff"); // Default label color (black)

    const handleFileChange = (file) => {
        console.log(file)
        setFiles(file)

    }

    const handleComment = (item) => {
        console.log(item)
        setSelectedQuestion(item)
        setComment(item.comment)
        setShowModal(true)
    }
    const uploadFilesApi = async (file, item, event) => {
        console.log(item)
        setSelectedQuestion(item)
        let promise = new Promise((resolve, rej) => {
            if (file.size <= 10000000) {
                let formData = new FormData();
                formData.append("file", file);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                    mode: "no-cors",
                }).then((res) => {

                    if (res.status === 200) {
                        resolve({
                            url: API.Docs + res.data.files[0].originalname,
                            name: res.data.files[0].originalname,
                            size: res.data.files[0].size,
                        });
                    } else {
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });

        return promise;
    };
    const handleSaveComment = () => {
        let index = questionaries.findIndex(i => i.name === selectedQuestion?.name)
        if (index !== -1) {
            questionaries[index].comment = comment
            setComment('')
            setShowModal(false)
            forceUpdate()
        }
    };
    const handleAttachment = (file) => {
        let loc = JSON.parse(JSON.stringify(questionaries))
        let index = questionaries.findIndex(i => i.name === selectedQuestion?.name)
        if (index !== -1) {
            loc[index].attachments = [file]
            setQuestionaries(loc)
            forceUpdate()
        }

    };
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');

    const handleOpenModal = () => { setShowModal(true) };
    const handleCloseModal = () => setShowModal(false);


    useEffect(() => {
        console.count()
    }, [showModal])

    // Filter GMS Two Options by Selected GMS One
    const filteredGmsTwoOptions = gmsTwoOptions.filter(gms2 => gms2.sectionId === selectedGmsOne);

    // Filter Subtopics based on GMS Two ID
    const getSubTopicsForGmsTwo = (gmsTwoId) => {
        return sectionTwo.filter(sub => sub.subTopic === gmsTwoId);
    };

    const getQuestionsForSubTopic = (subSectionId, subTopicId = null) => {
        return questionaries.filter(question => {
            // If subTopicId is provided, match both subSectionId and subTopicId
            // Otherwise, match only subSectionId
            return question.subSectionId === subSectionId &&
                (subTopicId ? question.subTopicId === subTopicId : true);
        });
    };





    const steps = gmsOne.map((gms, index) => ({
        id:gms.id,
        label: gms.name,
        status: index === activeIndex ? 1 : (index < activeIndex ? 1 : 0), // Set status based on active index
    }));

    useEffect(() => {
        const initialId = gmsOne[0].id; // Get the ID of the first step
        setSelectedGmsOne(initialId); // Set selectedGmsOne to the first step
    }, [gmsOne]); // Dependency array to run effect when gmsOne changes

    useEffect(() => {
        // Reset or update related states here based on active step
        setSelectedGmsTwo(null);
        setExpandedGmsTwo(null);
    }, [activeIndex]);

    const handleStepClick = (index) => {
        setActiveIndex(index);
        const activeId = gmsOne[index].id;
        setActiveId(activeId); // Update the active framework ID
        const selectedStep = steps[index];
        console.log("selectedStep :", selectedStep);
        console.log("Active ID:", activeId); // Logs the ID of the active framework
        setSelectedGmsOne(activeId);
        console.log("selectedGmsOne :", selectedGmsOne);
        setSelectedGmsTwo(null);
        setExpandedGmsTwo(null);
        setLabelColor("#007bff");
    };

    const [expandedItems, setExpandedItems] = useState([]); // Track expanded items
    const [expandAll, setExpandAll] = useState(false); // State to toggle expand all items

    const toggleExpandAll = () => {
        if (expandedItems.length === gmsTwoOptions.length) {
            // Collapse all if they are already expanded
            setExpandedItems([]);
        } else {
            // Expand all items and set the selectedGmsTwo to the first subtopic of the first gmsTwoOption
            const allIds = gmsTwoOptions.map(gms2 => gms2.id);
            setExpandedItems(allIds);
            const firstSubTopic = getSubTopicsForGmsTwo(allIds[0])[0]; // Get the first subtopic of the first expanded item
            if (firstSubTopic) {
                setSelectedGmsTwo(firstSubTopic.id);
            }
        }
    };

    const handleSubtopicSelect = (subtopicId) => {
        setSelectedGmsTwo(subtopicId);
    };

    const onRadioButtonSelected = (item, cbind) => {
        item.modified_by = login_data.id
        item.modified_on = DateTime.utc()
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const getDate = (date, format) => {
        if (!date) {
            return 'Not Set'
        }
        if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const onChangeDropwdown = (item, val) => {
        item.modified_by = login_data.id
        item.modified_on = DateTime.utc()

        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const draftResponse = () => {
        if (!auditId.supplierAssignmentSubmission) {
            let newObj = { userProfileId: admin_data.id, supplierId: login_data.id, response: JSON.stringify(questionaries), created_on: DateTime.utc(), created_by: login_data.id, type: 0 }
            APIServices.post(API.SupplierAssessmentSubmission(auditId.id), newObj).then(res => {

                updateData({ ...auditId, supplierAssignmentSubmission: res.data })
            })
        } else {
            let newObj = { modified_on: DateTime.utc(), response: JSON.stringify(questionaries), modified_by: login_data.id, type: 0 }
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(auditId.supplierAssignmentSubmission.id), newObj).then(res => {
                let obj = { ...auditId }
                obj['supplierAssignmentSubmission'].response = JSON.stringify(questionaries)
                updateData(obj)
            })
        }

    }
    const saveResponse = () => {
        if (!auditId.supplierAssignmentSubmission) {
            let newObj = { userProfileId: admin_data.id, supplierId: login_data.id, response: JSON.stringify(questionaries), created_on: DateTime.utc(), created_by: login_data.id, type: 1 }
            APIServices.post(API.SupplierAssessmentSubmission(auditId.id), newObj).then(res => {

                updateData({ ...auditId, supplierAssignmentSubmission: res.data })
            })
        } else {
            let newObj = { modified_on: DateTime.utc(), response: JSON.stringify(questionaries), modified_by: login_data.id, type: 1 }
            APIServices.patch(API.SupplierAssessmentSubmission_Edit(auditId.supplierAssignmentSubmission.id), newObj).then(res => {
                let obj = { ...auditId }
                obj['supplierAssignmentSubmission'].response = JSON.stringify(questionaries)
                updateData(obj)
            })
        }
    }
    const renderResponse = (question, index) => {
        return (
            <>  <div key={question.id} className='questDisp col-12 grid m-0 p-0'>
                <div className="col-10 fs-16 fw-4">
                    <p style={{color:question.mandatory ? "red" : '#374151'   }}>{question.label}</p>

                    {question.type === 'select' ? (
                        // Check for Yes, No, NA for radio buttons
                        question.values.some(option => option.label === "Yes") &&
                            question.values.some(option => option.label === "No") &&
                            question.values.some(option => option.label === "NA") ? (
                            <div className='grid m-0 p-0'>
                                {question.values.map((cb, cbind) => {
                                    return (
                                        <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                            <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(question, cbind)} checked={cb.selected === true} />

                                            <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                        </div>
                                    )
                                })}
                            </div>

                        ) : (
                            <Dropdown placeholder={question.placeholder} options={question.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={question.value} onChange={(e) => { onChangeDropwdown(question, e.value) }} />


                        )
                    ) : question.type === 'textarea' ? (
                        <InputTextarea placeholder={'Enter your response here'} value={question.value} style={{ width: '100%', height: 120, overflow: 'auto' }} onChange={(e) => { question.modified_by = login_data.id; question.modified_on = DateTime.utc(); question.value = e.target.value; forceUpdate() }} />

                    ) : null}
                </div>
                <div className="col-2 flex  justify-content-between" style={{ flexDirection: 'column' }}>
                    {question.attachment &&
                        <>{(!question.attachments || (question.attachments && question.attachments.length === 0)) ?
                            <div>
                                <FileUpload name="demo[]" auto customUpload mode="basic" className="custom-upload-image" uploadHandler={(e) => { uploadFilesApi(e.files[0], question, e).then(res => { if (res) { handleAttachment(res) } }) }} multiple={false} accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                                    chooseOptions={{ icon: 'pi pi-fw pi-upload fs-14 fw-6', label: 'Upload Attachment', className: 'custom-choose-btn' }} />
                            </div> :
                            <div className="clr-navy grid m-0  p-0" > <i className="pi pi-paperclip fs-14 fw-6 mr-2" /> <label style={{ width: '80%' }} className='text-three-dot text-underline '>{question.attachments[0].name} </label> </div>
                        }
                        </>
                    }
                    <div style={{ color: '#315975' }} className="" onClick={(e) => { e.stopPropagation(); handleComment(question) }}>
                        <i className="pi pi-comment fs-14 fw-6 mr-2" style={{ marginRight: '5px', cursor: 'pointer' }}></i>
                        <span>{question.comment ? 'Update ' : 'Add '} Comment</span>
                    </div>
                </div>
            </div>
                < hr className="p-1 m-0" />
            </>
        )
    }
    const getStatusColorCode1 = (id) => {
        
        let loc = questionaries
      let ids =   gmsTwoOptions.filter(x => x.sectionId === id ).map(x => x.id )
        let res = loc.filter(i => ids.includes(i.subSectionId)  )
        
        if (!res || (res && !res.length)) {
            return false
        } else if (res.length) {
            let len = res.filter(i => i.mandatory ? ( (i.values ? i.values.some(x => x.selected  )  ?  i.values.some(x => x.selected && parseFloat(x.value) <= 0 ) ? (i.comment && i.comment.trim().length ) : true : false  : (i.comment && i.comment.trim().length ||  (i.value && i.value.trim().length))) ): i.values ? (i.values.some(x => x.selected) ) : ((i.value && i.value.trim().length))).filter(i => i.attachment ? (i.attachments && i.attachments.length !== 0) : true).length
            // let len = res.filter(i => i.mandatory ? ( (i.values ? i.values.some(x => x.selected  )  ?  i.values.some(x => x.selected && parseFloat(x.value) <= 0 ) ? (i.comment && i.comment.trim().length ) : true : false  : (i.comment && i.comment.trim().length ||  (i.value && i.value.trim().length))) ): i.values ? (i.values.some(x => x.selected) ) : ((i.value && i.value.trim().length))).filter(i => i.attachment ? (i.attachments && i.attachments.length !== 0) : true).length
            if (len === 0) {
                return false
            } else if (len === res.length) {
                return true
            } else {
                return false
            }
        }
    }
    const getStatusColorCode2 = (data) => {
        let loc = questionaries
        let res = loc.filter(i => data.map(x => x.id).includes(i.subTopicId)  )
        console.log(data,res)
        if (!res || (res && !res.length)) {
            return 'black '
        } else if (res.length) {
            let len = res.filter(i => i.mandatory ? ( (i.values ? i.values.some(x => x.selected  )  ?  i.values.some(x => x.selected && parseFloat(x.value) <= 0 ) ? (i.comment && i.comment.trim().length ) : true : false  : (i.comment && i.comment.trim().length ||  (i.value && i.value.trim().length))) ): i.values ? (i.values.some(x => x.selected) ) : ((i.value && i.value.trim().length))).filter(i => i.attachment ? (i.attachments && i.attachments.length !== 0) : true).length
          
            if (len === 0) {
                return 'red '
            } else if (len === res.length) {
                return 'green '
            } else {
                return 'orange '
            }
        }
    }
    const getStatusColorCode3 = (res) => {
        if (!res || (res && !res.length)) {
            return 'black '
        } else if (res.length) {
            let len = res.filter(i => i.mandatory ? ( (i.values ? i.values.some(x => x.selected  )  ?  i.values.some(x => x.selected && parseFloat(x.value) <= 0 ) ? (i.comment && i.comment.trim().length ) : true : false  : (i.comment && i.comment.trim().length ||  (i.value && i.value.trim().length))) ): i.values ? (i.values.some(x => x.selected) ) : ((i.value && i.value.trim().length))).filter(i => i.attachment ? (i.attachments && i.attachments.length !== 0) : true).length
           
            if (len === 0) {
                return 'red '
            } else if (len === res.length) {
                return 'green '
            } else {
                return 'orange '
            }
        }
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>


                {auditId &&
                    <div><p><strong>Assessment ID:</strong> {auditId.id}</p></div>
                }

                {/* <Button
                    variant="light"
                    onClick={handleClose}
                // style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000 }}
                >
                    Close
                </Button> */}
            </div>

            <div className="row mb-3" style={{ padding: '10px' }}>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier  Start Date</p>
                    <p className="obs-content">
                        {getDate(auditId.assessmentStartDate, 'dd MMM yyyy')}
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier  End Date</p>
                    <p className="obs-content">
                        {getDate(auditId.assessmentEndDate, 'dd MMM yyyy')}
                    </p>
                </div>

                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier Name</p>
                    <p className="obs-content">
                        {getSupplier(auditId.supplierId)}
                    </p>

                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier Category</p>

                    <p className="obs-content">
                        {auditId?.information?.category}

                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Supplier Location</p>
                    <p className="obs-content"> 1003
                    </p>


                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">Vendor Code</p>
                    <p className="obs-content"> {auditId?.supplierCode}</p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0"> Supplier To</p>
                    <p className="obs-content">
                        Hyderabad, India
                    </p>
                </div>
                <div className="col-md-6 p-2">
                    <p className="obs-title m-0">No of No-Go's Answered</p>
                    <p className="obs-content">
                        0
                    </p>
                </div>
            </div>



            <Tab.Container id="left-tabs-example" className="mt-3 audit-tab" activeKey={selectedGmsOne}>
                <Row style={{ marginTop: 20 }}>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        <Stepper
                            steps={steps.map((i) => ({...i,completed: getStatusColorCode1(i.id) }) )}
                            onClickStep={handleStepClick}
                            activeBGColor="green"
                            labelColor={labelColor}
                            activeIndex={activeIndex + 1} // Current active step index
                        />
                    </div>

                    <Col sm={12}>
                        <div className='flex justify-content-end'>
                            <Button text onClick={toggleExpandAll} >
                                {/* {expandAll ? 'Collapse All' : 'Expand All'} */}
                                {expandedItems.length === gmsTwoOptions.length ? 'Collapse All' : 'Expand All'}
                            </Button>
                        </div>
                        <Tab.Content>
                            {gmsOne.map(gms => (
                                <Tab.Pane eventKey={gms.id} key={gms.id}>
                                    <label style={{ fontSize: 16, fontWeight: 'bold' }}>{gms.name}</label>
                                    <Accordion activeKey={expandedItems}>
                                        {gmsTwoOptions.filter(gms2 => gms2.sectionId === gms.id).map(gms2 => {
                                            const subTopics = getSubTopicsForGmsTwo(gms2.id);

                                            return (

                                                <Accordion.Item eventKey={gms2.id} key={gms2.id} >
                                                    {/* <Accordion.Header onClick={() => setExpandedGmsTwo(expandedGmsTwo === gms2.id ? null : gms2.id)}> */}
                                                    <Accordion.Header style={{borderLeft:'5px solid '+getStatusColorCode2(subTopics)}} onClick={() => {

                                                        setExpandedItems(prev =>
                                                            prev.includes(gms2.id)
                                                                ? prev.filter(id => id !== gms2.id) // Collapse this item
                                                                : [...prev, gms2.id] // Expand this item
                                                        );
                                                    }}>
                                                        <div className="fs-14 fw-4">{gms2.name}</div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        {/* Conditional rendering for subTopics */}
                                                        {/* {expandedGmsTwo === gms2.id && subTopics.length > 0 ? ( */}
                                                        {expandedItems.includes(gms2.id) && subTopics.length > 0 ? (
                                                            <>
                                                                <Nav variant="pills" className="flex-row custom-nav">
                                                                    {subTopics.map(subtopic => (
                                                                        <Nav.Item key={subtopic.id}>
                                                                            {/* <Nav.Link eventKey={subtopic.id} onClick={() => setSelectedGmsTwo(subtopic.id)}> */}
                                                                            <Nav.Link eventKey={subtopic.id} style={{ color: getStatusColorCode3(getQuestionsForSubTopic(gms2.id, subtopic.id)) }} active={selectedGmsTwo === subtopic.id} onClick={() => handleSubtopicSelect(subtopic.id)}>
                                                                                {subtopic.name}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    ))}
                                                                </Nav>

                                                                {selectedGmsTwo && getQuestionsForSubTopic(gms2.id, selectedGmsTwo).length !== 0 && (
                                                                    <div>
                                                                        {/* <div style={{background:'#F0F2F4'}}>
                                                                            <h5>Questions</h5>
                                                                        </div> */}

                                                                        <div style={{
                                                                            background: '#F0F2F4',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '100%'  // Make sure it takes the full height of the surrounding container if it's within a flex layout
                                                                        }}>
                                                                            <h5 className="m-0">Questions</h5>
                                                                        </div>
                                                                        {getQuestionsForSubTopic(gms2.id, selectedGmsTwo).map((question, index) => (
                                                                            <div>
                                                                                {renderResponse(question, index)}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (

                                                            <div className='questDisp'>
                                                                {/* <div><h5>Questions</h5></div> */}

                                                                <div style={{
                                                                    background: '#F0F2F4',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    height: '100%'  // Make sure it takes the full height of the surrounding container if it's within a flex layout
                                                                }}>
                                                                    <h5 className="m-0">Questions</h5>
                                                                </div>
                                                                {getQuestionsForSubTopic(gms2.id).map(question => (
                                                                    <div>
                                                                        <div key={question.id} className="col-12 grid m-0 p-0">

                                                                            <div className="col-10 fs-16 fw-4">
                                                                                <p>{question.label}</p>
                                                                                {/* Render input types based on question options and type */}
                                                                                {question.type === 'select' ? (
                                                                                    // Check for Yes, No, NA for radio buttons
                                                                                    question.values.some(option => option.label === "Yes") &&
                                                                                        question.values.some(option => option.label === "No") &&
                                                                                        question.values.some(option => option.label === "NA") ? (
                                                                                        <div className="radioStyle">
                                                                                            <label>
                                                                                                <input type="radio" name={`question-${question.name}`} value="yes" />
                                                                                                Yes
                                                                                            </label>
                                                                                            <label style={{ marginLeft: '10px' }}>
                                                                                                <input type="radio" name={`question-${question.name}`} value="no" />
                                                                                                No
                                                                                            </label>

                                                                                            <label style={{ marginLeft: '10px' }}>
                                                                                                <input type="radio" name={`question-${question.name}`} value="na" />
                                                                                                NA
                                                                                            </label>
                                                                                        </div>
                                                                                    ) : (
                                                                                        // Render  if conditions are not met
                                                                                        <select className="form-control">
                                                                                            <option value="">Select an option</option>
                                                                                            {question.values.map(option => (
                                                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    )
                                                                                ) : question.type === 'textarea' ? (
                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        rows="4"
                                                                                        style={{ width: '100%', resize: 'none' }}
                                                                                        placeholder="Enter your response here"
                                                                                    />
                                                                                ) : null}
                                                                            </div>

                                                                            <div style={{ color: '#315975' }} className="col-2 flex align-items-end" >
                                                                                <i className="pi pi-comment" style={{ marginRight: '5px', cursor: 'pointer' }}></i>
                                                                                <span>Add Comment</span>
                                                                            </div>
                                                                        </div>
                                                                        < hr className="p-1 m-0" />
                                                                    </div>

                                                                ))}




                                                            </div>
                                                        )}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            );
                                        })}
                                    </Accordion>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>

                        <div style={{ display: 'flex', float: 'right', marginTop: '20px' }}>
                            <Button style={{ marginRight: '10px' }} onClick={() => { draftResponse() }}>
                                Save Section
                            </Button>
                            <Button onClick={() => { saveResponse() }}>
                                Submit Self Assessment
                            </Button>

                        </div>

                    </Col>


                </Row>
            </Tab.Container>
            <Dialog header={'Add Comment for "' + questionaries.find(i => i.name === selectedQuestion?.name)?.label + ' "'} visible={showModal} style={{ width: '75%' }} onHide={handleCloseModal} >
                <div>
                    <InputTextarea value={comment} onChange={(e) => { setComment(e.target.value) }} style={{ width: '100%', height: 150, overflow: 'auto' }} />
                    <div className="flex justify-content-end col-12">
                        <Button style={{ width: 110 }} className='mr-2' label={'Close'} onClick={() => { setShowModal(false) }} />
                        <Button style={{ width: 110 }} label={'Save & Exit'} onClick={() => { handleSaveComment() }} />
                    </div>


                </div>

            </Dialog>
        </>
    );
};

const Stepper = ({ steps, onClickStep, labelColor = 'black', activeBGColor = '#6c757d', activeIndex = 0 }) => {
    console.log(steps)
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div style={{ position: 'relative' }}>
                        <div
                            onClick={() => onClickStep && onClickStep(index)}
                            style={{
                                display: 'flex',
                                position: 'relative',
                                flexDirection: 'column',
                                zIndex: 1,
                                alignItems: 'center',
                                cursor: onClickStep ? 'pointer' : 'default',
                                textAlign: 'center',
                                width: '170px', // Fixed width to keep all steps aligned
                            }}
                        >
                            <div
                                style={{
                                    //backgroundColor: (step.status === 1 ? activeBGColor : step.status === 0 ? 'rgb(255, 165, 0)' : 'gray'),
                                    backgroundColor: step.completed ? 'green' :  'gray',
                                    color: '#fff',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    transition: 'background-color 0.3s ease', // Smooth color transition
                                }}
                            >
                                {index + 1} {/* Display step number starting from 1 */}
                            </div>
                            <div
                                style={{
                                    fontSize: 16,
                                    marginTop: '8px',
                                    fontWeight: activeIndex === index + 1 ? 700 : 'normal', // Adjusted for 1-based index
                                    color: activeIndex === index + 1 ? labelColor : 'black',
                                    textDecoration: activeIndex === index + 1 ? 'underline' : 'none',
                                }}
                            >
                                {step.label}
                            </div>
                        </div>

                        {/* Add line between steps, except for the last step */}
                        {index < steps.length - 1 && (
                            <hr
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    left: '50%',
                                    top: '8px',
                                    width: '100%',
                                    position: 'absolute',
                                    zIndex: 0,
                                }}
                            />
                        )}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default SupplierPanel;