import { DateTime } from "luxon";




const filterStandardData = (
  standardData,
  submission,
  standardId,
  categoryId,
  findingKeys,
  valueKey, subCategorykeys,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {

  // Step 1: Validate if submission.response exists
  if (!submission || !submission.response || !Array.isArray(submission.response)) {
    submission.error = 'No valid response property found in submission.';
    return submission;
  }

  // Step 2: Get the latest reporting period from the submission response
  const reportingPeriod = submission?.reporting_period?.[0];
  if (!reportingPeriod) {
    submission.error = 'No reporting_period found in submission response.';
    return submission;
  }

  // Step 3: Parse the reporting period date (MM-yyyy format) and add 1 month
  let lastDate;
  try {
    lastDate = DateTime.fromFormat(reportingPeriod, 'MM-yyyy', { zone: 'utc' }).setZone(timezone).plus({ months: 1 });
  } catch (error) {
    submission.error = `Invalid reporting_period format: ${reportingPeriod}`;
    return submission;
  }

  // Step 4: Format the next month for comparison
  const reportingDate = lastDate.toFormat('MM-yyyy');

  let found = false;

  // Step 5: Iterate through standardData and find matching standardId
  const filteredData = standardData.filter(standard => {
    // Match by standardId
    if (standard.id !== standardId) return false;

    // Step 6: Validate if newEfDates is available
    if (!standard.newEfDates || standard.newEfDates.length === 0) {
      submission.error = 'newEfDates is empty or missing in standard.';
      return false;
    }

    // Step 7: Check the date ranges in newEfDates and find the index for matching range
    const dateIndex = standard.newEfDates.findIndex((dateRange) => {
      const startDate = DateTime.fromISO(dateRange.start, { zone: 'utc' }).setZone(timezone);
      const endDate = dateRange.end ? DateTime.fromISO(dateRange.end, { zone: 'utc' }).setZone(timezone) : DateTime.local();

      // Step 8: Check if reporting_date + 1 is greater than the start and end of the item in newEfDates
      return lastDate >= startDate && lastDate <= endDate;
    });

    // If no match for the reporting period, find the appropriate index
    if (dateIndex === -1) {
      const isAllDatesBeforeReportingDate = standard.newEfDates.every(dateRange => {
        const startDate = DateTime.fromISO(dateRange.start, { zone: 'utc' }).setZone(timezone);
        const endDate = dateRange.end ? DateTime.fromISO(dateRange.end, { zone: 'utc' }).setZone(timezone) : DateTime.local();

        return lastDate > endDate;
      });

      return isAllDatesBeforeReportingDate ? standard.newEfDates.length - 1 : 0;
    }
    console.log(standard.newEfDates[dateIndex].newEfs)
    if (!standard.newEfDates[dateIndex].newEfs || standard.newEfDates[dateIndex].newEfs.length === 0) {
      submission.error = 'newEf is empty or missing in standard.';
      return false;
    }
    let efCatIndex = standard.newEfDates[dateIndex].newEfs.findIndex(x => x.category === categoryId)
    if (efCatIndex === -1) {
      submission.error = `No match found for categoryId: ${categoryId} in newEfs.`;
      return submission
    }

    if (!standard.newEfDates[dateIndex].newEfs[efCatIndex].newEfItems || standard.newEfDates[dateIndex].newEfs[efCatIndex].newEfItems.length === 0) {
      submission.error = 'newEf is empty or missing in standard.';
      return false;
    }
    // Step 9: Ensure that the categoryId matches within newEfItems


    // Step 10: Match newEfItems with findingKeys and subCategorykeys
    return standard.newEfDates[dateIndex].newEfs[efCatIndex].newEfItems.some(item => {
      // Iterate over submission.response and match finding keys with subCategory keys
      submission.response.forEach(resItem => {
        const isMatch = findingKeys.every((key, index) => {
          const subCategoryKey = item[`subcategory${subCategorykeys[index]}`];
          return resItem[key] === subCategoryKey; // Check if key matches corresponding subcategory value
        });

        // If a match is found, attach the newEfItem to the response item
        if (isMatch) {
          found = true;
          resItem.emission = resItem[valueKey] * item.co2e
          resItem.ef = item; // Attach matching newEfItem as 'ef'
          return true; // Stop iteration on first match
        }
        return false; // Continue to the next iteration if no match
      });

      return false; // Continue if no match found in this newEfItems
    });

  });

  // Step 11: Check if no match was found, and add an error to submission
  if (!found) {
    submission.error = 'No matching newEfItem found or invalid data.';
    return submission;
  }

  // Step 12: Attach the filtered data to the submission object
  submission.calculatedResponse = filteredData;

  return submission;
};

const getSingleValueByDp = (data, key) => {
  console.log(data)
  if (data.response && data.response.length) {
    data.emission = 0
    for (const item of data.response) {
      data.emission += item[key]
    }
    return data
  } else {
    data.emission = 0
    return data
  }

}

export { filterStandardData,getSingleValueByDp }
