import React, { useEffect, useState } from "react";
import useForceUpdate from "use-force-update";
import { API } from "../../../constants/api_url";
import APIServices from "../../../service/APIService";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { filterAssignmentsByFiscalYear, filterDataByTierAndLocation, filterSubmissionsByFiscalYear, getLocationData, getRPTextFormat, groupArrayByKeys, removeDuplicatesFromArrayByKey } from "../../../components/BGHF/helper";
import './approver.css'
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Tooltip as ToolTip } from 'primereact/tooltip';
import { TabView, TabPanel } from 'primereact/tabview';
import Swal from "sweetalert2";
import { InputTextarea } from "primereact/inputtextarea";
import { getSingleValueByDp } from "../../../components/BGHF/emissionCalculationHelper";
import { hardcoded } from "../../constants/hardcodedid";
let standAlone = [], levelCount = 0
const KPIApproval = () => {

    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const [selectedIndicator, setSelectedIndicator] = useState(null)
    const [sourcelist, setSourceList] = useState([])
    const userList = useSelector((state) => state.userlist.userList);
    const [approvecomment, setApproveComment] = useState('')
    const [approvedialog, setApproveDialog] = useState('')
    const [block, setBlock] = useState(false)
    const [efapi, setEfApi] = useState([])

    const [sourcedialog, setSourceDialog] = useState(false)
    const [notevisible, setNoteVisible] = useState(false);
    const [note, setNote] = useState('');
    const [commentlist, setCommentList] = useState([]);
    const forceUpdate = useForceUpdate()
    const [dcflist, setDCFList] = useState([])
    const [indicatorlist, setIndicatorList] = useState([])
    const [overallindicatorlist, setOverallIndicatorList] = useState([])
    const [filter, setFilter] = useState({ year: null, country: 0, city: null, site: null })
    const status = [{ name: 'Approved and Locked', id: 1 }, { name: 'Pending Approval', id: 2 }, { name: 'Pending Submissions', id: 3 }, { name: 'Unlocked for Changes and Comments', id: 4 }, { name: 'Partial Assignment', id: 5 }, { name: 'Assignment Missing', id: 6 }]

    const [rawsitelist, setRawSiteList] = useState([])
    const [dcfass, setDCFAss] = useState([])
    const [quantitativesubmission, setQuantitativeSubmission] = useState([])
    const [overallQuantitativeSubmission, setOverallQuantitativeSubmission] = useState([])
    const [qnapprovals, setQnApproval] = useState([])
    const entryStartDate = DateTime.fromISO(admin_data.information.startdate, { zone: 'utc' }).toJSDate()
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);

    const [kpilist, setKPIList] = useState([])
    const [yearoption, setYearOption] = useState([])


    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let dcfrelation = {
            "include": ['dcf']
        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let efstd = { "include": [{ "relation": "newEfDates", "scope": { "include": [{ "relation": "newEfs", "scope": { "include": [{ "relation": "newEfItems", "scope": { "include": ["subcat1", "subcat2", "subcat3", "subcat4"] } }] } }] } }] }
        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)

        setYearOption(yrOptions)
        const promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(API.DCF_Title_Only)
        const promise3 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`)
        const promise4 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise5 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise6 = APIServices.get(API.Indicator_Approver_Ass_UP(admin_data.id))
        const promise7 = APIServices.get(API.QN_Submit_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(dcfrelation))}`)
        const promise8 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise9 = APIServices.get(
            API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
        );
        const promise10 = APIServices.get(API.QN_Indicator_Approval_UP(admin_data.id))

        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10]).then((values) => {
            setEfApi(values[9].data)
            setQnApproval(values[10].data)
            if (yrOptions.length && values[0].data.length !== 0) {
                let assApproverIndicator = values[6].data.filter(i => i.responsibility.includes(login_data.id))
                console.log(values[6].data)
                let shapedSite = []
                if (login_data.role === 'clientadmin') {

                    shapedSite = values[3].data.map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
                } else {
                    shapedSite = getLocationData(values[3].data, values[8].data.filter(i => i.user_id === login_data.id), [3]).map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
                }

                console.log(shapedSite)
                setLocList({ country: [...shapedSite.map(location => ({ name: location.name, id: location.id }))] });
                setRawSiteList(shapedSite)
                setOverallQuantitativeSubmission(values[7].data)
                setDCFAss(values[5].data.filter(i => checkEntity(values[4].data, i)))
                setQuantitativeSubmission(values[7].data.filter(i => (i.type === 2 || i.type === 3)).filter(i => checkEntity_(i, values[4].data, 'dcfId')))
                console.log(values[7].data.filter(i => (i.type === 2 || i.type === 3)).map(i => i.id))
                setDCFList(values[2].data)
                let indicator_list = [], kpi_list = []
                const shapedCategory = values[1].data.map(item => {
                    if (item.newTopics) {
                        item.newTopics = item.newTopics.filter(topics =>
                            topics.newMetrics && topics.newMetrics.length > 0
                        );
                    }
                    return item;
                }).filter(item => item.newTopics && item.newTopics.length > 0)
                let overallmetric = shapedCategory.flatMap(i => i.newTopics && i.newTopics.flatMap(x => x.newMetrics))
                setOverallIndicatorList(overallmetric)
                shapedCategory.flatMap(i => i.newTopics).forEach((top) => {
                    if (values[0].data[0].topic_ids.includes(top.id) && (top.tag === null || parseFloat(top.tag) === admin_data.id)) {
                        top.newMetrics.forEach((met) => {
                            if ((Array.isArray(met.data1) && met.data1.length && met.data1[0].type === 0) && values[0].data[0].metric_ids.includes(met.id) && !indicator_list.map(i => i.id).includes(met.id) && (met.tag === null || parseFloat(met.tag) === admin_data.id)) {
                                indicator_list.push(met)


                            }
                        })
                    }
                })
                console.log(assApproverIndicator)
                setIndicatorList(assApproverIndicator.filter(i => indicator_list.map(x => x.id).includes(i.indicatorId)).flatMap(i => (i.locations.map(y => { delete y.disabled; delete y.checked; delete y.selected; return ({ ...y, locationId: y[`tier${i.levelOfApproval}_id`], level: i.levelOfApproval, tier3_id: y.tier3_id === undefined ? null : y.tier3_id, ...indicator_list.find(v => v.id === i.indicatorId), siteId: y.id, assId: i.id, indicatorId: i.indicatorId, threshold: i.threshold, responsibility: i.responsibility, levelOfApproval: i.levelOfApproval }) }))))
                console.log(assApproverIndicator, assApproverIndicator.filter(i => indicator_list.map(x => x.id).includes(i.indicatorId)).flatMap(i => (i.locations.map(y => { delete y.disabled; delete y.checked; delete y.selected; return ({ ...y, locationId: y[`tier${i.levelOfApproval}_id`], level: i.levelOfApproval, tier3_id: y.tier3_id === undefined ? null : y.tier3_id, ...indicator_list.find(v => v.id === i.indicatorId), siteId: y.id, assId: i.id, indicatorId: i.indicatorId, threshold: i.threshold, responsibility: i.responsibility, levelOfApproval: i.levelOfApproval }) }))))


                // setFilter((prev) => ({ ...prev, year: yrOptions[yrOptions.length - 1].name }))


            }

        })
    }, [])
    const checkEntity_ = (rowData, entity_list, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId)
                );
            }
        } else {
            return false;
        }
    };
    const checkEntity = (entity, entityuser) => {
        let assignment = entity.find(i => i.id === entityuser.entityAssId)
        if (assignment) {
            if (entityuser.level === 0) {
                return assignment.tier0_ids.includes(0)
            } else if (entityuser.level === 1) {
                return assignment.tier1_ids.includes(entityuser.locationId)
            } else if (entityuser.level === 2) {
                return assignment.tier2_ids.includes(entityuser.locationId)
            } else if (entityuser.level === 3) {
                return assignment.tier3_ids.includes(entityuser.locationId)
            } else {
                return false
            }
        } else {
            return false
        }


    }


    useEffect(() => {
        console.log(filter)
        if (filter.year) {
            let month_array = generateMonthArray(filter.year, fymonth), kpi_list = []
            let filteredAssignment = filterAssignmentsByFiscalYear(dcfass, filter.year, fymonth)

            let filtered = filterDataByTierAndLocation(indicatorlist, rawsitelist, filter.country, filter.city, filter.site)
            console.log(indicatorlist)
            let filteredQnAssByYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(overallQuantitativeSubmission, filter.year, fymonth), rawsitelist, filter.country, filter.city, filter.site)
            for (const indicatoritem of filterDerivedAndStandaloneWithIds(filtered, overallindicatorlist)) {
                let obj = { ...indicatoritem }
                console.log(obj)
                let asslevel = indicatoritem.levelOfApproval
                obj.active = asslevel === 1 ? (filter.country !== 0 && filter.city === 0 && filter.site === null) : asslevel === 2 ? (filter.country !== null && filter.city !== 0 && filter.site === 0) : asslevel === 3 ? (filter.country !== null && filter.city !== null && filter.site) : (filter.country === 0 && filter.city === null && filter.site === null)
                obj.dcf_ids = Array.from(new Set(indicatoritem.standalone_ids.flatMap(i => overallindicatorlist.find(x => x.id === i && x.newDataPoints) ? overallindicatorlist.find(x => x.id === i).newDataPoints.filter(y => Array.isArray(y.data1) && y.data1.length && y.data1[0].datasource).flatMap(z => z.data1[0].datasource) : null).filter(i => i)))
                console.log(Array.from(new Set(indicatoritem.standalone_ids.flatMap(i => overallindicatorlist.find(x => x.id === i && x.newDataPoints) ? overallindicatorlist.find(x => x.id === i).newDataPoints.filter(y => Array.isArray(y.data1) && y.data1.length && y.data1[0].datasource).flatMap(z => z.data1[0].datasource) : null).filter(i => i))))

                let filteredUserAssignment = filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)).map(i => ({ ...i, periods: getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, filter.year) }))
                obj.approvingMonths = generateApprovedPeriods(filteredUserAssignment.map(i => i.periods))
                obj.labels = checkDataSources(obj.approvingMonths, quantitativesubmission, filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)), obj.dcf_ids, filter, indicatoritem)
                obj.data = [{ unit: '', values: checkDataSources(obj.approvingMonths, quantitativesubmission, filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)), obj.dcf_ids, filter, indicatoritem) }]

                const curMTDMonths = mergeDateStrings(obj.labels.filter(x => !x.upcoming).map(x => x.month))
                console.log(mergeDateStrings(obj.labels.filter(x => !x.upcoming).map(x => x.month)), obj.labels.filter(x => !x.upcoming))
                const prevMTDMonths = reduceYearByOne(mergeDateStrings(obj.labels.filter(x => !x.upcoming).map(x => x.month)))
                obj.curMTDMonths = curMTDMonths
                obj.prevMTDMonths = prevMTDMonths
                obj.curMTDMonthValue = obj.labels.filter(x => !x.upcoming).map(x => x.value).reduce((a, b) => { return a + b }, 0)
                obj.prevMTDMonthValue = obj.labels.filter(x => !x.upcoming).map(x => x.value2).reduce((a, b) => { return a + b }, 0)
                obj.deviation = getPercentage(obj.curMTDMonthValue, obj.prevMTDMonthValue)
                obj.deviationDirection = obj.deviation ? obj.curMTDMonthValue < obj.prevMTDMonthValue : null
                let oldApprovalIndex = qnapprovals.findIndex(i => i.year === filter.year && i.tier1_id === filter.country && i.tier2_id === filter.city && i.tier3_id === filter.site && i.indicatorId === indicatoritem.id)
                obj.approverId = oldApprovalIndex === -1 ? undefined : qnapprovals[oldApprovalIndex].id
                obj.performanceCommentary = oldApprovalIndex === -1 ? [] : qnapprovals[oldApprovalIndex].performanceCommentary
                obj.status = !obj.labels.length ? 6 : obj.labels[0].status
                obj.months = month_array
                console.log(obj, "assignment")
                kpi_list.push(obj)

            }
            console.log(kpi_list)
            setKPIList(kpi_list.filter(i => i.active).map(i => { delete i.newDataPoints; return i }))
        }
    }, [filter])
    const getPercentage = (current, previous) => {

        if (previous === 0) {
            return null;
        }
        const percentage = ((current - previous) / previous) * 100;
        return percentage

    }
    function mergeDateStrings(dateArray) {
        if (!Array.isArray(dateArray) || dateArray.length === 0) {
            return null; // Handle invalid input
        }

        const parseDateRange = (dateStr) => {
            if (dateStr.includes("to")) {
                const [start, end] = dateStr.split("to").map((s) => s.trim());
                return {
                    start: DateTime.fromFormat(start, "MMM-yyyy"),
                    end: DateTime.fromFormat(end, "MMM-yyyy"),
                };
            }
            const date = DateTime.fromFormat(dateStr, "MMM-yyyy");
            return { start: date, end: date };
        };

        let minDate = null;
        let maxDate = null;

        dateArray.forEach((dateStr) => {
            const { start, end } = parseDateRange(dateStr);

            if (!minDate || start < minDate) {
                minDate = start;
            }

            if (!maxDate || end > maxDate) {
                maxDate = end;
            }
        });

        // Format the range back to "MMM-yyyy"
        return `${minDate.toFormat("MMM-yyyy")} to ${maxDate.toFormat("MMM-yyyy")}`;
    }
    const refreshData = (filter, quantitativesubmission, overallQuantitativeSubmission) => {
        let month_array = generateMonthArray(filter.year, fymonth), kpi_list = []
        let filteredAssignment = filterAssignmentsByFiscalYear(dcfass, filter.year, fymonth)

        let filtered = filterDataByTierAndLocation(indicatorlist, rawsitelist, filter.country, filter.city, filter.site)
        console.log(indicatorlist)
        let filteredQnAssByYear = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(overallQuantitativeSubmission, filter.year, fymonth), rawsitelist, filter.country, filter.city, filter.site)
        for (const indicatoritem of filterDerivedAndStandaloneWithIds(filtered, overallindicatorlist)) {
            let obj = { ...indicatoritem }
            console.log(obj)
            let asslevel = indicatoritem.levelOfApproval
            obj.active = asslevel === 1 ? (filter.country !== 0 && filter.city === 0 && filter.site === null) : asslevel === 2 ? (filter.country !== null && filter.city !== 0 && filter.site === 0) : asslevel === 3 ? (filter.country !== null && filter.city !== null && filter.site) : (filter.country === 0 && filter.city === null && filter.site === null)
            obj.dcf_ids = Array.from(new Set(indicatoritem.standalone_ids.flatMap(i => overallindicatorlist.find(x => x.id === i && x.newDataPoints) ? overallindicatorlist.find(x => x.id === i).newDataPoints.filter(y => Array.isArray(y.data1) && y.data1.length && y.data1[0].datasource).flatMap(z => z.data1[0].datasource) : null).filter(i => i)))
            console.log(Array.from(new Set(indicatoritem.standalone_ids.flatMap(i => overallindicatorlist.find(x => x.id === i && x.newDataPoints) ? overallindicatorlist.find(x => x.id === i).newDataPoints.filter(y => Array.isArray(y.data1) && y.data1.length && y.data1[0].datasource).flatMap(z => z.data1[0].datasource) : null).filter(i => i))))

            let filteredUserAssignment = filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)).map(i => ({ ...i, periods: getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, filter.year) }))
            obj.approvingMonths = generateApprovedPeriods(filteredUserAssignment.map(i => i.periods))
            obj.labels = checkDataSources(obj.approvingMonths, quantitativesubmission, filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)), obj.dcf_ids, filter, indicatoritem)
            obj.data = [{ unit: '', values: checkDataSources(obj.approvingMonths, quantitativesubmission, filteredAssignment.filter(i => obj.dcf_ids.includes(i.dcfId)), obj.dcf_ids, filter, indicatoritem) }]

            const curMTDMonths = mergeDateStrings(obj.labels.filter(x => !x.upcoming).map(x => x.month))
            const prevMTDMonths = reduceYearByOne(mergeDateStrings(obj.labels.filter(x => !x.upcoming).map(x => x.month)))
            obj.curMTDMonths = curMTDMonths
            obj.prevMTDMonths = prevMTDMonths
            obj.curMTDMonthValue = obj.labels.filter(x => !x.upcoming).map(x => x.value).reduce((a, b) => { return a + b }, 0)
            obj.prevMTDMonthValue = obj.labels.filter(x => !x.upcoming).map(x => x.value2).reduce((a, b) => { return a + b }, 0)
            obj.deviation = getPercentage(obj.curMTDMonthValue, obj.prevMTDMonthValue)
            obj.deviationDirection = obj.deviation ? obj.curMTDMonthValue < obj.prevMTDMonthValue : null
            let oldApprovalIndex = qnapprovals.findIndex(i => i.year === filter.year && i.tier1_id === filter.country && i.tier2_id === filter.city && i.tier3_id === filter.site && i.indicatorId === indicatoritem.id)
            obj.approverId = oldApprovalIndex === -1 ? undefined : qnapprovals[oldApprovalIndex].id
            obj.performanceCommentary = oldApprovalIndex === -1 ? [] : qnapprovals[oldApprovalIndex].performanceCommentary
            obj.status = !obj.labels.length ? 6 : obj.labels[0].status
            obj.months = month_array
            console.log(obj, "assignment")
            kpi_list.push(obj)

        }
        console.log(kpi_list)
        setKPIList(kpi_list.filter(i => i.active).map(i => { delete i.newDataPoints; return i }))

    }

    const updateLocationFilterValue = (obj, val) => {

        let item = { ...filter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All Countries', id: 0 }]
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        if (obj === 'country') {
            item.city = val === 0 ? null : 0

            item.site = null
        }
        else if (obj === 'city') {

            item.site = val === 0 ? null : 0
        }

        setFilter(item)
        console.log(city_list, location_list, item, val)
        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
    }
    function formatDateStrings(dateArray, frequency = 1) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const result = [];

        for (let i = 0; i < dateArray.length; i += frequency) {
            const currentBatch = dateArray.slice(i, i + frequency);

            if (currentBatch.length === frequency) {
                if (frequency === 1) {
                    const [month, year] = currentBatch[0].split('-');
                    result.push(`${monthNames[parseInt(month) - 1]}-${year}`);
                } else {
                    const firstDate = currentBatch[0];
                    const lastDate = currentBatch[currentBatch.length - 1];
                    const [startMonth, startYear] = firstDate.split('-');
                    const [endMonth, endYear] = lastDate.split('-');
                    result.push(`${monthNames[parseInt(startMonth) - 1]}-${startYear} to ${monthNames[parseInt(endMonth) - 1]}-${endYear}`);
                }
            }
        }



        return result;
    }
    function generateMonthArray(year, fymonth) {
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let result = [];

        // Fiscal year starting from fymonth
        if (fymonth === 1) {
            // Calendar year starting from January
            for (let i = 0; i < 12; i++) {
                result.push(`${months[i]}-${year}`);
            }
        } else {
            // Fiscal year starting from the given month (not January)
            // Generate 12 months, adjusting the year if necessary
            for (let i = 0; i < 12; i++) {
                let monthIndex = (fymonth - 1 + i) % 12;
                let currentYear = year - 1;

                // Determine if we need to roll over to the next year
                if (monthIndex < fymonth - 1) {
                    currentYear++;
                }

                result.push(`${months[monthIndex]}-${currentYear}`);
            }
        }

        return result;
    }
    const generateMTDArray = (year, fymonth) => {
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let result = [];

        // Determine the current month and year using Luxon
        const currentDate = DateTime.local();
        const currentMonth = currentDate.month;
        const currentYear = currentDate.year;

        if (year === currentYear) {
            // Current year case
            let startMonth = fymonth;
            let endMonth = currentMonth - 1;

            if (startMonth <= endMonth) {
                // Fiscal year does not cross year boundary
                for (let i = startMonth - 1; i < endMonth; i++) {
                    result.push(`${months[i]}-${year}`);
                }
            } else {
                // Fiscal year crosses year boundary
                for (let i = startMonth - 1; i < 12; i++) {
                    result.push(`${months[i]}-${year - 1}`);
                }
                for (let i = 0; i < endMonth; i++) {
                    result.push(`${months[i]}-${year}`);
                }
            }
        } else {
            // Not the current year case
            let startMonth = fymonth;
            for (let i = 0; i < 12; i++) {
                let monthIndex = (startMonth - 1 + i) % 12;
                let yearAdjustment = Math.floor((startMonth - 1 + i) / 12);
                let adjustedYear = year + yearAdjustment;
                result.push(`${months[monthIndex]}-${adjustedYear}`);
            }
        }

        return result;
    };
    const reduceYearByOne = (dateString) => {
        if (!dateString) {
            return 'NA'
        }
        if (dateString.includes("to")) {
            // Handle range format "Jan-2022 to Feb-2022"
            const [start, end] = dateString.split(" to ");
            const [startMonth, startYear] = start.split('-');
            const [endMonth, endYear] = end.split('-');

            const newStartYear = (parseInt(startYear) - 1).toString();
            const newEndYear = (parseInt(endYear) - 1).toString();

            return `${startMonth}-${newStartYear} to ${endMonth}-${newEndYear}`;
        } else {
            // Handle single date format "Jan-2022"
            const [month, year] = dateString.split('-');
            const newYear = (parseInt(year) - 1).toString();

            return `${month}-${newYear}`;
        }


    };
    const getData = (indicator, datasource) => {
        console.log(indicator)
        if (indicator.id === 202) {
            let withdraw = 0
            for (const res of datasource) {
                withdraw += getSingleValueByDp(res, res.dcf.calculationDpIds).emission
            }
            return withdraw
        } else if (indicator.id === 490) {

            let disposal = 0
            for (const res of datasource) {
                disposal += getSingleValueByDp(res, res.dcf.calculationDpIds).emission
            }
            return disposal
        } else if (indicator.id === 887) {
            let withdraw = 0, disposal = 0
            for (const res of datasource) {
                if (res.dcf.id === 246) {
                    withdraw += getSingleValueByDp(res, res.dcf.calculationDpIds).emission
                } else if (res.dcf.id === 247) {
                    disposal += getSingleValueByDp(res, res.dcf.calculationDpIds).emission
                }

            }
            return withdraw - disposal
        }
        return 0
    }
    const checkDataSources = (requireMonths, submissions, assignments, dcfIds, filterOption, indicatorItem) => {
        console.log(submissions)
        let filteredSubmissionCurrent = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions.filter(i => dcfIds.includes(i.dcfId)), filterOption.year, fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)
        let filteredSubmissionPrevious = filterDataByTierAndLocation(filterSubmissionsByFiscalYear(submissions.filter(i => dcfIds.includes(i.dcfId)), (filterOption.year - 1), fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)
        let filteredAssignmentCurrent = filterDataByTierAndLocation(filterAssignmentsByFiscalYear(assignments, filterOption.year, fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)
        let filteredAssignmentPrevious = filterDataByTierAndLocation(filterAssignmentsByFiscalYear(assignments, (filterOption.year - 1), fymonth), rawsitelist, filterOption.country, filterOption.city, filterOption.site)

        let result = [], submittedData = []
        console.log(requireMonths, filteredAssignmentCurrent)
        for (const requiredrp of requireMonths) {

            let requiredAssignment = filterObjectsByDateRange(requiredrp, filteredAssignmentCurrent)
            let prevYearrequiredAssignment = filterObjectsByDateRange(requiredrp, filteredAssignmentPrevious)

            let datasources = filterByReportingPeriods(requiredrp, submissions.filter(i => dcfIds.includes(i.dcfId) && requiredAssignment.map(x => x.id).includes(i.entityUserAssId) && requiredAssignment.map(x => x.entityAssId).includes(i.entityAssId)))
            let prevYearDatasources = filterByReportingPeriods(reduceYearByOne(requiredrp), submissions.filter(i => dcfIds.includes(i.dcfId) && prevYearrequiredAssignment.map(x => x.id).includes(i.entityUserAssId) && prevYearrequiredAssignment.map(x => x.entityAssId).includes(i.entityAssId)))
            let curData = getData(indicatorItem, datasources)
            let prevData = getData(indicatorItem, prevYearDatasources)
            let deviation = curData > prevData
            let threshold = indicatorItem.threshold ? isWithinThreshold(curData, prevData, indicatorItem.tvalue1, indicatorItem.tvalue2) : true
            let groupedFilterAssignment = Object.keys(groupArrayByKeys(requiredAssignment, ['level', 'locationId']))
            console.log(datasources, submissions.filter(i => dcfIds.includes(i.dcfId)), submissions.filter(i => dcfIds.includes(i.dcfId) && requiredAssignment.map(x => x.id).includes(i.entityUserAssId) && requiredAssignment.map(x => x.entityAssId).includes(i.entityAssId)), requiredAssignment)
            const requiredAssignmentsWithMissingPeriods = requiredAssignment.flatMap(i => {
                // Get all periods for the assignment
                const periods = getPeriodsForAssignment_filtered(i.start_date, i.end_date, i.frequency, fymonth, filterOption.year, requiredrp);
                console.log(periods)
                // Filter out already submitted periods and conditionally add refobj when found
                return periods
                    .map(period => {
                        // Find the corresponding submission for this specific period
                        const foundSubmission = submissions.find(sub =>
                            i.entityAssId === sub.entityAssId && sub.entityUserAssId === i.id &&
                            i.dcfId === sub.dcfId &&
                            getRPTextFormat(sub.reporting_period) === period
                        );
                        let status = null
                        if (foundSubmission) {
                            let type = foundSubmission.type
                            let reject = foundSubmission.reject
                            let status = (type === 0 && !reject) ? 0 :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? 1
                                    : type === 1 && reject === 1
                                        ? 2
                                        : type === 1
                                            ? 3
                                            : type === 2
                                                ? 4
                                                : type === 3
                                                    ? 5
                                                    : null
                            if (status === 0 || status === 1) {

                                status = getOverdueDays(period) <= -DateTime.utc().toLocal().daysInMonth ? 7 : getOverdueDays(period) <= 0 ? 6 : 99


                            }
                        } else {
                            status = getOverdueDays(period) <= -DateTime.utc().toLocal().daysInMonth ? 7 : getOverdueDays(period) <= 0 ? 6 : 100

                        }
                        // Return the assignment with the period, and refobj only if found
                        return {

                            ...i, dueMonth: getDueMonth(period), reporting_period: period,
                            reporters: i.reporter_ids.map(id => getUser(id)).filter(Boolean),
                            reviewers: i.reviewer_ids.map(id => getUser(id)).filter(Boolean),
                            approvers: i.approver_ids.map(id => getUser(id)).filter(Boolean),
                            period, status,
                            ...(foundSubmission ? { data: foundSubmission } : {})
                        };
                    })

            });
            console.log(requiredAssignmentsWithMissingPeriods)
            console.log(requiredrp, requiredAssignment, indicatorItem, groupedFilterAssignment.length, dcfIds, dcfIds.length, datasources, datasources.filter(i => i.type === 2).length, datasources.filter(i => i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length), datasources.filter(i => i.type === 3 || i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length))
            // Result: all missing assignment-period combinations.
            // result.push({ assignmentCount: groupedFilterAssignment.length, month: requiredrp, approverFrequency: getMonthCount(requiredrp), datasources, pending: requiredAssignmentsWithMissingPeriods, upcoming: !getDisplayStatus(requiredrp), checked: (!groupedFilterAssignment.length || !datasources.length || ((groupedFilterAssignment.length ) !== requiredAssignment.length)) ? false : (datasources.filter(i => (i.type === 2 || i.type === 3)).length === (groupedFilterAssignment.length )) ? true : false, disabled: (((groupedFilterAssignment.length ) !== requiredAssignment.length) || !groupedFilterAssignment.length || !datasources.length || !datasources.filter(i => (i.type === 2)).length) ? true : (datasources.filter(i => (i.type === 2 || i.type === 3)).length === (groupedFilterAssignment.length )) ? false : true, new: !groupedFilterAssignment.length ? false : (datasources.filter(i => i.type === 2).length !== 0 && datasources.filter(i => i.type === 2 || i.type === 3).length === (groupedFilterAssignment.length )), status: !groupedFilterAssignment.length ? 6 : (groupedFilterAssignment.length ) !== requiredAssignment.length ? 5 : (datasources.filter(i => i.type === 3).length === (groupedFilterAssignment.length )) ? 1 : (datasources.filter(i => i.type === 2).length === (groupedFilterAssignment.length ) || ((datasources.length === datasources.filter(i => i.type === 3 || i.type === 2).length) && datasources.filter(i => i.type === 3 || i.type === 2).length === (groupedFilterAssignment.length ))) ? 2 : 3 })
            // !groupedFilterAssignment.length ? 6 : (groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length ? 5 : (datasources.filter(i => i.type === 3).length === (groupedFilterAssignment.length * dcfIds.length)) ? 1 : (datasources.filter(i => i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length) || ((datasources.length === datasources.filter(i => i.type === 3 || i.type === 2).length) && datasources.filter(i => i.type === 3 || i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length))) ? 2 : 3 
            result.push({ value: curData, value2: prevData, value2: prevData, deviation, threshold, approvedData: datasources.filter(i => i.type === 2 || i.type === 3), assignmentCount: groupedFilterAssignment.length, month: requiredrp, approverFrequency: getMonthCount(requiredrp), datasources, overallData: requiredAssignmentsWithMissingPeriods, pending: requiredAssignmentsWithMissingPeriods.filter(i => !i.data), upcoming: !getDisplayStatus(requiredrp), checked: (!groupedFilterAssignment.length || !datasources.length || ((groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length)) ? false : (datasources.filter(i => (i.type === 2 || i.type === 3)).length === (groupedFilterAssignment.length * dcfIds.length)) ? true : false, disabled: (((groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length) || !groupedFilterAssignment.length || !datasources.length || !datasources.filter(i => (i.type === 2)).length) ? true : (datasources.filter(i => (i.type === 2 || i.type === 3)).length === (groupedFilterAssignment.length * dcfIds.length)) ? false : true, new: !groupedFilterAssignment.length ? false : (datasources.filter(i => i.type === 2).length !== 0 && datasources.filter(i => i.type === 2 || i.type === 3).length === (groupedFilterAssignment.length * dcfIds.length)), status: !groupedFilterAssignment.length ? 6 : (groupedFilterAssignment.length * dcfIds.length) !== requiredAssignment.length ? 5 : (datasources.filter(i => i.type === 3).length === (groupedFilterAssignment.length * dcfIds.length)) ? 1 : (datasources.filter(i => i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length) || ((datasources.length === datasources.filter(i => i.type === 3 || i.type === 2).length) && datasources.filter(i => i.type === 3 || i.type === 2).length === (groupedFilterAssignment.length * dcfIds.length))) ? 2 : 3 })
        }
        return result

    }
    function isWithinThreshold(curData, prevData, tvalue1, tvalue2) {
        // Convert null/undefined to 0 for comparison
        const current = curData ?? 0;
        const previous = prevData ?? 0;

        // Calculate the difference
        const difference = Math.abs(current - previous);

        // Determine the lower and upper bounds of the threshold range
        const lowerThreshold = Math.min(tvalue1, tvalue2);
        const upperThreshold = Math.max(tvalue1, tvalue2);

        // Check if the difference lies within the threshold range
        return difference >= lowerThreshold && difference <= upperThreshold;
    }
    const getDisplayStatus = (rp) => {



        const [startMonth, endMonth] = rp.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();

        return endOfMonth.diff(currentDate, 'days').days <= 0;
    };
    function generateMonthArray(year, fymonth) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let result = [];

        // Fiscal year starting from fymonth
        if (fymonth === 1) {
            // Calendar year starting from January
            for (let i = 0; i < 12; i++) {
                result.push(`${months[i]}-${year}`);
            }
        } else {
            // Fiscal year starting from the given month (not January)
            // Generate 12 months, adjusting the year if necessary
            for (let i = 0; i < 12; i++) {
                let monthIndex = (fymonth - 1 + i) % 12;
                let currentYear = year - 1;

                // Determine if we need to roll over to the next year
                if (monthIndex < fymonth - 1) {
                    currentYear++;
                }

                result.push(`${months[monthIndex]}-${currentYear}`);
            }
        }

        return result;
    }
    const getPeriodsForAssignment = (startDate, endDate, frequency, fymonth, year) => {

        let start = DateTime.fromISO(startDate).toLocal();
        let fiscalYearStart = null
        if (fymonth === 1) {
            fiscalYearStart = DateTime.fromObject({ year, month: 1, day: 1 });
        } else {
            fiscalYearStart = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 });
        }
        // If start date is earlier than the fiscal year start, adjust it
        if (start < fiscalYearStart) {
            start = fiscalYearStart;
        }
        // Determine the current year
        const currentYear = DateTime.now().year;

        // If endDate is null, compute based on fiscal year and year condition
        if (!endDate) {


            // Set endDate based on fymonth and the adjusted endYear
            if (fymonth === 1) {
                endDate = DateTime.fromObject({ year, month: 12, day: 31 }); // Dec of the specified year
            } else {
                endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 31 }); // Mar(fymonth-1)-year
            }
        }

        let end = DateTime.fromISO(endDate); // Now `end` is defined either from input or calculated

        let periods = [];

        while (start <= end) {
            let periodEnd = start.plus({ months: frequency - 1 });
            if (periodEnd > end) {
                periodEnd = end;
            }

            // Create a period string
            const period =
                frequency === 1
                    ? start.toFormat('MMM-yyyy')
                    : `${start.toFormat('MMM-yyyy')} to ${periodEnd.toFormat('MMM-yyyy')}`;
            periods.push(period);

            // Move to the next period
            start = start.plus({ months: frequency });
        }

        return periods;
    };


    const getPeriodsForAssignment_filtered = (startDate, endDate, frequency, fymonth, year, targetDateStr) => {
        let start = DateTime.fromISO(startDate).toLocal();
        let fiscalYearStart = fymonth === 1
            ? DateTime.fromObject({ year, month: 1, day: 1 })
            : DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 });

        if (start < fiscalYearStart) start = fiscalYearStart;

        if (!endDate) {
            endDate = fymonth === 1
                ? DateTime.fromObject({ year, month: 12, day: 31 })
                : DateTime.fromObject({ year, month: fymonth - 1, day: 31 });
        }

        let end = DateTime.fromISO(endDate);
        let periods = [];

        // Parse the target date range directly
        const [targetStartStr, targetEndStr] = targetDateStr.split(' to ');
        const targetStart = DateTime.fromFormat(targetStartStr, 'MMM-yyyy');
        const targetEnd = targetEndStr ? DateTime.fromFormat(targetEndStr, 'MMM-yyyy') : targetStart;

        while (start <= end) {
            let periodEnd = start.plus({ months: frequency - 1 });
            if (periodEnd > end) periodEnd = end;

            const period =
                frequency === 1
                    ? start.toFormat('MMM-yyyy')
                    : `${start.toFormat('MMM-yyyy')} to ${periodEnd.toFormat('MMM-yyyy')}`;

            // Check if the period overlaps or matches the target date range
            const currentStart = start;
            const currentEnd = periodEnd;

            if (
                (currentStart <= targetEnd && currentEnd >= targetStart) // Checks for any overlap or match
            ) {
                periods.push(period);
            }

            start = start.plus({ months: frequency });
        }

        return periods;
    };



    const sortPeriods = (periods) => {
        return periods.sort((a, b) => {
            const getStartDate = (period) => {
                const start = period.split(' to ')[0].trim();
                return DateTime.fromFormat(start, 'MMM-yyyy');
            };

            const dateA = getStartDate(a);
            const dateB = getStartDate(b);
            return dateA - dateB;
        });
    };

    // Function to expand a date range into an array of months
    const expandRange = (start, end) => {
        const startDate = DateTime.fromFormat(start, 'MMM-yyyy');
        const endDate = DateTime.fromFormat(end, 'MMM-yyyy');
        const months = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            months.push(currentDate.toFormat('MMM-yyyy'));
            currentDate = currentDate.plus({ months: 1 });
        }

        return months;
    };

    // Function to remove duplicate single months that are part of a range
    const removeDuplicateMonths = (arrays) => {
        const expandedRanges = new Set();

        arrays.forEach(row => {
            row.forEach(period => {
                if (period.includes('to')) {
                    const [start, end] = period.split(' to ');
                    const rangeMonths = expandRange(start.trim(), end.trim());
                    rangeMonths.forEach(month => expandedRanges.add(month));
                }
            });
        });

        return arrays.map(row =>
            row.filter(period => {
                if (period.includes('to')) return true; // Keep ranges
                return !expandedRanges.has(period); // Remove single months if they exist in a range
            })
        );
    };

    function parseDateRange(dateStr) {
        const [start, end] = dateStr.split(' to ').map(date => DateTime.fromFormat(date, 'MMM-yyyy'));
        return {
            start,
            end: end || start
        };
    }

    function isDateInRange(target, range) {
        // Check if a target date falls within a range
        return target >= range.start && target <= range.end;
    }

    function isRangeOverlap(range1, range2) {
        // Check if two date ranges overlap
        return range1.start <= range2.end && range1.end >= range2.start;
    }

    function filterDatesByString(dateStr, dateArray) {
        const targetRange = parseDateRange(dateStr);

        return dateArray.filter(date => {
            const currentRange = parseDateRange(date);

            // Check for single date match or overlapping ranges
            return (currentRange.start.equals(targetRange.start) && currentRange.end.equals(targetRange.end)) ||
                isRangeOverlap(currentRange, targetRange);
        });
    }
    // Function to generate approved periods based on available data, ensuring longest ranges are prioritized
    const generateApprovedPeriods = (arrays) => {
        const cleanedData = removeDuplicateMonths(arrays);
        const periodMap = new Map();

        // Populate the map with the longest period found
        cleanedData.forEach(row => {
            row.forEach(period => {
                const periodKey = period.replace(/\sto\s/g, ' to '); // Normalize spacing for comparison
                const [start, end] = periodKey.includes('to') ? periodKey.split(' to ') : [periodKey, periodKey];

                if (periodMap.has(start)) {
                    const currentEnd = periodMap.get(start);
                    if (DateTime.fromFormat(end, 'MMM-yyyy') > DateTime.fromFormat(currentEnd, 'MMM-yyyy')) {
                        periodMap.set(start, end); // Update with longer range
                    }
                } else {
                    periodMap.set(start, end); // Add new period
                }
            });
        });

        // Convert back to the "start to end" format
        const result = [];
        periodMap.forEach((end, start) => {
            if (start === end) {
                result.push(start);
            } else {
                result.push(`${start} to ${end}`);
            }
        });

        return sortPeriods(result);
    };



    function filterDerivedAndStandaloneWithIds(data, overall) {
        const childIds = new Set();
        const standaloneChildren = {};

        // Function to collect standalone IDs recursively for both children and nested parents
        function collectStandaloneIds(itemId) {
            const item = overall.find(d => d.id === itemId);
            if (!item || !item.data1[0]) return [];

            let standaloneIds = item.data1[0].indicator.filter(id => {
                const child = overall.find(d => d.id === id);
                return child && child.data1[0]?.source === 1; // Check if the child is standalone
            });

            // Recursively collect standalone children from nested parents
            item.data1[0].indicator.forEach(id => {
                const child = overall.find(d => d.id === id);
                if (child && child.data1[0]?.source === 0) {
                    const nestedStandaloneIds = collectStandaloneIds(child.id);
                    standaloneIds = standaloneIds.concat(nestedStandaloneIds); // Merge nested results
                }
            });

            return standaloneIds;
        }

        // Collect standalone children for derived parents
        data.forEach(item => {
            if (item.data1[0]?.type === 0 && item.data1[0]?.source === 0) {
                const standaloneIds = collectStandaloneIds(item.id);
                if (standaloneIds.length > 0) {
                    standaloneChildren[item.id] = standaloneIds;
                }

                // Add all child IDs (standalone or not) to the set of child IDs
                item.data1[0].indicator.forEach(id => childIds.add(id));
            }
        });

        // Filter out derived children and attach standalone_ids to derived parents
        const filteredData = data.map(item => {
            // If item is a derived parent and has standalone children, add standalone_ids
            if (standaloneChildren[item.id]) {
                return { ...item, standalone_ids: standaloneChildren[item.id] };
            }

            // Check for standalone items
            if (item.data1[0]?.type === 0 && item.data1[0]?.source === 1) {
                return { ...item, standalone_ids: [item.id] };
            }

            // Retain only if it's not a derived child (id not in childIds)
            if (!childIds.has(item.id)) {
                return item;
            }

            return null; // Exclude derived children
        }).filter(item => item !== null); // Remove null values

        return filteredData;
    }

    function getFiscalYearsFromStartDate(start_date) {

        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).toLocal();
        const currentDate = DateTime.now().toLocal();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, it's a single year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Include the current fiscal year only if the current month is before the fiscal year start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }
    function parseMonthYear(monthYear) {
        return DateTime.fromFormat(monthYear, 'MMM-yyyy').startOf('month');
    }

    // Helper function to get the start and end of the month range
    function getMonthRange(period) {
        const rangeRegex = /^([A-Za-z]+-\d{4}) to ([A-Za-z]+-\d{4})$/;
        const singleMonthRegex = /^([A-Za-z]+-\d{4})$/;

        if (singleMonthRegex.test(period)) {
            const date = parseMonthYear(period);
            return [date, date.endOf('month')];
        } else if (rangeRegex.test(period)) {
            const [, startMonthYear, endMonthYear] = period.match(rangeRegex);
            const start = parseMonthYear(startMonthYear);
            const end = parseMonthYear(endMonthYear).endOf('month');
            return [start, end];
        } else {
            throw new Error("Invalid date format");
        }
    }

    // Main filtering function using Luxon
    function filterObjectsByDateRange(dateString, objects) {
        const [startRange, endRange] = getMonthRange(dateString);

        return objects.filter(obj => {
            const objStart = DateTime.fromISO(obj.start_date);
            const objEnd = obj.end_date ? DateTime.fromISO(obj.end_date) : DateTime.utc(); // Use current date if end_date is null

            return objStart <= endRange && objEnd >= startRange;
        });
    }
    function parseReportingPeriod(period) {
        return DateTime.fromFormat(period, 'MM-yyyy').startOf('month');
    }
    function filterByReportingPeriods(dateString, objects) {
        const [startRange, endRange] = getMonthRange(dateString);
        console.log(startRange, endRange, objects, dateString)
        return objects.filter(obj => {
            return obj.reporting_period.every(period => {
                const periodDate = parseReportingPeriod(period);
                return periodDate >= startRange && periodDate <= endRange;
            });
        });
    }
    function getMonthCount(period) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const singleMonthRegex = /^([A-Za-z]+-\d{4})$/;
        const rangeRegex = /^([A-Za-z]+-\d{4}) to ([A-Za-z]+-\d{4})$/;

        // Helper function to get month index from "Month-Year"
        function getMonthIndex(monthYear) {
            const [month, year] = monthYear.split("-");
            return { monthIndex: monthNames.indexOf(month), year: parseInt(year) };
        }

        // Check if it's a single month or a range
        if (singleMonthRegex.test(period)) {
            return 1; // Single month always returns count 1
        } else if (rangeRegex.test(period)) {
            const [, startMonthYear, endMonthYear] = period.match(rangeRegex);
            const start = getMonthIndex(startMonthYear);
            const end = getMonthIndex(endMonthYear);

            let monthDiff = (end.year - start.year) * 12 + (end.monthIndex - start.monthIndex) + 1;
            return monthDiff; // Returns the number of months between the range
        } else {
            return 0
        }
    }
    const updateCheckboxValue = (checked, topindex, index) => {
        let loc = JSON.parse(JSON.stringify(kpilist))

        loc[topindex].labels[index].checked = checked
        setKPIList(loc)
        forceUpdate()
    }
    const renderTable = (scopeData, topindex) => {
        console.log(topindex, scopeData)
        return (
            <div className="data-table" key={scopeData.title} style={{ whiteSpace: 'nowrap' }}>
                <div className='col-12 grid m-0 header cur-pointer' onClick={() => { if (scopeData.labels.some(i => i.assignmentCount !== 0)) { console.log(scopeData.selected); scopeData.selected = scopeData.selected ? !scopeData.selected : true }; forceUpdate() }}>
                    <div className='col-11  grid m-0 p-0 align-items-center' >
                        <div className=' p-0 m-0 text-three-dot' style={{ maxWidth: '90%' }}>{scopeData.title} {scopeData.labels.every(i => i.assignmentCount === 0) && <span className="mandatory">* Assignment Missing</span>} </div>


                        {/* <div className='ml-1'> <i onClick={(e) => { e.stopPropagation(); setMethodology({ text: scopeData.methodology, indicator: scopeData.title }); setMethodologyDialog(true) }} className='pi pi-exclamation-circle' /> </div> */}
                    </div>


                    <div className='col-1 grid m-0 justify-content-end'>

                        <div><i className={scopeData.selected ? 'pi pi-angle-up' : 'pi pi-angle-down'} /></div>
                    </div>

                </div>
                <div style={{ overflowX: 'auto' }}>
                    {scopeData.selected && <table style={{ minWidth: '100%' }}>
                        <tr>
                            <th colSpan={1}></th>
                            {scopeData.labels.length ?

                                scopeData.labels.map((label, index) => {

                                    return (
                                        <th key={index} colSpan={label.approverFrequency} >
                                            <div className='col-12 grid m-0 p-0 justify-content-between align-items-center' >
                                                <div className='col-4 m-0 p-0 flex' >

                                                    <Checkbox inputId={label.name} disabled={label.disabled} value={label.checked} onChange={(e) => { updateCheckboxValue(e.checked, topindex, index) }} checked={(label.checked || label.status === 1)} style={{ marginRight: "4px" }} />


                                                </div>

                                                <div className='col-6 m-0 p-0'>

                                                </div>
                                            </div>
                                        </th>)
                                }) :
                                <th colSpan={12} />
                            }
                            <th colSpan={3} rowSpan={1}>
                                Performance Deviation
                                {/* { scopeData.deviation  ? <> <i style={{color: !scopeData.deviationDirection ? 'red':'green'}} className={ 'pi ' +(!scopeData.deviationDirection ? 'pi-arrow-up' :'pi-arrow-down')} /> {scopeData.deviation} change YoY </> : 'NA'} */}
                            </th>
                            <th colSpan={1} rowSpan={2}>
                                Commentary
                            </th>
                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Reporting Month</th>

                            {scopeData.labels.map((month, index) => <td colSpan={month.approverFrequency} key={index}>{month.month}</td>)}
                            {scopeData.data.map((item, itemindex) => {

                                return (
                                    <>
                                        {itemindex === 0 && <th className='no-border-right  fixed-columns' rowSpan={1} > {scopeData.curMTDMonths} YTQ  </th>}
                                        {itemindex === 0 && <th className='no-border-right  fixed-columns' rowSpan={1}>{scopeData.curMTDMonths} YTQ  </th>}
                                        {itemindex === 0 && <th className='no-border-right fixed-columns' rowSpan={1}>Change </th>}

                                    </>
                                )
                            })

                            }

                        </tr>
                        <tr>
                            {scopeData.data.map((item, itemindex) => {

                                return (
                                    <>
                                        <th className='no-border-right fixed-columns' >{item.unit}</th>
                                        {scopeData.labels ? scopeData.labels.map((month, index) => <td colSpan={month.approverFrequency} key={index}>{month.value} {(month.status !== 1 && month.status !== 2) && <span className='mandatory'>* </span>} </td>) : <td colSpan={12} />}
                                        {itemindex === 0 && <td className='no-border-right  fixed-columns' rowSpan={scopeData.data.length + 3} > {scopeData.curMTDMonthValue}   </td>}
                                        {itemindex === 0 && <td className='no-border-right  fixed-columns' rowSpan={scopeData.data.length + 3}>{scopeData.prevMTDMonthValue}   </td>}
                                        {itemindex === 0 && <td className='no-border-right fixed-columns' rowSpan={scopeData.data.length + 3}>{scopeData.deviation} </td>}Required
                                        {itemindex === 0 && <td className='fixed-columns' rowSpan={scopeData.data.length + 3}> <div className={'flex justify-content-center  clr-navy fw-6 fs-14' + ((scopeData.performanceCommentary.length || scopeData.labels.some(i => i.new && i.threshold)) ? ' text-underline cur-pointer' : '')} style={{ color: (scopeData.labels.some(i => i.new && i.threshold)) ? 'red' : scopeData.performanceCommentary.length ? 'green' : 'gray' }}>   <div onClick={() => { setSelectedIndicator(topindex); setNote(''); setNoteVisible(true); setCommentList(scopeData.performanceCommentary) }}> {scopeData.labels.some(i => i.new && i.threshold) ? 'Required' : 'View/Update'} </div> </div>  </td>}
                                    </>
                                )
                            })

                            }

                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Data Source(s)</th>
                            {scopeData.labels.length ? scopeData.labels.map((label, index) => <td colSpan={label.approverFrequency} key={index}><span className={(label.overallData.length && !label.upcoming) ? 'cur-pointer clr-navy text-underline' : ''} onClick={() => { label.overallData.length ? openDataSource(label.overallData, topindex) : console.log('') }}>{label.upcoming ? 'NA' : label.overallData.length ? 'View' : '-'}</span> </td>) : <td colSpan={12} />}
                            {/* <td className='no-border-right  fixed-columns' >  </td>
                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='fixed-columns' > </td> */}

                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Action</th>
                            {scopeData.labels.length ? scopeData.labels.map((label, index) => <td colSpan={label.approverFrequency} key={index}>  <span className={(label.status === 1) ? 'cur-pointer clr-navy text-underline' : ''} onClick={() => { (label.status === 1) ? revokeApproval(label.approvedData) : console.log('') }} >{(label.status === 1) ? 'Revert Approval' : ''}</span> </td>) : <td colSpan={12} />}

                            {/* <td className='no-border-right  fixed-columns' >  </td>
                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='fixed-columns' > </td> */}

                        </tr>
                        <tr>
                            <th className='no-border-right fixed-columns' >Status</th>
                            {scopeData.labels.length ? scopeData.labels.map((label, index) => {
                                const statusItem = status.find(i => i.id === label.status);

                                return (
                                    <td key={index} colSpan={label.approverFrequency} >
                                        {(label.upcoming === false && statusItem) ? (
                                            <div style={{
                                                display: 'flex',
                                                width: '160px',
                                                justifyContent: 'center'
                                            }} >
                                                <span style={{ width: 150 }} className={`status ${statusItem.name.toLowerCase().replace(/\s+/g, '-')} m-0`}>
                                                    {statusItem.name}
                                                </span>
                                            </div>
                                        ) : (<div style={{ width: 160 }}></div>)}
                                    </td>
                                );
                            }) : <td colSpan={12} />}
                            {/* <td className='no-border-right  fixed-columns' >  </td>
                            <td className='no-border-right  fixed-columns' >  </td>
                            <td className='fixed-columns' > </td> */}

                        </tr>



                    </table>}
                </div>
            </div>
        );
    };
    const expandAll = () => {

        let loc = JSON.parse(JSON.stringify(kpilist))
        let find = kpilist.every(i => i.status !== 6 && i.selected)
        if (find) {
            loc.forEach((item, index) => {

                item.selected = false

            })
        } else {
            loc.forEach((item, index) => {

                if (item.status !== 6 && item.status !== 5) {
                    console.log(item)
                    item.selected = true
                }


            })
        }
        setKPIList(loc)
        forceUpdate()
    }
    const openDataSource = (datasources, topindex) => {
        if (datasources.length) {
            console.log(datasources)
            setSelectedIndicator(() => topindex);
            setSourceList(datasources)
            setSourceDialog(true)
        }

    }
    const responsibilityTemplate = (rowData) => {
        let text = []
        if (rowData.status === 6 || rowData.status === 7 || rowData.status === 1) {
            text = rowData.reporters
        } else if (rowData.status === 3) {
            text = rowData.reviewers
        } else if (rowData.status === 4) {
            text = rowData.approvers
        }
        return <div><ToolTip target={'.reviewer'} position="top" />  <div className="text-three-dot reviewer" data-pr-tooltip={text.join(",")} style={{ maxWidth: 150 }}>{text.join(',')}</div></div>


    }
    const actionTemplate = (rowData) => {

        if (rowData.type === 2) {
            return (
                <div className=' fw-6 fs-14 cur-pointer  text-three-dot text-underline' onClick={() => { returnToReporter(rowData) }} >Reject </div>
            )
        }

    }
    const returnToReporter = async (obj) => {
        console.log(obj)
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Alert</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Please enter reason for returning to reporter on this parameter(s)</div>`,
            input: 'textarea',
            inputValue: '',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value.trim()) {
                    return 'Enter Remarks'
                }
            }
        })

        if (return_remarks && return_remarks.trim()) {
            let newObj = {}
            let dt = DateTime.utc()
            newObj['type'] = 0
            newObj['reject'] = 1
            newObj['last_modified_on'] = dt
            newObj['last_modified_by'] = login_data.id
            newObj['logs'] = [{ user_id: login_data.id, user_type: 3, type: 1, category: 2, created_on: dt, formId: obj.dcfId, remarks: return_remarks }, ...obj.logs]
            if (obj.return_remarks === null) {
                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 3, type: 1, user_id: login_data.id, created_on: dt }]

            } else {
                let lt = obj.return_remarks
                lt.push({ remarks: return_remarks, user_type: 3, type: 1, user_id: login_data.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(obj.id), newObj).then((res) => {
                let loc1 = JSON.parse(JSON.stringify(quantitativesubmission))
                let loc2 = JSON.parse(JSON.stringify(overallQuantitativeSubmission))
                let loc3 = JSON.parse(JSON.stringify(sourcelist))

                let index1 = loc1.findIndex(i => i.id === obj.id)
                let index2 = loc2.findIndex(i => i.id === obj.id)
                let index3 = loc3.findIndex(i => (i.data && (i.data.id === obj.id)))
                console.log(index3,)
                if (index1 !== -1) {
                    loc1[index1] = { ...loc1[index1], ...newObj }


                    setQuantitativeSubmission(loc1)
                }
                if (index3 !== -1) {
                    console.log(loc3[index3])
                    loc3[index3].data = { ...loc3[index3].data, ...newObj }
                    loc3[index3].status = 7

                    setSourceList(loc3)
                }
                if (index1 !== -1) {
                    loc2[index2] = { ...loc1[index2], ...newObj }
                    setOverallQuantitativeSubmission(loc2)
                }
                refreshData(filter, loc1, loc2)
                Swal.fire({
                    title: "Data Submission Sent Back To Reporter For Correction",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                })
            }).catch((e) => {
                console.log(e)
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                })

            })

        }
    }
    const revokeApproval = async (datasources) => {
        console.log(datasources)
        if (datasources.length && datasources.every(i => i.type === 3)) {
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Revoke Approval(s)</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are You Sure Want to Revoke Approval</div>`,
                showCancelButton: true,
                confirmButtonText: 'Revoke',

            })
            if (accept) {
                setBlock(true)
                let approvedDatas = datasources

                try {

                    // Map over the array and initiate API calls for each object
                    const promises = approvedDatas.map(async (obj) => {
                        // Replace with your API call, e.g., fetch or axios
                        const response = await revokeSubmission(obj);
                        return response;
                    });

                    // Wait until all promises are resolved
                    const results = await Promise.all(promises);

                    let newData = mergeSubmission(quantitativesubmission, results)
                    let newData2 = mergeSubmission(overallQuantitativeSubmission, results)
                    setQuantitativeSubmission(newData)
                    setOverallQuantitativeSubmission(newData2)

                    refreshData(filter, newData, newData2)
                    setApproveDialog(false)
                    setBlock(false)

                } catch (error) {
                    // Handle any errors that occur during the API calls
                    setApproveDialog(false)
                    setBlock(false)


                }
            }
        }
    }
    const formTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = dcflist.find(i => i.id === rowData.dcfId)
        if (find) {
            txt = find.title
        }

        return <>
            <div className='clr-navy fw-6 fs-14 cur-pointer text-three-dot text-underline' onClick={() => { window.open(window.origin + '/data_input_status/' + rowData.dcfId + '/' + rowData.id) }} >{txt} </div>
        </>
    }
    const formTemplate_ = (rowData) => {
        let txt = 'Not Found'
        let find = dcflist.find(i => i.id === rowData.dcfId)
        if (find) {
            txt = find.title
        }

        return <>
            <div className=' fw-6 fs-14  text-three-dot '>{txt} </div>
        </>
    }
    const statusTemplate = (rowData) => {
        let txt = 'Not Found'
        if (rowData.status === 6) {
            txt = 'Submissions Due'
        } else if (rowData.status === 7) {
            txt = 'Submissions Overdue'
        } else if (rowData.status === 1) {
            txt = 'Resubmission Required'
        } else if (rowData.status === 3) {
            txt = 'Under Review'
        } else if (rowData.status === 4) {
            txt = 'Under Approval'
        } else if (rowData.status === 5) {
            txt = 'Approved'
        }
        return <>{txt}</>
    }
    const getCoverageText = (rowData) => {
        let text = "";

        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    const coverageTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = getCoverageText(rowData)
        if (find) {
            txt = find
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const reporterTemplate = (rowData) => {
        console.log(rowData)
        let txt = 'Not Found'
        let find = getUser(rowData.reporter_modified_by)
        if (find) {
            txt = find
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const reviewerTemplate = (rowData) => {
        let txt = 'Not Found'
        let find = getUser(rowData.reviewer_modified_by)
        if (find) {
            txt = find
        } else {
            txt = 'Self'
        }

        return <>
            <div >{txt} </div>
        </>
    }
    const getUser = (id) => {
        if (id === admin_data.id) {
            return "Enterprise Admin";
        }
        console.log(userLookup[id])
        return userLookup[id] ? userLookup[id].information.empname : ''
    };
    const userLookup = userList.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
    }, {});
    const rpTemplate = (rowData) => {


        return <>
            <div >{getRPTextFormat(rowData.reporting_period)} </div>
        </>
    }
    const getDueMonth = (dateStr) => {
        const [from, to] = dateStr.split(' to ')
        if (to) {
            return to
        }
        return from
    }
    function getOverdueDays(monthString) {
        console.log(monthString)
        const [startMonth, endMonth] = monthString.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days;
    }
    const customtHeader = <div style={{
        fontWeight: '600',
        fontSize: '24px'
    }}>Approver Comments for Reporter/Reviewer if any </div>;

    const customPlaceholder = <div style={{
        fontWeight: '500',
        fontSize: '16px'
    }}>Enter your comment</div>;
    const mergeSubmission = (oldArr, newArr) => {
        const newMap = new Map(newArr.map(item => [item.id, item]));

        return oldArr.map(item => newMap.has(item.id) ? newMap.get(item.id) : item);
    };
    async function revokeSubmission(obj) {
        // Simulating an API call with a delay
        return new Promise((resolve) => {
            let newObj = {}
            let dt = DateTime.utc()

            newObj['type'] = 2
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['last_modified_by'] = login_data.id
            if (!obj.return_remarks) {
                newObj['return_remarks'] = [{ remarks: approvecomment, user_type: 2, type: 1, user_id: login_data.id, created_on: dt }]

            } else {
                let lt = obj.return_remarks
                lt.push({ remarks: approvecomment, user_type: 2, type: 1, user_id: login_data.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(obj.id), newObj).then((res) => {
                if (!checkHardcoded(obj.dcfId.toString())) {
                    try {
                        APIServices.post(API.QNDP_Delete_By_SubmitId, { userProfileId: admin_data.id, submitId: obj.id })
                    } catch (e) {
                        console.log(e)
                    }
                }
                // let loc3 = JSON.parse(JSON.stringify(sourcelist))

                // let index3 = loc3.findIndex(i => (i.data && (i.data.id === obj.id)))
                // console.log(index3,)

                // if (index3 !== -1) {
                //     console.log(loc3[index3])
                //     loc3[index3].data = { ...loc3[index3].data, ...newObj }
                //     loc3[index3].status = 4

                //     setSourceList(loc3)
                // }

                resolve({ ...obj, ...newObj })
                // saveAutomatedResponseType2InDP(obj.response, obj)
            })


        })
    }
    const getValue = (obj) => {
        if (obj.type === 'checkbox-group' || obj.type === 'radio-group') {
            return obj.values
        } else {
            return obj.value
        }
    }
    const saveManualResponseInDP = async (resdata, obj) => {
        let result = []
        if (resdata.length) {

            for (let i = 0; i < resdata.length; i++) {
                let item = resdata[i]
                if (item.type !== 'paragraph' && item.name.toLowerCase().startsWith('dp')) {
                    result.push({ userProfileId: admin_data.id, submitId: obj.id, formType: 1, tier0_id: obj.tier0_id, tier1_id: obj.tier1_id, tier2_id: obj.tier2_id, tier3_id: obj.tier3_id, level: obj.level, frequency: obj.frequency, standard: obj.standard, dp: item.name, value: getValue(item), dataType: item.type, entityUserAssId: obj.entityUserAssId, entityAssId: obj.entityAssId, dcfId: obj.dcfId, reporting_period: obj.reporting_period, submissionType: 1, approved_by: login_data.id, reviewed_by: obj.reviewer_modified_by, reported_by: obj.reporter_modified_by, created_by: login_data.id, created_on: DateTime.utc() })
                }
            }
        } else {
            result.push({ userProfileId: admin_data.id, submitId: obj.id, formType: 1, tier0_id: obj.tier0_id, tier1_id: obj.tier1_id, tier2_id: obj.tier2_id, tier3_id: obj.tier3_id, level: obj.level, frequency: obj.frequency, standard: obj.standard, dp: '$$$', entityUserAssId: obj.entityUserAssId, entityAssId: obj.entityAssId, dcfId: obj.dcfId, reporting_period: obj.reporting_period, submissionType: 2, approved_by: login_data.id, reviewed_by: obj.reviewer_modified_by, reported_by: obj.reporter_modified_by, created_by: login_data.id, created_on: DateTime.utc() })
        }

        for (let i = 0; i < result.length; i++) {

            try {

                const response = await APIServices.post(API.QNDP_Report_UP(admin_data.id), result[i]);


            } catch (error) {
                alert(`Error : 420-${obj.dcfId}-${obj.id}, Found an issue, close this window stop approving data.please contact support@eisqr.com with screenshot of this error`)

            }

        }
        return result
        console.log(result)
    }
    const checkHardcoded = (id) => {
        return (hardcoded.dcf.includes(id) || hardcoded.dcf2.includes(id))
    }
    async function approveSubmission(obj) {
        // Simulating an API call with a delay
        return new Promise((resolve) => {
            let newObj = {}
            let dt = DateTime.utc()

            newObj['type'] = 3
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['approver_modified_on'] = dt
            newObj['approver_modified_by'] = login_data.id
            newObj['last_modified_by'] = login_data.id
            if (!obj.return_remarks) {
                newObj['return_remarks'] = [{ remarks: approvecomment.trim().length ? approvecomment : 'Data Approved', user_type: 3, type: 1, user_id: login_data.id, created_on: dt }]

            } else {
                let lt = obj.return_remarks
                lt.push({ remarks: approvecomment.trim().length ? approvecomment : 'Data Approved', user_type: 3, type: 1, user_id: login_data.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(obj.id), newObj).then((res) => {
                console.log(obj)
                if (!checkHardcoded(obj.dcfId.toString())) {

                    saveManualResponseInDP(obj.response, obj)

                } else {
                    console.log('hardcoded')
                }
                resolve({ ...obj, ...newObj })

                // saveAutomatedResponseType2InDP(obj.response, obj)
            })


        })
    }

    const approveData = async () => {
        let count = kpilist.filter(i => i.labels.some(x => x.checked && !x.disabled && x.new)).length

        let required = kpilist.filter(i => (i.threshold ? i.performanceCommentary.length === 0 : false) && i.labels.some((x, y) => x.checked && !x.disabled && x.new))

        if (required.length === 0) {
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Approve</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are You Sure Want to Approve ${count} Indicators</div>`,
                showCancelButton: true,
                confirmButtonText: 'Approve',

            })
            if (accept) {
                setBlock(true)
                let approvedDatas = removeDuplicatesFromArrayByKey(kpilist.flatMap(x => x.labels.filter(i => i.checked && !i.disabled).flatMap(i => i.approvedData)), 'id')

                try {

                    // Map over the array and initiate API calls for each object
                    const promises = approvedDatas.map(async (obj) => {
                        // Replace with your API call, e.g., fetch or axios
                        const response = await approveSubmission(obj);
                        return response;
                    });

                    // Wait until all promises are resolved
                    const results = await Promise.all(promises);
                    console.log(results)
                    let newData = mergeSubmission(quantitativesubmission, results)
                    let newData2 = mergeSubmission(overallQuantitativeSubmission, results)
                    console.log(newData, newData2)
                    setQuantitativeSubmission(newData)
                    setOverallQuantitativeSubmission(newData2)

                    refreshData(filter, newData, newData2)
                    setApproveDialog(false)
                    setBlock(false)

                } catch (error) {
                    // Handle any errors that occur during the API calls
                    setApproveDialog(false)
                    setBlock(false)


                }
            }
        } else {
            Swal.fire({
                title: "Some Indicator Requires Comments, kindly check & try again",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            })
        }



    }
    const updateNotes = () => {
        if (note && note.trim().length) {
            let selectedIndicator_ = kpilist[selectedIndicator]
            if (selectedIndicator_) {
                let loc = JSON.parse(JSON.stringify(qnapprovals))
                let index = qnapprovals.findIndex(i => i.indicatorId === selectedIndicator_.id && i.tier1_id === filter.country && i.tier2_id === filter.city && i.tier3_id === filter.site && i.year === filter.year)
                if (index === -1) {
                    APIServices.post(API.QN_Indicator_Approval_UP(admin_data.id), { performanceCommentary: [{ comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }], indicatorId: selectedIndicator_.id, tier1_id: filter.country, tier2_id: filter.city, tier3_id: filter.site, year: filter.year }).then((res) => {
                        kpilist[selectedIndicator].performanceCommentary = [{ comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }]
                        setCommentList((prev) => ([{ comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }, ...prev]))
                        loc.push(res.data)
                        setQnApproval(loc)
                    })
                } else {
                    APIServices.patch(API.QN_Indicator_Approval_Edit(selectedIndicator_.approverId), { performanceCommentary: [{ comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }, ...kpilist[selectedIndicator].performanceCommentary] }).then((res) => {
                        kpilist[selectedIndicator].performanceCommentary = [{ comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }, ...kpilist[selectedIndicator].performanceCommentary]
                        setCommentList((prev) => ([...prev, { comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }]))
                        loc[index].performanceCommentary = [{ comment: note.trim(), created_on: DateTime.utc(), created_by: login_data.id }, ...kpilist[selectedIndicator].performanceCommentary]
                        setQnApproval(loc)
                    })
                }
            }

        }


    }
    const getUserId = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div>
                    <h1 style={{ marginBottom: '0px', fontWeight: '700', fontSize: '20px' }}>KPI Approval Portal</h1>
                    {/* <p style={{fontWeight: '400', fontSize: '16px'}}>Impedit quis repellat quia quas provident dolor repellendus.</p> */}
                </div>
                <div
                    className="block mt-2 md:mt-0 p-input-icon-left"

                >
                    <i className="pi pi-search" />
                    <InputText type="search" placeholder="Search..." style={{ width: '100%' }} />
                </div>
            </div>
            <div className='grid m-0 align-items-center justify-content-between' >

                <div className="grid m-0 col-10">
                    <div className="p-col-3 p-md-3">
                        <Dropdown value={filter.year} options={yearoption} optionValue="name" optionLabel="label" onChange={(e) => setFilter((prev) => ({ ...prev, year: e.value }))} placeholder="Select Year" />
                    </div>
                    <div className="p-col-3 p-md-3">
                        <Dropdown value={filter.country} options={locList.country} optionLabel="name" optionValue="id" onChange={(e) => { updateLocationFilterValue('country', e.value) }} placeholder="Select Country" />
                    </div>
                    {filter.country !== 0 &&
                        <div className="p-col-3 p-md-3">
                            <Dropdown value={filter.city} options={locList.city} optionLabel="name" optionValue="id" onChange={(e) => { updateLocationFilterValue('city', e.value) }} placeholder="Select Region" />
                        </div>}
                    {filter.country !== 0 && filter.city !== 0 &&
                        <div className="p-col-3 p-md-3">
                            <Dropdown value={filter.site} options={locList.location} optionLabel="name" optionValue="id" onChange={(e) => { updateLocationFilterValue('site', e.value) }} placeholder="Select Business Unit" />
                        </div>}
                </div>
                <div className='col-2 grid m-0 justify-content-center align-items-center' >
                    {kpilist.length !== 0 && <Button style={{ height: 30 }} text label={kpilist.every(i => i.status !== 6 && i.selected) ? 'Collapse All' : 'Expand All'} onClick={expandAll} />}
                </div>
            </div>
            {kpilist.map((item, index) => {
                return renderTable(item, index)
            })

            }
            {filter.year !== null && filter.country !== null && kpilist.length !== 0 &&
                <div style={{ display: "flex", justifyContent: "end", marginTop: "3%" }}>

                    {/* <Button
                                label="Approve all Pending for Approval"
                                style={{ marginRight: '1%' }}
                                disabled={!indicator.every(obj => obj.labels.every(i => i.checked === true && !i.disabled))}
                                onClick={() => { setApproveComment(''); setApproveDialog(true) }}

                            /> */}
                    <Button
                        label="Approve Selected"
                        outlined
                        disabled={!kpilist.some(obj => obj.labels.some(i => i.checked === true && !i.disabled))}
                        style={{ color: "rgba(0, 82, 132, 1)" }}
                        onClick={() => { setApproveComment(''); setApproveDialog(true) }}
                    />
                </div>}

            <Dialog header={(selectedIndicator !== null) ? 'Data Source for ' + kpilist[selectedIndicator]?.title + ' - ' + filter.year : 'Data Source'} style={{ width: '75%' }} visible={sourcedialog} onHide={() => { setSourceDialog(false) }}>
                <TabView>
                    <TabPanel header="Data Source List">
                        <div>
                            <DataTable value={sourcelist.filter(i => i.data && (i.data.type === 2 || i.data.type === 3)).map(i => i.data)} >
                                <Column header='Form' body={formTemplate} />
                                <Column header='Entity' body={coverageTemplate} />
                                <Column header='Reporting Period' body={rpTemplate} />
                                <Column header='Reporter' body={reporterTemplate} />
                                <Column header='Reviewer' body={reviewerTemplate} />
                                <Column header='Action' body={actionTemplate} />
                            </DataTable>
                        </div>
                    </TabPanel>
                    <TabPanel header="Pending Data Submissions">
                        <div>
                            <DataTable value={sourcelist.filter(i => (!i.data || (i.data && i.data.type < 2)))} >
                                <Column header='Form' body={formTemplate_} />
                                <Column header='Entity' body={coverageTemplate} />
                                <Column header='Reporting Period' field='reporting_period' />
                                <Column header='Responsibility' body={responsibilityTemplate} />
                                <Column header='Status' body={statusTemplate} />
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>

            </Dialog>
            <Dialog header={customtHeader} visible={approvedialog} style={{
                width: "394px",
                background: 'rgba(255, 255, 255, 1)',
            }} onHide={() => { if (!approvedialog) return; setApproveDialog(false); }}>
                <div>
                    <div className='col-12 m-0'>
                        <div className='col-12'>
                            <InputTextarea style={{ width: '100%' }} autoResize value={approvecomment} onChange={(e) => setApproveComment(e.target.value)} rows={5} cols={30} placeholder="Enter your comment" />
                        </div>
                        <div className='col-12 flex justify-content-between'>
                            <Button
                                label="Close"
                                outlined

                                onClick={() => { setApproveDialog(false) }}
                            />
                            <Button
                                label="Approve Data"


                                onClick={() => { approveData() }}
                            />
                        </div>
                    </div>





                </div>
            </Dialog>
            <Dialog header={selectedIndicator !== null ? 'Performance Commentary ' + kpilist[selectedIndicator]?.title + ' - ' + filter.year : 'Performance Commentary'} visible={notevisible} style={{
                width: "75%", height: 'calc(100vh -  100px)',
                background: 'rgba(255, 255, 255, 1)',
            }} onHide={() => { if (!notevisible) return; setNoteVisible(false); }}>
                <div>
                    <div className='grid col-12 m-0 p-0'>
                        <div className='col-12'>
                            <InputTextarea style={{ width: '100%' }} autoResize value={note} onChange={(e) => { setNote(e.target.value) }} rows={5} cols={30} placeholder="Enter comments" />
                        </div>
                        <div className='col-12 flx justify-content-end'>
                            <Button onClick={() => updateNotes()} label={'Save Comment'} />
                        </div>
                        <div className='col-12'>
                            {commentlist.map((cmnt) => {
                                return (
                                    <div className='card m-1 p-3'>
                                        <div className='col-12 grid m-1 p-0'>

                                            <div className='col-6 fw-5 p-0  ' style={{ color: 'green' }}>{DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd/M/yyyy')}</div>
                                            <div className='col-6 fw-5 clr-navy p-0 flex justify-content-end'>{getUserId(cmnt.created_by)}</div>
                                        </div>
                                        <div className='m-1' style={{ fontStyle: 'italic', fontFamily: 'bold' }}>{cmnt.comment}</div>

                                    </div>
                                )
                            })

                            }
                        </div>

                    </div>


                </div>
            </Dialog>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(KPIApproval, comparisonFn);
