import React, { useEffect, useState, useRef } from "react";
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { ContextMenu } from "primereact/contextmenu";
import EdiText from 'react-editext'
import Swal from "sweetalert2";

const CertificateConfig = () => {

    const [certificatelist, setCertificateList] = useState([])
    const [certauthoritylist, setCertAuthorityList] = useState([])
    const [certificatelevellist, setCertificateLevelList] = useState([])
    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.admindetail);
    const [newcertificatemodal, setNewCertificateModal] = useState(false)
    const [newcertificateobj, setNewCertificationObj] = useState({ title: '' })
    const [newcertificateauthobj, setNewCertificationAuthObj] = useState({ title: '' })
    const [newcertificatelevelobj, setNewCertificationLevelObj] = useState({ title: '' })
    const [submitted, setSubmitted] = useState(false)
    const [selected, setSelected] = useState({ certificate: null })
    const certlevel = useRef([]);
    const certauthority = useRef([]);

    useEffect(() => {
        APIServices.get(API.Certifcation).then(res => setCertificateList(res.data))
    }, [])

    const saveNewCertificate = () => {
        setSubmitted(true)
        console.log(newcertificateobj)
        if (newcertificateobj.title.trim().length !== 0) {
            if (newcertificateobj.id !== undefined) {
                let obj = { ...newcertificateobj }
                APIServices.patch(API.Certifcation_Edit(newcertificateobj.id), obj
                ).then((a) => {
                    let loc = JSON.parse(JSON.stringify(certificatelist))
                    let index = loc.findIndex(x => x.id === newcertificateobj.id);
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...obj }
                    }
                    setCertificateList(loc)
                    setNewCertificateModal(false)
                    setSubmitted(false)
                    console.log(a)
                })
            } else {
                let obj = { ...newcertificateobj, created_on: DateTime.utc(), created_by: selector.id }
                APIServices.post(API.Certifcation, obj).then((a) => {
                    let loc = JSON.parse(JSON.stringify(certificatelist))

                    loc.push(a.data)
                    setCertificateList(loc)
                    setNewCertificateModal(false)
                    setSubmitted(false)
                    console.log(a)
                })
            }

        }
    }
    const addCertificateFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setNewCertificateModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveNewCertificate() }} />
        </>
    );
    const standardOptionTemplate = (rowData) => {
        return (
            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                <div className="col-10 text-three-dot">
                    {rowData.title}
                </div>

                <div className="col-2" style={{ zIndex: 10 }}>
                    <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                        e.stopPropagation();

                        setNewCertificationObj(JSON.parse(JSON.stringify(rowData))); setNewCertificateModal(true)
                    }}>edit</i>
                    <i className="material-icons" style={{ color: 'red' }} onClick={(e) => {
                        e.stopPropagation();
                        deleteCertification(rowData)
                    }}>delete</i>
                </div>
            </div>
        )
    }
    const getTierData = (val) => {
        console.log(val)
        setSelected(prev => ({ ...prev, certificate: val }))
        setNewCertificationAuthObj({ title: '' })
        setNewCertificationLevelObj({ title: '' })
        APIServices.get(API.Cert_Level_Certifcation(val)).then(res => { console.log(res.data, 'a'); setCertificateLevelList(res.data) })
        APIServices.get(API.Authority_Certifcation(val)).then(res => { console.log(res.data, 'b'); setCertAuthorityList(res.data) })
    }
    const showCertAuthorityCM = (e, ind) => {

        certauthority.current.forEach((i, j) => {
            if (j === ind) {
                certauthority.current[ind].show(e)
            } else {
                if (certauthority.current[j] !== null) {
                    certauthority.current[j].hide(e)
                }
            }
        })
        certlevel.current.forEach((i, j) => {

            if (certlevel.current[j] !== null) {
                certlevel.current[j].hide(e)

            }
        })


    }
    const showCertLevelCM = (e, ind) => {

        certlevel.current.forEach((i, j) => {
            if (j === ind) {
                certlevel.current[ind].show(e)
            } else {
                if (certlevel.current[j] !== null) {
                    certlevel.current[j].hide(e)
                }
            }
        })
        certauthority.current.forEach((i, j) => {

            if (certauthority.current[j] !== null) {
                certauthority.current[j].hide(e)

            }
        })


    }
    const setEditModeAuthority = (cyindex) => {
        certauthoritylist.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeLevel = (cyindex) => {
        certificatelevellist.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const deleteCertificationLevel = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to delete "${item.title}" from Certification Level</div>`,
            input: 'password',

            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        if (password === 'plsproseednow') {
            let loc = JSON.parse(JSON.stringify(certificatelevellist))
            let index = loc.findIndex(x => x.id === item.id)
            if (index !== -1) {
                APIServices.delete(API.Certifcation_Level_Edit(item.id)).then((res) => {
                    loc.splice(index, 1)
                    setCertificateLevelList(loc)

                })

            }
        }
    }
    const deleteCertification = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to delete "${item.title}" from Certification</div>`,
            input: 'password',

            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        if (password === 'plsproseednow') {
            let loc = JSON.parse(JSON.stringify(certificatelist))
            let index = loc.findIndex(x => x.id === item.id)
            if (index !== -1) {
                APIServices.delete(API.Certifcation_Edit(item.id)).then((res) => {
                    loc.splice(index, 1)
                    setCertificateList(loc)
                    if (selected.certificate === item.id) {
                        setSelected({ certificate: null })
                    }


                })

            }
        }
    }
    const deleteCertificationAuthority = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to delete "${item.title}" from Issuing Authority</div>`,
            input: 'password',

            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,

            inputAttributes: {
                maxlength: 15,
                autocapitalize: 'off',
                autocorrect: 'off',
                autocomplete: 'off',
            },
            didOpen: () => {



            }
        })
        if (password === 'plsproseednow') {
            let loc = JSON.parse(JSON.stringify(certauthoritylist))
            let index = loc.findIndex(x => x.id === item.id)
            if (index !== -1) {
                APIServices.delete(API.Authority_Issue_Edit(item.id)).then((res) => {
                    loc.splice(index, 1)
                    setCertAuthorityList(loc)
                })


            }
        }
    }
    const setEditModeCertAuthority = (cyindex) => {
        certauthoritylist.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeCertLevel = (cyindex) => {
        certificatelevellist.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const addNewLevel = () => {
        if (newcertificatelevelobj.title.trim().length !== 0) {
            if (newcertificatelevelobj.id !== undefined) {
                let obj = { ...newcertificatelevelobj }
                APIServices.patch(API.Certifcation_Level_Edit(newcertificatelevelobj.id), obj
                ).then((a) => {
                    let loc = JSON.parse(JSON.stringify(certificatelevellist))
                    let index = loc.findIndex(x => x.id === newcertificatelevelobj.id);
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...obj }
                    }
                    setCertificateLevelList(loc)

                    console.log(a)
                })
            } else {
                let obj = { ...newcertificatelevelobj, created_on: DateTime.utc(), created_by: selector.id }
                APIServices.post(API.Cert_Level_Certifcation(selected.certificate), obj).then((a) => {
                    let loc = JSON.parse(JSON.stringify(certificatelevellist))
                    setNewCertificationLevelObj({ title: '' })
                    loc.push(obj)
                    setCertificateLevelList(loc)

                    console.log(a)
                })
            }

        }
    }
    const addNewAuthority = () => {
        if (newcertificateauthobj.title.trim().length !== 0) {
            if (newcertificateauthobj.id !== undefined) {
                let obj = { ...newcertificateauthobj }
                APIServices.patch(API.Authority_Issue_Edit(newcertificateauthobj.id), obj
                ).then((a) => {
                    let loc = JSON.parse(JSON.stringify(certauthoritylist))
                    let index = loc.findIndex(x => x.id === newcertificateauthobj.id);
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...obj }
                    }
                    setCertAuthorityList(loc)

                    console.log(a)
                })
            } else {
                let obj = { ...newcertificateauthobj, created_on: DateTime.utc(), created_by: selector.id }
                APIServices.post(API.Authority_Certifcation(selected.certificate), obj).then((a) => {
                    let loc = JSON.parse(JSON.stringify(certauthoritylist))
                    setNewCertificationAuthObj({ title: '' })
                    loc.push(obj)
                    setCertAuthorityList(loc)

                    console.log(a)
                })
            }

        }
    }
    const updateAuthority = (e, id) => {
        console.log(e)
        let index = certauthoritylist.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.Authority_Issue_Edit(certauthoritylist[index].id),
                {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = certauthoritylist
                loc[index].title = e.trim();
                setCertAuthorityList(loc);
                forceUpdate()
            });
        }
    };
    const updateLevel = (e, id) => {
        console.log(e)
        let index = certificatelevellist.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.Certifcation_Level_Edit(certauthoritylist[index].id),
                {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = certificatelevellist
                loc[index].title = e.trim();
                setCertificateLevelList(loc);
                forceUpdate()
            });
        }
    };
    return (
        <div className="col-12 font-lato">
            {selector.role === 'eisqradmin' &&
                <div>
                    <h3 className="clr-navy">Certifcation Configuration </h3>
                    <div className="col-12 grid align-items-center justify-content-between">
                        <div className="col-10 grid align-items-center">
                            <label className="col-2 ">
                                Certification
                            </label>
                            <div className="col-5">
                                <Dropdown options={certificatelist} style={{ width: '100%' }} itemTemplate={standardOptionTemplate} filter optionLabel="title" optionValue="id" value={selected.certificate} onChange={(e) => { getTierData(e.value) }} />
                                {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                            </div>
                            <div className="col-1"
                                onClick={() => { setSubmitted(false); setNewCertificationObj({ title: '', created_on: DateTime.utc(), created_by: selector.id }); setNewCertificateModal(true) }}
                                style={{
                                    background: '#005284',
                                    borderRadius: '10px',
                                    color: 'white',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer'
                                }}>
                                <i className="material-icons" >add</i>
                                {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                            </div>
                        </div>

                    </div>
                    {selected.certificate !== null && <div className="grid flex justify-content-between">
                        <div className="col-6">
                            <div className="grid">
                                <div className="col-11">
                                    <InputText
                                        type={"text"}
                                        style={{ width: '100%' }}
                                        value={newcertificateauthobj.title}
                                        onChange={(e) => {
                                            setNewCertificationAuthObj(prev => ({ ...prev, title: e.target.value }))
                                        }}
                                        placeholder="Enter Authority"
                                    />
                                </div>
                                <div className="col-1" >
                                    <Button
                                        icon="pi pi-plus"

                                        style={{ cursor: newcertificateauthobj.title.trim().length === 0 ? 'not-allowed' : 'pointer', background: newcertificateauthobj.title.trim().length === 0 ? 'gray' : 'rgb(0, 82, 132)', border: newcertificateauthobj.title.trim().length === 0 ? '1px solid gray' : '1px solid rgb(0, 82, 132)' }}
                                        className="p-button-success mr-2"
                                        onClick={() => {
                                            addNewAuthority();
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-12"
                                style={{
                                    border: "1px solid grey",
                                    height: "calc(100vh - 290px)",
                                    overflow: "auto",
                                    borderRadius: 5
                                }}
                            >
                                {certauthoritylist.length === 0 ? (
                                    <text
                                        style={{
                                            padding: 5,
                                        }}
                                    >
                                        {"Add Auhtority"}
                                    </text>
                                )
                                    :
                                    (certauthoritylist.map((item, cyindex) => {
                                        return (
                                            <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                <div className="col-11"

                                                    style={{

                                                        borderBottom: "0.5px ridge grey", padding: 0
                                                    }}
                                                >
                                                    <EdiText
                                                        viewContainerClassName={selected.authority === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}
                                                        type='text'
                                                        value={item.title}
                                                        onSave={(e) => { updateAuthority(e, item.id); setEditModeCertAuthority() }}
                                                        editing={item.edit}
                                                        onCancel={() => { setEditModeCertAuthority() }}
                                                        hideIcons={true}
                                                        validationMessage="Title should not exist / not empty "
                                                        validation={val => { console.log(val); return val.trim().length !== 0 && certauthoritylist.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                        saveButtonContent="✔"
                                                        cancelOnUnfocus
                                                        cancelButtonContent={<strong>X</strong>}
                                                        editButtonClassName="custom-edit-button"
                                                    />

                                                </div>
                                                <ContextMenu model={[
                                                    {
                                                        label: 'Edit',
                                                        icon: 'pi pi-fw pi-pencil',
                                                        command: () => { setEditModeAuthority(cyindex) }
                                                    },
                                                    {
                                                        label: 'Delete',
                                                        icon: 'pi pi-fw pi-trash',
                                                        command: () => { deleteCertificationAuthority(item) }
                                                    },

                                                ]} ref={(r) => { certauthority.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                <div className="col-1" onContextMenu={(e) => { showCertAuthorityCM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                    <i className="material-icons" >settings</i>
                                                </div>
                                            </div>

                                        )
                                    }))
                                }
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="grid">
                                <div className="col-11">
                                    <InputText
                                        type={"text"}
                                        style={{ width: '100%' }}
                                        value={newcertificatelevelobj.title}
                                        onChange={(e) => {
                                            setNewCertificationLevelObj(prev => ({ ...prev, title: e.target.value }))
                                        }}
                                        placeholder="Enter Level"
                                    />
                                </div>
                                <div className="col-1" >
                                    <Button
                                        icon="pi pi-plus"

                                        style={{ cursor: newcertificatelevelobj.title.trim().length === 0 ? 'not-allowed' : 'pointer', background: newcertificatelevelobj.title.trim().length === 0 ? 'gray' : '#22C55E', border: newcertificatelevelobj.title.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                        className="p-button-success mr-2"
                                        onClick={() => {
                                            addNewLevel();
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-12"
                                style={{
                                    border: "1px solid grey",
                                    height: "calc(100vh - 290px)",
                                    overflow: "auto",
                                    borderRadius: 5
                                }}
                            >
                                {certificatelevellist.length === 0 ? (
                                    <text
                                        style={{
                                            padding: 5,
                                        }}
                                    >
                                        {"Add Certification Level"}
                                    </text>
                                )
                                    :
                                    (certificatelevellist.map((item, cyindex) => {
                                        return (
                                            <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                <div className="col-11"

                                                    style={{

                                                        borderBottom: "0.5px ridge grey", padding: 0
                                                    }}
                                                >
                                                    <EdiText
                                                        viewContainerClassName={'my-custom-view-wrapper'}
                                                        type='text'
                                                        value={item.title}
                                                        onSave={(e) => { updateLevel(e, item.id); setEditModeCertLevel() }}
                                                        editing={item.edit}
                                                        onCancel={() => { setEditModeCertLevel() }}
                                                        hideIcons={true}
                                                        validationMessage="Title should not exist / not empty "
                                                        validation={val => { console.log(val); return val.trim().length !== 0 && certificatelevellist.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                        saveButtonContent="✔"
                                                        cancelOnUnfocus
                                                        cancelButtonContent={<strong>X</strong>}
                                                        editButtonClassName="custom-edit-button"
                                                    />

                                                </div>
                                                <ContextMenu model={[
                                                    {
                                                        label: 'Edit',
                                                        icon: 'pi pi-fw pi-pencil',
                                                        command: () => { setEditModeLevel(cyindex) }
                                                    }, {
                                                        label: 'Delete',
                                                        icon: 'pi pi-fw pi-trash',
                                                        command: () => { deleteCertificationLevel(item) }
                                                    },

                                                ]} ref={(r) => { certlevel.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                <div className="col-1" onContextMenu={(e) => { showCertLevelCM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                    <i className="material-icons" >settings</i>
                                                </div>
                                            </div>

                                        )
                                    }))
                                }
                            </div>

                        </div>
                    </div>}
                </div>


            }
            <Dialog
                visible={newcertificatemodal}
                style={{
                    width: "60%",
                }}
                header={(newcertificateobj.id ? 'Edit' : 'Add') + ' Certificate'}
                modal
                footer={addCertificateFooter}
                onHide={() => {
                    setNewCertificateModal(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div className="col-12 ">

                            <div className="col-12 grid align-items-center">
                                <label className="col-4">
                                    Certificate Name
                                </label>
                                <div className="col-8">
                                    <InputText style={{ width: '100%' }} className={(submitted && newcertificateobj.title.trim().length === 0) ? 'p-invalid' : ''} value={newcertificateobj.title} onChange={(e) => { let loc = newcertificateobj; loc.title = e.target.value; setNewCertificationObj(loc); forceUpdate() }} />
                                </div>

                            </div>
                        </div>

                    </div>




                </div>

            </Dialog>
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CertificateConfig, comparisonFn);
