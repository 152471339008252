

const questionary = [
    {
        "type": "paragraph",
        "label": "Supplier Environmental Framework",
        "expanded": false

    },
    {
        "type": "paragraph",
        "label": "WATER",
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Monitoring Water Consumption",
        "expanded": false
    },
    {
        "type": "select",
        "attachment":true,
        "label": "Does the organization have a valid consent order under Water Act 1974 ?",
        "name": "SDP23_1",
        "subSectionId":1,
        "subTopicId":1,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is yes, is a copy of the consent order displayed in a prominent location?",
        "name": "SDP23_2",
        "subSectionId":1,
        "subTopicId":1,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
      
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "What is the source of water consumption in the organisation?",
        "name": "SDP23_4",
        "subSectionId":1,
        "subTopicId":1,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label":  "Has the organisation obtained the NOC from the ground water authority of the respective state, if ground water is used?",
        "name": "SDP23_3",
        "subSectionId":1,
        "subTopicId":1,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    
    {
        "type": "select",
        "label": "Does the organisation monitor water consumption in the organisation?",
        "name": "SDP23_5",
        "subSectionId":1,
        "subTopicId":1,
        "values": [
            {
                "label": "Yes",
                "value": "0",
                "selected": false
            },
            {
                "label": "No",
                "value": "1",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
       
      
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization installed water meters to monitor water consumption ? ",
        "name": "SDP23_6",
        "subSectionId":1,
        "subTopicId":1,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization calibrate the flowmeters at least once a year?",
        "name": "SDP23_7",
        "subSectionId":1,
        "subTopicId":1,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "ETP Operation & Maintainence",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization generate trade effluent?",
        "name": "SDP23_8",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0",
                "selected": false
            },
            {
                "label": "No",
                "value": "1",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to above, Is the Organisation a part of any common ETP?",
        "name": "SDP23_9",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If No to above,  Does the organisation have ETP installed?",
        "name": "SDP23_10",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to question 8iii, Whether flow meter is installed at the inlet & outlet of ETP?",
        "name": "SDP23_11",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is Yes to above, Whether flow meters for ETP calibrated at least once a year?",
        "name": "SDP23_12",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to question 8iii, Whether energy meter is installed at the inlet & outlet of ETP?",
        "name": "SDP23_13",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to question 8iii, Whether log sheet is being maintained for ETP?",
        "name": "SDP23_14",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to question 8iii, Whether the ETP is analysed once in a month?",
        "name": "SDP23_15",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether the quality and quantity of treated effluents conform the standard prescribed by the SPCB?",
        "name": "SDP23_16",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether the treated effluent is disposed as directed by the Board.",
        "name": "SDP23_17",
        "subSectionId":1,
        "subTopicId":2,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "STP Operation & Maintainence",
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "Is the organization a part of any common STP?",
        "name": "SDP23_18",
        "subSectionId":1,
        "subTopicId":3,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If No, Does the organisation has STP installed?",
        "name": "SDP23_19",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to 9ii, Whether flow meter is installed at the inlet & outlet of STP?",
        "name": "SDP23_20",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes, Whether flow meters for STP calibrated at least once a year?",
        "name": "SDP23_21",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to 9ii, Whether energy meter is installed at the inlet & outlet of STP?",
        "name": "SDP23_22",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to 9ii, Whether log sheet is being maintained for STP?",
        "name": "SDP23_23",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If Yes to 9ii, Whether the STP is analysed once in a month?",
        "name": "SDP23_24",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "0.1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether the quality and quantity of treated sewage conform the standard prescribed by the SPCB?",
        "name": "SDP23_25",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether the treated sewage is disposed as directed by the Board.",
        "name": "SDP23_26",
        "subSectionId":1,
        "subTopicId":3,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Water Stewardship Playbook",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have implemented activities/programs/kaizens from Water Stewardship Playbook?",
        "name": "SDP23_27",
        "subSectionId":1,
        "subTopicId":4,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If Yes to above, As per the Water Stewardship Playbook, please mention the activities/programs/kaizens which have been implemented by the organisation?",
        "name": "SDP23_28",
        "subSectionId":1,
        "subTopicId":4,
        "required": false,
        "value": null,
        "expanded": false,
        "attachment": true,
    },
    {
        "type": "paragraph",
        "label": "Water Neutrality",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the water neutrality percentage of the organization?",
        "name": "SDP23_29",
        "subSectionId":1,
        "subTopicId":5,
        "values": [
            {
                "label": "20",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "60",
                "value": "3",
                "selected": false
            },
            {
                "label": "80",
                "value": "4"
            },
            {
                "label": "100",
                "value": "5"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Zero Liquid Discharge Status",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization achieved Zero Liquid Discharge status from a competent authority?",
        "name": "SDP23_30",
        "subSectionId":1,
        "subTopicId":6,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization certified under ISO 14001:2015 standard?",
        "name": "SDP23_31",
        "subSectionId":1,
        "subTopicId":6,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization received any show cause notice in terms of water consumption & waste water from State Pollution Control Board?",
        "name": "SDP23_32",
        "subSectionId":1,
        "subTopicId":6,
        "values": [
            {
                "label": "Yes",
                "value": "-1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "attachment": true,
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "WASTE",
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Hazardous Waste",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization generate hazardous waste?",
        "name": "SDP23_33",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "3",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, then is the organization authorised under Hazardous Waste Management Rules(HWM), 2018?",
        "name": "SDP23_34",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization collect, handle, store and dispose hazardous waste in accordance with the HWM rules?",
        "name": "SDP23_35",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are the containers holding hazardous wastes marked as \"Hazardous Waste\" as per Form-8?",
        "name": "SDP23_36",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": " Are hazardous wastes disposed of by SPCB-approved agencies?",
        "name": "SDP23_37",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization a part of a common Treatment Storage Disposal Facility (TSDF)? ",
        "name": "SDP23_38",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, then whether the hazardous waste is being sent to TSDF regularly? ",
        "name": "SDP23_39",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are hazardous wastes kept in storage for longer than ninety days? If so, explain why.",
        "name": "SDP23_40",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is Form-3(Maintaining records of Hazardous Waste) being kept up to date at the location?",
        "name": "SDP23_41",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is Form 4 (Annual Returns) submitted to the SPCB by June 30th of each year? Please mention the date of the latest report submitted to the SPCB.",
        "name": "SDP23_42",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the person transporting the hazardous waste given a Transport Emergency (TREM) card as per Form 9(Transboundary Movement)?",
        "name": "SDP23_43",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the disposal of hazardous waste adhere to Form-10?",
        "name": "SDP23_44",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, is the acknowlegdement copy submitted to the SPCB?",
        "name": "SDP23_45",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has waste information been posted at the company's main entrance? (Using the format of the SPCB)",
        "name": "SDP23_46",
        "subSectionId":2,
        "subTopicId":7,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "E- Waste",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization manufacture/import electronic/electrical components?",
        "name": "SDP23_47",
        "subSectionId":2,
        "subTopicId":8,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, then is the organisation authorised under E-Waste Management(EWM) Rules, 2022?",
        "name": "SDP23_48",
        "subSectionId":2,
        "subTopicId":8,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organisation maintain records of e-waste generated under Form-2 ?",
        "name": "SDP23_49",
        "subSectionId":2,
        "subTopicId":8,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organisation generate E- Waste?",
        "name": "SDP23_50",
        "subSectionId":2,
        "subTopicId":8,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organisation collect, handle, store and dispose e- waste in accordance with the EWM rules?",
        "name": "SDP23_51",
        "subSectionId":2,
        "subTopicId":8,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization file annual returns in Form-3 SPCB/CPCB ?",
        "name": "SDP23_52",
        "subSectionId":2,
        "subTopicId":8,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Bio-Medical Waste",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization generates Bio-Medical Waste?",
        "name": "SDP23_53",
        "subSectionId":2,
        "subTopicId":9,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, then is the organization authorised under Bio-Medical Waste Management(BMWM) Rules, 2016?",
        "name": "SDP23_54",
        "subSectionId":2,
        "subTopicId":9,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "attachment": true,
        
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization collect, handle, store and dispose bio-medical waste in accordance with the BMWM rules?",
        "name": "SDP23_55",
        "subSectionId":2,
        "subTopicId":9,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization follow protocols of bio-medical waste handling?",
        "name": "SDP23_56",
        "subSectionId":2,
        "subTopicId":9,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization submit an annual report to the prescribed authority  as specified under Form-4 by June 30th every year?",
        "name": "SDP23_57",
        "subSectionId":2,
        "subTopicId":9,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Plastic Waste",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization generates Plastic Waste?",
        "name": "SDP23_58",
        "subSectionId":2,
        "subTopicId":10,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, then is the organization authorised under Plastic Waste Management(PWM) Rules, 2022?",
        "name": "SDP23_59",
        "subSectionId":2,
        "subTopicId":10,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "attachment": true,
        
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization collect, handle, store and dispose plastic waste in accordance with the PWM rules?",
        "name": "SDP23_60",
        "subSectionId":2,
        "subTopicId":10,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization achieved Zero Single Use Plastic status?",
        "name": "SDP23_61",
        "subSectionId":2,
        "subTopicId":10,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Battery Waste",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization generates Battery Waste?",
        "name": "SDP23_62",
        "subSectionId":2,
        "subTopicId":11,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, then is the organization authorised under Battery Waste Management(BWM) Rules, 2022?",
          
        "name": "SDP23_63",
        "subSectionId":2,
        "subTopicId":11,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "mandatory": 1,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization collect, handle, store and dispose battery waste in accordance with the BWM rules?",
        "name": "SDP23_64",
        "subSectionId":2,
        "subTopicId":11,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization file annual battery returns in Form 3?",
        "name": "SDP23_65",
        "subSectionId":2,
        "subTopicId":11,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Zero Waste to Landfill",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization achieved Zero Waste to Landfill status from a competent authority?",
        "name": "SDP23_66",
        "subSectionId":2,
        "subTopicId":12,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Waste Management Playbook",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have implemented activities/programs/kaizens from Waste Stewardship Playbook?",
        "name": "SDP23_67",
        "subSectionId":2,
        "subTopicId":13,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, please mention the activities/programs/kaizens which have been implemented by the organization?",
        "name": "SDP23_68",
        "subSectionId":2,
        "subTopicId":13,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Waste Intensity",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the waste intensity of the organization?",
        "name": "SDP23_69",
        "subSectionId":2,
        "subTopicId":14,
        "values": [
            {
                "label": "10",
                "value": "1",
                "selected": false
            },
            {
                "label": "20",
                "value": "2",
                "selected": false
            },
            {
                "label": "30",
                "value": "3",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any waste intensity reduction plan?",
        "name": "SDP23_70",
        "subSectionId":2,
        "subTopicId":14,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, please meantion the reduction target.",
        "name": "SDP23_71",
        "subSectionId":2,
        "subTopicId":14,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Briefly describe the organization's reuse and recycle projects.",
        "name": "SDP23_72",
        "subSectionId":2,
        "subTopicId":14,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization received any show cause notice in terms of waste management from State Pollution Control Board ?",
        "name": "SDP23_73",
        "subSectionId":2,
        "subTopicId":14,
        "values": [
            {
                "label": "Yes",
                "value": "-1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "attachment": true,
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "ENERGY",
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Energy Monitoring",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization monitor and track electricity consumption?",
        "name": "SDP23_74",
        "subSectionId":3,
        "subTopicId":15,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "attachment": true,
        
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then provide the following:\nTotal electricity consumption in KWh from April 2023- March 2024",
        "name": "SDP23_75",
        "subSectionId":3,
        "subTopicId":15,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "Does the organization consume electricity generated from any renewable source ?",
        "name": "SDP23_76",
        "subSectionId":3,
        "subTopicId":15,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Renewable Energy",
        "expanded": false
    },
   
    {
        "type": "select",
        "label": "If yes, please provide the following-\nPercentage of electricity from renewable sources",
        "name": "SDP23_77",
        "subSectionId":3,
        "subTopicId":16,
        "values": [
            {
                "label": "20",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "60",
                "value": "3",
                "selected": false
            },
            {
                "label": "80",
                "value": "4"
            },
            {
                "label": "100",
                "value": "5"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Energy Excellence Playbook",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have implemented activities/programs/kaizens from Energy Excellence Playbook?",
        "name": "SDP23_78",
        "subSectionId":3,
        "subTopicId":17,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention the activities/programs/kaizens which have been implemented by the organization?",
        "name": "SDP23_79",
        "subSectionId":3,
        "subTopicId":17,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization map its Scope 1 emissions?",
        "name": "SDP23_80",
        "subSectionId":3,
        "subTopicId":17,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide your total Scope 1 emissions of FY 2023-24",
        "name": "SDP23_81",
        "subTopicId":17,
        "subSectionId":3,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization map its Scope 2 emissions?",
        "name": "SDP23_82",
        "subSectionId":3,
        "subTopicId":17,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide your total Scope 2 emissions of FY 2023-24",
        "name": "SDP23_83",
        "subSectionId":3,
        "subTopicId":17,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Carbon Foot print Scope 1 & Scope 2 Reduction Plan",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any Scope 1 & 2 emission reduction plan?",
        "name": "SDP23_84",
        "subSectionId":3,
        "subTopicId":18,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Scope 3: Mapping ",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization map its Scope 3 emissions?",
        "name": "SDP23_85",
        "subSectionId":3,
        "subTopicId":19,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide your total Scope 3 emissions of FY 2023-24",
        "name": "SDP23_87",
        "subSectionId":3,
        "subTopicId":19,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Scope 3: Reduction Plan ",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any Scope 3 emission reduction plan?",
        "name": "SDP23_88",
        "subSectionId":3,
        "subTopicId":20,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Projects towards EP 100",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization a member of the RE-100/EP-100 initiative?",
        "name": "SDP23_89",
        "subSectionId":3,
        "subTopicId":21,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention organization's baseline year for measuring energy productivity.",
        "name": "SDP23_90",
        "subSectionId":3,
        "subTopicId":21,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention organization's EP100 goals/targets.",
        "name": "SDP23_91",
        "subSectionId":3,
        "subTopicId":21,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "Briefly describe your progress on EP100 goals so far.",
        "name": "SDP23_92",
        "subSectionId":3,
        "subTopicId":21,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization certified under ISO 50001 standard?",
        "name": "SDP23_93",
        "subSectionId":3,
        "subTopicId":21,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization received any Green Buildiung certification ?",
        "name": "SDP23_94",
        "subSectionId":3,
        "subTopicId":21,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention the name of the certification.",
        "name": "SDP23_95",
        "subSectionId":3,
        "subTopicId":21,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "PRODUCT STEWARDSHIP",
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Lifecycle Assessments",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct Life Cycle Assessments for any of its products?",
        "name": "SDP23_96",
        "subSectionId":4,
        "subTopicId":22,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, briefly describe your LCA approach and progress made so far.",
        "name": "SDP23_97",
        "subSectionId":4,
        "subTopicId":22,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Product Stewardship Playbook",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have implemented activities/programs/kaizens from Product Stewardship Playbook?",
        "name": "SDP23_98",
        "subSectionId":4,
        "subTopicId":23,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Recyclability",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any recycling process in place for waste generated?",
        "name": "SDP23_99",
        "subSectionId":4,
        "subTopicId":24,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, briefly describe your recycling process.",
        "name": "SDP23_100",
        "subSectionId":4,
        "subTopicId":24,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of recyclable material being used in the organization?",
        "name": "SDP23_101",
        "subSectionId":4,
        "subTopicId":24,
        "values": [
            {
                "label": "<85",
                "value": "0",
                "selected": false
            },
            {
                "label": ">85",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization use any recycled input material in final products?",
        "name": "SDP23_102",
        "subSectionId":4,
        "subTopicId":24,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention the percentage of recycled input material being used.",
        "name": "SDP23_103",
        "subSectionId":4,
        "subTopicId":24,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any plans to improve percentage of recycled input material in final products?",
        "name": "SDP23_104",
        "subSectionId":4,
        "subTopicId":24,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention the plans to improve percentage of recycled input material in final product",
        "name": "SDP23_105",
        "subSectionId":4,
        "subTopicId":24,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Recoverability",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any recoverability process in place for waste generated?",
        "name": "SDP23_106",
        "subSectionId":4,
        "subTopicId":25,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, briefly describe your recoverability process.",
        "name": "SDP23_107",
        "subSectionId":4,
        "subTopicId":25,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of recoverable material being used in the organization?",
        "name": "SDP23_108",
        "subSectionId":4,
        "subTopicId":25,
        "values": [
            {
                "label": "<90",
                "value": "0",
                "selected": false
            },
            {
                "label": ">90",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Zero Hazardous Waste ",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization RoHS (Restriction of Hazardous Substances) compliant?",
        "name": "SDP23_109",
        "subSectionId":4,
        "subTopicId":26,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization REACH (Registration, Evaluation, Authorisation and Restriction of Chemicals) compliant?",
        "name": "SDP23_110",
        "subSectionId":4,
        "subTopicId":26,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization achieved Zero Hazardous Material being used status?",
        "name": "SDP23_111",
        "subSectionId":4,
        "subTopicId":26,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "attachment": true,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If no, please provide the names of all hazardous materials used.",
        "name": "SDP23_112",
        "subSectionId":4,
        "subTopicId":26,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Meet Line & Lot Rejection",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Line & lot rejection <100 ppm (Refer last 3 month TVSM's supplier performance report)",
        "name": "SDP23_113",
        "subSectionId":4,
        "subTopicId":27,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Based upon the Supplier Performance Report, what is the organization's plan to achieve Zero lot rejection?",
        "name": "SDP23_114",
        "subSectionId":4,
        "subTopicId":27,
        "values": [
            {
                "label": "0",
                "value": "0",
                "selected": false
            },
            {
                "label": "1",
                "value": "0.5",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Based upon the Supplier Performance Report, what is the organization's plan to achieve <100 ppm line rejection?",
        "name": "SDP23_115",
        "subSectionId":4,
        "subTopicId":27,
        "values": [
            {
                "label": "0",
                "value": "1",
                "selected": false
            },
            {
                "label": "1",
                "value": "0.5",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Use of Alternate Materials ",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization use any alternate materials to reduce depency on critical minerals(like antimony, gallium, tungsten) in production?",
        "name": "SDP23_116",
        "subSectionId":4,
        "subTopicId":28,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention the alternate materials being used.",
        "name": "SDP23_117",
        "subSectionId":4,
        "subTopicId":28,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Alternate to Plastic in Packaging",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization use any alternate(other than plastic) packaging materials?",
        "name": "SDP23_118",
        "subSectionId":4,
        "subTopicId":29,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention the alternate materials for packaging being used.",
        "name": "SDP23_119",
        "subSectionId":4,
        "subTopicId":29,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Eco-Labelling",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization Eco labelling certified by BIS/ Eco Label certification from CII?",
        "name": "SDP23_120",
        "subSectionId":4,
        "subTopicId":30,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false,
        "attachment": true,
    },
    {
        "type": "paragraph",
        "label": "Product Recalls",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization faced any incident of product recall in the past?",
        "name": "SDP23_121",
        "subSectionId":4,
        "subTopicId":31,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, briefly describe the reasons for the same.",
        "name": "SDP23_122",
        "subSectionId":4,
        "subTopicId":31,
        "required": false,
        "value": null,
        "expanded": false,
        "mandatory": 2,

    },
    {
        "type": "paragraph",
        "label": "Supplier Sustainability Ambassadorship Framework",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct basic sustainability trainings for the employees?",
        "name": "SDP23_123",
        "subSectionId":7,"subTopicId":34,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, which of the following topics are covered under the sustainability trainings:\nWater",
        "name": "SDP23_124",
        "subSectionId":7,"subTopicId":34,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Energy",
        "name": "SDP23_125",
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Waste",
        "name": "SDP23_126",
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Road Safety",
        "name": "SDP23_127",
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Biodiversity",
        "name": "SDP23_128",
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Water",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of employees covered under Water related sustainability trainings?",
        "name": "SDP23_129",
        "subSectionId":5,"subTopicId":32,
        "values": [
            {
                "label": "30",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "50",
                "value": "3"
            },
            {
                "label": "60",
                "value": "4"
            },
            {
                "label": "70",
                "value": "5"
            },
            {
                "label": "80",
                "value": "6"
            },
            {
                "label": "90",
                "value": "7"
            },
            {
                "label": "100",
                "value": "8"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization identify any employee as an Ambassador for Water?",
        "name": "SDP23_130",
        "subSectionId":5,"subTopicId":32,
        "values": [
            {
                "label": "Yes",
                "value": "2",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Energy",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of employees covered under Energy related sustainability trainings?",
        "name": "SDP23_131",
        "subSectionId":6,"subTopicId":33,
        "values": [
            {
                "label": "30",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "50",
                "value": "3",
                "selected": false
            },
            {
                "label": "60",
                "value": "4"
            },
            {
                "label": "70",
                "value": "5"
            },
            {
                "label": "80",
                "value": "6"
            },
            {
                "label": "90",
                "value": "7"
            },
            {
                "label": "100",
                "value": "8"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization identify any employee as an Ambassador for Energy?",
        "name": "SDP23_132",
        "subSectionId":6,"subTopicId":33,
        "values": [
            {
                "label": "Yes",
                "value": "2",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Waste",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of employees covered under Waste related sustainability trainings?",
        "name": "SDP23_133",
        "subSectionId":8,"subTopicId":35,
        "values": [
            {
                "label": "30",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "50",
                "value": "3",
                "selected": false
            },
            {
                "label": "60",
                "value": "4"
            },
            {
                "label": "70",
                "value": "5"
            },
            {
                "label": "80",
                "value": "6"
            },
            {
                "label": "90",
                "value": "7"
            },
            {
                "label": "100",
                "value": "8"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization identify any employee as an Ambassador for Waste?",
        "name": "SDP23_134",
        "subSectionId":8,"subTopicId":35,
        "values": [
            {
                "label": "Yes",
                "value": "2",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Road Safety",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of employees covered under Road Safety related sustainability trainings?",
        "name": "SDP23_135",
        "subSectionId":9,"subTopicId":36,
        "values": [
            {
                "label": "30",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "50",
                "value": "3",
                "selected": false
            },
            {
                "label": "60",
                "value": "4"
            },
            {
                "label": "70",
                "value": "5"
            },
            {
                "label": "80",
                "value": "6"
            },
            {
                "label": "90",
                "value": "7"
            },
            {
                "label": "100",
                "value": "8"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization identify any employee as an Ambassador for Road Safety?",
        "name": "SDP23_136",
        "subSectionId":9,"subTopicId":36,
        "values": [
            {
                "label": "Yes",
                "value": "2",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Biodiversity",
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the percentage of employees covered under Biodiversity related sustainability trainings?",
        "name": "SDP23_137",
        "subSectionId":10,"subTopicId":37,
        "values": [
            {
                "label": "30",
                "value": "1",
                "selected": false
            },
            {
                "label": "40",
                "value": "2",
                "selected": false
            },
            {
                "label": "50",
                "value": "3",
                "selected": false
            },
            {
                "label": "60",
                "value": "4"
            },
            {
                "label": "70",
                "value": "5"
            },
            {
                "label": "80",
                "value": "6"
            },
            {
                "label": "90",
                "value": "7"
            },
            {
                "label": "100",
                "value": "8"
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization identify any employee as an Ambassador for Biodiversity?",
        "name": "SDP23_138",
        "subSectionId":10,"subTopicId":37,
        "values": [
            {
                "label": "Yes",
                "value": "2",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the total percentage of employees covered under sustainability trainings?",
        "name": "SDP23_139",
        "subSectionId":10,"subTopicId":37,
        "values": [
            {
                "label": "0",
                "value": "1",
                "selected": false
            },
            {
                "label": "20",
                "value": "2",
                "selected": false
            },
            {
                "label": "40",
                "value": "3",
                "selected": false
            },
            {
                "label": "60",
                "value": "4"
            },
            {
                "label": "80",
                "value": "5"
            },
            {
                "label": "100",
                "value": "10"
            }
        ],
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization monetise any benefits  thorugh action learning from implementation of Playbook ideas using ambassodorship trainings?",
        "name": "SDP23_140",
        "subSectionId":10,"subTopicId":37,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, briefly describe the monetary benefits to the organsation from the ambassadorship trainings ?",
        "name": "SDP23_141",
        "subSectionId":10,"subTopicId":37,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Supplier Social Stewardship Framework",
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Child Labour",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization employ any person below 14 years of age ?",
        "name": "SDP23_142",
        "subSectionId":11,"subTopicId":38,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization employ any person between 14-18 years of age  in the scheduled(The Factory Act, 1948) hazardous occupations and processes ?",
        "name": "SDP23_143",
        "subSectionId":11,"subTopicId":38,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization display prohibition of child labour at main gate entrance?",
        "name": "SDP23_144",
        "subSectionId":11,"subTopicId":38,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does child labour policy available ?",
        "name": "SDP23_145",
        "subSectionId":11,"subTopicId":38,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have effective control measure on preventing child labour and young wotrk force remidiation plan",
        "name": "SDP23_146",
        "subSectionId":11,"subTopicId":38,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Disciplinary Practices",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does organization treat all personnel with dignity & respect and not engage or tolerate the use of corporal punishment, mental or physical coercion or verbal abuse of personnel?",
        "name": "SDP23_147",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1 ",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a grievance redressal mechanism?",
        "name": "SDP23_148",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organsation have a Prevention of Sexual Harrassment (PoSH) Policy?",
        "name": "SDP23_149",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have an Internal Complaints Committee (ICC) in place?",
        "name": "SDP23_150",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does agreement of external member established ?",
        "name": "SDP23_151",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does organization cover PoSH worksop all its work force ?",
        "name": "SDP23_152",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does PoSH grivance redressal procedures established in notice board, with the list of  Internal Complaints Committee with their contact numbers ?",
        "name": "SDP23_153",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Appointment letter of Presiding Officer",
        "name": "SDP23_154",
        "subSectionId":12,"subTopicId":39,
        "values": [
            {
                "label": "Yes",
                "value": "0.20",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Discrimination",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does organization engage in or support discrimination in hiring, remuneration, access to training, promotion, termination or retirement based on race, national or territorial or social origin, caste, birth, religion, disability, gender, sexual orientation, family responsibilities, marital status, union membership, political opinions, age or any other condition that could give rise to discrimination?",
        "name": "SDP23_155",
        "subSectionId":13,"subTopicId":40,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a policy on Discrimination?",
        "name": "SDP23_156",
        "subSectionId":13,"subTopicId":40,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is there any wage disparity in any form ",
        "name": "SDP23_157",
        "subSectionId":13,"subTopicId":40,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Freedom of Association",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization allow all personnel to form, join and organise trade union(s) of their choice and to bargain collectively on their behalf with the organization?",
        "name": "SDP23_158",
        "subSectionId":14,"subTopicId":41,
        "values": [
            {
                "label": "Option-1",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a Code of Conduct in place?",
        "name": "SDP23_159",
        "subSectionId":14,"subTopicId":41,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a works committee in place ? (If Org. has more than 100 employees)",
        "name": "SDP23_160",
        "subSectionId":14,"subTopicId":41,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Forced or Compulsary Labour",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is there a evidence of work or service that a person has not offered to do voluntarily and is made to do under the threat of punishment or retaliation, or is demanded as a means of repayment of debt?",
        "name": "SDP23_161",
        "subSectionId":15,"subTopicId":42,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does appointment orders provided in local laungauge ",
        "name": "SDP23_162",
        "subSectionId":15,"subTopicId":42,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have dormitory facilities for Girls / Boys inside the factory ? \nIf so establish license to operate and Fire license ",
        "name": "SDP23_163",
        "subSectionId":15,"subTopicId":42,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Remuneration",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization ensure and respect the right of personnel to a living wage and ensure that wages for a normal work week, not including overtime, shall always meet at least legal or industry minimum standards, or collective bargaining agreements?",
        "name": "SDP23_164",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.30",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does over time hours is adhered as per factory's act (Per day / month / quarter )",
        "name": "SDP23_165",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "N0",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does minimum wages as per the state is provided ?",
        "name": "SDP23_166",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does appropriate pay slip provided to all employees ?",
        "name": "SDP23_167",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "For engaing more than 20 contract work force does facility obtained contractor License with appropriatre numbers and validity from Government",
        "name": "SDP23_168",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "Na",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does facility obtained registration of contract for contractors covering all types of contract work force engaged ",
        "name": "SDP23_169",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does ESI and PF paid on time to appropriate organization ",
        "name": "SDP23_170",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does contract work force paid annual leave with wages ?",
        "name": "SDP23_171",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does over time paid double as per the factory's act ?",
        "name": "SDP23_172",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does Bonus has been paid for eligible work force (including contract work force)",
        "name": "SDP23_173",
        "subSectionId":16,"subTopicId":43,
        "values": [
            {
                "label": "Yes",
                "value": "0.15",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Working Hours",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization comply with applicable laws, collective bargaining agreements and industry standards on working hours, breaks and public holidays? (The normal work week, not including overtime, shall be defined by law but shall not exceed 48 hours).",
        "name": "SDP23_174",
        "subSectionId":17,"subTopicId":44,
        "values": [
            {
                "label": "Yes",
                "value": "0.50",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does apprentices engaged in over time if so approval obtained from apprentice advisor",
        "name": "SDP23_175",
        "subSectionId":17,"subTopicId":44,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0.50",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is there any evidence of continuous working more than 10 days is observed ",
        "name": "SDP23_176",
        "subSectionId":17,"subTopicId":44,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0.50",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does maternity benefit is paid and extended as per the act ? Also is there a policy for availaing the benefits ? ",
        "name": "SDP23_177",
        "subSectionId":17,"subTopicId":44,
        "values": [
            {
                "label": "Yes",
                "value": "0.50",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Appointment of Safety and Welfare officer as per the act ",
        "name": "SDP23_178",
        "subSectionId":17,"subTopicId":44,
        "values": [
            {
                "label": "Yes",
                "value": "0.50",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization Social Accountability 8000 certified?",
        "name": "SDP23_179",
        "subSectionId":17,"subTopicId":44,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Supplier Safety Framework",
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Machine Guarding & Controls (Health & Safety)",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are there basic controls like 2 hand push button, physical gaurds available for machines & equipment where human intervention is required?",
        "name": "SDP23_180",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, please mention the percentage of machines which are equiped with basic controls like 2 hand push button & physical guards but not effective.",
        "name": "SDP23_181",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": ">50",
                "value": "1",
                "selected": false
            },
            {
                "label": ">75",
                "value": "0",
                "selected": false
            },
            {
                "label": "100",
                "value": "1.5",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are there advanced controls like type 4 photo sensor, area scanner available for machines & equipment where human intervention is required?",
        "name": "SDP23_182",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, please mention the percentage of machines which are equiped with advanced controls like type4 photo sensor, area scanner.",
        "name": "SDP23_183",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "<50%",
                "value": "1",
                "selected": false
            },
            {
                "label": "50-75%",
                "value": "2",
                "selected": false
            },
            {
                "label": "75-100%",
                "value": "1.5",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are there any Local exhaust Ventilation (LEV)  arrangement available for health aspects in welding, machining areas?",
        "name": "SDP23_184",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have power presses & high risk machines?",
        "name": "SDP23_185",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, are these power presses & high risk machines operated only by certified operators?",
        "name": "SDP23_186",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization certified under ISO 45001 standard?",
        "name": "SDP23_187",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has any incident been reported (leading to Lost Time Injuries, Fatality) in the machine due to absence of basic & advanced safety controls ?",
        "name": "SDP23_188",
        "subSectionId":18,"subTopicId":45,
        "values": [
            {
                "label": "Yes",
                "value": "-1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Contractor Safety & Permit To Work (Health & Safety)",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have Height work permit system available for all height related activities including fragile roof with appropriate controls?",
        "name": "SDP23_189",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have Confined space work permit system available for cleaning of pit, tanks & other chemical silos or enclosed containers?\n",
        "name": "SDP23_190",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "2",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, which of the following controls are available-\nSelf Contained Breathing Apparatus(SCBA)",
        "name": "SDP23_191",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Supplied air respirator",
        "name": "SDP23_192",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Oxygen level check",
        "name": "SDP23_193",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Ventilation arrangement",
        "name": "SDP23_194",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Resuce plan and team",
        "name": "SDP23_195",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization provide adequate PPEs for workers as per IS standard?",
        "name": "SDP23_196",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization provide basic induction, On The Job  training, Tool Box Talk are provided for contractors before entry and shift starting?",
        "name": "SDP23_197",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct any contractor safety & work permit field audits to monitor the adherence and consequence management?",
        "name": "SDP23_198",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether high risk training & certification is being provided for high critical jobs like roof work, confined space works electrical works (HT & LT )?",
        "name": "SDP23_199",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a reward & recognition system in place for workers based on their efforts and performance on safety  ?",
        "name": "SDP23_200",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether Portable equipment & hand tools are inspected at entry gate at a defined frequency for safety checks?",
        "name": "SDP23_201",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether all hot works are assessed at site before being allowed to perform through Hot work permit?",
        "name": "SDP23_202",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has any incident been reported in the past due to absence of work permit, training to contractors, inspection of tools & tackles?",
        "name": "SDP23_203",
        "subSectionId":19,"subTopicId":46,
        "values": [
            {
                "label": "Yes",
                "value": "-0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Road Safety (Health & Safety)",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether the trucks are parked with wheel chokes at designated location?",
        "name": "SDP23_204",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Do the drivers having valid document for both self and vehicle is checked by security?",
        "name": "SDP23_205",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are pedestrain crossings available for safely crossing the road inside the permises with adequate VCS for speed limit, dangers on road & signages are provided across plant",
        "name": "SDP23_206",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have systematic checks & enforcement in place for employees adhering to safety helmet /seatbelt while riding vehicles (2W /4W) inside premises?",
        "name": "SDP23_207",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Do the powered material handling equiments are periodically maintained ?",
        "name": "SDP23_208",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether trained & certified operator are used for operating forklifts & powered material handling equiment?",
        "name": "SDP23_209",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is there any general Road Safety training provided to all categories of employees with periodical campaigns to sustain the awareness?",
        "name": "SDP23_210",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether any defensive driver training provided for employees?",
        "name": "SDP23_211",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Whether any campaing or activities conducted on Road Safety Week?",
        "name": "SDP23_212",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has any incident been reported in the past due to any road safety violation like over speeding, sleeping under trucks, drink & drive?",
        "name": "SDP23_213",
        "subSectionId":20,"subTopicId":47,
        "values": [
            {
                "label": "Yes",
                "value": "-0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Electrical, Fire Safety & Emergency Preparedness (Health & Safety)",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Do the basic controls like electrical controls, Lockout/Tagout available with Electrical Line clearance permit adherence ?",
        "name": "SDP23_214",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have adequate and working conditioned fire extinguishers available?",
        "name": "SDP23_215",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have clear access for exits, staircases & electrical panels available?",
        "name": "SDP23_216",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Has the organization trained adequate number of people (>10% of employees) in first aid fire fighting using extinguishers?",
        "name": "SDP23_217",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is there a dedicated trained team available for operating fire hydrants?",
        "name": "SDP23_218",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are the flammable & combustible material isolated from spark producing activity?",
        "name": "SDP23_219",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are flammable gases in cylinders, containers stored in accordance with applicable laws?",
        "name": "SDP23_220",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are early warning systems like smoke/heat detectors and fire hydrant system for fire protection are installed in critical areas?",
        "name": "SDP23_221",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are mockdrills conducted perdiodically?",
        "name": "SDP23_222",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.5",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are Earth Leakage Circuit Breakers(ELCB) & Residual Current Circuit Breakers(RCCB) tested on routine basis and gaps are closed by site Maintenance team?",
        "name": "SDP23_223",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "N o",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "If yes, are the gaps found in testing closed by site maintainence team?",
        "name": "SDP23_224",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "1",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "-1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have preventive measures like flameproof light fittings, static charge dissipation arrangements available in hazardous areas?",
        "name": "SDP23_225",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },

    {
        "type": "select",
        "label": "Does the organisation follow 5S system properly?",
        "name": "SDP23_225",
        "subSectionId":21,"subTopicId":48,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 2,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Legal Compliances",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have obtained a licence under The Factory Act, 1948?",
        "name": "SDP23_226",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.292",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide a copy of the licence with following details-\nRegistration Number\nNumber of Workmen permitted\nValid up to",
        "name": "SDP23_227",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "In case the licence is under the renewal process, please provide the application number for the same",
        "name": "SDP23_228",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have any hazardous process listed in the first schedule of The Factory act, 1948?",
        "name": "SDP23_229",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please state the hazardous process listed in the first schedule of The Factory Act, 1948?",
        "name": "SDP23_230",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have a Site Appraisal Committee approval available?",
        "name": "SDP23_231",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Do the machines(which have the potential for fatal injury) have been provided with safe fencing, guarding and other presence sensing device to prevent worker from getting injured or avoiding trapping of body parts?",
        "name": "SDP23_232",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Do the Power presses(which have the potential for fatal injury) are incorporated with safety features ?",
        "name": "SDP23_233",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": " If yes, does the organization has a test report from a competent person (latest)?",
        "name": "SDP23_234",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false,
        "placeholder": ""
    },
    {
        "type": "select",
        "label": "Do the hoists and lifts(which have the potential for fatal injury) used for lifting & lowering material or men, are periodically tested by competent person (Form 36) ?",
        "name": "SDP23_235",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then does the organization has displayed the Safe Working Load at the site?",
        "name": "SDP23_236",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory have any pedestal grinding, buffing, linishing, cutting machine?",
        "name": "SDP23_237",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then does the peripherel speed of the machine, in RPM (Revolutions Per Minute), is mentioned?",
        "name": "SDP23_238",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any Pressure vessels?",
        "name": "SDP23_239",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then does the pressure vessels has safety relieve valve, drain valve and pressure guages?",
        "name": "SDP23_240",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are these pressure vessels tested at periodic intervels as per Form-8 (Examination of Pressure Vessel) ?",
        "name": "SDP23_241",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any activity that involves lifting, carrying or moving by hand or on head handle, where weight is more than 50 Kgs/ 30kgs by man / women respectively unaided by other persons?",
        "name": "SDP23_242",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any process or activity that involves flying of hazardous particles from welding,cuttiing, drilling, grinding etc.? ",
        "name": "SDP23_243",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then does the organization provide suitable goggles as per IS standard ?",
        "name": "SDP23_244",
        "subSectionId":22,"subTopicId":49,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any confined space? Ex : Any vessel with manhole, sewage pits, sludge pits etc. where oxygen level is atleast 19% as per Occupational Safety and Health Administration",
        "name": "SDP23_245",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization take suitable control measures to ensure safety of the person entering the confined space where level of oxygen is either below 19% during normal condition or during course of any work?",
        "name": "SDP23_246",
        "subSectionId":22,"subTopicId":49,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Fire Protection",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have adequate fire exits available without any obstruction and clear with Visual Cut-off Switch?",
        "name": "SDP23_247",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have portable first aid fire extinguishers adequately available? ",
        "name": "SDP23_248",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are fire drills conducted periodically?",
        "name": "SDP23_249",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then what is the frequency of the fire drills ? Please provide the latest drill report",
        "name": "SDP23_250",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have fire hydrants available?",
        "name": "SDP23_251",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide an evidence of the working condition of fire pumps & fire hydrants",
        "name": "SDP23_252",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have safety belts for people working at height?",
        "name": "SDP23_253",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any fragile roof ?",
        "name": "SDP23_254",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, what are the systems put in place by the organization to control the risk for person working above it? ",
        "name": "SDP23_255",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Do the workers engaged in hazardous process are medically examined by a qualified medical practitioner?",
        "name": "SDP23_256",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide Forms 39(Record of Eye Examination) and Form 17(Notice of dangerous occurrence which does not result in death or bodily injury)",
        "name": "SDP23_257",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have ovens available?",
        "name": "SDP23_258",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, the what are the safety features available with ovens ?",
        "name": "SDP23_259",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have crane operators available?",
        "name": "SDP23_260",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide latest eye examination report of the operators.",
        "name": "SDP23_261",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have forklift operators available?",
        "name": "SDP23_262",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide latest eye examination report of the operators.",
        "name": "SDP23_263",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have safety committee members available?",
        "name": "SDP23_264",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, how many members are available?",
        "name": "SDP23_265",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "What is the meeting frequency of the safety committee? Please provide the latest minutes of the meeting document ",
        "name": "SDP23_266",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "What are the PPE's used and mention the IS standard to which it complies?",
        "name": "SDP23_267",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have thermic fluid heater available?",
        "name": "SDP23_268",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide the  latest competent person test certificate for heater coil and thermic fluid.",
        "name": "SDP23_269",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have Material Safety Data Sheet of all chemicals available?",
        "name": "SDP23_270",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide the list of all chemicals used.",
        "name": "SDP23_271",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any high noise areas?",
        "name": "SDP23_272",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide the frequency of audiometry examination report for such employees working in high noise areas?",
        "name": "SDP23_273",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are there any reportable accidents at the factory unit in recent years?",
        "name": "SDP23_274",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide Form-18 (TNFR) or Form 17(KFR) or Form-18(HP) for employees covered under ESI(Respective State’s Factories Rules may be referred for the concerned forms.",
        "name": "SDP23_275",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the factory unit have any licensed gas cylinder storage available?",
        "name": "SDP23_276",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "mandatory": 1,
        "required": true,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide the latest copy of the license. ",
        "name": "SDP23_277",
        "required": false,
        "value": null,
        "expanded": false,
        "mandatory": 1,
    },
    {
        "type": "select",
        "label": "Does the organization store any petroleum products?",
        "name": "SDP23_278",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, does the quantity is greater than the following limits-\nClass A- 30 litres\nClass B- 2500 litres\nClass C- 4500 litres",
        "name": "SDP23_279",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false,
        "mandatory": 1,
    },
    {
        "type": "textarea",
        "label": "If yes, is there a license for such storage from Petroleum Explosives Safety Organization?  Produce a copy of the valid license.",
        "name": "SDP23_280",
        "required": false,
        "value": null,
        "expanded": false,
        "mandatory": 1,
    },
    {
        "type": "select",
        "label": "Does the organization has any emergency preparedness plan (onsite) ?",
        "name": "SDP23_281",
        "subSectionId":23,"subTopicId":50,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes whether it is approved by Director of factories?",
        "name": "SDP23_282",
        "subSectionId":23,"subTopicId":50,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Electrical Installations",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are all the electrical installation work including addition, repairs & replacement of appliances carried out by an electrical licensed person by state government and under the supervision of person holding competency certificate?",
        "name": "SDP23_283",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "NO",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the periodical installation and testing of equipment’s carried out at regular intervals by the electrical inspector?",
        "name": "SDP23_284",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": ")",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are the defects pointed out during such audit closed within the stipulated time through submission of rectification report?",
        "name": "SDP23_285",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then does the rectification report is acknowledged by the electrical inspector?",
        "name": "SDP23_286",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have appointed a Safety Officer with Directorate of Industrial Safety and Health(DISH) approval?",
        "name": "SDP23_287",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide the following- \nName of the Safety Officer:\nContact Number of the Safety Officer:\nApproval from competent authority:\n",
        "name": "SDP23_288",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have appointed a Welfare Officer with Directorate of Industrial Safety and Health(DISH) approval?",
        "name": "SDP23_289",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please provide the following-\nName of the Welfare Officer:\nContact Number of the Welfare Officer:\nApproval from competent authority:\n",
        "name": "SDP23_290",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a Building Stability certificate?",
        "name": "SDP23_291",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide the following:\nDate of the Document:\nValid up to:",
        "name": "SDP23_292",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct pre-employment medical check ups?",
        "name": "SDP23_293",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide pre-employment medical check up forms (5 samples in each category)",
        "name": "SDP23_294",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct medical check ups in hazardous areas?",
        "name": "SDP23_295",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide medical check up report in hazardous areas, for last 1 year",
        "name": "SDP23_296",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a canteen committee?",
        "name": "SDP23_297",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide the following-\nNumber of employees in the canteen committee:\nLast meeting date:\nLast meeting's Minutes of Meeting:\nAttendence for last 1 year:",
        "name": "SDP23_298",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a Group Personal Accident(GPA) Policy?",
        "name": "SDP23_299",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide the following-\nGPA policy number:\nValid upto:",
        "name": "SDP23_300",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a Mediclaim Policy?",
        "name": "SDP23_301",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide the following-\nMediclaim policy number:\nValid upto:",
        "name": "SDP23_302",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a Workmen Compensation(WC) Policy?",
        "name": "SDP23_303",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.107",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please provide the following-\nNumber of workmen covered under WC:\nNumber of workmen covered under ESI\nValid upto:",
        "name": "SDP23_304",
        "subSectionId":24,"subTopicId":51,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct scheduled legal reviews of all its compliances?",
        "name": "SDP23_305",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have an online system for legal compliance tracking?",
        "name": "SDP23_306",
        "subSectionId":24,"subTopicId":51,
        "values": [
            {
                "label": "Yes",
                "value": "0.25",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "paragraph",
        "label": "Supplier Governance Framework",
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company have a formal policy covering human rights and labour practices? Please provide a copy for the same",
        "name": "SDP23_307",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company organise training for your employees on your human rights and labour policy?",
        "name": "SDP23_308",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your site have a governance system in place to manage human rights and working conditions issues?",
        "name": "SDP23_309",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the facility have any EHS certifications like ISO 45001?",
        "name": "SDP23_310",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company have a formal written EHS (Environmental, Health & Safety) policy in place? Please provide a copy for the same",
        "name": "SDP23_311",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are all employees trained on relevant health and safety matters?",
        "name": "SDP23_312",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company have a formal Business Code of Conduct? Please provide a copy of the same",
        "name": "SDP23_313",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company organise training for your employees on your business ethics policy?",
        "name": "SDP23_314",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company have a supplier code of conduct? Please provide a copy for the same",
        "name": "SDP23_315",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company have set sustainability requirements towards its suppliers?",
        "name": "SDP23_316",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the facility have any Environmental certifications, like ISO 14001, RC 14001, EMAS?",
        "name": "SDP23_317",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then please mention the same",
        "name": "SDP23_318",
        "subSectionId":25,"subTopicId":52,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company have a formal environmental policy in place?",
        "name": "SDP23_319",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Are all employees trained on relevant environmental matters?",
        "name": "SDP23_320",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company map Greenhouse Gas emissions?",
        "name": "SDP23_321",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then provide your Scope 1 and scope 2 emissions",
        "name": "SDP23_322",
        "subSectionId":25,"subTopicId":52,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your company set Greenhouse Gas reduction targets?",
        "name": "SDP23_323",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, then mention the same",
        "name": "SDP23_324",
        "subSectionId":25,"subTopicId":52,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is your plant situated near a biodiversity  hotspot/ protected areas ?",
        "name": "SDP23_325",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a formal biodiversity policy in place?",
        "name": "SDP23_326",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, does the organization have a governance structure and a system in place to follow the policy?",
        "name": "SDP23_327",
        "subSectionId":25,"subTopicId":52,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization have a long term biodiversity management plan?",
        "name": "SDP23_328",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Is the organization aware that its operations/ products may lead to an impact on biodiversity?",
        "name": "SDP23_329",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does the organization conduct biodiversity impact assessments at operation sites and surrounding area?",
        "name": "SDP23_330",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your organization have a process for identifying, assessing, and managing risks?",
        "name": "SDP23_331",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention top 5 risks identified by the organization",
        "name": "SDP23_332",
        "subSectionId":25,"subTopicId":52,
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "select",
        "label": "Does your organization have a process for identifying, assessing, and managing opportunities?",
        "name": "SDP23_333",
        "subSectionId":25,"subTopicId":52,
        "values": [
            {
                "label": "Yes",
                "value": "0.227",
                "selected": false
            },
            {
                "label": "No",
                "value": "0",
                "selected": false
            },
            {
                "label": "NA",
                "value": "1",
                "selected": false
            }
        ],
        "required": false,
        "value": null,
        "expanded": false
    },
    {
        "type": "textarea",
        "label": "If yes, please mention top 5 opportunities identified by the organization",
        "name": "SDP23_334",
        "subSectionId":25,"subTopicId":52,
        "required": false,
        "value": null,
        "expanded": true
    }
]
   export default questionary.filter(i => i.type !== 'paragraph')
   
