import React, { useEffect, useState, useRef } from "react";
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { ContextMenu } from "primereact/contextmenu";
import EdiText from 'react-editext'


const RoleConfig = () => {

    const [applicationlist, setApplicationList] = useState([])
    const [rolelist, setRoleList] = useState([])
    const [certificatelevellist, setCertificateLevelList] = useState([])
    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.admindetail);
    const [newcertificatemodal, setNewCertificateModal] = useState(false)
    const [newcertificateobj, setNewCertificationObj] = useState({ title: '' })
    const [newroleobj, setNewRoleObj] = useState({ title: '' })
    const [newcertificatelevelobj, setNewCertificationLevelObj] = useState({ title: '' })
    const [submitted, setSubmitted] = useState(false)
    const [selected, setSelected] = useState({ application: null })
    const certlevel = useRef([]);
    const certauthority = useRef([]);

    useEffect(() => {
        APIServices.get(API.Application).then(res => setApplicationList(res.data))
    }, [])

 
    const standardOptionTemplate = (rowData) => {
        return (
            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                <div className="col-10">
                    {rowData.title}
                </div>

            
            </div>
        )
    }
    const getTierData = (val) => {
        console.log(val)
        setSelected(prev => ({ ...prev, application: val }))
        setNewRoleObj({ title: '' })

     
        APIServices.get(API.Role_Application(val)).then(res => { console.log(res.data, 'b'); setRoleList(res.data) })
    }
    const showRoleCM = (e, ind) => {

        certauthority.current.forEach((i, j) => {
            if (j === ind) {
                certauthority.current[ind].show(e)
            } else {
                if (certauthority.current[j] !== null) {
                    certauthority.current[j].hide(e)
                }
            }
        })
      


    }
   
    const setEditModeAuthority = (cyindex) => {
        rolelist.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }

    const setEditModeCertAuthority = (cyindex) => {
        rolelist.map((i, j) => { i.edit = false })

        forceUpdate()
    }

 
    const addNewRole = () => {
        if (newroleobj.title.trim().length !== 0) {
            if (newroleobj.id !== undefined) {
                let obj = { ...newroleobj }
                APIServices.patch(API.Role_Edit(newroleobj.id), obj
                ).then((a) => {
                    let loc = JSON.parse(JSON.stringify(rolelist))
                    let index = loc.findIndex(x => x.id === newroleobj.id);
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...obj }
                    }
                    setRoleList(loc)

                    console.log(a)
                })
            } else {
                let obj = { ...newroleobj, created_on: DateTime.utc(), created_by: selector.id }
                APIServices.post(API.Role_Application(selected.application), obj).then((a) => {
                    let loc = JSON.parse(JSON.stringify(rolelist))

                    loc.push(obj)
                    setRoleList(loc)

                    console.log(a)
                })
            }

        }
    }
    const updateRole = (e, id) => {
        console.log(e)
        let index = rolelist.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch(API.Role_Edit(rolelist[index].id),
                {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = rolelist
                loc[index].title = e.trim();
                setRoleList(loc);
                forceUpdate()
            });
        }
    };
  
    return (
        <div className="col-12 font-lato">
            {selector.role === 'eisqradmin' ?
                <div>
                       <h3 className="clr-navy"> Role Configuration </h3>
                    <div className="col-12 grid align-items-center justify-content-between">
                        <div className="col-10 grid align-items-center">
                            <label className="col-2 ">
                                Select Application 
                            </label>
                            <div className="col-5">
                                <Dropdown options={applicationlist} style={{ width: '100%' }} itemTemplate={standardOptionTemplate} filter optionLabel="title" optionValue="id" value={selected.application} onChange={(e) => { getTierData(e.value) }} />
                                {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                            </div>
                            
                        </div>

                    </div>
                    {selected.application !== null && <div className="grid flex justify-content-between">
                        <div className="col-6">
                            <div className="grid">
                                <div className="col-11">
                                    <InputText
                                        type={"text"}
                                        style={{ width: '100%' }}
                                        value={newroleobj.title}
                                        onChange={(e) => {
                                            setNewRoleObj(prev => ({ ...prev, title: e.target.value }))
                                        }}
                                        placeholder="Enter Role"
                                    />
                                </div>
                                <div className="col-1" >
                                    <Button
                                        icon="pi pi-plus"

                                        style={{ cursor: newroleobj.title.trim().length === 0 ? 'not-allowed' : 'pointer', background: newroleobj.title.trim().length === 0 ? 'gray' : 'rgb(0, 82, 132)', border: newroleobj.title.trim().length === 0 ? '1px solid gray' : '1px solid rgb(0, 82, 132)' }}
                                        className="p-button-success mr-2"
                                        onClick={() => {
                                            addNewRole();
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-12"
                                style={{
                                    border: "1px solid grey",
                                    height: "calc(100vh - 290px)",
                                    overflow: "auto",
                                    borderRadius: 5
                                }}
                            >
                                {rolelist.length === 0 ? (
                                    <text
                                        style={{
                                            padding: 5,
                                        }}
                                    >
                                        {"Add Role"}
                                    </text>
                                )
                                    :
                                    (rolelist.map((item, cyindex) => {
                                        return (
                                            <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                <div className="col-11"

                                                    style={{

                                                        borderBottom: "0.5px ridge grey", padding: 0
                                                    }}
                                                >
                                                    <EdiText
                                                        viewContainerClassName={selected.authority === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}
                                                        type='text'
                                                        value={item.title}
                                                        onSave={(e) => { updateRole(e, item.id); setEditModeCertAuthority() }}
                                                        editing={item.edit}
                                                        onCancel={() => { setEditModeCertAuthority() }}
                                                        hideIcons={true}
                                                        validationMessage="Title should not exist / not empty "
                                                        validation={val => { console.log(val); return val.trim().length !== 0 && rolelist.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                        saveButtonContent="✔"
                                                        cancelOnUnfocus
                                                        cancelButtonContent={<strong>X</strong>}
                                                        editButtonClassName="custom-edit-button"
                                                    />

                                                </div>
                                                <ContextMenu model={[
                                                    {
                                                        label: 'Edit',
                                                        icon: 'pi pi-fw pi-pencil',
                                                        command: () => { setEditModeAuthority(cyindex) }
                                                    },

                                                ]} ref={(r) => { certauthority.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                <div className="col-1" onContextMenu={(e) => { showRoleCM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                    <i className="material-icons" >settings</i>
                                                </div>
                                            </div>

                                        )
                                    }))
                                }
                            </div>

                        </div>
                        <div className="col-6">


                        </div>
                    </div>}
                </div>
: (
    <div className="col-12 card">You have no rights to access this page </div>
)

            }

        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RoleConfig, comparisonFn);
