import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const data = [
  {
    year: "2019",
    Male_LessThan30: 20,
    Male_30To50: 15,
    Male_Above50: 10,
    Female_LessThan30: 18,
    Female_30To50: 12,
    Female_Above50: 8,
  },
  {
    year: "2020",
    Male_LessThan30: 22,
    Male_30To50: 18,
    Male_Above50: 12,
    Female_LessThan30: 20,
    Female_30To50: 15,
    Female_Above50: 10,
  },
  {
    year: "2021",
    Male_LessThan30: 18,
    Male_30To50: 20,
    Male_Above50: 15,
    Female_LessThan30: 16,
    Female_30To50: 18,
    Female_Above50: 13,
  },
  {
    year: "2022",
    Male_LessThan30: 25,
    Male_30To50: 22,
    Male_Above50: 20,
    Female_LessThan30: 23,
    Female_30To50: 18,
    Female_Above50: 15,
  },
  {
    year: "2023",
    Male_LessThan30: 28,
    Male_30To50: 25,
    Male_Above50: 18,
    Female_LessThan30: 26,
    Female_30To50: 22,
    Female_Above50: 16,
  },
];

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "16px" }}>
            {entry.value === "Male_LessThan30"
              ? "Male Less than 30"
              : entry.value === "Male_30To50"
              ? "Male 30 to 50"
              : entry.value === "Male_Above50"
              ? "Male Above 50"
              : entry.value === "Female_LessThan30"
              ? "Female Less than 30"
              : entry.value === "Female_30To50"
              ? "Female 30 to 50"
              : "Female Above 50"}
          </span>
        </li>
      ))}
    </ul>
  );
};

const SocialChart5 = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Number of Employees by Age Group and Gender{" "}
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              stackOffset="sign"
              barSize={40}
            >
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideLeft",
                  offset: 0,
                  style: { textAnchor: "middle", fill: "#555" },
                }}
              />
              <Tooltip />
              <Legend content={<CustomLegend />} />
              <Bar dataKey="Male_LessThan30" stackId="a" fill="#BDD1F7" />
              <Bar dataKey="Male_30To50" stackId="a" fill="#739CEB" />
              <Bar dataKey="Male_Above50" stackId="a" fill="#4A6FB3" />
              <Bar dataKey="Female_LessThan30" stackId="b" fill="#FF9D7C" />
              <Bar dataKey="Female_30To50" stackId="b" fill="#FF6A4A" />
              <Bar dataKey="Female_Above50" stackId="b" fill="#CC4A33" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column header="Year" style={{ minWidth: "8%" }} field="year" />
            <Column
              header="Male Less than 30"
              style={{ minWidth: "8%" }}
              field="Male_LessThan30"
            />
            <Column
              header="Male 30 to 50"
              style={{ minWidth: "8%" }}
              field="Male_30To50"
            />
            <Column
              header="Male Above 50"
              style={{ minWidth: "8%" }}
              field="Male_Above50"
            />
            <Column
              header="Female Less than 30"
              style={{ minWidth: "8%" }}
              field="Female_LessThan30"
            />
            <Column
              header="Female 30 to 50"
              style={{ minWidth: "8%" }}
              field="Female_30To50"
            />
            <Column
              header="Female Above 50"
              style={{ minWidth: "8%" }}
              field="Female_Above50"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default SocialChart5;
