import React, { useState, useEffect } from "react";
import APIServices from "../../../../service/APIService";
import { API } from "../../../../constants/api_url";
import { TabMenu } from "primereact/tabmenu";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import { Calendar } from 'primereact/calendar';
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { MultiSelect } from "primereact/multiselect";



const SAPRawDataDisplay = () => {

    const [activeindex, setActiveIndex] = useState(0);
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)
    const [filter, setFilter] = useState({ dateRange: [null, null] })
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const forceUpdate = useForceUpdate()
    useEffect(() => {
        const now = DateTime.local();
        const startDate = now.startOf('month').toFormat('ddMMyyyy');
        const endDate = now.endOf('month').toFormat('ddMMyyyy');
        setFilter((prev) => ({ ...prev, dateRange: [now.startOf('month').toJSDate(), now.endOf('month').toJSDate()] }))
        setLoad(true)
        APIServices.post(API.SAPFuel, { startDate, endDate }).then(res => {
            setLoad(false)
            if (res.data.result) {
                setData(res.data.data)
            }
        }).catch((e)=>{
            setLoad(false)
        })


    }, [])
    const getDate = (rowData) => {
        if (DateTime.fromFormat(rowData.Date, 'yyyyMdd').isValid) {
            return DateTime.fromFormat(rowData.Date, 'yyyyMdd').toFormat('dd-MM-yyyy')
        }
        return 'Invalid Date'

    }
    const updateDate = (val) => {
        if (val && val.filter(i => i).length === 2) {
            const startDate = DateTime.fromJSDate(val[0]).toFormat('ddMMyyyy')
            const endDate = DateTime.fromJSDate(val[1]).toFormat('ddMMyyyy')
            if (activeindex !== null && tabList[activeindex].endpoint) {
                setLoad(true)
                APIServices.post(tabList[activeindex].endpoint, { startDate, endDate }).then(res => {
                    setLoad(false)
                    if (res.data.result) {
                        setData(res.data.data)
                        forceUpdate()
                    }else{
                        setData([])
                    }
                }).catch((e)=>{
                    setLoad(false)
                })
            }else{
                setData([])
            }
        }else{
            setData([])
        }
        setFilter((prev) => ({ ...prev, dateRange: val }))
    }
    const updateDataByTab = (val) => {
        if (filter.dateRange && filter.dateRange.filter(i => i).length === 2) {
            const startDate = DateTime.fromJSDate(filter.dateRange[0]).toFormat('ddMMyyyy')
            const endDate = DateTime.fromJSDate(filter.dateRange[1]).toFormat('ddMMyyyy')
            if (tabList[val].endpoint) {
                setLoad(true)
                APIServices.post(tabList[val].endpoint, { startDate, endDate }).then(res => {
                    setLoad(false)
                    if (res.data.result) {
                        setData(res.data.data)
                        forceUpdate()
                    }else{
                        setData([])
                    }
                }).catch((e)=>{
                    setLoad(false)
                })
            }else{
                setData([])
            }
        }else{
            setData([])
        }
        setActiveIndex(val)
    }
    const locationFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.Location)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const fuelTypeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.DPA0131)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const hazardTypeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.DPAN0041)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const nonHazardTypeFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(data))
        let locationOptions = Array.from(new Set(allentity.map(i => i.DPAN0042)))
        console.log(locationOptions)
        return (
            <MultiSelect
                panelClassName={'hidefilter'}
                filter
                value={options.value}
                options={locationOptions}
                onChange={(e) => options.filterCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const tabList = [
        { label: 'Fuel Data', icon: 'pi pi-receipt', endpoint: API.SAPFuel,className: 'white-space-nowrap'},
        { label: 'Waste (Hazardous)', icon: 'pi pi-receipt', endpoint: API.SAPHazard ,className: 'white-space-nowrap'},
        { label: 'Waste (Non-Hazardous)', icon: 'pi pi-receipt', endpoint: API.SAPNonHazard ,className: 'white-space-nowrap'},
        { label: 'Scope 3_Category 1: Purchased Goods and Services', icon: 'pi pi-receipt',className: 'white-space-nowrap' },
        { label: 'Scope 3_Category 2: Capital Goods', icon: 'pi pi-receipt',className: 'white-space-nowrap' },
        { label: 'Scope 3_Category 6: Business Travel', icon: 'pi pi-receipt',className: 'white-space-nowrap' },
        { label: 'Scope 3_Category 9: Downstream Transportation and Distribution', icon: 'pi pi-receipt' ,className: 'white-space-nowrap'},
        { label: 'Safety - Injuries', icon: 'pi pi-receipt' ,className: 'white-space-nowrap'},
        { label: 'Safety - Risk Score', icon: 'pi pi-receipt',className: 'white-space-nowrap' },
        { label: 'Employee Demograhics', icon: 'pi pi-receipt',className: 'white-space-nowrap' },
        { label: 'Employee Training', icon: 'pi pi-receipt',className: 'white-space-nowrap' }

    ]
    return (
        <div>
            <div className="col-12 flex align-items-center" >
                <span className="text-big-one"> Hello  {login_data?.role === 'clientadmin' ? login_data?.information?.companyname : login_data?.information?.empname} ! </span>   <span className="ml-1">{`<${login_data.email}>`} </span>
            </div>

            <div
                className="flex col-12 flex-start"
                style={{ flexDirection: "column" }}
            >
                <span className="text-big-one">
                    SAP Raw Data Repository
                </span>

            </div>
            <div className="col-12">
                <div className="field">
                    <label className="flex">From - To </label>
                    <Calendar placeholder="From - To" value={filter.dateRange} style={{ width: 185 }} onChange={(e) => updateDate(e.value)} selectionMode="range" hideOnRangeSelection dateFormat="dd/mm/yy" />
                </div>
            </div>
            <div className="col-12">
                <TabMenu model={tabList}  onTabChange={(e) => { updateDataByTab(e.index) }} activeIndex={activeindex} />
                {activeindex === 0 &&
                    <div>
                        <DataTable loading={load} value={data} filters={{ Location: { value: null, matchMode: 'in' }, DPA0131: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' header={'Date'} body={getDate} />
                            <Column header='Location' field='Location' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='DPA0131' header={'Fuel Type'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={fuelTypeFilterTemplate} />
                            <Column field='DPA0336' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.DPA0336)).toFixed(4)}</> }} />
                            <Column field='DPA0132' header={'UOM'} />
                        </DataTable>
                    </div>

                }
                {activeindex === 1 &&
                    <div>
                        <DataTable loading={load} value={data} filters={{ Location: { value: null, matchMode: 'in' }, DPAN0041: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' header={'Date'} body={getDate} />
                            <Column header='Location' field='Location' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='DPAN0041' header={'Material Description'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={hazardTypeFilterTemplate} />
                            <Column field='DPAN0500' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.DPAN0500)).toFixed(4)}</> }} />
                            <Column field='UOM' header={'UOM'} />
                        </DataTable>
                    </div>

                }
                {activeindex === 2 &&
                    <div>
                        <DataTable loading={load} value={data} filters={{ Location: { value: null, matchMode: 'in' }, DPAN0042: { value: null, matchMode: 'in' } }} scrollable showGridlines paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='No records found' >
                            <Column field='Date' header={'Date'} body={getDate} />
                            <Column header='Location' field='Location' filter showFilterMatchModes={false} showApplyButton={true} filterElement={locationFilterTemplate} />
                            <Column field='DPAN0042' header={'Material Description'} filter showFilterMatchModes={false} showApplyButton={true} filterElement={nonHazardTypeFilterTemplate} />
                            <Column field='DPAN0043' header={'Quantity'} body={(rowData) => { return <>{(parseFloat(rowData.DPAN0043)).toFixed(4)}</> }} />
                            <Column field='UOM' header={'UOM'} />
                        </DataTable>
                    </div>

                }
                {[3, 4, 5, 6].includes(activeindex) &&
                    <div className="col-12 flex justify-content-center font-italic clr-gray-200
                   ">Data received from S3, its under review </div>}
                {[7, 8, 9, 10, 11].includes(activeindex) &&
                    <div className="col-12 flex justify-content-center font-italic clr-gray-200
                   ">Data has to be received </div>}
            </div>


        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SAPRawDataDisplay, comparisonFn);