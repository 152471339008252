import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const WaterConsumption = () => {
  const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    // Add more months...
  ];
  const years = [
    { label: '2024', value: 2024 },
    { label: '2023', value: 2023 },
    // Add more years...
  ];

  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [selectedYear, setSelectedYear] = useState(years[0]);

  const data = [
    { name: 'Surface water', value: 40 },
    { name: 'Groundwater', value: 20 },
    { name: 'Seawater', value: 20 },
    { name: 'Produced water', value: 12 },
    { name: 'Third-party water', value: 8 }
  ];

  const COLORS = ['#27475E', '#3A6584', '#4A84AD', '#76A5C7', '#AACBE3'];


  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color, display: 'flex', alignItems: 'center', marginRight: 20 }}>
            <span style={{ height: 10, width: 10, backgroundColor: entry.color, borderRadius: '50%', display: 'inline-block', marginRight: 5 }}></span>
            {entry.value}: <strong style={{ marginLeft: 5 }}>{data[index].value}</strong>
          </li>
        ))}
      </ul>
    );
  };

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  return (
    <Card>
      <p className='card-title'>Water Consumption</p>
      <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>
      <Dropdown value={selectedMonth} className="me-3" options={months} onChange={(e) => setSelectedMonth(e.value)} placeholder="Select a Month" />
      <Dropdown value={selectedYear} options={years} onChange={(e) => setSelectedYear(e.value)} placeholder="Select a Year" />

      <ResponsiveContainer width="100%" height={300}>
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
          
            outerRadius={120}
            fill="#8884d8"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default WaterConsumption;
