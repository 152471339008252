import React, { useEffect, useState, useRef, createRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import FormBuilder from "../../components/FormBuilder";
import { MultiSelect } from 'primereact/multiselect';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";

import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $ from 'jquery'
import axios from "axios";
import IATACodes from "../../assets/dummy/IATACodes";
import { AutoComplete as AC } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";
import { API } from "../../constants/api_url";
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";

import cx from "classnames";

import { render } from "react-dom";
import Axios from "axios";
import { hardcoded } from "../../client/constants/hardcodedid";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";
import { Tooltip } from 'primereact/tooltip'
const google = window.google;

window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");
// (0.79/1000)

const DealerFormLibrary = () => {
    let dtref = useRef(null)
    const admin_data = useSelector((state) => state.user.admindetail)
    const login_data = useSelector((state) => state.user.userdetail)
    // const hardcodeddcf = [10, 11, 36, 15, 16]
    
    const forceUpdate = useForceUpdate()
    const his = useHistory()
    const [formfilter, setFormFilter] = useState('')
    const [formbk, setFormbk] = useState([])
    const [selectedform, setSelectedForm] = useState({ title: '', tags: [] })
    const [prevdialog, setPrevDialog] = useState(false);
    const [items, setItems] = useState([])
    const [searchstr, setSearch] = useState('')
    const [clienttag, setClientTag] = useState([])
    const [adddialog, setAddDialog] = useState(false)
    const [title, setTitle] = useState('')
    const [catlist, setCatList] = useState([])
    const [toplist, setTopList] = useState([])
    const [form, setForm] = useState([])
    const [submitted, setSubmitted] = useState(false)
    useEffect(() => {
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": ['newDataPoints'] } }] } }]


        }

        APIServices.get(API.DealerForm_Up(admin_data.id)).then((res) => {
            setForm(res.data);
            setFormbk(res.data)

        })

    }, []);

    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{option.title}</span>
            </div>
        );
    };
    const categoryFilterTemplate = (options) => {
        console.log(options.value)
        return <MultiSelect value={options.value} options={catlist} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="title" optionValue="title" placeholder="Category" className="p-column-filter" />;
    };
    const topicFilterTemplate = (options) => {
        console.log(toplist)
        return <MultiSelect value={options.value} options={toplist} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="title" optionValue="title" placeholder="Category" className="p-column-filter" />;
    };

    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }
    const renderPreview = () => {


        if (prevdialog && selectedform.data1.length !== 0) {

            let data = selectedform.data1


            return data.map((field, ind) => {
                if (field.type === 'paragraph') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">

                            <p>{removeHTMLTag(field.label)}</p>
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'date' + ind}>{removeHTMLTag(field.label)}</label>
                            <Calendar disabled showIcon ></Calendar>
                        </div>
                    )
                } else if (field.type === 'text') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'text' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'textarea') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textarea' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputTextarea disabled></InputTextarea>
                        </div>
                    )
                } else if (field.type === 'number') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textno' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText keyfilter={'num'} disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'select') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'select' + ind}>{removeHTMLTag(field.label)}</label>
                            <Dropdown options={field.values} ></Dropdown>
                        </div>
                    )
                } else if (field.type === 'radio-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'radio' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.label}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>
                    )
                } else if (field.type === 'checkbox-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'checkbox' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.value}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>

                    )
                }
            })
        }
    }
    const checkDPStatus = (data) => {
        if (data === null || data === undefined) {
            return { result: false, data: '0/0' }
        } else if (data.length === 0) {
            return { result: false, data: '0/0' }
        } else if (data.length !== 0) {
            let total = 0, count = 0

            data.forEach((item) => {

                if (item.type !== 'paragraph' && item.type !== 'file' && item.type !== 'date') {
                    total = total + 1
                    if (item.name.startsWith('DP')) {
                        count = count + 1
                    }

                }
            })
            return { result: true, data: count + '/' + total }
        } else {
            return { result: false, data: '0/0' }
        }
    }
    const nameTemplate = (rowData) => {
        console.log(rowData.id)
        return (
            <>
                <span > {rowData.title}</span>
            </>
        )
    }

    const dpidStatus = (rowData) => {

        return (
            <>
                <Tooltip target={".csf" + rowData.id}>
                    {
                        (!rowData.tags || !rowData.tags.length) ?
                            ''
                            :
                            clienttag.filter(i => rowData.tags.includes(i.id)).map(i => <>{i.name}  <br />  </>)

                    }
                </Tooltip>


                <span className={(!rowData.tags || !rowData.tags.length) ? 'status-tag-gray' : 'status-tag-navy csf' + rowData.id}  > {(!rowData.tags || !rowData.tags.length) ? 'Default' : 'CSF'}</span>
            </>
        )
    }
    const formIDTemplate = (rowData) => {
        return (
            <>
                <span> DCF{rowData.id}</span>
            </>
        )
    }
    const editDCF = (item) => {
        his.push({ pathname: '/edit_dealer_form', state: item })
    }
    const cloneDCF = async (item) => {
        let newObj = JSON.parse(JSON.stringify(item))
        newObj.title = item.title + ' _Cloned'
        delete newObj.updated
        delete newObj.id
        newObj.data2 = []
        delete newObj.modifier_id

        newObj.comments = ''
        newObj.suffix = 'DCF'
        newObj.created = DateTime.utc()
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to clone "${item.title}" Form
  </div>`,

            confirmButtonColor: 'green',
            showCancelButton: true,

            confirmButtonText:
                'Clone',

        })
        if (accept) {

        }
    }
    const exportDCF = (data, fileName) => {

        const ws = XLSX.utils.aoa_to_sheet([]);
        let rowIndex = 0;
        let merges = [];

        // Add headers
        const headers = ["Label", "Options"];
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: rowIndex++ });

        // Add data rows
        data.forEach(item => {
            const baseRow = [item.label.replace(/(<([^>]+)>)/gi, "")
                .replace(/\n/g, " ")
                .replace(/&nbsp;/g, " ").replace('&amp;', '&'), ''];

            if (item.values && item.values.length > 0) {
                item.values.forEach((val, valIndex) => {
                    const optionRow = baseRow.slice();
                    optionRow[1] = val.label;
                    XLSX.utils.sheet_add_aoa(ws, [optionRow], { origin: rowIndex++ });
                });

                // Merge the Type and Label cells
                merges.push({ s: { r: rowIndex - item.values.length, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
                merges.push({ s: { r: rowIndex - item.values.length, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
                merges.push({ s: { r: rowIndex - item.values.length, c: 2 }, e: { r: rowIndex - 1, c: 2 } });

            } else {
                XLSX.utils.sheet_add_aoa(ws, [baseRow], { origin: rowIndex++ });
            }
        });

        // Apply merges to the worksheet
        ws['!merges'] = merges;

        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Write workbook and save to file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        FileSaver.saveAs(dataBlob, DateTime.utc().toLocal().toFormat('dd_LLL_yyyy_') + fileName);
    }
    const search = (event) => {
        setSearch(event)
        let _items = formbk.filter((k) => { return (k.title.trim().toLowerCase().includes(event.trim().toLowerCase()) || k.id.toString().includes(event.trim()) ) })
        setForm(_items);
    }
    const deleteDCF = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.title}" from Dealer Library
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 10,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'vadapooche') {
            APIServices.delete(API.DealerForm_Edit(item.id)).then((a) => {
                let loc = form;
                loc.splice(form.findIndex((k) => { return k.id === item.id }), 1);
                setFormbk(loc)
                setForm(loc)
                forceUpdate()
            })
        }

    }
    const categoryTemplate = (rowData) => {
        return (
            <>
                <span> {rowData.cattop ? rowData.cattop.category : ''}</span>
            </>
        )
    }
    const topicTemplate = (rowData) => {
        return (
            <>
                <span> {rowData.cattop ? rowData.cattop.topic : ''}</span>
            </>
        )
    }
    const actionBodyTemplate = (rowData) => {

        return (

            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                    onClick={() => {
                        editDCF(rowData)
                    }}
                />
 
                <Button
                    icon="pi pi-trash"
                    className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                    onClick={() => {
                        deleteDCF(rowData)
                    }}
                />
            </div>

        )
    }
    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );
    const submitFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddDialog(false);
                }}
            />
            <Button
                label="Save & Exit"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    if (selectedform.title.trim().length !== 0) {
                        addNewDCF()
                    }
                }}
            />
        </>
    );
    const addNewDCF = () => {
        APIServices.post(API.DealerForm_Up(admin_data.id), { title: selectedform.title.trim(), data1: [], created_by: login_data.id, comments: "", created_on: DateTime.utc() }).then((res) => {
            let loc = JSON.parse(JSON.stringify(form))
            loc.unshift(res.data)

            setAddDialog(false)
            setForm(loc)
            setFormbk(loc)
            forceUpdate()

        })
    }
    const exportExcel = async () => {
        let newObj = {}, json = []
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Enter Password to Export DCF Library to Excel
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 10,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'getdcfnow') {
            formbk.forEach((item) => {
                newObj = {}
                newObj['Id'] = item.id
                newObj['Title'] = item.title
                newObj['Type'] = (hardcoded.dcf.includes(item.id.toString()) || hardcoded.dcf2.includes(item.id.toString())) ? 'Automated' : 'Manual'
                newObj['DataPoints'] = item.data1.map(i => i.type !== 'paragraph' && i.name).join(',')
                newObj['Total_No_of_Quantitative_Fields'] = item.data1.filter(i => i.type === 'number').length
                newObj['Tag Type'] = (!item.tags || !item.tags.length) ? 'Default' : 'CSF'
                newObj['Tag'] = (!item.tags || !item.tags.length) ? '' : clienttag.filter(i => item.tags.includes(i.id)).map(i => i.name).join(', ')
                json.push(newObj)
            })
            const ws = XLSX.utils.json_to_sheet(json)
            let sheet_name = 'DCF Listing_' + DateTime.utc().toLocal().toFormat('dd_LL_yyyy') + '_'
            const wb = {
                Sheets: { ['DCF Listing']: ws },
                SheetNames: ['DCF Listing'],
            };

            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

            const data = new Blob([excelBuffer], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            FileSaver.saveAs(data, sheet_name + ".xlsx");
        }
    }
    return (
        <div className="grid p-fluid">
            
                <div className="col-12 md:col-12">
                    <div className="card ">
                        <div
                            className="col-12 md:col-12"
                            style={{
                                textDecoration: "underline",
                                textAlign: "center",
                                textDecorationColor: "green",
                            }}
                        >
                            <h5>Dealer Form Library</h5>

                        </div>
                        <div className="flex grid col-12 justify-content-between" style={{ marginBottom: 20, marginTop: 20 }}>
                            <div style={{ width: 400 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search form title/id" value={searchstr} onChange={(e) => { search(e.target.value) }} />
                                </span>
                            </div>
                            <div>
                              
                                <Button
                                    icon="pi pi-plus"
                                    className="mr-2 "
                                    onClick={() => {
                                        setSelectedForm({ title: '', tags: [] })
                                        setAddDialog(true)
                                    }}
                                ></Button>
                            </div>
                        </div>
                        <DataTable

                            ref={dtref}
                            value={form.sort((a, b) => { return b.id - a.id }, 0)}
                            scrollable
                            paginator
                            rows={20}

                            rowsPerPageOptions={[10, 20, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} form"

                            emptyMessage="No Form found."

                            responsiveLayout="scroll"
                        >


                            <Column
                                field="title"
                                header="Form Name"
                                body={nameTemplate}
                                sortable
                                headerStyle={{
                                    width: "40%",
                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>





                            <Column
                                field="created"
                                header="Created On"
                                body={(rowData) => { return (<>{DateTime.fromISO(rowData.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')}</>) }}
                                headerStyle={{
                                    background: '#EEF2FF'
                                }}
                            >
                            </Column>
                            <Column
                                field="updated"
                                header="Last Modified"
                                body={(rowData) => { return (<>{DateTime.fromISO(rowData.modified_on ? rowData.modified_on   : rowData.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')}</>) }}
                                headerStyle={{
                                    background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column header="Action" headerStyle={{
                                background: '#EEF2FF', width: '15%'
                            }} body={actionBodyTemplate}> </Column>
                        </DataTable>
                        <Dialog
                            visible={adddialog}
                            header={'Add New Form'}
                            modal
                            style={{ width: '60%' }}
                            footer={submitFooter}
                            onHide={() => {
                                setAddDialog(false);
                            }}
                        >
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label className="m-2" htmlFor="username">Form Title</label>
                                    <InputText id="username" className="m-2" aria-describedby="username-help" value={selectedform.title} onChange={(e) => { setSelectedForm((prev) => ({ ...prev, title: e.target.value })) }} />
                                    <small id="username-help" className="m-2" style={{ color: 'red' }}>
                                        {selectedform.title.trim().length === 0 && 'please enter title '}
                                    </small>
                                </div>
                               
                            </div>

                        </Dialog>
                    </div>
                </div>

           

            }
            <Dialog
                visible={prevdialog}
                style={{
                    width: "450px",
                }}
                header={selectedform.title + " preview"}
                modal
                className="p-fluid"
                footer={prevDialogFooter}
                onHide={() => { setPrevDialog(false) }}
            >
                {renderPreview()}
            </Dialog>
        </div>)
}
export default DealerFormLibrary