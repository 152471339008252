import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton'
import { useDropzone } from 'react-dropzone';


import { Badge } from 'primereact/badge';
import { Message } from 'primereact/message';
import moment from "moment";
import useForceUpdate from "use-force-update";
import { DateTime } from 'luxon';

import { API } from '../../constants/api_url';
import APIServices from '../../service/APIService';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { error } from 'jquery';
import { useSelector } from 'react-redux';


const getID = () => {
    return parseInt(Date.now() * Math.random()) + moment().unix() + randomIntFromInterval(1, 1000)

}

const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const Certification = ({ }) => {


    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState([])
    const [certificates, setCertificates] = useState([]);
    const [levels, setLevels] = useState([]);
    const [authorities, setAuthorities] = useState([]);
    const [rawsitelist, setRawSitelist] = useState([]);
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const selector = useSelector(state => state.user.userdetail)

    const forceUpdate = useForceUpdate()
    const [certificate, setCertificate] = useState({
        certificateId: null,
        certAuthorityId: null,
        scopeOfCertrificate: '',
        status: null,
        certLevelId: null,
        issuedDate: null,
        validity: null,
        remark: '',
        expectedDate: null,
        comments: '',
        attachment: [],
        applicability: { country: 0, city: 0, location: 0 },

    });


    useEffect(() => {
        const uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };

        const promise0 = APIServices.get(
            API.LocationOne_UP(selector.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise1 = APIServices.get(API.Client_Certification_UP(selector.clientId))
        const promise2 = APIServices.get(API.Certifcation + '/?filter=%7B%22include%22%3A%5B%22certificationLevels%22,%22certIssueAuthorities%22%5D%20%7D')

        Promise.all([
            promise0,
            promise1,
            promise2
        ]).then((values) => {

            const shapedSite = values[0].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSitelist(shapedSite)

            setLocList({ country: shapedSite.map(location => ({ name: location.name, id: location.id })) });
            setRecord(values[1].data);
            setCertificates(values[2].data)
        })

    }, []);
    // Include openDialog in the dependency array












    const handleCertificateChange = (e) => {
        const selectedCertificateId = e.value;
        const selectedCertificate = certificates.find(cert => cert.id === selectedCertificateId);
        setCertificate({ ...certificate, certificateId: selectedCertificateId, certAuthorityId: null, scopeOfCertrificate: '', status: null, certLevelId: null, issuedDate: null, validity: null, remark: '', expectedDate: null, comments: '' });

        // const selectedCert = certificates.find(cert => cert.id === selectedCertificate);

        if (selectedCertificate) {

            if (selectedCertificate.certIssueAuthorities) {
                setAuthorities(selectedCertificate.certIssueAuthorities);
            }
            if (selectedCertificate.certificationLevels) {
                setLevels(selectedCertificate.certificationLevels);
            }
        } else {

            setAuthorities([]);
            setLevels([]);
        }

        forceUpdate();
    };



    const handleAuthorityChange = (e) => {
        const selectedAuthority = e.value;
        setCertificate({ ...certificate, certAuthorityId: selectedAuthority, scopeOfCertrificate: '', status: null, certLevelId: null, issuedDate: null, validity: null, remark: '', expectedDate: null, comments: '' });

    };



    const status = [

        { label: 'Issued', value: 1 },

        { label: 'Under Progress', value: 2 },

    ]


    // const saveData = () => {


    //     setSubmitted(true);
    //     let local = certificate;
    //     let local2 = record;
    //     if (certificate.certificateId !== null && certificate.status !== null && certificate.certAuthorityId !== null) {



    //         if (local.id) {
    //             let index = local2.findIndex((i) => {
    //                 return i.id === local.id;
    //             });
    //             local2[index] = local;
    //         } else {
    //             local.id = getID();
    //             local2.push(local);
    //         }



    //         setCertificate({
    //             certificateId: null,
    //             certAuthorityId: null,
    //             scopeOfCertrificate: '',
    //             status: null,
    //             certLevelId: null,
    //             issuedDate: null,
    //             validity: null,
    //             remark: '',
    //             expectedDate: null,
    //             comments: '',
    //             attachment: null,

    //         });

    //         setRecord(local2)
    //         forceUpdate()
    //         setVisible(false)




    //     }
    //     console.log(local2)
    //     console.log(local);

    // };


    const saveData = () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = certificate;
        let local2 = record;
        if (certificate.certificateId !== null && certificate.status !== null && certificate.certAuthorityId !== null && certificate.scopeOfCertrificate.trim().length !== 0 && (certificate.status === 1 ? (certificate.certLevelId !== null && certificate.issuedDate !== null && certificate.validity !== null && certificate.remark.trim().length !== 0) : (certificate.comments.trim().length !== 0 && certificate.expectedDate !== null))) {

            if (certificate.id === undefined) {
                let newObj = { certificateId: certificate.certificateId, status: certificate.status, certAuthorityId: certificate.certAuthorityId, scopeOfCertrificate: certificate.scopeOfCertrificate, created_on: DateTime.utc(), created_by: selector.id, applicability: certificate.applicability }
                if (certificate.attachment && certificate.attachment.length) {
                    newObj.attachment = certificate.attachment
                }

                if (certificate.status === 1) {
                    newObj.certLevelId = certificate.certLevelId
                    newObj.issuedDate = certificate.issuedDate
                    newObj.validity = certificate.validity
                    newObj.remark = certificate.remark

                    newObj.comments = ''
                    newObj.expectedDate = null

                }
                else {
                    newObj.certLevelId = certificate.certLevelId
                    newObj.issuedDate = certificate.issuedDate
                    newObj.validity = certificate.validity
                    newObj.remark = ''
                    newObj.comments = certificate.comments
                    newObj.expectedDate = certificate.expectedDate

                }
                APIServices.post(API.Client_Certification_UP(selector.id), newObj).then((res) => {

                    local2.push(res.data);

                    setCertificate({
                        certificateId: null,
                        certAuthorityId: null,
                        scopeOfCertrificate: '',
                        status: null,
                        certLevelId: null,
                        issuedDate: null,
                        validity: null,
                        remark: '',
                        expectedDate: null,
                        comments: '',
                        attachment: [],
                        applicability: { country: 0, city: 0, location: 0 },

                    });
                    setRecord(local2)
                    forceUpdate()
                    setVisible(false)
                    setSubmitted(false)
                })
            }
            else {
                let newObj = { certificateId: certificate.certificateId, status: certificate.status, certAuthorityId: certificate.certAuthorityId, scopeOfCertrificate: certificate.scopeOfCertrificate, modified_on: DateTime.utc(), modified_by: selector.id, applicability: certificate.applicability }
                if (certificate.attachment && certificate.attachment.length) {
                    newObj.attachment = certificate.attachment
                }

                newObj.certLevelId = certificate.certLevelId
                newObj.issuedDate = certificate.issuedDate
                newObj.validity = certificate.validity
                newObj.remark = certificate.remark

                newObj.comments = certificate.comments
                newObj.expectedDate = certificate.expectedDate



                delete newObj.created_on
                delete newObj.created_by
                APIServices.patch(API.Client_Certification_Edit(certificate.id), newObj).then((res) => {

                    // let index = local2.findIndex((i) => {
                    //     return i.id === local.id;
                    // });
                    // local2[index] = local;

                    let index = local2.findIndex(i => i.id === certificate.id)
                    if (index !== -1) {
                        local2[index] = { ...local2[index], ...newObj }
                    }

                    setRecord(local2)

                    setVisible(false)
                    setSubmitted(false)


                })
            }

        }
        console.log(certificate)
    };


    const getStatusLabel = (value) => {
        const statusObj = status.find(status => status.value === value);
        return statusObj ? statusObj.label : '';

    };


    const getLevelLabel = (value) => {

        const certIndex = certificates.findIndex(cert => cert.id === value.certificateId);
        let text1 = ''
        if (certIndex !== -1) {
            const levelIndex = certificates[certIndex].certificationLevels.findIndex(cert => cert.id === value.level)
            if (levelIndex !== -1) {
                text1 = certificates[certIndex].certificationLevels[levelIndex].title
            }
        }
        return text1;

        // const levelObj = levels.find(level => level.id === value);
        // return levelObj ? levelObj.title : '';

    };

    const getCertificateLabel = (value) => {
        const certificateObj = certificates.find(cert => cert.id === value);
        return certificateObj ? certificateObj.title : '';
    };

    const getAuthority = (value) => {
        const certIndex = certificates.findIndex(cert => cert.id === value.certificateId);

        let text = ''
        if (certIndex !== -1 && certificates[certIndex].certIssueAuthorities) {
            const authIndex = certificates[certIndex].certIssueAuthorities.findIndex(cert => cert.id === value.certAuthorityId)
            if (authIndex !== -1) {
                text = certificates[certIndex].certIssueAuthorities[authIndex].title
            }

        }
        return text


    };






    const onHide = () => setVisible(false);



    // Template for the 'Status' column
    // const statusBodyTemplate = (rowData) => {
    //     let severity;
    //     switch (rowData.currentStatus) {
    //         case 'Completed':
    //             severity = 'success';
    //             break;
    //         case 'Planned':
    //             severity = 'info';
    //             break;
    //         case 'In-progress':
    //             severity = 'warn';
    //             break;
    //         default:
    //             severity = 'info';
    //             break;
    //     }

    //     return <Message severity={severity} text={rowData.currentStatus} />;
    // };

    const dateFormating = (rowData) => {
        // Parse the date string or JavaScript Date object
        if (typeof rowData === 'string') {
            return DateTime.fromISO(rowData, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')
        } else {
            const luxonDate = DateTime.fromJSDate(rowData); // Assuming rowData.startDate is a JavaScript Date object

            // Format the date to "dd-MM-yyyy" format
            const formattedDate = luxonDate.toLocal().toFormat('dd-MM-yyyy');
            return formattedDate;
        }

    }

    const dateOfIssuance = (rowData) => {
        return (
            <div>
                {rowData.status === 2 ? "N/A" : (dateFormating(rowData.issuedDate) || "N/A")}

            </div>
        )
    }

    const validityTemplate = (rowData) => {
        return (
            <div>

                {rowData.status === 2 ? "N/A" : (dateFormating(rowData.validity) || "N/A")}
            </div>
        )
    }

    const applicabilityTemplate = (rowData) => {
        return (
            <div>

                {getCoverageText(rowData.applicability) || "N/A"}
            </div>
        )
    }
    const expectedDateTemplate = (rowData) => {
        return (
            <div>

                {rowData.status === 1 ? "N/A" : (dateFormating(rowData.expectedDate) || "N/A")}


            </div>
        )
    }

    const statusLevel = (value) => {
        const certIndex = certificates.findIndex(cert => cert.id === value.certificateId);

        let text = ''
        if (certIndex !== -1 && certificates[certIndex].certificationLevels) {
            const authIndex = certificates[certIndex].certificationLevels.findIndex(cert => cert.id === value.certAuthorityId)
            if (authIndex !== -1) {
                text = certificates[certIndex].certificationLevels[authIndex].title
            }

        }
        return text

    }

    const newCertificate = () => {
        setCertificate({
            certificateId: null,
            certAuthorityId: null,
            scopeOfCertrificate: '',
            status: null,
            certLevelId: null,
            issuedDate: null,
            validity: null,
            remark: '',
            expectedDate: null,
            comments: '',
            attachment: [],
            applicability: { country: 0, city: 0, location: 0 },
        });

        let item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
        setVisible(true);
        setSubmitted(false)
    }

    // const updateCertificate = (obj, val) => {
    //     let local = certificate;

    //     local[obj] = val;
    //     console.log(local)
    //     setCertificate(local)
    //     forceUpdate();
    // }

    const updateCertificate = (obj, val) => {
        let local = certificate
        if (obj === 'applicability') {
            let item = val
            let selected_item = { country: 0, city: 0, location: 0 }
            let country_list = [{ name: 'All', id: 0 }]
            let city_list = [{ name: 'All', id: 0 }]
            let location_list = [{ name: 'All', id: 0 }]
            rawsitelist.forEach((country) => {
                country_list.push({ name: country.name, id: country.id })
                if (country.id === item.country || item.country === 0) {
                    if (country.locationTwos) {
                        country.locationTwos.forEach((city) => {
                            city_list.push({ name: city.name, id: city.id })
                            if (city.id === item.city || item.city === 0) {
                                if (city.locationThrees) {
                                    city.locationThrees.forEach((site) => {
                                        location_list.push({ name: site.name, id: site.id })

                                    })
                                }
                            }
                        })

                    }

                }

            })
            setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        } else if (obj === 'status') {
            local.validity = null
            local.expectedDate = null
            local.issuedDate = null;
            local.certLevelId = null;
            local.comments = ''
            local.remark = ''

        } else if (obj === 'issuedDate') {
            local.validity = null
        }


        local[obj] = val

        console.log(local[obj], obj)
        setCertificate((prev) => ({ ...prev, ...local }))
        forceUpdate();
    }



    // Header for the table with a search input
    const renderHeader = () => {
        return (
            <div className="table-header-container">
                <h5>Certification</h5>

                <div className="table-header-actions py-4 d-flex justify-content-between">

                    <Button onClick={() => { newCertificate(); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
                </div>
            </div>
        );
    };


    // Styles for the dropzone
    const dropzoneStyle = {
        border: '2px dashed gray',
        borderRadius: '5px',
        padding: '40px 20px', // Increased padding as requested
        textAlign: 'center',
        cursor: 'pointer'
    };

    // Icon style
    const iconStyle = {
        width: '48px',
        height: '48px',
        color: '#9e9e9e'
    };

    const dialogFooter = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-text" />
            <Button label="Save" icon="pi pi-check"
                onClick={() => {
                    saveData()
                }}
            />
        </div>
    );

    const editVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))

        if (items.status === 1) {

            items.issuedDate = DateTime.fromISO(items.issuedDate, { zone: 'utc' }).toJSDate()
            items.validity = DateTime.fromISO(items.validity, { zone: 'utc' }).toJSDate()

        }
        else {

            items.expectedDate = DateTime.fromISO(items.expectedDate, { zone: 'utc' }).toJSDate()

        }
        const selectedCertificate = certificates.find(cert => cert.id === items.certificateId);


        if (selectedCertificate) {

            if (selectedCertificate.certIssueAuthorities) {
                setAuthorities(selectedCertificate.certIssueAuthorities);
            }
            if (selectedCertificate.certificationLevels) {
                setLevels(selectedCertificate.certificationLevels);
            }
        } else {
            items.certLevelId = null
            items.certAuthorityId = null
            setAuthorities([]);
            setLevels([]);
        }
        let item_ = items.applicability

        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item_.country || item_.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item_.city || item_.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        setLocList(() => ({ 'country': country_list, 'city': city_list, 'location': location_list }))

        setCertificate(items)
        setVisible(true);
        forceUpdate()
    }



    const deleteRow = (item) => {
        APIServices.delete(API.Client_Certification_Edit(item.id))
            .then(() => {
                let loc = record.filter(rec => rec.id !== item.id);
                setRecord(loc);
            })
            .catch(error => {
                console.error('Error deleting record:', error);
            });
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <>


                <div className="actions">
                    {/* <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editVal(rowData)
                        }}
                    /> */}
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    const titleTemplate = (rowData) => {


        return (
            <div className="text-underline clr-navy fw-5 cur-pointer"
                onClick={() => { editVal(rowData) }}>{getCertificateLabel(rowData.certificateId)}
            </div>
        );
    };

    function getFileExtension_(filename) {
        const lastDot = filename.lastIndexOf('.');
        let ext = lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
        let ext_types = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpeg', 'jpg', 'png', 'bmp']
        console.log(ext)
        return ext_types.includes(ext)
    }
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }

    const attachmentTemplate = (file) => {

        return (<div className="flex align-items-center text-three-dot col-10 mt-2">
            {(getFileExtension(file.originalname) === 'pdf') ?
                <img src={require('../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                    <img src={require('../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                    (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                        <img src={require('../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                        (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                            <img src={require('../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} />
                            :
                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
            <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                {file.originalname.slice(13)}
                <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />

            </span>
        </div>)
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept: 'image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx',

        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles[0].size <= 10000000 && getFileExtension_(acceptedFiles[0].name)) {
                let formData = new FormData()
                formData.append('file', acceptedFiles[0]);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    let loc = certificate
                    loc.attachment = res.data.files
                    console.log(res.data)
                    setCertificate(loc)

                })
            }

            // Handle file logic here
        }

    });

    const attachmentBodyTemplate = (rowData) => {
        return (
            (!rowData.attachment || rowData.attachment.length === 0) ?
                <span className="attachment-link">
                    No Attachment
                </span>
                :
                <span className="attachment-link">
                    <a href={API.Docs + rowData.attachment[0].originalname} target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-file-pdf" style={{ 'marginRight': '0.5em', 'color': 'red' }}></i>
                        {rowData.attachment[0].originalname}
                    </a>
                </span>



        );
    };

    const getCoverageText = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        if (rowData.country === 0 && rowData.city  === 0 && rowData.location  === 0) {
            text = 'Corporate'
        } else if (rowData.country !== 0 && rowData.city  === 0 && rowData.location  === 0) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.country)
            console.log(country_index)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.country !==0 && rowData.city !==0 && rowData.location  === 0) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.city })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }
        } else {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.location })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }


     return    text
    }

    return (
        <div className='font-lato'>

            {selector.role === "clientadmin" ? (
                <DataTable
                    value={record}
                    dataKey="id"
                    header={renderHeader()}
                    //   header={edit === 1 && renderHeader()}
                    className="p-datatable-initiatives">
                    <Column header="Applicability" 
                        body={applicabilityTemplate}
                    />

                    <Column
                        field="Certificate"
                        header="Certificate"
                        // body={(rowData) => { return <> {getCertificateLabel(rowData.certificateId)} </> }}
                        body={titleTemplate}
                    />

                    <Column field="authority" header="Authority"
                        body={(rowData) => { return <>{getAuthority(rowData)}</> }}
                    />


                    <Column field="status" header="Status"
                        body={(rowData) => { return <>{getStatusLabel(rowData.status)}</> }}
                    />

                    <Column field="level" header="Certificate Level"
                        // body={(rowData) => { return <>{getLevelLabel(rowData.level)}</> }}
                        body={statusLevel}
                    />

                    <Column field="Date of Issuance" header="Date of Issuance"
                        body={dateOfIssuance} />


                    <Column field="Validity" header="Validity"
                        body={validityTemplate}
                    />

                    <Column field="Expected Date of Achievement" header="Expected Date "
                        body={expectedDateTemplate}
                    />

                    <Column
                        body={attachmentBodyTemplate}
                        header="Attachment" />


                    <Column
                        body={actionBodyTemplate}
                    >

                    </Column>

                </DataTable>
            ) :
                <div className="col-12 card">You have no rights to access this page </div>
            }

            <Dialog header="Add Certification" visible={visible} style={{ width: '50vw' }} footer={dialogFooter} onHide={() => {
                setVisible(false);
                setSubmitted(false);
                // setCertificate({ certificateId: '', certAuthorityId: '' });
                // setAuthorities([]);
            }} modal>
                <div className="p-fluid row p-3">
                    <div className="p-field  col-12 pe-0 ps-0 pb-4">
                        <label htmlFor="category">Select Certification  <span className='mandatory'>*</span></label>
                        {/* <Dropdown id="category" value={certificate.certificateId} options={certificates.map(item => ({label: item.title, value: item.id}))} onChange={(e) => setCertificate({ ...certificate, certificateId: e.value })} placeholder="Select Certificate" /> 
                        */}
                        <Dropdown id="certify" value={certificate.certificateId}
                            options={certificates.map(cert => ({ label: cert.title, value: cert.id }))}

                            onChange={handleCertificateChange}
                            placeholder="Select Certificate" />

                        {submitted && certificate.certificateId === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Certificate
                            </small>
                        )}
                    </div>

                    {certificate.certificateId && (

                        <div className="p-field col-12 pe-0 ps-0 pb-4">
                            <label htmlFor="authority">Select Issuing Authority <span className='mandatory'>*</span></label>
                            <Dropdown id="authority"
                                value={certificate.certAuthorityId}

                                options={authorities.map(authority => ({ label: authority.title, value: authority.id }))}
                                // onChange={(e) => setCertificate({ ...certificate, certAuthorityId: e.value })} 
                                onChange={handleAuthorityChange}

                                placeholder="Select Issuing Authority" />

                            {submitted && certificate.certAuthorityId === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >

                                    Enter Issuing Authority
                                </small>
                            )}
                        </div>


                    )}

                    {certificate.certAuthorityId && ( // Check if an authority is selected
                        <>

                            <div className="p-field col-12 ">
                                <fieldset style={{
                                    padding: '20px',
                                    borderRadius: '10px',
                                    border: '1px solid #ced4da',

                                }}>
                                    <legend>Applicability</legend>
                                    <label>Country</label>
                                    <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={certificate.applicability.country} options={locList.country} optionLabel="name" optionValue="id"
                                        onChange={(e) => { updateCertificate('applicability', { country: e.value, city: 0, location: 0 }); }} placeholder="Select Country"
                                    />
                                    {certificate.applicability.country !== 0 &&
                                        <>  <label>City</label>
                                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={certificate.applicability.city} options={locList.city} optionLabel="name" optionValue="id"
                                                onChange={(e) => { updateCertificate('applicability', { country: certificate.applicability.country, city: e.value, location: 0 }); }} placeholder="Select City"
                                            />
                                        </>
                                    }
                                    {certificate.applicability.country !== 0 && certificate.applicability.city !== 0 && <> <label>Location</label>
                                        <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={certificate.applicability.location} options={locList.location} optionLabel="name" optionValue="id"
                                            onChange={(e) => { updateCertificate('applicability', { country: certificate.applicability.country, city: certificate.applicability.city, location: e.value }); }} placeholder="Select Site"
                                        /></>}
                                </fieldset>
                            </div>

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="description">Scope Of Certificate <span className='mandatory'>*</span></label>
                                <InputTextarea id="description" value={certificate.scopeOfCertrificate}
                                    onChange={(e) => { updateCertificate("scopeOfCertrificate", e.target.value) }} />

                                {submitted && certificate.scopeOfCertrificate.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Scope Of Certificate
                                    </small>
                                )}


                            </div>

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="category">Status <span className='mandatory'>*</span></label>
                                <Dropdown id="category" value={certificate.status} optionValue="value" options={status} onChange={(e) => { updateCertificate("status", e.target.value) }} placeholder="Select  status" />


                                {submitted && certificate.status === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Status
                                    </small>
                                )}

                            </div>

                        </>
                    )}



                    {certificate.status === 1 && (
                        <>
                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="level">Select Certification Level <span className='mandatory'>*</span></label>
                                <Dropdown id="level"
                                    value={certificate.certLevelId}


                                    options={levels.map(level => ({ label: level.title, value: level.id }))}
                                    onChange={(e) => { updateCertificate("certLevelId", e.value) }}

                                    placeholder="Certification Level" />

                                {submitted && certificate.certLevelId === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Certification Level                          </small>
                                )}
                            </div>
                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="DateOfIssuance">Date of Issuance <span className='mandatory'>*</span></label>
                                <Calendar id="issuedDate" value={certificate.issuedDate}
                                    onChange={(e) => { updateCertificate("issuedDate", e.value) }} showIcon />

                                {submitted && certificate.issuedDate === null && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Select Date of Issuance  </small>
                                )}
                            </div>
                            {certificate.issuedDate !== null &&
                                <div className="p-field col-12 pe-0 ps-0 pb-4">
                                    <label htmlFor="completionDate">Validity <span className='mandatory'>*</span></label>
                                    <Calendar id="completionDate" value={certificate.validity}
                                        onChange={(e) => { updateCertificate("validity", e.value) }} minDate={certificate.issuedDate} showIcon />

                                    {submitted && certificate.validity === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Select validity date
                                        </small>
                                    )}
                                </div>}

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="description">Remarks <span className='mandatory'>*</span></label>
                                <InputText id="description" value={certificate.remark} onChange={(e) => setCertificate({ ...certificate, remark: e.target.value })} />

                                {submitted && certificate.remark.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Remarks
                                    </small>
                                )}
                            </div>

                            <div className="p-field  col-12 ">
                                <label htmlFor="attachment" className='flex' >Attachment   <span><i style={{
                                    marginTop: 2,
                                    marginLeft: 3
                                }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Attach any relevant documents, reports, or files associated with the certification.`}
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"> help</i></span>  </label>

                                {certificate.attachment && certificate.attachment.length ?
                                    attachmentTemplate(certificate.attachment[0])
                                    : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                        <input {...getInputProps()} />
                                        <i className='pi pi-cloud-upload'></i>
                                        <p>Drag and drop or click to upload</p>
                                    </div>
                                }
                            </div>

                        </>
                    )}

                    {certificate.status === 2 && (
                        <>
                            <div>
                                <div className="p-field col-12 pe-0 ps-0 pb-4">
                                    <label htmlFor="completionDate">Expected Date of Achievement</label>
                                    <Calendar id="completionDate" value={certificate.expectedDate}
                                        onChange={(e) => { updateCertificate("expectedDate", e.value) }} showIcon />

                                    {submitted && certificate.expectedDate === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Enter Issuing Authority                            </small>
                                    )}
                                </div>
                            </div>

                            <div className="p-field col-12 pe-0 ps-0 pb-4">
                                <label htmlFor="description">Comments</label>
                                <InputText id="description" value={certificate.comments} onChange={(e) => setCertificate({ ...certificate, comments: e.target.value })} />

                                {submitted && certificate.comments.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Issuing Authority                            </small>
                                )}
                            </div>

                        </>
                    )}
                </div>
            </Dialog>



        </div>

    );
};

export default Certification;
