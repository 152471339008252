import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const waterDischargedData = [
  {
    year: "2019",
    surface: 20,
    seawater: 30,
    thirdParty: 30,
    groundWater: 20,
    ownProducedWater: 20,
    total: 120,
  },
  {
    year: "2020",
    surface: 15,
    seawater: 25,
    thirdParty: 12,
    groundWater: 10,
    ownProducedWater: 20,
    total: 82,
  },

  {
    year: "2021",
    surface: 20,
    seawater: 20,
    thirdParty: 15,
    groundWater: 10,
    ownProducedWater: 25,
    total: 90,
  },
  {
    year: "2022",
    surface: 30,
    seawater: 25,
    thirdParty: 20,
    groundWater: 25,
    ownProducedWater: 10,
    total: 110,
  },
  {
    year: "2023",
    surface: 15,
    seawater: 15,
    thirdParty: 10,
    groundWater: 20,
    ownProducedWater: 30,
    total: 90,
  },
  {
    year: "2024",
    surface: 25,
    seawater: 20,
    thirdParty: 25,
    groundWater: 15,
    ownProducedWater: 20,
    total: 105,
  },
  {
    month: "Dec",
    surface: 35,
    seawater: 30,
    thirdParty: 15,
    groundWater: 25,
    ownProducedWater: 10,
    total: 115,
  },
];

const CustomWaterLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 4 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

const OverviewWaterDischarge = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Water Disposed
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Quantity of water disposed through different modes
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          />{" "} */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ overflow: "hidden" }}>
          <ResponsiveContainer height={350} width="100.5%">
            <ComposedChart data={waterDischargedData}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="month" />
              <YAxis
                label={{
                  value: "Tons",
                  angle: -90,
                  position: "insideLeft",
                }}
              />{" "}
              <Tooltip />
              <Bar
                dataKey="surface"
                stackId="a"
                fill="#455565"
                name="Surface water"
                barSize={60}
              />
              <Bar
                dataKey="seawater"
                stackId="a"
                fill="#06b08c"
                name="Seawater"
              />
              <Bar
                dataKey="thirdParty"
                stackId="a"
                fill="#60d7b4"
                name="Third party water"
              />
              <Bar
                dataKey="ownProducedWater"
                stackId="a"
                fill="#c0ffdc"
                name="Own Produced water"
              />
              <Bar
                dataKey="groundWater"
                stackId="a"
                fill="#a7e3d3"
                name="Ground water"
              />
              <Legend content={CustomWaterLegend} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={waterDischargedData}
            tableClassName="font-lato"
          >
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Surface"
              style={{ minWidth: "8%" }}
              field="surface"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Sea Water"
              style={{ minWidth: "8%" }}
              field="seawater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Third Party"
              style={{ minWidth: "8%" }}
              field="thirdParty"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Ground Water"
              style={{ minWidth: "8%" }}
              field="groundWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Own Produced Water"
              style={{ minWidth: "8%" }}
              field="ownProducedWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Total"
              style={{ minWidth: "8%" }}
              field="total"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
export default OverviewWaterDischarge;
