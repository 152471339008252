import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import Axios from "axios";
import { API } from "../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import moment from "moment";
import { Chart } from "chart.js/dist/chart";
import { registerables } from "chart.js";

import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import ChartDL from "../../assets/js/chartjsplugin";
import { resetOverallPendingData, setOverallPendingData } from "../../RTK/Background/pendingData";
import { renderMonthlyReport } from "../../components/BGHF/helper";

import APIServices from "../../service/APIService";

Chart.register(...registerables);
const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};

const AdminCCDashboard = (props) => {
    const [products, setProducts] = useState(null);
    const siteList = useSelector((state) => state.sitelist.siteList)
    const [search, setSearch] = useState('')
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [countopt, setCountryOpt] = useState([])
    const [cityopt, setCityOpt] = useState([])
    const [locopt, setLocOpt] = useState([])
    const [indiopt, setIndiOpt] = useState([])
    const [indicators, setInidicators] = useState([])
    const [yearopt, setYearOpt] = useState([])
    const [dcfasscount, setDCFAssCount] = useState(0)
    const colorsBlue = ["#0010d9", "#0020ff", "#0040ff", "#1D53BF", "#0060ff", "#0080ff", "#009fff", "#00bfff", "#00ffff", "#22a7f0", "#a7d5ed", "#63bff0"];
    const colorsBlueBR = ["#0010d920", "#0020ff20", "#0040ff20", "#1D53BF20", "#0060ff20", "#0080ff20", "#009fff20", "#00bfff20", "#00ffff20", "#22a7f020", , "#a7d5ed20", "#63bff020"];
    const [monthopt, setMonthOpt] = useState([{ name: 'All', id: 0 }, { name: 'Januaury', id: 1 }, { name: 'Febraury', id: 2 }, { name: 'March', id: 3 }, { name: 'April', id: 4 }, { name: 'May', id: 5 },
    { name: 'June', id: 6 }, { name: 'July', id: 7 }, { name: 'August', id: 8 }, { name: 'September', id: 9 }, { name: 'October', id: 10 }, { name: 'November', id: 11 }, { name: 'December', id: 12 }])

    const [dcflist, setDcfList] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [sitelistall, setSiteListAll] = useState([])
    const [past, setPast] = useState([])
    const [filter, setFilter] = useState({ country: { name: 'All', id: 0 }, city: { name: 'All', id: 0 }, location: { name: 'All', id: 0 }, indicator: { title: 'All', id: 0 }, year: { name: 'All', id: 0 }, month: { name: 'All', id: 0 } })
    const dispatch = useDispatch()
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }]
    const locationList = useSelector(state => state.sitelist.locationList)
    const userList = useSelector(state => state.userlist.userList)

    const [lineOptions, setLineOptions] = useState(null);
    const [apidata, setApiData] = useState([]);
    const [activity, setActivity] = useState({
        pending: [],
        approved: [],
    });
    const selector = useSelector((state) => state.user.userdetail);
    const [pendingFilter, setPendingFilter] = useState({
        month: null,
        scope: {
            name: "All",
        },
        topic: {
            name: "All",
        },
    });
    const [filterData, setFilterData] = useState({
        scope: [],
        topic: [],
    });
    const [rawtopic, setRawTopic] = useState([]);
    const [rawdata, setRawData] = useState({
        topic: [],
        scope: [],
    });
    const forceUpdate = useForceUpdate();

    const [assdcfdata, setAssignedDCFData] = useState([]);
    let savedPast = useSelector((state) => state.pendingdata.overallPending)
    let savedPPF = useSelector((state) => state.pendingdata.ppfList)

    const dt = useRef(null);
    const [response, setResponse] = useState([])
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    useEffect(() => {
        console.log(savedPast)
        // renderTable()
        if (savedPast.length === 0) {
            renderTable(0)
        } else {

            setPast(savedPast)
        }
    }, []);
    useEffect(() => {
        if (locationList.length !== 0) {
            APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res) => {
                APIServices.get(API.AssignDCFUser_UP(selector.id)).then((res2) => {
                    let result = ((res2.data.length / (res.data[0].dcf_ids.length * locationList.length)) * 100).toFixed(0) + '%'
                    setDCFAssCount(result)
                })
            })
        }
    }, [locationList])
    useEffect(() => {

        if (indiopt.length !== 0 && response.length !== 0 && selector.id === 'hide') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: null })

        }
    }, [response])

    useEffect(() => { countopt !== 0 && setTimeout(() => { freezeFilter() }, 1500) }, [countopt])
    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };
    const renderTable = (type) => {
        if (type === 1) {
            console.log('refreshing...')
        }

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
      
       
        let metricString = {
            "include": [{ "relation": "newDataPoints" }]

        }
        let dcf_list = [], dcf_submitted = [], locloc = []
        let copt = [{ name: "All", id: 0 }], ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]
        siteList.forEach((country) => {
            copt.push(country)
            country.locationTwos.forEach((city) => {
                ccopt.push(city)
                city.locationThrees.forEach((loc, ind) => {

                    locopt.push(loc)

                })
            })
        })

        setCityOpt(ccopt)
        setCountryOpt(copt)
        setLocOpt(locopt)


        const promise1 = APIServices.get(API.DCF)
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.LocationThree)


        let indidata = [{ title: 'All', id: 0 }]
        APIServices.get(API.Metric + `?filter=${encodeURIComponent(JSON.stringify(metricString))}`).then((res) => {
            let metrics = res.data

            APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res) => {
                res.data.forEach((item) => {
                    item.metric_ids.forEach((item2) => {

                        let index = metrics.findIndex((k) => { return k.id === item2 })
                        if (index !== -1) {

                            indidata.push(metrics[index])
                        }
                    })
                })
                // let data = res.data[0].metric_ids.map((i) => { return metrics.filter((k) => { return k.id === i })[0] })
                setIndiOpt(indidata)
                setInidicators(indidata)
            })
        })
        setTimeout(() => {
            if (selector.id === 'hide') {
                APIServices.get(API.DP_report_UP(selector.id)).then((dpres) => {
                    let val = [], filterarr = groupArrayObject(dpres.data, 'submitId')
                    Object.keys(filterarr).forEach((item) => {

                        val.push({ rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

                    })
                    setResponse(val)
                    console.log(val)
                    let yearlist = [{ name: 'All', id: 0 }]
                    dpres.data.forEach((report) => {
                        report.year.forEach((yr) => {
                            if (yearlist.findIndex((k) => { return k.name === yr }) === -1) {
                                yearlist.push({ name: yr })
                            }
                        })
                    })
                    setYearOpt(yearlist)
                })
            }
        }, 2000)


        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;

        Promise.all([promise1, promise2, promise3]).then(function (values) {
            dcf_list = values[0].data; setDcfList(values[0].data)

            dcf_submitted = values[1].data;
            locloc = values[2].data

            forceUpdate()

            APIServices.get(API.AssignDCFUser_UP(selector.id)).then((res) => {
                let filter = [], locationFilter = [0], rawData = [], pending = [], count = 0


                res.data.forEach((item, i) => {



                    if (item.type === 0) {
                        count++

                        if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                            item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                             item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]

                             item.start_date = moment.utc(item.start_date).toDate()
                            filter.push(item)
                            if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                                locationFilter.push(item.site[0])
                            }
                            
                             rawData.push(...getMonths(moment(moment.utc(item.start_date).toDate()), moment.utc(moment.utc()), item.frequency, item, dcf_submitted, count).past_)


                        }

                    }
                })
                setPast(rawData)

                dispatch(setOverallPendingData(rawData))
                APIServices.get(site_url).then((res) => {
                    let site_list = [{ name: 'All', id: 0 }]
                    let site_list_all = []
                    res.data.forEach((country) => {
                        if (country.locationTwos !== undefined) {
                            country.locationTwos.forEach((city) => {
                                if (city.locationThrees !== undefined) {
                                    city.locationThrees.forEach((site) => {
                                        filter.forEach((item) => { if (site.id === item.site[0]) { item.site = site } })
                                        site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                                        site_list_all.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })

                                    })
                                }
                            })
                        }
                    })
                    setSiteListAll(site_list_all)
                    setSiteList(site_list.filter((k) => { return locationFilter.includes(k.id) }))
                })


            })

        })
    }
    const getMonthSubmission = (month) => {
        let total = savedPPF.filter((k) => { return k.duedate === month })
        let submitted = total.filter((k) => { return k.draft !== undefined && (k.draft.type === 3) }).length
        let draft = total.filter((k) => { return k.draft !== undefined && (k.draft.type === 0) }).length
        let pending = total.filter((k) => { return k.draft === undefined }).length

        return (((submitted) / (total.length)) * 100).toFixed(0) + '%'
    }
    const getYearSubmission = (year) => {
        let total = savedPPF.filter((k) => { return parseInt(k.duedate.split('-')[1]) === year })
        let submitted = total.filter((k) => { return k.draft !== undefined && (k.draft.type === 3) }).length
        let draft = total.filter((k) => { return k.draft !== undefined && (k.draft.type === 0) }).length
        let pending = total.filter((k) => { return k.draft === undefined }).length
        let value = (((submitted) / (total.length)) * 100).toFixed(0)
        return (((submitted) / (total.length)) * 100).toFixed(0) + '%'
    }
    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) + 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) + 1] + "-" + rp[0].split('-')[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split('-')[0]) + 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const showBarChart = (chartdata, id, custom) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");

        const resetChart = {
            id: "resetchart",
            beforeDraw(chart, args, options) {
                if (scope1c.config.data.datasets[0].label !== custom.name) {
                    count = 0;
                    const {
                        ctx,
                        chartArea: { top, left, right, bottom },
                    } = chart;

                    ctx.save();
                    const text = "Back";
                    const thickness = 3;
                    const textwidth = ctx.measureText(text).width;

                    ctx.fillStyle = "rgba(255,26,104,0.2)";
                    ctx.fillRect(right - (textwidth + 11), 5, textwidth + 10, 20);

                    ctx.fillStyle = "#666";
                    ctx.font = "12px Arial";
                    ctx.fillText(text, right - (textwidth + 6), 15);

                    ctx.lineWidth = thickness + "px";
                    ctx.strokeStyle = "rgba(255,26,104,1)";
                    ctx.strokeRect(right - (textwidth + 11), 5, textwidth + 10, 20);
                    coords.top = 5;
                    coords.left = right - (textwidth + 11);
                    coords.right = right;
                    coords.bottom = 25;

                    ctx.restore();
                }
            },
        };
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                //Bring in data

                datasets: [
                    {
                        data: dummy,
                        label: custom.name,
                        backgroundColor: [colorsBlue[0], colorsBlue[1], colorsBlue[2], colorsBlue[3], colorsBlue[4], colorsBlue[5], colorsBlue[6], colorsBlue[7], colorsBlue[8], colorsBlue[9], colorsBlue[10], colorsBlue[11]],
                        borderColor: [colorsBlueBR[0], colorsBlueBR[1], colorsBlueBR[2], colorsBlueBR[3], colorsBlueBR[4], colorsBlueBR[5], colorsBlueBR[6], colorsBlueBR[7], colorsBlueBR[8], colorsBlueBR[9], colorsBlueBR[10], colorsBlueBR[11]],
                    },
                ],
            },
            options: {
                onHover: (event, charElement) => {
                    if (scope1c.config.data.datasets[0].label === custom.name) {
                        event.native.target.style.cursor = charElement[0] ? "pointer" : "default";
                    } else {
                        event.native.target.style.cursor = "default";
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        },
                    },
                },
                legend: {
                    display: false,
                },
                parsing: {
                    xAxisKey: "name",
                    yAxisKey: "y",
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: "black",
                            boxWidth: 0,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },
            },
            plugins: [resetChart, ChartDL],
        });

        function clickHandler(click) {
            if (scope1c.config.data.datasets[0].label === custom.name) {
                const bar = scope1c.getElementsAtEventForMode(
                    click,
                    "nearest",
                    {
                        intersect: true,
                    },
                    true
                );
                if (bar.length) {
                    if (dummy[bar[0].index].drilldown) {
                        changeChart(bar[0].index);
                    }
                }
            }
        }

        function changeChart(ind) {
            scope1c.config.options.parsing.xAxisKey = "subset.x";
            scope1c.config.options.parsing.yAxisKey = "subset.xy";
            const users = [];
            const vlabels = dummy[ind].subset.map((labels) => {
                users.push(labels[1]);

                return labels[0];
            });
            console.log(users)
            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;
            scope1c.config.options.plugins.legend.display = true;
            scope1c.config.data.datasets[0].label = dummy[ind].name;
            scope1c.update();
        }

        function mouseMoveHandler(canvas, mousemove) {
            const x = mousemove.offsetX;
            const y = mousemove.offsetY;
            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                canvas.style.cursor = "pointer";
            } else {
                canvas.style.cursor = "default";
            }
        }

        function handleBackClick() {
            scope1c.config.options.parsing.xAxisKey = "name";
            scope1c.config.options.parsing.yAxisKey = "y";
            const users = [];
            const vlabels = dummy.map((labels) => {
                users.push(labels.y);
                return labels.name;
            });
            scope1c.config.options.plugins.legend.display = true;
            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;

            scope1c.config.data.datasets[0].label = custom.name;

            scope1c.update();
        }

        function mouseClickHandler(click) {
            const x = click.offsetX;
            const y = click.offsetY;

            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                handleBackClick();
            }
        }

        chartref.addEventListener("click", (e) => {
            if (count === 0) {
                count = 1;
                mouseClickHandler(e);
            }
        });
        chartref.addEventListener("mousemove", (e) => mouseMoveHandler(chartref, e));
        chartref.onclick = clickHandler;
    }
    const freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        if (stickyElem) {
            let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
            window.onscroll = function () {
                /* Check if the current Y offset
                is greater than the position of
                the element */
                if (window.pageYOffset > currStickyPos) {
                    stickyElem.style.position = "fixed";
                    stickyElem.style.top = "58px";

                    stickyElem.style.width =
                        document.getElementById("divsize").clientWidth + "px";

                    stickyElem.style.zIndex = 999;
                } else {
                    stickyElem.style.position = "relative";
                    stickyElem.style.top = "initial";
                    stickyElem.style.width = "100%";
                    stickyElem.style.background = "transparent";
                    stickyElem.style.zIndex = 1;
                }
            };
        }
    };
    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    useEffect(() => {
     
        selector.id === 'hide' && setPendingFilterValue()
    }, []);

    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);
    const groupArrayObject = (array, obj) => {
        return array.reduce((group, arr) => {

            let key = arr[obj];

            group[key] = group[key] ?? [];

            group[key].push(arr);

            return group;

        },

            {})
    }
    const getMonths = (startDate, endDate, frequency, item, old, count) => {

        var betweenMonths = [];
        let type = 0, past_ = [], present_ = [], future_ = []





        if (startDate.diff(endDate.startOf('month'), 'days') === 0) {
            let endDate_ = startDate

            let endDate__ = moment(moment.utc())
            if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

                if (check.result) {

                    if (check.data) {
                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: endDate__.diff(endDate__, 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                    }
                } else {
                    if (check.data) {

                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })
                    } else {
                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                    }

                }


            }

        }
        else if (startDate.diff(endDate.startOf('month'), 'days') < 31) {
            var date = startDate.startOf('month')

            // let endDate__ = moment('01.03.2023', 'DD.MM.YYYY')
            let endDate__ = moment(moment.utc())
            while (date <= endDate__) {

                if (endDate__.format('MMM-YYYY') !== date.format('MMM-YYYY')) {
                    console.log(date.format('MMM-YYYY'))
                    betweenMonths.push(date.format('MMM-YYYY'));
                }
                date.add(1, 'month');


            }

            if (frequency === 1) {

                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month() === moment(months[0]).month() && endDate__.year() === moment(months[0]).year()) {



                    } else {
                        if (endDate__.diff(moment(months[0]), 'months') === 1) {


                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                            if (check.result) {
                                if (check.data) {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                }
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                } else {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                }

                            }

                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                            if (check.result) {
                                if (check.data) {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                }
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                } else {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month() === moment(months[1]).month() && endDate__.year() === moment(months[1]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[1]), 'months') === 1) {

                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }

                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month() === moment(months[2]).month() && endDate__.year() === moment(months[2]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[2]), 'months') === 1) {


                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)
                                console.log(check)
                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }



                                }
                            } else {

                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month() === moment(months[11]).month() && endDate__.year() === moment(months[11]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[11]), 'months') === 1) {


                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month() === moment(months[5]).month() && endDate__.year() === moment(months[5]).year()) {


                        } else {
                            if (endDate__.diff(moment(months[5]), 'months') === 1) {


                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                if (check.result) {

                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }

                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                if (check.result) {

                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    }
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    if (moment(months[0]).month() === endDate__.month() && moment(months[0]).year() === endDate__.year()) {



                    } else {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {
                            if (check.data) {
                                check.list.forEach((list) => {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, response: list.response, id: list.id, user: item.user_id, approver: item.approver_id })

                                })
                            }


                        } else {
                            if (check.data) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                            } else {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                            }

                        }


                    }

                })
            }

        }



        return { type, months: betweenMonths, past_ }
    }
    const checkSubmission = (dcf, site, rp, old) => {
        console.log(site)
        let rps = getRP(rp)
        let result = { result: true, data: true }
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 0)
        })
        let index1 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 1
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 3
        })
        if (site[0] === 14) {
            console.log(index, index1, index2, old)
        }
        if (index2 !== -1) {

            result = { result: true, data: false }
        }
        else if (index !== -1) {
            result = {
                result: false, data: false, list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
                })
            }
        } else if (index1 !== -1) {
            result = {
                result: false, data: true, list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 1
                })
            }
        }

        console.log(result)
        return result
    }

    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
    //dummy
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const yeartemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Year </span> {rowData.year}
            </>
        );
    };
    const monthtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.month}
            </>
        );
    };
    const countrytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.country}
            </>
        );
    };
    const citytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.city}
            </>
        );
    };
    const locationtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.location}
            </>
        );
    };
    const approvedtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Approved </span> {rowData.approved_by}
            </>
        );
    };
    const remindertemplate = (rowData) => {
        let res = { msg: 'Email not sent' }
        let userIndex = userList.findIndex((i) => { return i.id === rowData.user })
        let approverIndex = userList.findIndex((i) => { return i.id === rowData.approver })
        if (rowData.draft === undefined) {

            if (userIndex !== -1) {
                res = { msg: 'Reminder mail sent to ' + userList[userIndex].email, mail: userList[userIndex].email, supemail: userList[userIndex].information.empdesgination }
            }

        } else {
            rowData.draft.type === 1 ? res = { msg: 'Reminder mail sent to ' + userList[userIndex].email, mail: userList[userIndex].email, supemail: userList[userIndex].information.empdesgination } : res = { msg: 'Reminder mail sent to ' + userList[approverIndex].email, mail: userList[approverIndex].email, supemail: userList[userIndex].information.empdesgination }
        }
        return (
            <>
                <span className="p-column-title"> Approved </span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <label
                        style={{
                            background: 'cadetblue',
                            padding: '5px',
                            color: 'white',
                            width: '100px',
                            display: 'flex',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            alert(res.msg);
                        }}
                    >

                        Reminder
                    </label>
                    <label
                        style={{
                            background: "indianred",

                            padding: '5px',
                            color: 'white',
                            width: '100px',
                            display: 'flex',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            alert("Remainder Mail send to " + res.supemail);
                        }}
                    >

                        Escalate
                    </label>
                </div>
            </>
        );
    };
    //Dummy end

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    };
    const updatePendingFilter = (obj, val) => {
        let loc = pendingFilter;
        loc[obj] = val;
        if (obj === "scope") {
            if (val.name !== "All") {
                let loc2 = filterData;
                loc["topic"] = {
                    name: "All",
                };

                let ind = rawtopic.findIndex((i) => {
                    return Object.keys(i)[0] === val.name;
                });

                loc2.topic = rawtopic[ind][val.name];
                setFilterData(loc2);
            } else {
                let loc2 = filterData;

                loc2.topic = [
                    {
                        name: "All",
                    },
                ];
                setFilterData(loc2);
            }
        }
        if (obj === "scope") {
            renderFilterValue(pendingFilter.month, pendingFilter.topic.name, val.name);
        } else if (obj === "topic") {
            renderFilterValue(pendingFilter.month, val.name, pendingFilter.scope.name);
        } else {
            renderFilterValue(val, pendingFilter.topic.name, pendingFilter.scope.name);
        }
        setPendingFilter(loc);
        forceUpdate();
    };
    const setPendingFilterValue = () => {
        let module = [];
        let list = rawdata;
        selector.information.config[0].location.countrylist.forEach((i) => {
            //  module.push({country: i.country})
            i.city.forEach((j) => {
                // module.push({country:i.country,city:j.city})
                j.location.forEach((k) => {
                    module.push({
                        country: i.country,
                        city: j.city,
                        location: k.location,
                    });
                });
            });
        });
        let scopelist = [];
        let topiclist = [];
        let count = 0;
        selector.information.config[0].selectedModule.forEach((i) => {
            i.children.forEach((j, a) => {
                if (a == 0) {
                    scopelist.push({
                        name: "All",
                    });
                }
                j.children.forEach((k) => {
                    list.scope.push(k.name);
                    scopelist.push({
                        name: k.name,
                    });
                    topiclist.push({
                        [k.name]: [],
                    });

                    if (k.children !== undefined) {
                        k.children.forEach((l, i) => {
                            list.topic.push(l.name);
                            if (i == 0) {
                                topiclist[count][k.name].push({
                                    name: "All",
                                });
                            }
                            topiclist[count][k.name].push({
                                name: l.name,
                            });
                        });
                        count++;
                    } else {
                        list.topic.push(k.name);
                        topiclist[count][k.name].push({
                            name: "All",
                        });
                        topiclist[count][k.name].push({
                            name: k.name,
                        });
                        count++;
                        // topiclist[count].push({ [k.name]: [{ name: k.name }] })
                    }
                });
            });
        });
        setRawData(list);
        let loc = filterData;
        loc.scope = scopelist;
        loc.topic = topiclist;

        setFilterData(loc);
        setRawTopic(topiclist);
    };
    const showNoDataFound = (id, txt) => {
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const ctx = document.getElementById(id).getContext("2d");

        // Set the font-size and font-family to mimic our paragraph
        ctx.font = "20px serif";

        // Draw the text
        ctx.fillText(txt, 0, 20);
    };
    const dateFormatting = (date) => {
        if (typeof date === "string") {
            let newDate = new Date(date);

            return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
        } else {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        }
    };
    const scopeFormat = (name) => {
        if (!name.toLowerCase().includes("general")) {
            let sub1 = name[0].toLowerCase();
            let sub2 = name.split(" ")[0];
            let sub3 = sub1 + name.substr(1, sub2.length - 1) + name.split(" ")[1];
            return sub3;
        } else {
            return "general";
        }
    };
    const renderFilterValue = (month, topic, scope) => {
        apidata.forEach((i) => {
            if (i.month === dateFormatting(month) && (i.scope === scopeFormat(scope) || scope === "All") && (i.topic === scopeFormat(topic) || topic === "All")) {

            }
        });
    };
    const responsibilityTemplate = (rowData) => {
        let res = 'Reporter not exist'
        let userIndex = userList.findIndex((i) => { return i.id === rowData.user })
        let approverIndex = userList.findIndex((i) => { return i.id === rowData.approver })
        if (rowData.draft === undefined) {

            if (userIndex !== -1) {
                res = userList[userIndex].information.empname + ' (Reporter)'
            }

        } else {
            rowData.draft.type === 1 ? res = userList[userIndex].information.empname + ' (Reporter)' : res = userList[approverIndex].information.empname + ' (Reviewer)'
        }

        return (<span>{res}</span>)
    }
    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }
    const renderGraph = (filter) => {
        let fg_gasfilled = [], fg_ghg = [], pg_ghg = [], pg_class = [], ck_ghg = [], ck_used = [], sc_ghg = [], sc_fuel = [], pe_usedm = [], fg_gasfilledm = [], pe_used = [], pe_ghg = [], sc_total = 0

        sc_ghg.push({
            name: 'c2o',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        sc_ghg.push({
            name: 'ch4',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        sc_ghg.push({
            name: 'n2o',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        pg_ghg.push({
            name: 'c2o',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        pg_ghg.push({
            name: 'ch4',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        pg_ghg.push({
            name: 'n2o',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        pg_ghg.push({
            name: 'others',
            drilldown: true,
            color: 'red',
            y: 0,
            subset: [],
        });
        response.forEach((fgitem) => {
            console.log(fgitem)
            if (checkSite(fgitem.site, filter.location) && (fgitem.year.includes(filter.year.name) || filter.year.name === 'All')) {
                fgitem.sitename = locationList.filter((loc) => { return loc.id === fgitem.site })[0].name

                if (fgitem.dcf === 10) {
                    fgitem.response.forEach((fg) => {
                        let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                        let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                        let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                        if (
                            fg_gasfilled.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            }) === -1
                        ) {
                            fg_gasfilled.push({
                                name: fgitem.sitename,
                                drilldown: true,
                                color: 'red',
                                y: gasrefilled,
                                subset: [[gastype, gasrefilled]],
                            });
                        } else {
                            let ind = fg_gasfilled.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            });

                            let subind = fg_gasfilled[ind].subset.findIndex((j) => {
                                return j[0] === gastype;
                            });
                            fg_gasfilled[ind].y = fg_gasfilled[ind].y + gasrefilled;
                            if (subind === -1) {
                                fg_gasfilled[ind].subset.push([gastype, gasrefilled]);
                            } else {
                                fg_gasfilled[ind].subset[subind][1] = fg_gasfilled[ind].subset[subind][1] + gasrefilled;
                            }
                        }
                        if (
                            fg_ghg.findIndex((i) => {
                                return i.name === 'GHG';
                            }) === -1
                        ) {
                            fg_ghg.push({
                                name: 'GHG',
                                drilldown: true,
                                color: 'red',
                                y: total,
                                subset: [[fgitem.rp_, total]],
                            });
                        } else {
                            let subind = fg_ghg[0].subset.findIndex((j) => {
                                return j[0] === fgitem.rp_;
                            });


                            fg_ghg[0].y = fg_ghg[0].y + total;
                            if (subind === -1) {
                                fg_ghg[0].subset.push([fgitem.rp_, total]);
                            } else {
                                fg_ghg[0].subset[subind][1] = fg_ghg[0].subset[subind][1] + total;
                            }
                        }
                        if (
                            fg_gasfilledm.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            }) === -1
                        ) {
                            fg_gasfilledm.push({
                                name: fgitem.sitename,
                                drilldown: true,
                                color: 'red',
                                y: gasrefilled,
                                subset: [[fgitem.rp_, gasrefilled]],
                            });
                        } else {
                            let ind = fg_gasfilledm.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            });
                            let subind = fg_gasfilledm[ind].subset.findIndex((j) => {
                                return j[0] === fgitem.rp_;
                            });


                            fg_gasfilledm[ind].y = fg_gasfilledm[ind].y + gasrefilled;
                            if (subind === -1) {
                                fg_gasfilledm[ind].subset.push([fgitem.rp_, gasrefilled]);
                            } else {
                                fg_gasfilledm[ind].subset[subind][1] = fg_gasfilledm[ind].subset[subind][1] + gasrefilled;
                            }
                        }
                    })
                }
                if (fgitem.dcf === 93) {
                    fgitem.response.forEach((fg) => {
                        let used = fg.filter((i) => { return i.dpId === "DPA0344" })[0].value
                        let lost = fg.filter((i) => { return i.dpId === "DPA0345" })[0].value
                        let state = fg.filter((i) => { return i.dpId === "DPA0346" })[0].value

                        let total = ((emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[3].data1[0].importedData.filter((k) => { return k.state === state })[0]['carbon_co2_in_kg'] / 1000) * used) + ((emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[3].data1[0].importedData.filter((k) => { return k.state === state })[0]['ckd_lost_in_kg'] / 1000) * lost)
                        if (
                            ck_used.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            }) === -1
                        ) {
                            ck_used.push({
                                name: fgitem.sitename,
                                drilldown: true,
                                color: 'red',
                                y: used + lost,
                                subset: [['Clinker', used], ['CKD', lost]],
                            });
                        } else {
                            let ind = ck_used.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            });


                            ck_used[ind].y = ck_used[ind].y + used + lost;
                            ck_used[ind].subset[0][1] = ck_used[ind].subset[0][1] + used;
                            ck_used[ind].subset[1][1] = ck_used[ind].subset[1][1] + lost;


                        }
                        if (
                            ck_ghg.findIndex((i) => {
                                return i.name === 'GHG';
                            }) === -1
                        ) {
                            ck_ghg.push({
                                name: 'GHG',
                                drilldown: true,
                                color: 'red',
                                y: total,
                                subset: [[fgitem.rp_, total]],
                            });
                        } else {
                            let subind = ck_ghg[0].subset.findIndex((j) => {
                                return j[0] === fgitem.rp_;
                            });


                            ck_ghg[0].y = ck_ghg[0].y + total;
                            if (subind === -1) {
                                ck_ghg[0].subset.push([fgitem.rp_, total]);
                            } else {
                                ck_ghg[0].subset[subind][1] = ck_ghg[0].subset[subind][1] + total;
                            }
                        }
                    })
                }
                if (fgitem.dcf === 11) {
                    console.log(fgitem)

                    fgitem.response.forEach((fg) => {
                        let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                        let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                        let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                        let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                        let total = 0, co2 = 0, ch4 = 0, n2o = 0
                        if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                            let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                            let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                            if (carbon > 0 && cv > 0) {
                                let gj = (carbon * 3.664 * 1000) / cv
                                total = (gj * 0.000004184 * consumed) / 1000
                                co2 = 0
                                ch4 = 0
                                n2o = 0
                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed

                            }

                        } else {
                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                            co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                            ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                            n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed

                        }


                        // sc_ghg = sc_ghg + (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed



                        let subind = sc_ghg[0].subset.findIndex((j) => {
                            return j[0] === fgitem.rp_;
                        });


                        sc_ghg[0].y = sc_ghg[0].y + co2
                        sc_ghg[1].y = sc_ghg[1].y + ch4
                        sc_ghg[2].y = sc_ghg[2].y + n2o
                        if (subind === -1) {
                            sc_ghg[0].subset.push([fgitem.rp_, co2])
                            sc_ghg[1].subset.push([fgitem.rp_, ch4])
                            sc_ghg[2].subset.push([fgitem.rp_, n2o])

                        } else {
                            console.log(co2, fgitem.rp_)
                            sc_ghg[0].subset[subind][1] = sc_ghg[0].subset[subind][1] + co2
                            sc_ghg[1].subset[subind][1] = sc_ghg[1].subset[subind][1] + ch4

                            sc_ghg[2].subset[subind][1] = sc_ghg[2].subset[subind][1] + n2o

                        }

                        sc_total = sc_total + total
                    })
                }
                if (fgitem.dcf === 72) {
                    fgitem.response.forEach((fg) => {
                        let ren = fg.filter((i) => { return i.dpId === "DPA0156" })[0].value
                        let nonren = fg.filter((i) => { return i.dpId === "DPA0157" })[0].value
                        let total2 = ren + nonren
                        let total = (ren * 0.00081) + (nonren * 0.00081)

                        if (
                            pe_used.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            }) === -1
                        ) {
                            pe_used.push({
                                name: fgitem.sitename,
                                drilldown: true,
                                color: 'red',
                                y: total2,
                                subset: [['Renewable', ren], ['Non-Renewable', nonren]],
                            });
                        } else {
                            let ind = pe_used.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            });


                            pe_used[ind].y = pe_used[ind].y + total2;
                            pe_used[ind].subset[0][1] = pe_used[ind].subset[0][1] + ren;
                            pe_used[ind].subset[1][1] = pe_used[ind].subset[1][1] + nonren;




                        }
                        if (
                            pe_ghg.findIndex((i) => {
                                return i.name === 'GHG';
                            }) === -1
                        ) {
                            pe_ghg.push({
                                name: 'GHG',
                                drilldown: true,
                                color: 'red',
                                y: total,
                                subset: [[fgitem.rp_, total]],
                            });
                        } else {
                            let subind = pe_ghg[0].subset.findIndex((j) => {
                                return j[0] === fgitem.rp_;
                            });


                            pe_ghg[0].y = pe_ghg[0].y + total;
                            if (subind === -1) {
                                pe_ghg[0].subset.push([fgitem.rp_, total]);
                            } else {
                                pe_ghg[0].subset[subind][1] = pe_ghg[0].subset[subind][1] + total;
                            }
                        }
                        if (
                            pe_usedm.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            }) === -1
                        ) {
                            pe_usedm.push({
                                name: fgitem.sitename,
                                drilldown: true,
                                color: 'red',
                                y: total2,
                                subset: [[fgitem.rp_, total2]],
                            });
                        } else {
                            let ind = pe_usedm.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            });
                            let subind = pe_usedm[ind].subset.findIndex((j) => {
                                return j[0] === fgitem.rp_;
                            });


                            pe_usedm[ind].y = pe_usedm[ind].y + total2;
                            if (subind === -1) {
                                pe_usedm[ind].subset.push([fgitem.rp_, total2]);
                            } else {
                                pe_usedm[ind].subset[subind][1] = pe_usedm[ind].subset[subind][1] + total2;
                            }
                        }
                    })
                }
                if (fgitem.dcf === 16) {
                    fgitem.response.forEach((fg) => {
                        let classtype = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                        let qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                        let cost = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value
                        let co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === classtype })[0]['co2_in_kg'] / 1000) * qty * cost
                        let ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === classtype })[0]['ch4_in_kg'] / 1000) * qty * cost
                        let n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === classtype })[0]['n2o_in_kg'] / 1000) * qty * cost
                        let others = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === classtype })[0]['others_in_kg'] / 1000) * qty * cost

                        let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === classtype })[0]['co2e_in_kg'] / 1000) * qty * cost

                        if (
                            pg_class.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            }) === -1
                        ) {
                            pg_class.push({
                                name: fgitem.sitename,
                                drilldown: true,
                                color: 'red',
                                y: cost,
                                subset: [[classtype, cost]],
                            });
                        } else {
                            let ind = pg_class.findIndex((i) => {
                                return i.name === fgitem.sitename;
                            });

                            let subind = pg_class[ind].subset.findIndex((j) => {
                                return j[0] === classtype;
                            });
                            pg_class[ind].y = pg_class[ind].y + cost;
                            if (subind === -1) {
                                pg_class[ind].subset.push([classtype, cost]);
                            } else {
                                pg_class[ind].subset[subind][1] = pg_class[ind].subset[subind][1] + cost;
                            }
                        }


                        let subind = pg_ghg[0].subset.findIndex((j) => {
                            return j[0] === fgitem.rp_;
                        });


                        pg_ghg[0].y = pg_ghg[0].y + co2
                        pg_ghg[1].y = pg_ghg[1].y + ch4
                        pg_ghg[2].y = pg_ghg[2].y + n2o
                        pg_ghg[3].y = pg_ghg[3].y + others

                        if (subind === -1) {
                            pg_ghg[0].subset.push([fgitem.rp_, co2])
                            pg_ghg[1].subset.push([fgitem.rp_, ch4])
                            pg_ghg[2].subset.push([fgitem.rp_, n2o])
                            pg_ghg[3].subset.push([fgitem.rp_, others])

                        } else {
                            console.log(co2, fgitem.rp_)
                            pg_ghg[0].subset[subind][1] = pg_ghg[0].subset[subind][1] + co2
                            pg_ghg[1].subset[subind][1] = pg_ghg[1].subset[subind][1] + ch4

                            pg_ghg[2].subset[subind][1] = pg_ghg[2].subset[subind][1] + n2o
                            pg_ghg[3].subset[subind][1] = pg_ghg[3].subset[subind][1] + others

                        }

                    })
                }
                if (fgitem.dcf === 36) {
                    console.log(fgitem)
                }
            }
        })

        if (document.getElementById('fggasfilled') !== null) {
            if (fg_ghg.length === 0) {
                showNoDataFound("fggasfilled", "No Data to show");
                showNoDataFound("fggasfilledm", "No Data to show");

                showNoDataFound("fgghg", "No Data to show");

            } else {
                showBarChart(fg_gasfilledm, "fggasfilledm", {
                    name: "Refrigerant Refilled by Month",
                    measure: " kg",
                })
                showBarChart(fg_gasfilled, "fggasfilled", {
                    name: "Refrigerant Refilled by Gas",
                    measure: " kg",
                })
                showBarChart(fg_ghg, "fgghg", {
                    name: "GHG ",
                    measure: " tco2e",
                })

            }
        }
        if (document.getElementById('ckused') !== null) {
            if (ck_ghg.length === 0) {
                showNoDataFound("ckused", "No Data to show");
                showNoDataFound("ckghg", "No Data to show");

            } else {
                showBarChart(ck_used, "ckused", {
                    name: "Production",
                    measure: " kg",
                })
                showBarChart(ck_ghg, "ckghg", {
                    name: "GHG ",
                    measure: " tco2e",
                })
            }
        }
        if (document.getElementById('scghg') !== null) {
            if (sc_ghg.length === 0) {

                showNoDataFound("scghg", "No Data to show");

            } else {
                showBarChart(sc_ghg, "scghg", {
                    name: "GHG ",
                    measure: " tco2e",
                })
            }
        }
        if (document.getElementById('peghg') !== null) {
            if (pe_ghg.length === 0) {
                showNoDataFound("peused", "No Data to show");
                showNoDataFound("peghg", "No Data to show");
                showNoDataFound("peusedm", "No Data to show");

            } else {
                showBarChart(pe_usedm, "peusedm", {
                    name: "Quantity usage by month",
                    measure: " kwh",
                })
                showBarChart(pe_used, "peused", {
                    name: "Quantity usage by source",
                    measure: " kwh",
                })
                showBarChart(pe_ghg, "peghg", {
                    name: "GHG ",
                    measure: " tco2e",
                })
            }
        }
        if (document.getElementById('pgghg') !== null) {
            if (pg_ghg.length === 0) {
                showNoDataFound("pgclass", "No Data to show");
                showNoDataFound("pgghg", "No Data to show");


            } else {
                showBarChart(pg_class, "pgclass", {
                    name: "Quantity usage by month",
                    measure: " usd",
                })
                showBarChart(pg_ghg, "pgghg", {
                    name: "GHG ",
                    measure: " tco2e",
                })

            }
        }

    }
    const onFilterChanged = (obj, val) => {
        let loc = filter
        loc[obj] = val
        let ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]


        if (obj === 'country') {
            siteList.forEach((country) => {
                if (val.id === 0 || val.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        ccopt.push(city)
                        city.locationThrees.forEach((loc, ind) => {

                            locopt.push(loc)

                        })
                    })
                }
            })
            loc.city = { name: 'All', id: 0 }
            loc.location = { name: 'All', id: 0 }
            setCityOpt(ccopt)

            setLocOpt(locopt)
            renderGraph({ location: { a: val, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, year: filter.year, month: filter.month })
        } else if (obj === 'city') {
            siteList.forEach((country) => {
                if (loc.country.id === 0 || loc.country.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        if (val.id === 0 || val.id === city.id) {
                            city.locationThrees.forEach((loc, ind) => {

                                locopt.push(loc)

                            })
                        }
                    })
                }
            })

            loc.location = { name: 'All', id: 0 }


            setLocOpt(locopt)
            renderGraph({ location: { a: filter.country, b: val, c: { name: 'All', id: 0 } }, year: filter.year, month: filter.month })

        } else if (obj === 'location') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: val }, year: filter.year, month: filter.month })

        } else if (obj === 'year') {
            loc.month = { name: 'All', id: 0 }
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: val, month: filter.month })

        } else if (obj === 'month') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: val })

        } else if (obj === 'indicator') {
            let indi = []
            console.log(indicators)
            indiopt.forEach((item) => {
                console.log(item.id, val.id)
                if (val.id === 0 || val.id === item.id) {
                    indi.push(item)
                }
            })
            setInidicators(indi)
            setTimeout(() => {
                renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: val })
                forceUpdate()
            }, 1000)

        }
        setFilter(loc)
        forceUpdate()

    }
    const renderTopic = (item) => {

        if (item.id === 116) {

            return (<div id={item.id}>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    {item.title}
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="fggasfilledm" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="fggasfilled" />
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="fgghg" />
                        </div>
                    </div>

                </div>

            </div>)
        } if (item.id === 140) {
            return (<div id={item.id}>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    {item.title}
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="ckused" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="ckghg" />
                        </div>
                    </div>
                </div>

            </div>)
        } if (item.id === 93) {
            return (<div id={item.id}>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    {item.title}
                </div>
                <div className="grid">
                    {/* <div className="col-6">
                        <div className="card">
                            <canvas id="ckused" />
                        </div>
                    </div> */}
                    <div className="col-6">
                        <div className="card">
                            <canvas id="scghg" />
                        </div>
                    </div>
                </div>

            </div>)
        } if (item.id === 121) {
            return (<div id={item.id}>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    {item.title}
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="peusedm" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="peused" />
                        </div>
                    </div>
                </div>
                <div className="grid">

                    <div className="col-6">
                        <div className="card">
                            <canvas id="peghg" />
                        </div>
                    </div>
                </div>

            </div>)
        } if (item.id === 122) {
            return (<div id={item.id}>
                <div
                    className="col-12"
                    style={{
                        background: "rgba(49,89,117,255)",
                        padding: 10,
                        marginBottom: 10,
                        color: "white",
                        borderRadius: 5,
                    }}
                >
                    {item.title}
                </div>
                <div className="grid">
                    <div className="col-6">
                        <div className="card">
                            <canvas id="pgclass" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <canvas id="pgghg" />
                        </div>
                    </div>
                </div>


            </div>)
        }
    }
    const searchOverDue = (e) => {
        let loc = JSON.parse(JSON.stringify(savedPast.filter((l) => { return l.overdue > 0 && (l.draft === undefined || (l.draft !== undefined && l.draft.type !== 3)) })))
        loc.forEach((item) => {
            item.sit = locationList.filter((l) => { return l.id === item.site[0] }).length !== 0 ? locationList.filter((l) => { return l.id === item.site[0] })[0].name : null

            if (item.draft !== undefined) {
                if (item.draft.type === 1) {
                    item.res = userList.filter((l) => { return l.id === item.user }).length !== 0 ? userList.filter((l) => { return l.id === item.user })[0].information.empname : null

                } else {

                    item.res = userList.filter((l) => { return l.id === item.approver }).length !== 0 ? userList.filter((l) => { return l.id === item.approver })[0].information.empname : null
                }
            } else {
                item.res = userList.filter((l) => { return l.id === item.approver }).length !== 0 ? userList.filter((l) => { return l.id === item.approver })[0].information.empname : null
                //  item.res = userList.filter((l) => { return l.id === item.user }).length !== 0 ? userList.filter((l) => { return l.id === item.user })[0].information.empname : null
            }
        })
        console.log(loc)
        setSearch(e.target.value)
        setPast(loc.filter((k) => { return (k.sit.toLowerCase().includes(e.target.value.toLowerCase()) || k.res.toLowerCase().includes(e.target.value.toLowerCase()) || k.dcf.title.toLowerCase().includes(e.target.value.toLowerCase())) }))
        forceUpdate()
    }
    return (
        <div>
            <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>Data Collection Status</h4>

            <div className="grid">
                {selector.role === "clientadmin" ? (
                    <>
                        <div className="col-12 lg:col-6 xl:col-3" style={{ width: '20%' }} >
                            <div className="card mb-0" style={{ padding: 0 }}>
                                <div className="flex col-12 " style={{ height: 126, alignItems: 'center' }}>
                                    <div className="col-8">
                                        <span className="block text-500 font-medium mb-3">DCF Assignment</span>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: 'white' }}>2023</span>
                                            <span style={{ color: 'white' }}>2023</span>
                                        </div>


                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center  border-round col-4"

                                    >
                                        <span className="text-green-500 font-medium" style={{ fontSize: 30 }}> {dcfasscount}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-3" style={{ width: '20%' }} >
                            <div className="card mb-0" style={{ padding: 0 }}>
                                <div className="flex  col-12" style={{ height: 126, alignItems: 'center' }}>
                                    <div className="col-8">
                                        <span className="block text-500 font-medium mb-3">DCF Submitted & Approved</span>


                                        <span className="text-500"> {moment(moment.utc()).year() - 1} </span>
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center  border-round col-4"

                                    >
                                        <span className="text-green-500 font-medium" style={{
                                            fontSize: 30
                                        }}> {getYearSubmission(moment(moment.utc()).year() - 1) === 'NaN%' ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }}></i> : getYearSubmission(moment(moment.utc()).year() - 1)}</span>

                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-3" style={{ width: '20%' }}>
                            <div className="card mb-0" style={{ padding: 0 }}>
                                <div className="flex col-12 " style={{ height: 126, alignItems: 'center' }}>
                                    <div className="col-8">
                                        <span className="block text-500 font-medium mb-3">DCF Submitted & Approved </span>

                                        <span className=" text-500 ">{moment(moment.utc().startOf('month')).subtract(2, 'month').format(`MMMM'YY`)}  </span> {/* <div className="text-900 font-medium text-xl">$2.100</div> */}
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center  border-round col-4"

                                    >
                                        <span className="text-green-500 font-medium" style={{ fontSize: 30 }}> {getMonthSubmission(moment(moment.utc().startOf('month')).subtract(2, 'month').format('MMM-YYYY')) === 'NaN%' ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }}></i> : getMonthSubmission(moment(moment.utc().startOf('month')).subtract(2, 'month').format('MMM-YYYY'))}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-3" style={{ width: '20%' }}>
                            <div className="card mb-0" style={{ padding: 0 }}>
                                <div className="flex col-12 " style={{ height: 126, alignItems: 'center' }}>
                                    <div className="col-8">
                                        <span className="block text-500 font-medium mb-3">DCF Submitted & Approved </span>

                                        <span className=" text-500 ">{moment(moment.utc().startOf('month')).subtract(1, 'month').format(`MMMM'YY`)}   </span> {/* <div className="text-900 font-medium text-xl">$2.100</div> */}
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center  border-round col-4"

                                    >
                                        <span className="text-green-500 font-medium" style={{ fontSize: 30 }}> {getMonthSubmission(moment(moment.utc().startOf('month')).subtract(1, 'month').format('MMM-YYYY')) === 'NaN%' ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }}></i> : getMonthSubmission(moment(moment.utc().startOf('month')).subtract(1, 'month').format('MMM-YYYY'))}</span>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-6 xl:col-3" style={{ width: '20%' }}>
                            <div className="card mb-0" style={{ padding: 0 }}>
                                <div className="flex col-12 " style={{ height: 126, alignItems: 'center' }}>
                                    <div className="col-8">
                                        <span className="block text-500 font-medium mb-3">DCF Submitted & Approved </span>

                                        <span className=" text-500 ">{moment(moment.utc().startOf('month')).subtract(0, 'month').format(`MMMM'YY`)}  </span> {/* <div className="text-900 font-medium text-xl">$2.100</div> */}
                                    </div>
                                    <div
                                        className="flex align-items-center justify-content-center  border-round col-4"

                                    >
                                        <span className="text-green-500 font-medium" style={{ fontSize: 30 }}> {getMonthSubmission(moment(moment.utc().startOf('month')).subtract(0, 'month').format('MMM-YYYY')) === 'NaN%' ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }}></i> : getMonthSubmission(moment(moment.utc().startOf('month')).subtract(0, 'month').format('MMM-YYYY'))}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {past.length === null ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
                                className="col-12"><i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i> </div>

                            : <div className="col-12 xl:col-12" id="divsize">
                                <div className="card">
                                    <div className="col-12 xl:col-12" style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                        <label style={{ fontWeight: 'bold' }}>Overdue Submissions - {past.length}</label>
                                        <div style={{ position: 'absolute', right: 10, top: 0, cursor: 'pointer' }} onClick={() => { dispatch(resetOverallPendingData([])); setPast((prev) => { return [] }); setTimeout(() => { renderTable(1) }, 1000) }} ><i className="material-icons">refresh</i></div>
                                    </div>
                                    <div className="col-6" style={{
                                        alignItems: 'center',
                                        justifyContent: 'flex-start'
                                    }} >

                                        <span className="p-input-icon-left" >
                                            <i className="pi pi-search" />
                                            <InputText value={search} onChange={searchOverDue} style={{ width: 300 }} placeholder="Search by DCF/Site/User" />
                                        </span>
                                    </div>
                                    <DataTable
                                        value={past}
                                        style={{
                                            padding: 10,
                                            minHeight: 150,
                                            maxHeight: 350,
                                            overflow: 'scroll'
                                        }}
                                        showGridlines

                                        responsiveLayout="scroll"
                                    >

                                        <Column header="DCF" field='dcf.title'> </Column>
                                        <Column header="Site" body={(rowData) => { let site = locationList.filter((i) => { return i.id === rowData.site[0] })[0].title; return <span>{site}</span> }} field='site'> </Column>
                                        <Column header="Reporting Period" field='rp'> </Column>
                                        <Column header="Overdue Days" field='overdue'> </Column>
                                        <Column header="Responsibility" body={responsibilityTemplate} field='responsibility'> </Column>

                                        <Column
                                            header="Action"
                                            body={remindertemplate}

                                        >

                                        </Column>



                                    </DataTable>
                                </div>
                            </div>
                        }
                        {/* {response.length !== 0 && selector.id === 17 && <>
                            <div className="col-12 sticky-div">
                                <div className="card">
                                    <div
                                        style={{
                                            marginBottom: 20,
                                        }}
                                    >
                                        <h5> Filter Section </h5>
                                    </div>
                                    <div className="grid">
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> Country </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.country}
                                                onChange={(e) => onFilterChanged("country", e.value)}
                                                options={countopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> City </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.city}
                                                onChange={(e) => onFilterChanged("city", e.value)}
                                                options={cityopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> Location </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.location}
                                                onChange={(e) => onFilterChanged("location", e.value)}
                                                options={locopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <h6> Indicator </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.indicator}
                                                onChange={(e) => onFilterChanged("indicator", e.value)}
                                                options={indiopt}
                                                optionLabel="title"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <h6> Year </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.year}
                                                onChange={(e) => onFilterChanged("year", e.value)}
                                                options={yearopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <h6> Month </h6>
                                            <Dropdown
                                                style={{
                                                    width: "90%",
                                                }}
                                                value={filter.month}
                                                onChange={(e) => onFilterChanged("month", e.value)}
                                                options={monthopt}
                                                optionLabel="name"
                                                placeholder="Select"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 sticky-div">
                                <div className="card">
                                    {indicators.map((item) => {
                                        return (<div>
                                            {renderTopic(item)}
                                        </div>)
                                    })

                                    }
                                </div>
                            </div>
                        </>
                        } */}
                        {/* <div className="col-12 xl:col-6">
                            <div className="card">
                                <div className="flex align-items-center justify-content-between mb-4">
                                    <h5> Approved Data </h5>
                                </div>
                                {activity.approved.length !== 0 ? (
                                    <div
                                        style={{
                                            height: 300,
                                            overflow: "scroll",
                                        }}
                                    >

                                        {activity.approved.map((item) => {
                                            return (
                                                <div>
                                                    <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                                                        <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"> {item.approved_by[0]} </div>
                                                        <span className="text-900 line-height-3">

                                                            {item.approved_by} &nbsp;
                                                            <span className="text-700">

                                                                approved &nbsp;
                                                                <span
                                                                    style={{
                                                                        color: "green",
                                                                    }}
                                                                >

                                                                    {item.topic} &nbsp;
                                                                </span>
                                                                for the location &nbsp;
                                                                <span className="text-blue-500">
                                                                    {item.location}({item.city}) &nbsp;
                                                                </span>
                                                                at {item.final_approved_on === undefined ? moment(item.approved_on).format("DD-MM-YYYY HH:SS") : moment(item.final_approved_on).format("DD-MM-YYYY HH:SS")}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    "No data found"
                                )}
                            </div>
                        </div>
                        <div className="col-12 xl:col-6">
                            <div className="card">
                                <div className="flex align-items-center justify-content-between mb-4">
                                    <h5> Pending Approval </h5>
                                </div>
                                {activity.pending.length !== 0 ? (
                                    <div
                                        style={{
                                            height: 300,
                                            overflow: "scroll",
                                        }}
                                    >

                                        {activity.pending.map((item) => {
                                            return (
                                                <div>
                                                    <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                                                        <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"> {item.created_by[0]} </div>
                                                        <span className="text-900 line-height-3">

                                                            {item.created_by} &nbsp;
                                                            <span className="text-700">

                                                                submitted &nbsp;
                                                                <span
                                                                    style={{
                                                                        color: "green",
                                                                    }}
                                                                >

                                                                    {item.topic} &nbsp;
                                                                </span>
                                                                for the location
                                                                <span className="text-blue-500">
                                                                    {item.location}({item.city}) &nbsp;
                                                                </span>
                                                                at {moment(item.created_on).format("DD-MM-YYYY HH:SS")}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </div>
                                            );
                                        })}

                                    </div>
                                ) : (
                                    "No data found"
                                )}
                            </div>
                        </div> */}


                    </>
                ) : (
                    <div className="col-12 card">You have no rights to access this page </div>
                )}
            </div>
        </div >
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(AdminCCDashboard, comparisonFn);
