import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const data = [
    { 
        waste: 'Hazardous waste generated (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste diverted from disposal (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste incinerated with energy recovery (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste incinerated without energy recovery (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste landfilled (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste other treatment (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste diverted from disposal (%)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Non-hazardous waste generated (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Non-hazardous waste diverted from disposal (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Non-hazardous waste incinerated with energy recovery (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Non-hazardous waste incinerated without energy recovery (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Non-hazardous waste landfilled (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Non-hazardous waste other treatment (million kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        waste: 'Hazardous waste diverted from disposal (%)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    
];

const WasteDataTable = () => {
    const tableStyle = { 
        borderRadius: '10px',
        overflow: 'hidden',
        border: '1px solid #EBEDEF', // Set border color to grey
        fontSize: '16px',
        fontWeight: '400'
    };

    const customWasteCell = (rowData, column) => {
        let padding = 'initial';
        if(rowData.waste === 'Hazardous waste diverted from disposal (million kg)' ||
        rowData.waste === 'Hazardous waste incinerated with energy recovery (million kg)' ||
        rowData.waste === 'Hazardous waste incinerated without energy recovery (million kg)' ||
        rowData.waste === 'Hazardous waste landfilled (million kg)' ||
        rowData.waste === 'Hazardous waste other treatment (million kg)' ||
        rowData.waste === 'Non-hazardous waste diverted from disposal (million kg)' ||
        rowData.waste === 'Non-hazardous waste incinerated with energy recovery (million kg)' ||
        rowData.waste === 'Non-hazardous waste incinerated without energy recovery (million kg)' ||
        rowData.waste === 'Non-hazardous waste landfilled (million kg)' ||
        rowData.waste === 'Non-hazardous waste other treatment (million kg)') {
            padding = '20px';
        }
        return <td style={{ paddingLeft: padding }}>{rowData.waste}</td>;
    };

    return (
        <DataTable value={data} style={tableStyle}>
            <Column field="waste" header="Waste"  body={customWasteCell}/>
            <Column field="2021" header="2021" />
            <Column field="2022" header="2022" />
            <Column field="2020One" header="2020" />
            <Column field="2020Two" header="2020" />
            <Column field="2020Three" header="2020" />
            <Column field="2020Four" header="2020" />
            <Column field="2020Five" header="2020" />
            <Column field="GRI Indicator" header="GRI Indicator" />
        </DataTable>
    );
}

export default WasteDataTable;
