import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { STANDARDS_URL } from "../constants";

const StandardSelect = (props) => {

    const [optionsValue, setOptionsValue] = useState([])
    
    useEffect(() => {
        getOptions()
    }, [])

    const  getOptions = async () => {
        const response = await fetch(STANDARDS_URL);
        if(response.ok) {
          const data = await response.json();
            setOptionsValue(data.map((i) => {
                return {label: i.name, value: i.id}

            }))
          //   setTopics(data)
        }
      }

    const handleChange = (newValue, actionMeta) => {

      
        props.onChangeValues(newValue);
       
        
    }

    const handleAddOptions = async (optionValue) => {
        
        const response = await fetch(STANDARDS_URL, {
          method: 'POST',
          body: JSON.stringify({
            name: optionValue,
            editable: 1,
            sasb: 1
           
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
  
        const data = await response.json()
        const newData = { label: data.name, value: data.id};
        setOptionsValue((prevState) => [...prevState, newData])

       
    }

    return (
        <>
            <CreatableSelect
                    isMulti
                    onChange={handleChange}
                    onCreateOption={handleAddOptions}
                    value={props.values}
                    options={optionsValue}
                  />
        </>
    )
}

export default StandardSelect;