import React, { useState, useRef } from "react";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { CustomLegend } from "./Dashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomRefrigerantLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 1 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const refrigerantData = {
  2019: [
    { month: "Jan", hfc134a: 45, hfc32: 85 },
    { month: "Feb", hfc134a: 70, hfc32: 95 },
    { month: "Mar", hfc134a: 55, hfc32: 65 },
    { month: "Apr", hfc134a: 80, hfc32: 60 },
    { month: "May", hfc134a: 65, hfc32: 75 },
    { month: "Jun", hfc134a: 90, hfc32: 85 },
    { month: "Jul", hfc134a: 40, hfc32: 100 },
    { month: "Aug", hfc134a: 95, hfc32: 70 },
    { month: "Sep", hfc134a: 55, hfc32: 90 },
    { month: "Oct", hfc134a: 80, hfc32: 55 },
    { month: "Nov", hfc134a: 60, hfc32: 85 },
    { month: "Dec", hfc134a: 85, hfc32: 60 },
  ],
  2020: [
    { month: "Jan", hfc134a: 75, hfc32: 55 },
    { month: "Feb", hfc134a: 95, hfc32: 65 },
    { month: "Mar", hfc134a: 60, hfc32: 90 },
    { month: "Apr", hfc134a: 80, hfc32: 75 },
    { month: "May", hfc134a: 65, hfc32: 110 },
    { month: "Jun", hfc134a: 55, hfc32: 80 },
    { month: "Jul", hfc134a: 100, hfc32: 70 },
    { month: "Aug", hfc134a: 90, hfc32: 60 },
    { month: "Sep", hfc134a: 85, hfc32: 50 },
    { month: "Oct", hfc134a: 65, hfc32: 95 },
    { month: "Nov", hfc134a: 70, hfc32: 85 },
    { month: "Dec", hfc134a: 105, hfc32: 60 },
  ],
  2021: [
    { month: "Jan", hfc134a: 85, hfc32: 90 },
    { month: "Feb", hfc134a: 60, hfc32: 75 },
    { month: "Mar", hfc134a: 110, hfc32: 55 },
    { month: "Apr", hfc134a: 95, hfc32: 60 },
    { month: "May", hfc134a: 80, hfc32: 105 },
    { month: "Jun", hfc134a: 65, hfc32: 90 },
    { month: "Jul", hfc134a: 55, hfc32: 75 },
    { month: "Aug", hfc134a: 70, hfc32: 85 },
    { month: "Sep", hfc134a: 95, hfc32: 100 },
    { month: "Oct", hfc134a: 60, hfc32: 90 },
    { month: "Nov", hfc134a: 85, hfc32: 110 },
    { month: "Dec", hfc134a: 75, hfc32: 95 },
  ],
  2022: [
    { month: "Jan", hfc134a: 90, hfc32: 85 },
    { month: "Feb", hfc134a: 55, hfc32: 105 },
    { month: "Mar", hfc134a: 75, hfc32: 95 },
    { month: "Apr", hfc134a: 110, hfc32: 80 },
    { month: "May", hfc134a: 70, hfc32: 60 },
    { month: "Jun", hfc134a: 85, hfc32: 75 },
    { month: "Jul", hfc134a: 90, hfc32: 100 },
    { month: "Aug", hfc134a: 95, hfc32: 55 },
    { month: "Sep", hfc134a: 65, hfc32: 85 },
    { month: "Oct", hfc134a: 75, hfc32: 105 },
    { month: "Nov", hfc134a: 100, hfc32: 65 },
    { month: "Dec", hfc134a: 55, hfc32: 120 },
  ],
  2023: [
    { month: "Jan", hfc134a: 60, hfc32: 105 },
    { month: "Feb", hfc134a: 90, hfc32: 95 },
    { month: "Mar", hfc134a: 110, hfc32: 85 },
    { month: "Apr", hfc134a: 80, hfc32: 75 },
    { month: "May", hfc134a: 85, hfc32: 105 },
    { month: "Jun", hfc134a: 105, hfc32: 55 },
    { month: "Jul", hfc134a: 100, hfc32: 60 },
    { month: "Aug", hfc134a: 90, hfc32: 70 },
    { month: "Sep", hfc134a: 65, hfc32: 115 },
    { month: "Oct", hfc134a: 95, hfc32: 90 },
    { month: "Nov", hfc134a: 70, hfc32: 100 },
    { month: "Dec", hfc134a: 110, hfc32: 80 },
  ],
  2024: [
    { month: "Jan", hfc134a: 85, hfc32: 120 },
    { month: "Feb", hfc134a: 65, hfc32: 90 },
    { month: "Mar", hfc134a: 95, hfc32: 105 },
    { month: "Apr", hfc134a: 110, hfc32: 65 },
    { month: "May", hfc134a: 80, hfc32: 130 },
    { month: "Jun", hfc134a: 105, hfc32: 55 },
    { month: "Jul", hfc134a: 115, hfc32: 70 },
    { month: "Aug", hfc134a: 75, hfc32: 120 },
    { month: "Sep", hfc134a: 90, hfc32: 95 },
    { month: "Oct", hfc134a: 115, hfc32: 85 },
    { month: "Nov", hfc134a: 80, hfc32: 115 },
    { month: "Dec", hfc134a: 130, hfc32: 65 },
  ],
};

const RefrigerantChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = refrigerantData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} Hfc134a`]: item.hfc134a,
    [`${year1} Hfc32`]: item.hfc32,
    [`${year2} Hfc134a`]: refrigerantData[year2][index].hfc134a,
    [`${year2} Hfc32`]: refrigerantData[year2][index].hfc32,
  }));
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Fugitive emissions
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Quantity of refrigerant refilled in the reporting year.
          </div>
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(refrigerantData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(refrigerantData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100.1%" height={400}>
          <BarChart
            data={combinedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barGap={5}
            barSize={30}
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: "tCO2",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />{" "}
            <Tooltip />
            <Legend content={CustomRefrigerantLegend} />
            <Bar
              dataKey={`${year1} Hfc134a`}
              stackId="a"
              fill="#4569B2"
              name="HFC134A"
            ></Bar>
            <Bar
              dataKey={`${year1} Hfc32`}
              stackId="a"
              fill="#BDD1F7"
              name="HFC32"
            ></Bar>
            <Bar dataKey={`${year2} Hfc134a`} stackId="b" fill="#4569B2"></Bar>
            <Bar dataKey={`${year2} Hfc32`} stackId="b" fill="#BDD1F7">
              <LabelList dataKey="hfc32" position="top" fill="black" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={refrigerantData[year1].map((item, index) => ({
              ...item,
              [`hfc134a_${year2}`]: refrigerantData[year2][index].hfc134a,
              [`hfc32_${year2}`]: refrigerantData[year2][index].hfc32,
            }))}
            tableClassName="font-lato"
            style={{ width: "100%" }}
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`HFC134A - ${year1}  (Millions GJ)`}
              style={{ minWidth: "8%" }}
              field="hfc134a"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`HFC32 - ${year2} (Millions GJ)`}
              style={{ minWidth: "8%" }}
              field="hfc32"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`HFC134A - ${year2}  (Millions GJ)`}
              style={{ minWidth: "8%" }}
              field={`hfc134a_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`HFC32 - ${year1}  (Millions GJ)`}
              style={{ minWidth: "8%" }}
              field={`hfc32_${year2}`}
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
export default RefrigerantChart;
