import React from "react";

function PageTitle (props) {
    return (
        <h3 className="page-title">
            {props.title}
        </h3>
    );
}

export default PageTitle;