import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const waterWithrawaldata = [
  {
    year: "2019",
    surface: 20,
    seawater: 30,
    thirdParty: 30,
    groundWater: 20,
    ownProducedWater: 20,
    total: 120,
  },
  {
    year: "2020",
    surface: 10,
    seawater: 30,
    thirdParty: 40,
    groundWater: 20,
    ownProducedWater: 50,
    total: 150,
  },
  {
    year: "2021",
    surface: 20,
    seawater: 20,
    thirdParty: 20,
    groundWater: 30,
    ownProducedWater: 20,
    total: 110,
  },
  {
    year: "2022",
    surface: 20,
    seawater: 30,
    thirdParty: 30,
    groundWater: 20,
    ownProducedWater: 20,
    total: 120,
  },
  {
    year: "2023",
    surface: 40,
    seawater: 30,
    thirdParty: 30,
    groundWater: 30,
    ownProducedWater: 30,
    total: 160,
  },
];

const renderCustomLegend = (props) => {
  const { payload } = props;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 15em)",
        gap: 10,
        color: "#4A4A4A",
        position: "relative",
        top: -200,
        right: -800,
      }}
    >
      {payload.map((entry, index) => (
        <div key={`item-${index}`} style={{ color: "black", fontSize: "14px" }}>
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          {entry.value}
        </div>
      ))}
    </div>
  );
};

const WaterWithdrawalChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Water withdrawal by source
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          />
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
        </div>
      </div>
      {activeMode && (
        <div style={{ overflow: "hidden" }}>
          <ResponsiveContainer height={500} width={700}>
            <ComposedChart data={waterWithrawaldata}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="surface"
                stackId="a"
                fill="#BDD1F7"
                name="Surface water"
                barSize={60}
              />
              <Bar
                dataKey="seawater"
                stackId="a"
                fill="#8FC3E8"
                name="Seawater"
              />
              <Bar
                dataKey="thirdParty"
                stackId="a"
                fill="#5B8FF7"
                name="Third party water"
              />
              <Bar
                dataKey="ownProducedWater"
                stackId="a"
                fill="#4569B2"
                name="Ground water"
              />
              <Bar
                dataKey="groundWater"
                stackId="a"
                fill="#C5DAE9"
                name="Ground water"
              />

              <Legend content={renderCustomLegend} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={waterWithrawaldata}
            tableClassName="font-lato"
          >
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Surface"
              style={{ minWidth: "8%" }}
              field="surface"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Sea Water"
              style={{ minWidth: "8%" }}
              field="seawater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Third Party"
              style={{ minWidth: "8%" }}
              field="thirdParty"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Ground Water"
              style={{ minWidth: "8%" }}
              field="groundWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Own Produced Water"
              style={{ minWidth: "8%" }}
              field="ownProducedWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Total"
              style={{ minWidth: "8%" }}
              field="total"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default WaterWithdrawalChart;
