import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  ResponsiveContainer,
} from "recharts";

const CustomScopeLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 3 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const scopeData = {
  2019: [
    {
      month: "Jan",
      indirectCO2e: 0.5,
      directCO2: 20.2,
      directCH4: 1.4,
      directN2O: 0.9,
      totalCO2e: 22.5,
    },
    {
      month: "Feb",
      indirectCO2e: 0.5,
      directCO2: 19.8,
      directCH4: 1.3,
      directN2O: 0.8,
      totalCO2e: 21.9,
    },
    {
      month: "Mar",
      indirectCO2e: 0.5,
      directCO2: 20.0,
      directCH4: 1.4,
      directN2O: 0.9,
      totalCO2e: 22.3,
    },
    {
      month: "Apr",
      indirectCO2e: 0.5,
      directCO2: 20.3,
      directCH4: 1.5,
      directN2O: 0.9,
      totalCO2e: 22.7,
    },
    {
      month: "May",
      indirectCO2e: 0.5,
      directCO2: 20.1,
      directCH4: 1.4,
      directN2O: 0.8,
      totalCO2e: 22.3,
    },
    {
      month: "Jun",
      indirectCO2e: 0.5,
      directCO2: 19.9,
      directCH4: 1.3,
      directN2O: 0.9,
      totalCO2e: 21.8,
    },
    {
      month: "Jul",
      indirectCO2e: 0.5,
      directCO2: 20.0,
      directCH4: 1.5,
      directN2O: 0.8,
      totalCO2e: 22.3,
    },
    {
      month: "Aug",
      indirectCO2e: 0.5,
      directCO2: 20.2,
      directCH4: 1.4,
      directN2O: 0.8,
      totalCO2e: 22.4,
    },
    {
      month: "Sep",
      indirectCO2e: 0.5,
      directCO2: 19.9,
      directCH4: 1.4,
      directN2O: 0.8,
      totalCO2e: 21.9,
    },
    {
      month: "Oct",
      indirectCO2e: 0.5,
      directCO2: 20.3,
      directCH4: 1.5,
      directN2O: 0.9,
      totalCO2e: 22.7,
    },
    {
      month: "Nov",
      indirectCO2e: 0.5,
      directCO2: 20.0,
      directCH4: 1.4,
      directN2O: 0.9,
      totalCO2e: 22.3,
    },
    {
      month: "Dec",
      indirectCO2e: 0.5,
      directCO2: 19.8,
      directCH4: 1.3,
      directN2O: 0.8,
      totalCO2e: 21.9,
    },
  ],
  2020: [
    {
      month: "Jan",
      indirectCO2e: 0.5,
      directCO2: 19.5,
      directCH4: 1.4,
      directN2O: 0.8,
      totalCO2e: 21.8,
    },
    {
      month: "Feb",
      indirectCO2e: 0.5,
      directCO2: 19.2,
      directCH4: 1.3,
      directN2O: 0.8,
      totalCO2e: 21.3,
    },
    {
      month: "Mar",
      indirectCO2e: 0.5,
      directCO2: 18.8,
      directCH4: 1.2,
      directN2O: 0.7,
      totalCO2e: 20.7,
    },
    {
      month: "Apr",
      indirectCO2e: 0.5,
      directCO2: 19.3,
      directCH4: 1.4,
      directN2O: 0.8,
      totalCO2e: 21.5,
    },
    {
      month: "May",
      indirectCO2e: 0.5,
      directCO2: 19.1,
      directCH4: 1.3,
      directN2O: 0.7,
      totalCO2e: 21.2,
    },
    {
      month: "Jun",
      indirectCO2e: 0.5,
      directCO2: 18.9,
      directCH4: 1.2,
      directN2O: 0.8,
      totalCO2e: 20.9,
    },
    {
      month: "Jul",
      indirectCO2e: 0.5,
      directCO2: 19.4,
      directCH4: 1.3,
      directN2O: 0.8,
      totalCO2e: 21.5,
    },
    {
      month: "Aug",
      indirectCO2e: 0.5,
      directCO2: 19.6,
      directCH4: 1.4,
      directN2O: 0.7,
      totalCO2e: 21.7,
    },
    {
      month: "Sep",
      indirectCO2e: 0.5,
      directCO2: 18.8,
      directCH4: 1.3,
      directN2O: 0.7,
      totalCO2e: 20.8,
    },
    {
      month: "Oct",
      indirectCO2e: 0.5,
      directCO2: 19.1,
      directCH4: 1.2,
      directN2O: 0.8,
      totalCO2e: 21.1,
    },
    {
      month: "Nov",
      indirectCO2e: 0.5,
      directCO2: 18.7,
      directCH4: 1.2,
      directN2O: 0.7,
      totalCO2e: 20.6,
    },
    {
      month: "Dec",
      indirectCO2e: 0.5,
      directCO2: 18.9,
      directCH4: 1.3,
      directN2O: 0.8,
      totalCO2e: 20.8,
    },
  ],
  2021: [
    {
      month: "Jan",
      indirectCO2e: 0.4,
      directCO2: 17.2,
      directCH4: 1.3,
      directN2O: 0.7,
      totalCO2e: 19.6,
    },
    {
      month: "Feb",
      indirectCO2e: 0.4,
      directCO2: 16.9,
      directCH4: 1.2,
      directN2O: 0.6,
      totalCO2e: 19.1,
    },
    {
      month: "Mar",
      indirectCO2e: 0.4,
      directCO2: 17.1,
      directCH4: 1.3,
      directN2O: 0.7,
      totalCO2e: 19.5,
    },
    {
      month: "Apr",
      indirectCO2e: 0.4,
      directCO2: 17.4,
      directCH4: 1.4,
      directN2O: 0.6,
      totalCO2e: 19.8,
    },
    {
      month: "May",
      indirectCO2e: 0.4,
      directCO2: 17.0,
      directCH4: 1.2,
      directN2O: 0.6,
      totalCO2e: 19.2,
    },
    {
      month: "Jun",
      indirectCO2e: 0.4,
      directCO2: 16.8,
      directCH4: 1.3,
      directN2O: 0.7,
      totalCO2e: 19.1,
    },
    {
      month: "Jul",
      indirectCO2e: 0.4,
      directCO2: 17.2,
      directCH4: 1.4,
      directN2O: 0.7,
      totalCO2e: 19.7,
    },
    {
      month: "Aug",
      indirectCO2e: 0.4,
      directCO2: 17.3,
      directCH4: 1.2,
      directN2O: 0.6,
      totalCO2e: 19.5,
    },
    {
      month: "Sep",
      indirectCO2e: 0.4,
      directCO2: 16.9,
      directCH4: 1.3,
      directN2O: 0.6,
      totalCO2e: 19.2,
    },
    {
      month: "Oct",
      indirectCO2e: 0.4,
      directCO2: 17.0,
      directCH4: 1.4,
      directN2O: 0.7,
      totalCO2e: 19.5,
    },
    {
      month: "Nov",
      indirectCO2e: 0.4,
      directCO2: 16.7,
      directCH4: 1.3,
      directN2O: 0.6,
      totalCO2e: 19.0,
    },
    {
      month: "Dec",
      indirectCO2e: 0.4,
      directCO2: 16.5,
      directCH4: 1.2,
      directN2O: 0.7,
      totalCO2e: 18.8,
    },
  ],
  2022: [
    {
      month: "Jan",
      indirectCO2e: 0.4,
      directCO2: 15.3,
      directCH4: 1.2,
      directN2O: 0.5,
      totalCO2e: 17.4,
    },
    {
      month: "Feb",
      indirectCO2e: 0.4,
      directCO2: 15.5,
      directCH4: 1.1,
      directN2O: 0.4,
      totalCO2e: 17.4,
    },
    {
      month: "Mar",
      indirectCO2e: 0.4,
      directCO2: 15.0,
      directCH4: 1.2,
      directN2O: 0.5,
      totalCO2e: 17.1,
    },
    {
      month: "Apr",
      indirectCO2e: 0.4,
      directCO2: 15.2,
      directCH4: 1.1,
      directN2O: 0.5,
      totalCO2e: 17.2,
    },
    {
      month: "May",
      indirectCO2e: 0.4,
      directCO2: 14.8,
      directCH4: 1.0,
      directN2O: 0.5,
      totalCO2e: 16.7,
    },
    {
      month: "Jun",
      indirectCO2e: 0.4,
      directCO2: 14.7,
      directCH4: 1.1,
      directN2O: 0.4,
      totalCO2e: 16.6,
    },
    {
      month: "Jul",
      indirectCO2e: 0.4,
      directCO2: 15.1,
      directCH4: 1.2,
      directN2O: 0.5,
      totalCO2e: 17.2,
    },
    {
      month: "Aug",
      indirectCO2e: 0.4,
      directCO2: 15.4,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 17.2,
    },
    {
      month: "Sep",
      indirectCO2e: 0.4,
      directCO2: 15.0,
      directCH4: 1.1,
      directN2O: 0.4,
      totalCO2e: 16.9,
    },
    {
      month: "Oct",
      indirectCO2e: 0.4,
      directCO2: 15.2,
      directCH4: 1.2,
      directN2O: 0.5,
      totalCO2e: 17.3,
    },
    {
      month: "Nov",
      indirectCO2e: 0.4,
      directCO2: 14.9,
      directCH4: 1.1,
      directN2O: 0.5,
      totalCO2e: 16.9,
    },
    {
      month: "Dec",
      indirectCO2e: 0.4,
      directCO2: 14.6,
      directCH4: 1.0,
      directN2O: 0.5,
      totalCO2e: 16.5,
    },
  ],
  2023: [
    {
      month: "Jan",
      indirectCO2e: 0.4,
      directCO2: 14.3,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 15.7,
    },
    {
      month: "Feb",
      indirectCO2e: 0.4,
      directCO2: 14.5,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 15.9,
    },
    {
      month: "Mar",
      indirectCO2e: 0.4,
      directCO2: 14.1,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 15.5,
    },
    {
      month: "Apr",
      indirectCO2e: 0.4,
      directCO2: 14.2,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 15.6,
    },
    {
      month: "May",
      indirectCO2e: 0.4,
      directCO2: 14.0,
      directCH4: 1.1,
      directN2O: 0.5,
      totalCO2e: 15.9,
    },
    {
      month: "Jun",
      indirectCO2e: 0.4,
      directCO2: 14.3,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 15.7,
    },
    {
      month: "Jul",
      indirectCO2e: 0.4,
      directCO2: 14.5,
      directCH4: 1.1,
      directN2O: 0.4,
      totalCO2e: 15.9,
    },
    {
      month: "Aug",
      indirectCO2e: 0.4,
      directCO2: 14.7,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 16.1,
    },
    {
      month: "Sep",
      indirectCO2e: 0.4,
      directCO2: 14.1,
      directCH4: 1.0,
      directN2O: 0.4,
      totalCO2e: 15.5,
    },
    {
      month: "Oct",
      indirectCO2e: 0.4,
      directCO2: 14.3,
      directCH4: 1.1,
      directN2O: 0.4,
      totalCO2e: 15.8,
    },
    {
      month: "Nov",
      indirectCO2e: 0.4,
      directCO2: 14.0,
      directCH4: 1.0,
      directN2O: 0.5,
      totalCO2e: 15.9,
    },
    {
      month: "Dec",
      indirectCO2e: 0.4,
      directCO2: 14.2,
      directCH4: 1.1,
      directN2O: 0.4,
      totalCO2e: 15.7,
    },
  ],
};

const Scope1Chart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = scopeData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} indirectCO2e`]: item.indirectCO2e,
    [`${year1} directCO2`]: item.directCO2,
    [`${year1} directN2O`]: item.directN2O,
    [`${year1} directCH4`]: item.directCH4,
    [`${year1} totalCO2e`]: item.totalCO2e,
    [`${year2} indirectCO2e`]: scopeData[year2][index].indirectCO2e,
    [`${year2} directCO2e`]: scopeData[year2][index].directCO2e,
    [`${year2} directN2O`]: scopeData[year2][index].directN2O,
    [`${year2} directCH4`]: scopeData[year2][index].directCH4,
    [`${year2} totalCO2e`]: scopeData[year2][index].totalCO2e,
  }));
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Scope 1 Emissions
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Direct emissions from the operations that are owned or controlled.
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(scopeData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(scopeData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={combinedData}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              barSize={30}
              barGap={5}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                label={{
                  value: "Million tCO2e",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend content={CustomScopeLegend} />

              <Bar
                dataKey={`${year1} indirectCO2e`}
                stackId="a"
                fill="#003f5c"
                name="Indirect CO2e"
              />
              <Bar
                dataKey={`${year1} directCO2`}
                stackId="a"
                fill="#58508d"
                name="Direct CO2"
              />
              <Bar
                dataKey={`${year1} directCH4`}
                stackId="a"
                fill="#bc5090"
                name="Direct CH4"
              />
              <Bar
                dataKey={`${year1} directN2O`}
                stackId="a"
                fill="#ff6361"
                name="Direct N2O"
              />

              <Bar
                dataKey={`${year2} indirectCO2e`}
                stackId="b"
                fill="#003f5c"
                name="Indirect CO2e"
              />
              <Bar
                dataKey={`${year2} directCO2`}
                stackId="b"
                fill="#58508d"
                name="Direct CO2"
              />
              <Bar
                dataKey={`${year2} directCH4`}
                stackId="b"
                fill="#bc5090"
                name="Direct CH4"
              />
              <Bar
                dataKey={`${year2} directN2O`}
                stackId="b"
                fill="#ff6361"
                name="Direct N2O"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={scopeData[year1].map((item, index) => ({
              ...item,
              [`indirectCO2e_${year2}`]: scopeData[year2][index].indirectCO2e,
              [`indirectCO2_${year2}`]: scopeData[year2][index].indirectCO2,
              [`indirectCH4_${year2}`]: scopeData[year2][index].indirectCH4,
              [`directN2O_${year2}`]: scopeData[year2][index].directN2O,
              [`totalCO2e_${year2}`]: scopeData[year2][index].totalCO2e,
            }))}
            tableClassName="font-lato"
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`indirectCO2e - ${year1}`}
              style={{ minWidth: "8%" }}
              field="indirectCO2e"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`directCO2 - ${year1}`}
              style={{ minWidth: "8%" }}
              field="directCO2"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`directCH4 - ${year1}`}
              style={{ minWidth: "8%" }}
              field="directCH4"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`directN2O - ${year1}`}
              style={{ minWidth: "8%" }}
              field="directN2O"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`totalCO2e - ${year1}`}
              style={{ minWidth: "8%" }}
              field="totalCO2e"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`indirectCO2e - ${year2}`}
              style={{ minWidth: "8%" }}
              field="indirectCO2e"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`directCO2 - ${year2}`}
              style={{ minWidth: "8%" }}
              field="directCO2"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`directCH4 - ${year2}`}
              style={{ minWidth: "8%" }}
              field="directCH4"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`directN2O - ${year2}`}
              style={{ minWidth: "8%" }}
              field="directN2O"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`totalCO2e - ${year2}`}
              style={{ minWidth: "8%" }}
              field="totalCO2e"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default Scope1Chart;
