import React, { useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const data = [
  {
    year: "2019",
    GovernanceBodies: 50,
    Executives: 25,
    Managers: 95,
    Operators: 20,
    Professionals: 50,
    AdminStaff: 60,
  },
  {
    year: "2020",
    GovernanceBodies: 80,
    Executives: 55,
    Managers: 95,
    Operators: 25,
    Professionals: 75,
    AdminStaff: 70,
  },
  {
    year: "2021",
    GovernanceBodies: 85,
    Executives: 65,
    Managers: 97,
    Operators: 40,
    Professionals: 80,
    AdminStaff: 78,
  },
  {
    year: "2022",
    GovernanceBodies: 90,
    Executives: 70,
    Managers: 98,
    Operators: 50,
    Professionals: 85,
    AdminStaff: 85,
  },
  {
    year: "2023",
    GovernanceBodies: 65,
    Executives: 75,
    Managers: 98,
    Operators: 55,
    Professionals: 87,
    AdminStaff: 85,
  },
];

const AntiCorruptionTrainingChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <div style={{ fontFamily: "Arial, sans-serif" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          AntiCorruption Training{" "}
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Number of AMP by AMP category who got trained on anti-corruption
            issues
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>{" "}
      {activeMode && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis
              domain={[0, 100]}
              label={{
                value: "Number of AMP",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="GovernanceBodies"
              stroke="#2f3b75"
              name="Governance bodies"
            />
            <Line
              type="monotone"
              dataKey="Executives"
              stroke="#ffb74d"
              name="Executives"
            />
            <Line
              type="monotone"
              dataKey="Managers"
              stroke="#e57373"
              name="Managers"
            />
            <Line
              type="monotone"
              dataKey="Operators"
              stroke="#ffeb3b"
              name="Operators"
            />
            <Line
              type="monotone"
              dataKey="Professionals"
              stroke="#26a69a"
              name="Professionals/Technicians"
            />
            <Line
              type="monotone"
              dataKey="AdminStaff"
              stroke="#78909c"
              name="Administrative Staff"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={dt} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Governance Bodies"
              style={{ minWidth: "8%" }}
              field="GovernanceBodies"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Executives"
              style={{ minWidth: "8%" }}
              field="Executives"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Managers"
              style={{ minWidth: "8%" }}
              field="Managers"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Operators"
              style={{ minWidth: "8%" }}
              field="Operators"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Professionals"
              style={{ minWidth: "8%" }}
              field="Professionals"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="AdminStaff"
              style={{ minWidth: "8%" }}
              field="AdminStaff"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
export default AntiCorruptionTrainingChart;
