import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton'
import { useDropzone } from 'react-dropzone';
import { Badge } from 'primereact/badge';
import { Message } from 'primereact/message';
import moment from "moment";
import useForceUpdate from "use-force-update";
import { DateTime } from 'luxon';
import Axios from 'axios';
import APIServices from '../../service/APIService';
import { API } from '../../constants/api_url';
import { useSelector } from 'react-redux';
import { name } from 'xlsx-populate/lib/RichTextFragment';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import Swal from 'sweetalert2';
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import {Editor} from 'primereact/editor'

const getID = () => {
    return parseInt(Date.now() * Math.random()) + moment().unix() + randomIntFromInterval(1, 1000)

}
const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const PlatformRelease = ({ }) => {

    // const [baDialog, setBADialog] = useState(false)
    // const [badata, setBAData] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState([])
    const [recordbk, setRecordBk] = useState([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const selector = useSelector(state => state.user.admindetail)
    const [clientlist, setClientList] = useState([])
    const [topiclist, setTopicList] = useState([])
    const [topiclistbk, setTopicListBk] = useState([])
    const [goallist, setGoalList] = useState([])
    const [categorylist, setCategoryList] = useState([])
    const [categoryunitlist, setCategoryUnitList] = useState([])
    const [search, setSearch] = useState('')
    const type = [{ name: 'Enhancement', id: 1 }, { name: 'Client Specific Request ', id: 2 }, { name: 'Bug or UI Fix', id: 3 }, { name: 'Other', id: 4 }]
    const implementationType = [{ name: 'UI/UX', id: 1 }, { name: 'Functional', id: 2 }, { name: 'Structural', id: 3 }, { name: 'Normanclature', id: 4 }, { name: 'Other', id: 5 }]
    const implementationScreen = [{ name: 'All', id: 4 }, { name: 'EiSqr Admin', id: 1 }, { name: 'Enterprise Admin', id: 2 }, { name: 'Enterprise Users', id: 3 }]
    const communicationStatus = [{ name: 'Yes', id: 1 }, { name: 'No', id: 2 }, { name: 'Other', id: 3 }, { name: 'NA', id: 4 }]
    const forceUpdate = useForceUpdate()
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const [rawsitelist, setRawSitelist] = useState([]);
    const [initiative, setInitiative] = useState({
        clientId: null,
        type: null,
        dateOfImplementation: null,
        implementationScreen: null,
        implementationTypeOthers: '',
        description: '',
        attachment: [],
        approvedBy: '',
        communicationMsg: '',
        remarks: '',

    });


    useEffect(async () => {
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        const promise0 = APIServices.get(API.UserProfile)
        const promise1 = APIServices.get(API.Change_Mgmt)

        Promise.all([
            promise0, promise1]).then((values) => {
                let user_ = [], topic_ = [], goals = []
                values[0].data.forEach((usr) => {
                    if (usr.role === 'clientadmin') {
                        user_.push({ name: usr.information.companyname, id: usr.id })
                    }

                })
                setRecord(values[1].data)
                setRecordBk(values[1].data)
                setClientList(user_)

            })


    }, []);

    const applicabilityTemplate = (rowData) => {
        return (
            <div>

                {getCoverageText(rowData.applicability) || "N/A"}
            </div>
        )
    }
    const getCoverageText = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        if (rowData.country === 0 && rowData.city === 0 && rowData.location === 0) {
            text = 'Corporate'
        } else if (rowData.country !== 0 && rowData.city === 0 && rowData.location === 0) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.country)
            console.log(country_index)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.country !== 0 && rowData.city !== 0 && rowData.location === 0) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.city })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }
        } else {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.location })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }


        return text
    }
    const getMinDate = (date) => {
        // Convert the JavaScript Date object to a Luxon DateTime object
        const dateTime = DateTime.fromJSDate(date);

        // Add one day to the date
        const newDateTime = dateTime.plus({ days: 1 });

        // Convert back to JavaScript Date object
        return newDateTime.toJSDate();
    }

    const updateInitiative = (obj, val) => {
        let local = initiative;

        local[obj] = val;

        console.log(local)
        setInitiative(local)
        forceUpdate();
    }

    function checkEditorValue(htmlString) {
        if(!htmlString){
            return true
        }
        const regex = /^<p>\s*<\/p>$/;
        return regex.test(htmlString);
    }
    
    const saveData =async () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = initiative;
        let local2 = record;
        let local3 = recordbk;
      
        if (initiative.clientId !== null && initiative.type !== null && initiative.dateOfImplementation !== null && initiative.implementationScreen !== null && !checkEditorValue(initiative.communicationMsg) &&  initiative.description.trim().length && initiative.approvedBy.trim().length) {
           
            const { value: password } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Enter Password to update changes</div>`,
                input: 'password',
                confirmButtonColor: 'red',
                inputPlaceholder: 'Enter your password',
                showCancelButton: true,
    
                inputAttributes: {
                    maxlength: 15,
                    autocapitalize: 'off',
                    autocorrect: 'off',
                    autocomplete: 'off',
                },
                didOpen: () => {
    
    
    
                }
            })
            if (password === 'readytogonow') {
            if (initiative.id === undefined) {
                let newObj = { ...initiative, created_on: DateTime.utc(), created_by: selector.id, modified_on: DateTime.utc() }

                APIServices.post(API.Change_Mgmt, newObj).then((res) => {

                    local2.push(res.data);
                    setInitiative({
                        clientId: null,
                        type: null,
                        dateOfImplementation: null,
                        implementationScreen: null,
                        implementationTypeOthers: '',
                        description: '',
                        attachment: [],
                        approvedBy: '',
                        communicationMsg: '',
                        remarks: '',

                    });
                    setRecord(local2)
                    setRecordBk(local3)
                    forceUpdate()
                    setVisible(false)
                })
            } else {
                let newObj = { ...initiative, modified_on: DateTime.utc(), modified_by: selector.id }
                delete newObj.created_by
                delete newObj.created_on

                APIServices.patch(API.ChangeMgmt_Edit(initiative.id), newObj).then((res) => {
                    let index = local2.findIndex(i => i.id === newObj.id)
                    let index2 = local3.findIndex(i => i.id === newObj.id)

                    if (index !== -1) {
                        local2[index] = newObj
                    }
                    if (index2 !== -1) {
                        local3[index2] = newObj
                    }
                    setRecord(local2)
                    setRecordBk(local3)
                    forceUpdate()
                    setVisible(false)

                })
            }

        }



        }
    
        console.log(initiative)
    };

    const initiativeCategories = [
        { label: 'GHG Reduction', value: 'GHG Reduction' },
        // ... other categories
    ];

    const CurrentStatus = [

        { label: 'New', value: 1 },

        { label: 'Underway', value: 2 },

        { label: 'Achieved', value: 3 },

        { label: 'Expired', value: 4 },

        { label: 'Revised', value: 5 },

        { label: 'Replaced', value: 6 },

        { label: 'Retired', value: 7 }


    ]

    const departments = [
        { label: 'ABC Department', value: 'ABC Department' },
        // ... other departments
    ];

    const measurementUnits = [
        { label: 'Ltr', value: 'Ltr' },
        // ... other departments
    ];

    const onHide = () => setVisible(false);




    const initiatives = [
        {
            id: 1,
            title: 'Energy Conservation Program',
            category: 'GHG Reduction',
            startDate: new Date(2023, 5, 1).toLocaleDateString(),
            completionDate: new Date(2023, 11, 1).toLocaleDateString(),
            responsibility: 'Operations',
            currentStatus: 'Completed',
            attachment: 'document1.pdf'
        },
        {
            id: 2,
            title: 'Water Usage Reduction',
            category: 'Resource Management',
            startDate: new Date(2023, 6, 15).toLocaleDateString(),
            completionDate: new Date(2024, 1, 15).toLocaleDateString(),
            responsibility: 'Facilities',
            currentStatus: 'In Progress',
            attachment: 'document2.pdf'
        },
        // Add more objects as needed for your application
    ];
    const titleTemplate = (rowData) => {


        return (<div className="text-underline clr-navy fw-5 cur-pointer" onClick={() => { editVal(rowData) }}>{rowData.title}</div>)
    }
    const categoryTemplate = (rowData) => {
        let text = 'NA'
        let index = categorylist.findIndex(i => i.id === rowData.category)
        if (index !== -1) {
            text = categorylist[index].title
        }
        return (<>{text}</>)
    }
    // Template for the 'Status' column

    const idTemplate = (rowData) => {


        return <div className='text-underline clr-navy text-three-dot fw-7 cur-pointer' onClick={() => { editVal(rowData) }}>{rowData.description}</div>
    }
    const clientBodyTemplate = (rowData) => {
        let text = 'NA'
        let index = clientlist.findIndex(i => i.id === rowData.clientId)
        if (index !== -1) {
            text = clientlist[index].name
        } else if (rowData.clientId === 0) {
            text = "Universal"
        }

        return <>{text}</>
    }
    const areaBodyTemplate = (rowData) => {
        let text = 'NA'
        let index = implementationScreen.findIndex(i => i.id === rowData.implementationScreen)
        if (index !== -1) {
            text = implementationScreen[index].name
        }

        return <>{text}</>
    }
    const statusBodyTemplate = (rowData) => {
        let severity;

        let text = 'NA'
        let index = type.findIndex(i => i.id === rowData.type)
        if (index !== -1) {
            text = type[index].name
        }


        return <>{text}</>
    };

    const dateFormating = (input) => {
        let dateTime;
        // Parse input into Luxon DateTime object
        if (typeof input === 'string') {

            // Parse UTC string
            dateTime = DateTime.fromISO(input, { zone: 'utc' });
        } else if (input instanceof Date) {

            // Convert JavaScript Date object to Luxon DateTime object
            dateTime = DateTime.fromJSDate(input);
        } else {
            throw new Error('Invalid input type. Expected UTC string or Date object.');
        }

        // Format DateTime object into "dd-LLL-yyyy" format
        return dateTime.toLocal().toFormat('dd-LLL-yyyy');
    }

    const startDateTemplate = (rowData) => {
        return (
            <div>

                {dateFormating(rowData.startDate)}

            </div>
        )
    }

    const endDateTemplate = (rowData) => {
        return (
            <div>

                {dateFormating(rowData.dateOfImplementation)}

            </div>
        )
    }

    const newInitiative = () => {
        setInitiative({
            clientId: null,
            type: null,
            dateOfImplementation: null,
            implementationScreen: null,
            implementationTypeOthers: '',
            description: '',
            attachment: [],
            approvedBy: '',
            communicationMsg: '',
            remarks: '',

        });

        let item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        setVisible(true);
        setSubmitted(false)
    }



    const attachmentBodyTemplate = (rowData) => {
        return (
            (rowData.attachment === null || !rowData.attachment.length) ?
                <span className="attachment-link">
                    No Attachment
                </span>
                :
                <span className="attachment-link">
                    <a href={API.Docs + rowData.attachment[0].originalname} target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-file-pdf" style={{ 'marginRight': '0.5em', 'color': 'red' }}></i>
                        {rowData.attachment[0].originalname}
                    </a>
                </span>



        );
    };



    // Header for the table with a search input
    const renderHeader = () => {
        return (
            <div >
    

                <div className="table-header-actions py-4 d-flex justify-content-between">

                    <Button onClick={() => { newInitiative(); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
                </div>
            </div>
        );
    };

    const onDrop = (acceptedFiles) => {
        console.log(acceptedFiles);
        // Process files here. For example, you could upload them to a server or parse file content.
    };
    function getFileExtension_(filename) {
        const lastDot = filename.lastIndexOf('.');
        let ext = lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
        let ext_types = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpeg', 'jpg', 'png', 'bmp']
        console.log(ext)
        return ext_types.includes(ext)
    }
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }
    const attachmentTemplate = (file, index) => {
        console.log(file)
        return (<div className="flex align-items-center text-three-dot col-10 mt-2">
            {(getFileExtension(file.originalname) === 'pdf') ?
                <img src={require('../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                    <img src={require('../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                    (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                        <img src={require('../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                        (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                            <img src={require('../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
            <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                {file.originalname.slice(13)} <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
                {/* <small>{new Date().toLocaleDateString()}</small> */}
            </span>
            <span>
                <i onClick={() => { initiative.attachment.splice(index, 1); forceUpdate() }} className='material-icons clr-delete'>delete</i>
            </span>
        </div>)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({

        accept: "image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint",

        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles.filter(i => i.size <= 10000000).length !== 0 && getFileExtension_(acceptedFiles[0].name)) {
                let fileList = acceptedFiles.filter(i => i.size <= 10000000)
                let formData = new FormData()
                fileList.forEach((file) => {
                    formData.append('file', file);
                })

                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    let loc = initiative
                    let filelist = []

                    res.data.files.forEach((file) => {
                        filelist.push(file)
                    })
                    loc.attachment = filelist

                    console.log(res.data)
                    setInitiative(loc)
                    forceUpdate();
                })
            }

            // Handle file logic here
        }

    });

    // Styles for the dropzone
    const dropzoneStyle = {
        border: '2px dashed gray',
        borderRadius: '5px',
        padding: '40px 20px', // Increased padding as requested
        textAlign: 'center',
        cursor: 'pointer'
    };

    // Icon style
    const iconStyle = {
        width: '48px',
        height: '48px',
        color: '#9e9e9e'
    };

    const dialogFooter = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-text" />
            <Button label="Save" icon="pi pi-check"
                onClick={() => {
                    saveData()
                }} />
        </div>
    );

    const editVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        items.dateOfImplementation = DateTime.fromISO(items.dateOfImplementation, { zone: 'utc' }).toJSDate()
        setInitiative(items)
        setVisible(true);
        forceUpdate()
    }
    const uploadFilesApi = async (file, filelist) => {
        let promise = new Promise((resolve, rej) => {
            if (file.size <= 10000000) {
                let formData = new FormData();
                formData.append("file", file);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                    mode: "no-cors",
                }).then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        resolve(null);
                    }
                }).catch((res) => {
                    resolve(null);
                })
            } else {
                resolve(null);
            }


        });

        return promise;
    };
    const deleteRow = (item) => {
        // alert("into del func")
        let loc = record
        let loc2 = recordbk
        Swal.fire({
            title: `Are you sure want to delete  ${item.description.slice(0, 10)}... ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.ChangeMgmt_Edit(item.id)).then((res) => {
                    let index = loc.findIndex((k) => { return k.id === item.id })
                    let index2 = loc2.findIndex((k) => { return k.id === item.id })
                    loc2.splice(index2, 1)
                    loc.splice(index, 1)
                    setRecord(loc)
                    setRecordBk(loc2)
                    forceUpdate()
                })
            }
        })



    }
    const sortRP = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.startDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.startDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.startDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.startDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortRP2 = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.dateOfImplementation, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.dateOfImplementation, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.dateOfImplementation, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.dateOfImplementation, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>


                <div className="actions">

                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };

    const categoryRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={type}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const areaRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={implementationScreen}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const applicabilityRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={[{ name: 'Universal', id: 0 }, ...clientlist.sort((a, b) => { return a.name - b.name })]}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const searchTable = (e) => {
        setSearch((prev) => e.target.value)
        console.log(recordbk)
        setRecord(recordbk.filter(i => (i.description.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) || DateTime.fromISO(i.dateOfImplementation, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy').toLowerCase().includes(e.target.value.trim().toLowerCase()) || i.approvedBy.trim().toLowerCase().includes(e.target.value.trim().toLowerCase())  ) ))
        forceUpdate()
    }
    return (
        <div className='font-lato'>

            {selector.role === "eisqradmin" ? (
                <div>
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 10
                    }}>Change Management</div>
                    <div className="col-6">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={search} onChange={searchTable} style={{ width: 320 }} placeholder="Search Description/Date/Verifier Name" />
                        </span>
                    </div>
                    <DataTable
                
                        value={record.sort((a, b) => {
                            let dateA = DateTime.fromISO(a.modified_on, { zone: 'utc' });
                            let dateB = DateTime.fromISO(b.modified_on, { zone: 'utc' });
                            if (dateA > dateB) return -1;
                            if (dateA < dateB) return 1;
                            return 0;
                        })}
                        paginator
                        rows={10}
                        filters={{ clientId: { value: null, matchMode: 'in' }, type: { value: null, matchMode: 'in' }, implementationScreen: { value: null, matchMode: 'in' } }}
                        dataKey="id"
                        tableClassName='font-lato'

                          header={ renderHeader()}
                        className="p-datatable-initiatives">
                        <Column
                            style={{ maxWidth: 300, width: 300 }}
                            body={idTemplate}
                            header="Description" />
                        <Column
                            body={endDateTemplate} sortable field='dateOfImplementation' sortFunction={sortRP2}
                            header="Implementation Date" />
                        <Column
                            field='implementationScreen' body={areaBodyTemplate} filter showFilterMatchModes={false} showApplyButton={false} filterElement={areaRowFilterTemplate}
                            header="Impacted Portals" />
                        <Column
                            field='type' body={statusBodyTemplate} filter showFilterMatchModes={false} showApplyButton={false} filterElement={categoryRowFilterTemplate}
                            header="Category" />
                        <Column
                            field='clientId' body={clientBodyTemplate} filter showFilterMatchModes={false} showApplyButton={false} filterElement={applicabilityRowFilterTemplate}
                            header="Applicability" />



                        <Column
                            field='approvedBy'
                            header="Verified By" />





                        <Column
                            body={actionBodyTemplate}
                        >

                        </Column>

                    </DataTable>
                </div>
            ) :
                <div className="col-12 card">You have no rights to access this page </div>
            }

            <Dialog contentClassName='fw-5 font-lato' header={initiative.id ? "Edit " : "Add New"} visible={visible} style={{ width: '50vw' }} footer={dialogFooter} onHide={() => { setVisible(false) }} modal>
                <Tooltip className="form-tt-1" target={".clientinit"} mouseTrack mouseTrackLeft={10} />
                <div className="p-fluid row p-3">
                    <div className="p-field col-12">
                        <label htmlFor="description" className='flex'> Brief Description of Change  <span className="mandatory"> *</span>  </label>
                        <InputTextarea className='mt-2' value={initiative.description} autoResize={false} style={{ maxHeight: 100, minHeight: 100, minWidth: '100%', maxWidth: '100%', overflow: 'auto' }} onChange={(e) => setInitiative((prev)=> ({ ...prev, description: e.target.value }))} />
                        {submitted && (initiative.description === null || initiative.description.trim().length === 0) && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Required Brief Description of Change
                            </small>
                        )}

                    </div>
                    <div className="p-field col-6 ps-0 pb-4">
                        <label htmlFor="startDate" className='flex'> Implementation Date <span className="mandatory"> *</span>  </label>
                        <Calendar className='mt-2' id="startDate" value={initiative.dateOfImplementation} onChange={(e) => { updateInitiative("dateOfImplementation", e.value) }} showIcon dateFormat='dd-MM-yy' />

                        {submitted && initiative.dateOfImplementation === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Implementation Date
                            </small>
                        )}
                    </div>
                    <div className="p-field col-12 ps-0 pb-4">
                        <label htmlFor="description" className='flex'>Impacted Portals<span className="mandatory"> *</span>  </label>
                        <Dropdown className='mt-2' id="category" value={initiative.implementationScreen} optionValue='id' optionLabel='name' options={implementationScreen} onChange={(e) => { updateInitiative("implementationScreen", e.value) }} placeholder="Select Area" />

                        {submitted && initiative.implementationScreen === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Impacted Portals
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="category" className='flex'>Implementation Category <span className="mandatory"> *</span> </label>
                        <Dropdown className='mt-2' id="category" value={initiative.type} optionValue='id' optionLabel='name' options={type} onChange={(e) => { updateInitiative("type", e.value) }} placeholder="Select Category" />

                        {submitted && initiative.type === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Implementation Category
                            </small>
                        )}
                    </div>

                    <div className="p-field col-6  ">
                        <label htmlFor="title" >Select Applicability   <span className="mandatory"> *</span>        </label>
                        <Dropdown className='mt-2' value={initiative.clientId} optionValue='id' optionLabel='name' options={[{ name: 'Universal', id: 0 }, ...clientlist.sort((a, b) => { return a.name - b.name })]} onChange={(e) => { updateInitiative("clientId", e.value) }} placeholder="Select Client" />

                        {submitted && initiative.clientId === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Applicability
                            </small>
                        )}
                    </div>







                    <div className="p-field  col-12 ">
                        <div >
                            <label htmlFor="attachment" className='flex' >Attachment     </label>

                            {initiative.attachment && initiative.attachment.length > 0 && <FileUpload
                                mode="basic"

                                className="flex justify-content-end"
                                chooseLabel={
                                    "Add More"}
                                customUpload
                                uploadHandler={(files) => {
                                    uploadFilesApi(files.files[0]).then((res) => {
                                        if (res !== null) {
                                            let local = initiative;
                                            if (local.attachment === null) {
                                                local.attachment = [res];
                                            } else {
                                                local.attachment.push(res);
                                            }

                                            setInitiative(local);
                                            forceUpdate();
                                        }
                                        files.options.clear();
                                    });
                                }}
                                name="demo[]"
                                auto={true}
                                accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                                maxFileSize={10000000}
                            />}
                        </div>
                        {initiative.attachment && initiative.attachment.length ?

                            <div>{initiative.attachment.map((file, index) => {
                                return (
                                    attachmentTemplate(file, index)
                                )

                            })
                            }
                            </div>

                            : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                <input {...getInputProps()} />
                                <i className='pi pi-cloud-upload'></i>
                                <p>Drag and drop or click to upload</p>
                            </div>
                        }
                    </div>
                    <div className="p-field  col-12 ">
                        <label htmlFor="comments" className='flex'>Tested & Verified By <span className="mandatory"> *</span>       </label>
                        <InputText className='mt-2' id="comments" value={initiative.approvedBy} onChange={(e) => setInitiative((prev)=> ({ ...prev, approvedBy: e.target.value }))} />

                        {submitted && initiative.approvedBy.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Validated Person Name
                            </small>
                        )}

                    </div>
                    <div className="p-field  col-12 ">
                        <label htmlFor="comments" className='flex'>Reference Id </label>
                        <InputText className='mt-2' id="comments" value={initiative.version}  disabled/>
                    </div>
                    <div className="p-field col-12 ">
                        <label htmlFor="status">Impacted Clients and Mode / Details of Change Communication <span className="mandatory"> *</span>  </label>
                        <Editor className='text-area mt-2' value={initiative.communicationMsg} autoResize={false} style={{height:250,  overflow: 'auto' }} onTextChange={(e) => setInitiative( (prev)=>(  {  ...prev, communicationMsg: e.htmlValue }))} />
                        {submitted && (checkEditorValue(initiative.communicationMsg)) && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Required details of change communication
                            </small>
                        )}


                    </div>
                    <div className="p-field  col-12 ">
                        <label htmlFor="comments" className='flex'>Remarks   </label>
                        <InputText className='mt-2' id="comments" value={initiative.remarks} onChange={(e) => setInitiative((prev)=>({ ...prev, remarks: e.target.value }))} />



                    </div>

                </div>
            </Dialog>
        </div>
    );
};

export default PlatformRelease;
