import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';

const EmissionTable = () => {
    const data = [
        {
            "key": "ci",  // unique key for Carbon intensity
            "category": "Carbon intensity",
            "subcategory": "Carbon intensity (grams of Co2 per % of GMS)",
            "data": {
                "2019": 122.8,
                "2020": 102.7,
                "2021": 100.8,
                "2022": 93.7,
                "YOY%": "-7%"
            },
            "styleClass": "header-row"
        },
        {
            "key": "edo",  // unique key for Emissions from direct operations (Scope 1)
            "category": "Emission category",
            "subcategory": "Emissions from direct operations (Scope 1)",
            "data": {
                "2019": 122.8,
                "2020": 102.7,
                "2021": 100.8,
                "2022": 93.7,
                "YOY%": "-7%"
            },
            "entries": [
                {
                    "key": "ff",  // unique key for Fossil Fuels
                    "name": "Fossil Fuels",
                    "data": {
                        "2019": 122.8,
                        "2020": 102.7,
                        "2021": 100.8,
                        "2022": 93.7,
                        "YOY%": "-7%"
                    }
                },
                {
                    "key": "r",  // unique key for Refrigerants
                    "name": "Refrigerants",
                    "data": {
                        "2019": 122.8,
                        "2020": 102.7,
                        "2021": 100.8,
                        "2022": 93.7,
                        "YOY%": "-7%"
                    }
                }
            ]
        },
        {
            "key": "epe",  // unique key for Emissions from purchased electricity (Scope 2*)
            "category": "Emission category",
            "subcategory": "Emissions from purchased electricity (Scope 2*)",
            "data": {
                "2019": 122.8,
                "2020": 102.7,
                "2021": 100.8,
                "2022": 93.7,
                "YOY%": "-7%"
            }
        },
        {
            "key": "eis",  // unique key for Emissions from Indirect Sources (Scope 3*)
            "category": "Emission category",
            "subcategory": "Emissions from Indirect Sources (Scope 3*)",
            "data": {
                "2019": 122.8,
                "2020": 102.7,
                "2021": 100.8,
                "2022": 93.7,
                "YOY%": "-7%"
            },
            "entries": [
                {
                    "key": "cp",  // unique key for Corporate purchases
                    "name": "Corporate purchases and Amazon-branded product emissions",
                    "data": {
                        "2019": 122.8,
                        "2020": 102.7,
                        "2021": 100.8,
                        "2022": 93.7,
                        "YOY%": "-7%"
                    }
                },
                {
                    "key": "cg",  // unique key for Capital goods
                    "name": "Capital goods",
                    "data": {
                        "2019": 122.8,
                        "2020": 102.7,
                        "2021": 100.8,
                        "2022": 93.7,
                        "YOY%": "-7%"
                    }
                },
                {
                    "key": "le",  // unique key for Lifecycle emissions
                    "name": "Lifecycle emissions from customer trips to Amazon's physical stores",
                    "data": {
                        "2019": 122.8,
                        "2020": 102.7,
                        "2021": 100.8,
                        "2022": 93.7,
                        "YOY%": "-7%"
                    }
                }
            ]
        }
    ];



    const [expandedRow, setExpandedRow] = useState(null);

    const onRowToggle = (event) => {
        const currentKey = event.data.key;
        setExpandedRow(currentKey === expandedRow ? null : currentKey);
    };

    const rowExpansionTemplate = (data) => {
        return data.entries ? (
            <DataTable value={data.entries} responsiveLayout="scroll" className="p-datatable-sm">
                <Column field="name" header="Name" />
                <Column field="data.2019" header="2019" />
                <Column field="data.2020" header="2020" />
                <Column field="data.2021" header="2021" />
                <Column field="data.2022" header="2022" />
                <Column field="data.YOY%" header="YOY%" />
            </DataTable>
        ) : null;
    };

    return (
        <Card>
            <p className='card-title'>Carbon Emission Tracking Service</p>
            <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>
            <DataTable value={data} expandedRows={expandedRow} onRowToggle={onRowToggle}
                rowExpansionTemplate={rowExpansionTemplate} dataKey="key" responsiveLayout="scroll">
                <Column expander style={{ width: '3em' }} />
                <Column field="subcategory" header="Category" />
                <Column field="data.2019" header="2019" />
                <Column field="data.2020" header="2020" />
                <Column field="data.2021" header="2021" />
                <Column field="data.2022" header="2022" />
                <Column field="data.YOY%" header="YOY%" />
            </DataTable>
        </Card>
    );
};

export default EmissionTable;
