// SupplierMSIDashboard.js
import React from "react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";

export const SupplierMSIDashboard = () => {
  // Sample data for Radar Chart (Average Supplier Performance in MSI Framework Areas)
  const dataRadar = [
    { category: "Environmental", score: 80 },
    { category: "Social", score: 60 },
    { category: "Governance", score: 70 },
  ];

  // Sample data for Bar Chart (Trends in overall Average Supplier MSI Scores)
  const dataTrends = [
    { year: "22-23", Environmental: 40, Social: 30, Governance: 20 },
    { year: "23-24", Environmental: 50, Social: 25, Governance: 15 },
    { year: "24-25", Environmental: 45, Social: 35, Governance: 10 },
  ];

  // Sample data for Horizontal Bar Chart (MSI Score Range across Supplier Categories)
  const dataCategories = [
    { category: "Forging & Machining", min: 30, max: 70 },
    { category: "Casting & Machining", min: 20, max: 60 },
    { category: "Pressing & Fabrication", min: 40, max: 80 },
    { category: "Proprietary Mechanical", min: 35, max: 75 },
    { category: "Proprietary Electrical", min: 25, max: 55 },
    { category: "Plastics, Rubber, Painting and Stickers", min: 40, max: 65 },
    { category: "EV/3W/2W", min: 30, max: 90 },
    { category: "BMW", min: 20, max: 70 },
    { category: "Accessories", min: 40, max: 100 },
  ];

  // Sample data for Pie Chart (Distribution of Supplier MSI Ratings)
  const dataRatings = [
    { name: "Platinum", value: 40 },
    { name: "Gold", value: 30 },
    { name: "Silver", value: 60 },
    { name: "Bronze", value: 25 },
    { name: "Not Rated", value: 55 },
  ];

  const COLORS = ["#145f7f", "#e97132", "#1a6b26", "#0e9dd5", "#a02b93"];

  const transformedData = dataCategories.map((item) => ({
    category: item.category,
    start: item.min,
    range: item.max - item.min,
  }));

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "20px",
        padding: "20px",
      }}
    >
      {/* Radar Chart */}
      <div>
        <h3 style={{ textAlign: "center" }}>
          Average Supplier Performance in MSI Framework Areas
        </h3>
        <ResponsiveContainer width="100%" height={300}>
          <RadarChart outerRadius={90} data={dataRadar}>
            <PolarGrid />
            <PolarAngleAxis dataKey="category" />
            <PolarRadiusAxis angle={30} domain={[0, 100]} />
            <Radar
              name="Supplier"
              dataKey="score"
              stroke="#165a7f" // Border color for the radar
              fill="none"
              strokeWidth={5}
            />
            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      </div>

      {/* Stacked Bar Chart */}
      <div>
        <h3 style={{ textAlign: "center" }}>
          Trends in overall Average Supplier MSI Scores
        </h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataTrends} barSize={60}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Environmental" stackId="a" fill="#1a6b26" />
            <Bar dataKey="Social" stackId="a" fill="#e97132" />
            <Bar dataKey="Governance" stackId="a" fill="#145f7f" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Horizontal Bar Chart */}
      <div>
        <h3 style={{ textAlign: "center" }}>
          MSI Score Range across Supplier Categories
        </h3>
        <ResponsiveContainer width={500} height={400}>
          <BarChart
            layout="vertical"
            data={transformedData}
            margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
            barSize={15}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" domain={[0, 100]} />
            <YAxis
              dataKey="category"
              type="category"
              style={{ fontSize: "11px" }}
            />
            <Tooltip />
            <Bar dataKey="start" stackId="a" fill="transparent" />
            <Bar dataKey="range" stackId="a" fill="#ff7300" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Pie Chart */}
      <div>
        <h3 style={{ textAlign: "center" }}>
          Distribution of Supplier MSI Ratings
        </h3>
        <ResponsiveContainer width="100%" height={350}>
          <PieChart>
            <Pie data={dataRatings} outerRadius={120} dataKey="value">
              {dataRatings.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
