import React, { useEffect, useState } from "react";
import { API } from "../../../../constants/api_url";
import APIServices from "../../../../service/APIService";
import useForceUpdate from "use-force-update";
import { Calendar } from "primereact/calendar";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from 'primereact/tabmenu'
import { useDropzone } from "react-dropzone";
import { Tag } from "primereact/tag";
const dropzoneStyle = {
    border: '2px dashed gray',
    borderRadius: '5px',
    padding: '40px 20px', // Increased padding as requested
    textAlign: 'center',
    cursor: 'pointer'
};

const AddPolicyTVS = () => {

    const [data, setData] = useState([])
    const [policy, setPolicy] = useState({ dateOfRenewal: null, dateOfRevision: null, date: null, title: '', section: null, type: 1, documents: [] })
    const allRoles = useSelector((state) => state.user.allRoles)
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const [activeindex, setActiveIndex] = useState(0)
    const [adddialog, setAddDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const forceUpdate = useForceUpdate();
    const addDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewPolicy() }} />

        </div>)
    }

    useEffect(()=>{
        APIServices.get(API.PolicyProcedure).then((res)=>{
            setData(res.data)

            
        })
    },[])
    const addNewPolicy = () => {
        let loc = JSON.parse(JSON.stringify(data))
        setSubmitted(true)
        if (policy.title.trim().length && policy.date && policy.section) {
            let newObj = { title: policy.title, section: policy.section, dateOfRenewal: policy.dateOfRenewal, dateOfRevision: policy.dateOfRenewal, documents: policy.documents }
            if (policy.id) {
                APIServices.patch(API.PolicyProcedure_Edit(policy.id), newObj).then(res => {
                    setAddDialog(false)
                    setSubmitted(false)
                    let index = loc.findIndex(i => i.id === policy.id)
                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...newObj }
                    }

                    setData(loc)
                    forceUpdate()

                })
            } else {
                newObj['date'] = policy.date
                newObj['type'] = policy.type
                APIServices.post(API.PolicyProcedure_Up(admin_data.id), newObj).then(res => {
                    setAddDialog(false)
                    setSubmitted(false)
                    loc.push(res.data)
                    setData(loc)
                    forceUpdate()

                })

            }
        }

    }
    const getDate = (date, format) => {
console.log(date)
        if (!date) {
            return ''
        } if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const dateTemplate = (date) => {

        return (
            <>
                <span className="p-column-title">Approved</span>
                 { getDate(date)}
            </>
        );
    }
    const sectionTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                { }
            </>
        );
    }
    const nameTemplate = (rowData) => {
        console.log(rowData)
        return (
      <div className='clr-navy text-underline cur-pointer fw-6' onClick={()=>{editPolicy(rowData)}}>{rowData.title}</div> 
        );
    }
    const editPolicy = (rowData)=>{

        let item = JSON.parse(JSON.stringify(rowData))
        item.date = DateTime.fromISO(item.date,{zone:'utc'}).toJSDate()
        if(item.dateOfRenewal){
            item.dateOfRenewal = DateTime.fromISO(item.dateOfRenewal,{zone:'utc'}).toJSDate()
        }

        if(item.dateOfRevision){
            item.dateOfRevision = DateTime.fromISO(item.dateOfRevision,{zone:'utc'}).toJSDate()
        }

        setPolicy(item)
        setAddDialog(true)
        setSubmitted(false)
    }
    const policyHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="flex col-12 justify-content-end" >
                    <Button label="New Policy" icon="pi pi-plus" style={{ width: 150 }} onClick={() => { setPolicy({ dateOfRenewal: null, dateOfRevision: null, date: null, title: '', section: null, type: 1, documents: [] }); setAddDialog(true) }} />

                </div>
            </React.Fragment>

        </div>
    );
    const procedureHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="flex col-12 justify-content-end" >
                    <Button label="New Policy" icon="pi pi-plus" style={{ width: 150 }} onClick={() => { setPolicy({ dateOfRenewal: null, dateOfRevision: null, date: null, title: '', section: null, type: 2, documents: [] }); setAddDialog(true) }} />

                </div>
            </React.Fragment>

        </div>
    );
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }
    const attachmentTemplate = (file) => {
        console.log(file)
        return (<div className="flex align-items-center  col-12 mt-2">
            {(getFileExtension(file.originalname) === 'pdf') ?
                <img src={require('../../../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                    <img src={require('../../../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                    (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                        <img src={require('../../../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                        (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                            <img src={require('../../../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
            <div className="col-7" style={{ flexDirection: 'column' }}>
                <span className="flex align-items-center text-left ml-3 text-three-dot clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                    {file.originalname.slice(13)}

                    {/* <small>{new Date().toLocaleDateString()}</small> */}
                </span>
                <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
            </div>
            <span className="col-2 flex justify-content-center">
                <i onClick={() => { policy.documents.splice(0, 1); forceUpdate() }} className='material-icons clr-delete cur-pointer'>delete</i>
            </span>
        </div>)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({

        accept: "image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint",
        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles.filter((a) => { return a.size <= 20000000 }).length && getFileExtension_(acceptedFiles[0].name)) {
                let formData = new FormData()
                acceptedFiles.filter((a) => { return a.size <= 20000000 }).forEach((file) => {
                    formData.append('file', file);
                })

                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    let loc = policy
                    let filelist = []

                    res.data.files.forEach((file) => {
                        filelist.push(file)
                    })
                    loc.documents = filelist
                    console.log(res.data)
                    setPolicy(loc)
                    forceUpdate();

                })
            }

            // Handle file logic here
        }
    });
    function getFileExtension_(filename) {
        const lastDot = filename.lastIndexOf('.');
        let ext = lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
        let ext_types = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpeg', 'jpg', 'png', 'bmp']
        console.log(ext)
        return ext_types.includes(ext)
    }
    const updatePolicy = (obj, val) => {
        let loc = policy
        loc[obj] = val
        setPolicy(loc)
        forceUpdate()
    }
    return (
        <div className="grid p-fluid">
            {(login_data.role === 'clientadmin' || allRoles.includes(14) || true) ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{

                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Policies & Procedures (${data.length})`} </h5>
                    </div>

                    <div>
                        <TabMenu model={[{ label: 'Policies', icon: 'pi pi-home' }, { label: 'Procedures', icon: 'pi pi-home' }
                        ]} onTabChange={(e) => { setActiveIndex(e.index) }} activeIndex={activeindex} />
                        {activeindex === 0 && <DataTable value={data.filter(i => i.type === 1)}
                            dataKey="id" paginator rows={12} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} policy(s)"
                            emptyMessage="No records found." header={policyHeader} responsiveLayout="scroll">
                            <Column field="title" header="Policy Name" headerStyle={{ width: '14%', minWidth: '10rem' }} body={nameTemplate}></Column>
                            <Column field="section" header="Section" body={sectionTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Policy Date" body={(rowData) => {return dateTemplate(rowData.date) }} field="date" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column header="Date of Renewal" body={(rowData) => {return dateTemplate(rowData.dateOfRenewal) }} field="dateOfRenewal" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Date of Revision" body={(rowData) => {return dateTemplate(rowData.dateOfRevision) }} field="dateOfRevision" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            {/* <Column header="Action" body={editTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column> */}

                        </DataTable>}
                        {activeindex === 1 && <DataTable value={data.filter(i => i.type === 2)}
                            dataKey="id" paginator rows={12} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} policy(s)"
                            emptyMessage="No records found." header={procedureHeader} responsiveLayout="scroll">
                            <Column field="title" header="SOP Name" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="section" header="Section" body={sectionTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="SOP Date" body={(rowData) => { dateTemplate(rowData.date) }} field="date" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column header="Date of Renewal" body={(rowData) => { dateTemplate(rowData.dateOfRenewal) }} field="dateOfRenewal" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Date of Revision" body={(rowData) => { dateTemplate(rowData.dateOfRevision) }} field="dateOfRevision" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            {/* <Column header="Action" body={editTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column> */}

                        </DataTable>}

                        <Dialog visible={adddialog} style={{ width: '450px' }} header="Add Policy" modal className="p-fluid" footer={addDialogFooter} onHide={() => { setSubmitted(false); setAddDialog(false) }}>

                            <div className="field">
                                <label htmlFor="refdate">{policy.type === 1 ? 'Policy Name' : 'SOP Name'} <span style={{ color: 'red' }}>*</span></label>
                                <InputText type={'text'} value={policy.title} onChange={(e) => { updatePolicy('title', e.target.value) }} placeholder="Name" />
                                {submitted && policy.title.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Policy Name is mandatory</small>}

                            </div>

                            <div className="field">
                                <label htmlFor="refdate">Section <span style={{ color: 'red' }}>*</span></label>
                                <Dropdown value={policy.section} optionLabel='name' onChange={(e) => { updatePolicy('section', e.target.value) }} placeholder="Category" options={[{ name: 'Forging & Machining', value: 1 }, { name: 'Casting & Machining', value: 2 }, { name: 'Pressing & Fabrication', value: 3 }, { name: 'Proprietary Mechanical', value: 4 }, { name: 'Proprietary Electrical', value: 5 }, { name: 'Plastics, Rubber, Painting and Stickers', value: 6 }, { name: 'EV/3W/2W', value: 7 }, { name: 'BW', value: 8 }, { name: 'Accessories', value: 9 }]} />
                                {submitted && policy.section === null && <small className="p-invalid" style={{ color: 'red' }}>Section is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">{policy.type === 1 ? 'Policy Date' : 'SOP Date'} <span style={{ color: 'red' }}>*</span></label>
                                <Calendar type={'text'} value={policy.date} onChange={(e) => { updatePolicy('date', e.value) }} dateFormat="dd/mm/yy" placeholder="Date" />
                                {submitted && !policy.date && <small className="p-invalid" style={{ color: 'red' }}>Date is mandatory</small>}

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Date Of Renewal </label>
                                <Calendar type={'text'} value={policy.dateOfRenewal} onChange={(e) => { updatePolicy('dateOfRenewal', e.value) }} dateFormat="dd/mm/yy" placeholder="Date of Renewal" />
                               

                            </div>
                            <div className="field">
                                <label htmlFor="refdate">Date Of Revision </label>
                                <Calendar type={'text'} value={policy.dateOfRevision} onChange={(e) => { updatePolicy('dateOfRevision', e.value) }} dateFormat="dd/mm/yy" placeholder="Date of Revision" />
                                

                            </div>


                            <div className="p-field  col-12 ">
                                <label htmlFor="attachment" className='flex' >Attachment        </label>
                                {policy.documents && policy.documents.length ?
                                    policy.documents.map((i) => {
                                        return attachmentTemplate(i)
                                    })
                                    : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                        <input {...getInputProps()} />
                                        <i className='pi pi-cloud-upload'></i>
                                        <p>Drag and drop or click to upload</p>
                                    </div>}
                            </div>





                        </Dialog>

                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AddPolicyTVS, comparisonFn);
