import React, { useEffect, useState } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";

const ClientGTA = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const forceUpdate = useForceUpdate()

    useEffect(() => {
        const promise0 = APIServices.get(API.Goal_UP(admin_data.id))

        Promise.all([promise0]).then((values) => {
            console.log(values)
        })

    }, [])

    return (
        <div>
            <div className="col-12">
                <label className="clr-navy fw-7 fs-20">Goals</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div
                    className="block mt-2 md:mt-0 p-input-icon-left"

                >
                    <i className="pi pi-search" />
                    <InputText type="search" placeholder="Search..." style={{ width: '100%' }} />
                </div>
                <div>
                    <Button icon={'pi pi-plus'} label='Add Goal' ></Button>
                </div>

            </div>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientGTA, comparisonFn);