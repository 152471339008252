import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const waterGeneratedData = {
  2019: [
    {
      month: "Jan",
      surface: 20,
      seawater: 30,
      thirdParty: 30,
      groundWater: 20,
      ownProducedWater: 20,
      total: 120,
    },
    {
      month: "Feb",
      surface: 15,
      seawater: 25,
      thirdParty: 12,
      groundWater: 10,
      ownProducedWater: 20,
      total: 82,
    },
    {
      month: "Mar",
      surface: 25,
      seawater: 20,
      thirdParty: 15,
      groundWater: 20,
      ownProducedWater: 25,
      total: 105,
    },
    {
      month: "Apr",
      surface: 30,
      seawater: 35,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 10,
      total: 110,
    },
    {
      month: "May",
      surface: 10,
      seawater: 40,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 30,
      total: 125,
    },
    {
      month: "Jun",
      surface: 35,
      seawater: 25,
      thirdParty: 10,
      groundWater: 20,
      ownProducedWater: 15,
      total: 105,
    },
    {
      month: "Jul",
      surface: 40,
      seawater: 30,
      thirdParty: 25,
      groundWater: 15,
      ownProducedWater: 20,
      total: 130,
    },
    {
      month: "Aug",
      surface: 20,
      seawater: 20,
      thirdParty: 15,
      groundWater: 10,
      ownProducedWater: 25,
      total: 90,
    },
    {
      month: "Sep",
      surface: 30,
      seawater: 25,
      thirdParty: 20,
      groundWater: 25,
      ownProducedWater: 10,
      total: 110,
    },
    {
      month: "Oct",
      surface: 15,
      seawater: 15,
      thirdParty: 10,
      groundWater: 20,
      ownProducedWater: 30,
      total: 90,
    },
    {
      month: "Nov",
      surface: 25,
      seawater: 20,
      thirdParty: 25,
      groundWater: 15,
      ownProducedWater: 20,
      total: 105,
    },
    {
      month: "Dec",
      surface: 35,
      seawater: 30,
      thirdParty: 15,
      groundWater: 25,
      ownProducedWater: 10,
      total: 115,
    },
  ],
  2020: [
    {
      month: "Jan",
      surface: 25,
      seawater: 35,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 25,
      total: 120,
    },
    {
      month: "Feb",
      surface: 15,
      seawater: 25,
      thirdParty: 15,
      groundWater: 20,
      ownProducedWater: 30,
      total: 105,
    },
    {
      month: "Mar",
      surface: 35,
      seawater: 20,
      thirdParty: 10,
      groundWater: 25,
      ownProducedWater: 15,
      total: 105,
    },
    {
      month: "Apr",
      surface: 40,
      seawater: 30,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 20,
      total: 135,
    },
    {
      month: "May",
      surface: 30,
      seawater: 25,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 10,
      total: 100,
    },
    {
      month: "Jun",
      surface: 20,
      seawater: 35,
      thirdParty: 30,
      groundWater: 25,
      ownProducedWater: 20,
      total: 130,
    },
    {
      month: "Jul",
      surface: 15,
      seawater: 40,
      thirdParty: 15,
      groundWater: 10,
      ownProducedWater: 30,
      total: 110,
    },
    {
      month: "Aug",
      surface: 30,
      seawater: 20,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 15,
      total: 110,
    },
    {
      month: "Sep",
      surface: 25,
      seawater: 15,
      thirdParty: 35,
      groundWater: 15,
      ownProducedWater: 20,
      total: 110,
    },
    {
      month: "Oct",
      surface: 40,
      seawater: 25,
      thirdParty: 20,
      groundWater: 20,
      ownProducedWater: 30,
      total: 135,
    },
    {
      month: "Nov",
      surface: 20,
      seawater: 30,
      thirdParty: 15,
      groundWater: 25,
      ownProducedWater: 20,
      total: 110,
    },
    {
      month: "Dec",
      surface: 25,
      seawater: 20,
      thirdParty: 30,
      groundWater: 20,
      ownProducedWater: 25,
      total: 120,
    },
  ],
  2021: [
    {
      month: "Jan",
      surface: 15,
      seawater: 25,
      thirdParty: 10,
      groundWater: 30,
      ownProducedWater: 20,
      total: 100,
    },
    {
      month: "Feb",
      surface: 30,
      seawater: 20,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 15,
      total: 110,
    },
    {
      month: "Mar",
      surface: 35,
      seawater: 30,
      thirdParty: 15,
      groundWater: 10,
      ownProducedWater: 25,
      total: 115,
    },
    {
      month: "Apr",
      surface: 25,
      seawater: 35,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 25,
      total: 120,
    },
    {
      month: "May",
      surface: 20,
      seawater: 15,
      thirdParty: 25,
      groundWater: 30,
      ownProducedWater: 10,
      total: 100,
    },
    {
      month: "Jun",
      surface: 30,
      seawater: 40,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 15,
      total: 120,
    },
    {
      month: "Jul",
      surface: 15,
      seawater: 25,
      thirdParty: 35,
      groundWater: 20,
      ownProducedWater: 15,
      total: 110,
    },
    {
      month: "Aug",
      surface: 25,
      seawater: 30,
      thirdParty: 15,
      groundWater: 25,
      ownProducedWater: 20,
      total: 115,
    },
    {
      month: "Sep",
      surface: 30,
      seawater: 20,
      thirdParty: 25,
      groundWater: 10,
      ownProducedWater: 20,
      total: 105,
    },
    {
      month: "Oct",
      surface: 20,
      seawater: 30,
      thirdParty: 15,
      groundWater: 20,
      ownProducedWater: 25,
      total: 110,
    },
    {
      month: "Nov",
      surface: 35,
      seawater: 25,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 30,
      total: 125,
    },
    {
      month: "Dec",
      surface: 15,
      seawater: 20,
      thirdParty: 30,
      groundWater: 25,
      ownProducedWater: 25,
      total: 115,
    },
  ],
  2022: [
    {
      month: "Jan",
      surface: 25,
      seawater: 15,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 10,
      total: 95,
    },
    {
      month: "Feb",
      surface: 20,
      seawater: 30,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 30,
      total: 115,
    },
    {
      month: "Mar",
      surface: 15,
      seawater: 25,
      thirdParty: 30,
      groundWater: 25,
      ownProducedWater: 15,
      total: 110,
    },
    {
      month: "Apr",
      surface: 40,
      seawater: 20,
      thirdParty: 15,
      groundWater: 20,
      ownProducedWater: 35,
      total: 130,
    },
    {
      month: "May",
      surface: 35,
      seawater: 25,
      thirdParty: 20,
      groundWater: 15,
      ownProducedWater: 20,
      total: 115,
    },
    {
      month: "Jun",
      surface: 25,
      seawater: 20,
      thirdParty: 35,
      groundWater: 30,
      ownProducedWater: 10,
      total: 120,
    },
    {
      month: "Jul",
      surface: 20,
      seawater: 40,
      thirdParty: 10,
      groundWater: 20,
      ownProducedWater: 30,
      total: 120,
    },
    {
      month: "Aug",
      surface: 30,
      seawater: 15,
      thirdParty: 25,
      groundWater: 25,
      ownProducedWater: 15,
      total: 110,
    },
    {
      month: "Sep",
      surface: 40,
      seawater: 30,
      thirdParty: 20,
      groundWater: 20,
      ownProducedWater: 10,
      total: 120,
    },
    {
      month: "Oct",
      surface: 15,
      seawater: 35,
      thirdParty: 25,
      groundWater: 15,
      ownProducedWater: 25,
      total: 115,
    },
    {
      month: "Nov",
      surface: 20,
      seawater: 25,
      thirdParty: 30,
      groundWater: 10,
      ownProducedWater: 20,
      total: 105,
    },
    {
      month: "Dec",
      surface: 35,
      seawater: 15,
      thirdParty: 25,
      groundWater: 25,
      ownProducedWater: 20,
      total: 120,
    },
  ],
  2023: [
    {
      month: "Jan",
      surface: 30,
      seawater: 15,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 20,
      total: 110,
    },
    {
      month: "Feb",
      surface: 25,
      seawater: 35,
      thirdParty: 15,
      groundWater: 20,
      ownProducedWater: 25,
      total: 120,
    },
    {
      month: "Mar",
      surface: 20,
      seawater: 20,
      thirdParty: 30,
      groundWater: 10,
      ownProducedWater: 25,
      total: 105,
    },
    {
      month: "Apr",
      surface: 15,
      seawater: 25,
      thirdParty: 20,
      groundWater: 25,
      ownProducedWater: 35,
      total: 120,
    },
    {
      month: "May",
      surface: 25,
      seawater: 20,
      thirdParty: 15,
      groundWater: 15,
      ownProducedWater: 30,
      total: 105,
    },
    {
      month: "Jun",
      surface: 35,
      seawater: 30,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 15,
      total: 125,
    },
    {
      month: "Jul",
      surface: 30,
      seawater: 25,
      thirdParty: 20,
      groundWater: 10,
      ownProducedWater: 25,
      total: 110,
    },
    {
      month: "Aug",
      surface: 25,
      seawater: 20,
      thirdParty: 15,
      groundWater: 30,
      ownProducedWater: 20,
      total: 110,
    },
    {
      month: "Sep",
      surface: 15,
      seawater: 35,
      thirdParty: 25,
      groundWater: 20,
      ownProducedWater: 15,
      total: 110,
    },
    {
      month: "Oct",
      surface: 30,
      seawater: 20,
      thirdParty: 15,
      groundWater: 20,
      ownProducedWater: 25,
      total: 110,
    },
    {
      month: "Nov",
      surface: 40,
      seawater: 30,
      thirdParty: 25,
      groundWater: 15,
      ownProducedWater: 10,
      total: 120,
    },
    {
      month: "Dec",
      surface: 20,
      seawater: 20,
      thirdParty: 30,
      groundWater: 25,
      ownProducedWater: 15,
      total: 110,
    },
  ],
};

const CustomWaterLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 4 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const WaterGeneratedChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = waterGeneratedData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} surface`]: item.surface,
    [`${year1} seawater`]: item.seawater,
    [`${year1} thirdParty`]: item.thirdParty,
    [`${year1} groundWater`]: item.groundWater,
    [`${year1} ownProducedWater`]: item.ownProducedWater,
    [`${year1} total`]: item.total,

    [`${year2} surface`]: waterGeneratedData[year2][index].surface,
    [`${year2} seawater`]: waterGeneratedData[year2][index].seawater,
    [`${year2} thirdParty`]: waterGeneratedData[year2][index].thirdParty,
    [`${year2} groundWater`]: waterGeneratedData[year2][index].groundWater,
    [`${year2} ownProducedWater`]:
      waterGeneratedData[year2][index].ownProducedWater,
    [`${year2} total`]: waterGeneratedData[year2][index].total,
  }));

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Water Withdrawal
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Quantity of water withdrawn from different sources.
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(waterGeneratedData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(waterGeneratedData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <div style={{ overflow: "hidden" }}>
          <ResponsiveContainer height={350} width="100.5%">
            <ComposedChart data={combinedData}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="month" />
              <YAxis
                label={{
                  value: "Million litres",
                  angle: -90,
                  position: "insideLeft",
                }}
              />{" "}
              <Tooltip />
              <Bar
                dataKey={`${year1} surface`}
                stackId="a"
                fill="#BDD1F7"
                name="Surface water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year1} seawater`}
                stackId="a"
                fill="#8FC3E8"
                name="Seawater"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year1} thirdParty`}
                stackId="a"
                fill="#5B8FF7"
                name="Third party water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year1} ownProducedWater`}
                stackId="a"
                fill="#4569B2"
                name="Own Produced water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year1} groundWater`}
                stackId="a"
                fill="#C5DAE9"
                name="Ground water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year2} surface`}
                stackId="b"
                fill="#BDD1F7"
                name="Surface water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year2} seawater`}
                stackId="b"
                fill="#8FC3E8"
                name="Seawater"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year2} thirdParty`}
                stackId="b"
                fill="#5B8FF7"
                name="Third party water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year2} ownProducedWater`}
                stackId="b"
                fill="#4569B2"
                name="Own Produced water"
                barSize={30}
                barGap={5}
              />
              <Bar
                dataKey={`${year2} groundWater`}
                stackId="b"
                fill="#C5DAE9"
                name="Ground water"
                barSize={30}
                barGap={5}
              />
              <Legend content={CustomWaterLegend} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={waterGeneratedData[year1].map((item, index) => ({
              ...item,
              [`surface_${year2}`]: waterGeneratedData[year2][index].surface,
              [`seawater_${year2}`]: waterGeneratedData[year2][index].seawater,
              [`thirdParty${year2}`]:
                waterGeneratedData[year2][index].thirdParty,
              [`groundWater_${year2}`]:
                waterGeneratedData[year2][index].groundWater,
              [`ownProducedWater_${year2}`]:
                waterGeneratedData[year2][index].ownProducedWater,
            }))}
            tableClassName="font-lato"
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Surface - ${year1}`}
              style={{ minWidth: "8%" }}
              field="surface"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Sea Water - ${year1}`}
              style={{ minWidth: "8%" }}
              field="seawater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Third Party - ${year1}`}
              style={{ minWidth: "8%" }}
              field="thirdParty"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Ground Water - ${year1}`}
              style={{ minWidth: "8%" }}
              field="groundWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Own Produced Water - ${year1}`}
              style={{ minWidth: "8%" }}
              field="ownProducedWater"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Surface - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`surface_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Sea Water - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`seawater_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Third Party - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`thirdParty_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Ground Water - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`groundWater_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Own Produced Water - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`ownProducedWater_${year2}`}
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
