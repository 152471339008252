import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const data = [
    { 
        ethical: 'Business Integrity',
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2021': 100,
        '2022': 200,
    },
    { 
        ethical: 'Transparency',
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2021': 100,
        '2022': 200,
    },
];

const EthicalDataTable = () => {
    const tableStyle = { 
        borderRadius: '10px',
        overflow: 'hidden',
        border: '1px solid #EBEDEF', // Set border color to grey
        fontSize: '16px',
        fontWeight: '400'
    };

    return (
        <DataTable value={data} style={tableStyle}>
            <Column field="ethical" header="Ethical Behaviour"/>
            <Column field="2020One" header="2020" />
            <Column field="2020Two" header="2020" />
            <Column field="2020Three" header="2020" />
            <Column field="2021" header="2021" />
            <Column field="2022" header="2022" />
        </DataTable>
    );
}

export default EthicalDataTable;
