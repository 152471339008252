export const API_URL  = 'https://api.eisqr.com'

// export const API_URL = 'https://eisqr-api.acuizen.com';

export const TOPICS_URL = API_URL + '/topics';
export const SUBTOPICS_URL = API_URL + '/sub-topics';
export const NESTED_SUBTOPIC_URL = '/sub-topics';

export const STANDARDS_URL = API_URL + '/standards';

export const QUESTIONS_URL = API_URL + '/questions';
export const SUBQUESTIONS_URL = API_URL + '/sub-questions';
export const NESTED_SUBQUESTIONS_URL = '/sub-questions';

export const LOGIN_URL = API_URL + '/users/login';
export const USER_PROFILES_URL = API_URL + '/user-profiles';