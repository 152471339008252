import React, { useState,useEffect } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';

const WaterWithdrawal = ({waterData}) => {
  const [months,setMonths] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [data,setData] = useState([])
  useEffect(()=>{
    setMonths([{label:'All',value:0},...waterData.map(i=> ({label:i.month,value:i.month}))] )

    renderMap(0)
  
  },[waterData])
  const getRPTextFormat = (item) => {
    if (item.length !== 0) {
        if (item.length >= 2) {
            const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                "LLL-yyyy"
            );
            const endDate = DateTime.fromFormat(
                item[item.length - 1],
                "MM-yyyy"
            ).toFormat("LLL-yyyy");
            return `${startDate} to ${endDate}`;
        } else {
            return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
        }
    }
  };
  const WaterWithdrawl = (submissions_cy) => {
    let surface = 0, ground = 0, third = 0, sea = 0, other = 0
   
    if (submissions_cy.length) {
  
        for (const item of submissions_cy) {
            console.log(item)
            item.response.forEach((dp) => {
                let val = dp.DPAN0049A === 1 ? (parseFloat(dp.DPAN0049) / 1000) : dp.DPAN0049A === 2 ? parseFloat(dp.DPAN0049) : (parseFloat(dp.DPAN0049) * 1000)
  
                if (dp.DPAN0048 === 1) {
                    surface += val
                } else if (dp.DPAN0048 === 2) {
                    ground += val
                } else if (dp.DPAN0048 === 3) {
                    sea += val
                } else if (dp.DPAN0048 === 5) {
                    third += val
                } else {
                    other += val
                }
            })
        }
    }
  
  
    return [ {name:'Surface Water',value:surface},{name:'Ground Water',value:ground},{name:'Third Party Water',value:third},{name:'Sea Water',value:sea},{name:'Other',value:other} ]
  
  }
  const WaterDisposal = (submissions_cy) => {
    let surface = 0, ground = 0, third = 0, sea = 0, other = 0
  
  
  
    if (submissions_cy.length) {
  
        for (const item of submissions_cy) {
            console.log(item)
            item.response.forEach((dp) => {
                let val = dp.DPAN0053A === 1 ? (parseFloat(dp.DPAN0053) / 1000) : dp.DPAN0053A === 2 ? parseFloat(dp.DPAN0053) : (parseFloat(dp.DPAN0053) * 1000)
          
                    if (dp.DPAN0052 === 1) {
                        surface += val
                    } else if (dp.DPAN0052 === 2) {
                        ground += val
                    } else if (dp.DPAN0052 === 3) {
                        sea += val
                    } else if (dp.DPAN0052 === 5) {
                        third += val
                    } else {
                        other += val
                    }
                
  
            })
        }
    }
    
    console.log(surface, ground, third, sea, other)
  
  
    return    [ {name:'Surface Water',value:surface},{name:'Ground Water',value:ground},{name:'Third Party Water',value:third},{name:'Sea Water',value:sea},{name:'Other',value:other} ]
  
  
  }
  const renderMap=(yr)=>{
    let locdata = waterData.filter(i => ( i.month === yr || yr === 0  ))
    console.log(waterData)
  setData(WaterDisposal(locdata.flatMap(i => i.data).filter(i => i.dcfId===247)))
    // console.log(locdata.flatMap(i => i.data).filter(i => i.dcfId===246))
  }
  

  const updateMonth = (val)=>{
    setSelectedMonth(val)
    renderMap(val)
  }
  const COLORS = ['#2A6FFA', '#5B8FF7', '#8DB0F4', '#A4C3FE', '#CFDCF5'];


  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color, display: 'flex', alignItems: 'center', marginRight: 20 }}>
            <span style={{ height: 10, width: 10, backgroundColor: entry.color, borderRadius: '50%', display: 'inline-block', marginRight: 5 }}></span>
            {entry.value}: <strong style={{ marginLeft: 5 }}>{data[index].value}</strong>
          </li>
        ))}
      </ul>
    );
  };

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  return (
    <Card>
      <p className='card-title'>Water Discharge</p>
      <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>
      <Dropdown value={selectedMonth} className="me-3" options={months} style={{width:150}} onChange={(e) => {updateMonth(e.value)}} placeholder="Select a Month" />
  

      <ResponsiveContainer width="100%" height={300}>
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
          
            outerRadius={120}
            fill="#8884d8"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default WaterWithdrawal;
