import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';

const BarOne = ({graphData}) => {
    const data = [
        { month: 'Jan', renewable: 10, nonRenewable: 38 },
        { month: 'Feb', renewable: 15, nonRenewable: 35 },
        { month: 'Mar', renewable: 28, nonRenewable: 32 },
        { month: 'Apr', renewable: 21, nonRenewable: 29 },
        { month: 'May', renewable: 44, nonRenewable: 26 },
        { month: 'Jun', renewable: 57, nonRenewable: 23 },
        { month: 'Jul', renewable: 30, nonRenewable: 20 },
        { month: 'Aug', renewable: 68, nonRenewable: 22 },
        { month: 'Sep', renewable: 15, nonRenewable: 25 },
        { month: 'Oct', renewable: 22, nonRenewable: 28 },
        { month: 'Nov', renewable: 29, nonRenewable: 31 },
        { month: 'Dec', renewable: 56, nonRenewable: 34 },
    ];



    return (
        <Card>
            <p className='card-title'>Energy Consumption Mix</p>
            <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>

            <ResponsiveContainer width="100%" height={500}>
                <BarChart width={8} height={4} data={graphData}>
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={() => ''} />
                    <Tooltip />
                    <Bar stackId="a" dataKey="nonRenewable" fill="#5B8FF7" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="nonRenewable" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="renewable" fill="#BDD1F7" radius={[10, 10, 0, 0]} barSize={30}>
                        <LabelList dataKey="renewable" position="top"  style={{ zIndex: 999 }}  />
                    </Bar>
                    <Legend verticalAlign="bottom" align="center" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default BarOne;
