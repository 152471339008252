// import React, {useEffect, useState} from "react";
// import Editable from "react-bootstrap-editable";
// import StandardSelect from "./StandardSelect";
// import { SUBTOPICS_URL } from "../constants";
// import { API } from "../../constants/api_url";
// import APIServices from "../../service/APIService";
// import ClientSelect from "./ClientSelect";

// const SubTopicEdit = (props) => {
//     const [values, setValues] = useState([])
//     const [clientList, setClientList] = useState([])
//     const [client, setClients] = useState([])
//     useEffect(() => {
         
 
//     let datas = [];
//         let promise = new Promise((resolve, error) => {
//             APIServices.get(API.UserProfile).then((res) => {
              

//                 res.data.forEach((item, index) => {
//                     if (item.role === "clientadmin") {
//                         datas.push({
//                             id: item.id,
//                             contactpersonmail: item.information.contactpersonmail,
//                             contactpersonno: item.information.contactpersonno,
//                             companyname: item.information.companyname,
//                             contactperson: item.information.contactperson,
//                             email: item.email,
//                             currency: item.information.currency === undefined ? null : item.information.currency,
//                             config: item.information.config,
//                             companylogo: item.information.companylogo, item
//                         });
//                     }
//                     if (index === res.data.length - 1) {
//                         resolve(datas);
//                     }
//                 });
//             });
//         });

//         promise.then((d) => {
//             setClientList(d);
//             console.log(d);
            
//         });

//       if(props.id) {
//         //Initiate options
//         getValues()
//       } 
//     }, [props.id])

//     const  getValues = async () => {
//       const response = await fetch(`${SUBTOPICS_URL}/${props.id}`);
//       if(response.ok) {
//         const data = await response.json();
        
//         setValues(JSON.parse(data.standards))
        
//       }
//     }

//     const handleOnChangeValues = (newValues) => {
//       handleUpdateValues(newValues)
//       setValues(newValues)
//     }

//     const handleOnChangeClientValues = (newValues) => {
//       setClients(newValues)
//     }

//     const handleUpdateValues = async (value) => {
//       const newValue = JSON.stringify(value)
//       const url = SUBTOPICS_URL + '/' + props.id;
//       const response = await fetch(url, {
//         method: 'PATCH',
//         body: JSON.stringify({
//           standards: newValue,
         
//         }),
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json'
//         },
//       });

      
    
//     }

//     return (
//         <>
       

//           <div className="message-body">
               
//                 <div className="message-content">
//                 <h5>Description</h5>
//                   <Editable initialValue={props.description}  
                            
//                             mode="inline"
//                             onSubmit={props.onSubmit}
//                             type="textarea"
//                             />
//                   <h5 class="mt-5">Choosen Clients</h5>
//                   <ClientSelect values={client} onChangeValues={handleOnChangeClientValues}/>
//                   <h5 class="mt-5">Choose Applicable Standards</h5>
//                   <StandardSelect values={values} onChangeValues={handleOnChangeValues} /> 
                  
//                 </div>
                
//               </div>
//         </>
//     )
// }

// export default SubTopicEdit;


import React, { useEffect, useState } from "react";
import Editable from "react-bootstrap-editable";
import StandardSelect from "./StandardSelect";
import { SUBTOPICS_URL } from "../constants";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import ClientSelect from "./ClientSelect";
import { Button } from "react-bootstrap";

const SubTopicEdit = (props) => {
    const [values, setValues] = useState([])
    const [values2, setValues2] = useState([])
    const [clientList, setClientList] = useState([])
    const [client, setClients] = useState([])

    useEffect(() => {
        

        if (props.id) {
            fetchClientList()
            getValues()
        }
    }, [props.id])

    const fetchClientList = async () => {
        try {
            const res = await APIServices.get(API.UserProfile)
            setClientList(res.data.filter(i=> i.role === "clientadmin" ).map((i) => {
                return {label: i.information.enterpriseid, value: i.id}
  
            }).filter(i => i.label && i.label.length !== 0 ) )
        } catch (error) {
            console.error('Failed to fetch clients:', error)
        }
    }

    const getValues = async () => {
        try {
            const response = await fetch(`${SUBTOPICS_URL}/${props.id}`)
            if (response.ok) {
                const data = await response.json()
                console.log(data)
                setValues(JSON.parse(data.standards))
                setValues2(data.clients)
            }
        } catch (error) {
            console.error('Failed to fetch values:', error)
        }
    }

    const handleOnChangeValues = (newValues) => {
        handleUpdateValues(newValues)
        setValues(newValues)
    }

    const handleOnChangeClientValues = (newValues) => {
        
        handleUpdateClientValues(newValues)
        setValues2(newValues)
    }

    const handleUpdateValues = async (value) => {
        const newValue = JSON.stringify(value)
        const url = SUBTOPICS_URL + '/' + props.id
        try {
            await fetch(url, {
                method: 'PATCH',
                body: JSON.stringify({ standards: newValue }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } catch (error) {
            console.error('Failed to update values:', error)
        }
    }

    const handleUpdateClientValues = async (value) => {
        // const newValue = JSON.stringify(value)
        console.log(value);
        const url = SUBTOPICS_URL + '/' + props.id
        try {
            APIServices.patch(url, { clients: value.map(i => i.value) })
        } catch (error) {
            console.error('Failed to update values:', error)
        }
    }


   

    return (
        <>
            <div className="message-body">
                <div className="message-content">
                    <h5>Description</h5>
                    <Editable initialValue={props.description}
                        mode="inline"
                        onSubmit={props.onSubmit}
                        type="textarea"
                    />
                    <h5 className="mt-5">Choosen Clients </h5>
                    <ClientSelect values={values2} onChangeValues={handleOnChangeClientValues} />
                    <h5 className="mt-5">Choose Applicable Standards</h5>
                    <StandardSelect values={values} onChangeValues={handleOnChangeValues} />
                </div>
            </div>
        </>
    )
}

export default SubTopicEdit
