import $ from "jquery";
import React, {Component, createRef, useRef, useEffect} from "react";
import Swal from "sweetalert2";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const FormBuilder = (props) => {

  const fb = useRef();

  useEffect(() => { 
    const options = {
      
      
      disabledActionButtons: ['data'],
     
      disabledAttrs: [
          'access',
          'className',

          'inline',



          'name',

          'other',


          'rows',
          'step',
          'style',
          'subtype',
          'toggle'

      ],
      disableFields: ['autocomplete', 'button', 'hidden', 'number', 'checkbox-group', 'date', 'header',
          'select', 'starRating', 'text',
          'file'
      ],
      onSave: function(evt, formData) {
          
          props.onSubmit(formData)
          const customSwal2 = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary',
              
            },
            buttonsStyling: false
          })
          customSwal2.fire(
            'Saved!',
            '',
            'success')

      },
  };  
   let formBuilderPlugin = $(fb.current).formBuilder(options);
   setTimeout(() => formBuilderPlugin.actions.setData(props.values), 500)
  
  }, [])

  return (
  <>
     <h5>Create Question / Options</h5>
      <div id="fb-editor" ref={fb} />
  </>
 
  
  );
}

// class FormBuilder extends Component {
//     fb = createRef();
//     componentDidMount() {
//       $(this.fb.current).formBuilder();
//     }
  
//     render() {
//       return <div id="fb-editor" ref={this.fb} />;
//     }
//   }

export default FormBuilder