import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { wasteData } from "./WasteManagementChart";

const data = [
  {
    name: "2019",
    Reused: 12,
    Recycled: 25,
    Composted: 18,
    EnergyRecovery: 10,
    Landfill: 35,
  },
  {
    name: "2020",
    Reused: 15,
    Recycled: 27,
    Composted: 16,
    EnergyRecovery: 12,
    Landfill: 30,
  },
  {
    name: "2021",
    Reused: 13,
    Recycled: 24,
    Composted: 20,
    EnergyRecovery: 9,
    Landfill: 34,
  },
  {
    name: "2022",
    Reused: 16,
    Recycled: 26,
    Composted: 19,
    EnergyRecovery: 11,
    Landfill: 28,
  },
  {
    name: "2023",
    Reused: 14,
    Recycled: 28,
    Composted: 17,
    EnergyRecovery: 10,
    Landfill: 31,
  },
];

const colors = {
  Reused: "#00876C",
  Recycled: "#7A5195",
  Composted: "#EF5675",
  EnergyRecovery: "#FFA600",
  Landfill: "#003F5C",
};

const OverviewNonHazardous = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Non-Hazardous Waste by Disposal Modes - %
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Percentage of Non-Hazardous Waste through Different Disposal Modes
          </div>
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19" />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode ? (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barSize={80}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(colors).map((key) => (
              <Bar key={key} dataKey={key} stackId="a" fill={colors[key]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div>
          <DataTable
            ref={dt}
            value={wasteData[2019]}
            tableClassName="font-lato"
          >
            <Column
              header="Reused %"
              style={{ minWidth: "8%" }}
              field="preparedReuse"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Recycled %"
              style={{ minWidth: "8%" }}
              field="recycled"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Composed %"
              style={{ minWidth: "8%" }}
              field="incineration"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Energy Recovery %"
              style={{ minWidth: "8%" }}
              field="incinerationEnergy"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Landfill %"
              style={{ minWidth: "8%" }}
              field="incinerationEnergy"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default OverviewNonHazardous;
