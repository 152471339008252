import React from "react";

function Breadcrumbs(props) {
    return (
        <nav aria-label="breadcrumb"> 
            <ol className="breadcrumb">
             {
                props.pages.map((page, index) => {
                    return <li key={index} className="breadcrumb-item">{page}</li>
                   
                })
             }
              
            </ol>
          </nav>
    );
}

export default Breadcrumbs

