import React from "react";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";

import { WasteManagement } from "./WasteManagementChart";
import { WaterGeneratedChart } from "./WaterGeneratedChart";
import { WaterConsumedChart } from "./WaterConsumed";
import { WaterDischargedData } from "./WaterDischarged";
import { WomenRatioChart } from "./WomenRatioChart";
import { GenderPieChart } from "./GenderPieChart";
import EmissionsCards from "./EmissionsCard";
import OverviewScope1 from "./OverviewScope1";
import OverviewScope3 from "./OverviewScope3";
import OverviewWaste from "./OverviewWaste";
import OverviewHazardous from "./OverviewHazardous";
import OverviewNonHazardous from "./OverviewNonHazardous";
import OverviewWater from "./OverviewWater";
import OverviewWaterDischarge from "./OverviewWaterDischarge";
import LTIFChart from "./Ltif";
export const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "14px" }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

const LineChart = ({ data }) => {
  const options = {
    plugins: {
      legend: { display: false },
    },
    elements: {
      line: {
        borderColor: "#28a745",
        borderWidth: 1.5,
      },
      point: {
        radius: 2,
        backgroundColor: "#28a745",
      },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    },
  };

  return (
    <Chart
      type="line"
      data={data}
      options={options}
      style={{
        position: "absolute",
        width: "5rem",
        top: 440,
        marginLeft: "10rem",
      }}
    />
  );
};

// const DashboardCard = ({ percentage, description, chartData }) => {
//   return (
//     <Card
//       style={{
//         width: "25%",
//         height: "9rem",
//         margin: "0.5rem",
//         padding: "0.5rem",
//         display: "flex",
//       }}
//     >
//       <div style={{ width: "60%" }}>
//         <div style={{ fontSize: "14px", fontWeight: "bold" }}>{percentage}</div>
//         <div style={{ marginBottom: "1rem", color: "#666" }}>{description}</div>
//       </div>
//       <LineChart data={chartData} />
//     </Card>
//   );
// };

const Dashboard = () => {
  // const dataTemplate1 = {
  //   labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  //   datasets: [
  //     {
  //       data: [80, 85, 90, 87, 92],
  //       fill: false,
  //       borderColor: "#28a745",
  //       tension: 0.4,
  //     },
  //   ],
  // };

  // const dataTemplate2 = {
  //   labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  //   datasets: [
  //     {
  //       data: [60, 95, 80, 77, 62],
  //       fill: false,
  //       borderColor: "#FF9D7C",
  //       tension: 0.4,
  //     },
  //   ],
  // };
  // const dataTemplate3 = {
  //   labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  //   datasets: [
  //     {
  //       data: [40, 75, 50, 57, 92],
  //       fill: false,
  //       borderColor: "#4569B2",
  //       tension: 0.4,
  //     },
  //   ],
  // };

  // const dataTemplate4 = {
  //   labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  //   datasets: [
  //     {
  //       data: [60, 35, 80, 77, 92],
  //       fill: false,
  //       borderColor: "#F9DF7F",
  //       tension: 0.4,
  //     },
  //   ],
  // };

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          background: "#f8f9fa",
        }}
      >
        <DashboardCard
          percentage="87%"
          description="Renewable Energy Consumption"
          chartData={dataTemplate1}
        />
        <DashboardCard
          percentage="54"
          description="Total Quantity of waste diverted from landfill"
          chartData={dataTemplate2}
        />
        <DashboardCard
          percentage="60%"
          description="Percentage of water recycled"
          chartData={dataTemplate3}
        />
        <DashboardCard
          percentage="90%"
          description="Percentage of women employees in workforce"
          chartData={dataTemplate4}
        />
      </div> */}

      <EmissionsCards />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Energy and Emissions
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Energy mix and Carbon emissions across the organization
        </div>
      </div>
      <OverviewScope1 />
      <OverviewScope3 />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Waste Management
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Managing the collection and disposal of waste across the organization
        </div>
      </div>
      <OverviewWaste />
      <OverviewHazardous />
      <OverviewNonHazardous />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Water Stewardship
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Management of water withdrawal and disposal
        </div>
      </div>
      <OverviewWater />
      <OverviewWaterDischarge />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Safety
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Employee safety performance across the organization
        </div>
      </div>
      <LTIFChart />
    </>
  );
};

export default Dashboard;
