import React from 'react';
import { Card } from 'primereact/card';
import { Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';

const BarFour = () => {
    const data = [
        { month: 'Jan', coal: 10, fuelOil: 20, woodChips: 15, diesel: 5 },
        { month: 'Feb', coal: 15, fuelOil: 25, woodChips: 18, diesel: 8 },
        { month: 'Mar', coal: 20, fuelOil: 22, woodChips: 20, diesel: 10 },
        { month: 'Apr', coal: 18, fuelOil: 18, woodChips: 22, diesel: 12 },
        { month: 'May', coal: 25, fuelOil: 15, woodChips: 25, diesel: 15 },
        { month: 'Jun', coal: 30, fuelOil: 12, woodChips: 28, diesel: 18 },
        { month: 'Jul', coal: 22, fuelOil: 10, woodChips: 30, diesel: 20 },
        { month: 'Aug', coal: 28, fuelOil: 8, woodChips: 32, diesel: 22 },
        { month: 'Sep', coal: 20, fuelOil: 15, woodChips: 35, diesel: 25 },
        { month: 'Oct', coal: 18, fuelOil: 18, woodChips: 38, diesel: 28 },
        { month: 'Nov', coal: 15, fuelOil: 22, woodChips: 40, diesel: 30 },
        { month: 'Dec', coal: 12, fuelOil: 25, woodChips: 42, diesel: 32 },
    ];

    return (
        <Card>
            <p className='card-title'>Energy consumption (TJ)</p>
            <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>

            <ResponsiveContainer width="100%" height={500}>
                <BarChart data={data}>
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={() => ''} />
                    <Tooltip />
                    <Bar stackId="a" dataKey="coal" fill="#C0FFDC" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="coal" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="fuelOil" fill="#7BD4C2" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="fuelOil" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="woodChips" fill="#00B08C" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="woodChips" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="diesel" fill="#475467" radius={[10, 10, 0, 0]} barSize={30}>
                        <LabelList dataKey="diesel" position="top" />
                    </Bar>
                    <Legend verticalAlign="bottom" align="center" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default BarFour;
