import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomLegend } from "./Dashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomFuelLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 1 && (
            <li
              key={`item-${index}`}
              style={{
                color: entry.color,

                marginRight: "5px",
              }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const fuelData = {
  2019: [
    { month: "Jan", mineralFuel: 120, biofuelBlend: 85 },
    { month: "Feb", mineralFuel: 150, biofuelBlend: 60 },
    { month: "Mar", mineralFuel: 95, biofuelBlend: 110 },
    { month: "Apr", mineralFuel: 160, biofuelBlend: 45 },
    { month: "May", mineralFuel: 140, biofuelBlend: 80 },
    { month: "Jun", mineralFuel: 90, biofuelBlend: 130 },
    { month: "Jul", mineralFuel: 130, biofuelBlend: 70 },
    { month: "Aug", mineralFuel: 180, biofuelBlend: 40 },
    { month: "Sep", mineralFuel: 85, biofuelBlend: 125 },
    { month: "Oct", mineralFuel: 145, biofuelBlend: 60 },
    { month: "Nov", mineralFuel: 95, biofuelBlend: 110 },
    { month: "Dec", mineralFuel: 110, biofuelBlend: 95 },
  ],
  2020: [
    { month: "Jan", mineralFuel: 170, biofuelBlend: 50 },
    { month: "Feb", mineralFuel: 140, biofuelBlend: 90 },
    { month: "Mar", mineralFuel: 160, biofuelBlend: 65 },
    { month: "Apr", mineralFuel: 120, biofuelBlend: 100 },
    { month: "May", mineralFuel: 155, biofuelBlend: 75 },
    { month: "Jun", mineralFuel: 130, biofuelBlend: 85 },
    { month: "Jul", mineralFuel: 140, biofuelBlend: 95 },
    { month: "Aug", mineralFuel: 115, biofuelBlend: 110 },
    { month: "Sep", mineralFuel: 130, biofuelBlend: 105 },
    { month: "Oct", mineralFuel: 150, biofuelBlend: 90 },
    { month: "Nov", mineralFuel: 95, biofuelBlend: 140 },
    { month: "Dec", mineralFuel: 100, biofuelBlend: 125 },
  ],
  2021: [
    { month: "Jan", mineralFuel: 110, biofuelBlend: 120 },
    { month: "Feb", mineralFuel: 130, biofuelBlend: 95 },
    { month: "Mar", mineralFuel: 125, biofuelBlend: 115 },
    { month: "Apr", mineralFuel: 165, biofuelBlend: 55 },
    { month: "May", mineralFuel: 135, biofuelBlend: 105 },
    { month: "Jun", mineralFuel: 95, biofuelBlend: 130 },
    { month: "Jul", mineralFuel: 150, biofuelBlend: 70 },
    { month: "Aug", mineralFuel: 140, biofuelBlend: 80 },
    { month: "Sep", mineralFuel: 155, biofuelBlend: 85 },
    { month: "Oct", mineralFuel: 100, biofuelBlend: 130 },
    { month: "Nov", mineralFuel: 90, biofuelBlend: 160 },
    { month: "Dec", mineralFuel: 110, biofuelBlend: 145 },
  ],
  2022: [
    { month: "Jan", mineralFuel: 130, biofuelBlend: 95 },
    { month: "Feb", mineralFuel: 110, biofuelBlend: 125 },
    { month: "Mar", mineralFuel: 160, biofuelBlend: 85 },
    { month: "Apr", mineralFuel: 145, biofuelBlend: 95 },
    { month: "May", mineralFuel: 125, biofuelBlend: 135 },
    { month: "Jun", mineralFuel: 170, biofuelBlend: 55 },
    { month: "Jul", mineralFuel: 155, biofuelBlend: 75 },
    { month: "Aug", mineralFuel: 140, biofuelBlend: 110 },
    { month: "Sep", mineralFuel: 130, biofuelBlend: 120 },
    { month: "Oct", mineralFuel: 115, biofuelBlend: 105 },
    { month: "Nov", mineralFuel: 95, biofuelBlend: 140 },
    { month: "Dec", mineralFuel: 100, biofuelBlend: 125 },
  ],
  2023: [
    { month: "Jan", mineralFuel: 180, biofuelBlend: 55 },
    { month: "Feb", mineralFuel: 145, biofuelBlend: 75 },
    { month: "Mar", mineralFuel: 135, biofuelBlend: 110 },
    { month: "Apr", mineralFuel: 120, biofuelBlend: 140 },
    { month: "May", mineralFuel: 155, biofuelBlend: 85 },
    { month: "Jun", mineralFuel: 105, biofuelBlend: 130 },
    { month: "Jul", mineralFuel: 135, biofuelBlend: 105 },
    { month: "Aug", mineralFuel: 115, biofuelBlend: 130 },
    { month: "Sep", mineralFuel: 130, biofuelBlend: 115 },
    { month: "Oct", mineralFuel: 90, biofuelBlend: 160 },
    { month: "Nov", mineralFuel: 125, biofuelBlend: 120 },
    { month: "Dec", mineralFuel: 145, biofuelBlend: 95 },
  ],
  2024: [
    { month: "Jan", mineralFuel: 100, biofuelBlend: 140 },
    { month: "Feb", mineralFuel: 125, biofuelBlend: 115 },
    { month: "Mar", mineralFuel: 165, biofuelBlend: 95 },
    { month: "Apr", mineralFuel: 155, biofuelBlend: 110 },
    { month: "May", mineralFuel: 95, biofuelBlend: 135 },
    { month: "Jun", mineralFuel: 140, biofuelBlend: 115 },
    { month: "Jul", mineralFuel: 175, biofuelBlend: 80 },
    { month: "Aug", mineralFuel: 160, biofuelBlend: 85 },
    { month: "Sep", mineralFuel: 120, biofuelBlend: 135 },
    { month: "Oct", mineralFuel: 90, biofuelBlend: 165 },
    { month: "Nov", mineralFuel: 130, biofuelBlend: 120 },
    { month: "Dec", mineralFuel: 150, biofuelBlend: 110 },
  ],
};

const FuelConsumptionChart = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = fuelData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} MineralFuel`]: item.mineralFuel,
    [`${year1} BiofuelBlend`]: item.biofuelBlend,
    [`${year2} MineralFuel`]: fuelData[year2][index].mineralFuel,
    [`${year2} BiofuelBlend`]: fuelData[year2][index].biofuelBlend,
  }));
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Breakdown of Fuel Consumption
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Quantity of fuel used in operations
          </div>
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(fuelData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(fuelData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100.1%" height={400}>
          <BarChart
            data={combinedData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barGap={5}
            barSize={30}
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                value: "Million Tons",
                angle: -90,
                position: "insideLeft",
                offset: 0,
                style: { textAnchor: "middle", fill: "#555" },
              }}
            />{" "}
            <Tooltip />
            <Legend content={CustomFuelLegend} />
            <Bar
              dataKey={`${year1} MineralFuel`}
              stackId="a"
              fill="#F28C82"
              name="Mineral Fuel"
            ></Bar>
            <Bar
              dataKey={`${year1} BiofuelBlend`}
              stackId="a"
              fill="#FEDBD0"
              name="Bio-Fuel Blend"
            ></Bar>
            <Bar
              dataKey={`${year2} MineralFuel`}
              stackId="b"
              fill="#F28C82"
              t
            ></Bar>
            <Bar
              dataKey={`${year2} BiofuelBlend`}
              stackId="b"
              fill="#FEDBD0"
            ></Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={fuelData[year1].map((item, index) => ({
              ...item,
              [`mineralFuel_${year2}`]: fuelData[year2][index].mineralFuel,
              [`biofuelBlend_${year2}`]: fuelData[year2][index].biofuelBlend,
            }))}
            tableClassName="font-lato"
            style={{ width: "100%" }}
          >
            <Column
              header="Month"
              style={{ minWidth: "8%" }}
              field="month"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Mineral Fuel - ${year1}`}
              style={{ minWidth: "8%" }}
              field="mineralFuel"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Biofuel Blend - ${year1}`}
              style={{ minWidth: "8%" }}
              field="biofuelBlend"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Mineral Fuel - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`mineralFuel_${year2}`}
              emptyMessage="No Assignment(s)"
            />
            <Column
              header={`Biofuel Blend - ${year2}`}
              style={{ minWidth: "8%" }}
              field={`biofuelBlend_${year2}`}
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};
export default FuelConsumptionChart;
