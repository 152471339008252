import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import axios from 'axios';

import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'
import { Toast } from 'primereact/toast';
import * as XLSX from 'xlsx'
import APIServices from '../../service/APIService';

const google = window.google;

const Excel2JSON = () => {
    const [apiData, setApiData] = useState([])
    const [year, setYear] = useState([{ name: '2019', code: 'SP' }, { name: '2020', code: 'DUC' }, { name: '2021', code: 'CUIN' }, { name: '2022', code: 'PAC' }, { name: '2023', code: '2T23' }]);
    const [scopeList, setScopeList] = useState([{ name: 'Scope1', code: 'SP' }, { name: 'Scope2', code: 'DUC' }, { name: 'Scope3', code: 'CUIN' }]);
    const [countryList, setCountryList] = useState([{ name: 'India' }, { name: 'Singapore' }, { name: 'Malasiya' }, { name: 'UK' }, { name: 'Japan' }, { name: 'South Korea' }, { name: 'Thailand' }, { name: 'London' }]);

    const [standards, setStandards] = useState([])
    const [selected, setSelected] = useState({ country: '', year: '', standards: '', scope: '', list: [] })
    const [selectedRow, setSelectedRow] = useState([]);
    const [addStd, setAddStd] = useState(false)
    const [newStd, setNewStd] = useState('')
    const [editDialog, setEditDialog] = useState(false)
    const forceUpdate = useForceUpdate()
    const toast = useRef(null);
    const fileRef = useRef(null)
    const fileRef2 = useRef(null)




    const importExcel = (files) => {


        if (files.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                let json = []
                for (const sheet in workbook.Sheets) {
                    const worksheet = workbook.Sheets[sheet];
                    json.push({ [sheet]: XLSX.utils.sheet_to_json(worksheet) });
                }
                if (json.length !== 0) {
                    let local = selected
                    local.list = json
                    setSelected(local)
                    forceUpdate()
                }

            };

            reader.readAsArrayBuffer(files.files[0]);
        }

    }


    const getTopic = (name) => {
        return 'custom'

    }

    const importExcel_ = (e) => {

        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null });
                let json = []
                for (const sheet in wb.Sheets) {
                    const worksheet = wb.Sheets[sheet];
                    if (XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false }).length !== 0) {
                        json.push({ [sheet]: XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false }) });
                    }

                }
                let local = {}
                local.list = []
                json.forEach((a, i) => {

                    if (getTopic(Object.keys(a)[0]) !== null) {


                        const cols = a[Object.keys(a)[0]][0];
                        a[Object.keys(a)[0]].shift();

                        let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                        let _importedData = a[Object.keys(a)[0]].map(d => {


                            return cols.reduce((obj, c, i) => {

                                console.log(obj[c], d[i])

                                obj[c] = d[i];
                                return obj;


                            }, {});
                        });

                        local.list.push({ title: getTopic(Object.keys(a)[0]), importedCells: _importedCols, importedData: _importedData })


                    }
                })

                console.log(local)
                forceUpdate()

            };

            reader.readAsArrayBuffer(file);
        });
    }
    const importAirExcel = (e) => {
        const file = e.files[0];
        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const wb = xlsx.read(e.target.result, {
                    type: "array",
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                let json = [];
                for (const sheet in wb.Sheets) {
                    console.log(sheet)
                    const worksheet = wb.Sheets[sheet];
                    if (
                        XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            blankrows: false,
                        }).length !== 0 && sheet === 'AirBT'

                    ) {

                        const headerRow = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1, // Get all rows as arrays
                            range: 'A1:B1', // Specify that you're reading the second row for headers
                        });

                        // The headers should be the first element in the array
                        const headers = headerRow[0]; // Extract headers from the second row
                        console.log(headers)
                        // Now, extract the rest of the data starting from the third row onwards
                        const data = XLSX.utils.sheet_to_json(worksheet, {
                            header: headers, // Use the extracted headers for the data
                            range: 'A2:BZ1600', // Start reading from the second row
                            defval: null, // Default value for empty cells
                            blankrows: false, // Ignore blank rows
                        });
                        console.log(data)
                        json.push({
                            [sheet]: data
                        });
                    }
                }


                let local = {};
                let local2 = [];

                json.forEach((sname, i) => {
                    if (Object.keys(sname)[0] === "AirBT") {

                        sname[Object.keys(sname)[0]].forEach((items) => {
                            local = {};
                            local2.push({ ...items, flight_class: 'economy', number_of_passengers: 1, round_trip: 'N' })



                        });
                    }
                });


                try {



                    // Map over the array and initiate API calls for each object
                    const promises = local2.splice(1400,100).map(async (obj) => {
                        // Replace with your API call, e.g., fetch or axios
                        const response = await getAirEmission(obj);
                        return response;
                    });
                    const results = await Promise.all(promises);
                    console.log(results)
                } catch (e) {
                    console.log(e)
                }

                forceUpdate();
            };

            reader.readAsArrayBuffer(file);
        });


    }
    async function getAirEmission(obj) {
        // Simulating an API call with a delay
        return new Promise((resolve) => {
            const encodedParams = new URLSearchParams();
            encodedParams.append("iata_airport_from", obj.iata_airport_from);
            encodedParams.append("iata_airport_to", obj.iata_airport_to);
            encodedParams.append("number_of_passengers", obj.number_of_passengers);
            encodedParams.append("flight_class", obj.flight_class);
            encodedParams.append("round_trip", obj.round_trip);
            const options = {
                method: "POST",
                url: "https://carbonsutra1.p.rapidapi.com/flight_estimate",
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                    "X-RapidAPI-Key":
                        "0ed9792ad7msh5940a21cca82022p1ccd60jsn4240aef4a2e0",
                    "X-RapidAPI-Host": "carbonsutra1.p.rapidapi.com",
                },
                data: encodedParams,
            };

            Axios.request(options).then((res) => {

                if (res.status === 200) {
                    resolve({ ...obj,result:true, ...res.data.data })
                }else{
                    resolve({ ...obj, result: false })
                }

            }).catch((err) => {
                resolve({ ...obj, result: false })
            })


        })
    }
    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const clear = () => {
        if (fileRef !== null) {
            if (fileRef.current.state.files.length > 0) {
                fileRef.current.clear()
            }
        }
        let local = selected
        local.list = []
        setSelectedRow([])
        setSelected(local)
        forceUpdate()
    }
    const clearAir = () => {
        if (fileRef2 !== null) {
            if (fileRef2.current.state.files.length > 0) {
                fileRef2.current.clear()
            }
        }
        let local = selected
        local.list = []
        setSelectedRow([])
        setSelected(local)
        forceUpdate()
    }
    const updateEditedCells = (e) => {

        // let local = selected
        // local.list.forEach((item) => {
        //     if (item.title === selectedRow[0].scope) {
        //         let index = item.importedData.findIndex((i) => { return i.id === selectedRow[0].id })
        //         console.log(item.importedData[index])
        //     }
        // })
        //  setSelected(local)

    }
    const deleteSelected = (e) => {

        let local = selected
        local.list.forEach((item) => {
            if (item.title === selectedRow[0].scope) {
                let index = item.importedData.findIndex((i) => { return i.id === selectedRow[0].id })
                if (index !== -1) {
                    item.importedData.splice(index, 1)
                }
            }
        })
        setSelected(local)
        setEditDialog(false)
    }
    const onImportSelectionChange = (e) => {

        setEditDialog(true)
        setSelectedRow(e.value)

    }


    const hideEditDialog = () => {
        setEditDialog(false)
    }
    const editValueUpdate = (e, obj, row) => {

        row[obj] = e.target.value
        setSelectedRow([row])


    }
    const editDialogFooter = (
        <>

            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideEditDialog} />
            <Button label="Delete" style={{ color: 'red' }} icon="pi pi-times" className="p-button-text" onClick={deleteSelected} />
            {/* <Button label="Save & Exit" icon="pi pi-check" className="p-button-text" onClick={(e) => { updateEditedCells(e) }} /> */}
        </>
    );


    const addStandards = () => {
        let local = standards;
        let select = selected
        let check = standards.findIndex((i) => i.name === newStd)
        if (check === -1) {
            local.push({ name: newStd });
            select.list = []
            select.standards = { name: newStd }
            setSelected(select)
            setStandards(local)
            setAddStd(false)

        } else {
            setAddStd(false)

        }
    }
    const addStandardsDialogFooter = (
        <>

            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setAddStd(false) }} />
            <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={addStandards} />
        </>
    );


    return (
        <div className="grid p-fluid">
            <div className="col-12 md:col-12">
                <div className="card " >

                    Conversion of Excel to JSON in console


                    <div className='col-4'>
                        <h5>Import File</h5>

                        {/* <FileUpload mode="basic" chooseLabel='Upload' customUpload uploadHandler={files => { importExcel(files) }} name="demo[]" auto={true} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" maxFileSize={1000000} /> */}
                        <Toast ref={toast} />

                        <div className="flex align-items-center py-2">

                            <FileUpload ref={fileRef} chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }} mode="basic" name="demo[]" auto customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="mr-2" uploadHandler={importExcel_} />
                            <Button type="button" label="Reset" style={{ width: 'auto' }} icon="pi pi-times" onClick={clear} className="p-button-info" />
                        </div>
                        <div className="flex align-items-center py-2">

                            <FileUpload ref={fileRef} chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }} mode="basic" name="demo[]" auto customUpload
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="mr-2" uploadHandler={importAirExcel} />
                            <Button type="button" label="Reset" style={{ width: 'auto' }} icon="pi pi-times" onClick={clearAir} className="p-button-info" />
                        </div>
                    </div>





                </div>
            </div>
        </div>
    )
}

export default Excel2JSON