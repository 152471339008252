import React, { useEffect, useState, useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import { MultiSelect } from 'primereact/multiselect';
import axios from "axios";
import $ from 'jquery'

import { Checkbox } from "primereact/checkbox";
import { AutoComplete as AC } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Chips } from "primereact/chips";
import { Editor } from "primereact/editor";

import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import Axios from "axios";
import { DateTime } from "luxon";
// Timeline,boundry & category
const google = window.google;
window.jQuery = $;
window.$ = $;

let row = 0
const GTIA = () => {

    const [objdialog, setObjDialog] = useState(false)
    const [approwindex, setAppRowIndex] = useState(null)
    const [selectLoc, setSelectLoc] = useState({ country: 'All', city: { name: 'All', value: 'All' }, location: { name: 'All', value: 'All' } })
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })

    const [inactiondialog, setInActionDialog] = useState(false)
    const [inaction, setInAction] = useState({ initiatives: ['', '', null], status: [['', '', '', 1]], responsibility: '', applicability: { country: 'All', city: 'All', location: 'All' }, indicator: 0 })
    const [historydialog, setHistoryDialog] = useState(false)
    const [historydata, setHistoryData] = useState([])
    const [boundarydialog, setBoundarydialog] = useState(false)
    const [indicatordialog, setIndicatorDialog] = useState(false)
    const [newgoal, setNewGoal] = useState({ title: '', category: null, alignment: null, alignment_tag: [], boundary: [], sdg: [] });
    const [selected, setSelected] = useState({ country: { name: 'All' }, city: '', location: '' })
    const [objective, setObjective] = useState([])
    const [initiatives, setInitiatives] = useState([])
    const [categorylist, setCategoryList] = useState([])
    const [newindicator, setNewIndicator] = useState({ title: '', type: 0, frequency: 'yearly', interim_frequency: 'yearly', interim_target: [{ xaxis: null, unit: '', yaxis: '' }], baseValue: '', targetValue: '', baseDate: null, targetDate: null, baseUnit: '', indicator: null })
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);

    const forceUpdate = useForceUpdate();
    const [addStatusdialog, setAddStatusdialog] = useState(false)
    const [addActiondialog, setAddActiondialog] = useState(false)
    const [targetdialog, setTargetDialog] = useState(false)
    const [newtarget, setNewTarget] = useState({ title: '', description: '', type: 0, comments: [], data1: [], created_on: moment().utc(), created_by: login_data.id })
    // const [newtarget, setNewTarget] = useState([]);
    const [newstatus, setNewStatus] = useState({ text: '', responsibility: '', indicator: 3 })
    const [newaction, setNewAction] = useState('')
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);
    const [general, setGeneral] = useState({
        area: "",
        empnos: "",
    });
    let indicator_list = [{ name: "Progress as planned", value: 3 }, { name: 'Progress somewhat behind plan', "value": 2 }, { name: 'Progress significantly behind plan', value: 1 }]
    const [emissionFactor, setEmissionFactor] = useState({
        owned: [],
        refrig: [],
        diesel: [],
        bt: [],
        pgas: [],
    });
    const [apiData, setApiData] = useState([]);
    const iclass = useRef(null)
    const [scope1listValue, setScope1listValue] = useState();
    const [scope2listValue, setScope2listValue] = useState();
    const [scope3listValue, setScope3listValue] = useState();
    const [calendarValue, setCalendarValue] = useState();
    const [selectedScope, setSelectedScope] = useState({});
    const [selectedCity1, setSelectedCity1] = useState(null);
    const [selectedCity2, setSelectedCity2] = useState(null);
    const [TabIndex, setTabIndex] = useState(0);
    const [pending, setPending] = useState([]);
    const [GFD, setGFD] = useState(false);
    const [rawcity, setRawCity] = useState([
        {
            India: [
                {
                    name: "Ahmedabad",
                },
                {
                    name: "Bengaluru",
                },
                {
                    name: "Delhi",
                },
                {
                    name: "Hyderabad",
                },
                {
                    name: "Kolkata",
                },
                {
                    name: "Mumbai",
                },
                {
                    name: "Noida",
                },
                {
                    name: "Pune",
                },
                {
                    name: "Chennai",
                },
            ],
            Singapore: [
                {
                    name: "Singapore",
                },
            ],
            Phillinpines: [
                {
                    name: "Metro Manila",
                },
                {
                    name: "Calabarzon",
                },
                {
                    name: "Davao",
                },
            ],
            Indonesia: [
                {
                    name: "Bekasi",
                },
            ],
            Japan: [
                {
                    name: "Tokyo",
                },
            ],
            "South Korea": [
                {
                    name: "Seoul",
                },
            ],
            Thailand: [
                {
                    name: "Bangkok",
                },
            ],
            UK: [
                {
                    name: "London",
                },
            ],
            China: [
                {
                    name: "Shanghai",
                },
                {
                    name: "Kunshan",
                },
                {
                    name: "Changshu",
                },
                {
                    name: "Nantong",
                },
                {
                    name: "Wuhan",
                },
                {
                    name: "Chengdu",
                },
                {
                    name: "Ulanqab",
                },
                {
                    name: "Beijing",
                },
                {
                    name: "Langfang",
                },
                {
                    name: "Shenzhen",
                },
                {
                    name: "Hongkong",
                },
                {
                    name: "Heyuan",
                },
            ],
        },
    ]);
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [rawLocation, setRawLocation] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [location, setLocation] = useState([]);
    const [s1ad, setS1AD] = useState(false);
    const [s1bd, setS1BD] = useState(false);
    const [s1cd, setS1CD] = useState(false);
    const [s2ad, setS2AD] = useState(false);
    const [s3ad, setS3AD] = useState(false);
    const [s3bd, setS3BD] = useState(false);
    const [s3cd, setS3CD] = useState(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyBAfSU3pX76vkQSAyo9YopnWBfN_CTTEd4",
        libraries: ["places", "drawing", "geometry", "localContext", "places", "visualization"],
    });

    const [s1select, setS1Select] = useState();
    const [s3select, setS3Select] = useState();
    const [scountry, setSCountry] = useState(null);
    const [acType, setACType] = useState([
        {
            name: "Split",
            code: "SP",
        },
        {
            name: "Ductable",
            code: "DUC",
        },
        {
            name: "Casstte Unit",
            code: "CUIN",
        },
        {
            name: "PAC",
            code: "PAC",
        },
    ]);
    const [itemclasstype, setItemClassType] = useState([
        {
            name: "Computer electronic product",
        },
        {
            name: "Motor vehicles, bodies and trailers, and parts",
        },
        {
            name: "Publishing industries, except internet ",
        },
        {
            name: "Electrical equipment, appliances, and components",
        },
        {
            name: "Machinery",
        },
        {
            name: "Miscellaneous manufacturing",
        },
        {
            name: "Fabricated metal products",
        },
        {
            name: "Printing and related support activities",
        },
    ]);
    const [gasType, setGasType] = useState([
        {
            name: "R-22",
            code: "R22",
        },
        {
            name: "R-410A",
            code: "R410",
        },
        {
            name: "R-32",
            code: "R32",
        },
        {
            name: "R-134A",
            code: "R134",
        },
        {
            name: "R-290",
            code: "R290",
        },
        {
            name: "R-600A",
            code: "R600",
        },
    ]);
    const [vehType, setVehType] = useState([
        {
            name: "Passenger",
            code: "PSNGR",
        },
        {
            name: "Delivery ",
            code: "DELI",
        },
    ]);
    const [modeType, setModeType] = useState([
        {
            name: "Road",
        },
        {
            name: "Rail",
        },
        {
            name: "Air",
        },
    ]);
    const [classType, setClassType] = useState([
        {
            name: "Business",
        },
        {
            name: "Economy",
        },
        {
            name: "First",
        },
        {
            name: "Premium",
        },
    ]);
    const [vehBtType, setVehBtType] = useState([
        {
            name: "Bus",
            code: "RD",
        },
        {
            name: "Car",
            code: "RL",
        },
        {
            name: "Taxi",
            code: "AR",
        },
        {
            name: "Bike",
            code: "AR",
        },
    ]);
    const [vehCapType, setVehCapType] = useState([
        {
            name: "Small",
            code: "RD",
        },
        {
            name: "Medium",
            code: "RL",
        },
        {
            name: "Large",
            code: "AR",
        },
        {
            name: "Average",
            code: "AR",
        },
    ]);
    const [vehFuelType, setVehFuelType] = useState([
        {
            name: "Diesel",
            code: "RD",
        },
        {
            name: "Petrol",
            code: "RL",
        },
        {
            name: "Hybrid",
            code: "AR",
        },
        {
            name: "CNG",
            code: "AR",
        },
        {
            name: "LPG",
            code: "AR",
        },
        {
            name: "Plug-in Hybrid E Vehicle",
            code: "AR",
        },
        {
            name: "Battery Electric Vehicle",
            code: "AR",
        },
    ]);
    const [vehFuelTypePr, setVehFuelTypePr] = useState([
        {
            name: "Diesel",
            code: "RD",
        },
        {
            name: "Petrol",
            code: "RL",
        },
        {
            name: "Hybrid",
            code: "AR",
        },
        {
            name: "CNG",
            code: "AR",
        },
        {
            name: "LPG",
            code: "AR",
        },
        {
            name: "Plug-in Hybrid E Vehicle",
            code: "AR",
        },
        {
            name: "Battery Electric Vehicle",
            code: "AR",
        },
    ]);
    const [vehFuelTypeBk, setVehFuelTypeBk] = useState([
        {
            name: "Diesel",
            code: "RD",
        },
        {
            name: "Petrol",
            code: "RL",
        },
        {
            name: "Hybrid",
            code: "AR",
        },
        {
            name: "Plug-in Hybrid E Vehicle",
            code: "AR",
        },
        {
            name: "Battery Electric Vehicle",
            code: "AR",
        },
    ]);
    const [slocation, setSLocation] = useState(null);
    const [scity, setSCity] = useState(null);
    const [smonth, setSMonth] = useState(new Date());
    const [apiLength, setApiLength] = useState(0);
    const toast = useRef(null);
    const [refFilter, setRefFilter] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const dt = useRef(null);
    const [refer, setRefer] = useState([]);
    const [refVal, setRefVal] = useState({
        date: new Date(),
        actype: "",
        gastype: "",
        gasrefilled: "",
        tco2e: 0,
        attachment: null,
    });
    const [refDialog, setRefDialog] = useState(false);
    const [deleteRefDialog, setDeleteRefDialog] = useState(false);
    const [deleteRefsDialog, setDeleteRefsDialog] = useState(false);
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });
    const [groute, setGroute] = useState("");
    //
    const [S1A, setS1A] = useState([]);
    const [S1B, setS1B] = useState([]);
    const [S1C, setS1C] = useState([]);
    const [indicatorlist, setIndicatorList] = useState([])
    const [data, setData] = useState({
        s1a: {},
        s1b: {},
        s1b: [],
        s2a: {},
        s3a: [],
        s3b: [],
        s3c: [],
    });
    const appref = useRef(null)
    const [site, setSite] = useState([])
    const [selectedCountries, setSelectedCountries] = useState(null);
    const SDG_list = [
        { name: 'NO POVERTY', code: 1 },
        { name: 'ZERO HUNGER', code: 2 },
        { name: 'GOOD HEALTH AND WELL-BEING', code: 3 },
        { name: 'QUALITY EDUCATION', code: 4 },
        { name: 'GENDER EQUALITY', code: 5 },
        { name: 'CLEAN WATER AND SANITATION', code: 6 },
        { name: 'AFFORDABLE AND CLEAN ENERGY', code: 7 },
        { name: 'DECENT WORK AND ECONOMIC GROWTH', code: 8 },
        { name: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE', code: 9 },
        { name: 'REDUCED INEQUALITIES', code: 10 }, { name: 'SUSTAINABLE CITIES AND COMMUNITIES', code: 11 }, { name: 'RESPONSIBLE CONSUMPTION AND PRODUCTION', code: 12 }
        , { name: 'CLIMATE ACTION', code: 13 }, { name: 'LIFE BELOW WATER', code: 14 }, { name: 'LIFE ON LAND', code: 15 },
        { name: 'PEACE, JUSTICE AND STRONG INSTITUTIONS', code: 16 }, { name: 'PARTNERSHIPS FOR THE GOAL', code: 17 }
    ];
    const alignment_list = [
        { name: 'CDP', code: 1 },
        { name: 'GRI', code: 2 }];

    const countryTemplate = (option) => {

        return (
            <div className="country-item flex" style={{ alignItems: 'center' }}>

                <img alt={option.name} style={{ width: 50, height: 50, marginRight: 10 }} src={require('../../assets/images/sdg_alignment/' + option.code.toString() + '.png').default} onError={(e) => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')} className={`flag flag-${option.code.toString().toLowerCase()} `} />
                <div>{option.name}</div>
            </div>
        );
    };

    const selectedCountriesTemplate = (option) => {
        if (option) {
            return (
                <div className="country-item country-item-value" style={{
                    margin: 10,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <img alt={option.name} style={{ width: 50, height: 50, marginRight: 10 }} src={require('../../assets/images/sdg_alignment/' + option.code.toString() + '.png').default} onError={(e) => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')} className={`flag flag-${option.code.toString().toLowerCase()}`} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return 'Select SDGs';
    };

    const panelFooterTemplate = () => {
        const selectedItems = selectedCountries;
        const length = selectedItems ? selectedItems.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };
    useEffect(() => {
        console.log(objective, 'update')
    }, [objective])
    useEffect(() => {
        let categories_uri = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics" }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let REQUEST_URL_WITH_ID_2 = API.LocationOne_UP(admin_data.id)
        let url_2 = REQUEST_URL_WITH_ID_2 + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        let Categoriesurl = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(categories_uri))}`;
        let promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        let promise1 = APIServices.get(Categoriesurl)
        let promise2 = APIServices.get(API.Goal_UP(admin_data.id))
        let promise3 = APIServices.get(url_2)
        Promise.all([promise0, promise1, promise2, promise3]).then(function (values) {

            let indicatorList = []

            setCategoryList(values[1].data)
            values[1].data.forEach((i) => {
                if (i.newTopics !== undefined) {
                    i.newTopics.forEach((j) => {
                        if (j.newMetrics !== undefined) {
                            j.newMetrics.forEach((k) => {
                                if (Array.isArray(k.data1) && k.data1[0].type !== undefined && k.data1[0].type === 0) {
                                    indicatorList.push(k)


                                }
                            })
                        }
                    })
                }
            })
            if (values[0].data.length !== 0) {

                setIndicatorList(indicatorList.filter((i) => { return values[0].data[0].metric_ids.includes(i.id) }))
            }

            values[2].data.forEach((item) => {
                let sdg = []
                item.alignment = alignment_list[alignment_list.findIndex((i) => { return i.code === item.alignment })]

                item.category = values[1].data[values[1].data.findIndex((i) => { return i.id === item.category })]
                item.sdg.forEach((k) => {
                    let index = SDG_list.findIndex((i) => { return i.code === k })
                    if (index !== -1) {
                        sdg.push(SDG_list[index])
                    }
                })
                item.sdg = sdg

            })
            setObjective(values[2].data)
            if (values[3].status === 200) {
                let capture = []




                values[3].data.forEach((cat, i) => {

                    if (cat.locationTwos !== undefined) {


                        cat.locationTwos.forEach((topic, j) => {

                            if (topic.locationThrees !== undefined) {

                                topic.locationThrees.forEach((metric, k) => {




                                    let capi = capture.findIndex((aa) => { return aa.value === cat.id })
                                    if (capi === -1) {
                                        capture.push({
                                            value: cat.id, name: cat.name, id: cat.id + '_country', children: [
                                                {
                                                    value: topic.id, name: topic.name, id: topic.id + '_city', children: [
                                                        {
                                                            value: metric.id, name: metric.name, id: metric.id + '_site'


                                                        }
                                                    ]
                                                }
                                            ]
                                        })

                                    } else {
                                        let topi = capture[capi].children.findIndex((aa) => { return aa.value === topic.id })
                                        if (topi === -1) {
                                            capture[capi].children.push({

                                                value: topic.id, name: topic.name, id: topic.id + '_city', children: [
                                                    {
                                                        value: metric.id, name: metric.name, id: metric.id + '_site'
                                                    }
                                                ]

                                            })
                                        } else {
                                            let meti = capture[capi].children[topi].children.findIndex((aa) => { return aa.value === metric.id })
                                            if (meti === -1) {
                                                capture[capi].children[topi].children.push({


                                                    value: metric.id, name: metric.name, id: metric.id + '_site'


                                                })
                                            }
                                            else {

                                            }

                                        }
                                    }




                                })
                            }
                        })
                    }
                })

                setSite(capture);

            }


        })







    }, []);





    //OwnedVehicle
    const [ovFilter, setOVFilter] = useState(null);
    const [selectedOv, setSelectedOv] = useState(null);
    const dtov = useRef(null);
    const [owvh, setOwvh] = useState([]);
    const [ovVal, setOvVal] = useState({
        date: new Date(),
        fuel_cat: "",
        vehtype: "",
        vehno: "",
        km: "",
        tco2e: 0,
        co2: "",
        n2o: "",
        ch4: "",
        dist_type: {
            name: "Km",
        },
        attachment: null,
    });
    const [ovDialog, setOvDialog] = useState(false);
    const [deleteOvDialog, setDeleteOvDialog] = useState(false);
    const [deleteOvsDialog, setDeleteOvsDialog] = useState(false);
    //Goods & Services
    const [gasFilter, setGasFilter] = useState(null);
    const [selectedgas, setSelectedGas] = useState(null);
    const dtgas = useRef(null);
    const [gas, setGas] = useState([]);
    const [gasVal, setGasVal] = useState({
        date: new Date(),
        itemname: "",
        itemmnf: "",
        itemclass: "",
        qty: "",
        spent: 0,
        tco2e: 0,
        co2: "",
        n2o: "",
        ch4: "",
        other: "",
        attachment: null,
    });
    const [gasDialog, setGasDialog] = useState(false);
    const [deletegasDialog, setDeleteGasDialog] = useState(false);
    const [deletegassDialog, setDeleteGassDialog] = useState(false);
    //Employee Commutte
    const [ecFilter, setECFilter] = useState(null);
    const [selectedEc, setSelectedEc] = useState(null);
    const dtec = useRef(null);
    const [ec, setEC] = useState([]);
    const [ecVal, setEcVal] = useState({
        date: new Date(),
        vehtype: "",
        vehno: "",
        passenger: 1,
        km: "",
        tco2e: 0,
        co2: "",
        n2o: "",
        ch4: "",
        dist_type: {
            name: "Km",
        },
        attachment: null,
    });
    const [ecDialog, setEcDialog] = useState(false);
    const [deleteEcDialog, setDeleteEcDialog] = useState(false);
    const [deleteEcsDialog, setDeleteEcsDialog] = useState(false);
    //Business Travel
    const [btFilter, setBTFilter] = useState(null);
    const [selectedBt, setSelectedBt] = useState(null);
    const dtbt = useRef(null);
    const [bt, setBT] = useState([]);
    const [btVal, setBtVal] = useState({
        date: new Date(),
        mode: "",
        vehtype: "",
        vehcap: "",
        vehfuel: "",
        fromto: "",
        passenger: 1,
        km: "",
        tco2e: 0,
        attachment: null,
        legs: [],
        class: "",
    });
    const [btDialog, setBtDialog] = useState(false);
    const [deleteBtDialog, setDeleteBtDialog] = useState(false);
    const [deleteBtsDialog, setDeleteBtsDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [routeDialog, setRouteDialog] = useState(false);
    const [addLegDialog, setAddLegDialog] = useState(false);
    const [routeDialogData, setRouteDialogData] = useState([]);

    //Autocomplete Airport Code
    const [autoValue, setAutoValue] = useState([]);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [selectedAutoValue, setSelectedAutoValue] = useState({
        from: "",
        to: "",
    });
    const [legData, setLegData] = useState({});

    // General Info
    function updateGI(obj, val) {
        let gi = general;
        gi[obj] = val;
        setGeneral(gi);
        forceUpdate();
    }
    //randomNumberGeneartor
    function getRandomArbitrary(min, max) {
        return Math.floor(Math.random() * max)
    }
    const localLocation = [
        {
            Ahmedabad: [
                {
                    name: "Gandhinagar",
                },
            ],
            Bengaluru: [
                {
                    name: "Sanjay Nagar",
                },
                {
                    name: "Whitefield",
                },
            ],
            Delhi: [
                {
                    name: "Greater Kailash",
                },
                {
                    name: "DC2",
                },
            ],
            Hyderabad: [
                {
                    name: "DC1",
                },
            ],
            Kolkata: [
                {
                    name: "DC1",
                },
            ],
            Mumbai: [
                {
                    name: "Prabhadevi",
                },
                {
                    name: "Andheri",
                },
                {
                    name: "Mahape",
                },
                {
                    name: "BKC",
                },
            ],
            Noida: [
                {
                    name: "Uttar Pradesh",
                },
            ],
            Pune: [
                {
                    name: "Dighi",
                },
            ],
            Chennai: [
                {
                    name: "Ambattur",
                },
                {
                    name: "DC2",
                },
            ],
            Singapore: [
                {
                    name: "Defu",
                },
                {
                    name: "Loyang",
                },
                {
                    name: "One-North",
                },
                {
                    name: "Tai seng",
                },
                {
                    name: "Jurong",
                },
            ],
            "Metro Manila": [
                {
                    name: "Makati",
                },
                {
                    name: "Mandaluyong",
                },
                {
                    name: "Quezon City",
                },
            ],
            Calabarzon: [
                {
                    name: "General Trias",
                },
            ],
            Davao: [
                {
                    name: "Davao DC1",
                },
            ],
            Bekasi: [
                {
                    name: "Cikaranag DC1",
                },
                {
                    name: "Cikaranag DC2",
                },
                {
                    name: "Cikaranag DC3",
                },
            ],
            Tokyo: [
                {
                    name: "Chiba",
                },
            ],
            Seoul: [
                {
                    name: "Gasan-dong",
                },
                {
                    name: "Anyang",
                },
            ],
            Bangkok: [
                {
                    name: "Hua mak",
                },
            ],
            London: [
                {
                    name: "Slough",
                },
                {
                    name: "Stockley Park",
                },
                {
                    name: "Hayes",
                },
                {
                    name: "Enfield",
                },
            ],
            Shanghai: [
                {
                    name: "DC1",
                },
            ],
            Kunshan: [
                {
                    name: "DC1",
                },
            ],
            Changshu: [
                {
                    name: "DC1",
                },
            ],
            Nantong: [
                {
                    name: "DC1",
                },
            ],
            Wuhan: [
                {
                    name: "DC1",
                },
            ],
            Chengdu: [
                {
                    name: "DC1",
                },
            ],
            Ulanqab: [
                {
                    name: "DC1",
                },
            ],
            Beijing: [
                {
                    name: "DC1",
                },
            ],
            Langfang: [
                {
                    name: "DC1",
                },
            ],
            Hongkong: [
                {
                    name: "DC1",
                },
            ],
            Heyuan: [
                {
                    name: "DC1",
                },
            ],
        },
    ];




    const renderData = (obj, val) => {

        setData({
            s1a: {},
            s2a: {},
        });
        setGeneral({});
        setRefer([]);
        setGas([]);
        setBT([]);
        setEC([]);
        setOwvh([]);

    };

    const dateFormatting = (date) => {
        if (typeof date === "string") {
            let newDate = new Date(date);

            return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
        } else {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        }
    };


    const onSelectedCity = (value) => {
        setSCity(value);

        let index = rawLocation.findIndex((j) => {
            return Object.keys(j)[0] === value.name;
        });

        setLocation(rawLocation[index][value.name]);
    };
    const onSelectedLocation = (e) => {
        let loc = selected;
        loc.location = e.value;
        setSelected(loc);
        if (e.value.name === 'All') {
            site.findIndex((k) => {
                if (k.locationTwos !== undefined) {
                    k.locationTwos.forEach((j) => {
                        if (j.id === selected.city.id) {
                            if (j.data1 === null) {
                                setObjective([])
                            } else {
                                setObjective(j.data1)
                            }
                        }
                    })
                }
            })

        } else {
            setObjective(e.value.data1 === null ? [] : e.value.data1)
        }
        forceUpdate()

    };

    const refillDate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Date </span> {moment(rowData.date).format("L")}
            </>
        );
    };
    const acTypeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> AC Type </span> {rowData.actype.name}
            </>
        );
    };
    const acGasTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Gas Type </span> {rowData.gastype.name}
            </>
        );
    };
    const gasRefilledTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Gas Refilled( in KG) </span> {rowData.gasrefilled}
            </>
        );
    };
    const ghgEmissionTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Gas Refilled( in KG) </span> {rowData.tco2e}
            </>
        );
    };

    const openNewRef = () => {
        setSubmitted(false);
        setRefDialog(true);
    };

    const hideDialog = () => {
        setRefVal({
            date: new Date(),
            actype: "",
            gastype: "",
            gasrefilled: "",
            tco2e: 0,
            attachment: null,
        });
        setOvVal({
            date: new Date(),
            vehtype: "",
            fuel_cat: "",
            vehno: "",
            km: "",
            co2: "",
            n2o: "",
            ch4: "",
            tco2e: 0,
            dist_type: {
                name: "Km",
            },
            attachment: null,
        });
        setGasVal({
            date: new Date(),
            itemname: "",
            itemmnf: "",
            itemclass: "",
            qty: "",
            spent: 0,
            tco2e: 0,
            co2: "",
            n2o: "",
            ch4: "",
            other: "",
            attachment: null,
        });
        setEcVal({
            date: new Date(),
            vehtype: "",
            vehno: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            co2: "",
            n2o: "",
            ch4: "",
            dist_type: {
                name: "Km",
            },
            attachment: null,
        });
        setBtVal({
            date: new Date(),
            mode: "",
            vehtype: "",
            vehcap: "",
            vehfuel: "",
            fromto: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            attachment: null,
            legs: [],
            class: "",
        });
        setSubmitted(false);
        setRefDialog(false);
        setOvDialog(false);
        setGasDialog(false);
        setEcDialog(false);
        setBtDialog(false);
    };

    const hideDeleteRefDialog = () => {
        setDeleteRefDialog(false);
        setRefVal({
            date: new Date(),
            actype: "",
            gastype: "",
            gasrefilled: "",
            tco2e: 0,
            attachment: null,
        });
    };
    const hideDeleteRefsDialog = () => {
        setDeleteRefsDialog(false);
    };
    const confirmDeleteRef = () => {
        let local = refer;

        let index = local.findIndex((i) => {
            return i.id === refVal.id;
        });
        if (index !== -1) {
            local.splice(index, 1);
            setRefer(local);
        }
        setRefVal({
            date: new Date(),
            actype: "",
            gastype: "",
            gasrefilled: "",
            tco2e: 0,
            attachment: null,
        });
        setDeleteRefDialog(false);
    };
    const confirmDeleteSelected = () => {
        let local = refer;
        selectedRef.forEach((a) => {
            let index = local.findIndex((i) => {
                return i.id === a.id;
            });
            if (index !== -1) {
                local.splice(index, 1);
            }
        });
        setRefVal({
            date: new Date(),
            actype: "",
            gastype: "",
            gasrefilled: "",
            tco2e: 0,
            attachment: null,
        });
        setSelectedRef(null);
        setRefer(local);
        setDeleteRefsDialog(false);
    };
    const deleteRefDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRefDialog} /> <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={confirmDeleteRef} />
        </>
    );

    const deleteRefsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRefsDialog} /> <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={confirmDeleteSelected} />
        </>
    );
    const refheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="p-button-success mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewRef}
                    />
                    <Button
                        label="Delete"
                        icon="pi pi-trash"
                        className="p-button-danger"
                        disabled={selectedRef === null || selectedRef.length === 0 ? true : false}
                        style={{
                            width: 100,
                        }}
                        onClick={() => setDeleteRefsDialog(true)}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setRefFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const saveRefrigerent = () => {
        setSubmitted(true);
        if (refVal.actype !== "" && refVal.date !== "" && parseInt(refVal.gasrefilled) > 0 && refVal.gastype !== "" && refVal.attachment !== null) {
            let local = refVal;
            let local2 = refer;
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getRandomArbitrary(111111111, 999999999);
                local2.push(local);
            }

            setRefVal({
                date: new Date(),
                actype: "",
                gastype: "",
                gasrefilled: "",
                tco2e: 0,
                attachment: null,
            });
            setRefer(local2);
            setSubmitted(false);
            setRefDialog(false);
        }

        forceUpdate();
    };
    const actionBodyTemplateRF = (rowData) => {
        return (
            <>

                {rowData.approved !== 1 ? (
                    <div className="actions">
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-success mr-2"
                            onClick={() => {
                                setRefVal({ ...rowData });
                                setRefDialog(true);
                            }}
                        />
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning mt-2"
                            onClick={() => {
                                setRefVal(rowData);
                                setDeleteRefDialog(true);
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <i class="pi pi-lock"> </i>
                    </div>
                )}
            </>
        );
    };
    const updateRefVal = (obj, val) => {
        let local = refVal;
        local[obj] = val;
        if (obj === "gasrefilled") {
            emissionFactor.refrig.forEach((item) => {
                if (item.emission === local["gastype"].name) {
                    local["tco2e"] = (val * item.tnkp) / 1000;
                }
            });
        }
        if (obj === "gastype" && local["gasrefilled"] !== "") {
            emissionFactor.refrig.forEach((item) => {
                if (item.emission === val.name) {
                    local["tco2e"] = (local["gasrefilled"] * item.tnkp) / 1000;
                }
            });
        }
        setRefVal(local);

        forceUpdate();
    };
    const refAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >

                        View
                    </a>
                )}
            </>
        );
    };
    const refDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} /> <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRefrigerent} />
        </>
    );
    // OwnedVehicle
    const vehTypeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Vehicle Type </span> {rowData.vehtype.name}
            </>
        );
    };
    const vehnoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Vehicle Number </span> {rowData.vehno}
            </>
        );
    };
    const vehkmTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Gas Refilled( in KG) </span> {rowData.km}
            </>
        );
    };
    const vehfcTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Fuel Category </span> {rowData.fuel_cat.label}
            </>
        );
    };
    const openNewOv = () => {
        setSubmitted(false);
        setOvDialog(true);
    };
    const ovDate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Date </span> {moment(rowData.date).format("L")}
            </>
        );
    };
    const saveOwnedVehicle = () => {
        setSubmitted(true);
        let local = ovVal;
        let local2 = owvh;
        if (ovVal.attachment !== null && ovVal.fuel_cat !== "" && parseInt(ovVal.km) > 0 && ovVal.vehno.trim() !== "" && ovVal.vehtype !== "") {
            if (local.id) {
                let index = local2.findIndex((i) => {
                    return i.id === local.id;
                });
                local2[index] = local;
            } else {
                local.id = getRandomArbitrary(111111111, 999999999);
                local2.push(local);
            }

            setOvVal({
                date: new Date(),
                vehtype: "",
                vehno: "",
                fuel_cat: "",
                km: "",
                tco2e: 0,
                co2: "",
                n2o: "",
                ch4: "",
                dist_type: {
                    name: "Km",
                },
                attachment: null,
            });
            setOwvh(local2);
            setOvDialog(false);
            setSubmitted(false);
        }
        forceUpdate();
    };
    const ovheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="p-button-success mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewOv}
                    />
                    <Button
                        label="Delete"
                        icon="pi pi-trash"
                        className="p-button-danger"
                        disabled={selectedOv === null || selectedOv.length === 0 ? true : false}
                        style={{
                            width: 100,
                        }}
                        onClick={() => setDeleteOvsDialog(true)}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setOVFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const actionBodyTemplateOV = (rowData) => {
        return (
            <>

                {rowData.approved !== 1 ? (
                    <div className="actions">
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-success mr-2"
                            onClick={() => {
                                setOvVal({ ...rowData });
                                setOvDialog(true);
                            }}
                        />
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning mt-2"
                            onClick={() => {
                                setOvVal(rowData);
                                setDeleteOvDialog(true);
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <i class="pi pi-lock"> </i>
                    </div>
                )}
            </>
        );
    };




    const hideDeleteGasDialog = () => {
        setGasVal({
            date: new Date(),
            itemname: "",
            itemmnf: "",
            itemclass: "",
            qty: "",
            spent: 0,
            tco2e: 0,
            co2: "",
            n2o: "",
            ch4: "",
            other: "",
            attachment: null,
        });
        setDeleteGasDialog(false);
    };
    const hideDeleteGassDialog = () => {
        setDeleteGassDialog(false);
    };
    const confirmDeleteGas = () => {
        let local = gas;

        let index = local.findIndex((i) => {
            return i.id === gasVal.id;
        });
        if (index !== -1) {
            local.splice(index, 1);
            setGas(local);
        }
        setGasVal({
            date: new Date(),
            itemname: "",
            itemmnf: "",
            itemclass: "",
            qty: "",
            spent: 0,
            tco2e: 0,
            attachment: null,
        });
        setDeleteGasDialog(false);
    };
    //check for approved status
    const checkApproved = (data) => {
        let check = true;
        data.forEach((i) => {
            if (i.approved === 1) {
                check = false;
            }
        });
        return check;
    };
    const locked = () => {
        return (
            <div>
                <i class="pi pi-lock"> </i>
            </div>
        );
    };
    const confirmDeleteSelectedGas = () => {
        let local = gas;
        selectedgas.forEach((a) => {
            let index = local.findIndex((i) => {
                return i.id === a.id;
            });
            if (index !== -1) {
                local.splice(index, 1);
            }
        });
        setGasVal({
            date: new Date(),
            itemname: "",
            itemmnf: "",
            itemclass: "",
            qty: "",
            spent: 0,
            tco2e: 0,
            co2: "",
            n2o: "",
            ch4: "",
            other: "",
            attachment: null,
        });
        setSelectedGas(null);
        setGas(local);
        setDeleteGassDialog(false);
    };




    const openNewEc = () => {
        setSubmitted(false);
        setEcDialog(true);
    };



    const hideDeleteEcDialog = () => {
        setEcVal({
            date: new Date(),
            vehtype: "",
            vehno: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            attachment: null,
        });
        setDeleteEcDialog(false);
    };
    const hideDeleteEcsDialog = () => {
        setDeleteEcsDialog(false);
    };
    const confirmDeleteEc = () => {
        let local = ec;

        let index = local.findIndex((i) => {
            return i.id === ecVal.id;
        });
        if (index !== -1) {
            local.splice(index, 1);
            setEC(local);
        }

        setEcVal({
            date: new Date(),
            vehtype: "",
            vehno: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            co2: "",
            n2o: "",
            ch4: "",
            dist_type: {
                name: "Km",
            },
            attachment: null,
        });
        setDeleteEcDialog(false);
    };
    const confirmDeleteSelectedEc = () => {
        let local = ec;
        selectedOv.forEach((a) => {
            let index = local.findIndex((i) => {
                return i.id === a.id;
            });
            if (index !== -1) {
                local.splice(index, 1);
            }
        });
        setEcVal({
            date: new Date(),
            vehtype: "",
            vehno: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            attachment: null,
        });
        setSelectedEc(null);
        setEC(local);
        setDeleteEcsDialog(false);
    };

    const openNewBt = () => {
        setSubmitted(false);
        setBtDialog(true);
    };
    const btDate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Date </span> {rowData.mode.name === "Air" ? moment(rowData.legs[0].date).format("L") : moment(rowData.date).format("L")}
            </>
        );
    };
    const checkValid = () => {
        let check = 1;
        if (btVal.legs.length !== 0) {
            btVal.legs.forEach((item) => {
                if (item.tco2e === undefined) {
                    check = 0;
                }
            });
        } else {
            check = 0;
        }

        return check;
    };
    const saveBusinessTravel = () => {
        setSubmitted(true);
        let local = btVal;
        let local2 = bt;
        if (btVal.mode !== "") {
            if (btVal.mode.name === "Road" && parseInt(btVal.passenger) > 0 && btVal.vehtype !== "" && parseFloat(btVal.tco2e) > 0 && btVal.attachment !== null) {
                if ((btVal.vehtype.name === "Bus" || btVal.vehtype.name === "Taxi" || (btVal.vehtype.name === "Bike" && btVal.vehcap.name !== undefined) || (btVal.vehtype.name === "Car" && btVal.vehcap.name !== undefined && btVal.vehfuel.name !== undefined)) && parseInt(btVal.km) > 0) {
                    if (local.id) {
                        let index = local2.findIndex((i) => {
                            return i.id === local.id;
                        });
                        local2[index] = local;
                    } else {
                        local.id = getRandomArbitrary(111111111, 999999999);
                        local2.push(local);
                    }

                    setBtVal({
                        date: new Date(),
                        vehtype: "",
                        fromto: "",
                        vehcap: "",
                        passenger: 1,
                        km: "",
                        vehfuel: "",
                        mode: "",
                        tco2e: 0,
                        attachment: null,
                        legs: [],
                        class: "",
                    });
                    setBT(local2);
                    setBtDialog(false);
                    forceUpdate();
                }
            } else if (btVal.mode.name === "Air" && checkValid() === 1 && parseInt(btVal.passenger) > 0 && btVal.attachment !== null) {
                let local2 = bt;

                btVal.legs.forEach((item) => {
                    local2.push({
                        id: getRandomArbitrary(111111111, 999999999) + getRandomArbitrary(1, 9),
                        date: item.date,
                        legs: [item],
                        mode: btVal.mode,
                        km: "NA",
                        tco2e: item.tco2e,
                        fromto: item.from + "/" + item.to,
                        passenger: btVal.passenger,
                        attachment: btVal.attachment,
                    });
                });

                setBtVal({
                    date: new Date(),
                    vehtype: "",
                    fromto: "",
                    vehcap: "",
                    passenger: 1,
                    km: "",
                    vehfuel: "",
                    mode: "",
                    tco2e: 0,
                    attachment: null,
                    legs: [],
                    class: "",
                });
                setBT(local2);
                setBtDialog(false);
                forceUpdate();
            } else if (btVal.mode.name === "Rail" && parseFloat(btVal.tco2e) > 0 && btVal.attachment !== null) {
                local2.push(local);
                setBtVal({
                    date: new Date(),
                    vehtype: "",
                    fromto: "",
                    vehcap: "",
                    passenger: 1,
                    km: "",
                    vehfuel: "",
                    mode: "",
                    tco2e: 0,
                    attachment: null,
                    legs: [],
                    class: "",
                });
                setBT(local2);
                setBtDialog(false);
                forceUpdate();
            }
        }
        // let local = btVal;
        // let local2 = bt;
        // if (btVal.mode.name !== 'Air') {
        //     if (local.id) {
        //         let index = local2.findIndex((i) => { return i.id === local.id })
        //         local2[index] = local
        //     } else {

        //         local.id = getRandomArbitrary(111111111, 999999999);
        //         local2.push(local);
        //     }

        //     setBtVal({ date: new Date(), vehtype: '', fromto: '', vehcap: '', passenger: 1, km: '', vehfuel: '', mode: '', tco2e: 0, attachment: null, legs: [], class: '' })
        //     setBT(local2);
        //     setBtDialog(false)
        //     forceUpdate()
        // } else {

        //     let local2 = bt;

        //     btVal.legs.forEach((item) => {
        //         local2.push({ id: getRandomArbitrary(111111111, 999999999) + getRandomArbitrary(1, 9), date: item.date, legs: [item], mode: btVal.mode, km: 'NA', tco2e: item.tco2e, fromto: item.from + '/' + item.to, passenger: btVal.passenger, attachment: btVal.attachment });
        //     })

        //     setBtVal({ date: new Date(), vehtype: '', fromto: '', vehcap: '', passenger: 1, km: '', vehfuel: '', mode: '', tco2e: 0, attachment: null, legs: [], class: '' })
        //     setBT(local2);
        //     setBtDialog(false)
        //     forceUpdate()
        // }
    };
    const btheader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="p-button-success mr-2"
                        style={{
                            width: 100,
                        }}
                        onClick={openNewBt}
                    />
                    <Button
                        label="Delete"
                        icon="pi pi-trash"
                        className="p-button-danger"
                        disabled={selectedBt === null || selectedBt.length === 0 ? true : false}
                        style={{
                            width: 100,
                        }}
                        onClick={() => setDeleteBtsDialog(true)}
                    />
                </div>
            </React.Fragment>
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setBTFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const actionBodyTemplateBt = (rowData) => {
        return (
            <>

                {rowData.approved !== 1 ? (
                    <div className="actions">
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning mt-2"
                            onClick={() => {
                                setBtVal(rowData);
                                setDeleteBtDialog(true);
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <i class="pi pi-lock"> </i>
                    </div>
                )}
            </>
        );
    };

    const btAttachmentTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Attachment </span>
                {rowData.attachment === null ? (
                    "No Attachments"
                ) : (
                    <a
                        onClick={() => {
                            window.open(rowData.attachment);
                        }}
                    >

                        View
                    </a>
                )}
            </>
        );
    };

    const hideDeleteBtDialog = () => {
        setEcVal({
            date: new Date(),
            vehtype: "",
            vehno: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            co2: "",
            n2o: "",
            ch4: "",
            dist_type: {
                name: "Km",
            },
            attachment: null,
        });
        setDeleteBtDialog(false);
    };
    const hideDeleteBtsDialog = () => {
        setDeleteBtsDialog(false);
    };
    const confirmDeleteBt = () => {
        let local = bt;

        let index = local.findIndex((i) => {
            return i.id === btVal.id;
        });
        if (index !== -1) {
            local.splice(index, 1);
            setBT(local);
        }

        setBtVal({
            date: new Date(),
            mode: "",
            vehtype: "",
            vehcap: "",
            vehfuel: "",
            fromto: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            attachment: null,
            legs: [],
            class: "",
        });
        setDeleteBtDialog(false);
    };
    const confirmDeleteSelectedBt = () => {
        let local = bt;
        selectedBt.forEach((a) => {
            let index = local.findIndex((i) => {
                return i.id === a.id;
            });
            if (index !== -1) {
                local.splice(index, 1);
            }
        });

        setBtVal({
            date: new Date(),
            mode: "",
            vehtype: "",
            vehcap: "",
            vehfuel: "",
            fromto: "",
            passenger: 1,
            km: "",
            tco2e: 0,
            attachment: null,
            legs: [],
            class: "",
        });
        setSelectedBt(null);
        setBT(local);
        setDeleteBtsDialog(false);
    };

    const routeDialogFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setRouteDialog(false);
                }}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    if (routeDialogData.length !== 0) {
                        setBtVal(routeDialogData);
                        setRouteDialog(false);
                    } else {
                        setRouteDialog(false);
                    }
                }}
            />
        </>
    );




    const closeBopundaryDialog = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setBoundarydialog(false); setAppRowIndex(null) }} />


        </>
    );

    const closeHistoryDialog = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setHistoryDialog(false) }} />
        </>
    );

    //Emission Value
    const getRoadEmission = () => {
        if (btVal.vehtype.name === "Taxi") {
            return 0.14876 / 1000;
        } else if (btVal.vehtype.name === "Bus") {
            return 0.11774 / 1000;
        } else if (btVal.vehtype.name === "Bike") {
            if (btVal.vehcap.name === "Small") {
                return 0.08306 / 1000;
            } else if (btVal.vehcap.name === "Medium") {
                return 0.1009 / 1000;
            } else if (btVal.vehcap.name === "Large") {
                return 0.13245 / 1000;
            } else if (btVal.vehcap.name === "Average") {
                return 0.11355 / 1000;
            }
        } else if (btVal.vehtype.name === "Car") {
            if (btVal.vehcap.name === "Small") {
                if (btVal.vehfuel.name === "Diesel") {
                    return 0.13758 / 1000;
                } else if (btVal.vehfuel.name === "Petrol") {
                    return 0.14946 / 1000;
                } else if (btVal.vehfuel.name === "Hybrid") {
                    return 0.10494 / 1000;
                } else if (btVal.vehfuel.name === "Plug-in Hybrid E Vehicle") {
                    return 0.05568 / 1000;
                } else if (btVal.vehfuel.name === "Battery Electric Vehicle") {
                    return 0.04565 / 1000;
                }
            } else if (btVal.vehcap.name === "Medium") {
                if (btVal.vehfuel.name === "Diesel") {
                    return 0.16496 / 1000;
                } else if (btVal.vehfuel.name === "Petrol") {
                    return 0.18785 / 1000;
                } else if (btVal.vehfuel.name === "Hybrid") {
                    return 0.10957 / 1000;
                } else if (btVal.vehfuel.name === "Plug-in Hybrid E Vehicle") {
                    return 0.09097 / 1000;
                } else if (btVal.vehfuel.name === "Battery Electric Vehicle") {
                    return 0.05254 / 1000;
                } else if (btVal.vehfuel.name === "CNG") {
                    return 0.15949 / 1000;
                } else if (btVal.vehfuel.name === "LPG") {
                    return 0.17927 / 1000;
                }
            } else if (btVal.vehcap.name === "Large") {
                if (btVal.vehfuel.name === "Diesel") {
                    return 0.20721 / 1000;
                } else if (btVal.vehfuel.name === "Petrol") {
                    return 0.27909 / 1000;
                } else if (btVal.vehfuel.name === "Hybrid") {
                    return 0.15151 / 1000;
                } else if (btVal.vehfuel.name === "Plug-in Hybrid E Vehicle") {
                    return 0.10492 / 1000;
                } else if (btVal.vehfuel.name === "Battery Electric Vehicle") {
                    return 0.06066 / 1000;
                } else if (btVal.vehfuel.name === "CNG") {
                    return 0.23626 / 1000;
                } else if (btVal.vehfuel.name === "LPG") {
                    return 0.26643 / 1000;
                }
            } else if (btVal.vehcap.name === "Average") {
                if (btVal.vehfuel.name === "Diesel") {
                    return 0.16843 / 1000;
                } else if (btVal.vehfuel.name === "Petrol") {
                    return 0.17431 / 1000;
                } else if (btVal.vehfuel.name === "Hybrid") {
                    return 0.11952 / 1000;
                } else if (btVal.vehfuel.name === "Plug-in Hybrid E Vehicle") {
                    return 0.09694 / 1000;
                } else if (btVal.vehfuel.name === "Battery Electric Vehicle") {
                    return 0.05477 / 1000;
                } else if (btVal.vehfuel.name === "CNG") {
                    return 0.17624 / 1000;
                } else if (btVal.vehfuel.name === "LPG") {
                    return 0.19828 / 1000;
                }
            }
        }
    };

    function roundValues(num) {
        var m = Number((Math.abs(num) * 100).toPrecision(6));
        return (Math.round(m) / 100) * Math.sign(num);
    }
    // RenderMap

    const renderMapRoute = (path) => {
        let check = 0;

        if (btVal.mode.name === "Road") {
            if (btVal.vehtype.name !== "Car" && btVal.vehtype.name !== "Bike") {
                check = 1;
            } else if (btVal.vehtype.name === "Car") {
                if (btVal.vehcap.name !== "") {
                    if (btVal.vehfuel.name !== "") {
                        check = 1;
                    } else {
                        alert("Select Fuel Type");
                    }
                } else {
                    check = 0;
                    alert("Select Car Capacity");
                }
            } else if (btVal.vehtype.name === "Bike") {
                if (btVal.vehcap.name !== "") {
                    check = 1;
                } else {
                    check = 0;
                    alert("Select Bike Capacity");
                }
            }
        } else {
            check = 1;
        }

        if (check === 1) {
            //   if (document.getElementById('resultTabel') !== null) { document.getElementById('resultTabel').innerHTML = "" }
            let from = document.getElementById("fromplace").value.trim();
            let to = document.getElementById("toplace").value.trim();

            var directionService = new google.maps.DirectionsService();
            var directionDisplay = new google.maps.DirectionsRenderer({
                draggable: true,
            });

            let btr = btVal;
            var request = {
                provideRouteAlternatives: true,
                waypoints: [],
                origin: from,
                destination: to,
                travelMode: btVal.mode.name === "Road" ? google.maps.TravelMode.DRIVING : google.maps.TravelMode.TRANSIT,
                unitSystem: google.maps.UnitSystem.IMPERIAL,
            };

            if (from.split(",").length !== 1 && to.split(",").length !== 1) {
                directionService
                    .route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let distance = result.routes[0].legs[0].distance.text.toString();
                            let km = 1.60934 * parseFloat(distance.replace("mi", ""));

                            if (btVal.mode.name === "Rail") {
                                let tco2e = ((km * 0.007837) / 10000) * btVal.passenger;

                                btr.tco2e = tco2e;
                                btr.fromto = document.getElementById("fromplace").value.trim().split(",")[0] + "/" + document.getElementById("toplace").value.trim().split(",")[0];

                                btr.km = roundValues(km);
                            } else {
                                let tco2e = 0;
                                if (btVal.vehtype.name !== "Bus" && btVal.vehtype.name !== "Taxi") {
                                    tco2e = km * getRoadEmission() * btVal.passenger;
                                } else {
                                    tco2e = km * getRoadEmission();
                                }
                                btr.tco2e = tco2e;
                                btr.fromto = document.getElementById("fromplace").value.trim().split(",")[0] + "/" + document.getElementById("toplace").value.trim().split(",")[0];

                                btr.km = roundValues(km);
                            }
                            setBtVal(btr);

                            // CreateTable("mytable", "resultTabel", ["Date", "DEP", "ARR", "KM", "Emission(t CO2e)", "Action"]);
                            // if (mode === 'Rail') {
                            //   rail.b.value.map((item, i) => {
                            //     TableAdd("mytable", [item.date, item.from, item.to, item.km, item.tco2e, "Delete"], i);

                            //   })
                            // } else {
                            //   rail.a.value.map((item, i) => {
                            //     TableAdd("mytable", [item.date, item.from, item.to, item.km, item.tco2e, "Delete",], i);

                            //   })
                            // }

                            setGroute(result);
                        } else {
                            btr.tco2e = 0;
                            btr.fromto = "";
                            btr["direction"] = "";
                            btr.km = 0;
                            alert("Route not found between" + from + " & " + to);
                        }
                    })
                    .catch((a) => console.log(a, "err"));
            }
        }
    };
    //   Map Dialog
    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds({
            lat: 13.017092278167553,
            lng: 80.1987183651303,
        });
        map.fitBounds(bounds);
    };
    const onUnmount = (map) => {
        console.log("unmount");
    };
    // Autocomplete Airport Code
    const searchAirCode = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            } else {
                setAutoFilteredValue(
                    autoValue.filter((country) => {
                        return country.value.toLowerCase().includes(event.query.toLowerCase());
                    })
                );
            }
        }, 250);
    };
    const exportToCSV = (csvData, sheetname, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = {
            Sheets: {
                [sheetname]: ws,
            },
            SheetNames: [sheetname],
        };
        const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
        });
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, fileName + ".xlsx");
    };
    const excelDateToJSDate = (excelDate) => {
        var date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
        var converted_date = date.toISOString().split("T")[0];
        return date;
    };


    const updateACValue = (obj, val) => {
        if (obj !== "date") {
            let value = selectedAutoValue;
            value[obj] = val;
            setSelectedAutoValue(value);
        }

        let leg = legData;
        if (obj === "date") {
            leg[obj] = val;
        } else if (obj === "class") {
            leg[obj] = val;
        } else {
            if (typeof val === "object" && val.value.includes("/")) {
                leg[obj] = val.value.split("/")[2];
            }
        }

        setLegData(leg);

        forceUpdate();
    };



    const indicatorTemplate = (rowData) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {rowData.indicator === 1 ? (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "red",
                            fontSize: 20,
                            borderRadius: 12,

                        }}
                        className="pi pi-circle-fill"
                    />
                ) : rowData.indicator === 2 ? (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "#FFBF00",
                            fontSize: 20,
                            borderRadius: 12,

                        }}
                        className="pi pi-circle-fill"
                    />
                ) : (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "green",
                            fontSize: 20,
                            borderRadius: 12,

                        }}
                        className="pi pi-circle-fill"
                    />
                )}
            </div>
        );
    };
    const viewHistory = (data) => {
        setHistoryDialog(true);
        setHistoryData(data)
    }
    function createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes.
        return div.firstChild;
    }
    const statusTemplate = (rowData) => {
        let st = ''
        return rowData.status.sort(function (left, right) {
            return moment.utc(left[0]).diff(moment.utc(right[0]))
        }).reverse().map((item, ind) => {
            if (ind === 0) {
                let bgcolor = ''
                if (item[2] === 1) {
                    bgcolor = 'red'
                } else if (item[2] === 2) {
                    bgcolor = "#FFBF00"
                } else {
                    bgcolor = 'green'
                }

                return (
                    <div style={{ flexDirection: 'column', display: 'flex', padding: 5 }}>
                        <text style={{ color: 'white', borderRadius: 10, padding: 5, fontStyle: 'italic', backgroundColor: bgcolor }}>As of {DateTime.fromISO(item[0], { zone: 'utc' }).toLocal().toFormat("yyyy LLL dd HH':'mm a")}</text>

                        <text style={{ padding: 5 }}>{item[1]}</text>
                        {rowData.status.length > 1 &&


                            <div id='viewhis'>
                                <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 10, color: '#003153' }}><label onClick={() => {
                                    viewHistory(rowData.status.sort(function (left, right) {
                                        return moment.utc(left[0]).diff(moment.utc(right[0]))
                                    }).reverse())
                                }}>view history</label></div>
                            </div>

                        }


                    </div>)

            }
        })



    }

    const initiativeTemplate = (rowData) => {
        let st = ''
        rowData.initiatives.sort(function (left, right) {
            return moment.utc(left[0]).diff(moment.utc(right[0]))
        }).reverse().forEach((item) => {
            st = st + `<div style='flex-direction:column;display:flex;padding:5px;width:180px;'><text style="padding:5px">${item[1]}</text><text style="color:green;padding:5px;font-style: italic;">${DateTime.fromISO(item[0], { zone: 'utc' }).toLocal().toFormat("yyyy LLL dd HH':'mm a")}</text> </div>`

        })

        return <div style={{ maxHeight: 120, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: st }} />

    }
    const boundaryTemplate = (rowData) => {
        let item = rowData.applicability
        let selected_item = { country: '', city: '', location: '' }
        let country_list = [{ name: 'All', id: 'All' }]
        let city_list = [{ name: 'All', id: 'All' }]
        let location_list = [{ name: 'All', id: 'All' }]
        site.forEach((country) => {
            country_list.push(country)
            if (item.country === 'All') {
                selected_item.country = 'All'


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id

                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            } else if (item.country === country.id) {
                selected_item.country = country.id


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            }

        })

        return (
            <div >
                <label>{label1}</label>
                <Dropdown disabled style={{ margin: 10, width: '100%' }} value={selected_item.country} options={country_list} optionLabel="name" optionValue="id"
                    placeholder={"Select " + label1}
                />
                <label>{label2}</label>
                <Dropdown disabled style={{ margin: 10, width: '100%' }} value={selected_item.city} options={city_list} optionLabel="name" optionValue="id"
                    placeholder={"Select " + label2}
                />
                <label>{label3}</label>
                <Dropdown disabled style={{ margin: 10, width: '100%' }} value={selected_item.location} options={location_list} optionLabel="name" optionValue="id"
                    placeholder={"Select " + label3}
                />
            </div >
        )


    }
    const deleteInitiative = async (data, inindex) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete the action "${data.initiatives[0]}"</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.Initiative_Edit(data.id)).then((res) => {

                let loc = objective


                let objindex = objective.findIndex((i) => { return i.selected === 1 })
                loc[objindex].initiative.splice(inindex, 1)
                setObjective(loc)
                forceUpdate()

            })
        }
    }
    const deleteTemplate = (rowData) => {
        return (<>
            <div>
                <i className="material-icons" style={{ cursor: 'pointer' }} onClick={() => { deleteInitiative(rowData) }}>delete</i>
            </div>
        </>)
    }
    const rowEditorTemplate = (rowData, props) => {
        const rowEditor = props.rowEditor;
        if (rowEditor.editing) {
            return rowEditor.element; // default element
        }
        else {
            // custom init element
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <button type="button" onClick={rowEditor.onInitClick} className={rowEditor.initClassName}>
                        <span className='p-row-editor-init-icon pi  pi-pencil p-clickable'></span>
                    </button>
                    <i className="material-icons" style={{ cursor: 'pointer', color: 'red', fontSize: '1.4rem' }} onClick={() => { deleteInitiative(rowData) }}>delete</i>
                </div>
            )
        }
    }
    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }
    const addInAction = () => {
        setSubmitted(true)
        let loc = objective
        let objindex = objective.findIndex((i) => { return i.selected === 1 })

        if (inaction.responsibility.trim().length !== 0 && inaction.initiatives[0].trim().length !== 0 && inaction.initiatives[2] !== null && inaction.status[0][1].trim().length !== 0) {
            let data = inaction;

            if (inaction.id === undefined) {
                data['created_on'] = DateTime.utc()
                data['created_by'] = login_data.id


                APIServices.post(API.Initiative_Goal(objective[objindex].id), data).then((res) => {
                    loc[objindex].initiative.push(res.data)
                    setObjective(loc)
                    setSubmitted(false)
                    setInActionDialog(false)
                })
            } else {
                data['modified_on'] = DateTime.utc()

                APIServices.patch(API.Initiative_Edit(inaction.id), data).then((res) => {
                    loc[objindex].initiative[loc[objindex].initiative.findIndex((i) => { return i.id === inaction.id })] = data;
                    setSubmitted(false)
                    setObjective(loc)
                    setInActionDialog(false)
                })

            }

        }
    }
    const addNewInitiative = () => {
        let item = { country: 'All', city: 'All', location: 'All' }
        let selected_item = { country: '', city: '', location: '' }
        let country_list = [{ name: 'All', id: 'All' }]
        let city_list = [{ name: 'All', id: 'All' }]
        let location_list = [{ name: 'All', id: 'All' }]
        site.forEach((country) => {
            country_list.push(country)
            if (item.country === 'All') {
                selected_item.country = 'All'


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id

                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            } else if (item.country === country.id) {
                selected_item.country = country.id


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            }

        })



        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
        setInActionDialog(true);

        forceUpdate()
    }
    const addNewObjective = () => {
        let loc = objective;
        let loc2 = newgoal
        setSubmitted(true)


        if (newgoal.title.trim().length > 0) {

            if (newgoal.id === undefined) {

                let data = JSON.parse(JSON.stringify([newgoal]))[0]
                data['category'] = 0
                data['alignment'] = 0
                data['created_on'] = DateTime.utc()
                data['boundary'] = []
                data['created_by'] = login_data.id
                delete data.selected
                data['sdg'] = data.sdg.map((i) => { return i.code })

                APIServices.post(API.Goal_UP(admin_data.id), data).then((res) => {

                    loc.push(res.data)
                    setSubmitted(false)
                    setNewGoal((prev) => ({ 'title': '', 'selected': 1, 'category': null, 'alignment': null, 'boundary': null, 'sdg': [], 'alignment_tag': [] }))

                    setObjDialog(false)
                })



            } else {

                let data = JSON.parse(JSON.stringify([newgoal]))[0]
                data['category'] = 0
                data['alignment'] = 0
                data['boundary'] = []
                data['sdg'] = data.sdg.map((i) => { return i.code })
                data['modified_on'] = DateTime.utc()
                delete data.selected

                APIServices.patch(API.Goal_Edit(newgoal.id), data).then((res) => {

                    let index = loc.findIndex((k) => { return k.id === newgoal.id })

                    loc[index] = newgoal
                    setSubmitted(false)
                    setNewGoal((prev) => ({ 'title': '', 'selected': 1, 'category': null, 'alignment': null, 'boundary': null, 'sdg': [], alignment_tag: [] }))
                    setObjDialog(false)
                })



            }
        }

        setObjective(loc);
        forceUpdate()
    }
    const validateInterimTarget = (item) => {

        let check = true;
        if (item.length === 1) {
            item.forEach((tar) => {
                if (tar.unit.trim().length === 0 || tar.xaxis === null || tar.yaxis.trim().length === 0) {
                    check = true
                }
            })
        } else {
            item.forEach((tar) => {
                if (tar.unit.trim().length === 0 || tar.xaxis === null || tar.yaxis.trim().length === 0) {
                    check = false
                }
            })
        }

        return check;
    }
    const showInterimTarget = (item) => {
        console.log(item)
        let check = false;
        if (item.length === 1) {
            item.forEach((tar) => {
                if (tar.unit.trim().length !== 0 && tar.xaxis !== null && tar.yaxis.trim().length !== 0) {
                    check = true
                }
            })
        } else {
            item.forEach((tar) => {
                if (tar.unit.trim().length === 0 || tar.xaxis === null || tar.yaxis.trim().length === 0) {
                    check = false
                }
            })
        }

        return check;
    }
    const addnewindicator = () => {
        let loc = objective;
        let loc2 = newindicator
        let objindex = objective.findIndex((i, ind) => { return i.selected === 1 })

        setSubmitted(true)

        if ((newindicator.baseDate !== null && newindicator.targetDate !== null && newindicator.baseUnit.trim().length !== 0 && newindicator.baseValue.trim().length !== 0 && newindicator.indicator !== null && newindicator.targetValue.trim().length !== 0 && validateInterimTarget(newindicator.interim_target)) || (newindicator.type === 1)) {
            if (newindicator.id === undefined) {

                let data = JSON.parse(JSON.stringify([newindicator]))[0]
                data.interim_target.forEach((i) => {
                    i.unit = data.baseUnit
                })

                data['created_on'] = DateTime.utc()
                data['created_by'] = login_data.id
                data['type'] = 0
                data['comments'] = []

                APIServices.post(API.Indicator_Goal(objective[objindex].id), data).then((res) => {

                    loc[objindex].indicator.push(res.data)
                    setSubmitted(false)
                    setNewIndicator((prev) => ({ title: '', type: 0, interim_frequency: 'yearly', frequency: 'yearly', interim_target: [{ xaxis: null, unit: '', yaxis: '' }], baseValue: '', targetValue: '', baseDate: null, targetDate: null, baseUnit: '', indicator: null }))
                    setIndicatorDialog(false)
                })



            } else {

                let data = JSON.parse(JSON.stringify([newindicator]))[0]
                delete data.selected
                delete data.newTargetsTwoId
                data.interim_target.forEach((i) => {
                    i.unit = data.baseUnit
                })
                data['modified_on'] = DateTime.utc()
                data['type'] = 0
                data['comments'] = []
                APIServices.patch(API.Indicator_Edit(newindicator.id), data).then((res) => {

                    let index = loc[objindex].indicator.findIndex((k) => { return k.id === newindicator.id })

                    loc[objindex].indicator[index] = data
                    setNewIndicator((prev) => ({ title: '', type: 0, interim_frequency: 'yearly', frequency: 'yearly', interim_target: [{ xaxis: null, unit: '', yaxis: '' }], baseValue: '', targetValue: '', baseDate: null, targetDate: null, baseUnit: '', indicator: null }))

                    setSubmitted(false)
                    setIndicatorDialog(false)
                })



            }


        } else if (!validateInterimTarget(newindicator.interim_target)) {
            alert('Field not to be empty in Interim Targets')
        }

        forceUpdate()
    }
    const addnewtarget = () => {
        let loc = JSON.parse(JSON.stringify(objective));
        let loc2 = newtarget
        let objindex = objective.findIndex((i, ind) => { return i.selected === 1 })

        setSubmitted(true)

        if (newtarget.title.trim().length > 0) {
            if (newtarget.id === undefined) {

                let data = JSON.parse(JSON.stringify([newtarget]))[0]


                APIServices.post(API.Target_Goal_2(objective[objindex].id), data).then((res) => {

                    loc[objindex].target.push(res.data)
                    setObjective(loc)

                    setSubmitted(false)
                    // setNewTarget((prev) => ({ title: '', description: '', type: 0, comments: [], data1: [], created_on: moment().utc(), created_by: login_data.id }))
                    setSubmitted(false)
                    setTargetDialog(false)
                    forceUpdate()
                })



            } else {

                let data = JSON.parse(JSON.stringify([newtarget]))[0]
                delete data.selected
                delete data.indicator
                delete data.indicator_view

                APIServices.patch(API.Target_Two_Edit(newtarget.id), data).then((res) => {

                    let index = loc[objindex].target.findIndex((k) => { return k.id === newtarget.id })

                    loc[objindex].target[index] = newtarget

                    setObjective(prev => (loc))


                    // setNewTarget((prev) => ({ title: '', description: '', type: 0, comments: [], data1: [], created_on: moment().utc(), created_by: login_data.id }))

                    setSubmitted(false)
                    setTargetDialog(false)

                    forceUpdate()
                })



            }

        }

        forceUpdate()
    }
    const addNewStatus = () => {
        setSubmitted(true)
        let objindex = objective.findIndex((i) => { return i.selected === 1 })
        let initindex = objective[objindex].initiative.findIndex((i) => { return i.selected === 1 })
        let loc = objective
        if (newstatus.text.trim().length > 0 && newstatus.indicator !== null && newstatus.responsibility.trim().length !== 0) {
            loc[objindex].initiative[initindex].status.unshift([DateTime.utc(), newstatus.text, newstatus.responsibility, newstatus.indicator])
            let data = loc[objindex].initiative[initindex]
            data['modified_on'] = DateTime.utc()
            APIServices.patch(API.Initiative_Edit(objective[objindex].initiative[initindex].id), data).then((res) => {
                setNewStatus({ text: '', responsibility: '', indicator: 3 });
                setObjective(loc)
                setSubmitted(false)
                setAddStatusdialog(false)
            })

        }

        forceUpdate()
    }
    const addNewAction = () => {
        let loc = objective.findIndex((i) => { return i.selected === 1 })

        if (newaction.trim().length > 0) {

            objective[loc].initiative[row].initiatives.push([DateTime.utc(), newaction])
            setNewAction("");
            setAddActiondialog(false)
        }

        forceUpdate()
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add New" icon="pi pi-plus" className="p-button-help" onClick={() => { addNewInitiative() }} />
            </React.Fragment>
        );
    };
    const textEditor = (options) => {

        return <InputTextarea rows={5} cols={30} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />

    }
    const showAddDialog = (e, ind) => {
        row = ind
        // e.preventDefault()
        setAddStatusdialog(true)
    }
    const showAddActionDialog = (e, ind) => {
        row = ind
        // e.preventDefault()
        setAddActiondialog(true)
    }
    const status_Editor = (options) => {

        return (
            <div >
                {options.value.sort(function (left, right) {
                    return moment.utc(left[0]).diff(moment.utc(right[0]))
                }).reverse().map((item, ind) => {
                    if (ind === 0) {
                        let bgcolor = ''
                        if (item[2] === 1) {
                            bgcolor = 'red'
                        } else if (item[2] === 2) {
                            bgcolor = "#FFBF00"
                        } else {
                            bgcolor = 'green'
                        }
                        return (
                            <>
                                <div style={{ flexDirection: 'column', display: 'flex', padding: 5 }}>
                                    <text style={{ color: 'white', borderRadius: 10, padding: 5, fontStyle: 'italic', backgroundColor: bgcolor }}>As of {DateTime.fromISO(item[0], { zone: 'utc' }).toLocal().toFormat("yyyy LLL dd HH':'mm a")}</text>

                                    <text style={{ padding: 5 }}>{item[1]}</text>
                                    {options.value.length > 1 &&


                                        <div id='viewhis'>
                                            <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 10, color: '#003153' }}><label onClick={() => {
                                                viewHistory(options.value.sort(function (left, right) {
                                                    return DateTime.fromISO(right[0]).diff(DateTime.fromISO(left[0]))
                                                }).reverse())
                                            }}>view history</label></div>
                                        </div>

                                    }

                                </div>
                                {ind !== options.value.length - 1 && <hr />}
                            </>
                        )
                    }
                })
                }
                <i class="material-icons" style={{
                    justifyContent: 'center',
                    display: 'flex'
                }} onClick={(e) => { showAddDialog(e, options.rowIndex) }}>add</i>
            </div >
        )

    }
    const boundary_Editor = (options) => {
        setAppRowIndex(options.rowIndex)
        let item = options.value
        let selected_item = { country: '', city: '', location: '' }
        let country_list = [{ name: 'All', id: 'All' }]
        let city_list = [{ name: 'All', id: 'All' }]
        let location_list = [{ name: 'All', id: 'All' }]
        site.forEach((country) => {
            country_list.push(country)
            if (item.country === 'All') {
                selected_item.country = 'All'


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id

                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            } else if (item.country === country.id) {
                selected_item.country = country.id


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            }

        })
        return (
            <div >
                <label>{label1}</label>
                <Dropdown style={{ margin: 10, width: '100%' }} value={options.value.country} options={country_list} optionLabel="name" optionValue="id"
                    onChange={(e) => { renderLocationDropdown({ country: e.value, city: 'All', location: 'All' }, options); forceUpdate() }} placeholder={"Select " + label1}
                />
                <label>{label2}</label>
                <Dropdown style={{ margin: 10, width: '100%' }} value={options.value.city} options={city_list} optionLabel="name" optionValue="id"
                    onChange={(e) => { renderLocationDropdown({ country: options.value.country, city: e.value, location: 'All' }, options); forceUpdate() }} placeholder={"Select " + label2}
                />
                <label>{label3}</label>
                <Dropdown style={{ margin: 10, width: '100%' }} value={options.value.location} options={location_list} optionLabel="name" optionValue="id"
                    onChange={(e) => { renderLocationDropdown({ country: options.value.country, city: options.value.city, location: e.value }, options); forceUpdate() }} placeholder={"Select " + label3}
                />
            </div >
        )

    }
    const action_Editor = (options) => {
        // options.value = options.value === undefined ? [] : options.value

        // let st = `<div style='flex-direction:column;display:flex;'>`
        // options.value.forEach((item, ind) => {
        //     st = st + `<i class="material-icons">delete</i><text>updated on ${item[0]}</text> <text>${item[1]}</text>`

        // })

        // st = st + `<i class="material-icons"   onclick="document.getElementById('statdiv').click(); " >add</i></div>`
        // // st = st + `<i class="material-icons" onclick="${showAddDialog()}" >add</i></div>`


        // return <div dangerouslySetInnerHTML={{ __html: st }} />;

        return (
            <div >
                {options.value.sort(function (left, right) {
                    return moment.utc(left[0]).diff(moment.utc(right[0]))
                }).reverse().map((item, ind) => {
                    return (
                        <div style={{ flexDirection: 'column', display: 'flex', padding: 10, width: 180 }}>

                            <text style={{ padding: 5 }}>{item[1]}</text>
                            <text><span style={{ color: 'green', fontStyle: 'italic' }}>{DateTime.fromISO(item[0], { zone: 'utc' }).toLocal().toFormat("yyyy LLL dd HH':'mm a")}</span> </text>

                        </div>
                    )
                })
                }
                {options.value.length === 0 && <i class="material-icons" onClick={(e) => { showAddActionDialog(e, options.rowIndex) }}>add</i>}
            </div >
        )

    }
    const statusEditor = (options) => {

        return (
            <Dropdown value={options.rowData.indicator} options={[{ name: "On Target", value: 3 }, { name: 'Slow', "value": 2 }, { name: 'Inadequate Progress', value: 1 }]} optionLabel="name" optionValue="value"
                onChange={(e) => { options.editorCallback(e.value); forceUpdate() }} placeholder="Select a Status"
            />
        );
    }
    const onRowEditComplete = (e) => {
        let datas = e.newData
        let loc = objective

        datas['modified_on'] = DateTime.utc()
        APIServices.patch(API.Initiative_Edit(e.data.id), datas).then((res) => {
            let objindex = objective.findIndex((i) => { return i.selected === 1 })
            loc[objindex].initiative[loc[objindex].initiative.findIndex((k) => { return k.id === e.newData.id })] = e.newData;

            setObjective(loc)
            forceUpdate()
        })
    }
    const saveInitiatives = () => {

        let index = objective.findIndex((k) => { return k.selected === 1 })
        objective[index].initiative = initiatives;
        forceUpdate()


    }
    const itemSelected = (item, ind) => {

        let obj = objective;
        obj.forEach((ob, ins) => {
            if (ins === ind) {
                if (ob.selected === 0 || ob.selected === undefined) {
                    APIServices.get(API.Initiative_Goal(ob.id)).then((res) => {
                        ob.action_view = 0
                        ob.initiative = res.data
                        forceUpdate()
                    })
                    APIServices.get(API.Target_Goal_2(ob.id)).then((res) => {
                        ob.target = res.data
                        ob.target_view = 0
                        forceUpdate()
                    })
                    APIServices.get(API.Indicator_Goal(ob.id)).then((res) => {
                        let indilist = JSON.parse(JSON.stringify(indicatorlist)).map((i) => { return i.id })
                        ob.indicator = res.data.filter((i) => { return indilist.includes(i.indicator) })
                        ob.indicator_view = 0
                        forceUpdate()
                    })
                    ob.selected = 1
                } else {
                    ob.selected = 0
                }

            } else {
                ob.selected = 0
            }
        });

        setObjective(obj)

        forceUpdate()
    }
    const itemSelectedTarget = (ind) => {

        let obj = objective;
        let objindex = objective.findIndex((i, ind) => { return i.selected === 1 })

        obj[objindex].target.forEach((ob, ins) => {
            if (ins === ind) {

                if (ob.selected === 0 || ob.selected === undefined) {

                    ob.selected = 1
                } else {
                    ob.selected = 0
                }

            } else {
                ob.selected = 0
            }
        });


        setObjective(obj)

        forceUpdate()
    }
    const itemSelectedIndicator = (ind) => {

        let obj = objective;
        let objindex = objective.findIndex((i, ind) => { return i.selected === 1 })

        obj[objindex].indicator.forEach((ob, ins) => {
            if (ins === ind) {

                if (ob.selected === 0 || ob.selected === undefined) {
                    ob.selected = 1
                } else {
                    ob.selected = 0
                }

            } else {
                ob.selected = 0
            }
        });


        setObjective(obj)

        forceUpdate()
    }
    const itemSelectedInitiative = (ind) => {

        let obj = objective;
        let objindex = objective.findIndex((i, ind) => { return i.selected === 1 })

        obj[objindex].initiative.forEach((ob, ins) => {
            if (ins === ind) {

                if (ob.selected === 0 || ob.selected === undefined) {
                    let item = ob.applicability

                    let country_list = [{ name: 'All', id: 'All' }]
                    let city_list = [{ name: 'All', id: 'All' }]
                    let location_list = [{ name: 'All', id: 'All' }]
                    site.forEach((country) => {
                        country_list.push(country)
                        if (item.country === 'All') {



                            city_list.push(...country.children)
                            country.children.forEach((city) => {
                                if (item.city === 'All') {

                                    location_list.push(...city.children)

                                    city.children.forEach((location) => {
                                        if (item.location === 'All') {


                                        } else if (item.location === location.id) {


                                        }
                                    })
                                } else if (item.city === city.id) {

                                    location_list.push(...city.children)

                                    city.children.forEach((location) => {
                                        if (item.location === 'All') {



                                        } else if (item.location === location.id) {



                                        }
                                    })

                                }
                            })
                        } else if (item.country === country.id) {



                            city_list.push(...country.children)
                            country.children.forEach((city) => {
                                if (item.city === 'All') {


                                    location_list.push(...city.children)
                                    city.children.forEach((location) => {
                                        if (item.location === 'All') {

                                        } else if (item.location === location.id) {



                                        }
                                    })
                                } else if (item.city === city.id) {


                                    location_list.push(...city.children)
                                    city.children.forEach((location) => {
                                        if (item.location === 'All') {



                                        } else if (item.location === location.id) {



                                        }
                                    })

                                }
                            })
                        }

                    })
                    setLocList({ country: country_list, city: city_list, location: location_list })
                    ob.selected = 1
                } else {
                    ob.selected = 0
                }

            } else {
                ob.selected = 0
            }
        });


        setObjective(obj)

        forceUpdate()
    }
    if (addStatusdialog || addActiondialog) {
        if (document.getElementById('statinput')) {

            setTimeout(() => {
                document.getElementById('statinput').focus()
            }, 20)

        }
    }

    const updateStatus = (e, val, obj) => {

        let loc = newstatus
        if (obj === 'text') {
            loc[obj] = val !== null ? val.replace('<img', `<img width='50%'`) : val
        } else {
            loc[obj] = val
        }


        setNewStatus(loc); forceUpdate();
    }
    const updateAction = (e, val) => {
        e.preventDefault(); setNewAction(val); forceUpdate();
    }
    const showMapping = () => {

        if (selected.country.name === 'All' && city.length === 0 && location.length === 0) {
            return { data: { name: 'Global' }, type: 'global' }
        } else if (selected.country.name !== 'All' && city.length === 0 && location.length === 0) {

            return { data: selected.country, type: 'locationOnes' }
        } else if (city.length !== 0 && location.length === 0) {
            if (selected.city.name === 'All') {

                return { data: selected.country, type: 'locationOnes' }
            } else {

                return { data: selected.city, type: 'locationOnes' }
            }
        } else if (city.length !== 0 && location.length !== 0) {
            if (selected.location.name === 'All') {


                return { data: selected.city, type: 'locationTwos' }
            } else {

                return { data: selected.location, type: 'locationThrees' }
            }
        }
    }
    const updateNewGoal = (obj, val) => {
        let loc = JSON.parse(JSON.stringify([newgoal]))[0];
        loc[obj] = val;


        setNewGoal(loc)

        forceUpdate()
    }
    const updateNewInAction = (obj, val) => {
        let loc = inaction;
        if (obj === 'initiatives') {
            loc[obj][0] = val
            loc[obj][1] = DateTime.utc()
        } else if (obj === 'status') {
            loc[obj][0][0] = DateTime.utc()
            loc[obj][0][1] = val

        } else if (obj === 'applicability') {
            let item = val
            let selected_item = { country: '', city: '', location: '' }
            let country_list = [{ name: 'All', id: 'All' }]
            let city_list = [{ name: 'All', id: 'All' }]
            let location_list = [{ name: 'All', id: 'All' }]
            site.forEach((country) => {
                country_list.push(country)
                if (item.country === 'All') {
                    selected_item.country = 'All'


                    city_list.push(...country.children)
                    country.children.forEach((city) => {
                        if (item.city === 'All') {
                            selected_item.city = 'All'
                            location_list.push(...city.children)

                            city.children.forEach((location) => {
                                if (item.location === 'All') {
                                    selected_item.location = 'All'

                                } else if (item.location === location.id) {
                                    selected_item.location = location.id

                                }
                            })
                        } else if (item.city === city.id) {
                            selected_item.city = city.id
                            location_list.push(...city.children)

                            city.children.forEach((location) => {
                                if (item.location === 'All') {
                                    selected_item.location = 'All'


                                } else if (item.location === location.id) {
                                    selected_item.location = location.id


                                }
                            })

                        }
                    })
                } else if (item.country === country.id) {
                    selected_item.country = country.id


                    city_list.push(...country.children)
                    country.children.forEach((city) => {
                        if (item.city === 'All') {
                            selected_item.city = 'All'

                            location_list.push(...city.children)
                            city.children.forEach((location) => {
                                if (item.location === 'All') {
                                    selected_item.location = 'All'

                                } else if (item.location === location.id) {
                                    selected_item.location = location.id


                                }
                            })
                        } else if (item.city === city.id) {
                            selected_item.city = city.id

                            location_list.push(...city.children)
                            city.children.forEach((location) => {
                                if (item.location === 'All') {
                                    selected_item.location = 'All'


                                } else if (item.location === location.id) {
                                    selected_item.location = location.id


                                }
                            })

                        }
                    })
                }

            })
            setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

            loc[obj] = selected_item

        } else if (obj === 'responsibility') {
            loc['status'][0][2] = val
            loc[obj] = val
        } else if (obj === 'indicator') {
            loc['status'][0][3] = val
            loc[obj] = val
        } else if (obj === 'date') {
            loc['initiatives'][2] = val
        }

        setInAction(loc);
        forceUpdate()
    }
    const updatenewtarget = (obj, val) => {
        let loc = newtarget;
        if (obj === 'title') {
            loc[obj] = val !== null ? val.replace('<img', `<img width='50%'`) : val === null ? '' : val
        } else {
            loc[obj] = val;
        }

        setNewTarget(loc)
        forceUpdate()
    }
    const updatenewindicator = (obj, val) => {

        let loc = newindicator;

        loc[obj] = val;
        if (obj === 'by') {
            loc.from = null;
            loc.to = null;
            loc.fromto = null;
        }
        if (obj === 'fromto') {
            loc.data = [];
        }
        if (obj === 'frequency') {
            if (val === 'monthly') {
                loc.interim_frequency = 'monthly'
            }
        }
        if (obj === 'type') {



            if (!val) {

                loc = { ...loc, type: 1, frequency: 'yearly', interim_frequency: 'yearly', interim_target: [{ xaxis: null, unit: '', yaxis: '' }], baseValue: '', targetValue: '', baseDate: '', targetDate: '', baseUnit: '' }
            } else {
                if (loc.indicator !== null) {
                    let ind = indicatorlist.findIndex((k) => { return k.id === loc.indicator })
                    if (ind !== -1 && Array.isArray(indicatorlist[ind].data1) && indicatorlist[ind].data1[0].unit !== undefined) {
                        loc.interim_target = [{ xaxis: null, unit: indicatorlist[ind].data1[0].unit, yaxis: '' }]
                        loc.baseUnit = indicatorlist[ind].data1[0].unit
                    }
                }

                loc[obj] = 0
                loc['baseDate'] = null;
                loc['targetDate'] = null;
                console.log(loc)
            }
        }
        if (obj === 'indicator') {
            let fd = indicatorlist.find((i) => { return i.id === val })
            if (fd) {
                loc['baseUnit'] = fd.data1[0].unit
            }
        }

        setNewIndicator(loc)



        forceUpdate()
    }
    const updatenewindicatorUnit = (val) => {
        let loc = newindicator;
        loc.baseUnit = val;

        loc.interim_target.forEach((item) => {
            item.unit = val
        })
        setNewIndicator(loc)
        forceUpdate()
    }

    const updateInterimTarget = (obj, index, val) => {
        let loc = newindicator;
        loc.interim_target[index][obj] = val;
        setNewIndicator(loc)
    }
    const showDataFill = () => {

        if (newindicator.fromto === null) {
            return { result: false }
        } else if (newindicator.fromto.filter((i) => { return i !== null }).length === 2) {
            let diff = 0;
            if (newindicator.by.name === 'Month') {
                diff = moment(newindicator.fromto[1]).diff(moment(newindicator.fromto[0]), 'month')

            } else {
                diff = moment(newindicator.fromto[1]).diff(moment(newindicator.fromto[0]), 'year')
            }

            return { result: true, diff }

        } else {
            return { result: false }

        }
    }
    const addNewRow = () => {
        let loc = newindicator;
        let check = true
        loc.interim_target.forEach((item) => {

            if (item.xaxis === null || item.yaxis.trim().length === 0) {
                check = false
            }
        })
        if (check) {
            loc.interim_target.push({ xaxis: null, unit: newindicator.baseUnit, yaxis: '' })

            setNewIndicator(loc)
            forceUpdate()
        } else {
            alert('Some field found empty in target interim')
        }

    }
    const deleteRow = (index) => {

        let loc = newindicator;
        if (loc.interim_target.length !== 1) {

            loc.interim_target.splice(index, 1)
        } else {
            loc.interim_target = [{ xaxis: null, unit: newindicator.baseUnit, yaxis: '' }]
        }
        setNewIndicator(loc)
        forceUpdate()
    }
    const renderLocationTree = (data, type, callback) => {

        if (document.getElementById('loctree') !== null) {

            $('#loctree').jstree({
                'core': {
                    'check_callback': true,
                    'data':
                        data,
                    "themes": {
                        "icons": false
                    }
                },
                'checkbox': {
                    'keep_selected_style': false
                },
                'search': {
                    'case_insensitive': true,
                    'show_only_matches': true
                },
                'plugins': ['checkbox', 'search']
            });
            $('#loctree').on('check_node.jstree uncheck_node.jstree changed.jstree', function (e,
                datas) {
                // Get the list of checked node IDs


                if (datas.event) {
                    var id = $('#type-id').val();
                    var type = $('#user-type').val();
                    var selected = $('#loctree').jstree("get_selected");
                    var overall = $('#loctree').jstree(true).get_selected(true);
                    if (callback !== undefined) {
                        callback.editorCallback(selected)
                    }
                    setNewGoal((prev) => ({ ...prev, 'boundary': selected }))
                    //  updateNewGoal('boundary', selected)

                    // Send the list of checked node IDs to the backend server
                    // if (id && type) {
                    //     save_checked_units(id, type, selected, overall);
                    //     save_assignment(id, type, selected, overall);
                    // }
                }
                // console.log(checkedNodeIds);


            });
            $('#loctree').delegate("a", "click", function (event, data) {
                $.jstree._reference(this).open_all(this, false, false);
                $(event.target).find('.jstree-checkbox').click()
            })
            $('#loctree').jstree(true).settings.core.data = data;

            if (type === undefined || type === null) {
                $('#loctree').jstree(true).refresh();
            }


        }
    }
    const editTarget = (item, index) => {


        setNewTarget(JSON.parse(JSON.stringify(item)))
        setTargetDialog(true);

        forceUpdate()
    }
    const editAction = (items, index) => {
        let actionItem = JSON.parse(JSON.stringify(items))
        let item = actionItem.applicability
        let selected_item = { country: '', city: '', location: '' }
        let country_list = [{ name: 'All', id: 'All' }]
        let city_list = [{ name: 'All', id: 'All' }]
        let location_list = [{ name: 'All', id: 'All' }]
        site.forEach((country) => {
            country_list.push(country)
            if (item.country === 'All') {
                selected_item.country = 'All'


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id

                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            } else if (item.country === country.id) {
                selected_item.country = country.id


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            }

        })
        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        actionItem.initiatives[2] = moment.utc(actionItem.initiatives[2]).toDate();

        setInAction(actionItem)
        setInActionDialog(true);

        forceUpdate()
    }
    const editIndicator = (item, index) => {
        var baseUTC = moment.utc(item.baseDate).toDate();
        var targetUTC = moment.utc(item.targetDate).toDate();
        item.interim_target.forEach((s) => {
            s.xaxis = moment.utc(s.xaxis).toDate();
        })

        item.baseDate = baseUTC
        item.targetDate = targetUTC


        setNewIndicator(item)
        setIndicatorDialog(true);

        forceUpdate()
    }
    const renderLocationDropdown = (item, callback) => {



        let selected_item = { country: '', city: '', location: '' }
        let country_list = [{ name: 'All', id: 'All' }]
        let city_list = [{ name: 'All', id: 'All' }]
        let location_list = [{ name: 'All', id: 'All' }]
        site.forEach((country) => {
            country_list.push(country)
            if (item.country === 'All') {
                selected_item.country = 'All'


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id

                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id
                        location_list.push(...city.children)

                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            } else if (item.country === country.id) {
                selected_item.country = country.id


                city_list.push(...country.children)
                country.children.forEach((city) => {
                    if (item.city === 'All') {
                        selected_item.city = 'All'

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'

                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })
                    } else if (item.city === city.id) {
                        selected_item.city = city.id

                        location_list.push(...city.children)
                        city.children.forEach((location) => {
                            if (item.location === 'All') {
                                selected_item.location = 'All'


                            } else if (item.location === location.id) {
                                selected_item.location = location.id


                            }
                        })

                    }
                })
            }

        })
        if (callback !== undefined) {

            callback.editorCallback(selected_item)

        } else {
            setBoundarydialog(true)
        }
        setSelectLoc((prev) => ({ ...prev, country: selected_item.country, city: selected_item.city, location: selected_item.location }))

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        forceUpdate();

    }
    const editGoal = (item, index) => {
        let setSite = JSON.parse(JSON.stringify(site))
        // setSite.forEach((country) => {
        //     if (item.boundary.findIndex((k) => { return k === country.id }) !== -1) {
        //         console.log(item)
        //         country.state = {
        //             selected: true
        //         }
        //     }
        //     country.children.forEach((city) => {
        //         if (item.boundary.findIndex((k) => { return k === city.id }) !== -1) {
        //             console.log(item)
        //             city.state = {
        //                 selected: true
        //             }
        //         }
        //         city.children.forEach((location) => {
        //             if (item.boundary.findIndex((k) => { return k === location.id }) !== -1) {
        //                 console.log(item)
        //                 location.state = {
        //                     selected: true
        //                 }
        //             }
        //         })
        //     })
        // })

        // console.log(JSON.parse(JSON.stringify([item])))
        setNewGoal(item)
        setObjDialog(true);
        // setTimeout(() => {

        //     renderLocationTree(setSite, item)

        // }, 200)
        forceUpdate()
    }

    const deleteGoal = async (item) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete the goal "${item.title}"</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.Goal_Edit(item.id)).then((res) => {
                let objindex = objective.findIndex((i) => { return i.id === item.id })
                let loc = objective;
                loc.splice(objindex, 1)
                setObjective(loc);
                forceUpdate()

                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Goal Deleted Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
        }

    }
    const deleteTarget = async (item) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete the target "${item.title}"</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {

            APIServices.delete(API.Target_Two_Edit(item.id)).then((res) => {
                let objindex = objective.findIndex((i) => { return i.selected === 1 })
                let tarindex = objective[objindex].target.findIndex((i) => { return i.id === item.id })

                let loc = objective;
                loc[objindex].target.splice(tarindex, 1)
                setObjective(loc);
                forceUpdate()
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Target Deleted Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
        }

    }
    const deleteIndicator = async (item) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete the Indicator "${item.title}"</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {

            APIServices.delete(API.Indicator_Edit(item.id)).then((res) => {
                let objindex = objective.findIndex((i) => { return i.selected === 1 })
                // let tarindex = objective[objindex].target.findIndex((i) => { return i.selected === 1 })
                let indindex = objective[objindex].indicator.findIndex((i) => { return i.id === item.id })
                console.log(objective[objindex])
                let loc = objective;
                loc[objindex].indicator.splice(indindex, 1)
                setObjective(loc);
                forceUpdate()
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Indicator Deleted Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
        }

    }
    const getLocationNameById = (id) => {
        let loctree = JSON.parse(JSON.stringify(site));
        let result = 'All'
        loctree.forEach((item) => {
            if (item.id === id) {

                result = item.name
            } else {
                item.children.forEach((item2) => {

                    if (item2.id === id) {
                        result = item2.name
                    } else {
                        item2.children.forEach((item3) => {
                            if (item3.id === id) {
                                result = item3.name
                            }
                        })
                    }
                })
            }
        })
        return result
    }
    return (
        <div className="grid p-fluid">

            {(login_data.role === "clientadmin" || login_data.role === "clientuser") ? (
                <div className="col-12 md:col-12">

                    <>
                        {true &&
                            <>
                                <div id='statdiv' onClick={(e, r) => { setAddStatusdialog(!addStatusdialog) }} />
                                <div>

                                    <div style={{ justifyContent: 'right', display: 'flex', marginTop: 15 }}>
                                        <Button label="Add  Goal" style={{ width: "130px", border: 0, display: 'flex', backgroundColor: '#315975', }} icon="pi pi-plus" className="p-button-help" onClick={() => { setObjDialog(true); setSubmitted(false); setNewGoal((prev) => ({ 'title': '', 'selected': 1, 'category': null, 'alignment': null, 'boundary': null, 'sdg': [], alignment_tag: [] })); }} />

                                    </div>

                                    <div
                                    // className="p-card" 
                                    // style={{ backgroundColor: 'white', padding: 10, marginBottom: 10 }} 
                                    >

                                        {/* <div
                                            className="col-12 md:col-12"
                                            style={{
                                                textAlign: 'center',
                                                background: 'grey',
                                                color: 'white', position: 'relative',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            <text>GOALS</text>
                                            <div style={{
                                                position: 'absolute',
                                                right: '10px', display: 'flex',
                                                top: '5px'
                                            }}>
                                                <text style={{
                                                    paddingTop: '2px',
                                                    background: 'white',
                                                    color: 'black',
                                                    width: '20px',
                                                    textAlign: 'center',
                                                    alignSelf: 'center',
                                                    borderRadius: '10px',
                                                    height: '20px'
                                                }}>{objective.length} </text>

                                            </div>
                                        </div> */}

                                        {/* <div> */}
                                        {/* <div style={{ padding: 10 }}>
                                            <h4>Add Goal for <span style={{ color: '#005284' }}>{showMapping() !== undefined && showMapping().data.name}</span></h4>
                                        </div> */}

                                        {/* </div> */}
                                        {
                                            <div style={{ maxHeight: 500, overflow: 'auto', marginTop: 10 }}>
                                                {objective.map((item, index) => {

                                                    return (
                                                        <div>
                                                            <div onClick={() => { itemSelected(item, index) }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', borderRadius: 10, marginTop: 10, padding: 10, backgroundColor: '#FFFFFF', color: '#222222', width: '100%', border: '1px solid #E0E0E0 ' }}>
                                                                <text style={{ padding: 10 }} > {item.title}</text>

                                                                <div>
                                                                    <i className="material-icons" style={{
                                                                        padding: '5px',
                                                                        color: 'red',
                                                                        borderRadius: '20px',
                                                                        fontSize: '20px',
                                                                        background: 'white'
                                                                    }} onClick={(e) => { e.stopPropagation(); deleteGoal(item) }}>delete</i>
                                                                    <i className="material-icons" style={{
                                                                        padding: '5px',
                                                                        fontSize: '20px',
                                                                        margin: '5px',
                                                                        borderRadius: '20px',
                                                                        background: 'white',
                                                                        color: 'green'
                                                                    }} onClick={(e) => { e.stopPropagation(); editGoal(JSON.parse(JSON.stringify(item)), index) }}>edit</i>

                                                                    {(item.selected === 0 || item.selected === undefined) ? <i style={{
                                                                        background: 'white',
                                                                        color: '#003153',
                                                                        padding: '3px',
                                                                        borderRadius: '20px'
                                                                    }} className="material-icons">navigate_next</i>
                                                                        : <i className="material-icons" style={{
                                                                            background: 'white',
                                                                            color: '#003153',
                                                                            padding: '3px',
                                                                            borderRadius: '20px'
                                                                        }}>expand_less</i>
                                                                    }

                                                                </div>
                                                            </div>
                                                            {item.selected === 1 &&

                                                                <>
                                                                    <div >



                                                                        {(item.target !== undefined && item.target.length !== 0) ? (
                                                                            <div className="" style={{ backgroundColor: '#FFFFFF', padding: 20, marginTop: 10, borderRadius: 10, border: '1px solid #E0E0E0', color: 'white' }}>

                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <div style={{ marginLeft: '10px' }}>
                                                                                        <h1 style={{ fontWeight: 'bold', color: '#222222' }}>Targets</h1>
                                                                                    </div>

                                                                                    <div>
                                                                                        <Button
                                                                                            label="Add target"
                                                                                            icon="pi pi-plus"
                                                                                            style={{
                                                                                                width: "158px", backgroundColor: '#FFFFFF'
                                                                                                , color: '#315975',
                                                                                                border: '1px solid',
                                                                                            }}
                                                                                            className="p-button-help"
                                                                                            onClick={() => {
                                                                                                setNewTarget({ title: '', description: '', type: 0, comments: [], data1: [], created_on: moment().utc(), created_by: login_data.id });
                                                                                                setTargetDialog(true);
                                                                                                setSubmitted(false);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <ol style={{ margin: 0 }}>
                                                                                    {item.target.length > 0 ? (
                                                                                        item.target.map((item2, index2) => (
                                                                                            <li key={index2} style={{ listStyleType: 'decimal', padding: '10px', color: '#101828' }}>
                                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                                    <div className='ql-editor' style={{ flex: 1, marginRight: 10, color: '#101828' }} dangerouslySetInnerHTML={{ __html: item2.title }} />
                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                        <i className="material-icons" style={{
                                                                                                            padding: '5px', color: 'red', borderRadius: '20px', fontSize: '20px', background: 'white', cursor: 'pointer'
                                                                                                        }} onClick={(e) => { e.stopPropagation(); deleteTarget(item2) }}>delete</i>
                                                                                                        <i className="material-icons" style={{
                                                                                                            padding: '5px', fontSize: '20px', borderRadius: '20px', background: 'white', color: 'green', cursor: 'pointer'
                                                                                                        }} onClick={(e) => { e.stopPropagation(); editTarget(item2) }}>edit</i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        ))
                                                                                    ) : (
                                                                                        <li style={{ textAlign: 'center', color: 'white', marginTop: 10 }}>No targets found.</li>
                                                                                    )}
                                                                                </ol>



                                                                            </div>

                                                                        ) :
                                                                            (
                                                                                <div className="col-12 md:col-12"
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    background: '#FFFFFF',
                                                                                    color: '#222222',
                                                                                    position: 'relative',
                                                                                    borderRadius: '10px',
                                                                                    marginBottom: 15,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    marginTop: '10px'
                                                                                }}>
                                                                                    {(item.target === undefined) ? (
                                                                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center' }}></i>
                                                                                    ) : (
                                                                                        <div className="flex align-items-start col-12" style={{padding: 20,flexDirection:'column' }}>
                                                                                            <p className='col-12' style={{ color: '#222222', textAlign:'left' }}>No targets found.</p>
                                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}> {/* Center the button container */}
                                                                                                <Button
                                                                                                    // label="Add target"
                                                                                                    // icon="pi pi-plus"
                                                                                                    label={<span style={{ fontWeight: '700', color: '#315975' }}>Add target</span>} // Apply font weight to the label
                                                                                                    icon={<span style={{ fontWeight: '700' }} className="pi pi-plus"></span>}
                                                                                                    style={{
                                                                                                        width: "158px", backgroundColor: '#FFFFFF'
                                                                                                        , color: '#315975',
                                                                                                        border: '1px solid',
                                                                                                    }}
                                                                                                    className="p-button-help"
                                                                                                    onClick={() => {
                                                                                                        setNewTarget({ title: '', description: '', type: 0, comments: [], data1: [], created_on: moment().utc(), created_by: login_data.id });
                                                                                                        setTargetDialog(true);
                                                                                                        setSubmitted(false);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                            )
                                                                        }



                                                                        <>
                                                                            <div className="col-12 md:col-12"
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    background: '#FFFFFF',
                                                                                    color: '#222222',
                                                                                    position: 'relative',
                                                                                    borderRadius: '10px',
                                                                                    marginBottom: 15,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    marginTop: '10px'
                                                                                }}>
                                                                                {item.indicator && item.indicator.length > 0 ? (
                                                                                    <>
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',paddingTop:'10px' }}>
                                                                                            <div style={{ marginLeft: '10px' }}>
                                                                                                <h1 style={{ fontWeight: 'bold', color: '#222222',margin:'0px',lineHeight:1.5 }}>Indicators</h1>
                                                                                                </div>
                                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                                                                                                <Button
                                                                                                    label="Add Indicator"
                                                                                                    icon="pi pi-plus"
                                                                                                    style={{
                                                                                                        width: "158px", backgroundColor: '#FFFFFF',
                                                                                                        color: '#315975',
                                                                                                        border: '1px solid',
                                                                                                    }}
                                                                                                    className="p-button-help"
                                                                                                    onClick={() => {
                                                                                                        setNewIndicator((prev) => ({
                                                                                                            title: '',
                                                                                                            type: 0,
                                                                                                            interim_frequency: 'yearly',
                                                                                                            frequency: 'yearly',
                                                                                                            interim_target: [{ xaxis: null, unit: '', yaxis: '' }],
                                                                                                            baseValue: '',
                                                                                                            targetValue: '',
                                                                                                            baseDate: null,
                                                                                                            targetDate: null,
                                                                                                            baseUnit: '',
                                                                                                            indicator: null
                                                                                                        }));
                                                                                                        setIndicatorDialog(true);
                                                                                                        setSubmitted(false);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                        <ol>
                                                                                            {item.indicator.map((item3, index3) => (
                                                                                                <li key={index3} style={{ marginBottom: 10 }}>
                                                                                                    <div style={{
                                                                                                        display: 'flex',
                                                                                                        justifyContent: 'space-between',
                                                                                                        alignItems: 'center',
                                                                                                        borderRadius: 10,
                                                                                                        padding: 10,
                                                                                                        color: '#222222'
                                                                                                    }}>


                                                                                                        <span style={{ paddingRight: 10 }}>
                                                                                                            {indicatorlist.find((i) => i.id === item3.indicator)?.title}
                                                                                                        </span>

                                                                                                      <div>
                                                                                                            <i className="material-icons"
                                                                                                                style={{
                                                                                                                    padding: '5px',
                                                                                                                    color: 'red',
                                                                                                                    borderRadius: '20px',
                                                                                                                    fontSize: '20px',
                                                                                                                    background: 'white'
                                                                                                                }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation();
                                                                                                                    deleteIndicator(item3);
                                                                                                                }}>
                                                                                                                delete
                                                                                                            </i>
                                                                                                            <i className="material-icons"
                                                                                                                style={{
                                                                                                                    padding: '5px',
                                                                                                                    fontSize: '20px',
                                                                                                                    margin: '5px',
                                                                                                                    borderRadius: '20px',
                                                                                                                    background: 'white',
                                                                                                                    color: 'green'
                                                                                                                }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.stopPropagation();
                                                                                                                    editIndicator(JSON.parse(JSON.stringify(item3)));
                                                                                                                }}>
                                                                                                                edit
                                                                                                            </i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ol>
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="flex align-items-start col-12" style={{padding: 20,flexDirection:'column' }}>
                                                                                        <p className="col-12" style={{ color: '#222222',textAlign:'left' }}>No indicators added. Please add an indicator.</p>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            <Button
                                                                                                label={<span style={{ fontWeight: '700', color: '#315975' }}>Add Indicator</span>}
                                                                                                icon={<span style={{ fontWeight: '700' }} className="pi pi-plus"></span>}
                                                                                                style={{
                                                                                                    width: "158px",
                                                                                                    backgroundColor: '#FFFFFF',
                                                                                                    color: '#315975',
                                                                                                    border: '1px solid',
                                                                                                }}
                                                                                                className="p-button-help"
                                                                                                onClick={() => {
                                                                                                    setNewIndicator({
                                                                                                        title: '',
                                                                                                        type: 0,
                                                                                                        interim_frequency: 'yearly',
                                                                                                        frequency: 'yearly',
                                                                                                        interim_target: [{ xaxis: null, unit: '', yaxis: '' }],
                                                                                                        baseValue: '',
                                                                                                        targetValue: '',
                                                                                                        baseDate: null,
                                                                                                        targetDate: null,
                                                                                                        baseUnit: '',
                                                                                                        indicator: null
                                                                                                    });
                                                                                                    setIndicatorDialog(true);
                                                                                                    setSubmitted(false);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>


                                                                        </>

                                                                        {/* Initiatives */}
                                                                        <div className="col-12 md:col-12"
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                background: '#FFFFFF',
                                                                                color: '#222222',
                                                                                position: 'relative',
                                                                                borderRadius: '10px',
                                                                                marginBottom: 15,
                                                                                border: '1px solid #E0E0E0',
                                                                                marginTop: '10px'
                                                                            }}>

                                                                            {item.initiative && item.initiative.length > 0 ? (
                                                                                <>
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',paddingTop:'10px' }}>
                                                                                        <div style={{marginLeft:'10px'}}>
                                                                                            <h1 style={{ fontWeight: 'bold', color: '#222222',margin:'0px',lineHeight:1.5 }}>Initiatives</h1>
                                                                                            </div>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                                                                                            <Button
                                                                                                label="Add Initiative"
                                                                                                icon="pi pi-plus"
                                                                                                style={{
                                                                                                    width: "158px",
                                                                                                    backgroundColor: '#FFFFFF',
                                                                                                    color: '#315975',
                                                                                                    border: '1px solid',
                                                                                                }}
                                                                                                className="p-button-help"
                                                                                                onClick={() => {
                                                                                                    // Function to handle adding new initiative
                                                                                                    addNewInitiative();
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <ol style={{ paddingLeft: 20 }}>
                                                                                        {item.initiative.map((item3, index3) => (
                                                                                            <li key={index3} style={{ marginBottom: 10 }}>
                                                                                                <div style={{
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'space-between',
                                                                                                    alignItems: 'center',
                                                                                                    borderRadius: 10,
                                                                                                    padding: 10,
                                                                                                    color: '#222222'
                                                                                                }}>
                                                                                                    <div className='ql-editor' style={{ flex: 1, marginRight: 10, color: '#101828'}}>
                                                                                                        {item3.initiatives[0] !== undefined ? item3.initiatives[0] : 'Unnamed Initiative'}
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', gap: '10px',alignItems:"center"}}>
                                                                                                        <i className="material-icons"
                                                                                                            style={{
                                                                                                                padding: '5px',
                                                                                                                color: 'red',
                                                                                                                borderRadius: '20px',
                                                                                                                fontSize: '20px',
                                                                                                                background: 'white'
                                                                                                            }}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation();
                                                                                                                deleteInitiative(item3, index3);
                                                                                                            }}>
                                                                                                            delete
                                                                                                        </i>
                                                                                                        <i className="material-icons"
                                                                                                            style={{
                                                                                                                padding: '5px',
                                                                                                                fontSize: '20px',
                                                                                                                margin: '5px',
                                                                                                                borderRadius: '20px',
                                                                                                                background: 'white',
                                                                                                                color: 'green'
                                                                                                            }}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation();
                                                                                                                editAction(item3);
                                                                                                            }}>
                                                                                                            edit
                                                                                                        </i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ol>
                                                                                </>
                                                                            ) : (
                                                                                <div className='flex align-items-start col-12' style={{  padding: 20,flexDirection:'column' }}>
                                                                                    <p className='col-12' style={{ color: '#222222',textAlign:'left' }}>No Actions added. Please add an action.</p>
                                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                        <Button
                                                                                            label={<span style={{ fontWeight: '700', color: '#315975' }}>Add Action</span>}
                                                                                            icon={<span style={{ fontWeight: '700' }} className="pi pi-plus"></span>}
                                                                                            style={{
                                                                                                width: "158px",
                                                                                                backgroundColor: '#FFFFFF',
                                                                                                color: '#315975',
                                                                                                border: '1px solid',
                                                                                            }}
                                                                                            className="p-button-help"
                                                                                            onClick={() => {
                                                                                                // Function to handle adding new initiative
                                                                                                addNewInitiative();
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>


                                                                    </div>
                                                                </>

                                                            }
                                                        </div>
                                                    )
                                                })

                                                }
                                            </div>
                                        }
                                        {/* <div style={{ justifyContent: 'center', display: 'flex', marginTop: 15 }}>
                                            <Button label="Add New Goal" style={{ width: "158px", border: 0, display: 'flex', backgroundColor: '#74c2b1', borderRadius: 50 }} icon="pi pi-plus" className="p-button-help" onClick={() => { setObjDialog(true); setSubmitted(false); setNewGoal((prev) => ({ 'title': '', 'selected': 1, 'category': null, 'alignment': null, 'boundary': null, 'sdg': [], alignment_tag: [] })); }} />

                                        </div> */}
                                    </div>

                                </div>

                                {/* <>
                                <div className="p-card" style={{ backgroundColor: 'white', padding: 10, marginBottom: 10 }}>
                                    <div style={{ padding: 10 }}>
                                        <h3>Add Initiative</h3>
                                    </div>
                                    <Button label="Add New" style={{ width: "auto", display: 'flex', marginBottom: 10 }} icon="pi pi-plus" className="p-button-help" onClick={() => { addNewInitiative() }} />
                                    <DataTable
                                        value={initiatives}
                                        style={{
                                            height: 270,
                                        }}
                                        editMode='row'

                                        onRowEditComplete={onRowEditComplete}
                                        header="Process Indicator"
                                        showGridlines
                                        responsiveLayout="scroll"
                                    >
                                        <Column field="id" header="ID">

                                        </Column>
                                        <Column editor={(options) => textEditor(options)} field="action" header="Identified Initiatives">

                                        </Column>
                                        <Column editor={(options) => status_Editor(options)} field="status" body={statusTemplate} header="Status">

                                        </Column>
                                        <Column editor={(options) => statusEditor(options)} field="indicator" body={indicatorTemplate} header="Indicator"></Column>
                                        <Column body={deleteTemplate} header="Action">
                                        </Column>
                                        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                    </DataTable>
                                </div>
                                <div>
                                    <Button label="Save" onClick={saveInitiatives} />

                                </div>
                            </> */}

                            </>
                        }
                    </>
                    <Dialog
                        visible={objdialog}
                        style={{
                            width: "60%", height: '80%'
                        }}
                        header={newgoal.id === undefined ? "Add Goal" : 'Edit Goal #' + newgoal.id}
                        modal
                        className="p-fluid"

                        onHide={() => { setObjDialog(false) }}
                    >
                        <div style={{ margin: 10 }}>
                            <text> Goal Title </text>

                            <InputText value={newgoal.title} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateNewGoal('title', e.target.value) }} placeholder="Goal title" />

                            {submitted && newgoal.title.trim().length <= 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    goal Title required
                                </small>
                            )}
                        </div>

                        <div style={{ marginTop: 20, padding: 10 }} >


                            <label >Alignment with Commitments/Initiatives</label>
                            <Chips value={newgoal.alignment_tag} style={{ padding: 10 }} onChange={(e) => {
                                updateNewGoal("alignment_tag", e.value);
                            }} separator="," />
                            <small
                                className="p-invalid"
                                style={{
                                    color: "gray",
                                }}
                            >

                                ( press , or enter key to add tags )
                            </small>


                        </div>


                        <div style={{ margin: 10, marginTop: '20px !important' }}>
                            <text> SDG Alignment </text>
                            <div className=" flex justify-content-center multiselect-demo" style={{ padding: 10 }}>
                                <MultiSelect value={newgoal.sdg} options={SDG_list} onChange={(e) => updateNewGoal('sdg', e.value)} optionLabel="name" placeholder="Select SDG Alignment" style={{ width: '100%' }} className="multiselect-custom"
                                    itemTemplate={countryTemplate} selectedItemTemplate={selectedCountriesTemplate} />
                            </div>

                        </div>
                        <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addNewObjective() }} />
                    </Dialog>
                    <Dialog
                        visible={indicatordialog}
                        style={{
                            width: "60%", height: '80%'
                        }}
                        header={newindicator.id === undefined ? "Add Indicator" : 'Edit Indicator #' + newindicator.id}
                        modal
                        className="p-fluid"

                        onHide={() => { setIndicatorDialog(false) }}
                    >

                        <div style={{ margin: 10 }}>
                            <text> Indicator <span style={{ color: 'red' }}>*</span> </text>

                            <Dropdown disabled={newindicator.id !== undefined} style={{ margin: 10, width: '100%' }} value={newindicator.indicator} options={indicatorlist} optionLabel="title" optionValue="id"
                                onChange={(e) => { updatenewindicator('indicator', e.value); forceUpdate() }} placeholder="Select Indicator"
                            />

                            {submitted && newindicator.indicator === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Select source indicator
                                </small>
                            )}
                        </div>

                        <div style={{ margin: 10 }} className="flex align-items-center">
                            <Checkbox inputId="view" name="pizza" value={newindicator.type} onChange={(e) => { updatenewindicator('type', e.value) }} checked={newindicator.type === 0} />
                            <label htmlFor="view" className="ml-2">Visualize </label>
                        </div>
                        {newindicator.type === 0 && <>
                            <div style={{ margin: 10 }}>
                                <text> Unit of measure <span style={{ color: 'red' }}>*</span> </text>

                                <InputText disabled value={newindicator.baseUnit} style={{ padding: 10, margin: 10 }} onChange={(e) => { updatenewindicatorUnit(e.target.value) }} type='text' placeholder="Unit" />

                                {submitted && newindicator.baseUnit.trim().length <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Unit  required
                                    </small>
                                )}
                            </div>
                            <div style={{ margin: 10 }}>
                                <text>Choose frequency </text>
                                <div className="flex flex-wrap gap-3" style={{ margin: 10 }}>
                                    <div className="flex align-items-center" style={{ marginRight: 10 }}>
                                        <RadioButton inputId="year" name="yearly" value="yearly" onChange={(e) => updatenewindicator('frequency', e.value)} checked={newindicator.frequency === 'yearly'} />
                                        <label htmlFor="year" className="ml-2">Yearly</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="month" name="monthly" value="monthly" onChange={(e) => updatenewindicator('frequency', e.value)} checked={newindicator.frequency === 'monthly'} />
                                        <label htmlFor="month" className="ml-2">Month & Year</label>
                                    </div>
                                </div>
                            </div>
                            <>
                                <div style={{ margin: 10 }}>
                                    <text>Baseline</text>
                                    <div className="col-12" style={{ padding: 20 }}>
                                        <div className="grid" style={{ alignItems: 'flex-end' }}>
                                            <div className="col-8 grid">

                                                <label style={{ margin: 10 }}>Month/Year</label>
                                                <Calendar maxDate={newindicator.targetDate} id="range" style={{ width: '70%' }} value={newindicator.baseDate} onChange={(e) => updatenewindicator('baseDate', e.value)} view={newindicator.frequency === 'yearly' ? 'year' : 'month'} dateFormat={newindicator.frequency === 'yearly' ? 'yy' : ' mm-yy'} />




                                            </div>
                                            <div className="col-4">
                                                <label style={{ margin: 10 }}>Value</label>
                                                <InputText style={{ width: '50%' }} keyfilter={'pnum'} value={newindicator.baseValue} onChange={(e) => { updatenewindicator('baseValue', e.target.value) }} />

                                            </div>
                                            {/* <div className="col-3">
                                            <label style={{ margin: 10 }}>Unit</label>
                                            <InputText style={{ width: '50%' }} value={newindicator.baseUnit} onChange={(e) => { updatenewindicator('baseUnit', e.target.value); updatenewindicator('targetUnit', e.target.value) }} />

                                        </div> */}

                                        </div>
                                        <div className="grid" style={{ alignItems: 'flex-end' }}>
                                            <div className="col-8 grid">

                                                {submitted && newindicator.baseDate === null && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Baseline Month/Year required
                                                    </small>
                                                )}

                                            </div>

                                            <div className="col-4">
                                                {submitted && newindicator.baseValue.trim().length <= 0 && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Baseline Value required
                                                    </small>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                            <>
                                <div style={{ margin: 10 }}>
                                    <text>Target</text>
                                    <div className="col-12" style={{ padding: 20 }}>
                                        <div className="grid" style={{ alignItems: 'flex-end' }}>
                                            <div className="col-8 grid">

                                                <label style={{ margin: 10 }}>Month/Year</label>
                                                <Calendar id="range" minDate={newindicator.baseDate} style={{ width: '70%' }} value={newindicator.targetDate} onChange={(e) => updatenewindicator('targetDate', e.value)} view={newindicator.frequency === 'yearly' ? 'year' : 'month'} dateFormat={newindicator.frequency === 'yearly' ? 'yy' : ' mm-yy'} />




                                            </div>
                                            <div className="col-4">
                                                <label style={{ margin: 10 }}>Value</label>
                                                <InputText style={{ width: '50%' }} keyfilter={'pnum'} value={newindicator.targetValue} onChange={(e) => updatenewindicator('targetValue', e.target.value)} />

                                            </div>
                                            {/* <div className="col-3">
                                            <label style={{ margin: 10 }}>Unit</label>
                                            <InputText style={{ width: '50%' }} value={newindicator.targetUnit} onChange={(e) => { updatenewindicator('targetUnit', e.target.value); updatenewindicator('baseUnit', e.target.value) }} />

                                        </div> */}

                                        </div>
                                        <div className="grid" style={{ alignItems: 'flex-end' }}>
                                            <div className="col-8 grid">

                                                {submitted && newindicator.targetDate === null && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Target Month/Year required
                                                    </small>
                                                )}

                                            </div>

                                            <div className="col-4">
                                                {submitted && newindicator.targetValue.trim().length <= 0 && (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Target Value required
                                                    </small>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                            <>
                                <div style={{ margin: 10 }}>
                                    <text>Interim Target</text>
                                    <div style={{ margin: 10 }}>
                                        <text>Choose Interim frequency </text>
                                        <div className="flex flex-wrap gap-3" style={{ margin: 10 }}>
                                            <div className="flex align-items-center" style={{ marginRight: 10 }}>
                                                <RadioButton inputId="year" name="yearly" value="yearly" disabled={newindicator.frequency === 'monthly'} onChange={(e) => updatenewindicator('interim_frequency', e.value)} checked={newindicator.interim_frequency === 'yearly'} />
                                                <label htmlFor="year" className="ml-2">Yearly</label>
                                            </div>
                                            <div className="flex align-items-center">
                                                <RadioButton inputId="month" name="monthly" value="monthly" onChange={(e) => updatenewindicator('interim_frequency', e.value)} checked={newindicator.interim_frequency === 'monthly'} />
                                                <label htmlFor="month" className="ml-2">Month & Year</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ padding: 10 }}>
                                        <div className="grid" style={{ alignItems: 'center', background: 'lightgrey', borderRadius: 10 }}>
                                            <div className="col-5" style={{ borderRight: '2px solid white' }}>

                                                <label style={{ margin: 10, color: 'white' }}>Month/Year <span>(X-Axis)</span></label>




                                            </div>
                                            <div className="col-3">
                                                <label style={{ margin: 10, color: 'white' }}>Value<span>(Y-Axis)</span></label>

                                            </div>
                                            <div className="col-3" style={{ borderRight: '2px solid white' }}>
                                                <label style={{ margin: 10, color: 'white' }}>Unit</label>

                                            </div>

                                        </div>
                                    </div>
                                    {newindicator.interim_target.map((target, index) => {
                                        return (
                                            <div className="col-12" style={{ padding: 5 }}>
                                                <div className="grid" style={{ alignItems: 'center' }}>
                                                    <div className="col-5" >


                                                        <Calendar minDate={newindicator.baseDate} maxDate={newindicator.targetDate} id="range" style={{ width: '100%' }} value={target.xaxis} onChange={(e) => { updateInterimTarget('xaxis', index, e.value); forceUpdate() }} view={newindicator.interim_frequency === 'yearly' ? 'year' : 'month'} dateFormat={newindicator.interim_frequency === 'yearly' ? 'yy' : ' mm-yy'} />




                                                    </div>

                                                    <div className="col-3">

                                                        <InputText style={{ width: '100%' }} value={target.yaxis} onChange={(e) => { updateInterimTarget('yaxis', index, e.target.value); forceUpdate() }} />

                                                    </div>
                                                    <div className="col-3" >

                                                        <InputText disabled style={{ width: '100%' }} value={newindicator.baseUnit} onChange={(e) => { updateInterimTarget('unit', index, e.target.value); forceUpdate() }} />

                                                    </div>
                                                    <div className="col-1">
                                                        {newindicator.interim_target.length - 1 === index ?
                                                            <div>
                                                                <i className="material-icons" onClick={(e) => { e.stopPropagation(); addNewRow() }} style={{ color: 'green' }}>add_box</i>
                                                                <i className="material-icons" onClick={() => { deleteRow(index) }}>delete</i>

                                                            </div> :
                                                            <div>
                                                                <i className="material-icons" onClick={() => { deleteRow(index) }}>delete</i>

                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                    }
                                </div>
                            </>
                        </>}
                        <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addnewindicator() }} />
                    </Dialog>
                    <Dialog

                        visible={targetdialog}
                        style={{
                            width: "75%",
                        }}
                        header={newtarget.id === undefined ? "Add Targets" : 'Edit Targets #' + newtarget.id}
                        modal
                        className="p-fluid"
                        focusOnShow
                        onShow={() => { forceUpdate() }}
                        onHide={() => { setTargetDialog(false); setNewTarget({ title: '', description: '', type: 0, comments: [], data1: [], created_on: moment().utc(), created_by: login_data.id }) }}
                    >
                        <div>
                            <div style={{ margin: 10 }}>
                                <text style={{ marginBottom: 10 }}> Target Title <span style={{ color: 'red' }}>*</span> </text>

                                <Editor className="target-options" value={newtarget.title} onTextChange={(e) => updatenewtarget('title', e.htmlValue)} style={{ height: '320px', padding: 10, margin: 10 }} />
                                {/* <InputText value={newtarget.title} style={{ padding: 10, margin: 10 }} onChange={(e) => { updatenewtarget('title', e.target.value) }} placeholder="Target title" /> */}

                                {submitted && newtarget.title.trim().length <= 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Target title required
                                    </small>
                                )}
                            </div>
                            <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addnewtarget() }} />

                        </div>
                    </Dialog>
                    <Dialog
                        ref={iclass}
                        visible={addStatusdialog}
                        style={{
                            width: "75%",
                        }}
                        header="Add/Update Status"
                        modal
                        className="p-fluid"
                        focusOnShow
                        onShow={() => { forceUpdate() }}
                        onHide={() => { setAddStatusdialog(false); setNewStatus({ text: '', responsibility: '', indicator: 3 }) }}
                    >
                        <div>
                            <label tyle={{ marginBottom: 10 }}>Status Text</label>
                            {/* <InputText value={newstatus.text} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateStatus(e, e.target.value, 'text') }} placeholder="Status" /> */}
                            <Editor value={newstatus.text} onTextChange={(e) => updateStatus(e, e.htmlValue, 'text')} style={{ height: '320px', padding: 10, margin: 10 }} />
                            {submitted && newstatus.text !== null && newstatus.text.trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: 'red',
                                        width: '100%',
                                        display: 'flex',
                                        padding: '5px'
                                    }}
                                >
                                    current status required
                                </small>
                            )}
                            <label>Overall Responsibility</label>
                            <InputText value={newstatus.responsibility} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateStatus(e, e.target.value, 'responsibility') }} placeholder="Responsibility" />
                            {submitted && newstatus.responsibility.trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: 'red',
                                        width: '100%',
                                        display: 'flex',
                                        padding: '5px'
                                    }}
                                >
                                    responsibility required
                                </small>
                            )}
                            <label>Status Indicator</label>
                            <Dropdown style={{ margin: 10, width: '100%' }} value={newstatus.indicator} options={indicator_list} optionLabel="name" optionValue="value"
                                onChange={(e) => { updateStatus(e, e.value, 'indicator'); forceUpdate() }} placeholder="Select a Status"
                            />
                            <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addNewStatus() }} />

                        </div>
                    </Dialog>
                    <Dialog
                        ref={iclass}
                        visible={addActiondialog}
                        style={{
                            width: "450px",
                        }}
                        header="Add Action"
                        modal
                        className="p-fluid"
                        focusOnShow
                        onHide={() => { setAddStatusdialog(false); setNewStatus({ text: '', responsibility: '', indicator: 3 }) }}
                    >
                        <div>

                            <InputText id='statinput' value={newaction} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateAction(e, e.target.value) }} placeholder="Action" />
                            <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addNewAction() }} />

                        </div>
                    </Dialog>

                    <Dialog

                        visible={boundarydialog}
                        style={{
                            width: "450px"
                        }}
                        footer={closeBopundaryDialog}
                        header="Applicability"
                        modal
                        className="p-fluid"
                        focusOnShow
                        onHide={() => { setBoundarydialog(false); setAppRowIndex(null) }}
                    >
                        <div style={{ marginBottom: 20, width: '100%' }}>
                            <label>{label1}</label>
                            <Dropdown disabled style={{ margin: 10, width: '100%' }} value={selectLoc.country} options={locList.country} optionLabel="name" optionValue="id"
                                onChange={(e) => { renderLocationDropdown({ country: e.value, city: 'All', location: 'All' }); forceUpdate() }} placeholder={"Select " + label1}
                            />
                            <label>{label2}</label>
                            <Dropdown disabled style={{ margin: 10, width: '100%' }} value={selectLoc.city} options={locList.city} optionLabel="name" optionValue="id"
                                onChange={(e) => { renderLocationDropdown({ country: selectLoc.country, city: e.value, location: 'All' }); forceUpdate() }} placeholder={"Select " + label2}
                            />
                            <label>{label3}</label>
                            <Dropdown disabled style={{ margin: 10, width: '100%' }} value={selectLoc.location} options={locList.location} optionLabel="name" optionValue="id"
                                onChange={(e) => { renderLocationDropdown({ country: selectLoc.country, city: selectLoc.city, location: e.value }); forceUpdate() }} placeholder={"Select " + label3}
                            />

                        </div>
                    </Dialog>
                    <Dialog

                        visible={historydialog}
                        style={{
                            width: "450px",
                        }}
                        footer={closeHistoryDialog}
                        header="Status History"
                        modal
                        className="p-fluid"
                        focusOnShow
                        onHide={() => { setHistoryDialog(false); }}
                    >
                        <div style={{ marginBottom: 20, width: '100%', heigth: 450, overflow: 'scroll' }}>

                            {historydata.map((item) => {
                                let bgcolor = ''
                                if (item[3] === 1) {
                                    bgcolor = 'red'
                                } else if (item[3] === 2) {
                                    bgcolor = "#FFBF00"
                                } else {
                                    bgcolor = 'green'
                                }
                                return (
                                    <div style={{ flexDirection: 'column', display: 'flex', padding: 5, borderRadius: 10, margin: 5, boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
                                        <div style={{ margin: 10 }} className='ql-editor' dangerouslySetInnerHTML={{ __html: item[1] }} />
                                        <text style={{ color: 'white', borderRadius: 10, padding: 5, fontStyle: 'italic', backgroundColor: bgcolor }}>As of {DateTime.fromISO(item[0], { zone: 'utc' }).toLocal().toFormat("yyyy LLL dd HH':'mm a")}</text>
                                        <text style={{ justifyContent: 'flex-end', display: 'flex', color: 'mediumvioletred', fontStyle: 'italic' }}><span style={{ color: 'gray' }}>Overall Responsibility : &nbsp;</span> {item[2]}</text>
                                    </div>
                                )
                            })

                            }

                        </div>

                    </Dialog>
                    <Dialog
                        visible={inactiondialog}
                        style={{
                            width: "60%", height: '80%'
                        }}
                        header={inaction.id === undefined ? "Add Action" : 'Edit Action #' + inaction.id}
                        modal
                        className="p-fluid"

                        onHide={() => { setInActionDialog(false) }}
                    >
                        <div style={{ margin: 10 }}>
                            <text> Action Title </text>

                            <InputText value={inaction.initiatives[0]} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateNewInAction('initiatives', e.target.value) }} placeholder="Action title" />

                            {submitted && inaction.initiatives[0].trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Action title required
                                </small>
                            )}
                        </div>
                        <div style={{ margin: 10 }}>
                            <text> Target Date  </text>
                            <Calendar style={{ padding: 10, width: 130 }} value={inaction.initiatives[2]} onChange={(e) => updateNewInAction('date', e.value)} />


                            {submitted && inaction.initiatives[2] === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Target date required
                                </small>
                            )}
                        </div>
                        <div style={{ margin: 10 }}>
                            <text> Status </text>

                            <InputText value={inaction.status[0][1]} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateNewInAction('status', e.target.value) }} placeholder="current status" />

                            {submitted && inaction.status[0][1].trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Status required
                                </small>
                            )}
                        </div>
                        <div style={{ margin: 10 }}>
                            <text>Status Indicator</text>
                            <Dropdown style={{ margin: 10, width: '100%' }} value={inaction.status[0][3]} options={indicator_list} optionLabel="name" optionValue="value"
                                onChange={(e) => { updateNewInAction('indicator', e.value); forceUpdate() }} placeholder="Select a Status"
                            />



                        </div>
                        <div style={{ margin: 10 }}>
                            <text> Responsibility </text>

                            <InputText value={inaction.responsibility} style={{ padding: 10, margin: 10 }} onChange={(e) => { updateNewInAction('responsibility', e.target.value) }} placeholder="responsibility" />

                            {submitted && inaction.responsibility.trim().length === 0 && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    responsibility required
                                </small>
                            )}
                        </div>
                        <div style={{ margin: 10, width: '100%' }}>
                            <fieldset style={{
                                padding: '30px',
                                borderRadius: '10px',
                                border: '1px solid #ced4da',
                                margin: '10px'
                            }}>
                                <legend>Applicability</legend>
                                <label>{label1}</label>
                                <Dropdown style={{ margin: 10, width: '100%' }} value={inaction.applicability.country} options={locList.country} optionLabel="name" optionValue="id"
                                    onChange={(e) => { updateNewInAction('applicability', { country: e.value, city: 'All', location: 'All' }); forceUpdate() }} placeholder={"Select " + label1}
                                />
                                <label>{label2}</label>
                                <Dropdown style={{ margin: 10, width: '100%' }} value={inaction.applicability.city} options={locList.city} optionLabel="name" optionValue="id"
                                    onChange={(e) => { updateNewInAction('applicability', { country: inaction.applicability.country, city: e.value, location: 'All' }); forceUpdate() }} placeholder={"Select " + label2}
                                />
                                <label>{label3}</label>
                                <Dropdown style={{ margin: 10, width: '100%' }} value={inaction.applicability.location} options={locList.location} optionLabel="name" optionValue="id"
                                    onChange={(e) => { updateNewInAction('applicability', { country: inaction.applicability.country, city: inaction.applicability.city, location: e.value }); forceUpdate() }} placeholder={"Select " + label3}
                                />
                            </fieldset>
                        </div>


                        <Button style={{ width: "auto", display: 'flex', marginTop: 20 }} label="Save & Exit" className="p-button-help" onClick={() => { addInAction() }} />
                    </Dialog>

                </div >
            ) : (
                <div className="col-12 card">You have no rights to access this page </div>
            )}
        </div >
    );
};

export default GTIA;
// 1//0g66oMGV-PK1uCgYIARAAGBASNwF-L9IrvTVmF8D1wmkLaVpr4XdAB6SauAa-iTaPtixksLtEVQFkjMDHVhUGTk3ugVxg3hqu8AM