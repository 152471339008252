import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import Axios from "axios";
import { API } from "../../constants/api_url";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import moment from "moment";
import chart, { Chart } from "chart.js/dist/chart";
import { registerables } from "chart.js";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import $, { data } from 'jquery'
import { Dropdown } from "primereact/dropdown";
import ChartDL from "../../assets/js/chartjsplugin";

import  * as chartTrendline from "chartjs-plugin-trendline";
import { ColorStyle } from "quill";
import { Checkbox } from 'primereact/checkbox';
import APIServices from "../../service/APIService";

Chart.register(chartTrendline);
Chart.register(...registerables);
const { DateTime } = require("luxon");
window.jQuery = $;
window.$ = $;
const google = window.google;
// ios_
const DashboardCC = (props) => {
    const [products, setProducts] = useState(null);
    const siteList = useSelector((state) => state.sitelist.siteList)
    const [irdcf, setIRByDCF] = useState([])
    const [expandedRows, setExpandedRows] = useState(null)
    const emissionFactor = useSelector((state) => state.emissionfactor.emissionFactor)
    const [dcfuserassignment, setDCFUserAssignment] = useState([])
    const [attdialog, setAttDialog] = useState(false)
    const [attachment, setAttachment] = useState([])
    const [countopt, setCountryOpt] = useState([])
    const [cityopt, setCityOpt] = useState([])
    const [locopt, setLocOpt] = useState([])
    const [cityopt_, setCityOpt_] = useState([])
    const [locopt_, setLocOpt_] = useState([])
    const [indiopt, setIndiOpt] = useState([])
    const [fwindicator, setFrameWorkIndicator] = useState([])
    const [indicators, setInidicators] = useState([])
    const [yearopt, setYearOpt] = useState([])
    const [gfilter, setGFilter] = useState({ a: 4, b: 4 })
    const [completeDCFAssigned, setCompleteDCFAssigned] = useState([])
    const [ytd, setYtd] = useState({ scope12: 0, scope3: 0, scope12_: 0, scope3_: 0 })
    const chartRefs = useRef([{ current: null }, { current: null }, { current: null }, { current: null }]);
    const colorsBlue = ["#1232C3", "#4E74E8", "#00AAF9", "#94D0F8", "#B9FCFC", "#0080ff", "#009fff", "#00bfff", "#00ffff", "#22a7f0", "#a7d5ed", "#63bff0"];
    const colorsBlueBR = ["#1232C3", "#4E74E8", "#00AAF9", "#94D0F8", "#B9FCFC", "#0060ff50", "#0080ff50", "#009fff50", "#00bfff50", "#00ffff50", "#22a7f050", "#a7d5ed50", "#63bff050"];
    const BGColors = [['#0282BA', '#00ABE6', '#8FCCF2'], ['#9c2e22', '#b44032', '#cc5343'], ['#bbaad7', '#c7b6e4', '#d4c2f1']]
    const BGColorsBlur = [['#0282BA80', '#00ABE680', '#8FCCF280'], ['#9c2e2280', '#b4403280', '#cc534380'], ['#bbaad790', '#c7b6e490', '#d4c2f190']]
    const [monthopt, setMonthOpt] = useState([{ name: 'All', id: 0 }, { name: 'Januaury', id: 1 }, { name: 'Febraury', id: 2 }, { name: 'March', id: 3 }, { name: 'April', id: 4 }, { name: 'May', id: 5 },
    { name: 'June', id: 6 }, { name: 'July', id: 7 }, { name: 'August', id: 8 }, { name: 'September', id: 9 }, { name: 'October', id: 10 }, { name: 'November', id: 11 }, { name: 'December', id: 12 }])
    const stackBar = ['#149CE0', '#94D0F8', '#B9FCFC']
    //ESG ['#72C3B2', '#ED5424', '#305874']
    const scope1Ids = [11, 93, 15, 10]
    const scope2Ids = [72]
    const scope3Ids = [16, 36]
    const [historydialog, setHistoryDialog] = useState(false)
    const [historydata, setHistoryData] = useState([])
    const [dcflist, setDcfList] = useState([])
    const [overallmetric, setOverallMetric] = useState([])
    const [frameworklist, setFrameworkList] = useState([])
    const [rfresponse, setRFResponse] = useState([])

    const [sframeworklist, setSFrameworkList] = useState([])
    const [sframeworklist2, setSFrameworkList2] = useState([])

    const [gta, setGTA] = useState([])
    const [gtaraw, setGTARaw] = useState([])
    const SDG_list = [
        { name: 'NO POVERTY', code: 1, url: require('../../assets/images/sdg_alignment/1.png').default },
        { name: 'ZERO HUNGER', code: 2, url: require('../../assets/images/sdg_alignment/2.png').default },
        { name: 'GOOD HEALTH AND WELL-BEING', code: 3, url: require('../../assets/images/sdg_alignment/3.png').default },
        { name: 'QUALITY EDUCATION', code: 4, url: require('../../assets/images/sdg_alignment/4.png').default },
        { name: 'GENDER EQUALITY', code: 5, url: require('../../assets/images/sdg_alignment/5.png').default },
        { name: 'CLEAN WATER AND SANITATION', code: 6, url: require('../../assets/images/sdg_alignment/6.png').default },
        { name: 'AFFORDABLE AND CLEAN ENERGY', code: 7, url: require('../../assets/images/sdg_alignment/7.png').default },
        { name: 'DECENT WORK AND ECONOMIC GROWTH', code: 8, url: require('../../assets/images/sdg_alignment/8.png').default },
        { name: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE', code: 9, url: require('../../assets/images/sdg_alignment/9.png').default },
        { name: 'REDUCED INEQUALITIES', code: 10, url: require('../../assets/images/sdg_alignment/10.png').default }, { name: 'SUSTAINABLE CITIES AND COMMUNITIES', code: 11, url: require('../../assets/images/sdg_alignment/11.png').default }, { name: 'RESPONSIBLE CONSUMPTION AND PRODUCTION', code: 12, url: require('../../assets/images/sdg_alignment/12.png').default }
        , { name: 'CLIMATE ACTION', code: 13, url: require('../../assets/images/sdg_alignment/13.png').default }, { name: 'LIFE BELOW WATER', code: 14, url: require('../../assets/images/sdg_alignment/14.png').default }, { name: 'LIFE ON LAND', code: 15, url: require('../../assets/images/sdg_alignment/15.png').default },
        { name: 'PEACE, JUSTICE AND STRONG INSTITUTIONS', code: 16, url: require('../../assets/images/sdg_alignment/16.png').default }, { name: 'PARTNERSHIPS FOR THE GOAL', code: 17, url: require('../../assets/images/sdg_alignment/17.png').default }
    ];
    const [past, setPast] = useState([])
    const [filter, setFilter] = useState({ country: { name: 'All', id: 0 }, city: { name: 'All', id: 0 }, location: { name: 'All', id: 0 }, indicator: { title: 'All', id: 0 }, year: { name: 'All', id: 0 }, month: { name: 'All', id: 0 }, from: null, to: null, timeline: [] })
    const dispatch = useDispatch()
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }]
    const locationList = useSelector(state => state.sitelist.locationList)
    const userList = useSelector(state => state.userlist.userList)
    const [category, setCategory] = useState([]);
    const [indicator, setIndicators] = useState([]);

    const [submitteddcf, setSubmittedDcf] = useState([])
    const [lineOptions, setLineOptions] = useState(null);
    const [apidata, setApiData] = useState([]);
    const [activity, setActivity] = useState({
        pending: [],
        approved: [],
    });
    const selector = useSelector((state) => state.user.userdetail);
    const [pendingFilter, setPendingFilter] = useState({
        month: null,
        scope: {
            name: "All",
        },
        topic: {
            name: "All",
        },
    });
    useEffect(() => {

        if (selector.id !== undefined) {

            let loc = filter
            loc.from = moment.utc(selector.information.startdate).local().toDate()
            loc.to = moment(moment.utc()).startOf('month').local().toDate()
            setFilter(loc)
            forceUpdate()
        }
    }, [selector])
    const [filterData, setFilterData] = useState({
        scope: [],
        topic: [],
    });
    const [rawtopic, setRawTopic] = useState([]);
    const [rawdata, setRawData] = useState({
        topic: [],
        scope: [],
    });
    const forceUpdate = useForceUpdate();

    const [data, setData] = useState([]);

    const dt = useRef(null);
    const [response, setResponse] = useState([])
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let months_ = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];



    useEffect(() => {


        let copt = [{ name: "All", id: 0 }], ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]
        siteList.forEach((country) => {
            copt.push(country)
            country.locationTwos.forEach((city) => {
                ccopt.push(city)
                city.locationThrees.forEach((loc, ind) => {

                    locopt.push(loc)

                })
            })
        })
        setCityOpt(ccopt)
        setCountryOpt(copt)
        setLocOpt(locopt)
        setCityOpt_(ccopt)
        setLocOpt_(locopt)
        let gtaString = {
            "include": ["newTargetsTwos", "newIndicatorTwos", "newInitiatives"]


        }
        let dcf_list = [], dcf_submitted = [],locloc=[]
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }
        const promise9 = APIServices.get(API.AssignDCFClient_UP(selector.id))
        const promise1 = APIServices.get( API.DCF)
        const promise2 = APIServices.get(API.DCF_Submit_UP(selector.id))
        const promise3 = APIServices.get(API.LocationThree)
        const promise4 = APIServices.get(API.AssignDCFUser_UP(selector.id))
        const promise6 = APIServices.get(API.RF_Submit_UP(selector.id))
        const promise5 = APIServices.get(API.Report_Name_Twos)
        const promise7 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(category_string))}`)
        const promise8 = APIServices.get(API.Goal_UP(selector.id) + `?filter=${encodeURIComponent(JSON.stringify(gtaString))}`)

        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9]).then(function (values) {
            dcf_list = values[0].data; setDcfList(values[0].data)
            dcf_submitted = values[1].data;
            let submit_group = groupArrayObject_3_Keys(dcf_submitted.filter((l) => { return [15, 72, 73, 16, 36, 11, 10].includes(l.dcf) && l.type === 3 }), 'dcf', 'site', 'frequencycd')
            setFrameworkList(values[4].data.filter((i) => { return selector.information.report.includes(i.id) }))
            setRFResponse(values[5].data)
            setDCFUserAssignment(values[3].data.filter((i) => { return i.type === 0 }))
            locloc = values[2].data
            setSubmittedDcf(dcf_submitted)
            let metrics = [], metrics_topics = []
            setIndicators(JSON.parse(JSON.stringify(values[6].data)).flatMap(item => item.newTopics && item.newTopics.flatMap(innerItem => innerItem.newMetrics && innerItem.newMetrics.map(subItem => subItem))).filter((i) => { return i !== undefined }))
            setCategory(values[6].data)
            values[6].data.forEach((cat) => {
                if (cat.newTopics) {
                    cat.newTopics.forEach((top) => {
                        if (top.newMetrics) {
                            metrics.push(...top.newMetrics)
                            top.newMetrics.forEach((met) => {
                                metrics_topics.push({ ...met, topic_title: top.title, topic_id: top.id })
                            })
                        }
                    })
                }
            })
            setOverallMetric(metrics_topics)
            let data = [], dcfAssignedList = []

            values[8].data.forEach((item) => {
                item.metric_ids.forEach((item2) => {

                    let index = metrics_topics.findIndex((k) => { return k.id === item2 })
                    if (index !== -1) {

                        data.push(metrics_topics[index])
                    }
                })


            })

            data.forEach((i) => {
                if (i.newDataPoints) {
                    i.newDataPoints.forEach((j) => {
                        if (Array.isArray(j.data1) && j.data1[0].datasource && typeof j.data1[0].datasource === 'number') {
                            let index = values[0].data.findIndex((k) => { return k.id === j.data1[0].datasource })
                            let index2 = dcfAssignedList.findIndex((l) => { return l.id === values[0].data[index].id })
                            if (index !== -1) {
                                if (index2 === -1) {
                                    dcfAssignedList.push(values[0].data[index])
                                }

                            }
                        }
                    })
                }
            })
            setIndiOpt(data)
            setInidicators(data)


            values[7].data.forEach((item, i) => {
                item.filter = {
                    "country": {
                        "name": "All",
                        "id": 0
                    },
                    "city": {
                        "name": "All",
                        "id": 0
                    },
                    "location": {
                        "name": "All",
                        "id": 0
                    }
                }


                item.selected = 0

            })

            setGTA(JSON.parse(JSON.stringify(values[7].data)))

            setGTARaw(JSON.parse(JSON.stringify(values[7].data)))

            metrics.forEach((i) => {

                if (i.newDataPoints) {
                    console.log(i.id)
                    i.newDataPoints.forEach((dp) => {
                        if (Array.isArray(dp.data1) && dp.data1[0].datasource !== undefined && typeof dp.data1[0].datasource === 'number') {
                            let index = dcfAssignedList.findIndex((k) => { return k.id === dp.data1[0].datasource })
                            if (index !== -1) {
                                if (dcfAssignedList[index]['indicator'] === undefined) {
                                    dcfAssignedList[index]['indicator_id'] = i.id
                                    dcfAssignedList[index]['indicator'] = []
                                    let show = Array.isArray(i.data1) && i.data1[0].tags1 !== undefined
                                    dcfAssignedList[index]['indicator'].push({ tags1: show ? i.data1[0].tags1 : [], tags2: show ? i.data1[0].tags2 : [], tags3: show ? i.data1[0].tags3 : [] })
                                }
                                // else{
                                //     if(!dcfAssignedList[index]['indicator'].includes(i.id)){
                                //         dcfAssignedList[index]['indicator'].push(i.id)
                                //     }

                                // }

                            }
                        }
                    })
                }
            })
            let completeDCFUserList = []
            let siteIds = siteList.flatMap(item => item.locationTwos.flatMap(innerItem => innerItem.locationThrees.map(subItem => subItem.id)))
            dcfAssignedList.forEach((i) => {
                console.log(i)
                siteIds.forEach((j) => {
                    let obj = i.indicator !== undefined ? { ...i.indicator[0] } : { tags1: [], tags2: [], tags3: [] }
                    let index = values[3].data.findIndex((k) => { return k.site[0] === j && k.dcfId === i.id })
                    if (index === -1) {
                        completeDCFUserList.push({ site: [j], indicator_id: i.indicator_id, dcfId: i.id, ...i.indicator[0] })
                    } else {

                        completeDCFUserList.push({ ...values[3].data[index], indicator_id: i.indicator_id, ...i.indicator[0] })
                    }
                })
            })
            let ass_status = []
            completeDCFUserList.forEach((assdcf) => {

                if (assdcf.id && assdcf.type === 0) {
                    let index = ass_status.findIndex((k) => { return k.dcfId === assdcf.dcfId })
                    let divBy = assdcf.frequency === 4 ? 12 : assdcf.frequency === 5 ? 6 : assdcf.frequency === 6 ? 1 : assdcf.frequency
                    let fixedVal = Math.floor(getTotalMonths(assdcf.start_date) / divBy)
                    if (submit_group[`${assdcf.dcfId}-${assdcf.site[0]}-${assdcf.frequency}`]) {


                        if (index === -1) {
                            ass_status.push({ dcfId: assdcf.dcfId, total: fixedVal, count: submit_group[`${assdcf.dcfId}-${assdcf.site[0]}-${assdcf.frequency}`].length, tags1: assdcf.tags1, tags2: assdcf.tags2, tags3: assdcf.tags3 })
                        } else {
                            ass_status[index].total = ass_status[index].total + fixedVal
                            ass_status[index].count = ass_status[index].count + (submit_group[`${assdcf.dcfId}-${assdcf.site[0]}-${assdcf.frequency}`].length)
                        }

                    } else {
                        console.log('defeated', `${assdcf.dcfId}-${assdcf.site[0]}-${assdcf.frequency}`, assdcf)
                        if (index === -1) {
                            ass_status.push({ dcfId: assdcf.dcfId, total: fixedVal, count: 0, tags1: assdcf.tags1, tags2: assdcf.tags2, tags3: assdcf.tags3 })
                        } else {
                            ass_status[index].total = ass_status[index].total + fixedVal
                            ass_status[index].count = ass_status[index].count + 0
                        }
                    }
                }
            })
            setIRByDCF(ass_status)
            setCompleteDCFAssigned(completeDCFUserList)

            forceUpdate()


        })
        setTimeout(() => {
            // if (selector.id === 17) {
            APIServices.get(API.DP_report_UP(selector.id)).then((dpres) => {
                let val = [], filterarr = groupArrayObject(dpres.data, 'submitId')
                Object.keys(filterarr).forEach((item) => {

                    val.push({ id: filterarr[item][0].submitId, rp: filterarr[item][0].rp, rp_: getRP_(filterarr[item][0].rp), year: filterarr[item][0].year, dcf: filterarr[item][0].dcfId, site: filterarr[item][0].site, response: Object.values(groupArrayObject(filterarr[item], 'form_id')) })

                })
                setResponse(val)

                let yearlist = [{ name: 'All', id: 0 }]
                dpres.data.forEach((report) => {
                    report.year.forEach((yr) => {
                        if (yearlist.findIndex((k) => { return k.name === yr }) === -1) {
                            yearlist.push({ name: yr })
                        }
                    })
                })
                setYearOpt(yearlist)
            })
            // }
        }, 2000)
    }, []);
    const getTotalMonths = (startDateStr) => {
        const startDate = DateTime.fromISO(startDateStr, { zone: "utc" });

        // Get the current date in UTC
        const currentDate = DateTime.utc();

        // Calculate the difference in months
        const monthCount = currentDate.diff(startDate.set({ day: 1 }), "months").months;

        return Math.floor(monthCount)
    }
    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    const getRP_ = (rp) => {
        if (rp.length === 1) {

            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2)
        } else {
            return months_[parseInt(rp[0].split('-')[0]) - 1] + "-" + rp[0].split('-')[1].slice(-2) + "to" + months_[parseInt(rp[rp.length - 1].split('-')[0]) - 1] + "-" + rp[rp.length - 1].split('-')[1].slice(-2)
        }
    }
    const getSum = (subset) => {
        let i = 0
        subset.forEach((item) => {
            i = i + item[1]
        })
        return i
    }
    const showPieChartNoDrill = (chartdata, id, custom, refid) => {

        let chartref = document.getElementById(id);
        let count = 1;
        if (Chart.getChart(id) !== undefined) {

            Chart.getChart(id).destroy();
        }

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + " tCO" + "\u2082" + "e";
        };
        let dummy = chartdata;
        let labelList = [];
        let index = custom.overall.findIndex((k) => { return k.ghg === custom.total && k.year === custom.name })

        // chartref.style.height = 
        chartdata.forEach((a) => {
            labelList.push([a.name]);
        });
        let scope1 = chartref.getContext("2d");
        const resetChart = {
            id: "resetchart",
            beforeDraw(chart, args, options) {

                const {
                    ctx,
                    chartArea: { top, left, right, bottom, width, height },
                } = chart;
                let total = 0
                if (chartRefs.current[refid].current !== null) {
                    let index = chartRefs.current[refid].current._metasets[0].data.filter((l) => { return (!l.hidden || l.hidden === undefined) })
                    // let total = index.reduce((a, b) => { return a['$context'].parsed + b['$context'].parsed })
                    index.forEach((l) => {

                        total = total + parseFloat(l['$context'].parsed)
                    })

                } else {
                    total = custom.total
                }
                ctx.font = '16px sans-serif';
                ctx.textAlign = 'center';
                ctx.fillStyle = 'black';
                const text = total.toFixed(2) + " tCO" + "\u2082" + "e"
                const textwidth = ctx.measureText(text).width;
                let text2 = custom.name
                if (custom.incomplete) {

                    ctx.fillText(text2, width / 2, bottom + 17)
                    ctx.fillStyle = 'red';
                    ctx.font = '12px sans-serif';
                    ctx.fillText('★', width / 2 + (text2.length * 6), bottom + 12)
                    ctx.font = '16px sans-serif';
                } else {
                    ctx.fillText(text2, width / 2, bottom + 17)
                }

                ctx.fillStyle = 'black';
                ctx.fillText(text, width / 2, 15)

                // ctx.save();
                // const text = custom.name;
                // const thickness = 3;
                // const textwidth = ctx.measureText(text).width;

                // ctx.fillStyle = "rgba(255,255,255,1)";
                // ctx.fillRect(right - (textwidth + 11), 5, textwidth + 10, 20);

                // ctx.fillStyle = "#666";
                // ctx.font = "16px Arial";
                // ctx.fillText(text, left + textwidth, 15);

                // ctx.lineWidth = thickness + "px";
                // // ctx.strokeStyle = "rgba(255,26,104,1)";
                // // ctx.strokeRect(right - (textwidth + 11), 5, textwidth + 10, 20);
                // coords.top = 5;
                // coords.left = right - (textwidth + 11);
                // coords.right = right;
                // coords.bottom = 25;

                // ctx.restore();

            },
        };
        const hiddenStrike = ['S̶c̶o̶p̶e̶ ̶1̶', 'S̶c̶o̶p̶e̶ ̶2̶', 'S̶c̶o̶p̶e̶ ̶3̶']
        const totalghg = {
            id: 'totalghg',
            afterDatasetDraw(chart, args, options) {

                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                ctx.save()
                let total = 0
                if (chartRefs.current[refid].current !== null) {
                    let index = chartRefs.current[refid].current._metasets[0].data.filter((l) => { return (!l.hidden || l.hidden === undefined) })
                    // let total = index.reduce((a, b) => { return a['$context'].parsed + b['$context'].parsed })
                    index.forEach((l) => {

                        total = total + parseInt(l['$context'].parsed)
                    })

                } else {
                    total = custom.total
                }
                ctx.font = '20px sans-serif';
                ctx.textAlign = 'center';
                ctx.fillStyle = 'black';
                ctx.fillText(total.toFixed(2) + " tCO" + "\u2082" + "e", width / 2 + (index === 2 ? 0 : index === 1 ? 15 : 30), top + (height / 2))

            }
        }

        chartRefs.current[refid].current = new Chart(scope1, {
            type: "pie",
            data: {
                //Bring in data
                labels: labelList,
                datasets: [
                    {
                        data: dummy,
                        label: custom.name,
                        backgroundColor: ['#149CE0', '#94D0F8', ' #B9FCFC'],
                        borderColor: ['#149CE0', '#94D0F8', ' #B9FCFC'],
                        borderWidth: 0.5,
                        weight: 1

                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                // Adjust the radius here to reduce the pie size
                radius: custom.radius + '%',
                // responsive: false,
                // maintainAspectRatio: false,
                // aspectRatio: 1,
                layout: {
                    padding: {
                        top: 20,
                        bottom: 20,

                    },
                },
                legend: {

                    display: true,
                    boxWidth: 10,
                },
                plugins: {
                    labels: {
                        render: (args) => {
                            let ad = 0,
                                count = 0;
                            args.dataset.data.forEach((a, i) => {
                                ad = ad + a.y;
                                count++;
                            });
                            let div = ad / 100;
                            return Math.round(args.value.y / div) + "%";
                        },
                    },
                    // afterInit: (chart) => {

                    //     // Add click event listener to the legend items
                    //     const legendItems = chart.legend.legendItems;
                    //     legendItems.forEach((item) => {
                    //         item.textEl.addEventListener('click', () => {
                    //             updateSize(chart)
                    //             const datasetIndex = item.index;
                    //             console.log(datasetIndex)
                    //             // Toggle the visibility of the clicked dataset
                    //             const meta = chart.getDatasetMeta(datasetIndex);
                    //             meta.hidden = meta.hidden === null ? !chart.data.datasets[datasetIndex].hidden : null;
                    //             chart.update();
                    //         });
                    //     });
                    // },
                    legend: {

                        // onClick: (e, legendItem, c) => {
                        //     let chart = chartRefs.current[refid].current
                        //     const dataIndex = legendItem.index;
                        //     const meta = e.chart.getDatasetMeta(0);
                        //     meta.data[dataIndex].hidden = !meta.data[dataIndex].hidden;
                        //     e.chart.update();
                        // },
                        onClick: (click, legendItem, legend) => {
                            const datasets = legend.legendItems.map((dataset, index) => { return dataset.text })

                            const index = datasets.indexOf(legendItem.text)
                            updatePieSize()
                            let chart = chartRefs.current[refid].current
                            const meta = chart.getDatasetMeta(0);
                            meta.data[index].hidden = !meta.data[index].hidden;
                            chart.update();

                        },
                        display: true,
                        position: "right",
                        labels: {
                            generateLabels: (chart) => {
                                let charts = chartRefs.current[refid].current
                                let hidden = []
                                if (charts !== null) {
                                    charts.getDatasetMeta(0).data.forEach((i, j) => {

                                        hidden.push(i.hidden)
                                    })
                                }

                                return chart.data.labels.map((dataset, index) => ({

                                    text: hidden.length === 0 ? dataset : hidden[index] ? hiddenStrike[index] : dataset,
                                    fillStyle: chart.data.datasets[0].backgroundColor[index],
                                    strokeStyle: chart.data.datasets[0].borderColor[index],
                                    fontColor: hidden.length === 0 ? 'black' : hidden[index] ? 'rgba(0, 0, 0, 0.2)' : 'black',

                                    boxWidth: 10,
                                    font: {

                                        size: 12,
                                        family: `"Roboto", sans-serif`,
                                        weight: "normal",
                                    }
                                }))

                            },
                            // color: "black",
                            boxWidth: 10,

                            // font: {
                            //     size: 12,
                            //     family: `"Roboto", sans-serif`,
                            //     weight: "normal",
                            // },
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },

                parsing: {
                    key: "y",
                },

            },
            plugins: [resetChart],
        })



    };
    const updatePieSize = () => {

        let overAll = [], indexArray = []
        setTimeout(() => {
            chartRefs.current.forEach((i, refid) => {
                if (i.current !== null) {
                    let total = 0

                    let index = i.current._metasets[0].data.filter((l) => { return (!l.hidden || l.hidden === undefined) })
                    // let total = index.reduce((a, b) => { return a['$context'].parsed + b['$context'].parsed })
                    index.forEach((l) => {

                        total = total + parseFloat(l['$context'].parsed)
                    })
                    overAll.push(total)
                    indexArray.push(refid)

                }

            })
            let maxValue = Math.max.apply(Math, overAll), count = 0

            indexArray.forEach((refid) => {

                chartRefs.current[refid].current.config._config.options.radius = ((overAll[count] / maxValue) * 100) + '%'
                chartRefs.current[refid].current.update()
                count += 1

            })


        }, 1000)


    }
    const showBarChartWithDrill = (chartdata, id, custom, colorind) => {

        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = JSON.parse(JSON.stringify(dummy)).map((k, g) => {

            return {
                data: k,
                incomplete: k.length === 0 ? true : k[0].incomplete,
                label: custom.label[g],
                borderColor: colorsBlue[g],
                backgroundColor: colorsBlueBR[g],
                barThickness: 50,
                borderRadius: 5,

            }
        })

        let datasets_ = JSON.parse(JSON.stringify(datasets))
        const resetChart = {
            id: "resetchart",
            beforeDraw(chart, args, options) {
                const {
                    ctx,
                    chartArea: { top, left, right, bottom, width },
                } = chart;

                // ctx.font = '16px sans-serif';
                // ctx.textAlign = 'center';
                // ctx.fillStyle = 'red';



                // ctx.fillText('★ - Incomplete Data', left + 80, 15)
                if (scope1c.config.data.datasets[0].label !== custom.label[0]) {
                    count = 0;

                    ctx.save();
                    const text = "↩ Back";
                    const thickness = 3;
                    const textwidth = ctx.measureText(text).width;


                    ctx.fillStyle = "rgba(55, 172, 232, 0.24)";
                    ctx.fillRect(right - (textwidth + 11), 5, textwidth + 10, 20, 20, [50, 0, 25, 0]);

                    ctx.fillStyle = "#666";
                    ctx.font = "12px Arial";

                    ctx.fillText(text, right - (textwidth + 6), 15);

                    ctx.lineWidth = thickness + "px";
                    ctx.strokeStyle = "rgba(55, 172, 232, 1)";
                    ctx.strokeRect(right - (textwidth + 11), 5, textwidth + 10, 20, [50, 0, 25, 0]);
                    // let img = require('../../assets/images/eisqr_logo.png').
                    // ctx.drawImage(img, 10, 10);
                    coords.top = 5;
                    coords.left = right - (textwidth + 11);
                    coords.right = right;
                    coords.bottom = 25;

                    ctx.restore();
                }
            }
        };

        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()
                if (scope1c.config.data.datasets[0].label === custom.label[0]) {

                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {
                            ds.data.forEach((j, indx) => {

                                if (scope1c.config.data.datasets[ind].incomplete) {
                                    ctx.fillStyle = "red";
                                    ctx.font = "12px Arial";
                                    ctx.fillText('★', chart.getDatasetMeta(ind).data[indx].x - (12 / 2), chart.getDatasetMeta(ind).data[indx].y - 6)
                                    // ctx.drawImage(img, chart.getDatasetMeta(ind).data[indx].x - (16 / 2), chart.getDatasetMeta(ind).data[indx].y - 12, 13, 10)
                                }


                            })

                        }
                    })
                }



            }
        }
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                //Bring in data

                datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 30
                    }
                },
                categoryPercentage: 0.8,
                onHover: (event, charElement) => {
                    if (scope1c.config.data.datasets[0].label === custom.label[0]) {

                        event.native.target.style.cursor = charElement[0] ? "pointer" : "default";

                    } else {
                        event.native.target.style.cursor = "default";
                    }
                },
                scales: {
                    y: {
                        stacked: false,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: false
                        },
                        barPercentage: 0.4
                    },
                    x: {
                        stacked: false,
                        grid: {
                            drawOnChartArea: false
                        }
                    }
                },
                legend: {
                    display: false,
                },
                parsing: {
                    xAxisKey: "name",
                    yAxisKey: "y",
                },
                plugins: {
                    legend: {

                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [resetChart, incomplete, ChartDL],
        });

        function clickHandler(click) {
            if (scope1c.config.data.datasets[0].label === custom.label[0]) {
                // if (scope1c.config.data.datasets[0].label === custom.name) {
                const bar = scope1c.getElementsAtEventForMode(
                    click,
                    "nearest",
                    {
                        intersect: true,
                    },
                    true
                );

                if (bar.length) {

                    if (datasets_[bar[0].datasetIndex].data[bar[0].index].drilldown) {
                        changeChart(bar[0].datasetIndex, bar[0].index);

                    }
                }
            }
        }

        function changeChart(ind, ind2) {

            scope1c.config.options.parsing.xAxisKey = "subset.x";
            scope1c.config.options.parsing.yAxisKey = "subset.xy";
            const users = [];
            let data_ = JSON.parse(JSON.stringify(datasets_))
            const vlabels = data_[ind].data[ind2].subset.map((labels) => {
                users.push(labels[1]);

                return labels[0];
            });


            scope1c.config.data.datasets = scope1c.config.data.datasets.slice(0, 1)
            scope1c.config.data.datasets[0].data = users;
            scope1c.config.data.labels = vlabels;
            scope1c.config.options.plugins.legend.display = true;
            scope1c.config.data.datasets[0].backgroundColor = colorsBlue[ind]
            scope1c.config.data.datasets[0].label = data_[ind].data[ind2].name;
            scope1c.update();

        }

        function mouseMoveHandler(canvas, mousemove) {
            const x = mousemove.offsetX;
            const y = mousemove.offsetY;
            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                if (scope1c.config.data.datasets[0].label !== custom.label[0]) {
                    canvas.style.cursor = "pointer";
                } else {
                    canvas.style.cursor = "default";
                }
            } else {
                canvas.style.cursor = "default";
            }
        }

        function handleBackClick() {
            scope1c.config.options.parsing.xAxisKey = "name";
            scope1c.config.options.parsing.yAxisKey = "y";
            const users = [];
            const vlabels = datasets_.forEach((labels) => {
                labels.data.forEach((k) => {
                    if (!users.includes(k.name)) {
                        users.push(k.name)
                    }
                })

            });

            scope1c.config.options.plugins.legend.display = true;
            scope1c.config.data.datasets = JSON.parse(JSON.stringify(datasets_));
            scope1c.config.data.labels = users;

            //  scope1c.config.data.datasets[0].label = custom.name;

            scope1c.update();

        }

        function mouseClickHandler(click) {
            const x = click.offsetX;
            const y = click.offsetY;

            if (x > coords.left && y < coords.right && y > coords.top && y < coords.bottom) {
                handleBackClick();
            }
        }

        chartref.addEventListener("click", (e) => {
            if (count === 0) {
                count = 1;
                mouseClickHandler(e);
            }
        });
        chartref.addEventListener("mousemove", (e) => mouseMoveHandler(chartref, e));
        chartref.onclick = clickHandler;

    }
    const showLineChartWithNoDrill = (chartdata, id, custom, colorind) => {

        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star_.png').default

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");



        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()


                scope1c.config.data.datasets.forEach((ds, ind) => {

                    if (scope1c.isDatasetVisible(ind)) {
                        ds.data.forEach((j, indx) => {
                            if (j.incomplete) {

                                ctx.fillStyle = "red";
                                ctx.font = "12px Arial";
                                ctx.fillText('★', chart.getDatasetMeta(ind).data[indx].x - (12 / 2), chart.getDatasetMeta(ind).data[indx].y - 4)
                                // ctx.drawImage(img, chart.getDatasetMeta(ind).data[indx].x - (16 / 2), chart.getDatasetMeta(ind).data[indx].y - 12, 13, 10)


                            }



                        })

                    }
                })




            }
        }
        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        // Function to calculate linear regression for trendline
        function calculateLinearRegression(data) {
            const n = data.length;
            let sumX = 0;
            let sumY = 0;
            let sumXY = 0;
            let sumXX = 0;

            for (let i = 0; i < n; i++) {
                sumX += i;
                sumY += data[i];
                sumXY += i * data[i];
                sumXX += i * i;
            }

            const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
            const intercept = (sumY - slope * sumX) / n;

            return { slope, intercept };
        }

        // Add trendline for the dataset
        const trendlineData = calculateLinearRegression(JSON.parse(JSON.stringify(chartdata)).map((k) => { return k.y }));
        console.log(trendlineData)
        let scope1c = new Chart(scope1, {
            type: "line",
            data: {
                //Bring in data

                datasets: [
                    {
                        data: chartdata,
                        label: custom.name,
                        backgroundColor: [custom.monthCount === 0 ? stackBar[colorind] : 'gray'],

                        borderColor: [custom.monthCount === 0 ? stackBar[colorind] : 'gray'],
                        pointStyle: function (context) {

                            const index = context.dataIndex; // Get the index of the current data point
                            // Define an array of custom point styles
                            const pointStyles = ["circle", "triangle", "rect", "rectRounded"];
                            // Return a different point style based on the index
                            return context.raw.incomplete ? img : pointStyles[0];
                        },
                        pointRadius: custom.monthCount === 0 ? 3 : 0,
                        trendlineLinear: {
                            color: "gray",

                            lineStyle: "dotted",
                            width: 2,
                            projection: false
                        }
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.2,
                        borderCapStyle: 'round',
                        borderJoinStyle: 'round',

                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },
                categoryPercentage: 0.8,
                onHover: (event, charElement) => {
                    if (scope1c.config.data.datasets[0].label === custom.label[0]) {

                        event.native.target.style.cursor = charElement[0] ? "pointer" : "default";

                    } else {
                        event.native.target.style.cursor = "default";
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        stacked: false,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: false
                        },
                        barPercentage: 0.4
                    },
                    x: {
                        stacked: false,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            align: 'center'
                        }
                    }
                },
                legend: {
                    display: false,
                },
                parsing: {
                    xAxisKey: "name",
                    yAxisKey: "y",
                },
                plugins: {

                    legend: {
                        onClick() { },
                        display: custom.monthCount === 0 ? true : false,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: 'percentage',
                        fontColor: ({ dataset, dataIndex }) => {

                            const backgroundColors = dataset.backgroundColor;
                            return backgroundColors[dataIndex];
                        },
                        precision: 0
                    },
                    tooltip: {
                        yAlign: "bottom",
                        displayColor: false,
                        callbacks: {
                            title: toolTipTitleRender,
                            label: toolTipLabelRender,
                        },
                    },
                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [ChartDL],
        });




    }
    const showStackedBarChartWithoutDrill = (chartdata, id, custom, colorind) => {



        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = []
        if (colorind === 0) {
            datasets = JSON.parse(JSON.stringify(dummy)).map((k, g) => {
                let filter_data = dummy.filter((k) => { return k[0].label === custom.label[g] })[0]

                return {
                    actual: filter_data,
                    data: filter_data.map((l) => { return l.y }),
                    label: custom.label[g],

                    backgroundColor: stackBar[g],
                    barThickness: 20,
                    borderRadius: 5,

                }
            })
        } else {
            datasets = chartdata
        }

        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()
                if (colorind === 0 && scope1c.config.data.datasets[0].label === custom.label[0]) {

                    let highestArray = []
                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {

                            ds.data.forEach((j, indx) => {

                                let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                                let y = chart.getDatasetMeta(ind).data[indx].y - 6

                                let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                                if (hindex === -1) {
                                    highestArray.push({ label: indx, array: [{ x, y }] })
                                } else {
                                    highestArray[hindex].array.push({ x, y })
                                }
                                //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                            })

                        }
                    })
                    highestArray.forEach((item, ind) => {

                        let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]
                        let incomplete = getInCompleteStatus(groupArrayObject_3_Keys(custom.actual.filter((k) => { return k.year === custom.labels[ind].split('-')[1] }), 'site', 'dcf', 'month'), [moment(custom.labels[ind], 'MMM-YYYY').format('MM-YYYY')], [...scope1Ids, ...scope2Ids, ...scope3Ids], 1)

                        if (incomplete && colorind === 0) {
                            // ctx.drawImage(img, filtered_y.x, filtered_y.y, 13, 10)
                            ctx.fillStyle = "red";
                            ctx.font = "12px Arial";
                            ctx.fillText('★', filtered_y.x, filtered_y.y)

                        }


                    })
                }



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                //Bring in data

                datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: false
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            align: 'center'
                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: colorind === 0,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [incomplete, ChartDL],
        });

    }
    const getRestMonthsOfGivenYear = (y1, y2) => {
        let year1 = y1
        let year2 = y2
        if (moment.utc(year1, 'YYYY').local().year() > moment.utc(year2, 'YYYY').local().year()) {
            year1 = y2
            year2 = y1
        }
        let startDate = moment.utc(year1, 'YYYY').local()
        let betweenMonths = []
        let endDate = null
        if (year2 === null) {
            endDate = moment.utc(year1, 'YYYY').local()
            while (startDate.startOf('month') <= endDate.endOf('year')) {


                betweenMonths.push(startDate.format('MMM-YYYY'));

                startDate.add(1, 'month');

            }
        } else {
            endDate = moment.utc(year2, 'YYYY').local()
            while (startDate.startOf('month') <= endDate.endOf('year')) {


                betweenMonths.push(startDate.format('MMM-YYYY'));

                startDate.add(1, 'month');

            }
        }



        return betweenMonths
    }
    const showStackedBarInGroupChartWithoutDrill = (chartdata, id, custom, colorind) => {

        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);

        let count = 1;
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        const img = new Image()
        img.src = require('../../assets/images/incomplete_star.png').default
        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
        let dummy = chartdata;
        let scope1 = chartref.getContext("2d");
        let datasets = JSON.parse(JSON.stringify(dummy)).map((k, g) => {
            let filter_data = dummy.filter((k) => { return k[0].label === custom.label[g] })[0]

            return {
                actual: filter_data,
                data: dummy,
                label: custom.label[g],

                backgroundColor: stackBar[g],
                barThickness: 20,
                borderRadius: 5,

            }
        })

        let datasets_ = JSON.parse(JSON.stringify(datasets))

        const toolTipTitleRender = (items) => {
            return "";
        };
        const toolTipLabelRender = (items) => {
            return items.label + " - " + items.formattedValue + custom.measure;
        };
        const incomplete = {
            id: 'incomplete',
            afterDatasetDraw(chart, args, options) {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;

                ctx.save()
                if (scope1c.config.data.datasets[0].label === custom.label[0]) {

                    let highestArray = []
                    scope1c.config.data.datasets.forEach((ds, ind) => {
                        if (scope1c.isDatasetVisible(ind)) {

                            ds.data.forEach((j, indx) => {

                                let x = chart.getDatasetMeta(ind).data[indx].x - (12 / 2)
                                let y = chart.getDatasetMeta(ind).data[indx].y - 6

                                let hindex = highestArray.findIndex((hi) => { return hi.label === indx })
                                if (hindex === -1) {
                                    highestArray.push({ label: indx, array: [{ x, y }] })
                                } else {
                                    highestArray[hindex].array.push({ x, y })
                                }
                                //    ctx.fillText(indx, chart.getDatasetMeta(ind).data[indx].x - (30 / 2), chart.getDatasetMeta(ind).data[indx].y - 25, 35)



                            })

                        }
                    })
                    highestArray.forEach((item, ind) => {

                        let filtered_y = item.array.sort((a, b) => { return a.y - b.y })[0]
                        let incomplete = getInCompleteStatus(groupArrayObject_3_Keys(custom.actual.filter((k) => { return k.year === custom.labels[ind].split('-')[1] }), 'site', 'dcf', 'month'), [moment(custom.labels[ind], 'MMM-YYYY').format('MM-YYYY')], [...scope1Ids, ...scope2Ids, ...scope3Ids], 1)

                        if (incomplete) {
                            // ctx.drawImage(img, filtered_y.x, filtered_y.y, 13, 10)
                            ctx.fillStyle = "red";
                            ctx.font = "12px Arial";
                            ctx.fillText('★', filtered_y.x, filtered_y.y)

                        }


                    })
                }



            }
        }
        let scope1c = new Chart(scope1, {
            type: "bar",
            data: {
                labels: custom.labels,
                //Bring in data

                datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.5,
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                layout: {
                    padding: {
                        top: 5
                    }
                },

                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: custom.measure,
                        },
                        type: 'linear',
                        // scaleLabel: {display:true,labelString:'Something'},
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value;
                            },
                        }, grid: {
                            drawOnChartArea: false
                        },

                    },
                    x: {

                        stacked: true,
                        grid: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            align: 'center'
                        }
                    }
                },
                legend: {
                    display: false,
                },

                plugins: {
                    legend: {

                        display: colorind === 0,
                        position: 'bottom',
                        labels: {
                            color: "black",
                            boxWidth: 10,
                            font: {
                                size: 16,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },
                    labels: {
                        render: (args) => {
                            return "";
                        },
                    },

                },
                animation: {
                    x: {
                        duration: 2500,
                        from: 0
                    },
                    y: {
                        duration: 1000,
                        from: 500
                    }
                },

            },
            plugins: [incomplete, ChartDL],
        });

    }
    const showTrendLineChart = (id, title, dataset, format) => {
        let ticks = JSON.parse(JSON.stringify(dataset)).map((i, j) => { return moment(i[0]).toDate() })
        let color = title === 'Scope 1' ? stackBar[0] : title === 'Scope 2' ? stackBar[1] : stackBar[2]

        google.charts.load('visualization', "1", { 'packages': ['corechart'] });
        google.charts.setOnLoadCallback(drawChart);
        $(window).resize(function () {
            drawChart();
        });
        function drawChart() {
            var data = new google.visualization.arrayToDataTable(dataset)


            var options = {
                title,
                width: '100%',
                height: 'auto',
                fontSize: 12,
                hAxis: {
                    format,
                    ticks: ticks.filter((i, j) => { return j !== 0 }),
                    slantedText: true,
                    slantedTextAngle: 90,
                    textStyle: {
                        color: "#495057", // Set the color of the horizontal axis
                    },
                },
                chartArea: { width: "95%", right: 5, },
                legend: { position: "none" },
                vAxis: {
                    title: " tCO" + "\u2082" + "e",
                    // scaleType: 'log',
                    gridlines: { color: 'none' },
                    minValue: 0
                }, pointShape: 'custom',
                pointSize: 4, dataOpacity: 0.7,
                series: {
                    0: { color: color }
                },
                animation: {
                    duration: 1000, // Animation duration in milliseconds
                    easing: 'out', // Animation easing function
                },

            };

            var chart = new google.visualization.LineChart(document.getElementById(id));


            chart.draw(data, options);

            forceUpdate()
        }

    }
    const showNoDataFoundGoogleChart = (id, txt) => {
        google.charts.load('current', { 'packages': ['table'] });
        google.charts.setOnLoadCallback(drawTable);

        function drawTable() {
            var data = new google.visualization.DataTable();
            data.addColumn('string', '');

            data.addRows([
                [txt],

                // Add more rows and text as needed
            ]);

            var table = new google.visualization.Table(document.getElementById(id));
            table.draw(data, { showRowNumber: false });
        }

    }
    const freezeFilter = () => {
        let stickyElem = document.querySelector(".sticky-div");
        if (stickyElem) {
            let currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
            window.onscroll = function () {
                /* Check if the current Y offset
                is greater than the position of
                the element */
                if (window.pageYOffset > currStickyPos) {
                    stickyElem.style.position = "fixed";
                    stickyElem.style.top = "58px";

                    stickyElem.style.width =
                        document.getElementById("divsize").clientWidth + "px";

                    stickyElem.style.marginLeft = '-28px'
                    stickyElem.style.zIndex = 999;
                } else {
                    stickyElem.style.position = "relative";
                    stickyElem.style.top = "initial";
                    stickyElem.style.marginLeft = 0
                    stickyElem.style.width = "100%";
                    stickyElem.style.background = "transparent";
                    stickyElem.style.zIndex = 1;
                }
            };
        }
    };
    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };




    const groupArrayObject = (array, obj) => {
        return array.reduce((group, arr) => {

            let key = arr[obj];

            group[key] = group[key] ?? [];

            group[key].push(arr);

            return group;

        },

            {})
    }
    const groupArrayObject_3_Keys = (array, obj1, obj2, obj3) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];
            let key3 = arr[obj3]

            const key = `${key1}-${key2}-${key3}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {})
    }
    const groupArrayObject_2_Keys = (array, obj1, obj2) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];


            const key = `${key1}-${key2}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {})
    }
    const groupArrayObject_4_Keys = (array, obj1, obj2, obj3, obj4) => {
        return array.reduce((result, arr) => {
            let key1 = arr[obj1];
            let key2 = arr[obj2];
            let key3 = arr[obj3]
            let key4 = arr[obj4]
            const key = `${key1}-${key2}-${key3}-${key4}`;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(arr);

            return result;
        }, {})
    }
    const getInCompleteStatus = (data, months, type, type2) => {
        let dcfId = []

        Object.keys(data).forEach((key) => {

            if (!dcfId.includes(parseInt(key.split('-')[1]))) {
                dcfId.push(parseInt(key.split('-')[1]))
            }
        })

        let filterAssignment = type !== undefined ? JSON.parse(JSON.stringify(dcfuserassignment.filter((l) => { return type.includes(l.dcfId) }))) : dcfuserassignment.filter((l) => { return dcfId.includes(l.dcfId) })
        let required = []

        filterAssignment.forEach((ass) => {
            required.push(...getRequiredMonthList(months[0], months[months.length - 1], ass.start_date, ass.dcfId, ass.site[0]))
        })

        if (Object.keys(data).length === required.length) {
            if (Object.keys(data).length === 0) {

                return true
            } else {
                let result = Object.keys(data).every(key => required.includes(key));

                return !result
            }
        } else {
            return true

        }

    }

    const getRequiredMonthList = (startfrom, enddate, startdate, dcf, site) => {

        let endDate = moment.utc(enddate, 'MM-YYYY').local()
        let startDate = moment.utc(startdate).local()
        let betweenMonths = []
        while (startDate.startOf('month') <= endDate.startOf('month')) {

            if (moment(startfrom, 'MM-YYYY').diff(startDate, 'M') <= 0) {
                betweenMonths.push(site + '-' + dcf + '-' + startDate.format('MMM-YYYY'));
            }
            startDate.add(1, 'month');

        }
        return betweenMonths

    }
    const getMonths = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], present_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))
        present_ = []
        future_ = []


        if (startDate.diff(endDate.startOf('month'), 'days') === 0) {
            let endDate_ = startDate

            let endDate__ = moment(moment.utc())
            if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

                if (check.result) {


                    past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: endDate__.diff(endDate__, 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                } else {
                    if (check.data) {

                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })
                    } else {
                        past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                    }

                }


            }

        }
        else if (startDate.diff(endDate.startOf('month'), 'days') < 31) {
            var date = startDate

            // let endDate__ = moment('01.03.2023', 'DD.MM.YYYY')
            let endDate__ = moment(moment.utc())
            while (date <= endDate__) {

                betweenMonths.push(date.format('MMM-YYYY'));
                date.add(1, 'month');


            }
            if (frequency === 1) {

                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month() === moment(months[0]).month() && endDate__.year() === moment(months[0]).year()) {



                    } else {
                        if (endDate__.diff(moment(months[0]), 'months') === 1) {

                            if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                                if (check.result) {


                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                            if (check.result) {


                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                } else {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month() === moment(months[1]).month() && endDate__.year() === moment(months[1]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[1]), 'months') === 1) {

                                if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {


                                } else {
                                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                                    if (check.result) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        if (check.data) {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                        } else {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                        }

                                    }
                                }
                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })

                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month() === moment(months[2]).month() && endDate__.year() === moment(months[2]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[2]), 'months') === 1) {

                                if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {



                                } else {
                                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                                    if (check.result) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    } else {
                                        if (check.data) {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                        } else {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                        }

                                    }

                                }
                            } else {

                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                                if (check.result) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month() === moment(months[11]).month() && endDate__.year() === moment(months[11]).year()) {



                        } else {
                            if (endDate__.diff(moment(months[11]), 'months') === 1) {

                                if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {



                                } else {
                                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                                    if (check.result) {

                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        if (check.data) {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                        } else {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                        }

                                    }

                                }
                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                                if (check.result) {

                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })

                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month() === moment(months[5]).month() && endDate__.year() === moment(months[5]).year()) {


                        } else {
                            if (endDate__.diff(moment(months[5]), 'months') === 1) {

                                if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {



                                } else {
                                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                    if (check.result) {


                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                    } else {
                                        if (check.data) {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                        } else {
                                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                        }

                                    }
                                }
                            } else {
                                let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                if (check.result) {


                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data, user: item.user_id, approver: item.approver_id })
                                } else {
                                    if (check.data) {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                                    } else {
                                        past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    if (moment(months[0]).month() === endDate__.month() && moment(months[0]).year() === endDate__.year()) {



                    } else {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, response: list.response, id: list.id, user: item.user_id, approver: item.approver_id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id })

                            } else {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: selector.id, frequency, user: item.user_id, approver: item.approver_id, submitted: true })

                            }

                        }


                    }

                })
            }

        }

        setPast((prev) => ([...prev, ...past_]))




        return { type, months: betweenMonths }
    }
    const checkSubmission = (dcf, site, rp, old) => {
        let rps = getRP(rp)
        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 1
        })
        if (index === -1 && index2 === -1) {

            result = { result: false, data: true }
        } else {
            if (index !== -1) {
                result = {
                    result: true, data: loc[index], list: loc.filter((i) => {
                        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 0
                    })
                }
            } else {
                result = {
                    result: true, data: loc[index2], list: loc.filter((i) => {
                        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && i.type === 1
                    })
                }
            }
        }

        return result
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((scope12_result_array_fg, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!scope12_result_array_fg[chunkIndex]) {
                scope12_result_array_fg[chunkIndex] = [] // start a new chunk
            }

            scope12_result_array_fg[chunkIndex].push(item)

            return scope12_result_array_fg
        }, [])
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
    //dummy
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span
                className="block mt-2 md:mt-0 p-input-icon-left"
                style={{
                    width: "40%",
                }}
            >
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setFilter(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </span>
        </div>
    );
    const yeartemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Year </span> {rowData.year}
            </>
        );
    };
    const monthtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.month}
            </>
        );
    };
    const countrytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.country}
            </>
        );
    };
    const citytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.city}
            </>
        );
    };
    const locationtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Month </span> {rowData.location}
            </>
        );
    };
    const approvedtemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Approved </span> {rowData.approved_by}
            </>
        );
    };
    const remindertemplate = (rowData) => {
        let res = { msg: 'Email not sent' }
        let userIndex = userList.findIndex((i) => { return i.id === rowData.user })
        let approverIndex = userList.findIndex((i) => { return i.id === rowData.approver })
        if (rowData.draft === undefined) {

            if (userIndex !== -1) {
                res = { msg: 'Reminder mail sent to ' + userList[userIndex].email, mail: userList[userIndex].email, supemail: userList[userIndex].information.empdesgination }
            }

        } else {
            rowData.draft.type === 1 ? res = { msg: 'Reminder mail sent to ' + userList[userIndex].email, mail: userList[userIndex].email, supemail: userList[userIndex].information.empdesgination } : res = { msg: 'Reminder mail sent to ' + userList[approverIndex].email, mail: userList[approverIndex].email, supemail: userList[userIndex].information.empdesgination }
        }
        return (
            <>
                <span className="p-column-title"> Approved </span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <label
                        style={{
                            background: 'cadetblue',
                            padding: '5px',
                            color: 'white',
                            width: '100px',
                            display: 'flex',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            alert(res.msg);
                        }}
                    >

                        Reminder
                    </label>
                    <label
                        style={{
                            background: "indianred",

                            padding: '5px',
                            color: 'white',
                            width: '100px',
                            display: 'flex',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            alert("Remainder Mail send to " + res.supemail);
                        }}
                    >

                        Escalate
                    </label>
                </div>
            </>
        );
    };
    //Dummy end

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    };
    const updatePendingFilter = (obj, val) => {
        let loc = pendingFilter;
        loc[obj] = val;
        if (obj === "scope") {
            if (val.name !== "All") {
                let loc2 = filterData;
                loc["topic"] = {
                    name: "All",
                };

                let ind = rawtopic.findIndex((i) => {
                    return Object.keys(i)[0] === val.name;
                });

                loc2.topic = rawtopic[ind][val.name];
                setFilterData(loc2);
            } else {
                let loc2 = filterData;

                loc2.topic = [
                    {
                        name: "All",
                    },
                ];
                setFilterData(loc2);
            }
        }
        if (obj === "scope") {
            renderFilterValue(pendingFilter.month, pendingFilter.topic.name, val.name);
        } else if (obj === "topic") {
            renderFilterValue(pendingFilter.month, val.name, pendingFilter.scope.name);
        } else {
            renderFilterValue(val, pendingFilter.topic.name, pendingFilter.scope.name);
        }
        setPendingFilter(loc);
        forceUpdate();
    };
    const setPendingFilterValue = () => {
        let module = [];
        let list = rawdata;
        selector.information.config[0].location.countrylist.forEach((i) => {
            //  module.push({country: i.country})
            i.city.forEach((j) => {
                // module.push({country:i.country,city:j.city})
                j.location.forEach((k) => {
                    module.push({
                        country: i.country,
                        city: j.city,
                        location: k.location,
                    });
                });
            });
        });
        let scopelist = [];
        let topiclist = [];
        let count = 0;
        selector.information.config[0].selectedModule.forEach((i) => {
            i.children.forEach((j, a) => {
                if (a == 0) {
                    scopelist.push({
                        name: "All",
                    });
                }
                j.children.forEach((k) => {
                    list.scope.push(k.name);
                    scopelist.push({
                        name: k.name,
                    });
                    topiclist.push({
                        [k.name]: [],
                    });

                    if (k.children !== undefined) {
                        k.children.forEach((l, i) => {
                            list.topic.push(l.name);
                            if (i == 0) {
                                topiclist[count][k.name].push({
                                    name: "All",
                                });
                            }
                            topiclist[count][k.name].push({
                                name: l.name,
                            });
                        });
                        count++;
                    } else {
                        list.topic.push(k.name);
                        topiclist[count][k.name].push({
                            name: "All",
                        });
                        topiclist[count][k.name].push({
                            name: k.name,
                        });
                        count++;
                        // topiclist[count].push({ [k.name]: [{ name: k.name }] })
                    }
                });
            });
        });
        setRawData(list);
        let loc = filterData;
        loc.scope = scopelist;
        loc.topic = topiclist;

        setFilterData(loc);
        setRawTopic(topiclist);
    };
    const showNoDataFound = (id, txt) => {

        if (Chart.getChart(id) !== undefined) {

            Chart.getChart(id).destroy();
        }
        let canvas = document.getElementById(id)
        const ctx = document.getElementById(id).getContext("2d");
        ctx.clearRect(0, 0, 500, 500);
        ctx.restore()

        ctx.textAlign = 'center';
        ctx.textBaseLine = 'middle';
        // Set the font-size and font-family to mimic our paragraph
        ctx.font = "16px serif";

        // Draw the text
        ctx.fillText(txt, canvas.offsetWidth / 2, canvas.offsetWidth / 2);

    };
    const showPieChartTAG = (id, chartdata, type) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = true
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {

            Chart.getChart(id).destroy();
        }

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };

        let total = 0
        chartdata.forEach((i) => {
            total = total + i.count
        })

        let labelList = [];
        chartdata.forEach((a) => {
            labelList.push([a.title]);
        });
        let dummy = JSON.parse(JSON.stringify(chartdata)).map((i) => { return ((i.count / total).toFixed(4) * 100) })

        let scope1 = chartref.getContext("2d");
        const toolTipTitleRender = (items) => {

            return "";
        };
        const toolTipLabelRender = (items) => {


            return items.label + '-' + items.formattedValue + '%';
        };
        const percentLabel2 = {
            id: 'percent_',
            afterDatasetDraw(chart, args, pluginoptions) {
                const { ctx, data } = chart;
                const { datasets, labels } = data;
                const dataset = datasets[0];
                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                const reducedRadiusPercentage = 0.8; // Change this value as needed

                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = '12px Arial';

                const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                const originalRadius = chart.width / 2;
                const innerRadius = chart.chartArea.width / 24;
                const adjustedRadius = (originalRadius - innerRadius) * reducedRadiusPercentage + innerRadius;

                for (let i = 0; i < dataset.data.length; i++) {
                    const value = dataset.data[i];
                    console.log('updating', chart.getDatasetMeta(0).data[i])
                    if (value > 0) {
                        const percentage = value
                        const startAngle = chart.getDatasetMeta(0).data[i].startAngle;
                        const angle = startAngle + (Math.PI * 2 * percentage) / 100 / 2;

                        const labelX = centerX + Math.cos(angle) * adjustedRadius * 1.15;
                        const labelY = centerY + Math.sin(angle) * adjustedRadius * 1.15;

                        // Draw the data label
                        ctx.fillStyle = 'black'; // Set the label color here
                        ctx.fillText(value.toFixed(1).toString() + '%', labelX, labelY);
                    }
                }

                ctx.restore();
            }


        }
        let scope1c = new Chart(scope1, {
            type: "pie",

            data: {
                //Bring in data
                labels: labelList,
                datasets: [
                    {
                        data: dummy,


                        backgroundColor: JSON.parse(JSON.stringify(chartdata)).map((i) => { return i.color })

                    },
                ],
            },
            options: {


                hover: {
                    mode: null // Set to null to disable hover
                },
                radius: '85%',
                legend: {
                    display: false,
                },
                plugins: {

                    legend: {
                        onClick: (e, legendItem, legend) => { },

                        display: false,
                        position: "bottom",
                        labels: {
                            color: "black",
                            boxWidth: 6,

                            font: {
                                size: 12,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },

                    tooltip: {
                        enabled: false
                    },
                },


            },
            plugins: [percentLabel2]
        });


    };
    const showPieChartTAG_Legend = (id, chartdata) => {
        let n = Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000);
        let count = true
        let chartref = document.getElementById(id);

        if (Chart.getChart(id) !== undefined) {

            Chart.getChart(id).destroy();
        }

        let coords = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };

        let total = 0
        chartdata.forEach((i) => {
            total = total + i.count
        })

        let labelList = [];
        chartdata.forEach((a) => {
            labelList.push([a.title]);
        });
        let dummy = JSON.parse(JSON.stringify(chartdata)).map((i) => { return ((i.count / total).toFixed(4) * 100) })

        let scope1 = chartref.getContext("2d");
        const toolTipTitleRender = (items) => {

            return "";
        };
        const toolTipLabelRender = (items) => {


            return items.label + '-' + items.formattedValue + '%';
        };
        const percentLabel2 = {
            id: 'percent_',
            afterDatasetDraw(chart, args, pluginoptions) {
                const { ctx, data } = chart;
                const { datasets, labels } = data;
                const dataset = datasets[0];
                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                const reducedRadiusPercentage = 0.8; // Change this value as needed

                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = '12px Arial';

                const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                const originalRadius = chart.width / 2;
                const innerRadius = chart.chartArea.width / 24;
                const adjustedRadius = (originalRadius - innerRadius) * reducedRadiusPercentage + innerRadius;

                for (let i = 0; i < dataset.data.length; i++) {
                    const value = dataset.data[i];
                    console.log('updating', chart.getDatasetMeta(0).data[i])
                    if (value > 0) {
                        const percentage = value
                        const startAngle = chart.getDatasetMeta(0).data[i].startAngle;
                        const angle = startAngle + (Math.PI * 2 * percentage) / 100 / 2;

                        const labelX = centerX + Math.cos(angle) * adjustedRadius * 0.65;
                        const labelY = centerY + Math.sin(angle) * adjustedRadius * 0.65;

                        // Draw the data label
                        ctx.fillStyle = 'black'; // Set the label color here
                        ctx.fillText(value.toFixed(1).toString() + '%', labelX, labelY);
                    }
                }

                ctx.restore();
            }


        }
        let scope1c = new Chart(scope1, {
            type: "pie",

            data: {
                //Bring in data
                labels: labelList,
                datasets: [
                    {
                        data: JSON.parse(JSON.stringify(chartdata)).map((i) => { return 0 }),


                        backgroundColor: JSON.parse(JSON.stringify(chartdata)).map((i) => { return i.color })

                    },
                ],
            },
            options: {

                elements: {
                    arc: {
                        display: false, // Set this to false to hide the pie graph
                    },
                },
                hover: {
                    mode: null // Set to null to disable hover
                },

                legend: {
                    display: true,
                },
                plugins: {

                    legend: {
                        onClick: (e, legendItem, legend) => { },

                        display: true,
                        position: "top",
                        labels: {
                            color: "black",
                            boxWidth: 6,

                            font: {
                                size: 12,
                                family: `"Roboto", sans-serif`,
                                weight: "normal",
                            },
                        },
                    },

                    tooltip: {
                        enabled: false
                    },
                },


            },
            plugins: []
        });


    };
    const renderPercentageGraph = (id, label, percent) => {
        let chartref = document.getElementById(id);
        if (Chart.getChart(id) !== undefined) {
            Chart.getChart(id).destroy();
        }
        let ch = chartref.getContext("2d");
        const data = {
            labels: [label],
            datasets: [{

                data: [percent, 100 - percent],
                backgroundColor: [
                    'cornflowerblue', 'rgba(0,0,0,0.1)'

                ],

                borderWidth: 0,
                borderRadius: 0,
                cutout: '80%'
            }]
        };
        const percentLabel = {
            id: 'percent',
            beforeDatasetsDraw(chart, args, pluginoptions) {

                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, data } = chart;
                ctx.save()

                const xcoor = chart.getDatasetMeta(0).data[0].x;
                const ycoor = chart.getDatasetMeta(0).data[0].y;

                ctx.font = 'bold 14px sans-serif'
                ctx.fillStyle = 'blue'
                ctx.textAlign = 'center'
                ctx.textBaseLine = 'middle'
                ctx.fillText(percent.toFixed(2) + '%', xcoor, ycoor + 5)
            }
        }

        // config 
        const config = {
            type: 'doughnut',
            data,
            options: {
                radius: '85%',

                plugins: {
                    legend: {
                        display: false
                    }, tooltip: {
                        enabled: false
                    }

                }
            },
            plugins: [percentLabel]
        };

        // render init block
        const myChart = new Chart(
            ch,
            config
        );


    }
    const dateFormatting = (date) => {
        if (typeof date === "string") {
            let newDate = new Date(date);

            return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
        } else {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        }
    };
    const scopeFormat = (name) => {
        if (!name.toLowerCase().includes("general")) {
            let sub1 = name[0].toLowerCase();
            let sub2 = name.split(" ")[0];
            let sub3 = sub1 + name.substr(1, sub2.length - 1) + name.split(" ")[1];
            return sub3;
        } else {
            return "general";
        }
    };
    const renderFilterValue = (month, topic, scope) => {
        apidata.forEach((i) => {
            if (i.month === dateFormatting(month) && (i.scope === scopeFormat(scope) || scope === "All") && (i.topic === scopeFormat(topic) || topic === "All")) {

            }
        });
    };
    const responsibilityTemplate = (rowData) => {
        let res = 'Reporter not exist'
        let userIndex = userList.findIndex((i) => { return i.id === rowData.user })
        let approverIndex = userList.findIndex((i) => { return i.id === rowData.approver })
        if (rowData.draft === undefined) {

            if (userIndex !== -1) {
                res = userList[userIndex].information.empname + ' (Reporter)'
            }

        } else {
            rowData.draft.type === 1 ? res = userList[userIndex].information.empname + ' (Reporter)' : res = userList[approverIndex].information.empname + ' (Reviewer)'
        }
        return (<span>{res}</span>)
    }
    const checkSite = (id, filter) => {
        let idlist = []

        siteList.forEach((country) => {

            if (filter.a.id === 0 || filter.a.id === country.id) {

                country.locationTwos.forEach((city) => {
                    if (filter.b.id === 0 || filter.b.id === city.id) {
                        city.locationThrees.forEach((loc) => {
                            if (filter.c.id == 0 || filter.c.id === loc.id) {
                                idlist.push(loc.id)
                            }
                        })
                    }
                })
            }
        })

        return idlist.includes(id)
    }
    const checkReportingPeriod = (rp, filter) => {

        let count = 0, rps = []
        filter.forEach((item) => {

            if (rp.includes(item)) {
                count = count + 1
                rps.push(item)
            }
        })
        return { result: count === rp.length, count: count, rps }
    }
    const renderGraph = (search) => {
        let betweenMonths = [], betweenYears = []
        let monthly_fg = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')


        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))
            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {
                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)
                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')
            let pie = []
            monthly_fg.filter((i) => { return (i.scope === 1) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 2) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })
            monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
                let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                if (index === -1) {
                    pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                } else {
                    pie[index].y = pie[index].y + item.ghg
                }
            })

            let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
            let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
            let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

            if (index1 === -1) {
                pie.push({ name: 'Scope 1', y: 0 })
            }
            if (index2 === -1) {
                pie.push({ name: 'Scope 2', y: 0 })
            }
            if (index3 === -1) {
                pie.push({ name: 'Scope 3', y: 0 })
            }


            if (scope1_array.length !== 0) {
                let scope1_tier2 = [], final = []
                Object.keys(scope1_array).forEach((key) => {
                    scope1_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title

                        if (
                            scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {

                            scope1_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,

                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope1_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope1_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope1_tier2[ind].y = scope1_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope1_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope1_tier2[ind].subset[subind][1] = scope1_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })

                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope1_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.year === j && k.scope === 1 }), 'site', 'dcfId', 'month'), JSON.parse(JSON.stringify(betweenMonths)).filter((k) => { return k.split('-')[1] === j })); return i })


                    final.push(dataset)
                })
                if (final.length !== 0) {

                    showBarChartWithDrill(final, 'scope111', {
                        name: "GHG Emission", label: betweenYears, monthCount: betweenMonths.length,
                        measure: " tCO" + "\u2082" + "e",
                    }, 0)
                } else {
                    showNoDataFound('scope111', 'No data found for Scope 1')
                }
            }
            if (scope2_array.length !== 0) {
                let scope2_tier2 = [], final = []
                Object.keys(scope2_array).forEach((key) => {
                    scope2_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope2_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope2_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope2_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope2_tier2[ind].y = scope2_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope2_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope2_tier2[ind].subset[subind][1] = scope2_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope2_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.year === j && k.scope === 2 }), 'site', 'dcfId', 'month'), JSON.parse(JSON.stringify(betweenMonths)).filter((k) => { return k.split('-')[1] === j })); return i })

                    final.push(dataset)
                })
                if (final.length !== 0) {
                    showBarChartWithDrill(final, 'scope222', {
                        name: "GHG Emission", label: betweenYears, monthCount: betweenMonths.length,
                        measure: " tCO" + "\u2082" + "e",
                    }, 1)
                } else {
                    showNoDataFound('scope222', 'No data found for Scope 2')
                }

            }
            if (scope3_array.length !== 0) {
                let scope3_tier2 = [], final = []
                Object.keys(scope3_array).forEach((key) => {
                    scope3_array[key].forEach((item) => {
                        item.dcfId = item.dcf
                        item.dcf = dcflist.filter((l) => { return l.id === item.dcf }).length === 0 ? item.dcf : dcflist.filter((l) => { return l.id === item.dcf })[0].title
                        if (
                            scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            }) === -1
                        ) {
                            scope3_tier2.push({
                                name: item.dcf,
                                drilldown: true,
                                color: 'red',
                                y: item.ghg,
                                subset: [[key, item.ghg]],
                            });
                        } else {
                            let ind = scope3_tier2.findIndex((i) => {
                                return i.name === item.dcf;
                            });
                            let subind = scope3_tier2[ind].subset.findIndex((j) => {
                                return j[0] === key;
                            });
                            scope3_tier2[ind].y = scope3_tier2[ind].y + item.ghg;
                            if (subind === -1) {
                                scope3_tier2[ind].subset.push([key, item.ghg]);
                            } else {
                                scope3_tier2[ind].subset[subind][1] = scope3_tier2[ind].subset[subind][1] + item.ghg;
                            }
                        }
                    })
                })
                betweenYears.forEach((j) => {

                    let dataset = JSON.parse(JSON.stringify(scope3_tier2)).map((i) => { i.subset.sort((a, b) => { return moment(a[0], 'MMM-YYYY').toDate() - moment(b[0], 'MMM-YYYY').toDate() }); i.subset = i.subset.filter((k) => { return k[0].split('-')[1] === j }); i.y = i.subset.length === 0 ? 0 : getSum(i.subset); i.incomplete = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.year === j && k.scope === 3 }), 'site', 'dcfId', 'month'), JSON.parse(JSON.stringify(betweenMonths)).filter((k) => { return k.split('-')[1] === j })); return i })

                    final.push(dataset)
                })
                if (final.length !== 0) {
                    showBarChartWithDrill(final, 'scope333', {
                        name: "GHG Emission", label: betweenYears, monthCount: betweenMonths.length,
                        measure: " tCO" + "\u2082" + "e",
                    }, 2)
                } else {
                    showNoDataFound('scope333', 'No data found for Scope 3')
                }
            }



        }



    }
    const renderGraph_Past = (search) => {
        let sc_report = [], betweenMonths = [], betweenYears_ = [], betweenYears = []
        let monthly_fg = [], monthly_sc = [], stats = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')
        betweenYears_.push((year - 3).toString())
        betweenYears_.push((year - 2).toString())
        betweenYears_.push((year - 1).toString())
        betweenYears_.push((year).toString())
        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))
            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })





            let scope12_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }), 'month')
            let scope1_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 1) }), 'month')
            let scope2_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 2) }), 'month')

            let scope3_array = groupArrayObject(monthly_fg.filter((i) => { return (i.scope === 3) }), 'month')


            let scope12_result_array = [], scope3_result_array = [], scope1_result_array = [], scope2_result_array = []
            Object.keys(scope12_array).forEach((key) => {
                scope12_array[key].forEach((i) => {
                    if (scope12_result_array.length === 0) {
                        if (i.scope === 1) {
                            scope12_result_array.push(['Month', 'Scope 1', 'Scope 2'])
                            scope12_result_array.push([i.month, i.ghg, 0])
                        } else {
                            scope12_result_array.push(['Month', 'Scope 1', 'Scope 2'])
                            scope12_result_array.push([i.month, 0, i.ghg])
                        }


                    } else {
                        let monthInd = scope12_result_array.findIndex((j) => { return j[0] === i.month })
                        if (monthInd === -1) {
                            if (i.scope === 1) {
                                scope12_result_array.push([i.month, i.ghg, 0])
                            } else {
                                scope12_result_array.push([i.month, 0, i.ghg])
                            }

                        } else {
                            if (i.scope === 1) {
                                scope12_result_array[monthInd][1] = scope12_result_array[monthInd][1] + i.ghg
                            } else {
                                scope12_result_array[monthInd][2] = scope12_result_array[monthInd][2] + i.ghg
                            }

                        }

                    }
                })
            })
            Object.keys(scope3_array).forEach((key) => {
                scope3_array[key].forEach((i) => {
                    if (scope3_result_array.length === 0) {

                        scope3_result_array.push(['Month', 'Scope 3'])
                        scope3_result_array.push([i.month, i.ghg])



                    } else {
                        let monthInd = scope3_result_array.findIndex((j) => { return j[0] === i.month })
                        if (monthInd === -1) {

                            scope3_result_array.push([i.month, i.ghg])


                        } else {

                            scope3_result_array[monthInd][1] = scope3_result_array[monthInd][1] + i.ghg


                        }

                    }
                })
            })
            Object.keys(scope1_array).forEach((key) => {
                scope1_array[key].forEach((i) => {
                    if (scope1_result_array.length === 0) {

                        scope1_result_array.push(['Month', 'Scope 1'])
                        scope1_result_array.push([i.month, i.ghg])



                    } else {
                        let monthInd = scope1_result_array.findIndex((j) => { return j[0] === i.month })
                        if (monthInd === -1) {

                            scope1_result_array.push([i.month, i.ghg])


                        } else {

                            scope1_result_array[monthInd][1] = scope1_result_array[monthInd][1] + i.ghg


                        }

                    }
                })
            })
            Object.keys(scope2_array).forEach((key) => {
                scope2_array[key].forEach((i) => {
                    if (scope2_result_array.length === 0) {

                        scope2_result_array.push(['Month', 'Scope 1'])
                        scope2_result_array.push([i.month, i.ghg])



                    } else {
                        let monthInd = scope2_result_array.findIndex((j) => { return j[0] === i.month })
                        if (monthInd === -1) {

                            scope2_result_array.push([i.month, i.ghg])


                        } else {

                            scope2_result_array[monthInd][1] = scope2_result_array[monthInd][1] + i.ghg


                        }

                    }
                })
            })
            scope12_result_array.forEach((item, ind) => {
                if (ind !== 0) {
                    let shape1 = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.scope === 1 && k.year === item[0].split('-')[1] }), 'site', 'dcf', 'month'), [moment(item[0], 'MMM-YYYY').format('MM-YYYY')], scope1Ids) ? 'point { size: 8; shape-type: star; fill-color: red; }' : null
                    let shape2 = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.scope === 2 && k.year === item[0].split('-')[1] }), 'site', 'dcf', 'month'), [moment(item[0], 'MMM-YYYY').format('MM-YYYY')], scope2Ids) ? 'point { size: 8; shape-type: star; fill-color: red; }' : null

                    item[0] = moment(item[0], 'MMM-YYYY').toDate()
                    item[3] = item[2]
                    item[2] = shape1
                    item[4] = shape2

                }
            })
            betweenMonths.forEach((i) => {
                let index1 = scope1_result_array.findIndex((l) => { return l[0] === moment(i, 'MM-YYYY').format('MMM-YYYY') })

                let index2 = scope2_result_array.findIndex((l) => { return l[0] === moment(i, 'MM-YYYY').format('MMM-YYYY') })

                let index3 = scope3_result_array.findIndex((l) => { return l[0] === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                if (index3 === -1) {
                    scope3_result_array.push([moment(i, 'MM-YYYY').format('MMM-YYYY'), 0])
                }
                if (index2 === -1) {
                    scope2_result_array.push([moment(i, 'MM-YYYY').format('MMM-YYYY'), 0])
                }
                if (index1 === -1) {
                    scope1_result_array.push([moment(i, 'MM-YYYY').format('MMM-YYYY'), 0])
                }
            })
            scope3_result_array.forEach((item, ind) => {
                if (ind !== 0) {
                    let shape = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.scope === 3 && k.year === item[0].split('-')[1] }), 'site', 'dcf', 'month'), [moment(item[0], 'MMM-YYYY').format('MM-YYYY')], scope3Ids)

                    item[0] = moment(item[0], 'MMM-YYYY').toDate()
                    item[2] = shape
                }
            })
            scope1_result_array.forEach((item, ind) => {
                if (ind !== 0) {
                    let shape = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.scope === 1 && k.year === item[0].split('-')[1] }), 'site', 'dcf', 'month'), [moment(item[0], 'MMM-YYYY').format('MM-YYYY')], scope1Ids)

                    item[0] = moment(item[0], 'MMM-YYYY').toDate()
                    item[2] = shape
                }
            })
            scope2_result_array.forEach((item, ind) => {
                if (ind !== 0) {
                    let shape = getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.scope === 2 && k.year === item[0].split('-')[1] }), 'site', 'dcf', 'month'), [moment(item[0], 'MMM-YYYY').format('MM-YYYY')], scope2Ids)

                    item[0] = moment(item[0], 'MMM-YYYY').toDate()
                    item[2] = shape
                }
            })
            let tscope12 = scope12_result_array.sort((a, b) => { return moment(a[0]) - moment(b[0]) }).filter((k) => { return betweenYears_.includes(moment(k[0]).format('YYYY')) })
            let tscope3 = scope3_result_array.sort((a, b) => { return moment(a[0]) - moment(b[0]) }).filter((k) => { return betweenYears_.includes(moment(k[0]).format('YYYY')) })
            let tscope1 = scope1_result_array.sort((a, b) => { return moment(a[0]) - moment(b[0]) }).filter((k) => { return betweenYears_.includes(moment(k[0]).format('YYYY')) })
            let tscope2 = scope2_result_array.sort((a, b) => { return moment(a[0]) - moment(b[0]) }).filter((k) => { return betweenYears_.includes(moment(k[0]).format('YYYY')) })


            setTimeout(() => {

                // if (tscope12.length !== 0) {
                //     tscope12.unshift([
                //         "Month",
                //         "Scope 1", { 'role': 'style' },
                //         "Scope 2", { 'role': 'style' }
                //     ])

                //     showTrendLineChart('scope12', 'Scope 1 + 2', tscope12, 'MMM-YYY')
                // } else {
                //     showNoDataFoundGoogleChart('scope12', 'No data found for Scope 1 + 2')
                // }
                if (tscope3.length !== 0) {


                    // tscope3.unshift([
                    //     "Month",
                    //     "Scope 3", { 'role': 'style' }
                    // ])
                    let dataset = []
                    tscope3.forEach((item) => {
                        dataset.push({ name: moment(item[0]).format('MMM-YYYY'), incomplete: item[2], y: item[1], drilldown: false })
                    })
                    showLineChartWithNoDrill(dataset, 'scope3', {
                        name: "Scope 3", label: [], monthCount: 0,
                        measure: " tCO" + "\u2082" + "e",
                    }, 2)
                    // showTrendLineChart('scope3', 'Scope 3', tscope3, 'MMM-YYY')
                } else {
                    showNoDataFound('scope3', 'No data found for Scope 3')
                }
                if (tscope1.length !== 0) {


                    // tscope1.unshift([
                    //     "Month",
                    //     "Scope 1", { 'role': 'style' }
                    // ])
                    let dataset = []
                    tscope1.forEach((item) => {
                        dataset.push({ name: moment(item[0]).format('MMM-YYYY'), incomplete: item[2], y: item[1], drilldown: false })
                    })
                    showLineChartWithNoDrill(dataset, 'scope1', {
                        name: "Scope 1", label: [], monthCount: 0,
                        measure: " tCO" + "\u2082" + "e",
                    }, 0)

                    // showTrendLineChart('scope1', 'Scope 1', tscope1, 'MMM-YYY')
                } else {

                    showNoDataFound('scope1', 'No data found for Scope 1')
                }
                if (tscope2.length !== 0) {


                    // tscope2.unshift([
                    //     "Month",
                    //     "Scope 2", { 'role': 'style' }
                    // ])
                    let dataset = []
                    tscope2.forEach((item) => {
                        dataset.push({ name: moment(item[0]).format('MMM-YYYY'), incomplete: item[2], y: item[1], drilldown: false })
                    })
                    showLineChartWithNoDrill(dataset, 'scope2', {
                        name: "Scope 2", label: [], monthCount: 0,
                        measure: " tCO" + "\u2082" + "e",
                    }, 1)
                    // showTrendLineChart('scope2', 'Scope 2', tscope2, 'MMM-YYY')
                } else {
                    showNoDataFound('scope2', 'No data found for Scope 2')
                }

            }, 1000)

        }



    }
    function getPreviousMonths(date) {
        const start = moment(date, 'YYYY-MM-DD').startOf('year');
        const end = moment(date, 'YYYY-MM-DD');

        const months = [];
        let current = moment(start).startOf('month');

        while (current.isSameOrBefore(end, 'month')) {
            const month = current.format('MMM-YYYY');
            months.push(month);
            current.add(1, 'month');
        }

        return months;
    }

    const renderAnalyticalGraph = () => {
        let date = moment(moment.utc()).toDate().getUTCFullYear()
        setTimeout(() => {
            renderGraph_Past({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: null, indicator: { id: 0 }, timeline: filter.timeline, from: moment(date - 3, 'YYYY').local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate() })
            renderDataTillDate({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [moment(date - 2, 'YYYY').local().toDate(), moment(moment.utc()).startOf('month').local().toDate()], from: moment(selector.information.startdate).local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate(), type: 0 })
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: null, indicator: { id: 0 }, timeline: filter.timeline, from: filter.from, to: filter.to }, 1)
            renderPieGraph({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [moment(date - 2, 'YYYY').local().toDate(), moment(moment.utc()).startOf('month').local().toDate()], from: moment(date - 3, 'YYYY').local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate() })
            forceUpdate()
        }, 500)

    }
    const renderDataTillDate = (search) => {
        let sc_report = [], betweenMonths = [], pastYearData = [], betweenYears = []
        let monthly_fg = [], local = ytd


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))
            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })







        }
        // local.scope12 = JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return i.scope === 1 || i.scope === 2 }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0).toFixed(2)
        // local.scope3 = JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return i.scope === 3 }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0).toFixed(2)
        let ghg12 = 0, ghg12_ = 0, ghg3 = 0, ghg3_ = 0
        if (endDate.month() === 0) {
            getPreviousMonths(moment('01-01-2023', 'DD-MM-YYYY').subtract(1, 'month')).forEach((item) => {
                ghg12 = ghg12 + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 1 || i.scope === 2) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)
                ghg3 = ghg3 + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 3) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)

            })
            getPreviousMonths(moment('01-01-2023', 'DD-MM-YYYY').subtract(13, 'month')).forEach((item) => {
                ghg12_ = ghg12_ + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 1 || i.scope === 2) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)
                ghg3_ = ghg3_ + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 3) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)

            })
            local.scope12 = ghg12.toFixed(2)
            local.scope3 = ghg3.toFixed(2)
        } else {

            getPreviousMonths(endDate.subtract(1, 'month')).forEach((item) => {

                ghg12 = ghg12 + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 1 || i.scope === 2) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)
                ghg3 = ghg3 + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 3) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)

            })
            getPreviousMonths(endDate.subtract(1, 'years')).forEach((item) => {

                ghg12_ = ghg12_ + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 1 || i.scope === 2) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)
                ghg3_ = ghg3_ + JSON.parse(JSON.stringify(monthly_fg)).filter((i) => { return ((i.scope === 3) && i.month === item) }).map((a, b) => { return a.ghg }).reduce((a, b) => { return a + b }, 0)

            })
            local.scope12 = ghg12.toFixed(2)
            local.scope3 = ghg3.toFixed(2)
            local.scope12_ = ghg12 === 0 ? 0 : ghg12_ >= ghg12 ? (ghg12_ / ghg12) * 100 : (ghg12_ / ghg12) * -100
            local.scope3_ = ghg3 === 0 ? 0 : ghg3_ >= ghg3 ? (ghg3_ / ghg3) * 100 : (ghg3_ / ghg3) * -100
        }

        setYtd(local)
        let loc = JSON.parse(JSON.stringify(gta))
        let gtaindex = loc.findIndex((i) => { return i.selected === 1 })
        loc[gtaindex].fy1list = JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } })
        loc[gtaindex].fy1 = JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } }).length !== 0 ? JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } })[0] : null
        loc[gtaindex].fy2list = JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } })
        loc[gtaindex].fy2 = JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } }).length > 0 ? JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } })[1] : JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } }).length !== 0 ? JSON.parse(JSON.stringify(betweenYears)).map((i) => { return { name: i } })[0] : null
        setGTA(loc)
        if (search.type !== undefined) {
            if ((loc[gtaindex].fy1 !== null && loc[gtaindex].fy1 !== undefined) && (loc[gtaindex].fy2 !== null && loc[gtaindex].fy2 !== undefined)) {
                monthly_fg.forEach((items) => {
                    if (items.year === loc[gtaindex].fy1.name || items.year === loc[gtaindex].fy2.name) {
                        let index = pastYearData.findIndex((l) => { return l.year === items.year && l.month === items.month && l.label === 'Scope ' + items.scope })
                        if (index === -1) {
                            pastYearData.push({ year: items.year, month: items.month, label: 'Scope ' + items.scope, y: items.ghg })
                        } else {
                            pastYearData[index].y = pastYearData[index].y + items.ghg
                        }
                    }
                })

                pastYearData.forEach((i) => {
                    let index1 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 1' })
                    let index2 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 2' })
                    let index3 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 3' })
                    if (index1 === -1) {
                        pastYearData.push({ year: i.year, month: i.month, label: 'Scope 1', y: 0 })
                    }
                    if (index2 === -1) {
                        pastYearData.push({ year: i.year, month: i.month, label: 'Scope 2', y: 0 })
                    }
                    if (index3 === -1) {
                        pastYearData.push({ year: i.year, month: i.month, label: 'Scope 3', y: 0 })
                    }

                })

                if (pastYearData.length !== 0) {
                    // pastYearData.push(...dummy_month)
                    let gsdata = [], labels = []

                    let sortedData = pastYearData.sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() })
                    let groupedArray = groupArrayObject(sortedData, 'month')
                    let groupedArray_ = groupArrayObject(sortedData, 'label')

                    // Object.keys(groupedArray).forEach((key) => {
                    //     labels.push(key)

                    // })
                    Object.keys(groupedArray_).forEach((key) => {
                        betweenMonths.forEach((l) => {
                            if (l.split('-')[1] === loc[gtaindex].fy1.name || l.split('-')[1] === loc[gtaindex].fy2.name) {
                                let dateFormate = moment(l, 'MM-YYYY').format('MMM-YYYY')

                                if (groupedArray_[key].findIndex((k) => { return k.month === dateFormate }) === -1) {
                                    groupedArray_[key].push({ ...groupedArray_[key][0], month: dateFormate, year: dateFormate.split('-')[1], y: 0 })
                                }
                            }
                        })
                        gsdata.push(groupedArray_[key].sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() }));

                    })


                    let restMonths = getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, loc[gtaindex].fy2.name)

                    gsdata.forEach((item, j) => {
                        let newData = []
                        restMonths.forEach((i) => {
                            let index = item.findIndex((l) => { return l.month === i })

                            if (index === -1) {
                                newData.push({ year: i.split('-')[1], month: i, label: item[0].label, y: 0 })
                            }
                        })
                        item.push(...newData)
                        // getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, loc[gtaindex].fy2.name).includes()
                    })

                    gsdata.forEach((i) => {
                        i = i.sort((a, b) => {
                            const dateA = moment(a.month, 'MMM-YYYY');
                            const dateB = moment(b.month, 'MMM-YYYY');
                            // Compare the months first
                            const monthComparison = dateA.month() - dateB.month();

                            // If the months are the same, compare the years
                            if (monthComparison === 0) {
                                return dateA.year() - dateB.year();
                            }

                            return monthComparison;
                        })
                    })
                    let chartData = []
                    gsdata.forEach((item) => {
                        item.forEach((i) => {

                            let index = chartData.findIndex((l) => { return l.label === i.label + '-' + i.year })
                            if (index === -1) {
                                chartData.push({ label: i.label + '-' + i.year, data: [i.y], stack: i.year, backgroundColor: stackBar[parseInt(i.label.match(/\d+/)[0]) - 1] })
                            } else {

                                chartData[index].data.push(i.y)
                            }
                        })
                    })

                    showStackedBarChartWithoutDrill(chartData, 'scope123c', { measure: " tCO" + "\u2082" + "e", labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'], actual: monthly_fg }, 1)
                }
            } else if ((loc[gtaindex].fy1 !== null && loc[gtaindex].fy1 !== undefined) && (loc[gtaindex].fy2 === undefined || loc[gtaindex].fy2 === null)) {
                monthly_fg.forEach((items) => {
                    if (items.year === loc[gtaindex].fy1.name) {
                        let index = pastYearData.findIndex((l) => { return l.year === items.year && l.month === items.month && l.label === 'Scope ' + items.scope })
                        if (index === -1) {
                            pastYearData.push({ year: items.year, month: items.month, label: 'Scope ' + items.scope, y: items.ghg })
                        } else {
                            pastYearData[index].y = pastYearData[index].y + items.ghg
                        }
                    }
                })

                pastYearData.forEach((i) => {
                    let index1 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 1' })
                    let index2 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 2' })
                    let index3 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 3' })
                    if (index1 === -1) {
                        pastYearData.push({ year: i.year, month: i.month, label: 'Scope 1', y: 0 })
                    }
                    if (index2 === -1) {
                        pastYearData.push({ year: i.year, month: i.month, label: 'Scope 2', y: 0 })
                    }
                    if (index3 === -1) {
                        pastYearData.push({ year: i.year, month: i.month, label: 'Scope 3', y: 0 })
                    }

                })

                if (pastYearData.length !== 0) {
                    // pastYearData.push(...dummy_month)
                    let gsdata = [], labels = []

                    let sortedData = pastYearData.sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() })
                    let groupedArray = groupArrayObject(sortedData, 'month')
                    let groupedArray_ = groupArrayObject(sortedData, 'label')

                    // Object.keys(groupedArray).forEach((key) => {
                    //     labels.push(key)

                    // })
                    Object.keys(groupedArray_).forEach((key) => {
                        betweenMonths.forEach((l) => {
                            if (l.split('-')[1] === loc[gtaindex].fy1.name) {
                                let dateFormate = moment(l, 'MM-YYYY').format('MMM-YYYY')

                                if (groupedArray_[key].findIndex((k) => { return k.month === dateFormate }) === -1) {
                                    groupedArray_[key].push({ ...groupedArray_[key][0], month: dateFormate, year: dateFormate.split('-')[1], y: 0 })
                                }
                            }
                        })
                        gsdata.push(groupedArray_[key].sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() }));

                    })


                    let restMonths = getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, null)




                    gsdata.forEach((item, j) => {
                        let newData = []
                        restMonths.forEach((i) => {
                            let index = item.findIndex((l) => { return l.month === i })

                            if (index === -1) {
                                newData.push({ year: i.split('-')[1], month: i, label: item[0].label, y: 0 })
                            }
                        })
                        item.push(...newData)
                        // getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, loc[gtaindex].fy2.name).includes()
                    })
                    gsdata.forEach((i) => {
                        i = i.sort((a, b) => {
                            const dateA = moment(a.month, 'MMM-YYYY');
                            const dateB = moment(b.month, 'MMM-YYYY');
                            // Compare the months first
                            const monthComparison = dateA.month() - dateB.month();

                            // If the months are the same, compare the years
                            if (monthComparison === 0) {
                                return dateA.year() - dateB.year();
                            }

                            return monthComparison;
                        })
                    })
                    let chartData = []
                    gsdata.forEach((item) => {
                        item.forEach((i) => {

                            let index = chartData.findIndex((l) => { return l.label === i.label + '-' + i.year })
                            if (index === -1) {
                                chartData.push({ label: i.label + '-' + i.year, data: [i.y], stack: i.year, })
                            } else {

                                chartData[index].data.push(i.y)
                            }
                        })
                    })
                    showStackedBarChartWithoutDrill(chartData, 'scope123c', { measure: " tCO" + "\u2082" + "e", labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'], actual: monthly_fg }, 1)
                }
            } else {
                // showNoDataFound('scope123c', 'No Data to show')
            }
        }
    }
    const updateComparisionGraph = (val, obj) => {

        let search = { location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, from: moment(selector.information.startdate).local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate() }
        let sc_report = [], betweenMonths = [], pastYearData = [], betweenYears = []
        let monthly_fg = []
        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')

        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));
                !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))

                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))
            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })

        }


        let loc = JSON.parse(JSON.stringify(gta))
        let gtaindex = loc.findIndex((i) => { return i.selected === 1 })

        loc[gtaindex][obj] = val

        setGTA(loc)

        if (loc[gtaindex].fy1 !== null && loc[gtaindex].fy2 !== null) {

            monthly_fg.forEach((items) => {
                if (items.year === loc[gtaindex].fy1.name || items.year === loc[gtaindex].fy2.name) {
                    let index = pastYearData.findIndex((l) => { return l.year === items.year && l.month === items.month && l.label === 'Scope ' + items.scope })
                    if (index === -1) {
                        pastYearData.push({ year: items.year, month: items.month, label: 'Scope ' + items.scope, y: items.ghg })
                    } else {
                        pastYearData[index].y = pastYearData[index].y + items.ghg
                    }
                }
            })

            pastYearData.forEach((i) => {
                let index1 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 1' })
                let index2 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 2' })
                let index3 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 3' })
                if (index1 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 1', y: 0 })
                }
                if (index2 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 2', y: 0 })
                }
                if (index3 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 3', y: 0 })
                }

            })

            if (pastYearData.length !== 0) {
                // pastYearData.push(...dummy_month)
                let gsdata = [], labels = []

                let sortedData = pastYearData.sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() })
                let groupedArray = groupArrayObject(sortedData, 'month')
                let groupedArray_ = groupArrayObject(sortedData, 'label')

                // Object.keys(groupedArray).forEach((key) => {
                //     labels.push(key)

                // })
                Object.keys(groupedArray_).forEach((key) => {
                    betweenMonths.forEach((l) => {
                        if (l.split('-')[1] === loc[gtaindex].fy1.name || l.split('-')[1] === loc[gtaindex].fy2.name) {
                            let dateFormate = moment(l, 'MM-YYYY').format('MMM-YYYY')

                            if (groupedArray_[key].findIndex((k) => { return k.month === dateFormate }) === -1) {
                                groupedArray_[key].push({ ...groupedArray_[key][0], month: dateFormate, year: dateFormate.split('-')[1], y: 0 })
                            }
                        }
                    })
                    gsdata.push(groupedArray_[key].sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() }));

                })


                let restMonths = getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, loc[gtaindex].fy2.name)

                gsdata.forEach((item, j) => {
                    let newData = []
                    restMonths.forEach((i) => {
                        let index = item.findIndex((l) => { return l.month === i })

                        if (index === -1) {
                            newData.push({ year: i.split('-')[1], month: i, label: item[0].label, y: 0 })
                        }
                    })
                    item.push(...newData)
                    // getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, loc[gtaindex].fy2.name).includes()
                })

                gsdata.forEach((i) => {
                    i = i.sort((a, b) => {
                        const dateA = moment(a.month, 'MMM-YYYY');
                        const dateB = moment(b.month, 'MMM-YYYY');
                        // Compare the months first
                        const monthComparison = dateA.month() - dateB.month();

                        // If the months are the same, compare the years
                        if (monthComparison === 0) {
                            return dateA.year() - dateB.year();
                        }

                        return monthComparison;
                    })
                })
                let chartData = []
                gsdata.forEach((item) => {
                    item.forEach((i) => {

                        let index = chartData.findIndex((l) => { return l.label === i.label + '-' + i.year })
                        if (index === -1) {
                            chartData.push({ label: i.label + '-' + i.year, data: [i.y], stack: i.year })
                        } else {

                            chartData[index].data.push(i.y)
                        }
                    })
                })

                showStackedBarChartWithoutDrill(chartData, 'scope123c', { measure: " tCO" + "\u2082" + "e", labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'], actual: monthly_fg }, 1)
            }
        } else if (loc[gtaindex].fy1 !== null && loc[gtaindex].fy2 === null) {
            monthly_fg.forEach((items) => {
                if (items.year === loc[gtaindex].fy1.name) {
                    let index = pastYearData.findIndex((l) => { return l.year === items.year && l.month === items.month && l.label === 'Scope ' + items.scope })
                    if (index === -1) {
                        pastYearData.push({ year: items.year, month: items.month, label: 'Scope ' + items.scope, y: items.ghg })
                    } else {
                        pastYearData[index].y = pastYearData[index].y + items.ghg
                    }
                }
            })

            pastYearData.forEach((i) => {
                let index1 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 1' })
                let index2 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 2' })
                let index3 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 3' })
                if (index1 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 1', y: 0 })
                }
                if (index2 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 2', y: 0 })
                }
                if (index3 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 3', y: 0 })
                }

            })

            if (pastYearData.length !== 0) {
                // pastYearData.push(...dummy_month)
                let gsdata = [], labels = []

                let sortedData = pastYearData.sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() })
                let groupedArray = groupArrayObject(sortedData, 'month')
                let groupedArray_ = groupArrayObject(sortedData, 'label')

                // Object.keys(groupedArray).forEach((key) => {
                //     labels.push(key)

                // })
                Object.keys(groupedArray_).forEach((key) => {
                    betweenMonths.forEach((l) => {
                        if (l.split('-')[1] === loc[gtaindex].fy1.name) {
                            let dateFormate = moment(l, 'MM-YYYY').format('MMM-YYYY')

                            if (groupedArray_[key].findIndex((k) => { return k.month === dateFormate }) === -1) {
                                groupedArray_[key].push({ ...groupedArray_[key][0], month: dateFormate, year: dateFormate.split('-')[1], y: 0 })
                            }
                        }
                    })
                    gsdata.push(groupedArray_[key].sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() }));

                })


                let restMonths = getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, null)

                gsdata.forEach((item, j) => {
                    let newData = []
                    restMonths.forEach((i) => {
                        let index = item.findIndex((l) => { return l.month === i })

                        if (index === -1) {
                            newData.push({ year: i.split('-')[1], month: i, label: item[0].label, y: 0 })
                        }
                    })
                    item.push(...newData)
                    // getRestMonthsOfGivenYear(loc[gtaindex].fy1.name, loc[gtaindex].fy2.name).includes()
                })

                gsdata.forEach((i) => {
                    i = i.sort((a, b) => {
                        const dateA = moment(a.month, 'MMM-YYYY');
                        const dateB = moment(b.month, 'MMM-YYYY');
                        // Compare the months first
                        const monthComparison = dateA.month() - dateB.month();

                        // If the months are the same, compare the years
                        if (monthComparison === 0) {
                            return dateA.year() - dateB.year();
                        }

                        return monthComparison;
                    })
                })
                let chartData = []
                gsdata.forEach((item) => {
                    item.forEach((i) => {

                        let index = chartData.findIndex((l) => { return l.label === i.label + '-' + i.year })
                        if (index === -1) {
                            chartData.push({ label: i.label + '-' + i.year, data: [i.y], stack: i.year })
                        } else {

                            chartData[index].data.push(i.y)
                        }
                    })
                })
                showStackedBarChartWithoutDrill(chartData, 'scope123c', { measure: " tCO" + "\u2082" + "e", labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'], actual: monthly_fg }, 1)
            }
        } else {
            // showNoDataFound('scope123c', 'No Data to show')
        }

    }
    const renderGraphByTimeLine = (search) => {
        let dataset = [], betweenMonths = [], betweenYears_ = [], betweenYears = [], dataset2 = []
        let monthly_fg = []
        let loc = gfilter


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()
        let year = moment(moment.utc()).format('YYYY')
        let finalDate = moment.utc(moment(selector.information.startdate).startOf('month').toDate()).local().startOf('month')
        if (search.to !== null && search.from !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {
                if (finalDate <= startDate.startOf('month')) {
                    betweenMonths.push(startDate.format('MM-YYYY'));
                    !betweenYears.includes(startDate.format('YYYY')) && betweenYears.push(startDate.format('YYYY'))
                }
                startDate.add(1, 'month');

            }


            let res = JSON.parse(JSON.stringify(response))
            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {


                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {


                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })







        }
        monthly_fg.filter((i) => { return (i.scope === 1 || i.scope === 2) }).forEach((item) => {
            let index = dataset.findIndex((i) => { return i.name === item.month })
            if (index === -1) {
                dataset.push({ name: item.month, incomplete: false, y: item.ghg })
            } else {
                dataset[index].y = dataset[index].y + item.ghg
            }

        })
        monthly_fg.filter((i) => { return (i.scope === 3) }).forEach((item) => {
            let index = dataset2.findIndex((i) => { return i.name === item.month })
            if (index === -1) {
                dataset2.push({ name: item.month, incomplete: false, y: item.ghg })
            } else {
                dataset2[index].y = dataset2[index].y + item.ghg
            }

        })
        betweenMonths.forEach((i) => {

            if (dataset.findIndex((l) => { return l.name === moment(i, 'MM-YYYY').format('MMM-YYYY') }) === -1) {
                dataset.push({ name: moment(i, 'MM-YYYY').format('MMM-YYYY'), y: 0, incomplete: false })
            }
            if (dataset2.findIndex((l) => { return l.name === moment(i, 'MM-YYYY').format('MMM-YYYY') }) === -1) {
                dataset2.push({ name: moment(i, 'MM-YYYY').format('MMM-YYYY'), y: 0, incomplete: false })
            }
        })
        dataset2.sort((a, b) => { return moment(a.name, 'MMM-YYYY') - moment(b.name, 'MMM-YYYY') })
        dataset.sort((a, b) => { return moment(a.name, 'MMM-YYYY') - moment(b.name, 'MMM-YYYY') })
        if (!search.show) {
            loc.a = search.id
            if (dataset.length !== 0) {
                showLineChartWithNoDrill(dataset, 'indicator1', {
                    name: "Indicator 1", label: [], monthCount: 1,
                    measure: " tCO" + "\u2082" + "e",
                }, 0)
            } else {
                showNoDataFound('indicator1', 'No data found')
            }
        } else if (search.show) {
            loc.b = search.id
            if (dataset2.length !== 0) {
                showLineChartWithNoDrill(dataset2, 'indicator2', {
                    name: "Indicator 2", label: [], monthCount: 1,
                    measure: " tCO" + "\u2082" + "e",
                }, 0)
            } else {
                showNoDataFound('indicator2', 'No data found')
            }
        }
        if (search.show === null) {
            loc.a = search.id
            loc.b = search.id
            if (dataset.length !== 0) {
                showLineChartWithNoDrill(dataset, 'indicator1', {
                    name: "Indicator 1", label: [], monthCount: 1,
                    measure: " tCO" + "\u2082" + "e",
                }, 0)
            } else {
                showNoDataFound('indicator1', 'No data found')
            }
            if (dataset2.length !== 0) {
                showLineChartWithNoDrill(dataset2, 'indicator2', {
                    name: "Indicator 2", label: [], monthCount: 1,
                    measure: " tCO" + "\u2082" + "e",
                }, 0)
            } else {
                showNoDataFound('indicator2', 'No data found')
            }
        }

        setGFilter(loc)

        forceUpdate()
    }
    const renderPieGraph = (search) => {
        let sc_report = [], betweenMonths = [], betweenYears = []
        let monthly_fg = [], monthly_sc = []


        let endDate = moment.utc(search.to).local()
        let startDate = moment.utc(search.from).local()

        if (search.from !== null && search.to !== null) {
            while (startDate.startOf('month') <= endDate.startOf('month')) {

                betweenMonths.push(startDate.format('MM-YYYY'));

                startDate.add(1, 'month');

            }
            let year = moment(moment.utc()).format('YYYY')
            betweenYears.push((year - 3).toString())
            betweenYears.push((year - 2).toString())
            betweenYears.push((year - 1).toString())
            betweenYears.push((year).toString())



            let res = JSON.parse(JSON.stringify(response))
            res.forEach((report, rind) => {
                let sc_total = 0, fg_total = 0

                if (checkSite(report.site, search.location)) {
                    report.sitename = locationList.filter((loc) => { return loc.id === report.site })[0].name
                    if (report.dcf === 16 && (search.indicator.id === 0 || search.indicator.id === 122)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0285" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {







                                let item_class = fg.filter((i) => { return i.dpId === "DPA0287" })[0].value.name
                                let item_qty = fg.filter((i) => { return i.dpId === "DPA0288" })[0].value
                                let price_per_item = fg.filter((i) => { return i.dpId === "DPA0289" })[0].value

                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[0].data1[0].importedData.filter((k) => { return k.item_classification === item_class })[0]['co2e_in_kg'] / 1000) * item_qty * price_per_item

                                fg_total = total + fg_total


                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.item_class === item_class })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, item_class: item_class })

                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 36 && (search.indicator.id === 0 || search.indicator.id === 123)) {


                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0290" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {




                                let total = 0


                                let travel_mode = fg.filter((i) => { return i.dpId === "DPA0291" })[0].value.name
                                let passenger = fg.filter((i) => { return i.dpId === "DPA0292" })[0].value

                                if (travel_mode.toLowerCase() === 'air') {

                                    total = fg.filter((i) => { return i.dpId === "DP_co2e_mt" })[0].value * passenger

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                } else if (travel_mode.toLowerCase() === 'road') {

                                    let veh_cat = fg.filter((i) => { return i.dpId === "DPA0295" })[0].value.name
                                    let veh_type = fg.filter((i) => { return i.dpId === "DPA0337" })[0].value
                                    let fuel = fg.filter((i) => { return i.dpId === "DPA0338" })[0].value
                                    let km = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value
                                    if (veh_cat.includes('Cars') || veh_cat.includes('Motor')) {
                                        if (veh_cat.includes('Cars')) {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.fuel_type === fuel.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        } else {
                                            total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.vehicle_type === veh_type.name && k.unit === 'km' })[0]['co2e_in_kg'] / 1000) * km * passenger

                                        }

                                    } else {

                                        total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[2].stdTopics[1].data1[0].importedData.filter((k) => { return k.vehicle_category === veh_cat && k.unit === 'passenger.km' })[0]['co2e_in_kg'] / 1000) * km * passenger


                                    }


                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total


                                } else if (travel_mode.toLowerCase() === 'rail') {



                                    total = fg.filter((i) => { return i.dpId === "DP_KM" })[0].value * passenger * 0.00116

                                    let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.travel_mode === travel_mode })

                                    if (updateind === -1) {

                                        monthly_fg.push({ scope: 3, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: total, dcf: report.dcf, site: report.site, travel_mode: travel_mode })



                                    } else {

                                        monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                    }

                                    fg_total = total + fg_total
                                }





                            }
                        })

                    }

                    if (report.dcf === 11 && (search.indicator.id === 0 || search.indicator.id === 93)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, sc_data = []
                        report.response.forEach((fg, ind) => {

                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0130" })[0].value.name
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0131" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0132" })[0].value.name
                            let consumed = fg.filter((i) => { return i.dpId === "DPA0336" })[0].value
                            let fuel_cat_ind = sc_data.findIndex((k) => { return k.fuel_cat === fuel_cat })

                            if (fuel_cat === "Solid Fuels" || fuel_cat === "Biomass") {
                                let carbon = fg.filter((i) => { return i.dpId === "DPA0134" })[0].value
                                let cv = fg.filter((i) => { return i.dpId === "DPA0133" })[0].value
                                if (carbon > 0 && cv > 0) {
                                    let gj = (carbon * 3.664 * 1000) / cv
                                    total = (gj * 0.000004184 * consumed) / 1000
                                    fg['value'] = ['Emission Factor-', 3.664, ' ,EnergyProduced-', 0.000004184]
                                    co2 = 0
                                    ch4 = 0
                                    n2o = 0
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }

                                } else {
                                    total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                    co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                    ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                    n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                    fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                    if (fuel_cat_ind === -1) {
                                        sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                    } else {
                                        sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                    }
                                }

                            } else {
                                total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2e_in_kg'] / 1000) * consumed
                                co2 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['co2_in_kg'] / 1000) * consumed
                                ch4 = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['ch4_in_kg'] / 1000) * consumed
                                n2o = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[0].data1[0].importedData.filter((k) => { return k.fuel_type === fuel_cat && k.fuel === fuel_type && k.unit === unit })[0]['n2o_in_kg'] / 1000) * consumed
                                fg['value'] = ['co2-', co2 / consumed, ' ,ch4-', ch4 / consumed, ' ,n2o-', n2o / consumed]
                                if (fuel_cat_ind === -1) {
                                    sc_data.push({ fuel_cat: fuel_cat, ghg: total })
                                } else {
                                    sc_data[fuel_cat_ind].ghg = sc_data[fuel_cat_ind].ghg + total

                                }
                            }


                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                            sc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.fuel_cat === item.fuel_cat })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: (item.ghg / addedMonth.count), dcf: report.dcf, site: report.site, fuel_cat: item.fuel_cat })


                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }
                    if (report.dcf === 10 && (search.indicator.id === 0 || search.indicator.id === 116)) {
                        let add = []

                        report.response.forEach((fg, ind) => {

                            let date = fg.filter((i) => { return i.dpId === "DPA0137" })[0].value
                            let yearind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') })
                            let monthind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') })
                            let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf })
                            let siteind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })


                            if (checkReportingPeriod([moment(date).format('MM-YYYY')], betweenMonths).result) {






                                let gastype = fg.filter((i) => { return i.dpId === "DPA0136" })[0].value.name
                                let gasrefilled = fg.filter((i) => { return i.dpId === "DPA0138" })[0].value
                                let total = (emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[2].data1[0].importedData.filter((k) => { return k.gas_type === gastype })[0]['co2e_in_kg'] / 1000) * gasrefilled

                                fg_total = total + fg_total

                                fg['gas'] = gastype
                                fg['gasfilled'] = gasrefilled
                                fg['value'] = ['EmissionFactor-', total / gasrefilled]
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.gastype === gastype })

                                if (updateind === -1) {

                                    monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, gastype: gastype, ghg: total })



                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + total
                                }

                            }
                        })

                    }
                    if (report.dcf === 72 && (search.indicator.id === 0 || search.indicator.id === 121)) {

                        report.rp.forEach((i) => {
                            if (checkReportingPeriod([i], betweenMonths).result) {
                                let yearind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') })
                                let monthind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') })
                                let dcfind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf })
                                let siteind = monthly_fg.findIndex((i) => { return i.year === moment(i, 'MM-YYYY').format('YYYY') && i.month === moment(i, 'MM-YYYY').format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site })

                                let ef = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[1].stdTopics[0].data1[0].importedData[0]['kwh_in_tco2e']

                                let renewable = report.response[0].filter((i) => { return i.dpId === "DPA0156" })[0].value / report.rp.length
                                let nonrenewable = (report.response[0].filter((i) => { return i.dpId === "DPA0157" })[0].value / report.rp.length)

                                if (yearind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (monthind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })
                                } else if (dcfind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                } else if (siteind === -1) {

                                    monthly_fg.push({ scope: 2, year: moment(i, 'MM-YYYY').format('YYYY'), month: moment(i, 'MM-YYYY').format('MMM-YYYY'), ghg: nonrenewable * ef, dcf: report.dcf, site: report.site, renewable, nonrenewable })

                                }
                            }
                        })

                    }
                    if (report.dcf === 15 && (search.indicator.id === 0 || search.indicator.id === 118)) {

                        let addedMonth = checkReportingPeriod(report.rp, betweenMonths)


                        let ef_by_fuel = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[4].data1[0].importedData
                        let ef_by_distance = emissionFactor[0].stdYears[0].stdNames[0].stdScopes[0].stdTopics[1].data1[0].importedData

                        let total = 0, co2 = 0, ch4 = 0, n2o = 0, mc_data = []

                        report.response.forEach((fg, ind) => {
                            let mode = fg.filter((i) => { return i.dpId === "DP_MODE" })[0].value ? 'by distance' : 'by fuel'
                            let fuel_cat = fg.filter((i) => { return i.dpId === "DPA0140" })[0].value
                            let fuel_type = fg.filter((i) => { return i.dpId === "DPA0139" })[0].value
                            let fuel = fg.filter((i) => { return i.dpId === "DPA0141" })[0].value.name
                            let unit = fg.filter((i) => { return i.dpId === "DPA0339" })[0].value
                            let km = fg.filter((i) => { return i.dpId === "DPA0144" })[0].value
                            let fuel_filled = fg.filter((i) => { return i.dpId === "DPA0143" })[0].value
                            let fuel_cat_ind = mc_data.findIndex((k) => { return k.mode === mode })
                            if (mode === 'by distance') {

                                total = ef_by_distance.filter((k) => { return k.vehicle_category === fuel_cat.name && k.vehicle_type === fuel_type.name && k.fuel_type === fuel && k.unit === unit.name })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            } else {
                                total = ef_by_fuel.filter((k) => { return k.fuel.trim() === fuel.trim() })[0]['co2e_in_kg']

                                if (fuel_cat_ind === -1) {

                                    mc_data.push({ mode: mode, ghg: total })
                                } else {
                                    mc_data[fuel_cat_ind].ghg = mc_data[fuel_cat_ind].ghg + total

                                }
                            }

                        })
                        addedMonth.rps.forEach((fm) => {
                            let date = moment(fm, 'MM-YYYY').toDate()

                            mc_data.forEach((item) => {
                                let updateind = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })

                                if (updateind === -1) {
                                    let updateind2 = monthly_fg.findIndex((i) => { return i.year === moment(date).format('YYYY') && i.month === moment(date).format('MMM-YYYY') && i.dcf === report.dcf && i.site === report.site && i.mode === item.mode })
                                    if (updateind2 !== -1) {
                                        monthly_fg[updateind2].ghg = (item.ghg / addedMonth.count) + monthly_fg[updateind2].ghg
                                    } else {
                                        monthly_fg.push({ scope: 1, year: moment(date).format('YYYY'), month: moment(date).format('MMM-YYYY'), ghg: 0, dcf: report.dcf, site: report.site, ghg: (item.ghg / addedMonth.count), mode: item.mode })
                                    }




                                } else {

                                    monthly_fg[updateind].ghg = monthly_fg[updateind].ghg + (item.ghg / addedMonth.count)
                                }
                            })


                        })




                    }

                }


            })
            let pieChartOverall = [], pastYearData = [], totalList = []
            betweenYears.forEach((year) => {
                let pie = [], total = 0
                monthly_fg.filter((i) => { return (i.scope === 1 && i.year === year) }).forEach((item) => {
                    let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                    if (index === -1) {
                        pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                    } else {
                        pie[index].y = pie[index].y + item.ghg
                    }
                })
                monthly_fg.filter((i) => { return (i.scope === 2 && i.year === year) }).forEach((item) => {
                    let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                    if (index === -1) {
                        pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                    } else {
                        pie[index].y = pie[index].y + item.ghg
                    }
                })
                monthly_fg.filter((i) => { return (i.scope === 3 && i.year === year) }).forEach((item) => {
                    let index = pie.findIndex((j) => { return j.name === 'Scope ' + item.scope })
                    if (index === -1) {
                        pie.push({ name: 'Scope ' + item.scope, y: item.ghg })
                    } else {
                        pie[index].y = pie[index].y + item.ghg
                    }
                })

                let index1 = pie.findIndex((j) => { return j.name === 'Scope 1' })
                let index2 = pie.findIndex((j) => { return j.name === 'Scope 2' })
                let index3 = pie.findIndex((j) => { return j.name === 'Scope 3' })

                if (index1 === -1) {
                    pie.unshift({ name: 'Scope 1', y: 0 })
                }
                if (index2 === -1) {
                    pie.splice(1, 0, { name: 'Scope 2', y: 0 })

                }
                if (index3 === -1) {
                    pie.push({ name: 'Scope 3', y: 0 })
                }
                pie.forEach((i) => {
                    total += i.y
                })
                totalList.push(total)

                pieChartOverall.push({ ghg: total, data: pie, year, incomplete: getInCompleteStatus(groupArrayObject_3_Keys(monthly_fg.filter((k) => { return k.year === year }), 'site', 'dcf', 'month'), JSON.parse(JSON.stringify(betweenMonths)).filter((k) => { return k.split('-')[1] === year }), [...scope1Ids, ...scope2Ids, ...scope3Ids]) })

            })
            monthly_fg.forEach((items) => {
                if (betweenYears.includes(items.year)) {
                    let index = pastYearData.findIndex((l) => { return l.year === items.year && l.month === items.month && l.label === 'Scope ' + items.scope })
                    if (index === -1) {
                        pastYearData.push({ year: items.year, month: items.month, label: 'Scope ' + items.scope, y: items.ghg })
                    } else {
                        pastYearData[index].y = pastYearData[index].y + items.ghg
                    }
                }
            })
            let dummy_month = []
            pastYearData.forEach((i) => {
                let index1 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 1' })
                let index2 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 2' })
                let index3 = pastYearData.findIndex((k) => { return k.month === i.month && k.label === 'Scope 3' })
                if (index1 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 1', y: 0 })
                }
                if (index2 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 2', y: 0 })
                }
                if (index3 === -1) {
                    pastYearData.push({ year: i.year, month: i.month, label: 'Scope 3', y: 0 })
                }

            })

            if (pastYearData.length !== 0) {
                // pastYearData.push(...dummy_month)
                let gsdata = [], labels = []

                let sortedData = pastYearData.sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() })
                let groupedArray = groupArrayObject(sortedData, 'month')
                let groupedArray_ = groupArrayObject(sortedData, 'label')

                // Object.keys(groupedArray).forEach((key) => {
                //     labels.push(key)

                // })
                Object.keys(groupedArray_).forEach((key) => {
                    betweenMonths.forEach((l) => {
                        let dateFormate = moment(l, 'MM-YYYY').format('MMM-YYYY')

                        if (groupedArray_[key].findIndex((k) => { return k.month === dateFormate }) === -1) {
                            groupedArray_[key].push({ ...groupedArray_[key][0], month: dateFormate, year: dateFormate.split('-')[1], y: 0 })
                        }
                    })
                    gsdata.push(groupedArray_[key].sort((a, b) => { return moment(a.month, 'MMM-YYYY').toDate() - moment(b.month, 'MMM-YYYY').toDate() }));


                })

                showStackedBarChartWithoutDrill(gsdata, 'scope36m', { measure: " tCO" + "\u2082" + "e", labels: JSON.parse(JSON.stringify(gsdata))[0].map((k) => { return k.month }), label: ['Scope 1', 'Scope 2', 'Scope 3'], actual: monthly_fg }, 0)
            }

            pieChartOverall.forEach((i, j) => {

                if (i.ghg === 0) {
                    showNoDataFound('scope123' + (j + 1), "No Data to show in " + i.year);
                } else {

                    showPieChartNoDrill(i.data, 'scope123' + (j + 1), { name: i.year, total: i.ghg, radius: (i.ghg / Math.max.apply(Math, totalList)) * 100, overall: JSON.parse(JSON.stringify(pieChartOverall)).sort((a, b) => { return a.ghg - b.ghg }), incomplete: i.incomplete }, j)

                }

            })

        }



    }

    const fillupData = (data, betweenMonths) => {

        betweenMonths.forEach((k, ind) => {

            let index = data.findIndex((l) => { return l.x === months_[k.split('-')[0] - 1] })
            if (index === -1) {


                data.push({ x: months_[k.split('-')[0] - 1] + `'` + k.split('-')[1].substr(2, 3), y: null, z: ind })
            } else {

                data[index].x = months_[k.split('-')[0] - 1] + `'` + k.split('-')[1].substr(2, 3)
                data[index].z = ind
            }
        })

        return data.sort((a, b) => { return a.z - b.z })
    }
    const onFilterChanged = (obj, val) => {
        let loc = filter
        loc[obj] = val
        let ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]


        if (obj === 'country') {
            siteList.forEach((country) => {
                if (val.id === 0 || val.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        ccopt.push(city)
                        city.locationThrees.forEach((loc, ind) => {

                            locopt.push(loc)

                        })
                    })
                }
            })
            loc.city = { name: 'All', id: 0 }
            loc.location = { name: 'All', id: 0 }
            setCityOpt(ccopt)

            setLocOpt(locopt)
            renderGraph({ location: { a: val, b: { name: 'All', id: 0 }, c: { name: 'All', id: 0 } }, indicator: filter.indicator, timeline: filter.timeline, from: filter.from, to: filter.to })
        } else if (obj === 'city') {
            siteList.forEach((country) => {
                if (loc.country.id === 0 || loc.country.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        if (val.id === 0 || val.id === city.id) {
                            city.locationThrees.forEach((loc, ind) => {

                                locopt.push(loc)

                            })
                        }
                    })
                }
            })

            loc.location = { name: 'All', id: 0 }


            setLocOpt(locopt)
            renderGraph({ location: { a: filter.country, b: val, c: { name: 'All', id: 0 } }, indicator: filter.indicator, timeline: filter.timeline, from: filter.from, to: filter.to })

        } else if (obj === 'location') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: val }, indicator: filter.indicator, timeline: filter.timeline, from: filter.from, to: filter.to })

        } else if (obj === 'timeline') {

            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, indicator: filter.indicator, timeline: val, from: filter.from, to: filter.to })

        } else if (obj === 'indicator') {
            let indi = []

            indiopt.forEach((item) => {

                if (val.id === 0 || val.id === item.id) {
                    indi.push(item)
                }
            })
            setInidicators(indi)
            setTimeout(() => {
                renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, indicator: val, timeline: filter.timeline, from: filter.from, to: filter.to })
                forceUpdate()
            }, 1000)

        } else if (obj === 'from') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, indicator: filter.indicator, timeline: filter.timeline, from: val, to: filter.to })

        } else if (obj === 'to') {
            renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, indicator: filter.indicator, timeline: filter.timeline, from: filter.from, to: val })

        }
        setFilter(loc)
        forceUpdate()

    }
    const onApplicabilityChanged = (obj, val, goal, gindex) => {
        let loc = goal.filter, filterData = []
        loc[obj] = val
        let ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]
        let initiative = JSON.parse(JSON.stringify(gtaraw))
        if (obj === 'country') {
            siteList.forEach((country) => {
                if (val.id === 0 || val.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        ccopt.push(city)
                        city.locationThrees.forEach((loc, ind) => {

                            locopt.push(loc)

                        })
                    })
                }
            })

            initiative[gindex].newInitiatives.forEach((init) => {
                if (val.id === 0 || init.applicability.country.split('_')[0] === 'All' || parseInt(init.applicability.country.split('_')[0]) === val.id) {
                    filterData.push(init)
                }
            })
            goal.newInitiatives = filterData

            loc.city = { name: 'All', id: 0 }
            loc.location = { name: 'All', id: 0 }
            setCityOpt_(ccopt)

            setLocOpt_(locopt)

        } else if (obj === 'city') {
            let initiative = JSON.parse(JSON.stringify(gtaraw)), filterData = []
            siteList.forEach((country) => {
                if (loc.country.id === 0 || loc.country.id === country.id) {
                    country.locationTwos.forEach((city) => {
                        if (val.id === 0 || val.id === city.id) {
                            city.locationThrees.forEach((loc, ind) => {

                                locopt.push(loc)

                            })
                        }
                    })
                }
            })
            initiative[gindex].newInitiatives.forEach((init) => {
                if ((loc.country.id === 0 || init.applicability.country.split('_')[0] === 'All' || parseInt(init.applicability.country.split('_')[0]) === loc.country.id) && (val.id === 0 || init.applicability.city.split('_')[0] === 'All' || parseInt(init.applicability.city.split('_')[0]) === val.id)) {
                    filterData.push(init)
                }
            })
            goal.newInitiatives = filterData
            loc.location = { name: 'All', id: 0 }


            setLocOpt_(locopt)


        } else if (obj === 'location') {
            let initiative = JSON.parse(JSON.stringify(gtaraw)), filterData = []
            initiative[gindex].newInitiatives.forEach((init) => {
                if ((loc.country.id === 0 || init.applicability.country.split('_')[0] === 'All' || parseInt(init.applicability.country.split('_')[0]) === loc.country.id) && (loc.city.id === 0 || init.applicability.city.split('_')[0] === 'All' || parseInt(init.applicability.city.split('_')[0]) === loc.city.id) && (val.id === 0 || init.applicability.location.split('_')[0] === 'All' || parseInt(init.applicability.location.split('_')[0]) === val.id)) {
                    filterData.push(init)
                }
            })
            goal.newInitiatives = filterData

        }
        goal.filter = loc
        forceUpdate()

    }
    const detectSign = (number) => {
        if (number > 0) {
            return 'green';
        } else if (number < 0) {
            return "red";
        } else {
            return "green";
        }
    }
    const statusTemplate = (rowData) => {
        return (
            <><div style={{ margin: 10 }} className='ql-editor' dangerouslySetInnerHTML={{ __html: rowData.status[0][1] }} /></>
        )
    }
    const resTemplate = (rowData) => {
        return (
            <>{rowData.status[0][2]}</>
        )
    }
    const initiativeTemplate = (rowData) => {
        return (

            <>{rowData.initiatives[0]}</>
        )
    }
    const initiativeDateTemplate = (rowData) => {
        return (

            <>{rowData.initiatives[2] === undefined ? 'Date Not Set' : moment.utc(rowData.initiatives[2]).local().format('DD-MMM-YYYY')}</>
        )
    }
    const viewHistory = (data) => {
        setHistoryDialog(true);
        setHistoryData(data)
    }
    const statusViewTemplate = (rowData) => {

        return (<>
            {rowData.status.length !== 1 ?
                <>
                    <i className="material-icons" style={{ justifyContent: 'center', display: 'flex', marginTop: 10, color: '#003153' }}><label onClick={() => {
                        viewHistory(rowData.status.sort(function (left, right) {
                            return moment.utc(left[0]).diff(moment.utc(right[0]))
                        }).reverse())
                    }}>visibility</label></i>
                </>
                :
                <div style={{ justifyContent: 'center', display: 'flex', marginTop: 10, color: 'black' }}>NA</div>
            }
        </>
        )
    }
    const indicatorTemplate = (rowData) => {
        let light = rowData.status[0][3]
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {light === 1 ? (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "red",
                            fontSize: 20,
                            borderRadius: 12,
                            border: "1px solid black",
                        }}
                        className="pi pi-circle-fill"
                    />
                ) : light === 2 ? (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "yellow",
                            fontSize: 20,
                            borderRadius: 12,
                            border: "1px solid black",
                        }}
                        className="pi pi-circle-fill"
                    />
                ) : (
                    <i
                        style={{
                            transform: "rotate(0deg)",
                            color: "green",
                            fontSize: 20,
                            borderRadius: 12,
                            border: "1px solid black",
                        }}
                        className="pi pi-circle-fill"
                    />
                )}
            </div>
        );
    };
    const goalSelected = (item_, index) => {
        let loc = gta;
        let date = moment(moment.utc()).toDate().getUTCFullYear()
        let copt = [{ name: "All", id: 0 }], ccopt = [{ name: "All", id: 0 }], locopt = [{ name: "All", id: 0 }]
        siteList.forEach((country) => {
            copt.push(country)
            country.locationTwos.forEach((city) => {
                ccopt.push(city)
                city.locationThrees.forEach((loc, ind) => {

                    locopt.push(loc)

                })
            })
        })

        setCityOpt_(ccopt)

        setLocOpt_(locopt)
        loc.forEach((i, ind) => {
            i.filter = {
                "country": {
                    "name": "All",
                    "id": 0
                },
                "city": {
                    "name": "All",
                    "id": 0
                },
                "location": {
                    "name": "All",
                    "id": 0
                }
            }
            if (ind === index) {
                if (i.selected === 0) {
                    i.selected = 1
                } else {
                    i.selected = 0
                }
            } else {
                i.selected = 0
            }
        })
        let initiative = JSON.parse(JSON.stringify(gtaraw))
        loc.newInitiatives = initiative.newInitiatives
        setTimeout(() => {
            if (item_.selected === 1 && index === 0) {

                renderGraph_Past({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: null, indicator: { id: 0 }, timeline: filter.timeline, from: moment(date - 3, 'YYYY').local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate() })
                renderDataTillDate({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [moment(date - 2, 'YYYY').local().toDate(), moment(moment.utc()).startOf('month').local().toDate()], from: moment(selector.information.startdate).local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate() })
                renderGraph({ location: { a: filter.country, b: filter.city, c: filter.location }, year: filter.year, month: null, indicator: { id: 0 }, timeline: filter.timeline, from: filter.from, to: filter.to }, 1)
                renderPieGraph({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [moment(date - 2, 'YYYY').local().toDate(), moment(moment.utc()).startOf('month').local().toDate()], from: moment(date - 3, 'YYYY').local().toDate(), to: moment(moment.utc()).startOf('month').local().toDate() })

                renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(selector.information.startdate).startOf('month').toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 4, show: null })

            }

        }, 1000)

        setGTA(loc)
        forceUpdate()
    }
    const updateTAGReport = (ids) => {
        setSFrameworkList(ids)
        setTimeout(() => {
            ids = [ids]
            let ql_indicator = overallmetric.filter((i) => { return Array.isArray(i.data1) && i.data1[0].type !== undefined && i.data1[0].type === 1 && !i.data1[0].general })
            let all_indicator = overallmetric.filter((i) => { return Array.isArray(i.data1) && i.data1[0].general !== undefined && !i.data1[0].general })
            let ass_indicator = indicators.filter((i) => { return Array.isArray(i.data1) && i.data1[0].general !== undefined && !i.data1[0].general })
            let a = 0, b = 0, c = 0, ta = 0, tb = 0, tc = 0, rejectIds = [], allowedIds = []
            let tags = JSON.parse(JSON.stringify(ids)).map((fw) => { return frameworklist.filter((k) => { return k.id === fw })[0].title.trim().toLowerCase() })
            let frame = JSON.parse(JSON.stringify(ids)).map((fw) => { return frameworklist.filter((k) => { return k.id === fw })[0] })
            let rfResponse = JSON.parse(JSON.stringify(rfresponse))

            rfResponse.forEach((i) => {
                let index = ql_indicator.findIndex((j) => { return j.id === i.indicatorId })

                if (index !== -1) {
                    i.tags1 = ql_indicator[index].data1[0].tags1
                    i.tags2 = ql_indicator[index].data1[0].tags2
                    i.tags3 = ql_indicator[index].data1[0].tags3
                }
            })
            const groupedResponse = groupArrayObject_4_Keys(rfResponse, 'rfid', 'indicatorId', 'categoryId', 'topicId')
            Object.keys(groupedResponse).forEach((key) => {
                let lastResponse = groupedResponse[key].sort((a, b) => { return b.id - a.id })[0]
                let index = ql_indicator.findIndex((j) => { return j.id === lastResponse.indicatorId })
                if (index !== -1) {

                    if (lastResponse.type === 0) {
                        !allowedIds.includes(lastResponse.indicatorId) && allowedIds.push(lastResponse.indicatorId)
                        const matchedTag1 = lastResponse.tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                        const matchedTag2 = lastResponse.tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                        const matchedTag3 = lastResponse.tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));

                        if (matchedTag1.length !== 0) {

                            a = a + (lastResponse.reject === 5 ? 1 : lastResponse.reject === 4 ? 0.75 : lastResponse.reject === 3 ? 0.5 : lastResponse.reject === 2 ? 0.25 : 0)

                        }
                        if (matchedTag2.length !== 0) {

                            b = b + (lastResponse.reject === 5 ? 1 : lastResponse.reject === 4 ? 0.75 : lastResponse.reject === 3 ? 0.5 : lastResponse.reject === 2 ? 0.25 : 0)
                        }
                        if (matchedTag3.length !== 0) {

                            c = c + (lastResponse.reject === 5 ? 1 : lastResponse.reject === 4 ? 0.75 : lastResponse.reject === 3 ? 0.5 : lastResponse.reject === 2 ? 0.25 : 0)
                        }
                    } else {
                        !rejectIds.includes(lastResponse.indicatorId) && rejectIds.push(lastResponse.indicatorId)
                    }
                }
            })
            ql_indicator.forEach((metric) => {
                if (!rejectIds.includes(metric.id)) {

                    const matchedTag1 = metric.data1[0].tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    const matchedTag2 = metric.data1[0].tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    const matchedTag3 = metric.data1[0].tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));

                    ta = ta + matchedTag1.length
                    tb = tb + matchedTag2.length
                    tc = tc + matchedTag3.length
                }
            })
            console.log(a, ta, b, tb, c, tc)
            if (a === 0 && ta === 0) {
                showNoDataFound('Ql1', 'N/A')
            } else {
                if (a === 0) {
                    renderPercentageGraph('Ql1', 'Must Have', 0, 1)
                } else {
                    renderPercentageGraph('Ql1', 'Must Have', (a / ta) * 100)
                }

            }
            if (b === 0 && tb === 0) {
                showNoDataFound('Ql2', 'N/A')
            } else {
                if (b === 0) {
                    renderPercentageGraph('Ql2', 'Progressive', 0)
                } else {
                    renderPercentageGraph('Ql2', 'Progressive', (b / tb) * 100)
                }

            }
            if (c === 0 && tc === 0) {
                showNoDataFound('Ql3', 'N/A')
            } else {
                if (c === 0) {
                    renderPercentageGraph('Ql3', 'Advanced', 0)
                } else {
                    renderPercentageGraph('Ql3', 'Advanced', (c / tc) * 100)
                }

            }
            let a1 = 0, ta1 = 0
            completeDCFAssigned.forEach((i) => {
                if (i.id && i.type === 0) {

                    const matchedTag1 = i.tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    const matchedTag2 = i.tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    const matchedTag3 = i.tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));

                    if (matchedTag1.length !== 0 || matchedTag2.length !== 0 || matchedTag3.length !== 0) {
                        a1 = a1 + 1
                    }

                } else {
                    const matchedTag1 = i.tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    const matchedTag2 = i.tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    const matchedTag3 = i.tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                    if (matchedTag1.length !== 0 || matchedTag2.length !== 0 || matchedTag3.length !== 0) {
                        ta1 = ta1 + 1
                    }


                }
            })
            let resultPercent = a1 / ((ta1 + a1) - (completeDCFAssigned.filter((i) => { return i.id && i.type === 1 }).length)) * 100


            if (a1 === 0 && ta1 === 0) {
                showNoDataFound('Q1', 'N/A')
            } else {

                renderPercentageGraph('Q1', '', resultPercent)


            }
            let category_ = [], totalTags = [{ title: 'Must Have', count: 0, color: '#e9e9e9' }, { title: 'Progressive', count: 0, color: '#a9a9a9' }, { title: 'Advanced', count: 0, color: '#808080' }]
            ass_indicator.forEach((i) => {
                const matchedTag1 = i.data1[0].tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                const matchedTag2 = i.data1[0].tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                const matchedTag3 = i.data1[0].tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                totalTags[0].count = matchedTag1.length + totalTags[0].count
                totalTags[1].count = matchedTag2.length + totalTags[1].count
                totalTags[2].count = matchedTag3.length + totalTags[2].count
                if (matchedTag1.length !== 0 || matchedTag2.length !== 0 || matchedTag3.length !== 0) {
                    let catIndex = category_.findIndex((k) => { return k.id === getCatIndex(i.id).id })
                    if (catIndex === -1) {
                        category_.push({ id: getCatIndex(i.id).id, count: 1, title: getCatIndex(i.id).title })
                    } else {
                        category_[catIndex].count = category_[catIndex].count + 1
                    }
                }
            })
            category.forEach((cat) => {
                let catInd = category_.findIndex((i) => { return i.id === cat.id })
                if (catInd === -1) {
                    category_.push({ id: cat.id, title: cat.title, count: 0, order: cat.order, color: getESGColor(cat.id) })
                } else {
                    category_[catInd]['order'] = cat.order
                    category_[catInd]['color'] = getESGColor(cat.id)
                }
            })

            showPieChartTAG('pie1', category_.sort((a, b) => { return a.order - b.order }), 2)
            // showPieChartTAG_Legend('pie1_1', category_.sort((a, b) => { return a.order - b.order }))
            showPieChartTAG('pie2', totalTags, 1)
            let b1 = 0, tb1 = 0
            irdcf.forEach((i) => {
                const matchedTag1 = i.tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                const matchedTag2 = i.tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                const matchedTag3 = i.tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                if (matchedTag1.length !== 0 || matchedTag2.length !== 0 || matchedTag3.length !== 0) {
                    b1 = i.count + b1
                    tb1 = i.total + tb1
                }
            })

            if (b1 === 0 && tb1 === 0) {
                showNoDataFound('Q2', 'N/A')
            } else {
                renderPercentageGraph('Q2', '', b1 / tb1)
            }
            let filteredIndicator = []
            ass_indicator.forEach((i) => {
                const matchedTag1 = i.data1[0].tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                const matchedTag2 = i.data1[0].tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                const matchedTag3 = i.data1[0].tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                totalTags[0].count = matchedTag1.length + totalTags[0].count
                totalTags[1].count = matchedTag2.length + totalTags[1].count
                totalTags[2].count = matchedTag3.length + totalTags[2].count
                if (matchedTag1.length !== 0 || matchedTag2.length !== 0 || matchedTag3.length !== 0) {
                    let index__ = filteredIndicator.findIndex((k) => { return k.id === i.topic_id })
                    if (index__ === -1) {
                        filteredIndicator.push({ id: i.topic_id, title: i.topic_title, data: [i] })
                    } else {

                        filteredIndicator[index__].data.push(i)
                    }
                }
            })
            console.log(filteredIndicator)
            setFrameWorkIndicator(filteredIndicator)
            forceUpdate()
        }, 200)

    }

    const getTAGColor = (ind) => {
        if (ind == 0) {
            return
        } else if (ind === 1) {
            return
        } else {
            return
        }
    }
    const getESGColor = (id) => {
        if (id === 1) {
            return '#72C3B2'
        } else if (id === 13) {
            return ' #ED5623'
        } else if (id === 14) {
            return '#315874'
        } else if (id === 15) {
            return '#C5C8CB'
        } else if (id === 16) {
            return '#00B0ED'
        } else {
            return '545555'
        }
    }
    const getCatIndex = (id) => {
        let catId = null
        category.forEach((cat) => {
            if (cat.newTopics) {
                cat.newTopics.forEach((top) => {
                    if (top.newMetrics) {
                        top.newMetrics.forEach((met) => {

                            if (met.id === id) {
                                catId = { id: cat.id, title: cat.title }
                            }
                        })
                    }
                })
            }
        })
        return catId
    }
    const expandAll = () => {
        let _expandedRows = {};

        fwindicator.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">

                <DataTable className="frozen-header-table" value={data.data} responsiveLayout="scroll">
                    <Column field="title" header="Indicator" ></Column>
                    <Column field="" body={() => { return <>No Data</> }} header="2023" />
                    <Column field="" body={() => { return <span style={{ color: 'grey' }}>No Data</span> }} header="2022" headerStyle={{ color: 'grey' }} />
                    <Column field="" body={() => { return <span style={{ color: 'grey' }}>No Data</span> }} header="2021" headerStyle={{ color: 'grey' }} />

                </DataTable>
            </div>
        );
    }
    const showPercentCanvas = (id) => {
        return (
            <canvas id={id} style={{ width: '100%' }} />
        )
    }
    const header_ = (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} text />
        </div>
    );
    return (
        <div className="col-12">


            <div className="grid">
                {selector.role === "clientadmin" ? (
                    <div className="col-12">
                        <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>KEY PERFORMANCE INDICATORS & ACTION TRACKER</h4>
                        <div className="col-12 xl:col-12" id="divsize">

                        </div>
                        <>



                            {gta.length !== 0 ?
                                <div className="col-12 ">
                                    {gta.map((goal, g) => {
                                        if (true) {
                                            return (
                                                <div style={{ marginBottom: 10 }}>
                                                    {
                                                        <>
                                                            <div
                                                                onClick={() => { goalSelected(goal, g) }}
                                                                style={{
                                                                    background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",

                                                                    padding: 10,
                                                                    color: "white",
                                                                    borderRadius: 5
                                                                }}
                                                            >
                                                                {goal.title}
                                                            </div>
                                                            {goal.selected === 1 &&
                                                                <div>
                                                                    {goal.newTargetsTwos !== undefined &&
                                                                        <div className="col-12 p-card" style={{ flexDirection: 'column', display: 'flex', marginTop: 10, marginBottom: 10 }}>
                                                                            <h5>Targets :</h5>
                                                                            <div style={{ marginLeft: 20 }}>
                                                                                {goal.newTargetsTwos.map((target, t) => {
                                                                                    if (t === 0) {
                                                                                        return (
                                                                                            <div style={{ padding: 5 }}>
                                                                                                <div style={{ padding: 0 }} className='ql-editor' dangerouslySetInnerHTML={{ __html: target.title }} />

                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="col-12  p-card" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 30 }}>
                                                                        <h5>SDG Alignment</h5>
                                                                        <div className="grid" style={{ justifyContent: 'space-evenly' }}>
                                                                            {goal.sdg.map((sdg) => {
                                                                                return (
                                                                                    <img src={SDG_list[sdg - 1].url} width={'100px'} height={'100px'} style={{ margin: 10, borderRadius: 10 }} />
                                                                                )
                                                                            })

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {g !== 0 && goal.newIndicatorTwos !== undefined &&
                                                                        goal.newIndicatorTwos.map((indi) => {
                                                                            let index = indicator.findIndex((i) => { return i.id === indi.indicator })
                                                                            if (index !== -1) {
                                                                                return (

                                                                                    <div className="p-card" style={{ marginTop: 10 }}>
                                                                                        <h4 style={{ padding: 10 }}>{indicator[index].title}</h4>

                                                                                    </div>

                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    {response.length !== 0 && g == 0 && <div>


                                                                        <h4>Indicator 1: Absolute Scope 1+2 Emissions</h4>
                                                                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-around', padding: 10 }}>
                                                                            <div className="col-6" style={{ margin: 10, padding: 10, backgroundColor: 'white', borderRadius: 10, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
                                                                                <h5 >YTD  Emissions (Scope 1 + Scope 2)</h5>
                                                                                <label style={{ fontSize: 50 }}>{ytd.scope12} <span style={{ fontSize: 20 }}>{" tCO" + "\u2082" + "e"}</span>  </label>
                                                                                <div className="grid col-12">
                                                                                    <div className="col-6" style={{
                                                                                        alignItems: 'center',
                                                                                        display: 'flex',

                                                                                    }}>
                                                                                        <img src={require('../../assets/images/aim.png').default} width={35} />
                                                                                        <label style={{ marginLeft: 10, color: 'grey' }}>No Target Set</label>
                                                                                    </div>
                                                                                    <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        <div style={{
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',

                                                                                        }}>
                                                                                            <img src={require('../../assets/images/calendar.png').default} style={{ filter: 'contrast(0.1)' }} width={35} />
                                                                                            <h4 style={{ marginLeft: 10, color: detectSign(ytd.scope12_) }}> {detectSign(ytd.scope12_) === 'green' ? '⭣' : '⭡'} {Math.abs(ytd.scope12_).toFixed(2)} %</h4>
                                                                                        </div>
                                                                                        <div><label >YoY as of {moment(moment.utc()).subtract(1, 'month').format('MMMM-YYYY')} </label></div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className="col-6" style={{ margin: 10, padding: 10, backgroundColor: 'white', borderRadius: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                                                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.a === 0 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.a === 0 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(3, 'month').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 0, show: false }) }}>3M</label>
                                                                                    <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.a === 1 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.a === 1 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(6, 'months').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 1, show: false }) }}>6M</label>   <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.a === 2 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.a === 2 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(12, 'months').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 2, show: false }) }}>1Y</label>   <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.a === 3 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.a === 3 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(36, 'months').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 3, show: false }) }}>3Y</label>  <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.a === 4 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.a === 4 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(selector.information.startdate).startOf('month').toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 4, show: false }) }} >Max</label>
                                                                                </div>
                                                                                <div className="col-6" style={{ width: '100%' }}>
                                                                                    <canvas width={'100%'} height={300} id="indicator1" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <h4>Indicator 2: Absolute Scope 3 Emissions</h4>
                                                                        <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-around', padding: 10 }}>

                                                                            <div className="col-6" style={{ margin: 10, padding: 10, backgroundColor: 'white', borderRadius: 10, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
                                                                                <h5>YTD Emissions (Scope 3)</h5>
                                                                                <label style={{ fontSize: 50 }}>{ytd.scope3}<span style={{ fontSize: 20 }}>{" tCO" + "\u2082" + "e"}</span>  </label>
                                                                                <div className="grid col-12">
                                                                                    <div className="col-6" style={{
                                                                                        alignItems: 'center',
                                                                                        display: 'flex',

                                                                                    }}>
                                                                                        <img src={require('../../assets/images/aim.png').default} width={35} />
                                                                                        <label style={{ color: 'grey', marginLeft: 10 }}>No Target Set</label>
                                                                                    </div>
                                                                                    <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        <div style={{
                                                                                            alignItems: 'center',
                                                                                            display: 'flex',

                                                                                        }}>
                                                                                            <img src={require('../../assets/images/calendar.png').default} style={{ filter: 'contrast(0.1)' }} width={35} />
                                                                                            <h4 style={{ color: detectSign(ytd.scope3_), marginLeft: 10 }}> {detectSign(ytd.scope3_) === 'green' ? '⭣' : '⭡'} {Math.abs(ytd.scope3_).toFixed(2)} %</h4>
                                                                                        </div>
                                                                                        <div><label >YoY as of {moment(moment.utc()).subtract(1, 'month').format('MMMM-YYYY')} </label></div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div className="col-6" style={{ margin: 10, padding: 10, backgroundColor: 'white', borderRadius: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                                                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                                                                    <label style={{ color: gfilter.b === 0 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.b === 0 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(3, 'month').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 0, show: true }) }}>3M</label>
                                                                                    <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.b === 1 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.b === 1 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(6, 'months').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 1, show: true }) }}>6M</label>   <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.b === 2 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.b === 2 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(12, 'months').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 2, show: true }) }}>1Y</label>   <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.b === 3 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.b === 3 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(moment.utc()).startOf('month').subtract(36, 'months').local().toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 3, show: true }) }}>3Y</label>  <hr class="vertical-line" />
                                                                                    <label style={{ cursor: 'pointer', color: gfilter.b === 4 ? 'white' : 'black', padding: 2, borderRadius: 5, backgroundColor: gfilter.b === 4 ? 'gray' : 'white' }} onClick={() => { renderGraphByTimeLine({ location: { a: { id: 0 }, b: { id: 0 }, c: { id: 0 } }, month: null, indicator: { id: 0 }, timeline: [], from: moment(selector.information.startdate).startOf('month').toDate(), to: moment(moment.utc()).startOf('month').subtract(1, 'month').local().toDate(), id: 4, show: true }) }} >Max</label>
                                                                                </div>
                                                                                <div className="col-6" style={{ width: '100%' }}>
                                                                                    <canvas width={'100%'} height={300} id="indicator2" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 md:col-12"
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                background: '#005284',
                                                                                color: 'white', position: 'relative',
                                                                                borderRadius: '10px', marginBottom: 15
                                                                            }}
                                                                            onClick={() => { goal.analysis_view = (goal.analysis_view === undefined || goal.analysis_view === 1) ? 0 : 1; goal.analysis_view === 0 && renderAnalyticalGraph(); forceUpdate() }}
                                                                        >
                                                                            <text>Interactive Visualizer</text>
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                right: '10px', display: 'flex',
                                                                                top: '3px'
                                                                            }}>
                                                                                {(goal.analysis_view === 1 || goal.analysis_view === undefined) ?
                                                                                    <>

                                                                                        <i className="material-icons">navigate_next</i> </>
                                                                                    :
                                                                                    <>

                                                                                        <i className="material-icons">expand_less</i>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {goal.analysis_view === 0 && <div className="card">


                                                                            <div className="col-12" style={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                ★ - Incomplete Data
                                                                                {/* ✹ */}
                                                                            </div >
                                                                            <h4>1. YoY trend of total GHG Emissions </h4>

                                                                            <div className="grid" >
                                                                                <div className="col-3">

                                                                                    <canvas id='scope1231' />
                                                                                </div>
                                                                                <div className="col-3" >

                                                                                    <canvas id='scope1232' />
                                                                                </div>
                                                                                <div className="col-3">

                                                                                    <canvas id='scope1233' />
                                                                                </div>
                                                                                <div className="col-3">

                                                                                    <canvas id='scope1234' />
                                                                                </div>
                                                                            </div>
                                                                            <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                            <h4>2. Monthly historical trend of total GHG emissions </h4>

                                                                            <div className="grid">

                                                                                <div className="col-12">

                                                                                    <canvas width={'100%'} height={350} id='scope36m' />
                                                                                </div>
                                                                            </div>


                                                                            <div >
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                                <h4>3a. Monthly trend of Scope 1 GHG emissions</h4>
                                                                                <div className="col-12">

                                                                                    <canvas width={'100%'} height={350} id='scope1' />

                                                                                </div>
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                                <h4>3b. Breakdown of Scope 1 GHG emissions by sources </h4>
                                                                                <div className="col-12">

                                                                                    {/* <label>Fugitive Emission</label> */}
                                                                                    <canvas width={'100%'} height={350} id='scope111' />
                                                                                </div>
                                                                                <label style={{ display: 'flex', justifyContent: 'flex-end' }}><span style={{ color: 'red' }}>Note </span> : &nbsp;Click on a bar to see month-wise breakdown.</label>
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                                <h4>4a. Monthly trend of Scope 2 GHG emissions</h4>
                                                                                <div className="col-12">

                                                                                    <canvas width={'100%'} height={350} id='scope2' />
                                                                                </div>
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                                <h4>4b. Breakdown of Scope 2 GHG emissions by sources </h4>
                                                                                <div className="col-12">

                                                                                    {/* <label>Fugitive Emission</label> */}
                                                                                    <canvas width={'100%'} height={350} id='scope222' />
                                                                                </div>
                                                                                <label style={{ display: 'flex', justifyContent: 'flex-end' }}><span style={{ color: 'red' }}>Note </span> : &nbsp;Click on a bar to see month-wise breakdown.</label>

                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                                <h4>5a. Monthly trend of Scope 3 GHG emissions</h4>
                                                                                <div className="col-12">

                                                                                    <canvas width={'100%'} height={350} id='scope3' />

                                                                                </div>
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                                <h4>5b. Breakdown of Scope 3 GHG emissions by sources </h4>
                                                                                <div className="col-12">

                                                                                    {/* <label>Fugitive Emission</label> */}
                                                                                    <canvas width={'100%'} height={350} id='scope333' />
                                                                                </div>
                                                                                <label style={{ display: 'flex', justifyContent: 'flex-end' }}><span style={{ color: 'red' }}>Note </span> : &nbsp;Click on a bar to see month-wise breakdown.</label>

                                                                            </div>

                                                                            <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />
                                                                            <h4>Comparison of GHG emissions between time periods</h4>
                                                                            <div className="col-12 grid">
                                                                                {/* <div className="col-4"  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                <label className="col-2" style={{ background: '#005284', textAlign: 'center', color: 'white', borderRadius: '10px', padding: '5px' }} >Q1</label>
                                                                                <label className="col-2" style={{ background: '#005284', textAlign: 'center', color: 'white', borderRadius: '10px', padding: '5px' }} >Q2</label>
                                                                                <label className="col-2" style={{ background: '#005284', textAlign: 'center', color: 'white', borderRadius: '10px', padding: '5px' }} >Q3</label>
                                                                                <label className="col-2" style={{ background: '#005284', textAlign: 'center', color: 'white', borderRadius: '10px', padding: '5px' }} >Q4</label>
                                                                                <label className="col-2" style={{ background: '#005284', textAlign: 'center', color: 'white', borderRadius: '10px', padding: '5px' }} >FY</label>
                                                                            </div> */}
                                                                                <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                    <div className="col-5" style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                                                        <label>Select Year </label>
                                                                                        <Dropdown options={goal.fy1list} value={goal.fy1} style={{ width: '90%' }} onChange={(e) => { updateComparisionGraph(e.value, 'fy1') }} optionLabel={'name'} placeholder="Select FY1" />
                                                                                    </div>
                                                                                    <div className="col-5" style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                                                        <label>Select Year </label>
                                                                                        <Dropdown options={goal.fy2list} value={goal.fy2} style={{ width: '90%' }} onChange={(e) => { updateComparisionGraph(e.value, 'fy2') }} optionLabel={'name'} placeholder="Select FY2" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">

                                                                                {/* <label>Fugitive Emission</label> */}
                                                                                <canvas width={'100%'} height={350} id='scope123c' />
                                                                            </div>
                                                                            <div hidden className="col-12  sticky-div">
                                                                                <div className="card">
                                                                                    <div
                                                                                        style={{
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        <h5> Filter Section </h5>
                                                                                    </div>
                                                                                    <div className="grid">
                                                                                        <div
                                                                                            style={{
                                                                                                width: "25%",
                                                                                            }}
                                                                                        >
                                                                                            <h6> Country </h6>
                                                                                            <Dropdown
                                                                                                style={{
                                                                                                    width: "90%",
                                                                                                }}
                                                                                                value={filter.country}
                                                                                                onChange={(e) => onFilterChanged("country", e.value)}
                                                                                                options={countopt}
                                                                                                optionLabel="name"
                                                                                                placeholder="Select"
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                width: "25%",
                                                                                            }}
                                                                                        >
                                                                                            <h6> City </h6>
                                                                                            <Dropdown
                                                                                                style={{
                                                                                                    width: "90%",
                                                                                                }}
                                                                                                value={filter.city}
                                                                                                onChange={(e) => onFilterChanged("city", e.value)}
                                                                                                options={cityopt}
                                                                                                optionLabel="name"
                                                                                                placeholder="Select"
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                width: "25%",
                                                                                            }}
                                                                                        >
                                                                                            <h6> Location </h6>
                                                                                            <Dropdown
                                                                                                style={{
                                                                                                    width: "90%",
                                                                                                }}
                                                                                                value={filter.location}
                                                                                                onChange={(e) => onFilterChanged("location", e.value)}
                                                                                                options={locopt}
                                                                                                optionLabel="name"
                                                                                                placeholder="Select"
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                width: "12.5%",
                                                                                            }}
                                                                                        >
                                                                                            <h6>  From </h6>
                                                                                            <Calendar id="range" style={{ width: '70%' }} value={filter.from} onChange={(e) => onFilterChanged('from', e.value)} view={'month'} dateFormat={' mm-yy'} />

                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                width: "12.5%",
                                                                                            }}
                                                                                        >
                                                                                            <h6>  To </h6>
                                                                                            <Calendar id="range" style={{ width: '70%' }} value={filter.to} onChange={(e) => onFilterChanged('to', e.value)} view={'month'} dateFormat={' mm-yy'} />

                                                                                        </div>
                                                                                        {/* <div
                                                    style={{
                                                        width: "25%",
                                                    }}
                                                >
                                                    <h6> Timeframe </h6>

                                                    <Calendar value={filter.timeline} onChange={(e) => onFilterChanged("timeline", e.value)} selectionMode="range" dateFormat='mm/yy' view='month' />

                                                </div> */}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div hidden >
                                                                                <div
                                                                                    className="col-12"
                                                                                    style={{
                                                                                        // background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                                                        padding: 10,


                                                                                        borderRadius: 5,
                                                                                    }}
                                                                                >
                                                                                    <h4> Scope 1 Emission Breakup</h4>
                                                                                </div>
                                                                                <div className="grid">
                                                                                    <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                                                        ★ - Incomplete Data
                                                                                    </div >
                                                                                    <div className="col-6">
                                                                                        {/* <label>Fugitive Emission</label> */}
                                                                                        <canvas id='scope111' />
                                                                                    </div>

                                                                                </div>
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />

                                                                                <div
                                                                                    className="col-12"
                                                                                    style={{
                                                                                        // background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                                                        padding: 10,

                                                                                        // color: "white",
                                                                                        borderRadius: 5,
                                                                                    }}
                                                                                >
                                                                                    <h4>Scope 2 Emission Breakup</h4>
                                                                                </div>
                                                                                <div className="grid">
                                                                                    <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                                                        ★ - Incomplete Data
                                                                                    </div >
                                                                                    <div className="col-6">

                                                                                        <canvas id='scope222' />
                                                                                    </div>

                                                                                </div>
                                                                                <hr className="col-12" style={{ borderTop: 'solid gray', padding: 0 }} />

                                                                                <div
                                                                                    className="col-12"
                                                                                    style={{
                                                                                        // background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                                                        padding: 10,

                                                                                        // color: "white",
                                                                                        borderRadius: 5,
                                                                                    }}
                                                                                >
                                                                                    <h4>Scope 3 Emission Breakup</h4>
                                                                                </div>
                                                                                <div className="grid">

                                                                                    <div className="col-6">
                                                                                        {/* <label>Fugitive Emission</label> */}
                                                                                        <canvas id='scope333' />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                    }
                                                                    {goal.newInitiatives !== undefined && response.length !== 0 &&

                                                                        <>
                                                                            <div
                                                                                className="col-12 md:col-12"
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    background: '#013153',
                                                                                    color: 'white', position: 'relative',
                                                                                    borderRadius: '10px', marginBottom: 15
                                                                                }}
                                                                                onClick={() => { goal.action_view = (goal.action_view === 1 || goal.action_view === undefined) ? 0 : 1; forceUpdate() }}
                                                                            >
                                                                                <text>Action Tracker</text>
                                                                                <div style={{
                                                                                    position: 'absolute',
                                                                                    right: '10px', display: 'flex',
                                                                                    top: '3px'
                                                                                }}>
                                                                                    {(goal.action_view === 1 || goal.action_view === undefined) ?
                                                                                        <>

                                                                                            <i className="material-icons">navigate_next</i> </>
                                                                                        :
                                                                                        <>

                                                                                            <i className="material-icons">expand_less</i>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {goal.action_view === 0 && <div>
                                                                                <div className="col-12" >
                                                                                    <div className="card">
                                                                                        <div
                                                                                            style={{
                                                                                                marginBottom: 20, display: 'flex', justifyContent: 'flex-start'
                                                                                            }}
                                                                                        >
                                                                                            <h5>Filter by location</h5>
                                                                                        </div>
                                                                                        <div className="grid">
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "33.33%",
                                                                                                }}
                                                                                            >
                                                                                                <h6> Country </h6>
                                                                                                <Dropdown
                                                                                                    style={{
                                                                                                        width: "90%",
                                                                                                    }}
                                                                                                    value={goal.filter.country}
                                                                                                    onChange={(e) => onApplicabilityChanged("country", e.value, goal, g)}
                                                                                                    options={countopt}
                                                                                                    optionLabel="name"
                                                                                                    placeholder="Select"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "33.33%",
                                                                                                }}
                                                                                            >
                                                                                                <h6> City </h6>
                                                                                                <Dropdown
                                                                                                    style={{
                                                                                                        width: "90%",
                                                                                                    }}
                                                                                                    value={goal.filter.city}
                                                                                                    onChange={(e) => onApplicabilityChanged("city", e.value, goal, g)}
                                                                                                    options={cityopt_}
                                                                                                    optionLabel="name"
                                                                                                    placeholder="Select"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "33.33%",
                                                                                                }}
                                                                                            >
                                                                                                <h6> Site </h6>
                                                                                                <Dropdown
                                                                                                    style={{
                                                                                                        width: "90%",
                                                                                                    }}
                                                                                                    value={goal.filter.location}
                                                                                                    onChange={(e) => onApplicabilityChanged("location", e.value, goal, g)}
                                                                                                    options={locopt_}
                                                                                                    optionLabel="name"
                                                                                                    placeholder="Select"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <DataTable

                                                                                    value={goal.newInitiatives}
                                                                                    scrollable
                                                                                    style={{ margin: 10 }}
                                                                                    showGridlines
                                                                                    responsiveLayout="scroll"
                                                                                >

                                                                                    <Column body={initiativeTemplate} header="Identified Action">

                                                                                    </Column>

                                                                                    <Column body={initiativeDateTemplate} header="Target Date">

                                                                                    </Column>
                                                                                    <Column field="status" body={statusTemplate} header="Status">

                                                                                    </Column>

                                                                                    <Column body={resTemplate} header="Responsibility">

                                                                                    </Column>
                                                                                    <Column field="indicator" body={indicatorTemplate} header="Indicator">

                                                                                    </Column>
                                                                                    <Column field="indicator" body={statusViewTemplate} header="Status History">

                                                                                    </Column>
                                                                                </DataTable>
                                                                            </div>
                                                                            }
                                                                        </>


                                                                    }

                                                                </div>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                            )
                                        }
                                    })

                                    }
                                </div>
                                :
                                <div className="col-12 card">Goals not configured yet</div>
                            }
                            {response.length !== 0 && <>

                                <div className="col-12 " hidden>
                                    <div className="col-12 " style={{ display: 'flex', justifyContent: 'space-around', padding: 10 }}>
                                        <div className="col-6" style={{ margin: 10, padding: 10, backgroundColor: 'white', borderRadius: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                                            <h5 >YTD  Emissions (Scope 1 + Scope 2)</h5>
                                            <h4  >{ytd.scope12} {" tCO" + "\u2082" + "e"} </h4>
                                            <div className="grid col-12">
                                                <div className="col-6" style={{
                                                    alignItems: 'center',
                                                    display: 'flex',

                                                }}>
                                                    <img src={require('../../assets/images/aim.png').default} width={35} />
                                                    <label style={{ marginLeft: 10, color: 'grey' }}>No Target Set</label>
                                                </div>
                                                <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{
                                                        alignItems: 'center',
                                                        display: 'flex',

                                                    }}>
                                                        <img src={require('../../assets/images/calendar.png').default} style={{ filter: 'contrast(0.1)' }} width={35} />
                                                        <h4 style={{ marginLeft: 10, color: detectSign(ytd.scope12_) }}> {detectSign(ytd.scope12_) === 'green' ? '⭡' : '⭣'} {Math.abs(ytd.scope12_).toFixed(2)} %</h4>
                                                    </div>
                                                    <div><label >YoY as of {moment(moment.utc()).subtract(1, 'month').format('MMMM-YYYY')} </label></div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-6" style={{ margin: 10, padding: 10, backgroundColor: 'white', borderRadius: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                                            <h5>YTD Emissions (Scope 3)</h5>
                                            <h4  >{ytd.scope3} {" tCO" + "\u2082" + "e"} </h4>
                                            <div className="grid col-12">
                                                <div className="col-6" style={{
                                                    alignItems: 'center',
                                                    display: 'flex',

                                                }}>
                                                    <img src={require('../../assets/images/aim.png').default} width={35} />
                                                    <label style={{ color: 'grey', marginLeft: 10 }}>No Target Set</label>
                                                </div>
                                                <div className="col-6" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{
                                                        alignItems: 'center',
                                                        display: 'flex',

                                                    }}>
                                                        <img src={require('../../assets/images/calendar.png').default} style={{ filter: 'contrast(0.1)' }} width={35} />
                                                        <h4 style={{ color: detectSign(ytd.scope3_), marginLeft: 10 }}> {detectSign(ytd.scope3_) === 'green' ? '⭡' : '⭣'} {Math.abs(ytd.scope3_).toFixed(2)} %</h4>
                                                    </div>
                                                    <div><label >YoY as of {moment(moment.utc()).subtract(1, 'month').format('MMMM-YYYY')} </label></div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="card">

                                        <div
                                            className="col-12"
                                            style={{
                                                background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                padding: 10,

                                                color: "white",
                                                borderRadius: 5,
                                            }}
                                        >
                                            Total material Direct & Indirect GHG emissions
                                        </div>
                                        <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                            ★ - Incomplete Data
                                            {/* ✹ */}
                                        </div >
                                        <div className="grid" >
                                            <div className="col-3">

                                                <canvas id='scope1231' />
                                            </div>
                                            <div className="col-3" >

                                                <canvas id='scope1232' />
                                            </div>
                                            <div className="col-3">

                                                <canvas id='scope1233' />
                                            </div>
                                            <div className="col-3">

                                                <canvas id='scope1234' />
                                            </div>
                                        </div>
                                        <div
                                            className="col-12"
                                            style={{
                                                background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                padding: 10,

                                                color: "white",
                                                borderRadius: 5,
                                            }}
                                        >
                                            Progress
                                        </div>
                                        <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                            ★ - Incomplete Data
                                        </div >
                                        <div >
                                            <div className="col-12">

                                                <canvas width={'100%'} height={350} id='scope1' />

                                            </div>
                                            <div className="col-12">

                                                <canvas width={'100%'} height={350} id='scope2' />
                                            </div>
                                            <div className="col-12">
                                                <canvas width={'100%'} height={350} id='scope3' />
                                                {/* <div id='scope3' style={{
                                                width: '100%',
                                                minHeight: '450px',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }} /> */}
                                            </div>
                                        </div>

                                        <div
                                            className="col-12"
                                            style={{
                                                background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                padding: 10,
                                                marginTop: 20,

                                                color: "white",
                                                borderRadius: 5,
                                            }}
                                        >
                                            Scope-wise trend of GHG emissions
                                        </div>
                                        <div className="grid">
                                            <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                ★ - Incomplete Data
                                            </div >
                                            <div className="col-12">

                                                <canvas width={'100%'} height={350} id='scope36m' />
                                            </div>
                                        </div>
                                        <div className="col-12 sticky-div">
                                            <div className="card">
                                                <div
                                                    style={{
                                                        marginBottom: 20,
                                                    }}
                                                >
                                                    <h5> Filter Section </h5>
                                                </div>
                                                <div className="grid">
                                                    <div
                                                        style={{
                                                            width: "25%",
                                                        }}
                                                    >
                                                        <h6> Country </h6>
                                                        <Dropdown
                                                            style={{
                                                                width: "90%",
                                                            }}
                                                            value={filter.country}
                                                            onChange={(e) => onFilterChanged("country", e.value)}
                                                            options={countopt}
                                                            optionLabel="name"
                                                            placeholder="Select"
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "25%",
                                                        }}
                                                    >
                                                        <h6> City </h6>
                                                        <Dropdown
                                                            style={{
                                                                width: "90%",
                                                            }}
                                                            value={filter.city}
                                                            onChange={(e) => onFilterChanged("city", e.value)}
                                                            options={cityopt}
                                                            optionLabel="name"
                                                            placeholder="Select"
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "25%",
                                                        }}
                                                    >
                                                        <h6> Location </h6>
                                                        <Dropdown
                                                            style={{
                                                                width: "90%",
                                                            }}
                                                            value={filter.location}
                                                            onChange={(e) => onFilterChanged("location", e.value)}
                                                            options={locopt}
                                                            optionLabel="name"
                                                            placeholder="Select"
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "12.5%",
                                                        }}
                                                    >
                                                        <h6>  From </h6>
                                                        <Calendar id="range" style={{ width: '70%' }} value={filter.from} onChange={(e) => onFilterChanged('from', e.value)} view={'month'} dateFormat={' mm-yy'} />

                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "12.5%",
                                                        }}
                                                    >
                                                        <h6>  To </h6>
                                                        <Calendar id="range" style={{ width: '70%' }} value={filter.to} onChange={(e) => onFilterChanged('to', e.value)} view={'month'} dateFormat={' mm-yy'} />

                                                    </div>
                                                    {/* <div
                                                    style={{
                                                        width: "25%",
                                                    }}
                                                >
                                                    <h6> Timeframe </h6>

                                                    <Calendar value={filter.timeline} onChange={(e) => onFilterChanged("timeline", e.value)} selectionMode="range" dateFormat='mm/yy' view='month' />

                                                </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12"
                                            style={{
                                                background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                padding: 10,

                                                color: "white",
                                                borderRadius: 5,
                                            }}
                                        >
                                            Scope 1 Emission Breakup
                                        </div>
                                        <div className="grid">
                                            <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                ★ - Incomplete Data
                                            </div >
                                            <div className="col-6">
                                                {/* <label>Fugitive Emission</label> */}
                                                <canvas id='scope111' />
                                            </div>

                                        </div>
                                        <div
                                            className="col-12"
                                            style={{
                                                background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                padding: 10,

                                                color: "white",
                                                borderRadius: 5,
                                            }}
                                        >
                                            Scope 2 Emission Breakup
                                        </div>
                                        <div className="grid">
                                            <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                ★ - Incomplete Data
                                            </div >
                                            <div className="col-6">

                                                <canvas id='scope222' />
                                            </div>

                                        </div>
                                        <div
                                            className="col-12"
                                            style={{
                                                background: " linear-gradient(90deg, rgba(48,89,116,1) 0%, rgba(237,84,36,1) 75%, rgba(114,195,178,1) 100%)",
                                                padding: 10,

                                                color: "white",
                                                borderRadius: 5,
                                            }}
                                        >
                                            Scope 3 Emission Breakup
                                        </div>
                                        <div className="grid">
                                            <div className="col-12" style={{ padding: 10, color: 'red', marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                ★ - Incomplete Data
                                            </div >
                                            <div className="col-6">
                                                {/* <label>Fugitive Emission</label> */}
                                                <canvas id='scope333' />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                            }



                        </>

                        <div className="col-12 " >
                            <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>PROGRESSION AGAINST STANDARDS & FRAMEWORKS</h4>
                            <div className="card">


                                <div style={{ marginBottom: 10 }}>
                                    <label
                                        style={{
                                            marginRight: 10,
                                        }}
                                    >
                                        Standards/Frameworks
                                    </label>
                                    <Dropdown display="chip" style={{ margin: 10 }} value={sframeworklist} onChange={(e) => updateTAGReport(e.value)} options={frameworklist} optionLabel="title" optionValue="id"
                                        filterBy="title" filter placeholder="Select Framework" className="w-full " panelClassName={'hidefilter'} />

                                </div>
                                {sframeworklist.length !== 0 && <>
                                    <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>Measure of implementation of Qualitative Indicators</h4>
                                    <div className="col-12 grid " style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        {/* <div className="col-3" style={{ display: 'flex', alignItems: 'center' }}>Qualitative Aspects</div> */}
                                        <div className="col-3 p-card">
                                            {showPercentCanvas('Ql1')}
                                        </div>
                                        <div className="col-3 p-card">
                                            {showPercentCanvas('Ql2')}
                                        </div>
                                        <div className="col-3 p-card">
                                            {showPercentCanvas('Ql3')}
                                        </div>
                                    </div>
                                    <div className="col-12 grid" style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>


                                        <div className="col-3" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Must Have</div>
                                        <div className="col-3" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Progressive</div>
                                        <div className="col-3" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Advanced</div>
                                    </div>
                                    {/* <div className="col-12 grid">

                                    <div className="col-4" style={{ display: 'flex', justifyContent: 'center' }}>Must Have</div>
                                    <div className="col-4" style={{ display: 'flex', justifyContent: 'center' }}>Progressive</div>
                                    <div className="col-4" style={{ display: 'flex', justifyContent: 'center' }}>Advanced</div>
                                </div> */}
                                    <hr />
                                    <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>Distribution of selected Quantitative Indicators</h4>

                                    <div className="col-12 grid" style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        <div className="col-3" style={{
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }} >
                                            <div style={{
                                                alignItems: 'flex-end',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                {

                                                    category.map((cat) => {
                                                        return (
                                                            <div className="grid" style={{
                                                                alignItems: 'center',
                                                                margin: '5px'
                                                            }}>
                                                                <label style={{ marginRight: 5 }} >{cat.title} </label>
                                                                <div style={{ width: 15, height: 15, borderRadius: 5, background: getESGColor(cat.id) }}></div>

                                                            </div>
                                                        )

                                                    })
                                                }
                                            </div>
                                        </div>

                                        <div className="col-3 p-card">
                                            {showPercentCanvas('pie1')}
                                        </div>



                                        <div className="col-3 p-card">
                                            {showPercentCanvas('pie2')}
                                        </div>
                                        <div className="col-3" style={{
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <div >
                                                {
                                                    [{ title: 'Must Have', count: 0, color: '#e9e9e9' }, { title: 'Progressive', count: 0, color: '#a9a9a9' }, { title: 'Advanced', count: 0, color: '#808080' }].map((tag, i) => {
                                                        return (
                                                            <div className="grid" style={{
                                                                alignItems: 'center',
                                                                margin: '5px'
                                                            }}>
                                                                <div style={{ width: 15, height: 15, borderRadius: 5, background: tag.color }}></div>
                                                                <label style={{ marginLeft: 5 }} >{tag.title} </label>
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <hr />
                                    <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>Data collection towards selected Quantitative Indicators</h4>
                                    <div className="col-12 grid" style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        {/* <div className="col-3" style={{ display: 'flex', alignItems: 'center' }}>Quantitative Aspects</div> */}

                                        <div className="col-3 p-card">
                                            {showPercentCanvas('Q1')}
                                        </div>
                                        <div className="col-3 p-card">
                                            {showPercentCanvas('Q2')}
                                        </div>
                                        <div className="col-3 p-card">
                                            {showPercentCanvas('Q3')}
                                        </div>
                                    </div>
                                    <div className="col-12 grid" style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>


                                        <div className="col-3" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Quantum of Data Assignment</div>
                                        <div className="col-3" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Quantum of response from Enterprise</div>
                                        <div className="col-3" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Quantum of response from Suppliers</div>
                                    </div>

                                    {fwindicator.length !== 0 &&
                                        <>
                                            <hr />
                                            <h4 style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>Overview of performance against selected Quantitative Indicators ( {fwindicator.flatMap((i) => { return i.data.flatMap(k => { return k }) }).length} ) </h4>

                                            <div className="col-12 grid">

                                                <div className="col-6" style={{

                                                    border: '1px solid rgb(222, 226, 230)',
                                                    padding: '1rem',
                                                    outlineColor: 'rgb(199, 210, 254)',
                                                    background: 'lightgray',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>

                                                </div>
                                                <div className="col-2" style={{

                                                    border: '1px solid rgb(222, 226, 230)',
                                                    padding: '1rem',
                                                    outlineColor: 'rgb(199, 210, 254)',
                                                    background: 'lightgray',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    2021
                                                </div>
                                                <div className="col-2" style={{

                                                    border: '1px solid rgb(222, 226, 230)',
                                                    padding: '1rem',
                                                    outlineColor: 'rgb(199, 210, 254)',
                                                    background: 'lightgray',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    2022
                                                </div>
                                                <div className="col-2" style={{

                                                    border: '1px solid rgb(222, 226, 230)',
                                                    padding: '1rem',
                                                    outlineColor: 'rgb(199, 210, 254)',
                                                    background: 'lightgray',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    2023
                                                </div>
                                            </div>
                                            <div className="col-12" style={{ maxHeight: 300, overflow: 'scroll' }}>
                                                {fwindicator.map((topic) => {
                                                    return (
                                                        <div>

                                                            <div className="col-12" style={{
                                                                textAlign: 'left',
                                                                border: '1px solid #dee2e6',
                                                                borderWidth: '0 0 1px 0',
                                                                padding: '1rem 1rem',
                                                                outlineColor: '#C7D2FE', borderWidth: 1, fontWeight: 'bold'
                                                            }}>
                                                                {topic.title}
                                                            </div>


                                                            <DataTable
                                                                value={topic.data}
                                                                showHeaders={false}
                                                                scrollable
                                                                scrollHeight="flex"
                                                                showGridlines
                                                                responsiveLayout="scroll"
                                                            >
                                                                <Column field="data1[0].title" body={(rowData) => { return <span >{rowData.data1[0].title}</span> }} style={{ width: '50%' }} header="Indicator" ></Column>
                                                                <Column field="" body={() => { return <span style={{ color: 'grey' }}>No Data</span> }} header="202" />
                                                                <Column field="" body={() => { return <span style={{ color: 'grey' }}>No Data</span> }} header="2022" headerStyle={{ color: 'grey' }} />
                                                                <Column field="" body={() => { return <span >No Data</span> }} header="2021" headerStyle={{ color: 'grey' }} />

                                                            </DataTable>
                                                        </div>
                                                    )
                                                })

                                                }

                                            </div>
                                        </>


                                    }
                                </>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-12 card">You have no rights to access this page </div>
                )}
            </div>
            <Dialog
                visible={attdialog}
                style={{
                    width: "50%",
                }}
                header="Attachments"
                modal
                className="p-fluid"

                onHide={() => { setAttDialog(false) }}
            >
                <div>
                    {attachment.map((i, index) => {

                        return (<>
                            <div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", marginTop: 20
                                    }}
                                >
                                    <label style={{ width: '80%' }}>{i.name}</label>
                                    <span
                                        style={{
                                            display: "flex", width: '20%',
                                            flexDirection: "row", justifyContent: 'center',
                                            color: "green", cursor: 'pointer',
                                            alignItems: "center",
                                        }}
                                        onClick={(e) => {
                                            window.open(i.url);
                                        }}
                                    >
                                        <i className="material-icons" > visibility </i>

                                    </span>

                                </div>
                            </div>
                        </>)
                    })

                    }
                </div>
            </Dialog>
            <Dialog

                visible={historydialog}
                style={{
                    width: "450px",
                }}

                header="Status History"
                modal
                className="p-fluid"
                focusOnShow
                onHide={() => { setHistoryDialog(false); }}
            >
                <div style={{ marginBottom: 20, width: '100%', heigth: 450, overflow: 'scroll' }}>

                    {historydata.map((item) => {
                        let bgcolor = ''
                        if (item[3] === 1) {
                            bgcolor = 'red'
                        } else if (item[3] === 2) {
                            bgcolor = "#FFBF00"
                        } else {
                            bgcolor = 'green'
                        }
                        return (
                            <div style={{ flexDirection: 'column', display: 'flex', padding: 5, borderRadius: 10, margin: 5, boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
                                <div style={{ margin: 10 }} className='ql-editor' dangerouslySetInnerHTML={{ __html: item[1] }} />
                                <text style={{ color: 'white', borderRadius: 10, padding: 5, fontStyle: 'italic', backgroundColor: bgcolor }}>As of {moment(item[0]).local().format('DD MMM YYYY, hh:mm A')}</text>
                                <text style={{ justifyContent: 'flex-end', display: 'flex', color: 'mediumvioletred', fontStyle: 'italic' }}><span style={{ color: 'gray' }}>Overall Responsibility : &nbsp;</span> {item[2]}</text>
                            </div>
                        )
                    })

                    }

                </div>

            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(DashboardCC, comparisonFn);
