import React,{useEffect, useState} from 'react';
import { Card } from 'primereact/card';
import { Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';

const BarFive = ({graphData}) => {
    const [data,seData] = useState([])

    useEffect(()=>{
        seData(graphData)
    },[graphData])


    return (
        <Card>
            <p className='card-title'>Energy consumption (TJ)</p>
            <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>

            <ResponsiveContainer width="100%" height={320}>
                <BarChart data={data}>
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={() => ''} />
                    <Tooltip />
                    <Bar stackId="a" dataKey="stationary" fill="#EE5724" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="stationary" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="fugitive" fill="#FF9D7C" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="fugitive" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="mobile" fill="#F5C37B" radius={[10, 10, 0, 0]} barSize={30}>
                        <LabelList dataKey="mobile" position="top" />
                    </Bar>
                   
                    <Legend verticalAlign="bottom" align="center" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default BarFive;
