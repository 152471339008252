// App.js
import React from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";

const EmissionsCard = ({
  title,
  emissions,
  target,
  percentageChange,
  trendData,
  isIncrease,
}) => {
  const trendColor =
    emissions.toLocaleString() !== "22%"
      ? isIncrease
        ? "red"
        : "green"
      : isIncrease
      ? "green"
      : "red";

  return (
    <div style={styles.card}>
      <div className="mr-3 ">
        <h3 style={styles.title}>{title}</h3>
        <div style={styles.emissionsValue}>
          {emissions.toLocaleString() !== "22%"
            ? emissions.toLocaleString() + `t CO₂e`
            : emissions.toLocaleString()}
        </div>
        <div style={styles.target}>
          <div style={styles.targetValue}>YTD</div>
          {}
          <span
            style={{
              ...styles.percentageChange,
              color: trendColor,
              justifyContent: "center",
            }}
          >
            {isIncrease ? "↑" : "↓"} {Math.abs(percentageChange)}%
          </span>
          <span style={styles.targetValue}>From 2023</span>
        </div>
      </div>
      <ResponsiveContainer width="23%" height={100}>
        <LineChart data={trendData}>
          <Line
            type="bump"
            dataKey="value"
            stroke={trendColor}
            dot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const EmissionsCards = () => {
  const data = [
    {
      title: "Direct Emissions (Scope 1)",
      emissions: 2342.23,
      target: 232400,
      percentageChange: 40,
      isIncrease: true,
      trendData: [
        { value: 30 },
        { value: 55 },
        { value: 40 },
        { value: 60 },
        { value: 62 },
        { value: 92 },
      ],
    },
    {
      title: "Indirect Emissions (Scope 2)",
      emissions: 342.23,
      target: 232400,
      percentageChange: -40,
      isIncrease: false,
      trendData: [
        { value: 400 },
        { value: 320 },
        { value: 640 },
        { value: 440 },
        { value: 740 },
        { value: 642.23 },
      ],
    },
    {
      title: "Indirect Emissions (Scope 3)",
      emissions: 600.12,
      target: 232400,
      percentageChange: 40,
      isIncrease: true,
      trendData: [
        { value: 600 },
        { value: 800 },
        { value: 450 },
        { value: 700 },
        { value: 300.12 },
        { value: 500 },
      ],
    },
    {
      title: "Percentage of Women Employees in Workplace",
      emissions: "22%",
      target: "",
      percentageChange: "20",
      isIncrease: "true",
      trendData: [
        { value: 200 },
        { value: 100 },
        { value: 600 },
        { value: 300 },
        { value: 200.12 },
        { value: 500 },
      ],
    },
  ];

  return (
    <div style={styles.dashboard}>
      {data.map((item, index) => (
        <EmissionsCard key={index} {...item} />
      ))}
    </div>
  );
};

const styles = {
  dashboard: {
    display: "flex",
    gap: "0.8%",
    padding: "10px",
    justifyContent: "center",
  },
  card: {
    width: "25%",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    padding: "1rem",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
  },
  title: {
    fontSize: "1em",
    marginBottom: "10px",
    color: "#333",
  },
  emissionsValue: {
    fontSize: "1.5em",
    fontWeight: "bold",
    marginTop: "10px",
  },
  target: {
    color: "#888",
    margin: "5px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  targetValue: {
    fontWeight: "bold",
    marginLeft: "10px",
  },
  percentageChange: {
    fontSize: "1em",
    fontWeight: "bold",
    marginLeft: "10px",
  },
};

export default EmissionsCards;
